import React, { useEffect, useReducer, useRef } from 'react';
import { Table, Icon as NeoIcon, Button, Chip } from "neo-latest";
import { Tabs, Dropdown, Spin, message, Tooltip, Form } from 'antd';
import CONFIG from '../../config';
import moment from 'moment-timezone';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
    getAllDemosLite,
    fetchDataCenters,
    fetchResources,
    fetchAllDemoStatus,
    exportTable,
    handleOnClickAction,
    clearDemoDCStatusTableData,
    putAssignedUserDataCenter,
    toggleSaveReportVisible,
    toggleHasUnsavedChangesFlag,
    clearDCData,
    currentDataCenterFilter
} from "../../actions";

import { isTabAlreadyOpen } from "../common/TabLayout";
import { fetchNamesBySearchString } from '../../actions/userRole'
import { exportMenu } from '../../utils/strings';
import { otherFields } from '../../utils/neoTableFields';
import { checkIfFilterSortIsEmpty } from '../../utils/formChanged';
import { LoadingOutlined } from '@ant-design/icons';
import pushMessage from '../common/PushMessage';
import FilterDrawer from '../common/FilterDrawer';
import EditDrawer from '../common/EditDrawer';
import DemoStatusDetails from './DemoStatusDetails';
import SaveReport from '../common/SaveReport';
const { TabPane } = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function DemoStatusTable(props) {
    const stateRef = useRef();
    const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
        showFilter: false,
        loading: true,
        panelKey: "open",
        demos: [],
        height: `${document.body.clientHeight - 175}px`,
        drawerHeight: `${document.body.clientHeight - 100}px`,
        showEdit: false,
        isReadOnlyEdit: false,
        record: null,
        formLoading: false,
        saveReport: false,
        matchedUsersNames: [],
        filterSave: {},
        filterSort: { filter: { type: "Demo Resource\\Demo DC" } },
        currentPage: 0,
    });

    const dispatch = useDispatch();
    const resources = useSelector(({ resources }) => resources?.data || []);
    const dataCenters = useSelector(({ dataCenters }) => dataCenters?.data || []);
    const timezone = useSelector(({ user }) => user?.profile?.timezone);
    const activeKey = useSelector(({ tabsLayout }) => tabsLayout.activeKey);
    const openedTabs = useSelector(({ tabsLayout }) => tabsLayout.panes);
    const demoStatusData = useSelector(({ dataCenterStatus }) => dataCenterStatus?.demoDataCenterStatus || []);
    const currentFilter = useSelector(({ dataCenterStatus }) => dataCenterStatus?.currentFilter || {});
    const currentStatus = useSelector(({ dataCenterStatus }) => dataCenterStatus?.status || "open");
    const isEdit = useSelector(({ dataCenterStatus }) => dataCenterStatus?.isEdit || false);
    const currentPageFromStore = useSelector(({ dataCenterStatus }) => dataCenterStatus?.currentPage || 0);

    const dataIndexMap = {
        demo: 'demo.title',
        updatedBy: 'updatedBy.fullName',
        updatedDate: 'updatedDate',
        description: 'description',
        assigned: "assignedUser.fullName",
        resolvedBy: "resolvedBy.fullName",
        resolvedDate: "resolvedDate",
        startUpdatedTime: "startUpdatedTime",
        endUpdatedTime: "endUpdatedTime",
        startResolvedTime: "startResolvedTime",
        endResolvedTime: "endResolvedTime",
        status: "status",
        type: "type",
        dcTitle: "dataCenter.title",
        resourceTitle: "resource.name",
    };

    useEffect(() => {
        const fetchData = async () => {
            batch(async () => {
                if (isEdit) {
                    await setState({ panelKey: currentStatus, filterSort: currentFilter, currentPage: currentPageFromStore })
                }
                const demoData = await dispatch(getAllDemosLite());
                await dispatch(fetchAllDemoStatus(isEdit ? currentStatus ?? "open" : state.panelKey, isEdit ? currentFilter : state.filterSort))
                await dispatch(fetchDataCenters());
                await dispatch(fetchResources({ getAll: true }));
                await setState({ demos: demoData, loading: false })
            })
        }
        fetchData();
        const cleanUpComponent = () => {
            if (isTabAlreadyOpen({ openedTabs: openedTabs, currentComponentPath: "/manage/demo-status" })) {
                dispatch(currentDataCenterFilter(stateRef?.current?.filterSort, stateRef?.current?.panelKey, stateRef?.current?.currentPage, null));
                dispatch(clearDemoDCStatusTableData());
            } else {
                dispatch(clearDCData());
            }
            dispatch(toggleHasUnsavedChangesFlag(activeKey, false));
            const dropdownLinks = document.querySelectorAll(
                '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
            );
            if (dropdownLinks?.length > 0) {
                dropdownLinks.forEach((link) => {
                    link.addEventListener('click', handleDatesSortForNeoTable);
                });
            }
        }
        return cleanUpComponent;
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        stateRef.current = { filterSort: state.filterSort, panelKey: state.panelKey, currentPage: state.currentPage };
        const dropdownLinks = document.querySelectorAll(
            '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
        );
        if (dropdownLinks?.length > 0) {
            dropdownLinks.forEach((link) => {
                link.addEventListener('click', handleDatesSortForNeoTable);
            });
        }
    });

    const handleDatesSortForNeoTable = (event) => {
        const targetElement = event.target;
        const sortingDateChild = targetElement.querySelector('#sorting-date');
        const arrowChild = ((targetElement.classList?.contains("neo-icon--small") || (targetElement.classList?.contains("neo-icon-arrow-down")) ||
            (targetElement.classList?.contains("neo-icon-arrow-up"))) && ((targetElement?.offsetParent?.firstChild?.innerHTML?.includes("sorting-date")) || (targetElement?.offsetParent?.lastChild?.innerHTML?.includes("sorting-date"))));
        if (sortingDateChild || arrowChild) {
            setTimeout(() => {
                const myElement = document.querySelector('.neo-dropdown__content');
                if (myElement) {
                    myElement.classList.add('sorting-classes');
                    const parentItem = document.querySelector(".sorting-classes");
                    const children = parentItem.children;
                    children[0].textContent = 'Clear Sort';
                    children[1].textContent = 'Older to Newer';
                    children[2].textContent = 'Newer to Older';
                }
            }, 10)
        }
    }

    const handeEditRecord = (record, isEdit) => {
        setState({
            showEdit: true,
            isReadOnlyEdit: isEdit,
            record
        })
    }

    const handleEditClick = () => {
        setState({
            showEdit: true,
            isReadOnlyEdit: false,
        })
    }

    const handleChangeSortName = () => {
        setTimeout(() => {
            const myElement = document.querySelector('.neo-dropdown__content');
            if (myElement) {
                myElement.classList.add('sorting-classes');
                const parentItem = document.querySelector(".sorting-classes");
                const children = parentItem.children;
                children[0].textContent = 'Clear Sort';
                children[1].textContent = 'Older to Newer';
                children[2].textContent = 'Newer to Older';
            }
        }, 10)
    }

    const columns = [
        {
            Cell: (a) => {
                return <span onClick={() => handeEditRecord(a?.cell?.row?.original, true)} style={{ textAlign: "center", color: "#1B77AF", cursor: "pointer" }}>{a?.cell?.row?.original?.demo?.title}</span>
            },
            Header: "Demo title",
            sortType: "string",
            accessor: "demo.title",
            disableFilters: true,
        },
        {
            Cell: (a) => {
                return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.updatedTime ? moment.tz(a?.cell?.row?.original?.updatedTime, timezone).format(CONFIG.dateFormats.userDateTime) : "-"}</span>
            },
            Header: <span onClick={handleChangeSortName} id='sorting-date'>Updated Time</span>,
            sortType: (rowA, rowB) => {
                const startTimeA = moment.tz(rowA?.original?.updatedTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timezone);
                const startTimeB = moment.tz(rowB?.original?.updatedTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timezone);
                if (startTimeA.isBefore(startTimeB)) {
                    return -1;
                }
                if (startTimeA.isAfter(startTimeB)) {
                    return 1;
                }
                return 0;
            },
            accessor: "updatedTime",
            disableFilters: true,
        },
        {
            Cell: (a) => {
                const record = a?.cell?.row?.original
                const recordTitle = record?.type === "Demo DC" ? record?.dataCenter?.title : record?.resource?.name
                return <Chip variant={record?.type === "Demo DC" ? CONFIG.demoStatus.tagChips[record?.status] : CONFIG.demoResourceStatus.tagTypes[record?.status]} style={{ textAlign: "center" }}>{recordTitle}</Chip>
            },
            Header: "Status",
            sortType: "string",
            accessor: "status",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Cell: (a) => {
                return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.assignedUser?.fullName}</span>
            },
            Header: "Assigned User",
            sortType: "alphanumeric",
            disableFilters: true,
            accessor: "assignedUser.fullName",
        },
        {
            Cell: (a) => {
                const record = a.cell.row.original;
                return (
                    <React.Fragment>
                        <div style={{ display: "flex", gap: "24px" }}>
                            <div>
                                <Tooltip title="Edit"><NeoIcon aria-label="menu-aria" size="md" style={{ cursor: "pointer", color: "#1B77AF" }} icon="edit" onClick={() => handeEditRecord(record, false)}
                                /></Tooltip>
                            </div>
                            <div>
                                <Tooltip title="Update Status"><NeoIcon aria-label="menu-aria" size="md" style={{ cursor: "pointer", color: "#1B77AF" }} icon="settings"
                                    onClick={() => {
                                        dispatch(handleOnClickAction({
                                            component: CONFIG.editComponentRoute.demos,
                                            tabTitle: record?.demo?.title,
                                            recordId: record?.demo?.id
                                        }))

                                    }}
                                /></Tooltip>
                            </div>
                        </div>
                    </React.Fragment>
                )
            },
            Header: "  ",
            disableFilters: true,
            disableSortBy: true
        }
    ]


    const handleExport = async (fileType) => {
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        if (demoStatusData && demoStatusData?.length > 0) {
            exportTable(CONFIG.exportTable.demoStatus?.name, demoStatusData, timezone, CONFIG.exportTable.demoStatus.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    const handleClear = async (isRefresh) => {
        if (!isRefresh) {
            setState({ filterSave: {}, filterSort: { ...state.filterSort, filter: { type: "Demo Resource\\Demo DC" } } })
        }
        setState({ loading: true });
        await dispatch(fetchAllDemoStatus(state.panelKey, !isRefresh ? { filter: { type: "Demo Resource\\Demo DC" } } : state.filterSort));
        setState({ loading: false });
    }

    const renderFilterPanel = () => {
        return (
            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                <div style={{ display: "flex", width: "100%", flexDirection: "row-reverse", gap: "24px" }}>
                    <div >
                        <Dropdown overlay={exportMenu(handleExport)} trigger="click">
                            <Button className="export-download-button" icon="chevron-down" variant="secondary">Download</Button>
                        </Dropdown>
                    </div>
                    <div >
                        <NeoIcon aria-label="menu-aria" size="md" className="clickable" onClick={() => setState({ showFilter: !state.showFilter })} title="Filter" icon={(state.showFilter || (!checkIfFilterSortIsEmpty(state.filterSort, ["type"]))) ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                    <div >
                        <NeoIcon aria-label="menu-aria" size="md" className="clickable" title="Refresh" icon="refresh" onClick={() => handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                </div>
            </div>
        )
    }

    const renderTable = () => {
        return (
            state.loading ? null : <Table
                columns={state?.panelKey === "open" ? columns : columns.slice(0, columns?.length - 1)}
                data={demoStatusData || []}
                className="table-actions"
                allowColumnFilter={false}
                itemsPerPageOptions={[10, 20, 50, 100]}
                initialStatePageIndex={state.currentPage}
                handlePageChange={(newPageIndex, newPageSize) => {
                    if (newPageIndex >= 0) setState({ currentPage: newPageIndex })
                }}
                showRowSeparator
                {...otherFields}
            />
        )
    }

    const handlePanelChange = async (key) => {
        setState({ panelKey: key, loading: true });
        await dispatch(fetchAllDemoStatus(key, state.filterSort));
        setState({ loading: false });
    }

    const renderTabContents = () => {
        return (
            <React.Fragment>
                <div>
                    {renderFilterPanel()}
                </div>
                <div className="height-neo-table">
                    {renderTable()}
                </div>
            </React.Fragment>
        )
    }

    const handleCloseFilterDrawer = () => {
        setState({ showFilter: !state.showFilter })
    }

    const handleSaveRecord = () => {
        props.form.validateFields(async (error, values) => {
            if (error) {
                return;
            }
            else {
                setState({ formLoading: true })
                let data = {
                    ...state.record,
                    assignedUser: JSON.parse(values.assignedUser),
                    notes: values.notes
                }
                const isUpdated = await dispatch(putAssignedUserDataCenter(data));
                if (isUpdated) {
                    setState({
                        formLoading: false, loading: true, showEdit: false,
                        isReadOnlyEdit: false,
                    })
                    await dispatch(clearDemoDCStatusTableData())
                    await dispatch(fetchAllDemoStatus(state.panelKey, state.filterSort));
                    setState({ loading: false });
                }
                setState({ formLoading: false, })
            }
        });
    }

    const getUTCTime = (time) => {
        return time.tz(timezone, true).toISOString() || undefined
    }

    const fetchUsersName = searchString => {
        setState({ matchedUsersNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    setState({ matchedUsersNames: [...new Set(result.map(item => item.fullName))] })
                }
                setState({ hasEnteredString: false })
            });
    };

    const handleSaveFilter = (filters) => {
        let { demo, description, dcTitle, resourceTitle, type, status, resolvedBy, updatedBy, assignedUser, updatedTime, resolvedTime } = filters;
        assignedUser = assignedUser?.trim();
        updatedBy = updatedBy?.trim();
        description = description?.trim();
        resolvedBy = resolvedBy?.trim();
        const isDcTitleEmpty = !dcTitle || (dcTitle && dcTitle.length === 0);
        const isResourceTitleEmpty = !resourceTitle || (resourceTitle && resourceTitle.length === 0);
        const isstatusEmpty = !status || (status && status.length === 0);
        const isTypeEmpty = !type || (type && type.length === 0);
        const isDemoEmpty = !demo || (demo && demo.length === 0);
        const isresolvedTimeEmpty = !resolvedTime || (resolvedTime && resolvedTime.length === 0);
        const isupdatedTimeEmpty = !updatedTime || (updatedTime && updatedTime.length === 0);

        if (!assignedUser && !description && !resolvedBy && !updatedBy && isDcTitleEmpty && isResourceTitleEmpty && isTypeEmpty && isresolvedTimeEmpty && isupdatedTimeEmpty && isstatusEmpty && isDemoEmpty) {
            pushMessage(CONFIG.messageType.warning, `Please select some fields to save.`)
            return;
        }
        let filterSave = {
            filter: {
                ...(!isDemoEmpty && {
                    [dataIndexMap.demo]: {
                        operator: "=",
                        oprVal: demo.join(CONFIG.delimiters.saveFilter) || undefined
                    }
                }),
                ...(description && {
                    [dataIndexMap.description]: {
                        operator: "=",
                        oprVal: description || undefined
                    }
                }),
                ...(!isDcTitleEmpty && {
                    [dataIndexMap.dcTitle]: {
                        operator: "=",
                        oprVal: dcTitle.join(CONFIG.delimiters.saveFilter) || undefined,
                    }
                }),
                ...(!isResourceTitleEmpty && {
                    [dataIndexMap.resourceTitle]: {
                        operator: "=",
                        oprVal: resourceTitle.join(CONFIG.delimiters.saveFilter) || undefined,
                    }
                }),
                ...(resolvedBy && {
                    [dataIndexMap.resolvedBy]: {
                        operator: "=",
                        oprVal: resolvedBy || undefined
                    }
                }),
                ...(assignedUser && {
                    [dataIndexMap.assigned]: {
                        operator: "=",
                        oprVal: assignedUser || undefined
                    }
                }),
                ...(updatedBy && {
                    [dataIndexMap.updatedBy]: {
                        operator: "=",
                        oprVal: updatedBy || undefined
                    }
                }),
                ...(!isstatusEmpty && {
                    [dataIndexMap.status]: {
                        operator: "=",
                        oprVal: CONFIG.demoStatus.types[status] || undefined
                    }
                }),
                ...(!isTypeEmpty && {
                    [dataIndexMap.type]: {
                        operator: "=",
                        oprVal: type.join(CONFIG.delimiters.saveFilter) || undefined
                    }
                }),
                ...(!isupdatedTimeEmpty && {
                    updatedTime: {
                        operator: "IN",
                        oprVal: !isupdatedTimeEmpty ? updatedTime[0].tz(timezone, true).startOf('day').toISOString() + '@' + updatedTime[1].tz(timezone, true).endOf('day').toISOString() : undefined
                    }
                }),
                ...(!isresolvedTimeEmpty && {
                    resolvedTime: {
                        operator: "IN",
                        oprVal: !isresolvedTimeEmpty ? resolvedTime[0].tz(timezone, true).startOf('day').toISOString() + '@' + resolvedTime[1].tz(timezone, true).endOf('day').toISOString() : undefined
                    }
                }),
            },
            filterTable: {
                id: 11,
                tableName: "Demo DC Status"
            },
        }
        dispatch(toggleSaveReportVisible(true))
        setState({ saveReport: true, showFilter: false, filterSave })
    }

    const handleFilter = async (filters, isSave) => {
        let { demo, description, dcTitle, resourceTitle, type, status, resolvedBy, updatedBy, assignedUser, updatedTime, resolvedTime } = filters;
        assignedUser = assignedUser?.trim();
        updatedBy = updatedBy?.trim();
        description = description?.trim();
        resolvedBy = resolvedBy?.trim();
        const isFilter = isSave ? "save" : "filter";
        const isDcTitleEmpty = !dcTitle || (dcTitle && dcTitle.length === 0);
        const isResourceTitleEmpty = !resourceTitle || (resourceTitle && resourceTitle.length === 0);
        const isstatusEmpty = !status || (status && status.length === 0);
        const isTypeEmpty = !type || (type && type.length === 0);
        const isDemoEmpty = !demo || (demo && demo.length === 0);
        const isresolvedTimeEmpty = !resolvedTime || (resolvedTime && resolvedTime.length === 0);
        const isupdatedTimeEmpty = !updatedTime || (updatedTime && updatedTime.length === 0);

        if (!assignedUser && !description && !resolvedBy && !updatedBy && isDcTitleEmpty && isResourceTitleEmpty && isTypeEmpty && isresolvedTimeEmpty && isupdatedTimeEmpty && isstatusEmpty && isDemoEmpty) {
            pushMessage(CONFIG.messageType.warning, `Please select some fields to ${isFilter}`)
            return;
        }
        await dispatch(clearDemoDCStatusTableData())
        state.filterSort = {
            filter: {
                [dataIndexMap.demo]: !isDemoEmpty ? demo.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.description]: description || undefined,
                [dataIndexMap.startUpdatedTime]: !isupdatedTimeEmpty ? getUTCTime(updatedTime[0]) : undefined,
                [dataIndexMap.endUpdatedTime]: !isupdatedTimeEmpty ? getUTCTime(updatedTime[1]) : undefined,
                [dataIndexMap.startResolvedTime]: !isresolvedTimeEmpty ? getUTCTime(resolvedTime[0]) : undefined,
                [dataIndexMap.endResolvedTime]: !isresolvedTimeEmpty ? getUTCTime(resolvedTime[1]) : undefined,
                [dataIndexMap.dcTitle]: !isDcTitleEmpty ? dcTitle.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.resourceTitle]: !isResourceTitleEmpty ? resourceTitle.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.updatedBy]: updatedBy || undefined,
                [dataIndexMap.resolvedBy]: resolvedBy || undefined,
                [dataIndexMap.assigned]: assignedUser || undefined,
                [dataIndexMap.status]: !isstatusEmpty ? typeof status === 'string' ? status : status?.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.type]: !isTypeEmpty ? type.join(CONFIG.delimiters.selectFilter) : "Demo Resource\\Demo DC",
            }
        }
        setState({ matchedUsersNames: [], loading: true })
        await dispatch(fetchAllDemoStatus(state.panelKey, state.filterSort));
        setState({ loading: false });
    }

    const drawerContainer = document.getElementById('#component-content');

    const formFields = [
        {
            label: "Demo Title",
            type: CONFIG.formFieldTypes.SELECT,
            key: "demo",
            mode: "tags",
            valueKey: "text",
            data: (state?.demos) ? state?.demos.map(demo => ({ text: demo?.title })) : [],
            colSpan: 24,
            value: state.filterSort.filter?.[dataIndexMap.demo]
        },
        {
            label: "Type",
            type: CONFIG.formFieldTypes.SELECT,
            data: CONFIG.filterDataSet.demoStatus,
            key: "type",
            mode: "tags",
            colSpan: 24,
            value: state.filterSort.filter?.[dataIndexMap.type]
        },
        {
            label: "Status",
            type: CONFIG.formFieldTypes.SELECT,
            data: CONFIG.filterDataSet.dcStatus,
            key: "status",
            mode: "default",
            colSpan: 24,
            value: state.filterSort.filter?.[dataIndexMap.status]
        },
        {
            label: "Data Center Title",
            type: CONFIG.formFieldTypes.SELECT,
            key: "dcTitle",
            colSpan: 24,
            valueKey: "text",
            data: (dataCenters) ? dataCenters?.map(demo => ({ text: demo?.title })) : [],
            value: state.filterSort.filter?.[dataIndexMap.dcTitle]
        },
        {
            label: "Resource Title",
            type: CONFIG.formFieldTypes.SELECT,
            key: "resourceTitle",
            colSpan: 24,
            valueKey: "text",
            data: (resources) ? resources?.map(resource => ({ text: resource?.name })) : [],
            value: state.filterSort.filter?.[dataIndexMap.resourceTitle]
        },
        {
            label: "Updated By",
            type: CONFIG.formFieldTypes.SEARCH_SELECT,
            key: "updatedBy",
            data: state.matchedUsersNames,
            colSpan: 24,
            value: state.filterSort.filter?.[dataIndexMap.updatedBy]
        },

        {
            label: "Assigned User",
            type: CONFIG.formFieldTypes.SEARCH_SELECT,
            key: "assignedUser",
            data: state.matchedUsersNames,
            colSpan: 24,
            value: state.filterSort.filter?.[dataIndexMap.assigned]
        },
        {
            label: "Resolved By",
            type: CONFIG.formFieldTypes.SEARCH_SELECT,
            key: "resolvedBy",
            data: state.matchedUsersNames,
            colSpan: 24,
            value: state.filterSort.filter?.[dataIndexMap.resolvedBy]
        },
        {
            label: "Description",
            type: CONFIG.formFieldTypes.INPUT,
            key: "description",
            colSpan: 24,
            value: state.filterSort.filter?.[dataIndexMap.description]
        },
        {
            label: "Updated Date",
            type: CONFIG.formFieldTypes.RANGE_PICKER,
            key: "updatedTime",
            colSpan: 24,
            value: [state.filterSort?.filter?.[dataIndexMap.startUpdatedTime], state.filterSort?.filter?.[dataIndexMap.endUpdatedTime]],
        },
        {
            label: "Resolved Date",
            type: CONFIG.formFieldTypes.RANGE_PICKER,
            key: "resolvedTime",
            colSpan: 24,
            value: [state.filterSort?.filter?.[dataIndexMap.startResolvedTime], state.filterSort?.filter?.[dataIndexMap.endResolvedTime]],
        },
    ]
    return (
        <div>
            <div style={{ color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px", marginTop: "28px" }}>
                Manage Demo Status
            </div>
            <Spin spinning={state.loading} indicator={antIcon}>
                <div style={{ marginTop: "16px" }}>
                    <Tabs activeKey={state.panelKey} className='demo-status-tabs' defaultActiveKey={state.panelKey} onChange={handlePanelChange}>
                        <TabPane tab="Open" key="open">
                            {renderTabContents()}
                        </TabPane>
                        <TabPane tab="Closed" key="closed">
                            {renderTabContents()}
                        </TabPane>
                    </Tabs>
                </div>
                {state.showFilter && (
                    <FilterDrawer
                        visible={state.showFilter}
                        width='520'
                        handleCloseFilterDrawer={handleCloseFilterDrawer}
                        placement="right"
                        drawerContainer={drawerContainer}
                        style={{ zIndex: 9999, }}
                        maskClosable={false}
                        panelHeader="Filters and Export"
                        formFields={formFields}
                        handleClear={handleClear}
                        timeZone={timezone}
                        hideSoftDeleteCheckbox={true}
                        hideSave={false}
                        handleSave={handleSaveFilter}
                        handleFilter={handleFilter}
                        getSearchData={fetchUsersName}
                    />)}
                {
                    state.showEdit && <EditDrawer
                        visible={state.showEdit}
                        width='520'
                        handleCloseFilterDrawer={() => setState({ showEdit: false, record: null })}
                        placement="right"
                        drawerContainer={drawerContainer}
                        style={{ zIndex: 9999, }}
                        maskClosable={false}
                        timeZone={timezone}
                        readOnly={state.isReadOnlyEdit}
                        form={props.form}
                        handleEditClick={handleEditClick}
                        handleSave={handleSaveRecord}
                        record={state.record}
                        showEdit={state.panelKey === "open"}
                        filterSort={state.filterSort}
                        title={`${(!state.isReadOnlyEdit) ? "Edit" : ""} Demo Status Details`}
                    >
                        <Spin spinning={state.formLoading} indicator={antIcon}>
                            <DemoStatusDetails
                                roleTemplates={props.roleTemplates}
                                form={props.form}
                                timezone={props.timezone}
                                panelKey={state.panelKey}
                                record={state.record}
                                readOnly={state.isReadOnlyEdit} />
                        </Spin>
                    </EditDrawer>
                }
                {state.saveReport && <SaveReport closeModal={() => setState({ saveReport: false })} reportFilter={state.filterSave} />}
            </Spin>
            <br />
        </div>
    )
}

const DemoStatusTableRef = Form.create()(DemoStatusTable);

export default DemoStatusTableRef;

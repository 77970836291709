
export const tableContentConfig = {
  contentToolbar: [
    "tableColumn",
    "tableRow",
    "mergeTableCells",
    "splitTableCell",
    "tableCellProperties",
    "tableProperties",
    "toggleTableCaption",
  ],
}
  ;

export const listProperties = {
  properties: {
    styles: true,
    startIndex: true,
    reversed: true,
  },
};

export const imageConfig = {
  toolbar: [
    "imageTextAlternative",
    "imageStyle:block",
    "imageStyle:side",
  ],
  styles: ["block", "side"],
};

export const toolbarConfig = {
  items: [
    "heading",
    "bold",
    "italic",
    "strikethrough",
    "removeFormat",
    "|",
    "fontSize",
    "fontFamily",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "alignment:left",
    "alignment:center",
    "alignment:right",
    "alignment:justify",
    "|",
    "horizontalLine",
    "insertTable",
    "style",
    "codeBlock",
    "|",
    "link",
    "unlink",
    "bookmark",
    "indent",
    "outdent",
    "numberedList",
    "bulletedList",
    "blockquote",
    "|",
    "imageUpload",
    "emoji",
    "specialCharacters",
    "|",
    "print",
    "previewButton",
    "sourceEditing",
    "|",
    "undo",
    "redo",
  ],
  shouldNotGroupWhenFull: true,
};

export const fontSizeConfig = {
  options: ["default", 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72],
};

export const headingConfig = {
  options: [
    { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
    { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
    { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
    { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
    { model: "heading4", view: "h4", title: "Heading 4", class: "ck-heading_heading4" },
    { model: "heading5", view: "h5", title: "Heading 5", class: "ck-heading_heading5" },
    { model: "heading6", view: "h6", title: "Heading 6", class: "ck-heading_heading6" },
  ],
};

export const styleConfig = {
  definitions: [
    {
      name: "Article category",
      element: "h3",
      classes: ["category"],
    },
    {
      name: "Title",
      element: "h2",
      classes: ["document-title"],
    },
    {
      name: "Subtitle",
      element: "h3",
      classes: ["document-subtitle"],
    },
    {
      name: "Info box",
      element: "p",
      classes: ["info-box"],
    },
    {
      name: "Side quote",
      element: "blockquote",
      classes: ["side-quote"],
    },
    {
      name: "Marker",
      element: "span",
      classes: ["marker"],
    },
    {
      name: "Spoiler",
      element: "span",
      classes: ["spoiler"],
    },
    {
      name: "Code (dark)",
      element: "pre",
      classes: ["fancy-code", "fancy-code-dark"],
    },
    {
      name: "Code (bright)",
      element: "pre",
      classes: ["fancy-code", "fancy-code-bright"],
    },
  ],
};

export const emojiConfig = {
  definitionsUrl: "cdn",
};

export const htmlConfig = {
  allow: [
    {
      name: /.*/,
      attributes: true,
      classes: true,
      styles: true
    }
  ]
}

export const fontColorConfig = {
  colors: [
    { color: '#F0F8FF', label: 'AliceBlue' },
    { color: '#FAEBD7', label: 'AntiqueWhite' },
    { color: '#00FFFF', label: 'Aqua' },
    { color: '#7FFFD4', label: 'Aquamarine' },
    { color: '#F0FFFF', label: 'Azure' },
    { color: '#F5F5DC', label: 'Beige' },
    { color: '#FFE4C4', label: 'Bisque' },
    { color: '#000000', label: 'Black' },
    { color: '#FFEBCD', label: 'BlanchedAlmond' },
    { color: '#0000FF', label: 'Blue' },
    { color: '#8A2BE2', label: 'BlueViolet' },
    { color: '#A52A2A', label: 'Brown' },
    { color: '#DEB887', label: 'BurlyWood' },
    { color: '#5F9EA0', label: 'CadetBlue' },
    { color: '#7FFF00', label: 'Chartreuse' },
    { color: '#D2691E', label: 'Chocolate' },
    { color: '#FF7F50', label: 'Coral' },
    { color: '#6495ED', label: 'CornflowerBlue' },
    { color: '#FFF8DC', label: 'Cornsilk' },
    { color: '#DC143C', label: 'Crimson' },
    { color: '#00FFFF', label: 'Cyan' },
    { color: '#00008B', label: 'DarkBlue' },
    { color: '#008B8B', label: 'DarkCyan' },
    { color: '#B8860B', label: 'DarkGoldenRod' },
    { color: '#A9A9A9', label: 'DarkGray' },
    { color: '#A9A9A9', label: 'DarkGrey' },
    { color: '#006400', label: 'DarkGreen' },
    { color: '#BDB76B', label: 'DarkKhaki' },
    { color: '#8B008B', label: 'DarkMagenta' },
    { color: '#556B2F', label: 'DarkOliveGreen' },
    { color: '#FF8C00', label: 'DarkOrange' },
    { color: '#9932CC', label: 'DarkOrchid' },
    { color: '#8B0000', label: 'DarkRed' },
    { color: '#E9967A', label: 'DarkSalmon' },
    { color: '#8FBC8F', label: 'DarkSeaGreen' },
    { color: '#483D8B', label: 'DarkSlateBlue' },
    { color: '#2F4F4F', label: 'DarkSlateGray' },
    { color: '#2F4F4F', label: 'DarkSlateGrey' },
    { color: '#00CED1', label: 'DarkTurquoise' },
    { color: '#9400D3', label: 'DarkViolet' },
    { color: '#FF1493', label: 'DeepPink' },
    { color: '#00BFFF', label: 'DeepSkyBlue' },
    { color: '#696969', label: 'DimGray' },
    { color: '#696969', label: 'DimGrey' },
    { color: '#1E90FF', label: 'DodgerBlue' },
    { color: '#B22222', label: 'FireBrick' },
    { color: '#FFFAF0', label: 'FloralWhite' },
    { color: '#228B22', label: 'ForestGreen' },
    { color: '#FF00FF', label: 'Fuchsia' },
    { color: '#DCDCDC', label: 'Gainsboro' },
    { color: '#F8F8FF', label: 'GhostWhite' },
    { color: '#FFD700', label: 'Gold' },
    { color: '#DAA520', label: 'GoldenRod' },
    { color: '#808080', label: 'Gray' },
    { color: '#808080', label: 'Grey' },
    { color: '#008000', label: 'Green' },
    { color: '#ADFF2F', label: 'GreenYellow' },
    { color: '#F0FFF0', label: 'HoneyDew' },
    { color: '#FF69B4', label: 'HotPink' },
    { color: '#CD5C5C', label: 'IndianRed' },
    { color: '#4B0082', label: 'Indigo' },
    { color: '#FFFFF0', label: 'Ivory' },
    { color: '#F0E68C', label: 'Khaki' },
    { color: '#E6E6FA', label: 'Lavender' },
    { color: '#FFF0F5', label: 'LavenderBlush' },
    { color: '#7CFC00', label: 'LawnGreen' },
    { color: '#FFFACD', label: 'LemonChiffon' },
    { color: '#ADD8E6', label: 'LightBlue' },
    { color: '#F08080', label: 'LightCoral' },
    { color: '#E0FFFF', label: 'LightCyan' },
    { color: '#FAFAD2', label: 'LightGoldenRodYellow' },
    { color: '#D3D3D3', label: 'LightGray' },
    { color: '#D3D3D3', label: 'LightGrey' },
    { color: '#90EE90', label: 'LightGreen' },
    { color: '#FFB6C1', label: 'LightPink' },
    { color: '#FFA07A', label: 'LightSalmon' },
    { color: '#20B2AA', label: 'LightSeaGreen' },
    { color: '#87CEFA', label: 'LightSkyBlue' },
    { color: '#778899', label: 'LightSlateGray' },
    { color: '#778899', label: 'LightSlateGrey' },
    { color: '#B0C4DE', label: 'LightSteelBlue' },
    { color: '#FFFFE0', label: 'LightYellow' },
    { color: '#00FF00', label: 'Lime' },
    { color: '#32CD32', label: 'LimeGreen' },
    { color: '#FAF0E6', label: 'Linen' },
    { color: '#FF00FF', label: 'Magenta' },
    { color: '#800000', label: 'Maroon' },
    { color: '#66CDAA', label: 'MediumAquaMarine' },
    { color: '#0000CD', label: 'MediumBlue' },
    { color: '#BA55D3', label: 'MediumOrchid' },
    { color: '#9370DB', label: 'MediumPurple' },
    { color: '#3CB371', label: 'MediumSeaGreen' },
    { color: '#7B68EE', label: 'MediumSlateBlue' },
    { color: '#00FA9A', label: 'MediumSpringGreen' },
    { color: '#48D1CC', label: 'MediumTurquoise' },
    { color: '#C71585', label: 'MediumVioletRed' },
    { color: '#191970', label: 'MidnightBlue' },
    { color: '#F5FFFA', label: 'MintCream' },
    { color: '#FFE4E1', label: 'MistyRose' },
    { color: '#FFE4B5', label: 'Moccasin' },
    { color: '#FFDEAD', label: 'NavajoWhite' },
    { color: '#000080', label: 'Navy' },
    { color: '#FDF5E6', label: 'OldLace' },
    { color: '#808000', label: 'Olive' },
    { color: '#6B8E23', label: 'OliveDrab' },
    { color: '#FFA500', label: 'Orange' },
    { color: '#FF4500', label: 'OrangeRed' },
    { color: '#DA70D6', label: 'Orchid' },
    { color: '#EEE8AA', label: 'PaleGoldenRod' },
    { color: '#98FB98', label: 'PaleGreen' },
    { color: '#AFEEEE', label: 'PaleTurquoise' },
    { color: '#DB7093', label: 'PaleVioletRed' },
    { color: '#FFEFD5', label: 'PapayaWhip' },
    { color: '#FFDAB9', label: 'PeachPuff' },
    { color: '#CD853F', label: 'Peru' },
    { color: '#FFC0CB', label: 'Pink' },
    { color: '#DDA0DD', label: 'Plum' },
    { color: '#B0E0E6', label: 'PowderBlue' },
    { color: '#800080', label: 'Purple' },
    { color: '#663399', label: 'RebeccaPurple' },
    { color: '#FF0000', label: 'Red' },
    { color: '#BC8F8F', label: 'RosyBrown' },
    { color: '#4169E1', label: 'RoyalBlue' },
    { color: '#8B4513', label: 'SaddleBrown' },
    { color: '#FA8072', label: 'Salmon' },
    { color: '#F4A460', label: 'SandyBrown' },
    { color: '#2E8B57', label: 'SeaGreen' },
    { color: '#FFF5EE', label: 'SeaShell' },
    { color: '#A0522D', label: 'Sienna' },
    { color: '#C0C0C0', label: 'Silver' },
    { color: '#87CEEB', label: 'SkyBlue' },
    { color: '#6A5ACD', label: 'SlateBlue' },
    { color: '#708090', label: 'SlateGray' },
    { color: '#708090', label: 'SlateGrey' },
    { color: '#FFFAFA', label: 'Snow' },
    { color: '#00FF7F', label: 'SpringGreen' },
    { color: '#4682B4', label: 'SteelBlue' },
    { color: '#D2B48C', label: 'Tan' },
    { color: '#008080', label: 'Teal' },
    { color: '#D8BFD8', label: 'Thistle' },
    { color: '#FF6347', label: 'Tomato' },
    { color: '#40E0D0', label: 'Turquoise' },
    { color: '#EE82EE', label: 'Violet' },
    { color: '#F5DEB3', label: 'Wheat' },
    { color: '#FFFFFF', label: 'White' },
    { color: '#F5F5F5', label: 'WhiteSmoke' },
    { color: '#FFFF00', label: 'Yellow' },
    { color: '#9ACD32', label: 'YellowGreen' }
  ]
}
import React, { Component } from 'react'
import { batch, connect } from 'react-redux';
import MyReports from '../MyReports';
import Reports from '../Reports';
import CONFIG from '../../config';
import {
    toggleIsFetchingUserReports,
    fetchUserStandardReports,
    fetchUserMyReports,
    fetchEntityTypes,
    fetchUserStandardReportsLite,
    fetchUserMyReportsLite
} from '../../actions';
import FilterDrawer from '../common/FilterDrawer';
import pushMessage from '../common/PushMessage';
import { Button, Icon as NeoIcon } from "@avaya/neo-react"

const dataIndexMap = {
    name: 'name',
    type: 'filterTable.tableName',
    startCreatedTime: "startCreatedTime",
    endCreatedTime: "endCreatedTime",
    startUpdatedTime: "startUpdatedTime",
    endUpdatedTime: "endUpdatedTime",
    startLastRunTime: "startLastRunTime",
    endLastRunTime: "endLastRunTime",
};

class UserReports extends Component {
    state = {
        isStandardReport: true,
        showFilter: false,
        filterSort: { "filter": {} }
    }
    entityTypes = {}

    async componentDidMount() {
        this.entityTypes = await this.props.fetchEntityTypes();
    }

    handleCloseFilterDrawer = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }))
    }

    handleClear = async () => {
        await this.setState({ filterSort: { "filter": {} } })
        batch(async () => {
            this.props.toggleIsFetchingUserReports(true);
            !this.state.isStandardReport ? await this.props.fetchUserMyReportsLite(this.state.filterSort) : await this.props.fetchUserStandardReportsLite(this.state.filterSort);
        });
    }

    handleFilter = async (filters) => {
        let { name, type, createdTime, updatedTime, lastRunTime } = filters;
        name = name?.trim();
        // type = type?.trim();
        const isTypeEmpty = !type || (type && type.length === 0);
        const iscreatedTimeEmpty = !createdTime || (createdTime && createdTime.length === 0);
        const isupdatedTimeEmpty = !updatedTime || (updatedTime && updatedTime.length === 0);
        const islastRunTimeEmpty = !lastRunTime || (lastRunTime && lastRunTime.length === 0);
        if (!name && !type && iscreatedTimeEmpty && isupdatedTimeEmpty && islastRunTimeEmpty) {
            pushMessage(CONFIG.messageType.warning, "Please select some fields to filter")
            return;
        }
        await this.setState({
            filterSort: {
                filter: {
                    [dataIndexMap.name]: name || undefined,
                    [dataIndexMap.type]: !isTypeEmpty ? (typeof type === 'string' ? type?.trim() : type.join(CONFIG.delimiters.selectFilter)) : undefined,
                    [dataIndexMap.startCreatedTime]: !iscreatedTimeEmpty ? this.getUTCTime(createdTime[0]) : undefined,
                    [dataIndexMap.endCreatedTime]: !iscreatedTimeEmpty ? this.getUTCTime(createdTime[1]) : undefined,
                    [dataIndexMap.startUpdatedTime]: !isupdatedTimeEmpty ? this.getUTCTime(updatedTime[0]) : undefined,
                    [dataIndexMap.endUpdatedTime]: !isupdatedTimeEmpty ? this.getUTCTime(updatedTime[1]) : undefined,
                    [dataIndexMap.startLastRunTime]: !islastRunTimeEmpty ? this.getUTCTime(lastRunTime[0]) : undefined,
                    [dataIndexMap.endLastRunTime]: !islastRunTimeEmpty ? this.getUTCTime(lastRunTime[1]) : undefined,
                }
            }
        })

        batch(async () => {
            this.props.toggleIsFetchingUserReports(true);
            !this.state.isStandardReport ? await this.props.fetchUserMyReportsLite(this.state.filterSort) : await this.props.fetchUserStandardReportsLite(this.state.filterSort);
        });
    }

    getUTCTime = (time) => {
        const { timeZone } = this.props;
        return time.tz(timeZone, true).toISOString();
    }

    render() {
        const filtered = Object.keys(JSON.parse(JSON.stringify(this.state.filterSort?.filter)))?.length > 0
        const drawerContainer = document.getElementById('#report-component-content');
        const { timeZone } = this.props;
        const formFields = [
            {
                label: "Report Name",
                type: CONFIG.formFieldTypes.INPUT,
                key: "name",
                colSpan: 24,
                value: this.state.filterSort?.filter?.name
            },
            {
                label: "Report Type",
                type: this.entityTypes?.length > 0 ? CONFIG.formFieldTypes.SELECT : CONFIG.formFieldTypes.INPUT,
                key: "type",
                mode: "tags",
                valueKey: "text",
                data: (this.entityTypes?.length > 0) ? this.entityTypes.map(entityType => ({ text: entityType.tableName })) : [],
                colSpan: 24,
                value: this.state.filterSort?.filter?.[dataIndexMap?.type]
            },
            {
                label: "Created Time",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "createdTime",
                colSpan: 24,
                value: [this.state.filterSort?.filter?.startCreatedTime, this.state.filterSort?.filter?.endCreatedTime],
            },
            {
                label: "Updated Time",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "updatedTime",
                colSpan: 24,
                value: [this.state.filterSort?.filter?.startUpdatedTime, this.state.filterSort?.filter?.endUpdatedTime]
            },
            {
                label: "Last Run Time",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "lastRunTime",
                colSpan: 24,
                value: [this.state.filterSort?.filter?.startLastRunTime, this.state.filterSort?.filter?.endLastRunTime]
            }
        ]
        return (
            <div>
                <span className='dashboard-schedules-category-heading'>Reports</span>
                <div className='width-100percent'>
                    <Button aria-label="Standard Reports" className={this.state.isStandardReport && "neo-button-selected"} variant="secondary" size="compact" onClick={() => this.setState({ isStandardReport: true })} >Standard Reports</Button>

                    <Button aria-label="My Reports" className={!this.state.isStandardReport ? "neo-button-selected left-spacing-3" : "left-spacing-3"} variant="secondary" size="compact" onClick={() => this.setState({ isStandardReport: false })}>My Reports</Button>

                    <NeoIcon aria-label="menu-aria" title="Refresh" className='clickable float-right left-spacing-3' icon="refresh" onClick={() => this.handleClear()} style={{ fontSize: "23px", color: '#0b67bd' }} />

                    <NeoIcon aria-label="menu-aria" className="clickable float-right" onClick={() => this.setState(prevState => ({
                        showFilter: !prevState.showFilter
                    }))} title="Filter" icon={filtered ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                </div>
                <div>
                    {this.state.isStandardReport ? <Reports filterSort={this.state.filterSort} /> : <MyReports filterSort={this.state.filterSort} />}
                    {this.state.showFilter && (
                        <FilterDrawer
                            visible={this.state.showFilter}
                            width='35%'
                            handleCloseFilterDrawer={this.handleCloseFilterDrawer}
                            placement="right"
                            drawerContainer={drawerContainer}
                            style={{ position: 'absolute', zIndex: 9999, }}
                            maskClosable={false}
                            panelHeader="Filters and Export"
                            formFields={formFields}
                            handleClear={this.handleClear}
                            handleFilter={this.handleFilter}
                            timeZone={timeZone}
                            hideSoftDeleteCheckbox={true}
                            hideApplyToAll={true}
                        />)}
                </div>
            </div>
        )
    }

}
const mapStateToProps = ({ userReports, user }) => {
    return {
        isFetching: userReports.isFetching,
        timeZone: user.profile.timezone
    }
}

export default connect(mapStateToProps, {
    toggleIsFetchingUserReports,
    fetchUserStandardReports,
    fetchUserMyReports,
    fetchEntityTypes,
    fetchUserStandardReportsLite,
    fetchUserMyReportsLite
})(UserReports);
import React, { useEffect, useState } from 'react'
import Loader from './common/Loader';
import { Button, Form as NeoForm, Icon, Notification } from 'neo-latest';
import { Form, Divider, Tooltip, Spin } from "antd"
import CONFIG from '../config';
import { TextInput } from "@avaya/neo-react"
import moment from 'moment-timezone';
import { getScheduleDetailsForSFDC, validateOpportunityNumberForExternalUser, updateScheduleForExternalUser } from '../actions';
import { useDispatch } from 'react-redux';
import pushMessage from './common/PushMessage';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function SFDCPage(props) {
    const [loading, setLoading] = useState(true);
    const [isValidOpp, setValidOpp] = useState(false);
    const [isValidUrl, setValidUrl] = useState(true);
    const [isErr, setErr] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [data, setData] = useState({});
    const [oppData, setOppData] = useState({});
    const [retryCount, setRetryCount] = useState(3);
    const [customerName, setCustomerName] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const { form } = props;
    const { getFieldDecorator, setFieldsValue } = form;
    const dispatch = useDispatch();
    const opportunityNumberPattern = /^000\d{7}$/;
    const sfdcURL = sessionStorage.getItem("sfdcURL");
    const scheduleId = sfdcURL.toString().split("/").slice(-2)[0];
    const token = sfdcURL.substring(sfdcURL.lastIndexOf('/') + 1);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 4000);
        getScheduleDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        //eslint-disable-next-line
    }, []);


    const getScheduleDetails = async () => {
        try {
            const response = await dispatch(getScheduleDetailsForSFDC(scheduleId, token));
            if (response && (response?.status === CONFIG.HTTP_STATUS.OK || response?.status === CONFIG.HTTP_STATUS.NO_CONTENT)) setValidUrl(true);
            setData(response?.data || {})
        }
        catch (error) {
            setValidUrl(false);
        }
    }

    const renderLinkExpired = () => {
        return (
            <div className="neo-alert-notification" style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                <Notification
                    description={<div>
                        Your link has expired. Please contact the Administrator.
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button aria-label="support" onClick={() => window.open(process.env.REACT_APP_SUPPORT_URL)} animation="none" size="default" status="default" >Contact Support</Button>
                        </div>
                    </div>}
                    header="Expired Link"
                    type="alert"
                    showTimestamp={false}
                    isElevated={true}
                />
            </div>
        )
    }

    const renderSuccessNotification = () => {
        return (
            <div className="neo-alert-notification" style={{ marginTop: "16px", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "50%" }}>
                    <Notification
                        description="Thank you for providing the opportunity number. Your session will now be closed."
                        header="Notification!"
                        type="success"
                        showTimestamp={false}
                        isElevated={true}
                    />
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (retryCount === 0 && !(oppData?.opportunity?.id)) {
            setValidUrl(false);
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        //eslint-disable-next-line
    }, [retryCount])

    const handleOppNumberChange = async (event) => {
        const opportunityNumber = event.target.value;
        setFieldsValue({ customerName: "-" });
        setValidOpp(false);
        setCustomerName("-");
        if (opportunityNumber.length > 10) {
            setErr(true);
            setErrorMessage("Opportunity number must not exceed 10 digits");
            setFieldsValue({ customerName: "-" });
            setIsFetching(false);
            return;
        }
        else if (opportunityNumber.length > 2 && opportunityNumber.slice(0, 3) !== "000") {
            setErr(true);
            setErrorMessage("Opportunity number must begin with 000")
        }
        else {
            setErr(false);
            setErrorMessage("");
            if (opportunityNumberPattern.test(opportunityNumber)) {
                setIsFetching(true);
                try {
                    const customerInfo = await dispatch(validateOpportunityNumberForExternalUser(opportunityNumber, scheduleId));
                    setOppData(customerInfo);
                    setValidOpp(customerInfo?.status === "Success");
                    if (customerInfo?.status === "Success") {
                        setCustomerName(customerInfo?.opportunity?.AccountName);
                        setFieldsValue({ customerName: customerInfo?.opportunity?.AccountName });
                        setErr(false);
                        setErrorMessage("")
                    }
                    else {
                        setFieldsValue({ customerName: "-" });
                        setErr(true);
                        setCustomerName("-");
                        setErrorMessage("Invalid Opportunity Number Entered")
                    }
                }
                catch (error) {
                    setValidUrl(false);
                }
                await setIsFetching(false);
                await setRetryCount(prevCount => prevCount - 1);
            }
        }
    };

    const handleScheduleSave = () => {
        form.validateFields(async (err, values) => {
            if (err || values?.customerName === "-") {
                pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                setErr(true);
                setErrorMessage("Invalid Opportunity Number Entered")
                return
            }
            else {
                const scheduleData = {
                    scheduleId: parseInt(scheduleId),
                    opportunityNumber: oppData?.opportunity?.otnNumber,
                    sfdcOpportunityId: oppData?.opportunity?.id,
                    customerName: oppData?.opportunity?.AccountName,
                    validatedBy: data?.opportunity?.accountOwnerEmail
                }
                setIsFetching(true);
                const response = await dispatch(updateScheduleForExternalUser(scheduleId, scheduleData, token));
                setIsFetching(false);
                if (response) {
                    setShowSuccess(true);
                    sessionStorage.removeItem("sfdcURL");
                    sessionStorage.removeItem("isSFDCRedirection");
                    sessionStorage.clear();
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500)
                }
            }
        })
    }

    return (
        <div style={{ height: "100%" }}>
            {loading && <Loader />}
            <div style={{ borderBottom: "1px solid #c9c9c9" }}>
                <div className='brand-logo' style={{ height: "58px", display: "flex", alignItems: "center", marginLeft: "20px", }}>
                    <div style={{ display: "flex", flex: "1" }}>
                        <div style={{ display: "flex" }}>
                            <svg width="100" height="28" viewBox="0 0 100 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M61.9861 0.111084L69.1111 16.3055L76.8472 0.111084H80.9028L66.9861 27.8889H63.0972L67.0972 20.3055L57.9167 0.111084H61.9861ZM89.9167 0.111084L100 20.3055H95.9305L88.5 4.70831L83.8333 14.5694H90.6528L91.7639 17.2361H82.5417L81.0833 20.3055H77.0139L87.0972 0.111084H89.9167ZM12.9028 0.111084L22.9861 20.3055H18.9306L11.5 4.70831L6.81944 14.5694H13.6389L14.75 17.2361H5.54167L4.06944 20.3055H0L10.0833 0.111084H12.9028ZM51.2639 0.111084L61.3472 20.3055H57.2778L49.8611 4.70831L45.1806 14.5694H52.0139L53.1111 17.2361H43.9028L42.4306 20.3055H38.3611L48.4444 0.111084H51.2639ZM23.25 0.111084L30.6806 16L38.1111 0.111084H42.1806L32.0972 20.3055H29.2778L19.1944 0.111084H23.25Z" fill="#DA291C" />
                            </svg>
                        </div>
                        <div>
                            <span style={{ verticalAlign: "middle", marginLeft: "24px" }} className="size-14px">{CONFIG.brandName}</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", flex: "1", justifyContent: "flex-end", marginRight: "16px" }}>
                        <Button onClick={() => {
                            sessionStorage.removeItem("sfdcURL");
                            sessionStorage.removeItem("isSFDCRedirection");
                            sessionStorage.clear();
                            setTimeout(() => {
                                window.location.reload();
                            }, 500)
                        }}>Close</Button>
                    </div>
                </div>
            </div>

            <div style={{ background: "#f1f1f1", padding: "8px", height: "100%", overflowY: "scroll" }}>
                <div style={{ padding: "8px", background: "#fff", height: 'calc(100dvh - 75px)', overflowY: "scroll", border: "1px solid #C9C9C9", borderRadius: "0px 4px 4px 4px" }}>
                    {showSuccess ? renderSuccessNotification() : !isValidUrl ? renderLinkExpired() :
                        <Spin spinning={isFetching} indicator={antIcon}>
                            <div style={{ margin: "16px" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: "#242424", fontSize: "19px", lineHeight: "28px", display: "flex", flex: "1", justifyContent: "flex-start" }}>
                                        Demo Schedule Details
                                    </div>
                                    <div style={{ display: "flex", flex: "1", justifyContent: "flex-end" }}>
                                        <Button disabled={!isValidOpp} onClick={handleScheduleSave}>Save</Button>
                                    </div>
                                </div>
                                <div style={{ marginTop: "16px" }}>
                                    <div style={{ minHeight: "216px", border: "1px solid #c9c9c9", background: "#f1f1f1", borderRadius: "4px" }}>
                                        <div style={{ margin: "16px" }}>
                                            <div style={{ display: 'flex', gap: "8px" }}>
                                                <div style={{ flex: "1" }}>
                                                    <TextInput id="bold-title" label="Demo Title" value={(data?.demo?.title || "-")} readOnly />
                                                </div>
                                                <div style={{ flex: "1" }}>
                                                    <TextInput id="bold-title" label="Schedule Created By" value={(data?.createdBy?.fullName || "-")} readOnly />
                                                </div>
                                                <div style={{ flex: "1" }}>
                                                    <TextInput id="bold-title" label="Schedule Created Time" value={data?.createdTime ? moment.tz(data?.createdTime, null).format(CONFIG.dateFormats.userDateTime) : "-"} readOnly />
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', gap: "8px", marginTop: "16px" }}>
                                                <div style={{ flex: "1" }}>
                                                    <TextInput id="bold-title" label="Schedule Start Time" value={data?.startTime ? moment.tz(data?.startTime, null).format(CONFIG.dateFormats.userDateTime) : "-"} readOnly />
                                                </div>
                                                <div style={{ flex: "1" }}>
                                                    <TextInput id="bold-title" label="Schedule End Time" value={data?.endTime ? moment.tz(data?.endTime, null).format(CONFIG.dateFormats.userDateTime) : "-"} readOnly />
                                                </div>
                                                <div style={{ flex: "1" }}>
                                                    <TextInput id="bold-title" label="Purpose" value={data?.purpose || "-"} readOnly />
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', gap: "8px", marginTop: "16px" }}>
                                                <div style={{ flex: "1" }}>
                                                    <TextInput id="bold-title" label="SFDC Account Owner" value={data?.opportunity?.accountOwnerEmail || "-"} readOnly />
                                                </div>
                                                <div style={{ flex: "1" }}>
                                                    <TextInput id="bold-title" label="Schedule Updated By" value={(data?.updatedBy?.fullName || "-")} readOnly />
                                                </div>
                                                <div style={{ flex: "1" }}>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "16px" }}>
                                                <div style={{ fontSize: "12px", fontWeight: "400", color: "#323232", lineHeight: "16px", }}>
                                                    Notes by Scheduler:
                                                </div>
                                                <div style={{ fontSize: "14px", fontWeight: "400", color: "#000", lineHeight: "20px" }}>
                                                    {(data?.notes || "-")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ margin: "16px 0" }}>
                                    <Divider style={{ marginBottom: "0px" }} />
                                </div>
                                <div style={{ color: "#242424", fontSize: "16px", lineHeight: "24px", marginBottom: "16px" }}>
                                    Please enter the Opportunity Number
                                </div>
                                <div style={{ margin: "16px 0" }}>
                                    <Form>
                                        <NeoForm>
                                            <div style={{ display: "flex", gap: "24px" }}>
                                                <div style={{ flex: "1" }}>
                                                    <Form.Item>
                                                        {
                                                            getFieldDecorator("oppNo")(
                                                                <TextInput
                                                                    maxLength={10}
                                                                    error={isErr}
                                                                    helperText={errorMessage?.length > 0 ? errorMessage : null}
                                                                    onChange={handleOppNumberChange} label="Opportunity Number" required />
                                                            )
                                                        }
                                                    </Form.Item>
                                                </div>
                                                <div style={{ flex: "3" }}>
                                                    <Form.Item>
                                                        {
                                                            getFieldDecorator("customerName", { initialValue: customerName })(
                                                                <TextInput label={<span style={{ color: "black", pointerEvents: "all" }}>Customer Name <span>
                                                                    <Tooltip placement='topLeft' title="The name will be automatically filled in when the correct Opportunity Number is entered.">
                                                                        <Icon aria-label="aria-menu" icon="info" />
                                                                    </Tooltip>
                                                                </span>
                                                                </span>}
                                                                    required
                                                                    value={customerName} readOnly defaultValue={"-"} />
                                                            )
                                                        }
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </NeoForm>
                                    </Form>
                                </div>
                                {!(oppData?.opportunity?.id) && <div style={{ width: "50%", marginTop: "32px" }}>
                                    <Notification
                                        description={`You have ${retryCount} attempt(s) to provide the opportunity number. After ${retryCount} attempt(s), this operation will be blocked.`}
                                        header="Warning!"
                                        type="warning"
                                        showTimestamp={false}
                                        isElevated={true}
                                    />
                                </div>}
                            </div>
                        </Spin>
                    }
                </div>
            </div>

        </div>
    )
}

const SFDCPageForm = Form.create()(SFDCPage);

export default SFDCPageForm;
import React from 'react';
import { Rate, Col, Row, Radio, Dropdown, Spin } from 'antd';
import { connect } from 'react-redux';
import { Button, Icon } from "@avaya/neo-react";
import { LoadingOutlined } from '@ant-design/icons';

import {
    fetchMostScheduledDemos,
    openLinkInNewTab,
    clearMostUsedDemos,
    toggleIsFetchingTrendingDemos,
    showUserDashboardMostScheduledDemosSection
} from '../../actions';
import CONFIG from '../../config'
import moment from 'moment-timezone';
import DemoRegistrationModal from '../common/DemoRegistrationModal';
class DashboardMostUsedDemos extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedDuration: CONFIG.mostPolularDemosTimeline[0],
            registrationModalVisible: false,
            demoRequestData: null
        }
    }

    handleDemoRequest = (record) => {
        this.setState({
            demoRequestData: record,
            registrationModalVisible: true
        })
    }

    getDemosForSelectedDuration = () => {
        const { mostScheduledDemos } = this.props;
        const { selectedDuration } = this.state;
        if (selectedDuration === CONFIG.mostPolularDemosTimeline[0]) return mostScheduledDemos?.mostPopularDemosInLast3Days;
        else if (selectedDuration === CONFIG.mostPolularDemosTimeline[1]) return mostScheduledDemos?.mostPopularDemosInLast7Days;
        else return mostScheduledDemos?.mostPopularDemosInLast14Days;
    }

    renderSolutionList = () => {
        const top5Demos = this.getDemosForSelectedDuration();
        const { isRatingEnabled } = this.props;

        return top5Demos ? top5Demos?.map((eachSolution) => {
            const { isDemoAlreadyRequested, showRequestButton, showScheduleButton } = eachSolution?.demo || {};
            const buttonText = isDemoAlreadyRequested
                ? "Request Pending"
                : showRequestButton
                    ? "Request"
                    : "Schedule";

            return (
                <div>
                    <Row className="dashboard-most-used-demo-card">
                        <Col className='dashboard-most-used-demo-card-solution-type' span={isRatingEnabled && eachSolution?.demo?.rating > 0 ? 16 : 24}>{eachSolution?.solutionType?.name}</Col>
                        <Col className='float-right' span={8}>{isRatingEnabled && eachSolution?.demo?.rating > 0 && <Rate
                            className='dashboard-schedule-more-options'
                            style={{ color: Math.round(eachSolution?.demo?.rating) <= 2 ? "#ff4545" : "#f7a032" }}
                            disabled allowHalf value={eachSolution?.demo?.rating} />}</Col>

                        <div>
                            <span className='dashboard-most-used-demo-title' >{eachSolution?.demo?.title}</span>
                            <div style={{ fontSize: "12px", paddingTop: "8px", paddingBottom: "8px" }}>
                                {eachSolution?.demo?.updatedTime !== null && <><span className="bold">Demo Last Updated: </span>
                                    <span >{moment.tz(eachSolution?.demo?.updatedTime, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)}</span></>}
                            </div>

                            <div className='dashboard-most-used-button-group' >
                                <span style={{ paddingRight: "10px" }}><Button onClick={() => this.props.openLinkInNewTab(eachSolution?.demo?.title, `/solutions/demo/${eachSolution?.demo?.id}`)} animation="none" size="compact" status="default" variant="secondary">Details</Button></span>
                                <span><Button
                                    onClick={
                                        () => {
                                            if (!!showScheduleButton) {
                                                this.props.openLinkInNewTab(`Schedule Demo - ${eachSolution?.demo?.title}`, eachSolution?.demo?.demoType === CONFIG.demoTypes.standardDemo ? `/scheduler/standard/${eachSolution?.demo?.id}/new` : `/scheduler/lab/${eachSolution?.demo?.id}/new`)
                                                // history.replace({
                                                //     pathname: eachSolution?.demo?.demoType === CONFIG.demoTypes.standardDemo ? `/scheduler/standard/${eachSolution?.demo?.id}/new` : `/scheduler/lab/${eachSolution?.demo?.id}/new`,
                                                // })
                                            }
                                            else if (!!showRequestButton) {
                                                this.handleDemoRequest(eachSolution)
                                            }
                                            else return null
                                        }}
                                    disabled={eachSolution?.demo?.isDemoAlreadyRequested}
                                    animation="none"
                                    size="compact"
                                    status="info">
                                    {buttonText}
                                </Button></span>
                            </div>
                        </div>
                    </Row>
                </div>
            )
        })
            : null

    }

    async componentDidMount() {
        await this.setState({
            selectedDuration: this.props.mostScheduledDemos?.mostPopularDemosInLast3Days?.length > 0 ? CONFIG.mostPolularDemosTimeline[0]
                : this.props.mostScheduledDemos?.mostPopularDemosInLast7Days?.length > 0 ? CONFIG.mostPolularDemosTimeline[1]
                    : CONFIG.mostPolularDemosTimeline[2]
        })


    }

    componentWillUnmount() {
        this.props.clearMostUsedDemos();
    }

    handleSelect = async (event) => {
        await this.setState({ selectedDuration: event.target.value });
    }

    getStartDate = (type) => {
        const { timeZoneName } = this.props;
        switch (type) {
            case CONFIG.mostUsedDemosTimeline[0]:
                return moment.tz(moment(), timeZoneName).subtract(30, 'days').startOf('day').toISOString();
            case CONFIG.mostUsedDemosTimeline[1]:
                return moment.tz(moment(), timeZoneName).subtract(6, 'months').startOf('month').startOf('day').toISOString();
            case CONFIG.mostUsedDemosTimeline[2]:
                return moment.tz(moment(), timeZoneName).subtract(365, 'days').startOf('day').toISOString();
            default:
                return moment.tz(moment(), timeZoneName).subtract(30, 'days').startOf('day').toISOString();
        }
    }

    handleDemoRequestModalClose = () => {
        this.setState({ demoRequestData: null, registrationModalVisible: false })
    }

    handleDemoRequestSubmit = () => {
        this.handleDemoRequestModalClose();
        this.props.toggleIsFetchingTrendingDemos(true);
        this.props.clearMostUsedDemos();
        this.props.fetchMostScheduledDemos();
    }

    render() {
        const { registrationModalVisible, demoRequestData } = this.state;
        const { mostScheduledDemos } = this.props
        const shouldRenderSection = (mostScheduledDemos?.mostPopularDemosInLast14Days && mostScheduledDemos?.mostPopularDemosInLast14Days?.length > 0);
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        const menu = (
            <div className='most-schedule-demos-filter'>
                <Radio.Group onChange={this.handleSelect} value={this.state.selectedDuration}>
                    {(mostScheduledDemos?.mostPopularDemosInLast3Days?.length > 0) && <Radio className="font-13px" style={radioStyle} value={CONFIG.mostPolularDemosTimeline[0]}>{CONFIG.mostPolularDemosTimeline[0]}</Radio>}
                    {(mostScheduledDemos?.mostPopularDemosInLast7Days?.length > 0) && <Radio className="font-13px" style={radioStyle} value={CONFIG.mostPolularDemosTimeline[1]}>{CONFIG.mostPolularDemosTimeline[1]}</Radio>}
                    {(mostScheduledDemos?.mostPopularDemosInLast14Days?.length > 0) && <Radio className="font-13px" style={radioStyle} value={CONFIG.mostPolularDemosTimeline[2]}>{CONFIG.mostPolularDemosTimeline[2]}</Radio>}
                </Radio.Group>
            </div>
        );

        return (<>
            {shouldRenderSection && <div>
                <Row className='dashboard-category-heading-with-extra'>
                    <span className='dashboard-schedules-category-heading'>Most Popular Demos</span>
                    <span className='dashboard-category-extra' style={{ fontWeight: "500 !important", fontFamily: 'Noto Sans', color: '#242424' }}>
                        <Dropdown overlay={menu}>
                            <Icon aria-label="aria-menu"
                                className="btn-icon-test another-class"
                                icon="filter-filled"
                                size="sm"
                            />
                        </Dropdown>
                    </span>
                </Row>
                <div className={this.props.isLoading && 'dashboard-used-demo-div'}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.props.isLoading}>
                        {this.renderSolutionList()}
                    </Spin>
                </div>
                {
                    registrationModalVisible &&
                    <DemoRegistrationModal
                        visible={registrationModalVisible}
                        handleDemoRequestSubmit={this.handleDemoRequestSubmit}
                        demo={demoRequestData?.demo}
                        isEdit={false}
                        rawData={demoRequestData}
                        handleCancel={this.handleDemoRequestModalClose}
                    />
                }
            </div>}
        </>
        );
    }
}

const mapStateToProps = ({ user, mostScheduledDemos, trendingDemos }) => {
    return {
        mostScheduledDemos: mostScheduledDemos.mostScheduledDemos,
        user: user.profile,
        isLoading: trendingDemos?.isLoading,
        isRatingEnabled: user.isRatingEnabled,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchMostScheduledDemos,
        clearMostUsedDemos,
        toggleIsFetchingTrendingDemos,
        showUserDashboardMostScheduledDemosSection,
        openLinkInNewTab
    }
)(DashboardMostUsedDemos);
import React from "react";
import { connect } from "react-redux";
import { Row, Dropdown, Tooltip, Spin, message, Tabs } from 'antd';
import CONFIG from "../../config";
import {
    selectTitle,
    toggleBackButton,
    fetchAllDemoFeedbackQuestions,
    currentFeedbackQuestionsFilter,
    toggleIsFetchingFeedbackQuestions,
    deleteFeedbackQuestion,
    restoreFeedbackQuestion,
    clearFeedbackQuestions,
    exportTable,
    refreshFeedbackQuestions,
    handleOnClickAction,
    fetchLiteDemoQuestionsOrFeedbackQuestions
} from "../../actions";
import pushMessage from "../common/PushMessage";
import openConfirm from '../common/Confirm';
import { fetchNamesBySearchString } from '../../actions/userRole'
import { exportMenu } from "../../utils/strings";
import { isTabAlreadyOpen } from "../common/TabLayout";
import throttle from 'lodash/throttle';
import { LoadingOutlined } from "@ant-design/icons";
import { Table as NeoTable, Icon as NeoIcon, Button as NeoButton } from "neo-latest";
import { checkIfFilterSortIsEmpty } from "../../utils/formChanged.js";
import { otherFields } from "../../utils/neoTableFields";
import FilterDrawer from "../common/FilterDrawer";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;

const dataIndexMap = {
    title: 'title',
    createdBy: 'createdBy.fullName',
    createdTime: 'createdTime',
    updatedBy: 'updatedBy.fullName',
    updatedTime: 'updatedTime',
    status: 'isActive',
    viewDeletedItems: 'viewDeletedItems'
};

class ManageFeedbackQuestions extends React.Component {

    constructor(props) {
        super(props)

        this.handleClear = throttle(this.handleClear, 1000);

        this.state = {
            status: "true",
            matchedUsersNames: [],
            searchString: [],
            hasEnteredString: false,
            showFilter: false,
            questionType: "feedback",
            currentPage: 0,
            currentPath: null
        }
    }

    isViewDeletedItemsChecked = undefined;
    filterSort = {};
    feedbackQuestionId = undefined;

    fetchUsersName = searchString => {
        this.setState({ matchedUsersNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    this.setState({ matchedUsersNames: [...new Set(result.map(item => item.fullName))] })
                }
                this.setState({ hasEnteredString: false })
            });
    };

    handleChange = (_, __, sorter) => {
        this.filterSort = {
            ...this.filterSort,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }
        this.props.fetchLiteDemoQuestionsOrFeedbackQuestions({ filterSort: this.filterSort, status: this.state.status, questionType: this.state.questionType });
    };

    handleStatusChange = (value) => {
        this.setState({ status: value })
        this.props.toggleIsFetchingFeedbackQuestions(true);
        this.props.fetchLiteDemoQuestionsOrFeedbackQuestions({ filterSort: this.filterSort, status: value, questionType: this.state.questionType });
    }

    filterRecords = async (filters) => {
        let { title, viewDeletedItems, updatedBy, createdBy } = filters;
        title = title?.trim();
        const status = !viewDeletedItems ? this.state.status : undefined;
        if (!title && !viewDeletedItems && !updatedBy && !createdBy) {
            pushMessage(CONFIG.messageType.warning, "Please select some fields to filter");
            return;
        }
        this.titleSearchString = title;
        this.filterSort = {
            filter: {
                title,
                viewDeletedItems,
                [dataIndexMap.updatedBy]: updatedBy || undefined,
                [dataIndexMap.createdBy]: createdBy || undefined,
            }
        }
        this.props.toggleIsFetchingFeedbackQuestions(true);
        await this.props.fetchLiteDemoQuestionsOrFeedbackQuestions({ filterSort: this.filterSort, status: status, questionType: this.state.questionType });
    }

    handleFilter = (filters) => {
        let { viewDeletedItems } = filters;
        if (viewDeletedItems || this.isViewDeletedItemsChecked) {
            this.isViewDeletedItemsChecked = viewDeletedItems
            this.setState({ status: "false" });
            this.filterRecords(filters)
        }
        else this.filterRecords(filters);
    };

    handleClear = async (refresh) => {
        if (refresh) {
            this.props.refreshFeedbackQuestions();
        }
        else {
            this.titleSearchString = undefined;
            this.filterStatusTypes = undefined;
            this.filterSort = {}
        }
        this.props.toggleIsFetchingFeedbackQuestions(true);
        await this.props.fetchLiteDemoQuestionsOrFeedbackQuestions({ filterSort: this.filterSort, status: this.state.status, questionType: this.state.questionType });
        this.isViewDeletedItemsChecked = undefined;
        this.setState({ matchedUsersNames: [] })
    };



    handleExport = async (fileType) => {
        const { timeZone } = this.props;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const demoFeedbackQuestions = await this.props.fetchLiteDemoQuestionsOrFeedbackQuestions({ filterSort: this.filterSort, status: this.state.status, questionType: this.state.questionType });
        if (demoFeedbackQuestions?.length > 0 && demoFeedbackQuestions !== undefined) {
            exportTable(CONFIG.exportTable.feedbackQuestion.name, demoFeedbackQuestions, timeZone, CONFIG.exportTable.feedbackQuestion.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    handleDeleteConfirmation = async () => {
        const response = await this.props.deleteFeedbackQuestion(this.feedbackQuestionId);
        if (response) {
            this.props.toggleIsFetchingFeedbackQuestions(true);
            this.props.fetchLiteDemoQuestionsOrFeedbackQuestions({ filterSort: this.filterSort, status: this.state.status, questionType: this.state.questionType });
        }
    }

    handleRestoreConfirmation = async () => {
        const response = await this.props.restoreFeedbackQuestion(this.feedbackQuestionId);
        if (response) {
            this.props.toggleIsFetchingFeedbackQuestions(true);
            this.props.fetchLiteDemoQuestionsOrFeedbackQuestions({ filterSort: this.filterSort });
        }
    }


    handleDeleteRestore = (demoId, isRestore) => {
        this.feedbackQuestionId = demoId;
        this.renderDeleteRestoreConfirmation(isRestore)
    }

    renderDeleteRestoreConfirmation = (isRestore) => {
        const okButtonText = isRestore && "Confirm Restore"
        const modalTitle = isRestore ? CONFIG.restoreModalTitle?.replace('<placeholder>', 'feedback question') : CONFIG.deleteModalTitle?.replace('<placeholder>', 'feedback question')
        const content = <>
            {!isRestore ? <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "feedback question")}</h4> : ""}
        </>
        openConfirm(okButtonText, isRestore ? this.handleRestoreConfirmation : this.handleDeleteConfirmation, null, content, modalTitle);
    }

    async componentDidMount() {
        this.setState({ currentPath: window.location.pathname })
        const { isEdit, currentFilter, status, currentPageNumber } = this.props;
        if (isEdit) {
            this.setState({ currentPage: currentPageNumber });
            this.filterSort = currentFilter;
            if (Object.keys(JSON.parse(JSON.stringify(currentFilter)))?.length > 0) {
                const filterObject = JSON.parse(JSON.stringify(currentFilter?.filter));
                Object.keys(filterObject)?.length > 0 && this.setState({ showFilter: true })
            }
            await this.setState({ status })
        }
        this.props.toggleIsFetchingFeedbackQuestions(true);
        await this.props.fetchLiteDemoQuestionsOrFeedbackQuestions({ filterSort: this.filterSort, status: this.state.status, questionType: this.state.questionType });
        this.props.toggleIsFetchingFeedbackQuestions(false);
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentFeedbackQuestionsFilter(this.filterSort, this.state.status, this.state.currentPage);
        }
        else
            this.props.clearFeedbackQuestions();
    }

    renderFilterPanel = () => {
        return (
            <div
                style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
            >
                {
                    <div style={{ display: "flex", flex: "1", zIndex: "1", position: "relative" }}>
                        <NeoButton
                            onClick={() => {
                                this.props.handleOnClickAction({
                                    component: CONFIG.createComponentRoute.feedbackQuestions,
                                    tabTitle: "Create",
                                });
                            }}
                        >
                            Add New
                        </NeoButton>
                    </div>
                }
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        flex: "1",
                        flexDirection: "row-reverse",
                        gap: "24px",
                    }}
                >
                    <div>
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoButton
                                className="export-download-button"
                                icon="chevron-down"
                                variant="secondary"
                            >
                                Download
                            </NeoButton>
                        </Dropdown>
                    </div>
                    <div>
                        <NeoIcon aria-label="menu-aria"
                            size="md"
                            className="clickable"
                            onClick={() =>
                                this.setState((prevState) => ({
                                    showFilter: !prevState.showFilter,
                                }))
                            }
                            title="Filter"
                            icon={
                                this.state.showFilter ||
                                    !checkIfFilterSortIsEmpty({ filter: this.filterSort })
                                    ? "filter-filled"
                                    : "filter"
                            }
                            style={{ fontSize: "23px", color: "#0b67bd" }}
                        />
                    </div>
                    <div>
                        <NeoIcon aria-label="menu-aria"
                            size="md"
                            className="clickable"
                            title="Refresh"
                            icon="refresh"
                            onClick={() => this.handleClear(true)}
                            style={{ fontSize: "23px", color: "#0b67bd" }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderTabContents = () => {
        return (
            <React.Fragment>
                <div>{this.renderFilterPanel()}</div>
                <div className="height-neo-table">{this.renderTable()}</div>
            </React.Fragment>
        );
    };

    renderTable = () => {
        const { userRoleTemplate, feedbackQuestions, isFetching } = this.props;
        const deleteView = this.filterSort?.filter?.viewDeletedItems;
        this.isViewDeletedItemsChecked = deleteView;
        const neoColumns = [
            {
                Cell: (a) => {
                    return (
                        <span style={{ textAlign: "center" }}>
                            {a?.cell?.row?.original?.title}
                        </span>
                    );
                },
                Header: "Title",
                sortType: "string",
                accessor: "title",
                disableFilters: true,
            },
            {
                Cell: (a) => {
                    return (
                        <span style={{ textAlign: "center" }}>
                            {a?.cell?.row?.original?.createdBy?.fullName}
                        </span>
                    );
                },
                Header: "Created By",
                sortType: "string",
                accessor: "createdBy.fullName",
                disableFilters: true,
            },
            {
                Cell: (a) => {
                    return (
                        <span style={{ textAlign: "center" }}>
                            {
                                <NeoIcon aria-label="menu-aria"
                                    style={
                                        a?.cell?.row?.original?.isActive
                                            ? { color: "#088A08" }
                                            : { color: "#DA291C" }
                                    }
                                    icon={
                                        a?.cell?.row?.original?.isActive ? "available" : "missed"
                                    }
                                />
                            }
                        </span>
                    );
                },
                sortType: "basic",
                Header: "Status",
                accessor: "isActive",
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    const isEditDisabled = userRoleTemplate.templateType !== CONFIG.roleTypes.superAdmin;
                    return isEditDisabled ? null : (
                        <React.Fragment>
                            <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
                                {!deleteView &&
                                    <>
                                        <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                            <button
                                                onClick={() => {
                                                    this.props.handleOnClickAction({
                                                        component: CONFIG.editComponentRoute.feedbackQuestions,
                                                        tabTitle: record?.title,
                                                        recordId: record?.id
                                                    })
                                                }}
                                                disabled={isEditDisabled}
                                                className={!isEditDisabled ? "link" : "link-disabled"}>
                                                <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                            </button>
                                        </Tooltip>

                                    </>
                                }
                                {!deleteView && <>
                                    <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                        <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleDeleteRestore(record.id)}>
                                            <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                </>
                                }
                                {deleteView &&
                                    <>

                                        <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleDeleteRestore(record.id, true)}>
                                            <span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </>
                                }
                            </div>
                        </React.Fragment>
                    );
                },
                Header: "  ",
                disableFilters: true,
                disableSortBy: true,
            },
        ];

        return isFetching ? (
            <Spin
                style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                spinning={isFetching}
                indicator={antIcon}
            />
        ) : (
            <NeoTable
                columns={neoColumns}
                data={feedbackQuestions || []}
                className="table-actions"
                allowColumnFilter={false}
                itemsPerPageOptions={[10, 20, 50, 100]}
                showRowSeparator
                initialStatePageIndex={this.state.currentPage}
                handlePageChange={(newPageIndex) => {
                    if (newPageIndex >= 0) this.setState({ currentPage: newPageIndex })
                }}
                {...otherFields}
            />
        );
    };

    handleCloseFilterDrawer = () => {
        this.setState((prevState) => ({
            showFilter: !prevState.showFilter,
        }));
    };

    render() {

        const { timeZone } = this.props;
        const formFields = [
            {
                label: "Title",
                type: CONFIG.formFieldTypes.INPUT,
                key: "title",
                value: this.filterSort?.filter?.title,
                colSpan: 24
            },
            {
                label: "Created By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "createdBy",
                data: this.state.matchedUsersNames,
                value: this.filterSort?.filter?.[dataIndexMap.createdBy],
                colSpan: 24
            },
            {
                label: "Updated By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "updatedBy",
                data: this.state.matchedUsersNames,
                value: this.filterSort?.filter?.[dataIndexMap.updatedBy],
                colSpan: 24
            }
        ];

        return (
            <React.Fragment>
                <div
                    style={{
                        color: "#242424",
                        fontSize: "19px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        marginTop: "28px",
                    }}
                >
                    List of Feedback Questions
                </div>

                <div style={{ marginTop: "16px" }}>
                    <Tabs
                        disabled={this.isViewDeletedItemsChecked}
                        activeKey={this.state.status}
                        className="demo-status-tabs"
                        defaultActiveKey={this.state.status}
                        onChange={this.handleStatusChange}
                    >
                        <TabPane
                            disabled={this.isViewDeletedItemsChecked}
                            tab="Active"
                            key="true"
                        >
                            {this.renderTabContents()}
                        </TabPane>
                        <TabPane
                            tab="Inactive"
                            key="false"
                        >
                            {this.renderTabContents()}
                        </TabPane>
                        <TabPane
                            disabled={this.isViewDeletedItemsChecked}
                            tab="All"
                            key="all"
                        >
                            {this.renderTabContents()}
                        </TabPane>
                    </Tabs>
                </div>
                <div id="component-content" className="approvals-pagination-row">
                    <div></div>
                    <Row>
                        {this.state.showFilter && (
                            <FilterDrawer
                                visible={this.state.showFilter}
                                width="520"
                                handleCloseFilterDrawer={this.handleCloseFilterDrawer}
                                placement="right"
                                maskClosable={true}
                                panelHeader="Filters and Export"
                                formFields={formFields}
                                handleClear={this.handleClear}
                                handleFilter={this.handleFilter}
                                timeZone={timeZone}
                                hideSoftDeleteCheckbox={false}
                                hideSave={true}
                                getSearchData={this.fetchUsersName}
                                label='View Deleted Feedback Questions'
                                viewDeletedItemsValue={this.filterSort?.filter?.viewDeletedItems}
                                hideApplyToAll={true}
                            />
                        )}
                    </Row>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = ({ user, feedbackQuestions, tabsLayout }) => {
    return {
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        feedbackQuestions: feedbackQuestions?.questions,
        isFetching: feedbackQuestions?.isFetching,
        timeZone: user.profile.timezone,
        currentFilter: feedbackQuestions.currentFilter,
        status: feedbackQuestions.status,
        isEdit: feedbackQuestions.isEdit,
        openedTabs: tabsLayout.panes,
        currentPageNumber: feedbackQuestions.currentPageNumber
    };
};

export default connect(mapStateToProps, {
    selectTitle,
    toggleBackButton,
    fetchAllDemoFeedbackQuestions,
    toggleIsFetchingFeedbackQuestions,
    deleteFeedbackQuestion,
    restoreFeedbackQuestion,
    clearFeedbackQuestions,
    exportTable,
    refreshFeedbackQuestions,
    handleOnClickAction,
    currentFeedbackQuestionsFilter,
    fetchLiteDemoQuestionsOrFeedbackQuestions
})(ManageFeedbackQuestions);
import React from 'react'
import { Table, Row, Col, message, Divider, Typography, Dropdown, Menu, Spin } from 'antd'
import { connect } from 'react-redux';
import moment from 'moment-timezone'
import pushMessage from './PushMessage';
import { toggleIsFetchingReportFlag, updateRunReportCurrentPage, exportTable, runReport, toggleIsFetchingUserReports, exportCommonStandardReport } from '../../actions'
import GeneralReportGraph from './GeneralReportGraph'
import CONFIG from '../../config'
import StatusIcon from './StatusIcon';
import { runCustomReports } from "../../actions/reports";
import { LoadingOutlined } from '@ant-design/icons';
import { Icon as NeoIcon, Button } from "@avaya/neo-react";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Text } = Typography
class ReportOutput extends React.Component {
    currentPage = 1
    entity = ""
    filterSort = {}
    flag = 0;
    state = {
        reportData: [],
        isReportFetched: false
    }

    async componentDidMount() {
        const { entityChanged, userRun, isRunByUser, currentReport, timeZoneName } = this.props;
        // const flag = (!this.props.isEditMode || entityChanged);
        const utcOffset = moment().tz(timeZoneName).utcOffset();
        const currentReportData = !isRunByUser ? this.getCurrentReportData() : currentReport;
        const reportObject = isRunByUser ? this.props.reportObject : currentReportData;
        // const reportId = userRun === "fromUser" ? reportObject?.id : null;
        const { reportId } = this.props.params || undefined;
        this.filterSort = (isRunByUser ? JSON.parse(reportObject?.filterSortObject) : reportObject?.filterSortObject)
        if (entityChanged) {
            await this.props.clearEntityFlag();
        }

        var tableContent = document.querySelector('.ant-table-body')
        tableContent && tableContent.addEventListener('scroll', (event) => {
            let maxScroll = event.target.scrollHeight - event.target.clientHeight
            let currentScroll = event.target.scrollTop
            if ((event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100) && (maxScroll !== 0 && currentScroll !== 0)) {
                if (this.props.count !== this.state.reportData?.length) {
                    this.flag += 1;
                    this.flag === 1 && this.handleFetchReports()
                }
            }
        })
        // if (flag) {
        //     userRun !== "fromUser" && this.createFilterSortObject(this.props.isGeneralReport)
        // }

        if (!reportObject?.default) {
            if (!reportId) await this.createFilterSortObject(false)
            await this.clearCurrentReportPage();
            await this.props.toggleIsFetchingReportFlag(true);
            await this.props.toggleIsFetchingUserReports(true);
            await this.handleFetchReports()
        }
        else {
            (reportObject?.fetchFromAPI === false) && await this.props.updateRunReportCurrentPage({ filterSort: this.filterSort, reportKey: reportObject?.reportKey, reportId: reportId, entity: this.entity, userRunReport: userRun, isGeneralReport: reportObject?.default, reportName: (reportObject?.parentReport === null || reportObject?.parentReport === undefined) ? reportObject?.name : reportObject?.parentReport.name, utcOffset: utcOffset })
        }

    }

    clearCurrentReportPage = async () => {
        this.currentPage = 1;
        await this.setState({ reportData: [] })
    }

    removeDuplicateRecordsIfAny = () => {
        let reportData = this.state.reportData;
        reportData = this.props.data ? reportData.concat(this.props.data) : []
        return reportData?.filter((value, index) => reportData.indexOf(value) === index)
    }

    async componentDidUpdate(prevProps, prevState) {
        if (((prevProps?.entityChanged !== this.props.entityChanged) && (prevProps?.stateEntity !== this.props.stateEntity))) {
            this.clearCurrentReportPage()
            this.filterSort = { filter: {} }
            this.handleFetchReports()
            this.props.clearEntityFlag()
        }
    }

    handleFetchReports = async () => {
        const { userRun, reportObject } = this.props;
        let entity;
        const reportId = userRun === "fromUser" ? reportObject?.id : null
        this.props.form.validateFields((err, values) => {
            entity = userRun !== "fromUser" ? JSON.parse(values?.entity) : reportObject?.filterTable;
            entity = entity?.tableName?.replaceAll(" ", "");
        });
        this.props.toggleIsFetchingReportFlag(true);
        this.props.toggleIsFetchingUserReports(true);
        await this.handleRunReports(reportId, entity, userRun)
        this.flag = 0;
        var scrollContainer = document.querySelector('.ant-table-body')
        if (scrollContainer) scrollContainer.scrollTop = scrollContainer?.scrollHeight - scrollContainer?.clientHeight - scrollContainer?.clientHeight;
    }

    handleRunReports = async (reportId, entity, userRun) => {
        await this.props.runReport({ pageNumber: this.currentPage++, filterSort: this.filterSort, reportId, entity, userRunReport: userRun })
        if (this.props.response === CONFIG.HTTP_STATUS.OK || this.props.response === CONFIG.HTTP_STATUS.NO_CONTENT) {
            setTimeout(async () => {
                const uniqueReportData = this.removeDuplicateRecordsIfAny();
                await this.setState({
                    reportData: uniqueReportData
                })
            }, 100)
        }
    }

    handleChange = async (pagination, _, sorter) => {
        this.clearCurrentReportPage();
        const { userRun, reportObject } = this.props;
        const reportId = userRun === "fromUser" ? reportObject?.id : null
        let entity
        this.props.form.validateFields((err, values) => {
            if (err === null) {
                entity = userRun !== "fromUser" ? JSON.parse(values?.entity) : reportObject?.filterTable;
                entity = entity?.tableName?.replaceAll(" ", "");
                if (!err) {
                    this.filterSort = {
                        filter: {
                            ...this.filterSort?.filter
                        },
                        sort: sorter.columnKey
                            ? {
                                [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                            }
                            : undefined
                    }
                }
                this.props.toggleIsFetchingReportFlag(true);
                this.props.toggleIsFetchingUserReports(true);
                this.handleRunReports(reportId, entity, userRun)
            }
        });
    }

    getOperatorValue = (columnType, value, operator) => {
        const { timeZoneName } = this.props;
        switch (columnType) {
            case CONFIG.formFieldTypes.MULTISELECT:
            case CONFIG.formFieldTypes.PACKAGE:
            case CONFIG.formFieldTypes.AUTOCOMPLETE:
                return value.join('\\\\')
            case CONFIG.formFieldTypes.DATE:
                if (Array.isArray(value)) {
                    const startTime = value[0].tz(timeZoneName, true).startOf('day').toISOString();
                    const endTime = value[1].tz(timeZoneName, true).endOf('day').toISOString();
                    return startTime + '@' + endTime
                }
                return value.tz(timeZoneName, true).startOf('day').toISOString()
            case CONFIG.formFieldTypes.NUMBER:
                return value?.toString();
            case CONFIG.formFieldTypes.MONTH:
                if (operator === "<" || operator === ">=") return value.startOf('month').startOf('day').toISOString();
                else if (operator === "<=" || operator === ">") return value.endOf('month').endOf('day').toISOString();
                else return value.startOf('month').startOf('day').toISOString() + "@" + value.endOf('month').endOf('day').toISOString();
            case CONFIG.formFieldTypes.DURATION:
                let minutes = value.Days ? value.Days * 24 * 60 : 0
                minutes += value.Hours ? value.Hours * 60 : 0
                minutes += value.Minutes ? value.Minutes : 0
                return minutes.toString()

            default:
                return value
        }
    }

    handleRunReport = async (isGeneralReport) => {
        this.props.form.validateFields(async (err, values) => {
            if (err === null) {
                this.clearCurrentReportPage();
                this.props.toggleIsFetchingReportFlag(true);
                this.props.toggleIsFetchingUserReports(true);
                const { userRun, timeZoneName, isRunByUser, currentReport } = this.props;
                const currentReportData = !isRunByUser ? this.getCurrentReportData() : currentReport;
                const reportObject = isRunByUser ? this.props.reportObject : currentReportData
                const reportId = userRun === "fromUser" ? reportObject?.id : null
                const utcOffset = moment().tz(timeZoneName).utcOffset();
                this.createFilterSortObject(isGeneralReport)
                if (reportObject.default) {
                    await this.props.updateRunReportCurrentPage({ filterSort: this.filterSort, reportKey: reportObject?.reportKey, reportId: reportId, entity: this.entity, userRunReport: userRun, isGeneralReport: reportObject.default, reportName: (reportObject?.parentReport === null || reportObject?.parentReport === undefined) ? reportObject?.name : reportObject?.parentReport.name, utcOffset: utcOffset })
                }
                else {
                    this.handleRunReports(reportId, this.entity, userRun)
                }
            }
        });
    }

    createFilterSortObject = (isGeneralReport) => {
        const { userRun, timeZoneName, currentReport, isRunByUser } = this.props;
        const currentReportData = !isRunByUser ? this.getCurrentReportData() : currentReport;
        const reportObject = this.props.reportObject || currentReportData
        this.props.form.validateFields((err, values) => {
            const tableName = this.entity = userRun !== "fromUser" ? JSON.parse(values?.entity) : reportObject?.filterTable;
            this.entity = this.entity?.tableName?.replaceAll(" ", "");
            if (currentReportData?.filerAccess !== CONFIG.roleTemplateAccess.readOnly) {
                this.filterSort = { ...this.filterSort, filter: {} }
            }
            if (!err) {
                if (!isGeneralReport) {
                    if (values?.startEndTime?.length > 0) {
                        if (tableName?.id === 16 || tableName?.tableName === "Daily Test Report") {
                            this.filterSort = {
                                ...this.filterSort,
                                filter: {
                                    ...this.filterSort?.filter,
                                    createdTime: {
                                        operator: 'IN',
                                        oprVal: values.startEndTime ? values.startEndTime[0].tz(timeZoneName, true).startOf('day').toISOString() + "@" + values.startEndTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
                                    },
                                }
                            }
                        }
                        else {
                            this.filterSort = {
                                ...this.filterSort,
                                filter: {
                                    ...this.filterSort?.filter,
                                    startTime: {
                                        operator: '>=',
                                        oprVal: values.startEndTime ? values.startEndTime[0].tz(timeZoneName, true).startOf('day').toISOString() : undefined
                                    },
                                    endTime: {
                                        operator: '<',
                                        oprVal: values.startEndTime ? values.startEndTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
                                    },
                                }
                            }
                        }
                    }
                    values.keys.forEach(k => {
                        this.filterSort = {
                            ...this.filterSort,
                            filter: {
                                ...this.filterSort?.filter,
                                [JSON.parse(values.names[(k * 3) + 1]).columnKey]: {
                                    operator: values.names[(k * 3) + 2],
                                    oprVal: this.getOperatorValue(JSON.parse(values.names[(k * 3) + 1])?.columnType, values.names[(k * 3) + 3], values.names[(k * 3) + 2])
                                },
                            }
                        }
                    })
                }
                else {
                    this.filterSort = {
                        ...this.filterSort,
                        filter: {
                            startTime: {
                                operator: '>=',
                                oprVal: values.startEndTime ? values.startEndTime[0].tz(timeZoneName, true).startOf('day').toISOString() : undefined
                            },
                            endTime: {
                                operator: '<',
                                oprVal: values.startEndTime ? values.startEndTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
                            },
                        }
                    }
                }

            }

        });
    }

    handleExport = async (report, columns, entity, fileType, currentReport, userRun) => {
        if (report === undefined || report === "") {
            pushMessage(CONFIG.messageType.error, "Please enter Report Name");
            return;
        }
        this.props.form.validateFields(async (err, values) => {
            if (err === null) {
                const { timeZoneName, isGeneralReport } = this.props;
                this.createFilterSortObject(isGeneralReport)
                let reportName = report + `.${fileType}`;
                let reportData;
                pushMessage(CONFIG.messageType.loading, "Exporting", 0);
                if (!isGeneralReport) {
                    reportData = await runCustomReports({ filterSort: this.filterSort, entity })
                }
                (!isGeneralReport ?
                    exportTable(CONFIG.exportTable.report.name, reportData.dataSet, timeZoneName, reportName, fileType, columns)
                    : this.props.exportCommonStandardReport({ name: reportName, filterSort: this.filterSort, userRun, reportId: currentReport?.id, reportKey: currentReport?.reportKey })
                ).then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Report exported successfully" : "There is no data to export. Please verify the filters")
                })
                    .catch(() => {
                        message.destroy()
                        pushMessage(CONFIG.messageType.error, "Unable to export report")
                    })
            }
        });
    }

    getColumns = (columnsData, isGeneralReport) => {
        const { timeZoneName, generalReportData } = this.props
        if (isGeneralReport) {
            const columns = generalReportData?.columns?.map((item) => {
                return {
                    key: item,
                    dataIndex: item,
                    title: item,
                    align: 'center',
                    width: 125
                };
            })
            return columns ? [{ key: "type", dataIndex: "type", title: " ", align: 'left', width: 400 }, ...columns] : [];
        }
        else
            return (columnsData?.map(column => {
                const columnKeySplit = column?.columnKey?.split(".")
                const shouldAlignCenter = column?.columnType === CONFIG.formFieldTypes.DATE || column?.columnType === CONFIG.formFieldTypes.MONTH || column?.columnType === CONFIG.formFieldTypes.NUMBER || column?.columnType === CONFIG.formFieldTypes.BOOLEAN
                return {
                    ...column,
                    dataIndex: column?.columnKey,
                    key: column?.columnKey,
                    title: column?.columnName,
                    align: shouldAlignCenter ? "center" : "left",
                    width: 200,
                    sorter: true,
                    render: (value, item) => {
                        return column?.columnKey?.includes("purpose") && item[columnKeySplit?.[0]][column?.columnKey.split("Map.")?.[1]]
                            ? item[columnKeySplit?.[0]][column?.columnKey.split("Map.")?.[1]]
                            : columnKeySplit.length === 3 && Array.isArray(item[columnKeySplit?.[0]])
                                ? item[columnKeySplit?.[0]]?.length > 0 ? item[columnKeySplit?.[0]]?.reduce((acc, item) => {
                                    if (Array.isArray(item[columnKeySplit?.[1]])) { // resources = null
                                        item[columnKeySplit?.[1]].forEach((eachItem) => {
                                            eachItem[columnKeySplit?.[2]] !== undefined && acc.push(eachItem[columnKeySplit?.[2]])
                                        })
                                    }
                                    else {
                                        acc.push(item[columnKeySplit?.[1]]?.[columnKeySplit?.[2]]);
                                    }
                                    acc = acc?.filter(item => item !== undefined)
                                    return acc
                                }, [])?.join(", ") : "-"
                                : columnKeySplit.length === 2 && Array.isArray(item[columnKeySplit?.[0]])
                                    ? item[columnKeySplit?.[0]]?.reduce((acc, item) => { acc.push(item[columnKeySplit?.[1]]); return acc }, [])?.join(", ")
                                    : column?.columnType === CONFIG.formFieldTypes.DATE
                                        ? value ? moment.tz(value, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"
                                        : column?.columnType === CONFIG.formFieldTypes.BOOLEAN
                                            ? <StatusIcon status={value} />
                                            : (column?.columnType === CONFIG.formFieldTypes.SINGLESELECT && column?.filterTable?.id === 11 && column?.columnKey === "type")
                                                ? value
                                                : (column?.columnType === CONFIG.formFieldTypes.SINGLESELECT && column?.filterTable?.id === 11 && column?.columnKey === "status")
                                                    ? CONFIG.demoStatus.types[value]
                                                    : (column?.columnType === CONFIG.formFieldTypes.NUMBER && column.columnKey === "extendedDays" && value === 0)
                                                        ? value
                                                        : value ? value : "-"
                    }
                }
            }))
    }

    getTableData = () => {
        const { generalReportData } = this.props
        const demoType = generalReportData?.data?.length > 0 && generalReportData?.data[0].hasOwnProperty('demoCount') ? "demoCount" : "demoHours"
        const countType = demoType === "demoCount" ? "scheduleCount" : "hoursScheduled"
        const rows = generalReportData?.data?.map((item, index) => {
            return {
                id: index, type: item.subject, ...item[demoType]?.reduce((acc, item) => {
                    acc[item.month] = item[countType];
                    return acc
                }, {})
            }
        })
        return rows || [];
    }

    getCurrentReportData = () => {
        const { currentReport, isCloneReport, isGeneralReport } = this.props;
        const { reportId } = this.props.params || undefined;
        const id = reportId !== undefined ? reportId : "-1";
        return currentReport?.find(item => (
            (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isCloneReport) && (item?.isGeneralReport === isGeneralReport)))?.details;
    }

    handleMenuClick = (e) => {
        const { userRun, form, currentReport, isRunByUser } = this.props;
        const { getFieldValue } = form;
        const currentReportData = !isRunByUser ? this.getCurrentReportData() : currentReport;
        const reportName = getFieldValue('name');
        const columnsData = userRun === "fromUser" ? currentReportData?.details?.viewColumnsJson : getFieldValue('columns') && getFieldValue('columns')?.length !== 0 ? getFieldValue('columns')?.map(column => column) : currentReportData?.reportColumns;
        this.handleExport(reportName, columnsData, this.selectedEntity, e.key, currentReportData, userRun);
    }

    render() {
        const { data, form, currentReport, isFetching, count, isRunByUser, isGeneralReport, generalReportData, userRun, shouldHideRunQuery } = this.props;
        const { getFieldValue } = form;
        const currentReportData = !isRunByUser ? this.getCurrentReportData() : currentReport;
        const { reportData } = this.state;
        const entity = !isGeneralReport && getFieldValue('entity') && JSON.parse(getFieldValue('entity'))
        const columnsData = userRun === "fromUser" ? currentReportData?.details?.viewColumnsJson : getFieldValue('columns') && getFieldValue('columns')?.length !== 0 ? getFieldValue('columns')?.map(column => column) : currentReportData?.reportColumns;
        const tableData = isGeneralReport ? this.getTableData() : data;
        this.tableColumns = columnsData && (this.getColumns(columnsData, isGeneralReport) || []);
        this.selectedEntity = entity?.tableName?.replaceAll(" ", "");
        const isGraphDataNull = generalReportData?.chart === null
        const customReportData = (reportData.length > 0 && reportData) || []
        const menu = (
            <Menu onClick={this.handleMenuClick}>
                <Menu.Item key={CONFIG.exportFileType.xlsx.ext}>
                    {CONFIG.exportFileType.xlsx.name}
                </Menu.Item>
                <Menu.Item key={CONFIG.exportFileType.csv.ext}>
                    {CONFIG.exportFileType.csv.name}
                </Menu.Item>
            </Menu>
        );

        return (
            <div>
                <Row>
                    <Col xl={15} sm={24}>
                        <div className='large-text'>Output</div>
                    </Col>
                    <Col xl={9} sm={24} className="right-align">
                        {
                            userRun !== "fromUser" &&
                            <Dropdown overlay={menu}>
                                <Button className="left-spacing-3" variant='secondary' icon='chevron-down' dir="rtl">
                                    Export
                                </Button>
                            </Dropdown>
                        }
                        {!shouldHideRunQuery && <Button className="primary-action-button-filled left-spacing-3" onClick={() => this.handleRunReport(isGeneralReport)}>Run Query</Button>}
                    </Col>
                </Row>
                <br />

                {isGeneralReport && !isGraphDataNull && <>
                    <GeneralReportGraph
                        graphData={generalReportData}
                        reportObject={userRun === "fromUser" ? currentReportData?.details : currentReportData}
                    /><Divider /></>}

                <Table
                    size='middle'
                    className="responsive-container"
                    rowKey={(record) => record.id}
                    bordered
                    columns={this.tableColumns}
                    dataSource={isGeneralReport ? tableData : customReportData}
                    onChange={this.handleChange}
                    scroll={!isGeneralReport ? { y: 480, x: 800 | true } : { x: 800 | true, y: "none" | false }}
                    // loading={isFetching}
                    locale={{
                        emptyText: isFetching ? <Spin indicator={antIcon} /> : <div style={{ border: "1px solid white", background: "white" }} className='dashboard-no-schedules'><NeoIcon aria-label="menu-aria" icon="info" size='md' />No Data</div>,
                    }}
                    pagination={false}
                    footer={() => <>{!isGeneralReport && customReportData?.length !== 0 && <div style={{ textAlign: 'center' }}>
                        <Text strong>Fetched {customReportData?.length} out of {count} Records</Text>
                    </div>}
                    </>}
                />
            </div>
        )
    }
}

const mapStateToProps = ({ currentReport, user, userReports }, props) => {
    const acutalReport = props?.userRun === "fromUser" ? userReports : currentReport?.reportDetails;
    return {
        data: props?.userRun === "fromUser" ? acutalReport.runReportData : currentReport?.runReportData,
        generalReportData: props?.userRun === "fromUser" ? acutalReport.runGeneralReportData : currentReport?.runGeneralReportData,
        columns: userReports?.details?.reportColumns,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        currentPage: props?.userRun === "fromUser" ? acutalReport.currentPage : currentReport?.currentPage,
        count: props?.userRun === "fromUser" ? acutalReport.count : currentReport?.count,
        currentReport: acutalReport,
        isFetching: props?.userRun === "fromUser" ? userReports?.isFetching : currentReport?.isFetching,
        response: props?.userRun === "fromUser" ? acutalReport.response : currentReport?.response,
        isRunByUser: props?.userRun === "fromUser"
    };
}


export default connect(mapStateToProps, { toggleIsFetchingReportFlag, updateRunReportCurrentPage, runReport, toggleIsFetchingUserReports, exportCommonStandardReport })(ReportOutput);

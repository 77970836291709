import React, { useEffect, useState } from 'react';
import {
    getDemoRequestById,
    closeCurrentOpenTab
}
    from '../../actions';
import { Row, Descriptions, Checkbox, Input, Divider, Radio, Icon, Spin, Form, Typography } from 'antd';
import { useDispatch, connect } from "react-redux";
import history from '../../history';
import moment from 'moment-timezone';
import CONFIG from '../../config';
import { Button } from "@avaya/neo-react"

import NoContent from '../common/NoContent';

const { TextArea } = Input;
const { Text } = Typography;


function DemoRequestDetails(props) {
    //Checks to determine if the user was re-directed to this page from an external link.
    const redirectToDemoRequest = localStorage.getItem('redirectToDemoRequest')
    const demoRequestIdFromEmail = localStorage.getItem('demoRequestId')

    const dispatch = useDispatch();
    const { timeZone, demoRequest } = props;
    const { getFieldDecorator } = props.form;
    const { demoRequestId } = props.match.params;
    const isPending = demoRequest?.statusMgmtStatus === "pending";
    const [loading, setLoading] = useState(false);
    const { notes, updatedBy, updatedTime, resolvedBy, resolvedTime, assignedUser, demo, userAnswers, statusMgmtStatus } = demoRequest || {};
    const statusName = statusMgmtStatus?.charAt(0).toUpperCase() + statusMgmtStatus?.slice(1);
    useEffect(() => {
        dispatch(getDemoRequestById(redirectToDemoRequest ? demoRequestIdFromEmail : demoRequestId));
        setLoading(true);
        setTimeout(() => setLoading(false), 800)

        return function clearCookies() {
            if (demoRequestIdFromEmail) {
                localStorage.removeItem('demoRequestId');
                localStorage.removeItem("redirectToDemoRequest");
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
        //eslint-disable-next-line
    }, []);

    const handleCancel = () => {
        dispatch(closeCurrentOpenTab());

    }

    const handleRegister = (action) => {
        const data = {
            ...demoRequest,
            statusMgmtStatus: action,
        }
        history.replace({ pathname: `/manage/demo-request/email`, data: data, emailType: CONFIG.emailTemplateEventType.demoRequestCompleteUser, showComments: true, action: action })
    }

    const renderFormButtons = () => {
        return (
            <Row className="right-align vertical-spacing">
                <React.Fragment>
                    <Button animation="none" size="default" status="default" variant="secondary" onClick={handleCancel}>Cancel</Button>
                    {isPending && <>
                        <Button className="left-spacing-3" animation="none" size="default" status="default" variant="primary" onClick={() => handleRegister("approved")}>Approve</Button>
                        <Button className="left-spacing-3" animation="none" size="default" status="warning" variant="primary" onClick={() => handleRegister("declined")}>Decline</Button>
                    </>
                    }
                </React.Fragment>
            </Row>
        );
    }

    const renderQuestionsAnswers = (questions) => {
        const { question, answerText, questionOptions } = questions;
        const { answerType, title } = question;
        let options, answers;
        options = questionOptions && questionOptions?.split("//");
        answers = answerType === CONFIG.demoAnswerTypes.Checkbox && answerText?.split("//");

        return (
            <React.Fragment>
                <Form.Item style={{ pointerEvents: "none" }} label={<div className="label-request">{title}</div>}>
                    {answerType === CONFIG.demoAnswerTypes.Textbox ?
                        <Text type="secondary">{answerText}</Text>
                        : answerType === CONFIG.demoAnswerTypes.RadioButton ?
                            <Radio.Group value={answerText}>
                                {options?.map(opt =>
                                    (<Radio key={opt} label={opt} value={opt}>{opt}</Radio>))}
                            </Radio.Group>
                            : <Checkbox.Group value={answers} >{options?.map(opt =>
                                (<Checkbox key={opt} label={opt} value={opt}>{opt}</Checkbox>))}
                            </Checkbox.Group>
                    }
                </Form.Item>
            </React.Fragment>
        )
    }

    return (
        <div className="content-container">
            <Spin spinning={loading} >
                {Array.isArray(demoRequest) ? <NoContent />
                    :
                    <>{renderFormButtons()}
                        <Descriptions className="demo-approvers-details" >
                            <Descriptions.Item span={4} label="Demo">{demo?.title || "-"}</Descriptions.Item>
                            <Descriptions.Item span={4} label="Requested By">{updatedBy?.fullName || "-"}</Descriptions.Item>
                            <Descriptions.Item span={4} label="Requester Email">{updatedBy?.email || "-"}</Descriptions.Item>
                            <Descriptions.Item span={4} label="Requested Time">{updatedTime ? moment.tz(updatedTime, timeZone).format(CONFIG.dateFormats.userDateTime) : "-"}</Descriptions.Item>
                            <Descriptions.Item span={4} label="Assigned To">{assignedUser?.fullName || "-"}</Descriptions.Item>
                            <Descriptions.Item span={4} label="Admin Email">{assignedUser?.email || "-"}</Descriptions.Item>
                            {statusName !== 'Pending' && <><Descriptions.Item span={4} label={`${statusName} By`} >{resolvedBy?.fullName || "-"}</Descriptions.Item>
                                <Descriptions.Item span={4} label={`${statusName} Time`}>{resolvedTime ? moment.tz(resolvedTime, timeZone).format(CONFIG.dateFormats.userDateTime) : "-"}</Descriptions.Item></>}
                        </Descriptions >
                        <Divider />
                        <div>
                            <div align="center" style={{ fontSize: "15px" }}><b>
                                <Icon aria-label="aria-menu" style={{ fontSize: '15px', color: "#da291c" }} width={1} type="form" />&nbsp;
                                Registration Details:</b></div > <br />
                            <div style={{ color: "red", float: "right", fontSize: "12px" }}>* Read only</div>
                            {userAnswers && userAnswers?.map((eachAnswer) => { return renderQuestionsAnswers(eachAnswer) })}
                        </div>
                        {!isPending && <div>
                            <div align="center" style={{ fontSize: "15px" }}><b>
                                <Icon aria-label="aria-menu" style={{ fontSize: '15px', color: "#da291c" }} width={1} type="form" />&nbsp;
                                Admin's Comment</b></div > <br />
                            <span style={{ fontSize: '14px' }} >Add Comment:</span>
                            <Form.Item placeholder="(Optional)">
                                {
                                    getFieldDecorator("comment", { initialValue: notes })(<TextArea
                                        disabled={!isPending}
                                        allowClear={true}
                                        rows={4} />)
                                }
                            </Form.Item>
                        </div>}
                        {renderFormButtons()}
                    </>
                }
            </Spin>
        </div>
    )
}

const DemoRequestDetailsRef = Form.create()(DemoRequestDetails);

function mapStateToProps(state) {
    return {
        timeZone: state.user.profile.timezone,
        purposeTypes: state.newSchedule.demoPurposeTypes ? state.newSchedule.demoPurposeTypes : [],
        demoApprovals: state.demoApprovals.data,
        demoApprovalRequest: state.demoApprovals.demoApprovalRequestData,
        isFetching: state.demoApprovals.isFetching,
        demoRequest: state.demoRequest.demoRequest,
        user: state.user.profile,
    };
}

export default connect(mapStateToProps)(DemoRequestDetailsRef) 

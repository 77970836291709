import React from 'react';
import { connect } from 'react-redux';
import CONFIG from "../../../config";
import moment from 'moment-timezone';
import { Divider } from 'antd';
import { Button as NeoButton, BasicModal, Icon } from "@avaya/neo-react"
import { getInitialValue } from '../../common/Scheduler';
class StandardDemoConfirmationModal extends React.Component {

    renderDetails = () => {
        const { demo, startTime, endTime, timezone, selectedResource, isMultipleScheduleBooking, form } = this.props
        const bufferStart = demo?.startTimeBuffer;
        const { isBatchEdit, batchId } = this.props?.match?.params;
        const formValues = form.getFieldsValue();
        const selectedPurpose = JSON.parse(formValues?.demoPurpose);
        const additionalAttributes = getInitialValue(selectedPurpose, form);
        const bufferEnd = demo?.endTimeBuffer;
        const bufferStartTime = bufferStart > 0 ? moment.tz(startTime, timezone).subtract(bufferStart, 'm') : undefined;
        const bufferEndTime = bufferEnd > 0 ? moment.tz(endTime, timezone).add(bufferEnd, 'm') : undefined;
        return (
            <div className='confirmation-container'>
                <div className='confirmation-banner'>
                    <div>
                        <div className='date-banner-left'>{moment.tz(startTime, timezone).format(CONFIG.dateFormats.schedulerDate)}</div>
                        <div className='day-banner-left'>Start Time: {moment.tz(startTime, timezone).format(" hh:mm A z")}</div>
                    </div>
                    <div style={{ margin: "0 16px" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4168 6.22258C19.7081 5.92809 20.1829 5.92549 20.4774 6.21678L29.6774 15.3168C29.8193 15.4572 29.8995 15.6483 29.9 15.8479C29.9005 16.0476 29.8215 16.2392 29.6803 16.3803L20.4803 25.5803C20.1874 25.8732 19.7126 25.8732 19.4197 25.5803C19.1268 25.2874 19.1268 24.8126 19.4197 24.5197L27.3393 16.6H3.75C3.33579 16.6 3 16.2642 3 15.85C3 15.4358 3.33579 15.1 3.75 15.1H27.3253L19.4226 7.28322C19.1281 6.99193 19.1255 6.51707 19.4168 6.22258Z" fill="black" />
                        </svg>
                    </div>
                    <div>
                        <div className='date-banner-left'>{moment.tz(endTime, timezone).format(CONFIG.dateFormats.schedulerDate)}</div>
                        <div className='day-banner-left'>End Time: {moment.tz(endTime, timezone).format(" hh:mm A z")}</div>
                    </div>
                </div>
                <Divider style={{ paddingTop: "1px", color: '#c9c9c9', margin: "0px" }} />
                <div >
                    <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Demo Name:
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                        {demo.title}
                    </div>
                </div>
                <div >
                    {(bufferStart > 0 || bufferEnd > 0) && <>
                        <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>
                            Buffer Time:
                        </div>
                        <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                            {bufferStart > 0 && `Start: ${bufferStartTime?.format(CONFIG.timeFormats.default)} `} {bufferEnd > 0 && `| End: ${bufferEndTime?.format(CONFIG.timeFormats.default)}`}
                        </div>
                    </>}
                </div>
                <div>
                    <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Resource:
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                        {(isMultipleScheduleBooking && selectedResource?.length > 0) ?
                            selectedResource?.map((item, index) => { return (<span>{item?.name} {index !== selectedResource?.length - 1 && " | "}</span>) })
                            : selectedResource?.name}
                    </div>
                </div>
                <div>
                    <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Purpose:
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                        {selectedPurpose ? selectedPurpose?.purpose : "-"}
                    </div>
                </div>
                {
                    additionalAttributes?.length > 0 ?
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {additionalAttributes.map((item) => item?.isVisible && (
                                    <div key={item.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: '8px' }}>
                                        <div style={{ fontSize: '12px', fontWeight: '400', lineHeight: '16px', color: '#242424' }}>
                                            {item.attributeLabel}:
                                        </div>
                                        <div style={{ fontSize: '14px', fontWeight: '600', lineHeight: '20px', color: '#242424' }}>
                                            {(item.value && item?.value?.length > 0) ? item?.value : "N/A"}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        : null
                }
                {
                    (isBatchEdit && batchId) ? <>
                        <Divider className="neo-std-divider" />
                        <div style={{ width: "100%" }} className='reccurence-confirmation-warning'>
                            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ margin: "0 16px" }}>
                                    <Icon aria-label="aria-menu"
                                        icon="warning"
                                        size="md"
                                        style={{ color: "#8e4900" }}
                                    />
                                </span>
                                <span>
                                    Updating this series will going to effect all the occurence. Are you sure you want to continue?
                                </span>
                            </div>
                        </div>
                    </> : null
                }
            </div>
        );
    }

    render() {
        const { isReschedule, currentSchedule } = this.props;
        const modalTitle = <span className='modal-title'>{`${currentSchedule ? isReschedule ? "Create" : "Update" : "Create"} Schedule`}</span>
        const okButtonText = currentSchedule && !isReschedule ? 'Update' : 'Create'
        return (
            <>
                <BasicModal
                    open={this.props.isModalVisible}
                    onClose={() => this.props.handleModalClose()}
                    closeButtonLabel={"Cancel"}
                    title={modalTitle}
                    className="neo-modal confirmation-modal-neo"
                    actions={[
                        <NeoButton key="Confirm"
                            onClick={this.props.handleSchedule}
                            id="schedule-confirm"
                        >
                            {okButtonText}
                        </NeoButton>]}>
                    {this.renderDetails()}
                </BasicModal>
            </>
        );
    }
}

const mapStateToProps = ({ scheduler, user, newSchedule }) => {
    return {
        timezone: scheduler.selectedTimezone,
        startTime: scheduler.startTime,
        endTime: scheduler.endTime,
        currentSchedule: newSchedule.currentSchedule,
        isMultipleScheduleBooking: user.profile?.isMultipleScheduleBooking || false,
    };
}

export default connect(
    mapStateToProps,
    {

    }
)(StandardDemoConfirmationModal);
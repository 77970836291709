import React from "react";
import { Layout, Drawer } from "antd";
import { Menu } from "antd5"
import { connect } from "react-redux";
import cookie from 'react-cookies';
import history from '../history';
import CONFIG from "../config";
import logo from "../assets/images/logo.png";
import { checkWarningForRoute } from "./common/TabLayout";
import { toggleMenuButton, handleOnClickAction, showPortalTourModal, addNewTab, updateActiveTab, restoreTabsOnPageRefresh, openLinkInNewTab, toggleHasUnsavedChangesFlag, clearHasUnsavedChanges } from "../actions";
import { removeAllCookies } from '../utils/cookies';
import WarningModal from "./common/WarningModal";
import { analytics } from "../firebase";
import { Icon as NeoIcon } from "@avaya/neo-react";
const { Sider } = Layout;
const { SubMenu } = Menu;

class NavigationMenu extends React.Component {
  state = {
    collapsed: false,
    isLocked: true,
    activeKey: window.location.pathname.includes("/non-sso-users") ? "/home/non-sso-users" : '/home',
    isModalOpen: false,
    item: null,
  };

  handleLogout = () => {
    removeAllCookies();
    window.location.href = process.env.REACT_APP_SAML_LOGOUT;
  }

  handleCollapse = () => {
    this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
  };

  handleDrawerClose = () => {
    this.props.toggleMenuButton(false)
  };

  renderMenuItems = menu => {
    if (menu) {
      return menu.map((item) => {
        const isParentMenuItem = item.icon !== null;
        if (item.subMenu.length === 0) {
          return item.title !== 'Logout'
            ? (
              <Menu.Item
                onClick={() => {
                  if (this.props.user?.isLoginComplete) {
                    const hasTabToBeClosedHasUnsavedChanges = checkWarningForRoute(this.props.activeKey) && this.props.panes?.filter(el => el?.key === this.props.activeKey)?.[0]?.hasUnsavedChanges;
                    this.setState({
                      item,
                      isModalOpen: hasTabToBeClosedHasUnsavedChanges
                    })
                    if (!hasTabToBeClosedHasUnsavedChanges) this.props.openLinkInNewTab(item.title, item.value ? `${item.url}/${item.value}` : `${item.url}`)
                  }
                  else {
                    this.setState({ activeKey: `${item.url}` })
                    history.replace(item.value ? { pathname: `${item.url}/${item.value}`, pageValue: item.value, title: item.title } : { pathname: `${item.url}` })
                  }
                }}
                key={item.value ? `${item.url}/${item.value}` : `${item.url}`}
                id={item.title}
                title={item.title} >
                <span className="no-underline">
                  <span>{item?.icon && <NeoIcon aria-label="menu-aria" icon={item.icon} className="menu-icons" />}<span style={{ marginLeft: "15px" }} className={isParentMenuItem ? "medium-text" : "font-12"}>{item.title}</span></span>
                </span>
              </Menu.Item >
            )
            : (
              <Menu.Item id={item.title} key={item.url} onClick={this.handleLogout}>
                <span><NeoIcon aria-label="menu-aria" icon={item?.icon} className="menu-icons" /><span style={{ marginLeft: "15px" }} className={isParentMenuItem ? "medium-text" : "font-12"}>{item.title}</span></span>
              </Menu.Item>
            )
        }
        else {
          return (
            <SubMenu
              key={item.title}
              id={item.title}
              title={
                <span>
                  {item?.icon && <NeoIcon aria-label="menu-aria" icon={item.icon} className="menu-icons" />}
                  <span style={{ marginLeft: "15px" }} className={isParentMenuItem ? "medium-text" : "font-12"}>{item.title}</span>
                </span>
              }
            >
              {this.renderMenuItems(item.subMenu)}
            </SubMenu>
          );
        }
      });
    }
  };

  handleAdditionSupport = () => {
    const { userDetails } = this.props;
    const { contactUs: {
      name: eventName,
      parameters: eventParams
    } } = CONFIG.firebaseEvents;

    const userObj = { ...userDetails, userName: userDetails?.firstName + " " + userDetails?.lastName }
    let parameters = {};
    Object.keys(eventParams).forEach(paramKey => {
      parameters[paramKey] = userObj[eventParams[paramKey]]
    });
    analytics().logEvent(eventName, parameters);

    window.open(process.env.REACT_APP_SUPPORT_URL, "_blank")
  }

  renderSupportMenu = () => {
    const item = CONFIG.supportMenus;
    return (
      <Menu.Item
        style={this.state.collapsed ? { paddingLeft: "31px" } : { paddingLeft: "24px" }}
        id={item.title}
        key={item.url}
        onClick={() => this.handleAdditionSupport(item)}>
        <span className="no-underline"><NeoIcon aria-label="menu-aria" icon={item?.icon} className="menu-icons" /><span
          style={{ marginLeft: "15px" }}
          className={"medium-text"}>{item.title}</span></span>
      </Menu.Item>
    )
  }

  renderSuperSettings = () => {
    return (
      <Menu.Item
        style={this.state.collapsed ? { paddingLeft: "31px" } : { paddingLeft: "24px" }}
        id={"/admin/settings"}
        key={"/admin/settings"}
        className="super-admin-settings"
        onClick={() => this.props.handleOnClickAction({
          component: CONFIG.createComponentRoute.adminSettings,
          tabTitle: "Admin",
        })}
      >
        <span className="no-underline"><NeoIcon aria-label="menu-aria" icon={"configure"} className="menu-icons" /><span
          style={{ marginLeft: "15px" }}
          className={"medium-text"}>Settings</span></span>
      </Menu.Item>
    )
  }

  renderHelpMenu = () => {
    const item = CONFIG.helpMenus;
    return (
      <SubMenu
        key={item.title}
        id={item.title}
        aria-label="sub-menus"
        className={`additional-support-menu-${0}`}
        title={
          <span>
            {item?.icon && <NeoIcon aria-label="menu-aria" icon={item.icon} className="menu-icons" />}
            <span style={{ marginLeft: "15px" }} className={"medium-text"}>{item.title}</span>
          </span>
        }
      >
        <Menu.Item
          aria-label="menus"
          key="whatsNew"
          id="whatsNew"
          title={`What's New`}
          onClick={() => this.props.showPortalTourModal(true, true)}
        >
          <span className="no-underline">
            <span><span style={{ marginLeft: "15px" }} className={"demo-type-details"}>What's New</span></span>
          </span>
        </Menu.Item>
        {
          CONFIG.helpMenus?.subMenu?.map(item => {
            return (
              <Menu.Item
                aria-label="menus"
                onClick={() => {
                  if (this.props.user?.isLoginComplete) {
                    const hasTabToBeClosedHasUnsavedChanges = checkWarningForRoute(this.props.activeKey) && this.props.panes?.filter(el => el?.key === this.props.activeKey)?.[0]?.hasUnsavedChanges;
                    this.setState({
                      item,
                      isModalOpen: hasTabToBeClosedHasUnsavedChanges
                    })
                    if (!hasTabToBeClosedHasUnsavedChanges) this.props.openLinkInNewTab(item.title, `${item.url}`)
                  }
                  else {
                    this.setState({ activeKey: `${item.url}` })
                    history.replace({ pathname: `${item.url}` })
                  }
                }}
                key={`${item.url}`}
                id={item.title}
                title={item.title} >
                <span className="no-underline">
                  <span><span style={{ marginLeft: "15px" }} className={"demo-type-details"}>{item.title}</span></span>
                </span>
              </Menu.Item >
            )
          })
        }
      </SubMenu>
    );
  }

  renderMenu = () => {
    const { isFirstTimeLogin, isLoginComplete, activeKey, userDetails } = this.props;
    const isSuperAdmin = userDetails?.roleTemplate?.templateType === CONFIG.roleTypes.superAdmin;
    const shoulDisplayHome = !isLoginComplete && cookie.load('ssoComplete') !== 'true';
    const isNonSSOLogin = localStorage.getItem('isNonSSOLogin') === 'true';
    return < Menu
      mode="inline"
      style={{ fontFamily: 'Noto Sans', height: "100%", maxHeight: "100%" }}
      className="menu"
      inlineCollapsed={this.state.collapsed}
      defaultSelectedKeys={shoulDisplayHome ? [this.state.activeKey] : (isFirstTimeLogin ? ['/my-profile'] : ['/dashboard'])}
      selectedKeys={shoulDisplayHome ? [this.state.activeKey] : (isFirstTimeLogin ? ['/my-profile'] : [activeKey])}
    >
      {this.renderMenuItems((isLoginComplete && !isFirstTimeLogin) ? this.props.menu : (shoulDisplayHome ? isNonSSOLogin ? [...CONFIG.nonssoHomePage, ...CONFIG.landingPageMenu] : [...CONFIG.ssoHomePage, ...CONFIG.landingPageMenu] : undefined))}
      {this.props.viewport.isMobileView ? this.renderMenuItems(!shoulDisplayHome ? CONFIG.mobileMenu : undefined) : null}
      {isLoginComplete && this.renderHelpMenu()}
      {isLoginComplete && this.renderSupportMenu()}
      {isSuperAdmin && this.renderSuperSettings()}
    </Menu >
  }

  renderBrandIdentity = () => {
    return (
      <div className='brandLogo' >
        <img src={logo} alt="logo" />
        {
          <p className="logo-text bottom-spacing-4">
            <span className="large-text">{CONFIG.brandName}</span>
          </p>
        }
      </div>);
  }

  handleMouseEnter = (e) => {
    const { collapsed, isLocked } = this.state;
    if (isLocked) return;
    if (collapsed) this.setState({ collapsed: false })
  }

  handleMouseLeave = (e) => {
    const { collapsed, isLocked } = this.state;
    if (isLocked) return;
    if (!collapsed) this.setState({ collapsed: true })
  }

  handleLock = () => {
    if (!this.state.isLocked && !this.state.collapsed) {
      this.setState({
        collapsed: false,
        isLocked: !this.state.isLocked,
      })
    }
    else {
      this.setState({
        collapsed: !this.state.collapsed,
        isLocked: !this.state.isLocked,
      })
    }
  }

  handleArrowHoverEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  componentDidMount() {
    const parentMenu = document.querySelector(".parentMenu");
    const childMenu = document.querySelector(".childMenu");
    parentMenu && parentMenu.addEventListener("mouseover", this.handleMouseEnter, false);
    parentMenu && parentMenu.addEventListener("mouseleave", this.handleMouseLeave, false);
    childMenu && childMenu.addEventListener("mouseover", this.handleArrowHoverEnter, false);
  }

  componentWillUnmount() {
    const parentMenu = document.querySelector(".parentMenu");
    const childMenu = document.querySelector(".childMenu");
    parentMenu && parentMenu.removeEventListener("mouseover", this.handleMouseEnter, false);
    parentMenu && parentMenu.removeEventListener("mouseleave", this.handleMouseLeave, false);
    childMenu && childMenu.removeEventListener("mouseover", this.handleArrowHoverEnter, false);
  }

  handleOk = async () => {
    const { item } = this.state;
    await this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
    this.setState({ isModalOpen: false })
    await this.props.clearHasUnsavedChanges();
    await this.props.openLinkInNewTab(item.title, item.value ? `${item.url}/${item.value}` : `${item.url}`)
  }

  handleCancel = () => {
    this.setState({ isModalOpen: false })
  };

  render() {
    const { viewport } = this.props;
    const { isLocked } = this.state;

    return (
      <div className="parentMenu">
        {!viewport.isMobileView
          ? <><Sider
            className="navigation-menu"
            theme="light"
            width={CONFIG.navigationMenuWidth}
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.handleCollapse}
          >
            {this.renderMenu()}

            <div
              className={this.state.collapsed ? "footer-neo-collapsed " : "footer-neo"}
            >
              <div style={{ marginBottom: "7px" }}>
                <NeoIcon aria-label="menu-aria"

                  title={!isLocked ? "Collapse" : "Expand"}
                  onClick={this.handleLock}
                  className={this.state.collapsed ? "collapsible-icon-collapsed" : "collapsible-icon"}
                  icon={!isLocked ? "page-last" : "page-first"}
                />
              </div>
            </div>
          </Sider>
          </>
          : <Drawer
            title={this.renderBrandIdentity()}
            placement="left"
            id="navigation-drawer"
            onClose={this.handleDrawerClose}
            visible={this.props.showMenu}
          >
            {this.renderMenu()}
          </Drawer>
        }
        <WarningModal
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          modalOpen={this.state.isModalOpen}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu: state.user.menu,
    viewport: state.viewport,
    showMenu: state.header.showMenu,
    isFirstTimeLogin: state.user.isFirstTimeLogin,
    user: state.user,
    isLoginComplete: state.user.isLoginComplete,
    panes: state.tabsLayout.panes,
    activeKey: state.tabsLayout.activeKey,
    userDetails: state.user.profile,
  };
};

export default connect(mapStateToProps, { toggleMenuButton, handleOnClickAction, showPortalTourModal, addNewTab, updateActiveTab, restoreTabsOnPageRefresh, openLinkInNewTab, toggleHasUnsavedChangesFlag, clearHasUnsavedChanges })(NavigationMenu);

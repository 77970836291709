import { Plugin, ButtonView } from 'ckeditor5';


export default class PrintPlugin extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('print', (locale) => {
            const button = new ButtonView(locale);

            button.set({
                label: 'Print',
                withText: true,
                tooltip: true
            });

            button.on('execute', () => {
                printEditorContent(editor);
            });

            return button;
        });
    }

}


function printEditorContent(editor) {
    const content = editor.getData();

    const newTab = window.open("", "_blank", "popup=yes,height=600,width=900");
    newTab.document.open();

    const stylesheets = Array.from(document.styleSheets)
        .map((style) => style.href)
        .filter((href) => href)
        .map((href) => `<link rel="stylesheet" href="${href}">`)
        .join("\n");

    newTab.document.write(`
        <head>
        <title>Preview</title>
        ${stylesheets}
        <style>
        body { padding: 20px; font-family: Arial, sans-serif; }
        img { max-width: 100%; height: auto; } /* Ensure images fit */
        </style>
        </head>
        <body class="ck-content">
        ${content} 
        </body>
        <script>
        window.onload = function() {
            window.print();
            window.setTimeout(function() { window.close(); }, 500);
        }
        </script>
        </html>
        <html>
    `);

    newTab.document.close();
}
import React from "react";
import { connect, batch } from "react-redux";
import { Form, Input, Row, Col, Switch, Button, Modal, Icon, Divider, Select, Spin, Tooltip } from "antd";
import {
    toggleBackButton,
    fetchRoleTemplates,
    fetchAdminRoleTemplates,
    toggleIsFetchingDemoPurposeFormFlag,
    fetchDemoPurposeById,
    updateDemoPurposeRoleTemplate,
    saveDemoPurposeRoleTemplates,
    clearDemoPurposeForm,
    cancelDemoPurposeRoleTemplates,
    postDemoPurpose,
    closeCurrentOpenTab,
    toggleHasUnsavedChangesFlag
} from "../../actions";
import FormActionButtons from "../common/FormActionButtons";
import RoleTemplatesTable from "../common/RoleTemplatesTable";
import pushMessage from '../../components/common/PushMessage';
import CONFIG from "../../config";
import { checkRoleTemplateChanges } from "../../utils/status";
const { Option } = Select;
class DemoPurposeForm extends React.Component {
    constructor(props) {
        super(props);
        this.isEditMode = this.props.match.params.purposeId !== undefined;
        this.state = {
            visible: false,
        };
        this.id = 0;
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    componentDidUpdate() {
        const { openedTabs, activeKey } = this.props;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, true)
        else if (hasUnsavedChanges && !this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false)
    }

    componentWillUnmount() {
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        this.props.clearDemoPurposeForm();
        this.id = 0
    }

    componentDidMount() {
        //document.getElementById('container').scrollIntoView();

        const { userRoleTemplate } = this.props;
        const { purposeId } = this.props.match.params;
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        batch(() => {
            this.props.toggleBackButton(false);
            const fetchAllRoleTemplates = isAdmin ? this.props.fetchAdminRoleTemplates : this.props.fetchRoleTemplates;
            fetchAllRoleTemplates()
                .then(async () => {
                    if (purposeId) {
                        this.props.toggleIsFetchingDemoPurposeFormFlag(true)
                        await this.props.fetchDemoPurposeById(purposeId)
                        this.id = this.props.demoPurpose?.demoPurposeAttributes?.length || 0;
                    }
                });
        });
    }

    remove = (k) => {
        const { getFieldValue, setFieldsValue } = this.props.form;
        const keys = getFieldValue('keys');
        setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    add = () => {
        const { getFieldValue, setFieldsValue } = this.props.form;
        const keys = getFieldValue('keys');
        const nextKeys = keys.concat(this.id++);
        setFieldsValue({
            keys: nextKeys,
        });
    };

    handleCreate = () => {
        const { form } = this.props;
        const { getFieldValue } = this.props.form;
        form.validateFields((err, values) => {
            if (err) {
                pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                return
            }
            else {
                this.props.toggleIsFetchingDemoPurposeFormFlag(true)
                let valuesObject = {}
                let purposeName = values?.name
                let demoPurposeAttributes = []
                const isActive = values?.isActive
                if (values?.label?.length > 0) {
                    let eachObject = {}
                    const keys = (getFieldValue('keys') || [])
                    for (let i = 0; i < values?.label?.length; i++) {
                        if (keys.includes(i)) {
                            eachObject = {
                                attributeLabel: values?.label[i],
                                attributeType: values?.type[i],
                                isMandatory: values?.required[i] || false,
                                isVisible: values?.isVisible[i] || false,
                                id: this.isEditMode ? values?.id[i] : undefined,
                                type: this.isEditMode ? values?.atrType[i] : undefined,
                            }
                            demoPurposeAttributes.push(eachObject)
                            eachObject = {}
                        }
                    }
                }
                valuesObject = { purposeName, demoPurposeAttributes, isActive }
                const purposeId = this.isEditMode ? this.props.match.params.purposeId : undefined
                this.props.postDemoPurpose(valuesObject, purposeId)
            }
        })
    }

    validateToNextPassword = (rule, value, callback) => {
        if (value && !CONFIG.regEx.demoPurpose.test(value)) {
            callback('Cannot contain special characters');
        }
        callback();
    };

    renderAdditionalFields = (key) => {
        const { getFieldDecorator } = this.props.form;
        const { demoPurpose } = this.props;
        const isFieldDisabled = demoPurpose?.demoPurposeAttributes[key]?.type === CONFIG.purposeAttributeTypes.opportunityNumber || demoPurpose?.demoPurposeAttributes[key]?.type === CONFIG.purposeAttributeTypes.customerName;
        return (
            <Row gutter={48}>
                <Form.Item style={{ display: 'none' }} >
                    {
                        getFieldDecorator(`id[${key}]`, {
                            initialValue: demoPurpose?.demoPurposeAttributes[key]?.id
                        })
                    }
                </Form.Item>
                <Form.Item style={{ display: 'none' }} >
                    {
                        getFieldDecorator(`atrType[${key}]`, {
                            initialValue: demoPurpose?.demoPurposeAttributes[key]?.type
                        })
                    }
                </Form.Item>
                <Col xl={7} lg={12} md={12} sm={12} xs={24}>
                    <Form.Item label="Field Label">
                        {getFieldDecorator(`label[${key}]`, {
                            rules: [
                                { required: true, message: "Please input the field label" },
                                // { validator: this.validateToNextPassword, },
                            ],
                            initialValue: demoPurpose?.demoPurposeAttributes[key]?.attributeLabel || undefined
                        })(<Input disabled={isFieldDisabled} />)}
                    </Form.Item>
                </Col>
                <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                    <Form.Item label="Field Type">
                        {getFieldDecorator(`type[${key}]`, {
                            rules: [
                                {
                                    required: true,
                                    message: "Please input the field type",
                                },
                            ],
                            initialValue: demoPurpose?.demoPurposeAttributes[key]?.attributeType || undefined
                        })(< Select allowClear={true} showArrow={true} placeholder="Select the field type" disabled={isFieldDisabled}>
                            <Option value="Textbox">Textbox</Option>
                            <Option value="Vertical">Vertical</Option>
                        </Select>)}
                    </Form.Item>
                </Col>
                <Col xl={4} lg={12} md={12} sm={12} xs={24} >
                    <Form.Item label={<span>Required <Tooltip title={CONFIG.systemMessage.required}><Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                        {getFieldDecorator(`required[${key}]`, {
                            valuePropName: "checked",
                            initialValue: demoPurpose?.demoPurposeAttributes[key]?.isMandatory || undefined
                        })(
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                disabled={isFieldDisabled}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col xl={4} lg={12} md={12} sm={12} xs={24} >
                    <Form.Item label={<span>Visibility <Tooltip title={CONFIG.systemMessage.isVisible}><Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                        {getFieldDecorator(`isVisible[${key}]`, {
                            valuePropName: "checked",
                            initialValue: demoPurpose?.demoPurposeAttributes[key]?.isVisible || undefined
                        })(
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                disabled={isFieldDisabled}
                            />
                        )}
                    </Form.Item>
                </Col>
                {!isFieldDisabled && <Col xl={3} lg={12} md={12} sm={12} xs={24} style={{ marginTop: "32px" }}>
                    <Icon aria-label="aria-menu"
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        onClick={() => this.remove(key)}
                    />
                </Col>}
            </Row >
        )
    }

    renderAssignButton = () => {
        return <Button className="primary-action-button-bordered right-border-radius" onClick={this.showModal}>Assign</Button>;
    }

    getCurrentData = (formData) => {
        return {
            name: formData.name,
            isActive: formData.isActive,
            roleTemplates: formData?.roleTemplates,
            keys: formData?.keys,
            isVisible: formData.isVisible,
            label: formData.label,
            type: formData.type,
            required: formData.required
        }
    }

    checkDemoPurposeFormChanged = (currentDemoPurposeForm, isSuperAdminUser) => {
        const { purpose, isActive, roleTemplates, demoPurposeAttributes } = this.props.demoPurposeForm || {}
        const { demoPurposeObj } = this.props || {}
        const { purposeId } = this.props.match.params;
        let hasChanged = false;
        if (purposeId) {
            hasChanged = currentDemoPurposeForm?.name !== purpose
                || currentDemoPurposeForm?.isActive !== isActive
                || checkRoleTemplateChanges(demoPurposeObj?.roleTemplates, roleTemplates, isSuperAdminUser)

            if (currentDemoPurposeForm?.keys?.length > 0 || demoPurposeAttributes?.length > 0) {
                if (currentDemoPurposeForm?.keys?.length !== demoPurposeAttributes?.length) {
                    hasChanged = true;
                } else {
                    let newPurposeAttributeObj = [];
                    let tempPurposeObj = {}
                    for (let i = 0; i < currentDemoPurposeForm?.keys?.length; i++) {
                        tempPurposeObj = {
                            attributeLabel: currentDemoPurposeForm?.label?.[i],
                            attributeType: currentDemoPurposeForm?.type?.[i],
                            isMandatory: currentDemoPurposeForm?.required?.[i] || false,
                            isVisible: currentDemoPurposeForm?.isVisible?.[i] || false
                        }
                        newPurposeAttributeObj.push(tempPurposeObj);
                        tempPurposeObj = {}
                    }
                    const changed = newPurposeAttributeObj?.map(newData => demoPurposeAttributes?.some(oldData => (
                        oldData?.attributeLabel === newData?.attributeLabel &&
                        oldData?.attributeType === newData?.attributeType &&
                        oldData?.isMandatory === newData?.isMandatory &&
                        oldData?.isVisible === newData?.isVisible)));
                    if (changed?.includes(false)) { hasChanged = true };
                }
            }
        }
        if (hasChanged) return hasChanged;
        return hasChanged;
    }

    handleRoleTemplatesCancel = e => {
        this.props.cancelDemoPurposeRoleTemplates();
        this.setState({
            visible: false
        });
    };

    handleRoleTemplatesAssign = () => {
        this.props.saveDemoPurposeRoleTemplates();
        this.setState({ visible: false });
    }

    getAssignedRoleTemplates = (isSuperAdminUser) => {
        const { demoPurposeForm } = this.props;
        const { roleTemplates } = demoPurposeForm || {};
        let assignedRoleTemplates = [];
        if (roleTemplates) {
            roleTemplates.forEach(obj => {
                const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
                const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
                if (isSuperAdminUser ? userAccessCondition || adminAccessCondition : userAccessCondition) {
                    assignedRoleTemplates.push(obj.roleTemplate.title);
                }
            })
        }
        return assignedRoleTemplates;
    }

    render() {
        const {
            form: { getFieldDecorator, getFieldValue }
        } = this.props;
        const { form, roleTemplates, user, isFetching, demoPurpose, demoPurposeForm } = this.props;
        const { purposeId } = this.props.match.params;
        const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const TestingDisabled = demoPurpose?.purpose === "Testing";
        const assignedRoleTemplates = this.getAssignedRoleTemplates(isSuperAdminUser);
        const currentDemoPurposeForm = this.getCurrentData(form.getFieldsValue());
        this.hasChanged = purposeId ? this.checkDemoPurposeFormChanged(currentDemoPurposeForm, isSuperAdminUser) : true;
        getFieldDecorator('keys', { initialValue: this.isEditMode ? ([...Array(this.props.demoPurpose?.demoPurposeAttributes?.length).keys()]) : [] });
        const keys = (getFieldValue('keys') || [])
        return (
            <div className="content-container">
                <Spin spinning={isFetching} wrapperClassName="spin-overlay">
                    <br />
                    <br />
                    <Form>
                        <Row gutter={48}>
                            <Col xl={10} sm={10} xs={24}>
                                <Form.Item label="Purpose Name">
                                    {getFieldDecorator("name", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input the name of the demo purpose",
                                            },
                                        ],
                                        initialValue: demoPurpose ? demoPurpose?.purpose : undefined
                                    })(<Input id="demo-purpose-name" disabled={TestingDisabled} />)}
                                </Form.Item>
                            </Col>
                            <Col xl={10} sm={10} xs={24}>
                                <Form.Item label="Assign Role Template">
                                    {getFieldDecorator("roletemplate", {
                                        initialValue: assignedRoleTemplates.length !== 0
                                            ? assignedRoleTemplates
                                            : "None"
                                    })(<Input id="demo-purpose-rt" className="role-template-input" disabled addonAfter={this.renderAssignButton()} />)}
                                    {<Modal
                                        title="Role Template"
                                        visible={this.state.visible}
                                        onCancel={this.handleRoleTemplatesCancel}
                                        footer={<FormActionButtons okText="Assign" handleCancel={this.handleRoleTemplatesCancel} handleSubmit={this.handleRoleTemplatesAssign} />}
                                    >
                                        <RoleTemplatesTable
                                            hideColumns={isSuperAdminUser ? [] : ['admin']}
                                            dataKey="roleTemplate"
                                            data={roleTemplates.all}
                                            pagination={false}
                                            assignedData={demoPurposeForm?.roleTemplates}
                                            allAdminAccess={demoPurposeForm?.allRTAdminAccess}
                                            allUserAccess={demoPurposeForm?.allRTUserAccess}
                                            updateRoleTemplateData={this.props.updateDemoPurposeRoleTemplate}
                                        />
                                    </Modal>
                                    }
                                </Form.Item>
                            </Col>
                            <Col xl={3} lg={4} sm={6} xs={24} >
                                <Form.Item label="Status">
                                    {getFieldDecorator("isActive", {
                                        valuePropName: "checked",
                                        initialValue: demoPurpose?.isActive
                                    })(
                                        <Switch
                                            checkedChildren="Active"
                                            unCheckedChildren="Inactive"
                                            id="demo-purpose-active"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <div style={{ fontSize: "17px", color: "black" }}>Additional Fields</div>
                        </Row>
                        {keys.map((key) => {
                            return this.renderAdditionalFields(key)
                        })}
                        <br />
                        <Row className="center-align">
                            <Button id="add-demo-purpose" onClick={this.add} align="center" type="dashed" style={{ width: '40%' }}>
                                <Icon aria-label="aria-menu" type="plus" /> Add Field
                            </Button>
                        </Row>
                        <br />
                        <br />
                        <Row className="right-align">
                            <FormActionButtons
                                handleSubmit={this.handleCreate}
                                cancelText={"Discard & Close"}
                                isDisabled={!this.hasChanged}
                                handleCancel={() => this.props.closeCurrentOpenTab()}
                                okText={this.isEditMode ? "Update" : "Create"}
                            />
                        </Row>
                    </Form>
                </Spin>
            </div >
        );
    }
}

const DemoPurposeFormRef = Form.create()(DemoPurposeForm);
const mapStateToProps = ({ roleTemplates, viewport, user, demoPurposeForm, tabsLayout }) => {
    return {
        timeZoneName: user.profile.timezone,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        user: user.profile,
        roleTemplates,
        viewport,
        isFetching: demoPurposeForm.isFetching,
        demoPurpose: demoPurposeForm.demoPurposes,
        demoPurposeForm,
        demoPurposeObj: demoPurposeForm?.demoPurposeObj,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
    };
};
export default connect(mapStateToProps, {
    toggleBackButton,
    fetchRoleTemplates,
    fetchAdminRoleTemplates,
    toggleIsFetchingDemoPurposeFormFlag,
    fetchDemoPurposeById,
    updateDemoPurposeRoleTemplate,
    saveDemoPurposeRoleTemplates,
    cancelDemoPurposeRoleTemplates,
    clearDemoPurposeForm,
    postDemoPurpose,
    closeCurrentOpenTab,
    toggleHasUnsavedChangesFlag
})(DemoPurposeFormRef);

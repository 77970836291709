import React from 'react';
import { Modal, Input, Form, Row, Col, Select, Switch, Button, Upload, Icon, Spin } from 'antd';

import CONFIG from '../../config';
import pushMessage from '../common/PushMessage';
import DataCenters from '../common/DataCenters';
import { getFileName } from '../../utils/file';
import { validateURL } from '../../utils/strings';

const { Option } = Select;
const { Dragger } = Upload;



let fileDropped = false;

const CollateralForm = Form.create({ name: 'form_in_modal' })(
    class extends React.Component {
        state = {
            file: undefined,
            isLoaded: false,
            isFile: true
        };

        stateUpdated = false;

        handleCancel = () => {
            const { onCancel } = this.props;
            onCancel();
            this.setState({ file: undefined });
            this.stateUpdated = false;
        }

        async componentDidMount() {
            const { currentCollateral } = this.props;
            if (currentCollateral) {
                await this.setState({ isFile: !currentCollateral?.isLinkType });
            }
        }

        handleTypeChange = (value) => {
            const item = JSON.parse(value);
            this.setState({ isFile: item?.value })
        }

        handleCollateralTypeChange = (value) => {
            if (JSON.parse(value)?.name !== "Link(s)") {
                this.setState({ isFile: true })
            } else {
                this.setState({ isFile: false })
            }
        }

        handleAdd = () => {
            const { onAdd, form } = this.props;
            const { isFile } = this.state;

            form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                else if (isFile && !this.state.file) {
                    form.setFields({
                        file: { errors: [Error("Please upload a file")] }
                    });
                    return;
                }
                fileDropped = false;
                onAdd(this.state.file, values, isFile);
                form.resetFields();
                this.setState({ file: undefined });
                this.stateUpdated = false;
            });
        }

        renderTypeOptions = (collateralTypes) => {
            if (this.props.visible) {
                return collateralTypes.map(type => {
                    return <Option key={type.id} value={JSON.stringify(type)}>{type.name}</Option>
                });
            }
            return;
        }


        renderFooterButtons = (okButtonText) => {
            return [
                <Button aria-label="Click" key="cancel" className="cancel-button" onClick={this.handleCancel}> Cancel </Button>,
                <Button aria-label="Click" id='collateral-confirm' key="ok" className="primary-action-button-filled" onClick={this.handleAdd}> {okButtonText} </Button>
            ];
        }

        static getDerivedStateFromProps(props, state) {
            const { editMode, currentCollateral } = props;
            const { link, isLinkType } = currentCollateral;
            if (editMode && !fileDropped && !state.isLoaded && !isLinkType) {
                let mediaFile;
                mediaFile = new File([], getFileName(link));
                mediaFile.uid = Date.now();
                const uploadedFile = link ? mediaFile : undefined;
                return { ...state, file: uploadedFile, isLoaded: true }
            }
            return state;
        }

        renderUploadTypeOption = () => {
            return CONFIG.ezDemoType.map((type, index) => {
                return <Option key={type} value={JSON.stringify(type)}>{type?.text}</Option>
            });
        }

        render() {
            const switchValue = this.props.editMode ? this.props.currentCollateral.isActive : false;
            const okButtonText = this.props.editMode ? 'Save' : 'Add';
            const { visible, form, collateralTypes, editMode } = this.props;
            const { getFieldDecorator } = form;
            const uploadProps = {
                multiple: false,
                listType: "picture",
                fileList: this.state.file ? [this.state.file] : [],
                beforeUpload: (file) => {
                    const { form } = this.props;
                    const isWithinUploadLimit = file.size / 1024 / 1024 < CONFIG.fileSize.collateral;
                    if (!isWithinUploadLimit) {
                        pushMessage(CONFIG.messageType.warning, `File must be smaller than ${CONFIG.fileSize.collateral} MB`)
                        return true;
                    }
                    fileDropped = true;
                    this.setState({
                        file
                    });
                    form.setFields({
                        file: { errors: [] }
                    });
                    return false;
                },
                onRemove: () => this.setState({ file: undefined })
            };
            return (
                <Modal
                    visible={visible}
                    title={`${editMode ? 'Edit' : 'Add'} Collateral`}
                    className="long-modal"
                    onCancel={this.handleCancel}
                    footer={this.renderFooterButtons(okButtonText)}
                >
                    <Spin tip="Uploading" spinning={this.props.isUploading === true}>
                        <Form layout="vertical">
                            <Form.Item label="Title">
                                {getFieldDecorator('title', {
                                    rules: [{ required: true, message: 'Please input the title of the collateral' }],
                                    initialValue: this.props.currentCollateral.title
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Description">
                                {getFieldDecorator('description', {
                                    initialValue: this.props.currentCollateral.description
                                })(<Input type="textarea" />)}
                            </Form.Item>
                            <Row gutter={48}>
                                <Col span={12}>
                                    <Form.Item label="Type">
                                        {getFieldDecorator('collateralType', {
                                            rules: [{ required: true, message: 'Please select a type' }],
                                            initialValue: this.props.currentCollateral.collateralType ? JSON.stringify(this.props.currentCollateral.collateralType) : undefined
                                        })(
                                            <Select showSearch={true} id='collateral-type' placeholder="Select a type" onChange={this.handleCollateralTypeChange} >
                                                {this.renderTypeOptions(collateralTypes)}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <DataCenters form={this.props.form} dataCenter={this.props.currentCollateral.dataCenter} />
                                </Col>
                            </Row>
                            {this.state.isFile ? <Form.Item label="File" required>
                                {
                                    getFieldDecorator('file')(
                                        <Dragger {...uploadProps}>
                                            <p className="ant-upload-drag-icon">
                                                <Icon aria-label="aria-menu" type="inbox" />
                                            </p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                            <p className="ant-upload-hint">
                                                Allowed Types : ppt, pptx, doc, docx, txt, csv, xlx, xlsx, pdf, epub, mp4, mpg, mpv, avi, gif, jpg, jpeg, png, zip, tar, gz, rar
                                            </p>

                                        </Dragger>
                                    )
                                }
                            </Form.Item> :
                                <Form.Item label="URL" required>
                                    {
                                        getFieldDecorator('url', {
                                            rules: [{ required: true, message: "Please enter a Valid URL", validator: validateURL, }],
                                            initialValue: this.props.currentCollateral?.isLinkType ? this.props.currentCollateral?.link : undefined,
                                        })(<Input />)
                                    }
                                </Form.Item>}
                            <Form.Item label="Status">
                                {getFieldDecorator('isActive', {
                                    valuePropName: 'checked',
                                    initialValue: switchValue
                                })(<Switch checkedChildren="Active" unCheckedChildren="Inactive" />)}
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            );
        }
    },
);

export default CollateralForm;
import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  listProperties,
  fontSizeConfig,
  headingConfig,
  toolbarConfig,
  tableContentConfig,
  imageConfig,
  styleConfig,
  emojiConfig,
  htmlConfig,
  fontColorConfig,
} from "../../utils/editorConfig";
import {
  HorizontalLine,
  Table,
  TableToolbar,
  TableCellProperties,
  TableProperties,
  TableCaption,
  Style,
  GeneralHtmlSupport,
  Heading,
  CodeBlock,
  Link,
  Bookmark,
  List,
  BlockQuote,
  ListProperties,
  Indent,
  IndentBlock,
  RemoveFormat,
  Strikethrough,
  SpecialCharactersEssentials,
  SpecialCharacters,
  ClassicEditor,
  Essentials,
  Paragraph,
  Bold,
  Italic,
  EasyImage,
  ImageUpload,
  ImageToolbar,
  ImageInsert,
  CloudServices,
  Image,
  ImageStyle,
  SourceEditing,
  Alignment,
  Font,
  FontColor,
  FontBackgroundColor,
  Emoji,
  Mention,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import PreviewPlugin from "./Plugins/PreviewPlugin";
import PrintPlugin from "./Plugins/PrintPlugin";
import UnlinkPlugin from "./Plugins/UnlinkPlugin";
import { connect } from "react-redux";
import { toggleCkEditorContentChangedFlag } from "../../actions";
import CONFIG from "../../config";

class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      initialized: false,
      editorKey: props.editorKey,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.placeholders) !== JSON.stringify(prevProps.placeholders)
    ) {
      this.setState({ editorKey: Math.random().toString() });
    }
  }

  handleChange = (event, editor) => {
    const { emailType, storeKey, recordId, isClone } = this.props;
    if (
      storeKey === "dynamicEmailTemplate" &&
      (emailType === CONFIG.emailTemplateEventType.manualBuildAvailable ||
        emailType === CONFIG.emailTemplateEventType.demoRequestCompleteUser ||
        emailType === CONFIG.emailTemplateEventType.maintenanceUpdate ||
        emailType === CONFIG.emailTemplateEventType.maintenanceCreate)
    ) {
      this.props.toggleCkEditorContentChangedFlag(true);
    }

    const that = this;
    const data = editor.getData();
    if (data.indexOf("blob:http") >= 0) {
      const interval = setInterval(function () {
        let newData = editor.getData();
        newData = newData.replace(/ data-mention="[^"]*"/g, "");
        console.log({ newData })
        that.props.componentKey && recordId !== undefined
          ? that.props.updateEditorContent(
            that.props.componentKey,
            recordId,
            newData,
            isClone
          )
          : that.props.componentKey
            ? that.props.updateEditorContent(that.props.componentKey, newData)
            : that.props.updateEditorContent(newData);
        if (newData.indexOf("blob:http") < 0) {
          clearInterval(interval);
        }
      }, 500);
    }

    let newData = editor.getData();
    newData = newData.replace(/ data-mention="[^"]*"/g, "");
    console.log({ newData })
    that.props.componentKey && recordId !== undefined
      ? that.props.updateEditorContent(
        that.props.componentKey,
        recordId,
        newData,
        isClone
      )
      : that.props.componentKey
        ? that.props.updateEditorContent(that.props.componentKey, newData)
        : that.props.updateEditorContent(newData);

    const placeHolders = this.props.placeholders
      ? this.props.placeholders.map((item) => item.placeholder)
      : [];
    var placeholderslistInHtml =
      editor.document?.$.body?.getElementsByClassName("cke_placeholder");
    placeholderslistInHtml !== undefined &&
      Array.from(placeholderslistInHtml).forEach((placeholderHtml) => {
        const placeholder = placeholderHtml.innerText.replace(/\[|\]/gi, "");
        return !placeHolders.includes(placeholder)
          ? placeholderHtml.removeAttribute("class")
          : undefined;
      });
  };

  componentWillUnmount() {
    if (this.editorInstance) {
      this.editorInstance.destroy().catch((error) =>
        console.error("Error during editor destroy:", error)
      );
    }
  }

  handleReady = (editor) => {
    this.editorInstance = editor;

    // *** Downcast Conversion Override for Mentions ***
    // This tells CKEditor to output only the plain mention text (e.g., [[header1]])
    // rather than wrapping it in a span with the data-mention attribute.
    editor.conversion.for("downcast").elementToElement({
      model: "mention",
      view: (modelElement, { writer: viewWriter }) => {
        const mentionText = modelElement.getAttribute("mention");
        // Create a span element with only the "mention" class.
        const mentionElement = viewWriter.createContainerElement("span", { class: "mention" });
        // Insert the custom mention text inside the element.
        viewWriter.insert(viewWriter.createPositionAt(mentionElement, 0), viewWriter.createText(mentionText));
        return mentionElement;
      },
    });

    // Custom editing view changes (optional)
    editor.editing.view.change((writer) => {
      writer.addClass("my-custom-ckeditor-class", editor.editing.view.document.getRoot());
    });

    this.setState({ initialized: true });
  };

  render() {
    const {
      contentDetails,
      isEdit,
      recordId,
      contentData,
      componentKey,
      edition,
      placeholders,
    } = this.props;
    const { isContentLoaded, html } =
      componentKey === "capabilityQuestionInstruction"
        ? contentDetails?.[recordId] || {}
        : contentDetails || {};
    const shouldDisplayEditor =
      (isEdit === true && (recordId !== undefined || isContentLoaded === true)) || isEdit === false;

    const pluginsToUse =
      edition === "basic"
        ? [
          EasyImage,
          ImageUpload,
          CloudServices,
          ImageToolbar,
          ImageInsert,
          Image,
          ImageStyle,
        ]
        : [
          HorizontalLine,
          Table,
          TableToolbar,
          Style,
          GeneralHtmlSupport,
          CodeBlock,
          Heading,
          Bookmark,
          Link,
          Indent,
          IndentBlock,
          List,
          ListProperties,
          BlockQuote,
          SpecialCharactersEssentials,
          SpecialCharacters,
          Essentials,
          Paragraph,
          Strikethrough,
          Bold,
          Italic,
          EasyImage,
          ImageUpload,
          ImageToolbar,
          ImageInsert,
          CloudServices,
          Image,
          ImageStyle,
          SourceEditing,
          Alignment,
          Font,
          FontColor,
          FontBackgroundColor,
          Emoji,
          Mention,
          RemoveFormat,
          TableCellProperties,
          TableProperties,
          TableCaption,
        ];

    return (
      shouldDisplayEditor && (
        <CKEditor
          editor={ClassicEditor}
          key={this.state.editorKey}
          config={{
            licenseKey:
              process.env.REACT_APP_MODE === "development"
                ? process.env.REACT_APP_CK5_DEVELOPMENT_LICENSE
                : process.env.REACT_APP_CK5_PRODUCTION_LICENSE,
            cloudServices: {
              tokenUrl:
                process.env.REACT_APP_USER_SERVICE_API_URL +
                "/ckeditorTokenService/generateCkeditorToken",
              uploadUrl: process.env.REACT_APP_CK_EDITOR_UPLOAD_URL,
            },
            mention: {
              feeds: [
                {
                  marker: "[[",
                  feed: (query) => {
                    if (!placeholders || !Array.isArray(placeholders)) {
                      return [];
                    }
                    return placeholders
                      .map((item) => "[[" + item.placeholder + "]]")
                      .filter((placeholder) =>
                        placeholder.toLowerCase().includes(query.toLowerCase())
                      );
                  },
                  minimumCharacters: 0,
                },
              ],
              dropdownLimit: 100,
            },
            plugins: pluginsToUse,
            extraPlugins: [PreviewPlugin, PrintPlugin, UnlinkPlugin],
            emoji: emojiConfig,
            htmlSupport: htmlConfig,
            style: styleConfig,
            fontSize: fontSizeConfig,
            heading: headingConfig,
            toolbar: toolbarConfig,
            table: tableContentConfig,
            list: listProperties,
            image: imageConfig,
            fontColor: fontColorConfig,
            allowedContent: true,
          }}
          data={recordId !== undefined ? contentData || "" : html || ""}
          onChange={(event, editor) => this.handleChange(event, editor)}
          onReady={this.handleReady}
        />
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contentDetails: state[ownProps.storeKey],
    demoComponentDetails: state.demoComponentDetails,
    demoAccess: state.demoAccess,
    demoDescription: state.demoDescription,
    ownProps,
    state,
  };
};

export default connect(mapStateToProps, { toggleCkEditorContentChangedFlag })(TextEditor);

import React from "react";
import { connect, batch } from "react-redux";
import { message, Spin, Rate, Dropdown, Tabs } from "antd";
import moment from 'moment-timezone';
import { Button as NeoButton, Table as NeoTable, Menu as NeoMenu, MenuItem } from "neo-latest"
import { Icon as NeoIcon } from "@avaya/neo-react";
import { otherFields } from '../../utils/neoTableFields';
import { checkIfFilterSortIsEmpty } from '../../utils/formChanged';
import {
  clearDemos,
  fetchActiveResources,
  fetchDemoCollateralTypes,
  exportTable,
  restoreDemo,
  updateScheduleWarning,
  handleOnClickAction,
  toggleSaveReportVisible,
  currentDemoFilter,
  getAllDemosLite,
  fetchAllDemosLite,
  clearFetchedDemos
} from "../../actions";
import { getAllDemos } from '../../actions/demos';
import { checkDemoDependencies, deleteDemo } from "../../actions/demos"
import openConfirm from '../common/Confirm';
import CONFIG from "../../config";
import pushMessage from '../common/PushMessage';
import FilterDrawer from "../common/FilterDrawer";
import { exportMenu } from "../../utils/strings";
import SaveReport from "../common/SaveReport";
import throttle from 'lodash/throttle';
import { isTabAlreadyOpen } from "../common/TabLayout";
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const dataIndexMap = {
  title: 'title',
  isActive: 'isActive',
  startTime: 'startTime',
  endTime: 'endTime',
  applyToAllCheck: 'includeStartAndEndDate',
  viewDeletedItems: 'viewDeletedItems',
  demoType: 'demoType',
  rating: 'rating',
};
const { TabPane } = Tabs;
class ManageDemo extends React.Component {

  constructor(props) {
    super(props);
    this.handleClear = throttle(this.handleClear, 1000);
  }

  state = {
    status: "true",
    demoData: [],
    loading: false,
    isReturnedFromEdit: false,
    isReturnedFromDelete: false,
    deletedRecordIndex: undefined,
    value: "",
    currentPath: null,
    showFilter: false,
    currentPage: 0,
  }

  demoId = undefined;
  filterSort = {}
  filterSave = {}

  onChange = (value) => {
    this.setState({ value })
  }

  clearCurrentDemos = async () => {
    this.props.clearFetchedDemos();
    this.setState({ currentPage: 0 })
  }

  handleSelectChange = async (value) => {
    await this.clearCurrentDemos()
    this.setState({ status: value }, () => { this.handleFetchDemos() })
  }

  handleFilter = async (filters, isSave) => {
    const { timeZoneName } = this.props;
    let { title, startEndTime, applyToAllCheck, viewDeletedItems, demoType } = filters;
    const isStartEndTimeEmpty = !startEndTime || (startEndTime && startEndTime.length === 0);
    const isDemoTypeEmpty = !demoType || (demoType && demoType.length === 0);

    const isDemoTitleEmpty = !title || (title && title.length === 0);
    const isFilter = isSave ? "save" : "filter";
    if (isDemoTitleEmpty && isStartEndTimeEmpty && isDemoTypeEmpty && !viewDeletedItems) {
      pushMessage(CONFIG.messageType.warning, `Please select some fields to ${isFilter}`)
      return;
    }
    if (viewDeletedItems) this.setState({ status: "false" })
    if (isSave) {
      this.filterSave = {
        ...this.filterSave,
        filter: {
          ...(!isDemoTitleEmpty && {
            [dataIndexMap.title]: {
              operator: "=",
              oprVal: !isDemoTitleEmpty ? title.join(CONFIG.delimiters.saveFilter) : undefined

            }
          }),
          ...(!isDemoTypeEmpty && {
            [dataIndexMap.demoType]: {
              operator: "=",
              oprVal: !isDemoTypeEmpty ? demoType.join(CONFIG.delimiters.saveFilter) : undefined
            }
          }),
          ...(!isStartEndTimeEmpty && {
            activatedTime: {
              operator: "IN",
              oprVal: !isStartEndTimeEmpty ? startEndTime[0].tz(timeZoneName, true).startOf('day').toISOString() + '@' + startEndTime[1].endOf('day').tz(timeZoneName, true).toISOString() : undefined
            }
          }),
          ...(!isStartEndTimeEmpty && {
            expiredTime: {
              operator: "IN",
              oprVal: !isStartEndTimeEmpty ? startEndTime[0].tz(timeZoneName, true).startOf('day').toISOString() + '@' + startEndTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
            }
          }),
          ...(viewDeletedItems && {
            isSoftDelete: {
              operator: "=",
              oprVal: viewDeletedItems
            }
          })
        },
        filterTable: {
          id: 1,
          tableName: "Demo"
        },
      }
      this.props.toggleSaveReportVisible(true)
      this.setState({ saveReport: true })
    }
    else {
      await this.clearCurrentDemos()
      this.filterSort = {
        ...this.filterSort,
        filter: {
          [dataIndexMap.title]: !isDemoTitleEmpty ? title.join(CONFIG.delimiters.selectFilter) : undefined,
          [dataIndexMap.startTime]: !isStartEndTimeEmpty ? startEndTime[0].tz(timeZoneName, true).toISOString() : undefined,
          [dataIndexMap.endTime]: !isStartEndTimeEmpty ? startEndTime[1].tz(timeZoneName, true).toISOString() : undefined,
          [dataIndexMap.applyToAllCheck]: applyToAllCheck || undefined,
          [dataIndexMap.viewDeletedItems]: viewDeletedItems,
          [dataIndexMap.demoType]: !isDemoTypeEmpty ? demoType.join(CONFIG.delimiters.selectFilter) : undefined,
        }
      }
      this.handleFetchDemos(true);
    }
  }

  handleClear = async (isRefresh) => {
    await this.clearCurrentDemos()
    if (!isRefresh) {
      this.filterSort = { ...this.filterSort, filter: undefined };
      this.filterSave = {};
    }
    this.handleFetchDemos();
  }

  handleSave = (filters) => {
    this.handleFilter(filters, true)
  }

  handleExport = async (fileType) => {
    const { timeZoneName } = this.props;

    pushMessage(CONFIG.messageType.loading, "Exporting", 0);
    const demos = await getAllDemos({ filterSort: this.filterSort, status: this.state.status ? this.state.status === "true" ? true : this.state.status === "false" ? false : undefined : undefined });
    if (demos?.length > 0 && demos !== undefined) {
      exportTable(CONFIG.exportTable.demo.name, demos, timeZoneName, CONFIG.exportTable.demo.fileName + `.${fileType}`, fileType)
        .then((response) => {
          message.destroy()
          const isExport = response.status === CONFIG.HTTP_STATUS.OK
          pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
        })
        .catch(() => {
          message.destroy()
          pushMessage(CONFIG.messageType.error, "Unable to export table")
        })
    }
    else {
      message.destroy()
      pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
    }
  }

  handleFetchDemos = async () => {
    this.setState({ loading: true });
    await this.props.fetchAllDemosLite({ filterSort: this.filterSort, status: this.state.status })
    this.setState({ loading: false });

  }

  renderDeleteRestoreConfirmation = (dependencyList, isRestore) => {
    const { scheduleCount, maintenanceCount, collateralCount } = dependencyList;
    const hardDelete = this.filterSort?.filter?.viewDeletedItems;
    const okButtonText = isRestore ? "Confirm Restore" : hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
    const text = isRestore ? "Restoration" : "Deletion"

    const showDependencyList = scheduleCount !== 0 || maintenanceCount !== 0 || collateralCount !== 0;
    const modalTitle = isRestore ? CONFIG.restoreModalTitle?.replace('<placeholder>', 'demo') : showDependencyList ? CONFIG.dependencyModalTitle : CONFIG.deleteModalTitle?.replace('<placeholder>', 'demo')
    const content = <>
      {hardDelete && !isRestore ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Demo")}</h4> : !isRestore ? <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "Demo")}</h4> : ""}
      {showDependencyList &&
        <>
          <h4>{text} of selected demo would affect</h4>
          {!hardDelete && <>{scheduleCount} * Future Scheduled Demos <br /></>}
          {!hardDelete && <>{maintenanceCount} * Future Maintenance Windows<br /></>}
          {collateralCount} * Collaterals
        </>
      }
    </>
    openConfirm(okButtonText, isRestore ? this.handleRestoreConfirmation : this.handleDeleteConfirmation, null, content, modalTitle);
  }

  handleDeleteConfirmation = async () => {
    const hardDelete = this.filterSort?.filter?.viewDeletedItems;
    const response = await deleteDemo(this.demoId, hardDelete);
    if (response) {
      this.handleFetchDemosAfterDeleteOrRestore()
    }
  }

  handleFetchDemosAfterDeleteOrRestore = async () => {
    this.clearCurrentDemos();
    this.fetchDemosOnMount();
  }

  handleDeleteRestore = (demoId, isRestore) => {
    this.demoId = demoId;
    const hardDelete = this.filterSort?.filter?.viewDeletedItems;

    if (this.props.developerMode && !isRestore && hardDelete) {
      pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
    }
    else {
      checkDemoDependencies(demoId, hardDelete)
        .then(dependencyList => this.renderDeleteRestoreConfirmation(dependencyList, isRestore))
        .catch((err) => pushMessage(CONFIG.messageType.error, err.message))
    }
  }


  handleRestoreConfirmation = async () => {
    const response = await this.props.restoreDemo(this.demoId)
    if (response) {
      this.handleFetchDemosAfterDeleteOrRestore();
    }
  }

  async componentWillUnmount() {
    if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
      await this.props.currentDemoFilter(this.filterSort, this.state.status, this.state.currentPage)
    } else {
      this.props.clearDemos();
    }
  }

  fetchDemosOnMount = async () => {
    this.setState({ loading: true })
    await this.props.fetchAllDemosLite({
      filterSort: this.filterSort,
      status: this.state.status
    })
    this.setState({ loading: false })
  }

  async componentDidMount() {
    const { isEdit, demo, currentPage } = this.props;
    this.setState({ loading: true, currentPath: window.location.pathname })

    if (isEdit) {
      this.filterSort = demo.currentFilter;
      await this.setState({ status: demo.status, currentPage: currentPage })
      if (demo?.currentFilter?.filter !== undefined) this.setState({ showFilter: true })
    }
    else {
      this.filterSort = {}
    }
    await this.fetchDemosOnMount();
    batch(() => {
      this.props.fetchActiveResources();
      this.props.getAllDemosLite({});
      this.props.fetchDemoCollateralTypes();
    });
    this.setState({ loading: false })
  }

  componentDidUpdate = () => {
    const dropdownLinks = document.querySelectorAll(
      '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
    );
    if (dropdownLinks?.length > 0) {
      dropdownLinks.forEach((link) => {
        link.addEventListener('click', this.handleDatesSortForNeoTable);
      });
    }
  }


  handleDatesSortForNeoTable = (event) => {
    const targetElement = event.target;
    const sortingDateChild = targetElement.querySelector('#sorting-date');
    const arrowChild = ((targetElement.classList?.contains("neo-icon--small") || (targetElement.classList?.contains("neo-icon-arrow-down")) ||
      (targetElement.classList?.contains("neo-icon-arrow-up"))) && ((targetElement?.offsetParent?.firstChild?.innerHTML?.includes("sorting-date")) || (targetElement?.offsetParent?.lastChild?.innerHTML?.includes("sorting-date"))));
    if (sortingDateChild || arrowChild) {
      setTimeout(() => {
        const myElement = document.querySelector('.neo-dropdown__content');
        if (myElement) {
          myElement.classList.add('sorting-classes');
          const parentItem = document.querySelector(".sorting-classes");
          const children = parentItem.children;
          children[0].textContent = 'Clear Sort';
          children[1].textContent = 'Older to Newer';
          children[2].textContent = 'Newer to Older';
        }
      }, 10)
    }
  }

  handleChangeSortName = () => {
    setTimeout(() => {
      const myElement = document.querySelector('.neo-dropdown__content');
      if (myElement) {
        myElement.classList.add('sorting-classes');
        const parentItem = document.querySelector(".sorting-classes");
        const children = parentItem.children;
        children[0].textContent = 'Clear Sort';
        children[1].textContent = 'Older to Newer';
        children[2].textContent = 'Newer to Older';
      }
    }, 10)
  }

  calculateActionChildren = (record) => {
    const { userRoleTemplate } = this.props;
    const isEditDisabled = userRoleTemplate?.templateType !== CONFIG.roleTypes.superAdmin && record.adminAccess === CONFIG.roleTemplateAccess.readOnly
    const deleteView = this.filterSort?.filter?.viewDeletedItems;
    const viewRatingDisabled = record?.rating === 0;
    if (deleteView) {
      return ([
        <MenuItem id="demo-restore"
          disabled={isEditDisabled}
          onClick={() => {
            if (isEditDisabled) return null;
            else this.handleDeleteRestore(record.id, true)
          }}
        >
          <NeoIcon aria-label="menu-aria" style={{
            marginRight: "8px"
          }} icon="history" size="md" />
          Restore
        </MenuItem>,
        <MenuItem id="demo-delete" title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined}
          disabled={isEditDisabled}
          onClick={() => {
            if (isEditDisabled) return null;
            else this.handleDeleteRestore(record.id)
          }}
        >
          <NeoIcon aria-label="menu-aria" style={{
            marginRight: "8px"
          }} icon="trash" size="md" />
          Delete
        </MenuItem>
      ])
    }
    else {
      return ([
        <MenuItem onClick={() => {
          if (isEditDisabled) return null;
          else {
            this.props.handleOnClickAction({
              component: CONFIG.editComponentRoute.demos,
              tabTitle: record?.title,
              recordId: record?.id
            })
          }
        }}
          id="demo-edit" title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} disabled={isEditDisabled}>
          <NeoIcon aria-label="menu-aria" style={{
            marginRight: "8px"
          }} icon="edit" size="md" />
          Edit
        </MenuItem>,
        <MenuItem
          onClick={() => {
            if (isEditDisabled) return null;
            else {
              this.props.handleOnClickAction({
                component: CONFIG.cloneComponentRoute.demos,
                tabTitle: record?.title,
                recordId: record?.id,
                isClone: true
              })
            }
          }}
          id="demo-clone"
          title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} disabled={isEditDisabled}>
          <NeoIcon aria-label="menu-aria" style={{
            marginRight: "8px"
          }} icon="copy" size="md" />
          Clone
        </MenuItem>,
        <MenuItem id="demo-delete" title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined}
          disabled={isEditDisabled}
          onClick={() => {
            if (isEditDisabled) return null;
            else this.handleDeleteRestore(record.id)
          }}
        >
          <NeoIcon aria-label="menu-aria" style={{
            marginRight: "8px"
          }} icon="trash" size="md" />
          Delete
        </MenuItem>,
        <MenuItem
          onClick={() => {
            if ((isEditDisabled || viewRatingDisabled)) return null;
            else {
              this.props.handleOnClickAction({
                component: CONFIG.cloneComponentRoute.demosRating,
                tabTitle: record?.title,
                recordId: record?.id,
              })
            }
          }}
          disabled={(isEditDisabled || viewRatingDisabled)}
          title={(viewRatingDisabled) ? CONFIG.warningMessages.noRating : isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} >
          <NeoIcon aria-label="menu-aria" style={{
            marginRight: "8px"
          }} icon="star-half-filled" size="md" />
          View Ratings
        </MenuItem>
      ])
    }
  }

  renderTable = () => {
    const { demos, timeZoneName } = this.props;
    const neoColumns = [
      {
        Cell: (a) => {
          return <span style={{ textAlign: "center", }}>{a?.cell?.row?.original?.title}</span>
        },
        Header: "Title",
        sortType: "string",
        accessor: "title",
        disableFilters: true,
      },
      {
        Cell: (a) => {
          return <span style={{ textAlign: "center", }}>{a?.cell?.row?.original?.demoType}</span>
        },
        Header: "Demo Type",
        sortType: "string",
        disableFilters: true,
        accessor: "demoType",
      },
      {
        Cell: (a) => {
          return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.activatedTime ? moment.tz(a?.cell?.row?.original?.activatedTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"}</span>
        },
        Header: <span onClick={this.handleChangeSortName} id='sorting-date'>Activation Date</span>,
        sortType: (rowA, rowB) => {
          const startTimeA = moment.tz(rowA?.original?.activatedTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
          const startTimeB = moment.tz(rowB?.original?.activatedTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
          if (startTimeA.isBefore(startTimeB)) {
            return -1;
          }
          if (startTimeA.isAfter(startTimeB)) {
            return 1;
          }
          return 0;
        },
        accessor: "activatedTime",
        disableFilters: true,
      },
      {
        Cell: (a) => {
          return <span style={{ textAlign: "center" }}>{<NeoIcon aria-label="menu-aria" style={a?.cell?.row?.original?.isActive ? { color: "#088A08" } : { color: "#DA291C" }} icon={a?.cell?.row?.original?.isActive ? "available" : "missed"} />}</span>
        },
        Header: "Status",
        sortType: "basic",
        accessor: "isActive",
        disableFilters: true,
      },
      {
        Cell: (a) => {
          const record = a.cell.row.original;
          return <span style={{ textAlign: "center" }}>{record?.rating === 0 ? "No ratings yet" : <Rate style={{ color: "#F38D00" }}
            className="review-Rating" disabled allowHalf defaultValue={record?.rating} />}</span>
        },
        Header: "Ratings",
        sortType: "basic",
        disableFilters: true,
        accessor: "rating",
      },
      {
        Cell: (a) => {
          const record = a.cell.row.original;
          const children = this.calculateActionChildren(record)
          return (
            <NeoMenu positionToToggle="left" menuRootElement={<NeoIcon aria-label="menu-aria" id={record?.id} style={{ cursor: "pointer", color: "#1B77AF" }} icon="typing" size='md' />}>
              {children}
            </NeoMenu>
          )
        },
        Header: "  ",
        disableFilters: true,
        disableSortBy: true
      }
    ]
    return (
      this.state.loading ? null : <NeoTable
        columns={neoColumns}
        data={demos || []}
        className="table-actions"
        allowColumnFilter={false}
        itemsPerPageOptions={[10, 20, 50, 100]}
        showRowSeparator
        initialStatePageIndex={this.state.currentPage}
        handlePageChange={(newPageIndex, newPageSize) => {
          this.setState({ currentPage: newPageIndex })
        }}
        {...otherFields}
      />
    )
  }

  renderFilterPanel = () => {
    return (
      <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
        {<div style={{ display: "flex", flex: "1" }}>
          <NeoButton className="remove-btn-index" id="demo-create-new" onClick={() => this.props.handleOnClickAction({
            component: CONFIG.createComponentRoute.demos,
            tabTitle: "Create",
          })}>Create Demo</NeoButton>
        </div>}
        <div style={{ display: "flex", width: "100%", flex: "1", flexDirection: "row-reverse", gap: "24px" }}>
          <div >
            <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
              <NeoButton id="demo-list-export" className="export-download-button" icon="chevron-down" variant="secondary">Download</NeoButton>
            </Dropdown>
          </div>
          <div >
            <NeoIcon aria-label="menu-aria" size="md" id="demo-list-filter" className="clickable" onClick={() => this.setState(prevState => ({
              showFilter: !prevState.showFilter
            }))} title="Filter" icon={(this.state.showFilter || (!checkIfFilterSortIsEmpty({ filter: this.filterSort }))) ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
          </div>
          <div >
            <NeoIcon aria-label="menu-aria" size="md" id="demo-list-refresh" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
          </div>
        </div>
      </div>
    )
  }

  renderTabContents = () => {
    return (
      <React.Fragment>
        <div>
          {this.renderFilterPanel()}
        </div>
        <div className="height-neo-table">
          {this.renderTable()}
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { timeZoneName, allDemos } = this.props;
    const drawerContainer = document.getElementById('#component-content');
    const formFields = [
      {
        label: "Title",
        type: CONFIG.formFieldTypes.SELECT,
        key: "title",
        valueKey: "text",
        data: (allDemos) ? allDemos.map(demo => ({ text: demo.title })) : [],
        colSpan: 24,
        value: this.filterSort?.filter?.title
      },
      {
        label: "Activation / Expired Date",
        type: CONFIG.formFieldTypes.RANGE_PICKER,
        key: "startEndTime",
        colSpan: 24,
        value: [this.filterSort?.filter?.startTime, this.filterSort?.filter?.endTime, this.filterSort?.filter?.includeStartAndEndDate]
      },
      {
        label: "Demo Type",
        type: CONFIG.formFieldTypes.SELECT,
        key: "demoType",
        data: CONFIG.filterDataSet.allDemoTypes,
        colSpan: 24,
        value: this.filterSort?.filter?.demoType
      }
    ]
    return (
      <React.Fragment>
        <div style={{ display: "flex", marginTop: "28px" }}>
          <div style={{ display: "flex", flex: "1", color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px" }}>
            Manage Demo List
          </div>
        </div>
        <Spin spinning={this.state.loading} indicator={antIcon} >
          <div style={{ marginTop: "16px" }}>
            <Tabs disabled={this.filterSort?.filter?.viewDeletedItems} activeKey={this.state.status} className='demo-status-tabs' defaultActiveKey={this.state.status} onChange={this.handleSelectChange}>
              <TabPane disabled={this.filterSort?.filter?.viewDeletedItems} tab="Active" key="true">
                {this.renderTabContents()}
              </TabPane>
              <TabPane tab="Inactive" key="false">
                {this.renderTabContents()}
              </TabPane>
              <TabPane disabled={this.filterSort?.filter?.viewDeletedItems} tab="All" key="all">
                {this.renderTabContents()}
              </TabPane>
            </Tabs>
          </div>
        </Spin>
        <div>
          {this.state.showFilter && (
            <FilterDrawer
              visible={this.state.showFilter}
              width='520'
              handleCloseFilterDrawer={() => this.setState(prevState => ({
                showFilter: false,
                record: null
              }))}
              placement="right"
              drawerContainer={drawerContainer}
              style={{ zIndex: 9999, }}
              maskClosable={false}
              panelHeader="Filters and Export"
              formFields={formFields}
              handleClear={this.handleClear}
              handleSave={this.handleSave}
              timeZone={timeZoneName}
              hideSoftDeleteCheckbox={false}
              label="View Deleted Demos"
              hideSave={false}
              viewDeletedItemsValue={this.filterSort?.filter?.viewDeletedItems}
              handleFilter={this.handleFilter}
            />)}
        </div>

        {this.state.saveReport && <SaveReport closeModal={() => this.setState({ saveReport: false })} reportFilter={this.filterSave} />}
      </React.Fragment >
    );
  }
}

const mapStateToProps = ({ demos, user, tabsLayout }) => {
  return {
    demos: demos.data,
    allDemos: demos.allDemos,
    isEdit: demos.isEdit,
    demosCount: demos.count,
    currentPage: demos.currentPage,
    timeZoneName: user.profile?.timezone,
    developerMode: user.profile?.isDeveloper,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
    currentDemoId: demos.currentDemoId,
    demo: demos,
    currentPageNumber: demos.currentPageNumber,
    pageSize: demos.pageSize,
    demoIndex: demos.demoIndex,
    response: demos.response,
    openedTabs: tabsLayout.panes,
  };
};

export default connect(
  mapStateToProps,
  {
    clearDemos,
    fetchActiveResources,
    fetchDemoCollateralTypes,
    restoreDemo,
    updateScheduleWarning,
    handleOnClickAction,
    toggleSaveReportVisible,
    currentDemoFilter,
    getAllDemosLite,
    fetchAllDemosLite,
    clearFetchedDemos
  }
)(ManageDemo);
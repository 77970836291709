import {Plugin,ButtonView} from 'ckeditor5';


export default class PreviewPlugin extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('previewButton', (locale) => {
            const button = new ButtonView(locale);

            button.set({
                label: 'Preview',
                withText: true,
                tooltip: true
            });

            button.on('execute', () => {
                const content = editor.getData();

                const newTab = window.open();
                newTab.document.open();

                const stylesheets = Array.from(document.styleSheets)
                    .map((style) => style.href)
                    .filter((href) => href)
                    .map((href) => `<link rel="stylesheet" href="${href}">`)
                    .join("\n");

                newTab.document.write(`
                    <html>
                    <head>
                        <title>Preview</title>
                        ${stylesheets}
                        <style>
                            body { padding: 20px; font-family: Arial, sans-serif; }
                            img { max-width: 100%; height: auto; } /* Ensure images fit */
                        </style>
                    </head>
                    <body class="ck-content">
                        ${content} 
                    </body>
                    </html>
                `);

                newTab.document.close();
            });

            return button;
        });
    }
}

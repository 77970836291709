import React from 'react';
import ReactApexCharts from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { Button } from "@avaya/neo-react";
import { Icon } from 'neo-latest';
import { Spin, message, Tabs } from 'antd';
import { connect } from 'react-redux';
import CONFIG from '../../config';
import pushMessage from '../../components/common/PushMessage';
import moment from 'moment-timezone';
import { createCommonFilterObject, createGraphData, createTableColumns, createTableColumnsUserDistribution, createUserDistributionData } from "../../utils/reports";
import {
    runCommonStandardReport,
    exportCommonStandardReport
} from "../../actions"
import ReportOutputTableNeo from './ReportOutputTableNeo';
import { LoadingOutlined } from '@ant-design/icons';
import DemoTrendReport from './DemoTrendReport';
import ReportGlobe from './ReportGlobe';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;
class ReportOutputGraph extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            columns: [],
            activeKey: "1",
            series: [],
            randomKey: new Date().toISOString(),
            options: {
                chart: {
                    id: "apex-react-charts",
                    type: 'bar',
                    fontFamily: "Noto-Sans, sans-serif",
                    height: 500,
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },
                    toolbar: {
                        export: {
                            svg: {
                                filename: "Demo Usage per Quarter"
                            },
                            png: {
                                filename: "Demo Usage per Quarter"
                            }
                        }
                    }
                },
                colors: CONFIG.neoGraphColors,
                xaxis: {
                    categories: [],
                    group: {
                        style: {
                            fontSize: '16px',
                            fontWeight: 600,
                            fontFamily: "Noto-Sans, sans-serif",
                        },
                        groups: []
                    }
                },
                yaxis: {
                    title: {
                        text: "No. of Scheduled Demos",
                        style: {
                            fontSize: '16px',
                            fontWeight: 600,
                            fontFamily: "Noto-Sans, sans-serif",
                        },
                    }
                },
                dataLabels: {
                    enabled: true
                },
                legend: {
                    show: true
                },
                title: {
                    text: '',
                },
                tooltip: {
                    x: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
                noData: {
                    text: "No Data Found",
                    style: {
                        color: "black",
                        fontSize: '22px',
                        fontWeight: 600,
                        fontFamily: "Noto-Sans, sans-serif",
                    }
                }
            },
        };
    }

    handleRunReport = async () => {
        const { reportId } = this.props.params || undefined;
        const { isRunByUser, currentReport, timezone } = this.props;
        const currentReportData = isRunByUser === "fromUser" ? currentReport : this.getCurrentReportData();
        const filterSort = this.filterSort = currentReportData?.filterSortObject;
        isRunByUser !== "fromUser" && this.props.handleUpdateFilterSort(this.filterSort);
        const regions = filterSort?.filter?.regions?.oprVal?.split("\\\\");
        const users = filterSort?.filter?.users?.oprVal?.split("\\\\");
        const countries = filterSort?.filter?.countries?.oprVal?.split("\\\\");
        const quarters = filterSort?.filter?.quarters?.oprVal?.split("\\\\");
        this.setState({ loading: true });
        const utcOffset = moment().tz(timezone).utcOffset();
        await this.props.runCommonStandardReport({ filterSort: this.filterSort, isRunByUser, reportId: parseInt(reportId), reportKey: currentReportData?.reportKey, utcOffset });
        if (currentReportData?.reportKey === CONFIG.standardReportKeys.userDistribution) {
            const columns = createTableColumnsUserDistribution(this.props.generalReportData || [], { users, regions, countries, type: filterSort?.filter?.type?.oprVal, isTotalRequired: (filterSort?.filter?.isTotalRequired?.oprVal || false) });
            this.createUserDistributionGraph(this.props.generalReportData || [], { users, regions, countries, type: filterSort?.filter?.type?.oprVal, isTotalRequired: (filterSort?.filter?.isTotalRequired?.oprVal || false) }, currentReportData, columns);
        }
        else {
            const categories = Array(currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsage ? (regions?.length > 0 ? regions?.length : 4) : quarters?.length).fill((users?.length > 0 ? users : ["avaya", "bp"])?.sort((a, b) => a.localeCompare(b))).flatMap(element => {
                return element?.map(item => {
                    return item === CONFIG.userTypes[0]?.value ? item.charAt(0).toUpperCase() + item.slice(1) : item.toUpperCase();
                });
            });
            const xAxisGroups = (currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsage ? (regions?.length > 0 ? regions : ["APAC", "CALA", "EMEA", "NAR"]) : quarters)?.map(item => ({ title: item, cols: users?.length === 0 ? 2 : users?.length }))?.sort((a, b) => a.title.localeCompare(b.title))
            this.createData(categories, xAxisGroups, currentReportData?.name, currentReportData?.reportKey);
        }
    }

    async componentDidMount() {
        const { isRunByUser, form } = this.props;
        if (isRunByUser) {
            this.handleRunReport();
        }
        else {
            form.validateFields(async (err, values) => {
                if (err) {
                    pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                    return;
                } else this.handleRunReport();
            });
        }
    }

    createData = (userTypeValues, xAxisGroups, reportName, reportKey) => {
        ApexCharts.exec("apex-react-charts", 'resetSeries', { shouldUpdateChart: true });
        const data = createGraphData(this.props.generalReportData ? this.props.generalReportData?.graphData : []);
        const columns = createTableColumns(this.props.generalReportData ? this.props.generalReportData?.tableData : [], reportKey);
        this.setState((prevState) => ({
            ...this.state,
            series: data,
            options: {
                ...prevState.options,
                xaxis: {
                    ...prevState.options.xaxis,
                    categories: userTypeValues,
                    group: {
                        ...prevState.options.xaxis.group,
                        groups: xAxisGroups
                    }
                },
                dataLabels: {
                    enabled: (reportKey === CONFIG.standardReportKeys.demoScheduleTrend || data?.length > 4) ? false : true,
                },
                tooltip: {
                    y: {
                        formatter: (value) => {
                            const selectedValue = this.props.form.getFieldValue("reportType")
                            if (reportKey === CONFIG.standardReportKeys.demoScheduleTrend && (!selectedValue || selectedValue?.length === 0 || (selectedValue?.length === 1 && selectedValue?.includes("percentage")))) {
                                return `${value} %`
                            }
                            else return value
                        }
                    }
                },
                toolbar: {
                    export: {
                        svg: {
                            filename: reportName
                        },
                        png: {
                            filename: reportName
                        }
                    }
                }
            },
            loading: false,
            columns: columns,
        }));
    }

    createUserDistributionGraph = (data, values, report, columns) => {
        if (!data || data?.length === 0) {
            this.setState({
                series: [],
                columns: [],
                loading: false
            })
        }
        else if (values?.type && (values?.regions?.length > 0 || values?.countries?.length > 0)) {
            const xCategories = (values?.type === "regions" ? (values?.regions?.length > 0 ? values?.regions : ["APAC", "CALA", "EMEA", "NAR"]) : values?.countries)?.sort((a, b) => a.localeCompare(b))
            const graphData = createUserDistributionData(data, values);
            this.setState((prevState) => ({
                columns,
                loading: false,
                series: graphData,
                options: {
                    ...prevState.options,
                    chart: {
                        ...prevState?.options?.chart,
                        stacked: true
                    },
                    xaxis: {
                        ...prevState.options.xaxis,
                        categories: xCategories,
                        group: {
                            ...prevState.options.xaxis.group,
                            groups: []
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '50%',
                            endingShape: 'rounded'
                        },
                    },
                    colors: CONFIG.neoGraphColors,
                    yaxis: {
                        title: {
                            text: "Total No. of Users",
                            style: {
                                fontSize: '16px',
                                fontWeight: 600,
                                fontFamily: "Noto-Sans, sans-serif",
                            },
                        }
                    },
                    toolbar: {
                        export: {
                            svg: {
                                filename: report?.name
                            },
                            png: {
                                filename: report?.name
                            }
                        }
                    }
                }
            }))
        }
        else {
            const categories = data.map(item => item.entityName);
            const series = [{ name: 'Total Countries Count', data: data.map(item => item.totalCountriesCount) }, { name: 'Total Users Count', data: data.map(item => item.totalUsersCount) }];
            this.setState((prevState) => ({
                ...this.state,
                series: series,
                options: {
                    chart: {
                        ...prevState?.options?.chart,
                        stacked: true
                    },
                    xaxis: {
                        categories,
                        group: {
                            style: {
                                fontSize: '16px',
                                fontWeight: 600,
                                fontFamily: "Noto-Sans, sans-serif",
                            },
                            groups: []
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '30%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    colors: CONFIG.neoGraphColors,
                    yaxis: {
                        title: {
                            text: "Total No. of Users",
                            style: {
                                fontSize: '16px',
                                fontWeight: 600,
                                fontFamily: "Noto-Sans, sans-serif",
                            },
                        }
                    },
                    toolbar: {
                        export: {
                            svg: {
                                filename: report?.name
                            },
                            png: {
                                filename: report?.name
                            }
                        }
                    }
                },
                loading: false,
                columns: columns
            }))
        }
    }

    handleRunQuery = () => {
        const { form, isRunByUser, currentReport, timezone } = this.props;
        const { reportId } = this.props.params || undefined;
        const currentReportData = isRunByUser === "fromUser" ? currentReport : this.getCurrentReportData();
        form.validateFields(currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsage ?
            ['year', 'quarters', 'users', 'regions', 'demoPurposes']
            : (currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsageSolutionDemo || currentReportData?.reportKey === CONFIG.standardReportKeys.demoScheduleTrend) ? ['year', 'quarters', "users", 'type', 'demoPurposes', `${form.getFieldValue("type")}`, "reportType"]
                : ["users", `${form.getFieldValue("type")}`, "type", "isTotalRequired"]
            , async (err, values) => {
                if (err) {
                    pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                    return;
                }
                else {
                    this.setState({ loading: true });
                    const utcOffset = moment().tz(timezone).utcOffset();
                    this.filterSort = createCommonFilterObject(values);
                    isRunByUser !== "fromUser" && this.props.handleUpdateFilterSort(this.filterSort);
                    await this.props.runCommonStandardReport({ filterSort: this.filterSort, isRunByUser, reportId: parseInt(reportId), reportKey: currentReportData?.reportKey, utcOffset });
                    if (currentReportData?.reportKey === CONFIG.standardReportKeys.userDistribution) {
                        const columns = createTableColumnsUserDistribution(this.props.generalReportData || [], values);
                        this.createUserDistributionGraph(this.props.generalReportData || [], values, currentReportData, columns);
                        this.setState({ randomKey: new Date().toISOString() })
                        if (!((values?.type === "countries" || values?.type === "regions") && (values?.countries?.length > 0 || values?.regions?.length > 0))) {
                            this.setState({ activeKey: "1" })
                        }
                    }
                    else {
                        const categories = Array(currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsage ? (values?.regions?.length > 0 ? values?.regions?.length : 4) : values?.quarters?.length).fill((values?.users?.length > 0 ? values?.users : ["avaya", "bp"])?.sort((a, b) => a.localeCompare(b))).flatMap(element => {
                            return element?.map(item => {
                                return item === CONFIG.userTypes[0]?.value ? item.charAt(0).toUpperCase() + item.slice(1) : item.toUpperCase();
                            });
                        });
                        const xAxisGroups = (currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsage ? (values?.regions?.length > 0 ? values?.regions : ["APAC", "CALA", "EMEA", "NAR"]) : values?.quarters)?.map(item => ({ title: item, cols: values?.users?.length === 0 ? 2 : values?.users?.length }))?.sort((a, b) => a.title.localeCompare(b.title))
                        this.createData(categories, xAxisGroups, currentReportData?.name, currentReportData?.reportKey);
                    }
                }
            })
    }

    getCurrentReportData = (isAll = false) => {
        const { reportTabDetails, isCloneReport, isGeneralReport } = this.props;
        const { reportId } = this.props.params || undefined;
        const id = reportId !== undefined ? reportId : "-1";
        if (isAll) return reportTabDetails?.find(item => (
            (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isCloneReport) && (item?.isGeneralReport === isGeneralReport)))
        return reportTabDetails?.find(item => (
            (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isCloneReport) && (item?.isGeneralReport === isGeneralReport)))?.details;
    }

    handleExport = async () => {
        const { isRunByUser, currentReport } = this.props;
        const { reportId } = this.props.params || undefined;
        const currentReportData = isRunByUser === "fromUser" ? currentReport : this.getCurrentReportData();
        const reportName = currentReportData?.name + ".xlsx";
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        this.props.exportCommonStandardReport({ name: reportName, filterSort: this.filterSort, isRunByUser, reportId: parseInt(reportId), reportKey: currentReportData?.reportKey })
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }

    render() {
        const { isRunByUser, currentReport, shouldHideRunQuery, generalReportData, form } = this.props;
        const currentReportData = isRunByUser === "fromUser" ? currentReport : this.getCurrentReportData();
        const formValues = form.getFieldsValue();
        const isGlobeMapDisabled = !((formValues?.type === "countries" || formValues?.type === "regions") && (formValues?.countries?.length > 0 || formValues?.regions?.length > 0))
        return (
            <Spin indicator={antIcon} spinning={this.state.loading}>
                <div style={{ marginTop: "16px" }}>
                    <div style={{ marginBottom: "16px", display: 'flex', justifyContent: "flex-end" }}>
                        {this.props?.isRunByUser !== "fromUser" && <Button className="left-spacing" variant="secondary" onClick={this.handleExport}>Download</Button>}
                        {!shouldHideRunQuery && <Button className="left-spacing" onClick={this.handleRunQuery}>Run Query</Button>}
                    </div>
                    {currentReportData?.reportKey === CONFIG.standardReportKeys.demoScheduleTrend ? <DemoTrendReport form={this.props.form} currentReport={currentReportData} reportData={generalReportData} />
                        : currentReportData?.reportKey !== CONFIG.standardReportKeys.userDistribution ? <ReactApexCharts options={this.state.options} series={this.state.series} type="bar" height={500} /> : null}
                    <br />
                    {currentReportData?.reportKey === CONFIG.standardReportKeys.userDistribution &&
                        <Tabs onChange={(key) => {
                            this.setState({ activeKey: key })
                        }} className='globe-map-tabs' type="card" style={{ margin: "16px" }} activeKey={this.state.activeKey}>
                            <TabPane
                                tab={<span><Icon aria-label="aria-menu" style={{ marginRight: "8px" }} icon="graph" />Bar Graph</span>}
                                key="1"
                            >
                                <ReactApexCharts options={this.state.options} series={this.state.series} type="bar" height={500} />
                            </TabPane>
                            <TabPane
                                disabled={isGlobeMapDisabled}
                                tab={<span><Icon aria-label="aria-menu" style={{ marginRight: "8px" }} icon="global" />Globe Map</span>}
                                key="2"
                            >
                                <ReportGlobe randomKey={this.state.randomKey} generalReportData={generalReportData} form={this.props.form} />
                            </TabPane>
                        </Tabs>}
                    <ReportOutputTableNeo
                        form={this.props.form}
                        tableColumns={this.state.columns}
                        currentReportData={currentReportData}
                        isRunByUser={this.props?.isRunByUser === "fromUser" ? true : false}
                        filterSort={this.filterSort}
                    />
                </div>
            </Spin>
        )
    }
}

const mapStateToProps = ({ currentReport, user, userReports }, props) => {
    const acutalReport = props?.isRunByUser === "fromUser" ? userReports : currentReport?.reportDetails;
    return {
        data: props?.isRunByUser === "fromUser" ? acutalReport.runReportData : currentReport?.runReportData,
        generalReportData: props?.isRunByUser === "fromUser" ? acutalReport.runGeneralReportData : currentReport?.runGeneralReportData,
        currentReport: props?.isRunByUser === "fromUser" ? props?.currentReport : acutalReport,
        isRunByUser: props?.isRunByUser,
        reportTabDetails: currentReport.reportDetails,
        timezone: user.profile ? user.profile.timezone : undefined,
    };
}

export default connect(mapStateToProps, {
    runCommonStandardReport,
    exportCommonStandardReport
})(ReportOutputGraph);
import React from "react";
import { connect, batch } from "react-redux";
import moment from "moment-timezone";
import { analytics } from "../../firebase";
import { Row, Col, Card, Icon, Tooltip, Button, Modal, Divider } from "antd";
import {
  fetchUserSchedules,
  fetchDashboardActiveSchedules,
  toggleIsFetchingSchedule,
  fetchScheduleById,
  selectTitle,
  toggleBackButton,
  fetchActiveDemoResources,
  toggleIsFetchingUserSchedules,
  deleteMaintenanceSchedule,
  fetchSchedulesOverDuration,
  updateCurrentDemoSchedule,
  updateSchedulerStartEndTime,
  fetchDataCenters,
  clearMostUsedDemos,
  toggleIsFetchingDashboardSchedules,
  updateUserSchedulesParams,
  optOutSchedule,
  toggleIsReschedule,
  fetchAdminDashboardDailyTestingReport,
  deleteDashboardSchedule,
  fetchUpcomingMaintenanceForAdmins,
  fetchPendingApprovalsForAdmins,
  fetchCompletedApprovalsForAdmins,
  fetchingDashboardAdminRequests,
  clearAdminDashboard,
  fetchAdminDashboardRequestStats,
  clearAdminRequestData,
  isFetchingDashboardRequestStats,
  showAdminDashboardUpcomingSchedulesSection,
  showAdminDashboardUpcomingMaintenanceSection,
  clearAdminDashboardSectionViews,
  toggleIsFetchingDailyReports,
  openLinkInNewTab,
  toggleIsFetchingAdminDemoStatus,
  fetchAdminDemoStatus,
  fetchAdminSFDCData,
  toggleIsFetchingSFDCStatus,
} from "../../actions";
import DashboardSchedules from "../Dashboard/DashboardSchedules";
import AdminDashboardApprovals from "./AdminDashboardApprovals";
import RequestStatistics from "./RequestStatistics";
import CONFIG from "../../config";
import history from "../../history";
import openConfirm from "../common/Confirm";
import openInfo from "../common/Info";
import { getDemoCardWarnings } from "../../utils/dashboardCardMessage";
import { Icon as NeoIcon } from "@avaya/neo-react";
import AdminDashboardDemoStats from "./AdminDashboardDemoStats";
import AdminSFDCStatistics from "./AdminSFDCStatistics";

class AdminDashboard extends React.Component {
  state = {
    height: `${document.body.clientHeight - 123}px`,
    loading: false,
  };

  handleDemoScheduleUpdate = async (
    scheduleId,
    schedule,
    isReschedule,
    isEdit,
    fromRescheduleEmail,
    isBatchEdit
  ) => {
    const { timezone, isActiveSchedules } = this.props;
    const { demo } = schedule;
    const {
      isDemoAlreadyRequested,
      showRequestButton,
      showScheduleButton,
      demoType,
    } = demo;

    const schedulerStartTime = isActiveSchedules
      ? moment(schedule.startTime).tz(timezone).startOf("day").toISOString()
      : moment().tz(timezone).startOf("day").toISOString();
    const schedulerEndTime = isActiveSchedules
      ? moment(schedule.startTime).tz(timezone).endOf("day").toISOString()
      : moment().tz(timezone).endOf("day").toISOString();

    this.props.updateSchedulerStartEndTime(
      schedulerStartTime,
      schedulerEndTime
    );

    //  !isReschedule is to check if the user is editing the schedule.
    if (showScheduleButton || isEdit) {
      await this.props
        .fetchActiveDemoResources(demo?.id)
        // .then(async () => await this.props.fetchSchedulesOverDuration(demo?.id, startTime, endTime))
        .then(() =>
          this.props.updateCurrentDemoSchedule(
            { ...schedule, id: scheduleId },
            fromRescheduleEmail
          )
        )
        .then(() =>
          this.props.openLinkInNewTab(
            `Schedule Demo - ${schedule?.demo?.title}`,
            isBatchEdit && demoType === CONFIG.demoTypes.standardDemo
              ? `/scheduler/standard/${scheduleId}/edit/isReschedule=${isReschedule}/isBatchEdit=${isBatchEdit}/batchId=${schedule?.batchNumber}`
              : `/scheduler/${demoType === CONFIG.demoTypes.standardDemo
                ? "standard"
                : "lab"
              }/${scheduleId}/edit/isReschedule=${isReschedule}/fromManageSide=${false}`
          )
        );
    } else if (showRequestButton || isDemoAlreadyRequested) {
      history.replace({
        pathname: `/demo-registation/${demo?.id}/${!!isDemoAlreadyRequested}`,
        fromDashboard: true,
        pathValues: this.props.pathValues,
      });
    }
  };

  handleDemoScheduleCancel = async (scheduleId, demoType, fromEmail) => {
    await this.props.deleteDashboardSchedule(scheduleId, demoType);
    Modal.destroyAll();
  };

  handleMaintenanceCancel = (maintenanceId) => {
    this.props.deleteMaintenanceSchedule(maintenanceId).then(() => {
      this.props.fetchUserSchedules({
        isActive: true,
        viewType: CONFIG.dashboard.schedulesViewType.CARD,
      });
      this.props.fetchUserSchedules(this.props.params);
    });
  };

  handleOptout = async (record, demoType) => {
    this.props.toggleIsFetchingDashboardSchedules(true);
    await this.props.optOutSchedule(record, demoType);
    this.props.toggleIsFetchingDashboardSchedules(false);
  };

  handleDeleteScheduleForMyself = async (record, demoType) => {
    const { userDetails } = this.props;
    const eapUsers = record?.participants?.eapUsers?.filter(
      (eachUser) => eachUser?.id !== userDetails?.id
    );

    const schedule = {
      createdBy: record?.createdBy,
      demo: record?.demo,
      demoPurpose: record?.demoPurpose,
      endTime: record?.endTime,
      endTimeBuffer: record?.endTimeBuffer,
      id: record?.id,
      batchNumber: record?.batchNumber,
      isOneTime: record?.isOneTime,
      isPreRegistration: record?.isPreRegistration,
      purposeAttributesSchedule: record?.purposeAttributesSchedule,
      resource: record?.resource,
      startTime: record?.startTime,
      startTimeBuffer: record?.startTimeBuffer,
      userAnswers: record?.userAnswers,
      participants:
        {
          ...record.participants,
          eapUsers,
        } || null,
    };

    await this.props.updateUserSchedulesParams({
      isActive: true,
      viewType: CONFIG.dashboard.schedulesViewType.CARD,
    });
    this.handleOptout(schedule, demoType);
    Modal.destroyAll();
  };

  handleDeleteEmailRedirection = async () => {
    const { timezone, userDetails } = this.props;
    const scheduleId = localStorage.getItem("scheduleId");
    this.props.toggleIsFetchingSchedule(true);
    localStorage.removeItem("scheduleId");
    localStorage.removeItem("deleteSchedule");
    const scheduleDetails = await fetchScheduleById(scheduleId);
    this.setState({ loading: true });
    const isScheduleExpired = scheduleDetails && scheduleDetails?.isExpired;
    const isScheduleSoftDeleted =
      scheduleDetails && scheduleDetails?.isSoftDelete;

    if (
      isScheduleExpired !== undefined &&
      !isScheduleExpired &&
      !isScheduleSoftDeleted
    ) {
      const demoType = scheduleDetails?.demo?.demoType;
      const hasParticipants = scheduleDetails?.participants !== null;
      const scheduleCreator =
        hasParticipants &&
        [
          ...scheduleDetails?.participants?.eapUsers,
          ...scheduleDetails?.participants?.extUsers,
        ]?.filter((par) => par?.isCreator === true)?.[0];
      const isLoggedInUserCreator = scheduleCreator?.id === userDetails?.id;
      const showSingleScheduleDelete =
        !hasParticipants || isLoggedInUserCreator;
      const startDateTime = moment
        .tz(scheduleDetails?.startTime, timezone)
        .format(CONFIG.dateFormats.userDateTime);
      const endDateTime = moment
        .tz(scheduleDetails?.endTime, timezone)
        .format(CONFIG.dateFormats.userDateTime);
      const endTime = moment
        .tz(scheduleDetails?.endTime, timezone)
        .format(CONFIG.dateFormats.userTime);
      const bufferStart = scheduleDetails?.demo?.startTimeBuffer;
      const bufferEnd = scheduleDetails?.demo?.endTimeBuffer;
      const bufferStartTime =
        bufferStart > 0
          ? moment
            .tz(scheduleDetails?.startTime, timezone)
            .subtract(bufferStart, "m")
          : undefined;
      const bufferEndTime =
        bufferEnd > 0
          ? moment.tz(scheduleDetails?.endTime, timezone).add(bufferEnd, "m")
          : undefined;
      const scheduleCard = (
        <Card
          id="demo-card"
          size="small"
          style={{ marginTop: "15px" }}
          title={
            <div className="demo-card-timings">
              <Row className="top-spacing-6">
                <Col span={24}>
                  <span>
                    {startDateTime} -{" "}
                    {demoType === CONFIG.demoTypes.standardDemo
                      ? endTime
                      : endDateTime}{" "}
                    &nbsp;
                    {bufferStartTime &&
                      (bufferEndTime ||
                        demoType !== CONFIG.demoTypes.standardDemo) && (
                        <Tooltip
                          title={
                            <>
                              <div>
                                Buffer Start Time -{" "}
                                {bufferStartTime.format(
                                  CONFIG.dateFormats.userDateTime
                                )}
                              </div>
                              {demoType === CONFIG.demoTypes.standardDemo && (
                                <div>
                                  Buffer End Time -{" "}
                                  {bufferEndTime.format(
                                    CONFIG.dateFormats.userDateTime
                                  )}
                                </div>
                              )}
                            </>
                          }
                        >
                          <Icon aria-label="aria-menu"
                            type="info-circle"
                            className="text-color-primary"
                            width={1}
                          />
                        </Tooltip>
                      )}
                  </span>
                </Col>
              </Row>
            </div>
          }
        >
          <span className="medium-text bold">
            {scheduleDetails?.demo?.title}
          </span>
          {demoType === CONFIG.demoTypes.standardDemo && (
            <div className="demoApprovedStatus">
              <span className="demo-card-body-details bold">Resource: </span>
              <span className="demo-card-body-details">
                {scheduleDetails?.resource?.name}
              </span>
            </div>
          )}

          {hasParticipants && !isLoggedInUserCreator && (
            <div className="delete-option-modal center-align-content ">
              <Button
                className="delete-option-button-email primary-action-button-filled"
                onClick={() =>
                  this.handleDeleteScheduleForMyself(
                    scheduleDetails,
                    demoType,
                    true
                  )
                }
              >
                {" "}
                Delete for me
              </Button>
              <Button
                className="delete-option-button-email primary-action-button-filled"
                onClick={() =>
                  this.handleDemoScheduleCancel(
                    scheduleDetails.id,
                    demoType,
                    true
                  )
                }
              >
                {" "}
                Delete for Everyone
              </Button>
            </div>
          )}
        </Card>
      );

      showSingleScheduleDelete &&
        openConfirm(
          "Yes",
          () => {
            (!hasParticipants || isLoggedInUserCreator) &&
              this.handleDemoScheduleCancel(scheduleDetails.id, demoType, true);
          },
          null,
          scheduleCard,
          CONFIG.deleteModalTitle?.replace("<placeholder>", "schedule"),
          "access-alert"
        );
      hasParticipants &&
        !isLoggedInUserCreator &&
        Modal.warning({
          width: "500px",
          title: "Confirm Delete?",
          content: scheduleCard,
          okText: "Cancel",
        });
    } else
      openInfo(
        "Warning!",
        " Sorry! You are no longer authorized to access this schedule or this schedule has been removed",
        null,
        "OK",
        true
      );
    this.setState({ loading: false });
  };

  handleSelectSchedule = (selectedDemo) => {
    history.replace({ pathname: `/solutions/demo/${selectedDemo.demo?.id}` });
  };

  showAccessWarning = () => {
    openInfo(
      "Warning!",
      " Sorry! You are no longer authorized to access this schedule or this schedule has been removed",
      null,
      "OK",
      true
    );
  };

  checkIfScheduleIsEditable = (schedule, reschedule) => {
    const isScheduleExpired = reschedule
      ? false
      : schedule && schedule?.isExpired;
    const isSoftDeleted =
      schedule && reschedule ? false : schedule?.isSoftDelete;

    // To Show Edit button : isDemoActive ->True, hasAccessToDemo--> True, isDemoExpired-->False, isDemoSoftDeleted-->False
    const showEditButton = schedule && getDemoCardWarnings(schedule)[2];
    const isScheduleEditable =
      !isScheduleExpired && !isSoftDeleted && showEditButton;
    localStorage.removeItem("scheduleId");
    return isScheduleEditable;
  };

  handleEditEmailRedirection = async () => {
    const scheduleId = localStorage.getItem("scheduleId");
    this.props.toggleIsFetchingSchedule(true);
    localStorage.removeItem("scheduleId");
    localStorage.removeItem("editSchedule"); // Remove the cookies
    const schedule = await fetchScheduleById(scheduleId);
    this.setState({ loading: true });

    const isScheduleEditable = this.checkIfScheduleIsEditable(schedule, false);
    const demoType = schedule?.demo?.demoType; // Check if schedule is editable
    if (isScheduleEditable) {
      if (demoType !== CONFIG.demoTypes.standardDemo) {
        this.props.openLinkInNewTab(
          `Schedule Demo - ${schedule?.demo?.title}`,
          `/scheduler/lab/${schedule?.id
          }/edit/isReschedule=${false}/fromManageSide=${false}`
        );
        return;
      } else if (demoType === CONFIG.demoTypes.standardDemo) {
        const { demo } = schedule;
        await this.props
          .fetchActiveDemoResources(demo?.id)
          // .then(async () => await this.props.fetchSchedulesOverDuration(demo?.id, startTime, endTime))
          .then(() =>
            this.props.updateCurrentDemoSchedule({
              ...schedule,
              id: schedule?.id,
            })
          )
          .then(() =>
            this.props.openLinkInNewTab(
              `Schedule Demo - ${schedule?.demo?.title}`,
              `/scheduler/${demoType === CONFIG.demoTypes.standardDemo ? "standard" : "lab"
              }/${schedule?.id
              }/edit/isReschedule=${false}/fromManageSide=${false}`
            )
          );
      } else this.showAccessWarning();
    } else this.showAccessWarning();
    this.setState({ loading: false });
  };

  handleRescheduleEmailRedirection = async () => {
    const scheduleId = localStorage.getItem("scheduleId");
    this.props.toggleIsFetchingSchedule(true);
    localStorage.removeItem("scheduleId");
    localStorage.removeItem("reSchedule");
    const schedule = await fetchScheduleById(scheduleId);
    this.setState({ loading: true });
    const isScheduleReschedulable = this.checkIfScheduleIsEditable(
      schedule,
      true
    );
    const { isDemoAlreadyRequested, showRequestButton } = schedule?.demo || [];
    const shouldRedirectToRequestPage =
      showRequestButton || isDemoAlreadyRequested;
    const demoType = schedule?.demo?.demoType;
    if (isScheduleReschedulable) {
      if (shouldRedirectToRequestPage) {
        this.handleSelectSchedule(schedule);
      } else {
        if (demoType !== CONFIG.demoTypes.standardDemo) {
          this.props.openLinkInNewTab(
            `Schedule Demo - ${schedule?.demo?.title}`,
            `/scheduler/lab/${schedule?.id
            }/edit/isReschedule=${true}/fromManageSide=${false}`
          );
          return;
        } else if (demoType === CONFIG.demoTypes.standardDemo) {
          this.props.toggleIsReschedule(true);
          const isReschedule = true;
          const isEdit = false;
          const fromRescheduleEmail = true;
          this.handleDemoScheduleUpdate(
            schedule.id,
            schedule,
            isReschedule,
            isEdit,
            fromRescheduleEmail,
            false
          );
        } else this.showAccessWarning();
      }
    } else this.showAccessWarning();
    this.setState({ loading: false });
  };

  componentDidMount() {
    const { isLoginComplete } = this.props;
    batch(async () => {
      if (isLoginComplete) {
        this.props.selectTitle("AdminDashboard");
        this.props.toggleBackButton(false);
        this.props.toggleIsFetchingDailyReports(true);
        this.props.toggleIsFetchingAdminDemoStatus(true);
        this.props.toggleIsFetchingUserSchedules(true, "isLoading");
        this.props.toggleIsFetchingDashboardSchedules(true);
        await this.props.fetchDashboardActiveSchedules();
        await this.props.fetchUpcomingMaintenanceForAdmins();
        this.props.toggleIsFetchingDashboardSchedules(true);
        this.props.toggleIsFetchingAdminDemoStatus(true);
        await this.props.fetchAdminDemoStatus();
        this.props.toggleIsFetchingSFDCStatus(true);
        await this.props.fetchAdminSFDCData();
        this.props.toggleIsFetchingSFDCStatus(false);
        if (localStorage.getItem("scheduleId") !== null) {
          if (localStorage.getItem("deleteSchedule"))
            this.handleDeleteEmailRedirection();
          else if (localStorage.getItem("editSchedule"))
            this.handleEditEmailRedirection();
          else if (localStorage.getItem("reSchedule"))
            this.handleRescheduleEmailRedirection();
        }
        this.props.toggleIsFetchingDashboardSchedules(false);
        if (
          this.props.todaySchedules?.length === 0 &&
          this.props.upcomingSchedules?.length === 0
        )
          this.props.showAdminDashboardUpcomingSchedulesSection(false);
        if (this.props.upcomingMaintenances?.length === 0)
          this.props.showAdminDashboardUpcomingMaintenanceSection(false);
        this.props.fetchDataCenters();
        this.props.fetchAdminDashboardDailyTestingReport();
        this.props.toggleIsFetchingDailyReports(false);
        this.props.toggleIsFetchingAdminDemoStatus(false);
        this.props.fetchingDashboardAdminRequests(false);
        await this.props.isFetchingDashboardRequestStats(true);
        await this.props.fetchAdminDashboardRequestStats();
        await this.props.toggleIsFetchingAdminDemoStatus(false);
        await this.props.isFetchingDashboardRequestStats(false);
      }
    });
  }

  componentWillUnmount() {
    this.props.clearMostUsedDemos();
    this.props.clearAdminDashboard();
    this.props.clearAdminDashboardSectionViews();
  }

  handleContactUsNavigation = () => {
    const { userDetails } = this.props;
    const {
      contactUs: { name: eventName, parameters: eventParams },
    } = CONFIG.firebaseEvents;

    const userObj = {
      ...userDetails,
      userName: userDetails?.firstName + " " + userDetails?.lastName,
    };
    let parameters = {};
    Object.keys(eventParams).forEach((paramKey) => {
      parameters[paramKey] = userObj[eventParams[paramKey]];
    });
    analytics().logEvent(eventName, parameters);

    window.open(process.env.REACT_APP_SUPPORT_URL);
  };

  fetchAdminApprovals = async () => {
    this.props.clearAdminRequestData();
    this.props.fetchingDashboardAdminRequests(true);
    await this.props.fetchPendingApprovalsForAdmins();
    await this.props.fetchCompletedApprovalsForAdmins();
    await this.props.isFetchingDashboardRequestStats(true);
    await this.props.fetchAdminDashboardRequestStats();
    await this.props.isFetchingDashboardRequestStats(false);
    setTimeout(() => {
      this.props.fetchingDashboardAdminRequests(false);
    }, 100);
  };

  render() {
    const { isLoginComplete, showUpcomingSchedulesSection, SFDCStatusData } =
      this.props;
    const { pendingOpportunityCount, exceptionOpportunityCount } =
      SFDCStatusData || {};
    //  -----------------------------------------------------------
    // | -------------------------------  ----------------------- |
    // | |        						| |       				| |
    // | |        						| |          			| |
    // | |        						| |		Daily Testing	| |
    // | |        						| |     20% Height      | |
    // | |        						| |          			| |
    // | |        						|  ---------------------| |
    // | |        						|  ---------------------| |
    // | |        						| |       				| |
    // | |        						| |                     | |
    // | |        						| |     Upcoming Sched. | |
    // | |       Pending Approvals		| |       40% Height    | |
    // | |        						| |                     | |
    // | |        						|  ---------------------  |
    // | |        						|  ---------------------  |
    // | |        						| |                     | |
    // | |        						| |     Upcoming Maint. | |
    // | |        						| |        40% Height   | |
    // | |        						| |               		| |
    // | --------------------------------  ---------------------- |
    // -----------------------------------------------------------

    return (
      isLoginComplete && (
        <>
          <Row className="admin-dashboard-row" gutter={[24, 0]}>
            <Col
              span={12}
              className="admin-dashboard-row-1-of-4-div"
              style={{ paddingLeft: "16px" }}
            >
              <AdminDashboardDemoStats />
              <div>
                <AdminDashboardApprovals
                  fetchAdminApprovals={this.fetchAdminApprovals}
                />
              </div>
            </Col>
            <Col
              span={12}
              className="admin-dashboard-row-2-of-4-div"
              style={{ paddingLeft: "12px" }}
            >
              <RequestStatistics />
              {(pendingOpportunityCount > 0 ||
                exceptionOpportunityCount > 0) && <AdminSFDCStatistics />}
              {showUpcomingSchedulesSection ? (
                <div className="dashboard-card-grid-height-left">
                  <DashboardSchedules
                    handleDemoScheduleUpdate={this.handleDemoScheduleUpdate}
                    handleDemoScheduleCancel={this.handleDemoScheduleCancel}
                    handleMaintenanceCancel={this.handleMaintenanceCancel}
                    section="All"
                  />
                  <DashboardSchedules
                    handleDemoScheduleUpdate={this.handleDemoScheduleUpdate}
                    handleDemoScheduleCancel={this.handleDemoScheduleCancel}
                    handleMaintenanceCancel={this.handleMaintenanceCancel}
                    section="Maintenance"
                  />
                </div>
              ) : (
                <>
                  <div
                    style={{ marginTop: "16px" }}
                    className="dashboard-card-grid-height-left"
                  >
                    <DashboardSchedules
                      handleDemoScheduleUpdate={this.handleDemoScheduleUpdate}
                      handleDemoScheduleCancel={this.handleDemoScheduleCancel}
                      handleMaintenanceCancel={this.handleMaintenanceCancel}
                      section="Maintenance"
                    />
                  </div>
                  <div>
                    <span className="dashboard-schedules-category-heading">
                      <NeoIcon aria-label="menu-aria"

                        icon="calendar"
                        style={{ fontSize: "20px", paddingRight: "10px" }}
                      />
                      Upcoming Schedules
                    </span>
                    <div className="dashboard-no-schedules">
                      <NeoIcon aria-label="menu-aria" icon="info" size="md" />
                      No demos scheduled
                    </div>
                    <div style={{ marginLeft: "6px" }}>
                      <Divider style={{ marginBottom: "0px" }} />
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </>
      )
    );
  }
}

const mapStateToProps = ({
  user,
  dashboard,
  scheduler,
  notifications,
  viewport,
  demoSchedules,
  trendingDemos,
  adminDashboard,
  adminDashboardSections,
}) => {
  return {
    upcomingSchedules: dashboard.dashboardUpcomingSchedules,
    todaySchedules: dashboard.dashboardTodaySchedules,
    upcomingMaintenances: adminDashboard.upcomingMaintenances,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
    timezone: scheduler.selectedTimezone,
    schedule: demoSchedules.demoSchedule,
    response: demoSchedules.scheduleResponse,
    isActiveSchedules: dashboard.isActiveSchedules,
    isLoginComplete: user.isLoginComplete,
    userDetails: user.profile,
    viewport,
    isLoading: trendingDemos?.isLoading,
    notifications: notifications.userNotifications,
    activeScheduleCount: dashboard.activeScheduleCount,
    params: dashboard.fetchUsersSchedulesParams,
    showUpcomingSchedulesSection:
      adminDashboardSections.showAdminUpcomingSchedulesSection,
    showUpcomingMaintenenceSection:
      adminDashboardSections.showAdminUpcomingMaintenenceSection,
    SFDCStatusData: adminDashboard.SFDCStatusData,
  };
};

export default connect(mapStateToProps, {
  showAdminDashboardUpcomingSchedulesSection,
  fetchUserSchedules,
  fetchDashboardActiveSchedules,
  selectTitle,
  toggleBackButton,
  fetchActiveDemoResources,
  toggleIsFetchingUserSchedules,
  deleteMaintenanceSchedule,
  fetchSchedulesOverDuration,
  updateCurrentDemoSchedule,
  updateSchedulerStartEndTime,
  fetchDataCenters,
  clearMostUsedDemos,
  toggleIsFetchingDashboardSchedules,
  toggleIsFetchingSchedule,
  fetchScheduleById,
  updateUserSchedulesParams,
  optOutSchedule,
  toggleIsReschedule,
  fetchAdminDashboardDailyTestingReport,
  deleteDashboardSchedule,
  fetchPendingApprovalsForAdmins,
  fetchingDashboardAdminRequests,
  fetchCompletedApprovalsForAdmins,
  fetchUpcomingMaintenanceForAdmins,
  clearAdminDashboard,
  fetchAdminDashboardRequestStats,
  clearAdminRequestData,
  isFetchingDashboardRequestStats,
  showAdminDashboardUpcomingMaintenanceSection,
  clearAdminDashboardSectionViews,
  toggleIsFetchingDailyReports,
  openLinkInNewTab,
  toggleIsFetchingAdminDemoStatus,
  fetchAdminDemoStatus,
  fetchAdminSFDCData,
  toggleIsFetchingSFDCStatus,
})(AdminDashboard);

import React from 'react';
import { Col, Form, Checkbox } from 'antd'
import CONFIG from '../../config';
import { Select, Input } from "antd5";
import { Icon, TextInput, TextArea, Button } from "neo-latest"
import { fetchOpportunityDetails } from '../../actions/scheduler';
import pushMessage from '../common/PushMessage';
const { Option } = Select;

export const createScheduleObject = (attributes, isCurrentSchedule) => {
    const purposeData = [];
    let newObject = {}
    if (attributes !== null && attributes !== undefined) {
        attributes.forEach((eachItem) => {
            if (isCurrentSchedule) {
                newObject = {
                    demoPurposeAttribute: eachItem.demoPurposeAttribute,
                    id: eachItem.id,
                    attributeValue: eachItem.demoPurposeAttribute.value
                };
            }
            else {
                newObject = {
                    demoPurposeAttribute: eachItem,
                    attributeValue: eachItem.value
                }
            }
            purposeData.push(newObject);
        })
    }
    return purposeData;
}

export const renderVerticalSelect = (verticalSolutions, label, isRequired) => {
    return (
        <Select
            showSearch
            bordered={false}
            suffixIcon={<Icon aria-label="aria-menu" style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
            className='organiser-select'
            size='large'
            placeholder="Select a vertical">
            {renderVerticalOptions(verticalSolutions)}
            <Option value="Other">Other</Option>
        </Select>
    )
}

export const renderVerticalOptions = (verticalSolutions) => {
    return verticalSolutions?.map((solution, index) => {
        return <Option key={index} value={solution.title}>{solution.title}</Option>
    });
}

export const getInitialValue = (selectedPurpose, form) => {
    return selectedPurpose?.demoPurposeAttributes?.length > 0 ? selectedPurpose?.demoPurposeAttributes?.map((eachItem) => {
        return {
            attributeLabel: eachItem.attributeLabel,
            attributeType: eachItem.attributeType,
            isMandatory: eachItem.isMandatory,
            isVisible: eachItem.isVisible,
            id: eachItem.id,
            type: eachItem?.type,
            value: form.getFieldValue(eachItem.attributeLabel)
        }
    }) : [];
}

export const getInitialValueForSchedules = (selectedPurpose, form) => {
    return selectedPurpose.purposeAttributesSchedule?.length > 0 ? selectedPurpose.purposeAttributesSchedule.map((eachItem) => {
        return {
            id: eachItem.id,
            attributeValue: eachItem.attributeValue,
            demoPurposeAttribute: {
                attributeLabel: eachItem.demoPurposeAttribute.attributeLabel,
                attributeType: eachItem.demoPurposeAttribute.attributeType,
                id: eachItem.demoPurposeAttribute.id,
                isMandatory: eachItem.demoPurposeAttribute.isMandatory,
                isVisible: eachItem.demoPurposeAttribute.isVisible,
                type: eachItem?.demoPurposeAttribute.type,
                value: form.getFieldValue(eachItem.demoPurposeAttribute.attributeLabel)
            }
        }
    }) : [];
}

const getInitialValueForPurposeAttribute = (eachItem, isReschedule, isCurrentSchedule, customerInfo, atrType) => {
    let initialValue;

    if ((atrType === CONFIG.purposeAttributeTypes.customerName || atrType === CONFIG.purposeAttributeTypes.opportunityNumber) && customerInfo) {
        initialValue = atrType === CONFIG.purposeAttributeTypes.customerName ? customerInfo?.opportunity?.AccountName : customerInfo?.opportunity?.otnNumber;
    } else {
        initialValue = !isReschedule ? isCurrentSchedule ? eachItem.attributeValue : eachItem.value : undefined;
    }

    return initialValue;
}

export const renderAdditionalFields = (eachItem, length, props, data, isCurrentSchedule, isReschedule, isScheduler, customerInfo, handleOpportunityNumberChange, isFetchingOpportunity, user, handleNoOpportunityCheckbox) => {
    const { getFieldDecorator } = props.form;
    const label = isCurrentSchedule ? eachItem.demoPurposeAttribute.attributeLabel : eachItem.attributeLabel;
    const type = isCurrentSchedule ? eachItem.demoPurposeAttribute.attributeType : eachItem.attributeType
    const isRequired = isCurrentSchedule ? eachItem.demoPurposeAttribute.isMandatory : eachItem.isMandatory;
    const isVisible = isCurrentSchedule ? eachItem.demoPurposeAttribute.isVisible : eachItem.isVisible;
    const atrType = isCurrentSchedule ? eachItem.demoPurposeAttribute.type : eachItem.type;
    const doNotHaveOpportunity = props.form.getFieldValue('doNotHaveOpportunity');

    return (
        <>
            {isVisible && <Col span={atrType === CONFIG.purposeAttributeTypes.opportunityNumber ? 18 : 6} style={type === CONFIG.Textbox ? { marginTop: "2px" } : {}}>
                <><div style={{ display: 'flex', flexDirection: atrType === CONFIG.purposeAttributeTypes.opportunityNumber ? 'row' : 'column' }}>
                    <div style={atrType === CONFIG.purposeAttributeTypes.opportunityNumber ? { display: 'flex', flexDirection: 'column', width: '32%' } : { display: 'flex', flexDirection: 'column' }}>
                        <Form.Item label={label}>
                            {type === CONFIG.Textbox ?
                                getFieldDecorator(label, {
                                    rules: [{
                                        required: (atrType === CONFIG.purposeAttributeTypes.opportunityNumber) ? !doNotHaveOpportunity : atrType === CONFIG.purposeAttributeTypes.customerName ? isRequired : isRequired,
                                        message: "Please fill this field"
                                    }],
                                    initialValue: getInitialValueForPurposeAttribute(eachItem, isReschedule, isCurrentSchedule, customerInfo, atrType)
                                })(
                                    renderPurposeInput(atrType, handleOpportunityNumberChange, customerInfo?.status, isFetchingOpportunity, label, customerInfo, doNotHaveOpportunity)
                                )
                                : getFieldDecorator(label, {
                                    rules: [{
                                        required: isRequired,
                                        message: "Please fill this field"
                                    }],
                                    initialValue: !isReschedule ? isCurrentSchedule ? eachItem.attributeValue : eachItem.value : undefined
                                })(
                                    renderVerticalSelect(data, label, isRequired)
                                )
                            }
                        </Form.Item>
                        {(atrType === CONFIG.purposeAttributeTypes.opportunityNumber) && customerInfo && customerInfo?.status === "Fail" && !doNotHaveOpportunity &&
                            <div><span style={{ color: "#da291c", fontSize: "12px" }}>Invalid opportunity number</span></div>}
                    </div>
                    {(atrType === CONFIG.purposeAttributeTypes.opportunityNumber && user?.isSfdcByPass) &&
                        <div style={{ marginTop: "21px", marginLeft: "24px" }}>
                            <Form.Item>
                                {
                                    getFieldDecorator("doNotHaveOpportunity", {
                                        initialValue: !isReschedule ? props?.currentSchedule ? props?.currentSchedule?.opportunity ? props?.currentSchedule?.opportunity?.sfdcOpportunityId ? false : true : false : false : false,
                                        valuePropName: 'checked'
                                    })(
                                        <Checkbox
                                            onChange={(e) => handleNoOpportunityCheckbox(e.target.checked)}
                                            value={`${!isReschedule && isCurrentSchedule ? "readOnly" : ""} ${!isReschedule ? props?.currentSchedule ? props?.currentSchedule?.opportunity ? props?.currentSchedule?.opportunity?.sfdcOpportunityId ? "unchecked" : "checked" : "unchecked" : "unchecked" : "unchecked"}`}
                                            name="Don't have opportunity number" style={{ color: "#000" }} >Don't have opportunity number</Checkbox >
                                    )
                                }
                            </Form.Item></div>}
                    {
                        (atrType === CONFIG.purposeAttributeTypes.opportunityNumber && !user?.isSfdcByPass) && <div style={{ marginTop: "21px", marginLeft: "24px" }}>
                            <Button icon="bounce" dir="rtl" onClick={
                                (event) => { event.preventDefault(); window.open(user?.sfdcUrl, '_blank') }
                            } variant='secondary'>Find Opportunity Number</Button>
                        </div>
                    }
                </div>
                </>
            </Col>}
            {(atrType === CONFIG.purposeAttributeTypes.opportunityNumber && doNotHaveOpportunity) &&
                <Col span={24}>{renderAccountOwnerFields(props, isReschedule, user)}</Col>}
        </>
    )
}

const renderAccountOwnerFields = (props, isReschedule, userDn) => {
    const { getFieldDecorator } = props.form;
    const doNotHaveOpportunity = props.form.getFieldValue('doNotHaveOpportunity');
    // const requestOpportunityNumber = props.form.getFieldValue('requestOpportunityNumber');
    return (
        <>
            <div className='schedular-account-owner'>
                <div style={{ width: '100%' }}><Form.Item
                    label={"Account Owner Email"}
                >
                    {getFieldDecorator("accountOwnerEmail", {
                        rules: [
                            {
                                required: doNotHaveOpportunity,
                                type: "email",
                                message: "Please enter a valid email-id"
                            }
                        ],
                        initialValue: !isReschedule ? props?.currentSchedule ? props?.currentSchedule?.opportunity?.accountOwnerEmail : undefined : undefined
                    })
                        (<TextInput
                            // disabled={requestOpportunityNumber}
                            aria-label={"Account Owner Email"}
                            defaultValue={!isReschedule ? props?.currentSchedule ? props?.currentSchedule?.opportunity?.accountOwnerEmail : undefined : undefined}
                            value={!isReschedule ? props?.currentSchedule ? props?.currentSchedule?.opportunity?.accountOwnerEmail : undefined : undefined} />)
                    }
                </Form.Item>
                </div>
                {/* <div style={{ marginTop: "20px", marginLeft: "12px" }}>
                    <Form.Item>
                        {
                            getFieldDecorator("requestOpportunityNumber", {
                                valuePropName: 'checked'
                            })(
                                <Checkbox
                                    onChange={(e) => handleNoAccountOwnerCheckBox(e.target.checked, props)}
                                    name="Request Opportunity number" style={{ color: "#000" }} >Don't know account owner's email?</Checkbox >
                            )
                        }
                    </Form.Item>
                </div>
                {requestOpportunityNumber && <><div style={{ width: "1px", border: "1px solid #C9C9C9" }} />
                    <div style={{ marginTop: "20px", }}><Button style={{ height: "40px", width: "260px" }} variant="primary">
                        <a style={{ color: "#FFFFFF" }} href={(userDn === "Associates" || userDn === "CUST") ?
                            "https://avaya--f4utest.sandbox.lightning.force.com/lightning/o/Opportunity/new?nooverride=1&recordTypeId=01230000000DpZ0AAK" :
                            "https://avaya--f4utest.sandbox.my.site.com/PartnerCentral/s/opportunity/Opportunity/Default"} target="_blank" rel="noreferrer">
                            Request Opportunity Number</a></Button></div>
                </>
                } */}
            </div>
        </>
    )
}

// const handleNoAccountOwnerCheckBox = async (value, props) => {
//     if (value) {
//         await props.form.setFieldsValue({ [`accountOwnerEmail`]: "" })
//     }
// }

const patternCheck = /^\d{10}$/; // Matches 10 digits
const opportunityNumberPattern = /^000\d{7}$/; // Matches 10 digits starting with 000

const handleInputChange = async (event, handleOpportunityNumberChange, isFetchingOpportunity, label, atrType) => {
    const inputValue = event.target.value;

    if (inputValue.length > 10) {
        pushMessage(CONFIG.messageType.warning, 'Opportunity number must not exceed 10 digits');
    } else if (inputValue.length > 2 && inputValue.slice(0, 3) !== "000") {
        pushMessage(CONFIG.messageType.warning, 'Opportunity number must begin with 000');
    } else {
        if (opportunityNumberPattern.test(inputValue)) {
            await isFetchingOpportunity(true);
            const customerInfo = await fetchOpportunityDetails(inputValue);
            await handleOpportunityNumberChange(customerInfo, label, atrType);
            await isFetchingOpportunity(false);
        } else {
            await handleOpportunityNumberChange({});
        }
    }
};

export const renderPurposeInput = (atrType, handleOpportunityNumberChange, status, isFetchingOpportunity, label, customerInfo, doNotHaveOpportunity) => {
    return (
        (atrType === CONFIG.purposeAttributeTypes.opportunityNumber) ?
            <Input
                className='input-antd' size='large'
                style={status === "Fail" && !doNotHaveOpportunity && { border: "1px solid #da291c" }}
                onChange={(event) => handleInputChange(event, handleOpportunityNumberChange, isFetchingOpportunity, label, atrType)}
                pattern={patternCheck}
                maxLength={10}
                disabled={doNotHaveOpportunity}
            />
            : atrType === CONFIG.purposeAttributeTypes.customerName ? <TextInput disabled={!doNotHaveOpportunity} aria-label="customer name" /> : <Input className='input-antd' size='large' disabled={atrType === CONFIG.purposeAttributeTypes.customerName} />
    );
}

export const isAttributeChanged = (currentData, newData, form) => {
    const oldData = currentData || []; // demoPurpose object
    let currentDataAttributes = currentData;
    let newDataAttributes = newData; // purposeDemoAttributes
    currentDataAttributes = currentDataAttributes?.map((eachItem) => {
        return {
            attributes: eachItem.demoPurposeAttribute
        }
    })

    const idOfOldData = findId(currentDataAttributes, false); //demopurpose object id
    const idOfNewData = findId(newDataAttributes, true); // purposeDemoAttributes object id
    const missingAttributes = [];
    if (idOfNewData?.length > 0) {
        idOfNewData.forEach((item) => {
            if (!(idOfOldData.includes(item))) {
                missingAttributes.push(item)
            }
        })

        let additionalAttributes = newDataAttributes?.demoPurposeAttributes?.filter(function (item) {
            return missingAttributes.indexOf(item.id) !== -1;
        });

        additionalAttributes.forEach((eachItem) => {
            let newObject = {
                demoPurposeAttribute: {
                    ...eachItem,
                    value: form.getFieldValue(eachItem.attributeLabel)
                }
            };
            oldData.push(newObject);
        })
    }

    return oldData;
}

export const findId = (data, isNew) => {
    const idArray = []
    const attributes = isNew ? data?.demoPurposeAttributes : data;
    if (attributes?.length > 0) {
        attributes.forEach((eachItem) => {
            idArray.push(isNew ? eachItem.id : eachItem.attributes.id)
        })
    }
    return idArray
}

export const checkDeletedPurpose = (currentPurpose) => {
    return !currentPurpose?.isActive
}

export const renderNotes = (notes, form) => {
    const { getFieldDecorator } = form;
    return (
        <Col xl={12} lg={12} sm={12} xs={12}>
            <Form.Item label="Notes">
                {getFieldDecorator("notes", {
                    initialValue: notes ?? ""
                })(
                    <TextArea rows={2} />
                )}
            </Form.Item>
        </Col>
    )
}
import React from 'react';
import { connect, batch } from 'react-redux';
import { Table, Row, Button, Icon, Col, Divider, Alert, message, Dropdown, Tooltip, Spin } from "antd";
import DragListView from 'react-drag-listview';
import { Icon as NeoIcon } from "@avaya/neo-react";
import { exportMenu } from "../../utils/strings";
import CONFIG from '../../config';
import {
  selectTitle,
  currentSolutionFilter,
  getAllSolutionTypes,
  updateSelectedSolutionType,
  fetchSolutions,
  reorderSolutions,
  refreshSolutions,
  toggleHasSolutionsUpdated,
  toggleIsFetchingSolutions,
  deleteSolution,
  putSolutionsOrder,
  clearSolutions,
  fetchDemos,
  restoreSolution,
  exportTable,
  toggleSaveReportVisible,
  handleOnClickAction,
} from '../../actions';
import StatusIcon from '../common/StatusIcon';
import pushMessage from '../common/PushMessage';
import FilterPanel from '../common/FilterPanel';
import StatusFilter from '../common/StatusFilter';
import SolutionTypes from '../common/SolutionTypes';
import openConfirm from '../common/Confirm';
import SaveReport from "../common/SaveReport";
import { checkSolutionDependencies } from '../../actions/solutions'
import { isTabAlreadyOpen } from "../common/TabLayout";
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const ManageUserContext = React.createContext();
class ManageSolutions extends React.Component {
  constructor(props) {
    super(props);
    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const newSolutions = that.props.activeSolutions;
        const item = newSolutions.splice(fromIndex, 1)[0];
        newSolutions.splice(toIndex, 0, item);
        that.props.reorderSolutions(newSolutions);
      },
      handleSelector: "i"
    };
    this.state = {
      sortedInfo: null,
      filteredData: undefined,
      status: true,
      showFilter: false,
    }
    this.tableRef = React.createRef()
  }
  filterSave = {}
  filterSort = {}
  isViewDeletedItemsChecked = undefined;
  solutionId = undefined;
  status = true;

  getSortedSolutions = () => {
    const { activeSolutions } = this.props;
    const { sortedInfo } = this.state;
    if (this.isTableSorted) activeSolutions.sort((a, b) => {
      if (sortedInfo.order === "descend") [a, b] = [b, a]
      return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
    })
    return activeSolutions;
  }

  getFilteredData = (title, data) => {
    let filteredData = title
      ? data?.filter(solution => solution.title.toLowerCase().includes(title.toLowerCase()))
      : data;
    // filteredData = status !== undefined && !this.isViewDeletedItemsChecked
    //   ? filteredData?.filter(solution => solution.isActive === status)
    //   : filteredData;
    return filteredData
  }

  handleStatusChange = (value) => {
    this.setState({ status: value })
  }

  filterRecords = (filters) => {
    let { title, viewDeletedItems } = filters;
    this.isViewDeletedItemsChecked = viewDeletedItems;
    this.formFilters = filters;
    // const data = this.getData();
    title = title?.trim();
    if (!title && !viewDeletedItems) {
      pushMessage(CONFIG.messageType.warning, "Please select some fields to filter");
      return;
    }
    this.titleSearchString = title;
    this.filterSort = { ...this.filterSort, title, viewDeletedItems }
    this.setState(prevState => ({
      status: viewDeletedItems ? false : prevState.status,
    }));
    this.tableRef.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  handleFilter = (filters) => {
    let { viewDeletedItems } = filters;
    if (viewDeletedItems || this.isViewDeletedItemsChecked) {
      this.props.toggleIsFetchingSolutions(true);
      this.props.fetchSolutions(this.solutionType?.id ? this.solutionType?.id : this.props.selectedSolutionType?.id, false, viewDeletedItems, null).then(() => {
        this.isViewDeletedItemsChecked = viewDeletedItems;
        this.filterRecords(filters);
      })
    }
    else this.filterRecords(filters);
  }

  handleSave = (filters) => {
    let { title, viewDeletedItems } = filters;
    this.formFilters = filters;
    title = title?.trim();
    if (!title && !viewDeletedItems) {
      if (!this.filterSave.save) pushMessage(CONFIG.messageType.warning, "Please select some fields to save");
      this.handleClear(false);
      return;
    }
    this.filterSave = {
      ...this.filterSave,
      filter: {
        ...(title && {
          title: {
            operator: "=",
            oprVal: title || undefined
          }
        }),
        ...(viewDeletedItems && {
          isSoftDelete: {
            operator: "=",
            oprVal: viewDeletedItems
          }
        })
      },
      filterTable: {
        id: 4,
        tableName: "Solution"
      }
    }
    this.props.toggleSaveReportVisible(true)
    this.setState({ saveReport: true })
  }

  handleClear = async (refresh) => {
    if (refresh) {
      this.props.refreshSolutions();
      this.currentPage = 1;
      this.props.toggleIsFetchingSolutions(true);
      await this.props.fetchSolutions(this.props.selectedSolutionType.id, false, this.isViewDeletedItemsChecked, null);
      this.props.toggleHasSolutionsUpdated(false);
      const currentFilterSize = this.filterSort?.filter !== undefined ? Object.keys(this.filterSort?.filter)?.length : 0;
      currentFilterSize > 0 && this.filterRecords(this.filterSort)
    }
    else {
      this.titleSearchString = undefined;
      this.filterStatusTypes = undefined;
      this.formFilters = undefined;
      this.filterSave = {};
      if (this.isViewDeletedItemsChecked) {
        this.props.toggleIsFetchingSolutions(true);
        this.props.fetchSolutions(this.props.selectedSolutionType.id);
      }
      this.isViewDeletedItemsChecked = undefined;
      this.setState({ filteredData: undefined });
      this.filterSort = {}
    }
  }

  handleExport = async (fileType) => {
    const { timeZoneName, } = this.props;
    const data = this.getData();
    const exportData = this.getFilteredData(this.titleSearchString, data)
    if (exportData?.length > 0 && exportData !== undefined) {
      pushMessage(CONFIG.messageType.loading, "Exporting", 0);
      exportTable(CONFIG.exportTable.solutions.name, exportData, timeZoneName, CONFIG.exportTable.solutions.fileName + `.${fileType}`, fileType)
        .then((response) => {
          message.destroy()
          const isExport = response.status === CONFIG.HTTP_STATUS.OK
          pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
        })
        .catch(() => {
          message.destroy()
          pushMessage(CONFIG.messageType.error, "Unable to export table")
        })
    }
    else {
      message.destroy()
      pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
    }
  }

  handleDeleteRestore = (solutionId, isRestore) => {
    this.solutionId = solutionId
    const hardDelete = this.isViewDeletedItemsChecked;
    checkSolutionDependencies(solutionId, hardDelete)
      .then(dependencyList => this.renderDeleteRestoreConfirmation(dependencyList, isRestore))
      .catch((err) => pushMessage(CONFIG.messageType.error, err.message))
  }

  renderDeleteRestoreConfirmation = (dependencyList, isRestore) => {
    const { scheduleCount, maintenanceCount, demoCount } = dependencyList;
    const hardDelete = this.isViewDeletedItemsChecked;
    const okButtonText = isRestore ? "Confirm Restore" : hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
    const text = isRestore ? "Restoration" : "Deletion"
    const showDependencyList = scheduleCount !== 0 || maintenanceCount !== 0 || demoCount !== 0
    const modalTitle = isRestore ? CONFIG.restoreModalTitle.replace('<placeholder>', 'solution') : showDependencyList ? CONFIG.dependencyModalTitle : CONFIG.deleteModalTitle?.replace('<placeholder>', 'solution')
    const content = <>
      {hardDelete && !isRestore ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Solution")}</h4> : !isRestore ? <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "Solution")}</h4> : ""}
      {showDependencyList &&
        <>
          <h4>{text} of selected solution would affect</h4>
          {!hardDelete && <> {scheduleCount} * Future Scheduled Demos <br /></>}
          {!hardDelete && <>{maintenanceCount} * Future Maintenance Windows<br /></>}
          {demoCount}  * Assigned Demos <br />
        </>
      }
    </>
    openConfirm(okButtonText, isRestore ? this.handleRestoreConfirmation : this.handleDeleteConfirmation, null, content, modalTitle);
  }

  handleDeleteConfirmation = () => {
    const hardDelete = this.isViewDeletedItemsChecked;
    if (this.props.userProfile?.isDeveloper && hardDelete) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
    else {
      this.props.deleteSolution(this.solutionId, hardDelete)
        .then(async () => {
          this.props.toggleIsFetchingSolutions(true);
          await this.props.fetchSolutions(this.props.selectedSolutionType.id, false, this.isViewDeletedItemsChecked, null);
        })
    }
  }

  handleRestoreConfirmation = () => {
    this.props.restoreSolution(this.solutionId)
      .then(async () => {
        this.props.toggleIsFetchingSolutions(true);
        await this.props.fetchSolutions(this.props.selectedSolutionType.id, false, true, null);
      })
  }

  renderWarningMessage = () => {
    return (
      <div>
        <Alert
          message={CONFIG.warningMessages.noSolutionTypeAccess}
          type="warning"
          showIcon
        />
        <br />
      </div>
    )
  }

  handleSelectChange = (value) => {
    this.solutionType = JSON.parse(value);
    this.props.clearSolutions();
    this.setState({
      filteredData: []
    })
    this.props.updateSelectedSolutionType(this.solutionType);
    this.props.toggleIsFetchingSolutions(true);
    if (this.isViewDeletedItemsChecked)
      this.handleFilter(this.formFilters)
    else this.props.fetchSolutions(this.solutionType.id, false)
      .then(() => {
        this.formFilters && this.handleFilter(this.formFilters)
      })
  }

  handleChange = (_, __, sorter) => {
    this.setState({
      sortedInfo: sorter
    });
  }

  renderUpdateButton = () => {
    const isUpdateDisabled = !this.isTableSorted || this.props.solutions?.length === 0 || this.titleSearchString !== undefined || this.state.status !== true || this.isViewDeletedItemsChecked !== undefined
    const deleteView = this.isViewDeletedItemsChecked;
    return !deleteView && (
      <Button className="primary-action-button-bordered vertical-spacing"
        disabled={!this.props.isReordered && isUpdateDisabled}
        onClick={() => this.props.putSolutionsOrder(this.getSortedSolutions(), this.props.selectedSolutionType.id)}
      >
        Update order
      </Button>
    );
  }

  componentWillUnmount() {
    if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
      const filterSort = {
        ...this.filterSort,
        sorter: this.state.sortedInfo ?? undefined
      }
      this.props.currentSolutionFilter(filterSort, this.state.status);
      this.props.updateSelectedSolutionType(this.solutionType);
    }
    else {
      this.props.clearSolutions();
    }
  }

  componentDidMount() {
    const { userRoleTemplate, solution } = this.props;
    this.setState({ currentPath: window.location.pathname })
    if (solution.isEdit) {
      this.filterSort = solution.currentFilter;
      const { sorter, ...filterSort } = solution.currentFilter;
      this.setState({ status: solution.status });
      this.solutionType = solution?.selectedType;
      const currentFilterSize = filterSort !== undefined ? Object.keys(filterSort)?.length : 0;
      if (currentFilterSize > 0) {
        this.isViewDeletedItemsChecked = filterSort?.viewDeletedItems
        this.handleFilter(this.filterSort)
        this.setState({ showFilter: true });
        this.filterSort = filterSort;
      }
      this.setState({ sortedInfo: solution.currentFilter.sorter })
    }
    const access = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined;
    batch(async () => {
      this.props.selectTitle('Manage Solutions');
      this.props.toggleIsFetchingSolutions(true);
      await this.props.fetchDemos({ access, getAll: true })
      if (!this.props.selectedSolutionType) {
        await this.props.getAllSolutionTypes()
          .then(() => {
            if (this.props?.solutionTypes?.length !== 0) {
              this.props.fetchSolutions(this.solutionType?.id ? this.solutionType?.id : this.props.solutionTypes?.[0].id, false, (solution?.currentFilter?.viewDeletedItems || false)).then(() => {
                this.props.updateSelectedSolutionType(this.solutionType?.id ? this.solutionType?.id : this.props.solutionTypes?.[0]);
              });
            }
            else {
              this.props.toggleIsFetchingSolutions(false);
            }
          });
      }
      else this.props.fetchSolutions(this.props.selectedSolutionType.id, false, (solution?.currentFilter?.viewDeletedItems || false));
    });
  }

  getData = () => {
    const { solutions, activeSolutions, inactiveSolutions } = this.props;
    return (this.isViewDeletedItemsChecked || this.state.status === undefined) ? solutions : this.state.status === true ? activeSolutions : inactiveSolutions;
  }

  render() {
    const { userRoleTemplate } = this.props;
    let { sortedInfo, showFilter } = this.state;
    const isUserSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
    const data = this.getData();
    const tableData = this.getFilteredData(this.titleSearchString, data)
    const adminColumns = [
      {
        title: "",
        key: "operate",
        align: 'center',
        render: () => <Icon aria-label="aria-menu" className="drag-handle" type="drag" />,
        width: '5%'
      }
    ]
    const formFields = [
      {
        label: "Title",
        type: CONFIG.formFieldTypes.INPUT,
        key: "title",
        value: this.filterSort?.title
      }
    ]
    sortedInfo = sortedInfo || {};
    this.isTableSorted = Object.keys(sortedInfo).length > 2;
    const isDragDisabled = this.titleSearchString !== undefined || this.state.status !== true || this.isViewDeletedItemsChecked !== undefined
    const columns = [
      {
        title: <span className={this.titleSearchString ? "filtered-column" : ''}>Solution Name</span>,
        dataIndex: 'title',
        key: 'title',
        width: '30%',
        align: 'left',
        sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
      },
      {
        title: <span className={this.filterStatusTypes ? "filtered-column" : ''}>Status</span>,
        dataIndex: 'isActive',
        key: 'isActive',
        sorter: (a, b) => a.isActive.toString().localeCompare(b.isActive.toString()),
        sortOrder: sortedInfo.columnKey === 'isActive' && sortedInfo.order,
        width: '15%',
        render: (status) => <StatusIcon status={status} />,
        align: 'center'
      },
      {
        title: 'Actions',
        key: 'actions',
        width: '10%',
        render: (record) => {
          const isEditDisabled = userRoleTemplate && userRoleTemplate.templateType !== CONFIG.roleTypes.superAdmin && record.adminAccess === CONFIG.roleTemplateAccess.readOnly
          const deleteView = this.isViewDeletedItemsChecked;
          return (
            <div>
              {!deleteView &&
                <>
                  <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                    <button
                      id='solutions-edit'
                      onClick={() => {
                        this.props.handleOnClickAction({
                          component: CONFIG.editComponentRoute.solutions,
                          tabTitle: record?.title,
                          recordId: record?.id
                        }
                        )
                      }}
                      disabled={isEditDisabled}
                      className={!isEditDisabled ? "link" : "link-disabled"}>
                      <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                    </button>
                  </Tooltip>
                  <Divider type="vertical" />
                </>
              }
              <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                <>
                  <button disabled={isEditDisabled} id='solutions-delete' className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleDeleteRestore(record.id)}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                  {deleteView && <>
                    <Divider type="vertical" />
                    <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleDeleteRestore(record.id, true)}><span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span></button>
                  </>
                  }
                </>
              </Tooltip>
            </div>
          )
        },
        align: 'center'
      },
    ];
    const spin = <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}><Spin indicator={antIcon} /></div>
    return (
      this.props.solutionTypes?.length !== 0 ? (
        <React.Fragment>
          <Row className="vertical-spacing">
            <Col xl={3} sm={6} xs={24}>
              <StatusFilter disabled={this.isViewDeletedItemsChecked} onChange={this.handleStatusChange} value={this.state.status} />
            </Col>
            <Col xl={4} sm={6} xs={24} className="left-spacing">
              {this.props.solutionTypes && <SolutionTypes solutionTypes={this.props.solutionTypes} handleSelectChange={this.handleSelectChange} selectedSolutionType={this.props.selectedSolutionType} />}
            </Col>
            <Col xl={2} sm={6} xs={24} className="left-spacing">
              <Button id="solutions-create-new" className="primary-action-button-filled" onClick={() => {
                this.props.handleOnClickAction({
                  component: CONFIG.createComponentRoute.solutions,
                  tabTitle: "Create"
                })
              }}>New
                <Icon aria-label="aria-menu" type="plus-circle" />
              </Button>
            </Col>
            <span className="solution-align">
              <Col xl={1} sm={2} xs={2} className="float-right" style={{ marginRight: "20px" }}>
                <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                  <NeoIcon aria-label="menu-aria" title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
                </Dropdown>
              </Col>
              <Col xl={1} sm={2} xs={2} className="float-right" style={{ marginRight: "20px" }}>
                <NeoIcon aria-label="menu-aria" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
              </Col>
              <Col xl={1} sm={2} xs={2} className="float-right" style={{ marginRight: "20px" }}>
                <NeoIcon aria-label="menu-aria" className="clickable" id="solutions-filter" onClick={() => this.setState(prevState => ({
                  showFilter: !prevState.showFilter
                }))} title="Filter" icon={showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
              </Col>
            </span>
          </Row>
          <div className="content-container responsive-container">
            <Row>
              {this.state.showFilter &&
                <FilterPanel
                  panelHeader="Filters and Export"
                  label='View Deleted Solutions'
                  formFields={formFields}
                  handleClear={this.handleClear}
                  handleFilter={this.handleFilter}
                  handleSave={this.handleSave}
                  viewDeletedItemsValue={this.filterSort?.viewDeletedItems}
                />}
            </Row>
            <h3>{this.isViewDeletedItemsChecked ? 'List of Deleted Solutions' : this.state.status ? 'List of Active Solutions' : this.state.status === false ? 'List of Inactive Solutions' : 'List of All Solutions'}</h3>
            <div ref={this.tableRef}>
              {isUserSuperAdmin && this.renderUpdateButton()}
              <DragListView {...this.dragProps}>
                <Table
                  size='middle'
                  className="responsive-container"
                  columns={(isUserSuperAdmin && isDragDisabled === false) ? [...adminColumns, ...columns] : columns}
                  dataSource={tableData}
                  bordered
                  pagination={false}
                  rowKey={(record) => record.id}
                  loading={this.props.isFetching}
                  onChange={this.handleChange}
                />
              </DragListView>
            </div>
            {isUserSuperAdmin && this.renderUpdateButton()}
          </div>
          {this.state.saveReport && <SaveReport closeModal={() => this.setState({ saveReport: false })} reportFilter={this.filterSave} />}
        </React.Fragment >
      ) :
        this.props.isFetching ? spin : this.renderWarningMessage()
    );
  }
}

const mapStateToProps = ({ solutions, user, tabsLayout, solutionTypeData }) => {
  return {
    solutionTypes: solutionTypeData.solutionTypes,
    solutions: solutions.data,
    selectedSolutionType: solutions.selectedType,
    status: solutions.status,
    currentFilter: solutions.currentFilter,
    isReordered: solutions.isReordered,
    isFetching: solutions.isFetching,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
    userProfile: user.profile,
    solution: solutions,
    timeZoneName: user.profile ? user.profile.timezone : undefined,
    data: solutions.data,
    openedTabs: tabsLayout.panes,
    activeSolutions: solutions.activeSolutions,
    inactiveSolutions: solutions.inactiveSolutions,
  }
}
export default connect(
  mapStateToProps,
  {
    selectTitle,
    currentSolutionFilter,
    getAllSolutionTypes,
    updateSelectedSolutionType,
    fetchSolutions,
    reorderSolutions,
    toggleIsFetchingSolutions,
    deleteSolution,
    restoreSolution,
    putSolutionsOrder,
    clearSolutions,
    fetchDemos,
    exportTable,
    refreshSolutions,
    toggleHasSolutionsUpdated,
    handleOnClickAction,
    toggleSaveReportVisible,
  }
)(ManageSolutions);
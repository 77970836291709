import React from 'react'
import { Form, Row, Col, Input, Switch, DatePicker, Select, Button, Checkbox, Tooltip, Menu, Dropdown, Icon } from 'antd';
import moment from 'moment-timezone';
import CONFIG from '../../config';
import { getDateTime } from '../../utils/datepicker';
const { RangePicker } = DatePicker;
const { Option } = Select;
const FilterForm = ({ formFields, handleFilter, handleClear, form, handleExport, timeZone, label, hideSoftDeleteCheckbox, getSearchData, getSearchEmailData, hideApplyToAll, handleSave, viewDeletedItemsValue }) => {
    const { getFieldDecorator } = form;
    const colSpan = formFields.length % 2 === 0 ? 12 : 8;
    const currentDateTime = getDateTime({ convertTimeZone: true, timeZone });
    const ranges = {
        'Tomorrow': [moment.tz(timeZone), moment.tz(timeZone).add(1, 'days')],
        'Next 7 days': [moment.tz(timeZone), moment.tz(timeZone).add(7, 'days')],
        'Next 30 days': [moment.tz(timeZone), moment.tz(timeZone).add(30, 'days')],
        'Next 365 days': [moment.tz(timeZone), moment.tz(timeZone).add(365, 'days')],
        'Yesterday': [moment.tz(timeZone).subtract(1, 'days'), moment.tz(timeZone)],
        'Last 7 days': [moment.tz(timeZone).subtract(7, 'days'), moment.tz(timeZone)],
        'Last 30 days': [moment.tz(timeZone).subtract(30, 'days'), moment.tz(timeZone)],
        'Last 365 days': [moment.tz(timeZone).subtract(365, 'days'), moment.tz(timeZone)]
    }
    const getInitialValue = (value, type) => {
        if (value) {
            if (type === CONFIG.formFieldTypes.SELECT) return value?.split("\\")
            else if (type === CONFIG.formFieldTypes.SEARCH_SELECT) return value
            return
        }
    }
    const getFormElement = (field, idx) => {
        switch (field.type) {
            case CONFIG.formFieldTypes.INPUT:
                return getFieldDecorator(field.key, { initialValue: field.value })
                    (<Input key={idx} />)
            case CONFIG.formFieldTypes.SELECT:
                return (
                    getFieldDecorator(field.key, {
                        initialValue: getInitialValue(field.value, CONFIG.formFieldTypes.SELECT),
                        rules: [field.mode === "default" ? { type: "string" } : { type: 'array' }]
                    })(
                        <Select
                            mode={field.mode || "multiple"}
                            placeholder={`Select ${field.label}`}
                            key={idx}
                            getPopupContainer={() => document.getElementById('filterForm')}
                        >
                            {
                                field.data?.map((dataObj, index) =>
                                    <Option key={index} title={dataObj[field.dataKey || "text"]} value={dataObj[field.valueKey || "value"]}>{dataObj[field.dataKey || "text"]}</Option>
                                )
                            }
                        </Select>
                    )
                )
            case CONFIG.formFieldTypes.SEARCH_SELECT:
                return (
                    getFieldDecorator(field.key, {
                        initialValue: getInitialValue(field.value, CONFIG.formFieldTypes.SEARCH_SELECT)
                    })(
                        <Select
                            showSearch={true}
                            placeholder={`Type ${field.label} to search`}
                            onSearch={getSearchData}
                            notFoundContent="No Match found!"
                            getPopupContainer={() => document.getElementById('filterForm')}
                        >
                            {field.data?.map((eachName, index) => (
                                <Option title={eachName} key={index} value={eachName} >{eachName}</Option>
                            ))}
                        </Select>
                    )
                )
            case CONFIG.formFieldTypes.EMAIL_SEARCH:
                return (
                    getFieldDecorator(field.key, {
                        initialValue: getInitialValue(field.value, CONFIG.formFieldTypes.EMAIL_SEARCH)
                    })(
                        <Select
                            showSearch={true}
                            placeholder={`Type ${field.label} to search`}
                            onSearch={getSearchEmailData}
                            notFoundContent="No Match found!"
                            getPopupContainer={() => document.getElementById('filterForm')}
                        >
                            {field.data?.map((eachName, index) => (
                                <Option title={eachName} key={index} value={eachName} >{eachName}</Option>
                            ))}
                        </Select>
                    )
                )
            case CONFIG.formFieldTypes.SWITCH:
                return getFieldDecorator(field.key, { valuePropName: "checked" })
                    (<Switch
                        key={idx}
                        checkedChildren={field.checked}
                        unCheckedChildren={field.unchecked}
                    />)
            case CONFIG.formFieldTypes.RANGE_PICKER:
                return (
                    <Row gutter={24}>
                        <Col span={!hideApplyToAll ? 17 : 24}>
                            {getFieldDecorator(field.key, {
                                initialValue: field.value[0] !== undefined ? [moment(field.value[0]), moment(field.value[1])] : [],
                                rules: [{ type: 'array' }]
                            })(
                                <RangePicker
                                    key={idx}
                                    className="full-width"
                                    ranges={ranges}
                                    showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
                                    format={CONFIG.dateFormats.default}
                                    defaultPickerValue={[currentDateTime, currentDateTime]}
                                    getPopupContainer={() => document.getElementById('filterForm')}
                                    disabled={field.disabled}
                                />
                            )}
                        </Col>
                        {!hideApplyToAll && <Col span={7}>
                            {getFieldDecorator("applyToAllCheck", {
                                initialValue: field.value[2],
                                valuePropName: "checked"
                            })(
                                <Checkbox disabled={field.disabled}>
                                    <Tooltip title={CONFIG.systemMessage.applyToAllCheck}>
                                        Apply to all
                                    </Tooltip>
                                </Checkbox>
                            )}
                        </Col>
                        }
                    </Row>
                )
            default:
                return null
        }
    }
    const clearFilters = () => {
        form.resetFields();
        handleClear();
    }
    const handleMenuClick = (e) => {
        handleExport(e.key)
    }
    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key={CONFIG.exportFileType.xlsx.ext}>
                {CONFIG.exportFileType.xlsx.name}
            </Menu.Item>
            <Menu.Item key={CONFIG.exportFileType.csv.ext}>
                {CONFIG.exportFileType.csv.name}
            </Menu.Item>
        </Menu>
    );

    return (
        <Form id="filterForm">
            <Row>
                {!hideSoftDeleteCheckbox && (
                    <Form.Item>
                        {getFieldDecorator("viewDeletedItems",
                            {
                                valuePropName: "checked",
                                initialValue: viewDeletedItemsValue
                            })(
                                <Checkbox>
                                    {label}
                                </Checkbox>
                            )}
                    </Form.Item>
                )
                }
            </Row>
            <Row gutter={48}>
                {
                    formFields?.map((field, idx) => {
                        return <Col lg={field.colSpan || colSpan} xs={24} key={idx}>
                            <Form.Item label={field.label} key={idx}>
                                {getFormElement(field, idx)}
                            </Form.Item>
                        </Col>
                    })
                }
            </Row>
            <Row className="vertical-spacing right-align">
                <React.Fragment>
                    <Button onClick={clearFilters} className={'cancel-button right-spacing'} id='filter-panel-clear'>Clear</Button>
                    <Button.Group className="left-spacing">
                        <Button className={`primary-action-button-filled`} onClick={() => {
                            handleFilter(form.getFieldsValue())
                        }} id='filter-panel-filter'>Filter</Button>
                        {handleExport &&
                            <Dropdown overlay={menu}>
                                <Button className="primary-action-button-filled left-spacing-3">
                                    Export <Icon aria-label="aria-menu" type="down" />
                                </Button>
                            </Dropdown>
                        }
                        {handleSave && <Button className={`left-spacing-2 primary-action-button-filled`}
                            onClick={() => {
                                handleSave(form.getFieldsValue())
                            }}>Save</Button>}
                    </Button.Group>
                </React.Fragment>
            </Row>
        </Form>
    )
}
const FilterFormRef = Form.create()(FilterForm);
export default FilterFormRef;
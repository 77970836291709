import React, { useEffect, useState, useCallback, useRef } from 'react';
import { message, Spin, Tabs, Dropdown } from "antd";
import moment from 'moment-timezone';
import pushMessage from '../common/PushMessage';
import { exportMenu } from '../../utils/strings';
import { useSelector, useDispatch } from 'react-redux';
import { checkIfFilterSortIsEmpty } from '../../utils/formChanged';
import { Button, Table as NeoTable, Icon as NeoIcon, Tooltip as NeoTooltip } from "neo-latest"
import { LoadingOutlined } from '@ant-design/icons';
import CONFIG from '../../config';
import { otherFields } from '../../utils/neoTableFields';
import { fetchNamesBySearchString } from '../../actions/userRole';
import FilterDrawer from "../common/FilterDrawer";
import EditDrawer from '../common/EditDrawer';
import openConfirm from '../common/Confirm';
import SFDCStatsDetails from "./SFDCStatsDetails"
import {
    getAllDemosLite,
    fetchSFDCStatsData,
    clearSFDCStatsData,
    exportTable,
    updateSDFCRecord,
    resendNotificationToAccountOwner,
    sendSFDCReminderToAll,
    currentSFDCStatsFilter
} from "../../actions";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;

function ManageSFDCStats(props) {
    const dispatch = useDispatch();
    const stateRef = useRef();
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState("pending");
    const [showFilter, setShowFilter] = useState(false);
    const [filterSort, setFilterSort] = useState({ filter: {} });
    const [currentPage, setCurrentPage] = useState(0);
    const [matchedUsersNames, setMatchedUsersNames] = useState([]);
    const [record, setRecord] = useState(null);
    const [readyOnly, setReadOnly] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [isArchived, setArchieved] = useState(false);
    const [notes, setNotes] = useState("")
    const timeZoneName = useSelector(({ user }) => user?.profile?.timezone);
    const demos = useSelector(({ filterPanel }) => filterPanel.allDemos);
    const resources = useSelector(({ filterPanel }) => filterPanel.allResources);
    const sfdcStatsData = useSelector(({ sfdcStats }) => sfdcStats.data);
    const isEdit = useSelector(({ sfdcStats }) => sfdcStats.isEdit);
    const filters = useSelector(({ sfdcStats }) => sfdcStats.filters);
    const retainedStatus = useSelector(({ sfdcStats }) => sfdcStats.status);
    const retainedCurrentPage = useSelector(({ sfdcStats }) => sfdcStats.currentPage);
    const isRedirection = useSelector(({ sfdcStats }) => sfdcStats.isRedirection);
    const retainedRecord = useSelector(({ sfdcStats }) => sfdcStats.record);


    const dataIndexMap = {
        email: "createdBy.fullName",
        updatedBy: "updatedBy.fullName",
        accountOwner: "opportunity.accountOwnerEmail",
        demo: "demo.title",
        resource: "resource.name",
        startTime: "startTime",
        endTime: "endTime",
        applyToAllCheck: 'includeStartAndEndDate',
        demoType: 'demo.demoType'
    }

    useEffect(() => {
        setLoading(true);
        (async () => {
            if (isEdit) {
                setFilterSort(filters);
                setStatus(retainedStatus);
                setCurrentPage(retainedCurrentPage);
                if (isRedirection) {
                    handeEditRecord(retainedRecord, false)
                }
            }
            await dispatch(getAllDemosLite(null, true));
            await dispatch(fetchSFDCStatsData(isEdit ? filters : filterSort, isEdit ? retainedStatus : status))
            setLoading(false);
        })();
        const cleanUpContents = () => {
            dispatch(currentSFDCStatsFilter(stateRef?.current?.filterSort, stateRef?.current?.status, stateRef?.current?.currentPage))
        }
        return cleanUpContents;
        // eslint-disable-next-line
    }, [])

    const handleSelectChange = async (value) => {
        await setStatus(value);
        setLoading(true);
        await dispatch(fetchSFDCStatsData(filterSort, value))
        setLoading(false);
    }

    const handeEditRecord = (record, readOnly) => {
        setRecord(record);
        setReadOnly(readOnly)
        setArchieved(record?.isArchived);
        setNotes(record?.archiveNotes)
        setShowEdit(true);
    }

    const handleEditClick = () => {
        setReadOnly(false);
        setShowEdit(true)
    }

    const handleExport = async (fileType) => {
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        if (sfdcStatsData && sfdcStatsData?.length > 0) {
            exportTable(CONFIG.exportTable.sfdcStats?.name, sfdcStatsData, timeZoneName, CONFIG.exportTable.sfdcStats.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    const handleClear = async (isRefresh) => {
        if (!isRefresh) {
            setFilterSort({ filter: {} })
        }
        setLoading(true);
        await dispatch(fetchSFDCStatsData({ filter: {} }, status))
        setLoading(false);
    }

    const renderTable = () => {
        const neoColumns = [
            {
                Cell: (a) => {
                    return <span onClick={() => handeEditRecord(a.cell.row.original, true)} style={{ textAlign: "center", color: "#1B77AF", cursor: "pointer" }}>{a?.cell?.row?.original?.demo?.title}</span>
                },
                Header: "Demo title",
                sortType: "string",
                disableFilters: true,
                accessor: "demo.title",
            },
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    let bufferStartTime;
                    const bufferStart = record?.startTimeBuffer || 0;
                    bufferStartTime = bufferStart > 0 ? moment.tz(record.startTime, timeZoneName).subtract(bufferStart, 'm') : moment.tz(record.startTime, timeZoneName);

                    return <span style={{ textAlign: "center" }}>
                        {(bufferStart > 0) ? <span>
                            {moment(record?.startTime).tz(timeZoneName).format(CONFIG.dateFormats.userDateTime)}{" "}
                            <NeoTooltip
                                position={'auto'}
                                label={<>
                                    <div>Buffer Start Time  - {bufferStartTime?.format(CONFIG.dateFormats.userDateTime)}</div>
                                </>}>{<NeoIcon aria-label="menu-aria" icon="info" style={{ color: '#147ecf' }} />}
                            </NeoTooltip></span> :
                            <span>{moment(record?.startTime).tz(timeZoneName).format(CONFIG.dateFormats.userDateTime)} </span>}
                    </span>
                },
                Header: <span onClick={handleChangeSortName} id='sorting-date'>Start Time</span>,
                disableFilters: true,
                sortType: (rowA, rowB) => {
                    const startTimeA = moment.tz(rowA?.original?.startTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    const startTimeB = moment.tz(rowB?.original?.startTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    if (startTimeA.isBefore(startTimeB)) {
                        return -1;
                    }
                    if (startTimeA.isAfter(startTimeB)) {
                        return 1;
                    }
                    return 0;
                },
                accessor: "startTime",
            },
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    let bufferEndTime;
                    const bufferEnd = record?.endTimeBuffer || 0;
                    bufferEndTime = bufferEnd > 0 ? moment.tz(record.endTime, timeZoneName).add(bufferEnd, 'm') : moment.tz(record.endTime, timeZoneName);
                    const demoType = record?.demoType;
                    return <span style={{ textAlign: "center" }}>
                        {(bufferEnd > 0 && demoType === CONFIG.demoTypes.standardDemo) ? <span>
                            {moment(record?.endTime).tz(timeZoneName).format(CONFIG.dateFormats.userDateTime)}{" "}
                            <NeoTooltip
                                position={'auto'}
                                label={<>
                                    <div>Buffer End Time  - {bufferEndTime?.format(CONFIG.dateFormats.userDateTime)}</div>
                                </>}>{<NeoIcon aria-label="menu-aria" icon="info" style={{ color: '#147ecf' }} />}
                            </NeoTooltip></span> :
                            <span>{moment(record?.endTime).tz(timeZoneName).format(CONFIG.dateFormats.userDateTime)} </span>}
                    </span>
                },
                Header: <span onClick={handleChangeSortName} id='sorting-date'>End Time</span>,
                disableFilters: true,
                sortType: (rowA, rowB) => {
                    const startTimeA = moment.tz(rowA?.original?.endTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    const startTimeB = moment.tz(rowB?.original?.endTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    if (startTimeA.isBefore(startTimeB)) {
                        return -1;
                    }
                    if (startTimeA.isAfter(startTimeB)) {
                        return 1;
                    }
                    return 0;
                },
                accessor: "endTime",
            },
            {
                Cell: (a) => {
                    const creator = a?.cell?.row?.original?.createdBy?.fullName;
                    return <span style={{ textAlign: "center" }}>{creator || "-"}</span>
                },
                Header: "Created By",
                accessor: "createdBy.fullName",
                disableFilters: true,
                sortType: "basic"
            },
            {
                Cell: (a) => {
                    const creator = a?.cell?.row?.original?.opportunity?.accountOwnerEmail;
                    return <span style={{ textAlign: "center" }}>{creator || "-"}</span>
                },
                Header: "Account Owner",
                disableFilters: true,
                accessor: "opportunity.accountOwnerEmail",
                sortType: "basic"
            },
            {
                Header: "  ",
                disableFilters: true,
                disableSortBy: true,
                Cell: (a) => {
                    const record = a.cell.row.original;
                    return (
                        <React.Fragment>
                            <div style={{ display: "flex", gap: "24px" }}>
                                <div>
                                    <NeoTooltip label="Edit"><NeoIcon aria-label="menu-aria" size="md" style={{ cursor: "pointer", color: "#1B77AF" }} icon="edit"
                                        onClick={() => handeEditRecord(record, false)}
                                    /></NeoTooltip>
                                </div>
                                <div>
                                    <NeoTooltip position='left' label="Send Reminder">
                                        <NeoIcon aria-label="menu-aria" size="md" style={{ cursor: "pointer", color: "#1B77AF" }} icon="email-forwarded"
                                            onClick={() => {
                                                dispatch(resendNotificationToAccountOwner(record?.id));
                                            }}
                                        />
                                    </NeoTooltip>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
            }
        ];

        return (
            loading ? null : <NeoTable
                columns={neoColumns}
                data={sfdcStatsData || []}
                className="table-actions"
                allowColumnFilter={false}
                itemsPerPageOptions={[10, 20, 50, 100]}
                showRowSeparator
                initialStatePageIndex={currentPage}
                handlePageChange={(newPageIndex, newPageSize) => {
                    setCurrentPage(newPageIndex)
                }}
                {...otherFields}
            />
        )
    }

    useEffect(() => {
        stateRef.current = { filterSort: filterSort, status: status, currentPage: currentPage };
        const dropdownLinks = document.querySelectorAll(
            '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
        );
        if (dropdownLinks?.length > 0) {
            dropdownLinks.forEach((link) => {
                link.addEventListener('click', handleDatesSortForNeoTable);
            });
        }
    });

    const handleDatesSortForNeoTable = (event) => {
        const targetElement = event.target;
        const sortingDateChild = targetElement.querySelector('#sorting-date');
        const arrowChild = ((targetElement.classList?.contains("neo-icon--small") || (targetElement.classList?.contains("neo-icon-arrow-down")) ||
            (targetElement.classList?.contains("neo-icon-arrow-up"))) && ((targetElement?.offsetParent?.firstChild?.innerHTML?.includes("sorting-date")) || (targetElement?.offsetParent?.lastChild?.innerHTML?.includes("sorting-date"))));
        if (sortingDateChild || arrowChild) {
            setTimeout(() => {
                const myElement = document.querySelector('.neo-dropdown__content');
                if (myElement) {
                    myElement.classList.add('sorting-classes');
                    const parentItem = document.querySelector(".sorting-classes");
                    const children = parentItem.children;
                    children[0].textContent = 'Clear Sort';
                    children[1].textContent = 'Older to Newer';
                    children[2].textContent = 'Newer to Older';
                }
            }, 10)
        }
    }

    const handleChangeSortName = () => {
        setTimeout(() => {
            const myElement = document.querySelector('.neo-dropdown__content');
            if (myElement) {
                myElement.classList.add('sorting-classes');
                const parentItem = document.querySelector(".sorting-classes");
                const children = parentItem.children;
                children[0].textContent = 'Clear Sort';
                children[1].textContent = 'Older to Newer';
                children[2].textContent = 'Newer to Older';
            }
        }, 10)
    }

    const renderFilterPanel = () => {
        return (
            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                <div style={{ display: "flex", flex: "1", alignItems: "center" }}>
                    <Button disabled={!(sfdcStatsData && sfdcStatsData?.length > 0)} onClick={() => {
                        const modalTitle = `Would you like to notify all the below account owners ? `;
                        openConfirm("Notify", () => dispatch(sendSFDCReminderToAll(status)), null, null, modalTitle, null, "Cancel");
                    }} className="remove-btn-index" icon="email" variant="primary">Notify Everyone</Button>
                </div>
                <div style={{ display: "flex", flex: "1", width: "100%", flexDirection: "row-reverse", gap: "24px" }}>
                    <div >
                        <Dropdown overlay={exportMenu(handleExport)} trigger="click">
                            <Button className="export-download-button remove-btn-index" icon="chevron-down" variant="secondary">Download</Button>
                        </Dropdown>
                    </div>
                    <div >
                        <NeoIcon aria-label="menu-aria" size="md" className="clickable" onClick={() => setShowFilter(filter => !filter)} title="Filter" icon={(showFilter || (!checkIfFilterSortIsEmpty(filterSort))) ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                    <div >
                        <NeoIcon aria-label="menu-aria" size="md" className="clickable" title="Refresh" icon="refresh" onClick={() => handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                </div>
            </div >
        )
    }

    const renderTabContents = () => {
        return (
            <React.Fragment>
                <div>
                    {renderFilterPanel()}
                </div>
                <div className="height-neo-table">
                    {renderTable()}
                </div>
            </React.Fragment>
        )
    }

    const getUTCTime = (time) => {
        return time.tz(timeZoneName, true).toISOString() || undefined
    }

    const handleFilter = async (filters) => {
        let { demo, startEndTime, resource, demoType, email, updatedBy, accountOwner } = filters;
        email = email?.trim();
        updatedBy = updatedBy?.trim();
        accountOwner = accountOwner?.trim();
        const isStartEndTimeEmpty = !startEndTime || (startEndTime && startEndTime.length === 0);
        const isDemoEmpty = !demo || (demo && demo.length === 0);
        const isDemoTypeEmpty = !demoType || (demoType && demoType.length === 0);
        const isResourceEmpty = !resource || (resource && resource.length === 0);

        if (!email && !updatedBy && !accountOwner && isStartEndTimeEmpty && isDemoTypeEmpty && isResourceEmpty && isDemoEmpty) {
            pushMessage(CONFIG.messageType.warning, `Please select some fields to filter`)
            return;
        }
        await dispatch(clearSFDCStatsData())
        const filterSortObject = {
            filter: {
                [dataIndexMap.email]: email || undefined,
                [dataIndexMap.updatedBy]: updatedBy || undefined,
                [dataIndexMap.accountOwner]: accountOwner || undefined,
                [dataIndexMap.demo]: !isDemoEmpty ? demo.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.resource]: !isResourceEmpty ? resource.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.startTime]: !isStartEndTimeEmpty ? getUTCTime(startEndTime[0]) : undefined,
                [dataIndexMap.endTime]: !isStartEndTimeEmpty ? getUTCTime(startEndTime[1]) : undefined,
                [dataIndexMap.demoType]: !isDemoTypeEmpty ? demoType.join(CONFIG.delimiters.selectFilter) : undefined,
            }
        }
        setFilterSort(filterSortObject)
        setLoading(true);
        await dispatch(fetchSFDCStatsData(filterSortObject, status))
        setLoading(false);

    }

    const fetchUsersName = searchString => {
        setMatchedUsersNames([])
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id;
                    setMatchedUsersNames([...new Set(result.map(item => item.fullName))])
                }
            });
    };

    const handleSaveRecord = async () => {
        setLoading(true);
        const payload = {
            id: record?.id,
            archiveNotes: notes?.trim(),
            isArchived: isArchived
        }
        await dispatch(updateSDFCRecord(payload));
        await dispatch(fetchSFDCStatsData(filterSort, status))
        setLoading(false);
        setShowEdit(false)
        setRecord(null);
        setArchieved(false);
        setNotes("")
    }

    const drawerContainer = document.getElementById('#component-content');
    const formFields = [
        {
            label: "Demo",
            type: CONFIG.formFieldTypes.SELECT,
            key: "demo",
            mode: "tags",
            valueKey: "text",
            data: (demos) ? demos.map(demo => ({ text: demo.title })) : [],
            colSpan: 24,
            value: filterSort.filter?.[dataIndexMap.demo]
        },
        {
            label: "Start / End Date",
            type: CONFIG.formFieldTypes.RANGE_PICKER,
            key: "startEndTime",
            colSpan: 24,
            value: [filterSort?.filter?.[dataIndexMap.startTime], filterSort?.filter?.[dataIndexMap.endTime], filterSort?.filter?.[dataIndexMap.applyToAllCheck]],
        },
        {
            label: "Resource",
            type: CONFIG.formFieldTypes.SELECT,
            key: "resource",
            mode: "tags",
            valueKey: "text",
            data: (resources) ? resources.map(resource => ({ text: resource.name })) : [],
            value: filterSort.filter?.[dataIndexMap.resource],
            colSpan: 24
        },
        {
            label: "Demo Type",
            type: CONFIG.formFieldTypes.SELECT,
            key: "demoType",
            data: CONFIG.filterDataSet.allDemoTypes,
            value: filterSort.filter?.[dataIndexMap.demoType],
            colSpan: 24
        },
        {
            label: "Created By",
            type: CONFIG.formFieldTypes.SEARCH_SELECT,
            data: matchedUsersNames,
            key: "email",
            value: filterSort?.filter?.[dataIndexMap.email],
            colSpan: 24
        },
        {
            label: "Updated By",
            type: CONFIG.formFieldTypes.SEARCH_SELECT,
            data: matchedUsersNames,
            key: "updatedBy",
            value: filterSort.filter?.[dataIndexMap.updatedBy],
            colSpan: 24
        },
        {
            label: "Account Owner",
            type: CONFIG.formFieldTypes.INPUT,
            key: "accountOwner",
            value: filterSort.filter?.[dataIndexMap.accountOwner],
            colSpan: 24
        },
    ];

    const handleNotesChange = useCallback((value) => {
        setNotes(value);
    }, []);

    return (
        <div style={{ marginTop: "28px" }}>
            <div style={{ display: "flex", flex: "1", color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px" }}>
                Manage Schedule Opportunities
            </div>
            <Spin spinning={loading} indicator={antIcon} >
                <div style={{ marginTop: "16px" }}>
                    <Tabs activeKey={status} className='demo-status-tabs' defaultActiveKey={status} onChange={handleSelectChange}>
                        <TabPane tab="Pending" key="pending">
                            {renderTabContents()}
                        </TabPane>
                        <TabPane tab="Timed-out" key="timedOut">
                            {renderTabContents()}
                        </TabPane>
                        <TabPane tab="Archived" key="archived">
                            {renderTabContents()}
                        </TabPane>
                    </Tabs>
                </div>
            </Spin>
            {showFilter && (
                <FilterDrawer
                    visible={showFilter}
                    width='520'
                    handleCloseFilterDrawer={() => { setShowFilter(false) }}
                    placement="right"
                    drawerContainer={drawerContainer}
                    style={{ zIndex: 9999, }}
                    maskClosable={false}
                    panelHeader="Filters and Export"
                    formFields={formFields}
                    handleClear={handleClear}
                    timeZone={timeZoneName}
                    hideSoftDeleteCheckbox={true}
                    hideSave={true}
                    handleFilter={handleFilter}
                    getSearchData={fetchUsersName}
                />)}
            {
                showEdit && <EditDrawer
                    visible={showEdit}
                    width='520'
                    handleCloseFilterDrawer={() => { setShowEdit(false); setRecord(null); }}
                    placement="right"
                    drawerContainer={drawerContainer}
                    style={{ zIndex: 9999, }}
                    maskClosable={false}
                    timeZone={timeZoneName}
                    readOnly={readyOnly}
                    form={props.form}
                    handleSave={handleSaveRecord}
                    record={record}
                    handleEditClick={handleEditClick}
                    showEdit={true}
                    filterSort={filterSort}
                    title={`${readyOnly ? "" : "Edit"} SFDC Statistics Details`}
                >
                    <Spin spinning={loading} indicator={antIcon}>
                        <SFDCStatsDetails
                            record={record}
                            timezone={timeZoneName}
                            setArchieved={setArchieved}
                            setNotes={handleNotesChange}
                            readOnly={readyOnly}
                        />
                    </Spin>
                </EditDrawer>
            }
        </div>
    )
}

export default ManageSFDCStats;
import React from 'react';
import { Row, Col, Select, Input, Icon, Tooltip } from 'antd';
import { connect } from 'react-redux';

import { updateDemoDCStatus } from '../../actions/index';
import CONFIG from '../../config';
import { getUpdatedStatusDescription, getDisplayTime } from '../../utils/status';
import NoContent from '../common/NoContent'

const { Option } = Select;

class DemoStatus extends React.Component {

    handleSelectChange = (value, timeZone) => {
        const dcStatus = JSON.parse(value);
        const updatedDesc = getUpdatedStatusDescription(dcStatus.status, dcStatus.description || '', timeZone);
        this.props.updateDemoDCStatus({ ...dcStatus, id: dcStatus.id ? dcStatus.id : undefined, description: updatedDesc });
    }

    handleInputChange = (e, status) => {
        this.props.updateDemoDCStatus({ ...status, description: e.target.value });
    }

    renderStatusOptions = (status) => {
        return CONFIG.demoStatus.types.map((statusName, index) => {
            const statusValue = { status: index, description: status.description, dataCenter: status.dataCenter, id: status?.id || 0 };
            return <Option key={index} value={JSON.stringify(statusValue)}>{statusName}</Option>
        });
    }

    render() {
        const { user, statuses, resources } = this.props;
        let resourcesDataCenter = []
        resources?.length > 0 && resources.forEach(item => resourcesDataCenter.push(item.resource.dataCenter.title));
        const empty = < NoContent description="No Data" />
        const timeZone = user ? user.profile.timezone : "";
        return (
            <div className="demoStatusContainer">
                {
                    resourcesDataCenter?.length !== 0
                        ? statuses?.map((dcStatus, index) => {
                            const demoDCStatus = { status: dcStatus.status, description: dcStatus.description, dataCenter: dcStatus.dataCenter, id: dcStatus?.id || 0 };
                            return dcStatus.dataCenter.active && resourcesDataCenter?.includes(dcStatus.dataCenter.title) && (
                                <Row gutter={12} key={index} className="vertical-spacing">
                                    <Col span={3} className="bold">Data Center : </Col>
                                    <Col span={3} className="title-medium"> {dcStatus.dataCenter.title} </Col>
                                    <Col span={2} className="bold">Status : </Col>
                                    <Col span={6}>
                                        <Select showSearch={true} defaultValue={JSON.stringify(demoDCStatus)} placeholder="Select a status" onChange={(value) => this.handleSelectChange(value, timeZone)}>
                                            {this.renderStatusOptions(dcStatus)}
                                        </Select>
                                    </Col>
                                    <Col span={3} className="bold"> <Tooltip title={CONFIG.systemMessage.dcDescription} >Description : <Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip> </Col>
                                    <Col span={6}><Input onChange={(e) => this.handleInputChange(e, dcStatus, timeZone)} defaultValue={dcStatus.description} value={getDisplayTime(dcStatus.description)} /></Col>
                                </Row>
                            )
                        })
                        : empty
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        statuses: state.openTabDemoDetails?.currentTabData?.demoDCStatuses,
        user: state.user,
        resources: state.openTabDemoDetails?.currentTabData?.demoResources
    }
}

export default connect(
    mapStateToProps,
    {
        updateDemoDCStatus
    }
)(DemoStatus);
import React from 'react'
import { connect } from "react-redux";
import moment from 'moment-timezone';
import { Form, Row, Col, Input, Button, Switch, DatePicker, Upload, Icon, Spin, Card, Modal, Alert, Divider } from 'antd';
import { batch } from 'react-redux';
import EmailUpdates from '../common/EmailUpdates';
import HTML from '../common/HTML'
import {
  toggleBackButton,
  fetchNews,
  toggleIsFetchingNewsFlag,
  postNews,
  updateNewsDescription,
  updateNewsRoleTemplate,
  fetchRoleTemplates,
  fetchAdminRoleTemplates,
  saveNewsRoleTemplates,
  cancelNewsRoleTemplates,
  clearCurrentNews,
  currentNewsFilter,
  deleteTab,
  updateTabsLocalStorage,
  hasSaveChanges,
  updateNewsDetailsTabData,
  updateNewsEditorTabData,
  toggleHasUnsavedChangesFlag,
  closeCurrentOpenTab,
  clearFormData,
  putNewsMedia,
  deleteNewsMedia,
  deleteNews,
  postDraftNews,
  handleOnClickAction,
} from '../../actions';
import TextEditor from '../common/TextEditor';
import RoleTemplatesTable from '../common/RoleTemplatesTable';
import history from '../../history';
import CONFIG from '../../config';
import openConfirm from '../common/Confirm';
import pushMessage from '../common/PushMessage';
import NoContent from '../common/NoContent';
import FormActionButtons from '../common/FormActionButtons';
import { getStartOfDateValue, range, areDatesEqual, areHoursEqual, getDateTime } from '../../utils/datepicker';
import { isTabAlreadyOpen, isTabRestoredFromLocalStorage } from "../common/TabLayout";
import { checkIfNewsHasChanged } from "../../utils/formChanged";
import { getFileName } from '../../utils/file';
import { validateURL } from '../../utils/strings';
import { Collapse } from 'antd5';

const { Panel } = Collapse;
const { Dragger } = Upload;

const NewsForm = Form.create({ name: 'news_form' })(
  class extends React.Component {

    constructor(props) {
      super(props);
      this.isEditMode = this.props.match.params.newsId !== undefined;
      this.state = {
        startValue: null,
        endValue: null,
        visible: false,
        media: null,
        imagePreviewUrl: null,
        imagesList: [],
        isClone: false,
        coverMedia: null,
        coverImagePreviewUrl: null,
        coverImageList: [],
        isLoaded: false,
      };
    }

    static getDerivedStateFromProps(props, state) {
      const { newsDetailsTabData } = props;
      const isClone = window.location.pathname?.includes("/news/clone");
      const { newsId } = props.match.params;
      const id = newsId !== undefined ? newsId : "-1";
      const editMode = props.match.params.newsId !== undefined;
      const currentNews = newsDetailsTabData?.find(item => (
        (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isClone)));
      if (editMode && !state.isLoaded) {
        let mediaFile;
        let coverMediaFile;
        mediaFile = new File([], getFileName(currentNews?.details?.mediaPath));
        coverMediaFile = new File([], getFileName(currentNews?.details?.coverImagePath));
        mediaFile.uid = Date.now();
        coverMediaFile.uid = Date.now();
        const mediaUploadedFile = currentNews?.details?.mediaPath ? mediaFile : undefined;
        const coverMediaUploadedFile = currentNews?.details?.coverImagePath ? coverMediaFile : undefined;
        return { ...state, media: mediaUploadedFile, imagesList: mediaUploadedFile, coverImageList: coverMediaUploadedFile, coverMedia: coverMediaUploadedFile, isLoaded: true }
      }
      return state;
    }

    componentWillUnmount() {
      if (this.state.intervalId) {
        clearInterval(this.state.intervalId);
        this.setState(prevState => {
          return {
            ...prevState,
            intervalId: 0,
          };
        });
      }

      // Checking if the tab is still in the openTab list. If yes, then update store with form data.
      this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
      const currentNewsData = this.getCurrentNewsData();
      const { bodyHtml, introHtml, originalDetails, details } = currentNewsData || [];
      if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: window.location.pathname })) {
        this.props.updateNewsDetailsTabData({
          newsId: this.props.match.params?.newsId !== undefined
            ? this.props.match.params?.newsId
            : "-1",
          newsDetails: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined, mediaPath: details?.mediaPath || null, coverImagePath: details?.coverImagePath || null, bodyHtml: bodyHtml, introHtml: introHtml, originalDetails: originalDetails },
          isClone: this.state.isClone
        });
      }
      this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
    }

    createCurrentObj = (newData, newIntroHtml, newBodyHtml) => {
      const currentData = this.props.form.getFieldsValue();
      return {
        roleTemplates: newData?.roleTemplates?.filter(rT => (rT.adminAccess !== 0 || rT.userAccess !== 0)),
        title: currentData.title,
        link: currentData?.link,
        linkText: currentData?.linkText,
        hasThumbnail: currentData.hasThumbnail,
        publishTime: moment.tz(currentData.publishTime, this.props.timeZoneName),
        expiryTime: moment.tz(currentData.expiryTime, this.props.timeZoneName),
        introHtml: newIntroHtml,
        bodyHtml: newBodyHtml,
        notifyUsers: currentData.notifyUsers,
        isOverride: currentData.isOverride,
        media: currentData.media,
        mediaPath: newData?.mediaPath || null,
        coverMedia: currentData.coverMedia,
        coverImagePath: newData?.coverImagePath
      }
    }

    componentDidUpdate() {
      const { newsId } = this.props.match.params;
      const isNewsClone = window.location.pathname?.includes("/news/clone");
      const currentNewsData = this.getCurrentNewsData();
      const { originalDetails, details, introHtml, bodyHtml } = currentNewsData || [];
      const currentFormData = this.createCurrentObj(details, introHtml, bodyHtml);
      const hasNewFormChanged = newsId ? checkIfNewsHasChanged(currentFormData, originalDetails) : true;
      this.hasChanged = (newsId && !isNewsClone) ? hasNewFormChanged?.[0] || hasNewFormChanged?.[1] : true;
      this.roleTemplatesChanged = (hasNewFormChanged?.[0] === undefined || hasNewFormChanged?.[0] === false) && hasNewFormChanged?.[1] === true ? true : false;
      const { openedTabs, activeKey } = this.props;
      const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
      if (!hasUnsavedChanges && this.hasChanged)
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
      else if (hasUnsavedChanges && !this.hasChanged)
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
    }

    async componentDidMount() {
      const { newsId } = this.props.match.params;
      const { previousTabs } = this.props;
      const currentPath = window.location.pathname
      const isNewsClone = window.location.pathname?.includes("/news/clone");
      this.setState({ isClone: isNewsClone })
      const { userRoleTemplate } = this.props;
      const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
      const fetchAllRoleTemplates = isAdmin ? this.props.fetchAdminRoleTemplates : this.props.fetchRoleTemplates;
      // Checking if the current exact tab is the openTabs list. 
      if (isTabAlreadyOpen({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
        if (isTabRestoredFromLocalStorage({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
          batch(async () => {
            fetchAllRoleTemplates()
              .then(async () => {
                if (newsId) {
                  await this.props.toggleIsFetchingNewsFlag(true);
                  await this.props.fetchNews(newsId, null, null, isNewsClone);
                }
              });
            this.props.updateTabsLocalStorage(currentPath);
            await this.props.updateNewsDetailsTabData({
              newsId: this.props.match.params?.newsId !== undefined
                ? this.props.match.params?.newsId
                : "-1",
              newsDetails: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined, },
              isClone: this.state.isClone
            });
          });
        }
      }
      else {
        // Clearing the old form data - could be uncleared CREATE form / UPDATE form
        await this.props.clearFormData(CONFIG.createComponentRoute.news.title, (this.props.match.params.newsId || '-1'), window.location.pathname?.includes("clone"));
        // Current exact tab is not in the openTabs list. Hence fetching from API
        batch(() => {
          fetchAllRoleTemplates()
            .then(async () => {
              if (newsId) {
                await this.props.toggleIsFetchingNewsFlag(true);
                await this.props.fetchNews(newsId, null, null, isNewsClone);
              }
              else {
                await this.props.updateNewsDetailsTabData({
                  newsId: this.props.match.params?.newsId !== undefined
                    ? this.props.match.params?.newsId
                    : "-1",
                  newsDetails: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined },
                  isClone: this.state.isClone
                });
              }
            });
        })
      }

      this.introKey = Math.random().toString();
      this.bodyKey = Math.random().toString();
    }


    disabledStartDate = (start) => {
      const { timeZoneName } = this.props;
      const currentNewsData = this.getCurrentNewsData();
      const { expiryTime } = currentNewsData;
      const end = this.state.endValue;

      const startValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZoneName, date: moment(start) });
      const currentValue = getStartOfDateValue({ convertTimeZone: true, timeZone: timeZoneName });
      const endValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZoneName, date: moment(end) });
      const expiryValue = expiryTime ? expiryTime.valueOf() : undefined;

      const startTimeCondition = startValue < currentValue;
      const expiryTimeCondition = expiryTime ? startValue > expiryValue : false;

      if (!start || !end) return expiryTime ? (startTimeCondition || expiryTimeCondition) : startTimeCondition;

      return startValue > endValue || startTimeCondition;
    };

    disabledEndDate = end => {
      const { timeZoneName } = this.props;
      const currentNewsData = this.getCurrentNewsData();
      const { publishTime } = currentNewsData;
      const start = this.state.startValue;

      const endValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZoneName, date: moment(end) });
      const currentValue = getStartOfDateValue({ convertTimeZone: true, timeZone: timeZoneName });
      const startValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZoneName, date: moment(start) });
      const publishValue = publishTime ? publishTime.valueOf() : undefined;

      const endTimeCondition = endValue < currentValue;
      const publishTimeValue = publishTime ? endValue < publishValue : false;

      if (!end || !start) return publishTime ? (publishTimeValue || endTimeCondition) : endTimeCondition

      return endValue < startValue;
    };

    disabledPublishTime = (current) => {
      const { endValue } = this.state;
      if (endValue && areDatesEqual(endValue, current)) {
        return {
          disabledHours: () => range(endValue.get('hours') + 1, 24),
          disabledMinutes: () => areHoursEqual(endValue, current) ? range(endValue.get('minute'), 60) : []
        };
      }
    }

    disabledExpiryTime = (current) => {
      const { startValue } = this.state;
      if (startValue && areDatesEqual(startValue, current)) {
        return {
          disabledHours: () => range(0, startValue.get('hours')),
          disabledMinutes: () => areHoursEqual(startValue, current) ? range(0, startValue.get('minute')) : []
        };
      }
    }


    handlePublishOpenChange = (isOpen) => {
      const { form } = this.props;
      const { endValue } = this.state;
      if (!isOpen) {
        const startValue = form.getFieldValue('publishTime');
        if (endValue && startValue && endValue.valueOf() < startValue.valueOf()) form.setFieldsValue({ publishTime: undefined });
      }
    }

    handleExpiryOpenChange = (isOpen) => {
      const { form } = this.props;
      const { startValue } = this.state;
      if (!isOpen) {
        const endValue = form.getFieldValue('expiryTime');
        if (endValue && startValue && endValue.valueOf() < startValue.valueOf()) form.setFieldsValue({ expiryTime: undefined });
      }
    }

    handleChange = (field, value) => {
      this.setState({
        [field]: value,
      });
    };

    handleStartChange = value => {
      this.handleChange('startValue', value);
    };

    handleEndChange = value => {
      this.handleChange('endValue', value);
    };


    handleExpiryOk = () => {
      const { form } = this.props
      if (!form.getFieldValue('publishTime')) {
        form.setFields({
          publishTime: { errors: [Error("Please select start date")] },
          expiryTime: { value: "" }
        })
        this.setState({
          endValue: null
        });
      }
    }

    showModal = () => {
      this.setState({
        visible: true
      });
    };

    handleRoleTemplatesCancel = e => {
      const { newsId } = this.props.match.params;
      const id = newsId !== undefined ? newsId : "-1";
      this.props.cancelNewsRoleTemplates(id, this.state.isClone);
      this.setState({
        visible: false
      });
    };

    handleRoleTemplatesAssign = () => {
      const { newsId } = this.props.match.params;
      const id = newsId !== undefined ? newsId : "-1";
      this.props.saveNewsRoleTemplates(id, this.state.isClone);
      this.setState({ visible: false });
    }

    handleSubmit = () => {
      const isClone = window.location.pathname?.includes("/news/clone");
      const currentNewsData = this.getCurrentNewsData();
      const { details } = currentNewsData || [];
      const draftMode = details?.isDraftMode === true
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.toggleIsFetchingNewsFlag(true);
          this.props.postNews((this.props.match.params.newsId || "-1"), values, isClone, this.roleTemplatesChanged, draftMode);
        }
      });
    }

    handleSaveDraft = (isDraftEdit) => {

      // Creating a Draft out of existing News -->
      // Parent Id --> No
      // IsDraftEdit --> False
      // record --> Yes
      // POST --> http://localhost:7072/newsNotificationSupportService/news/draft/
      // Payload -->    "parentNews":{"id":393}


      // Creating a Draft out of CREATE NEW -->
      // Parent Id --> No
      // IsDraftEdit --> No
      // record --> No

      // Editing a Draft -->
      // Parent Id --> yes / no
      // IsDraftEdit --> true
      // record --> Yes

      const currentNewsData = this.getCurrentNewsData();
      const isNewsClone = currentNewsData ? currentNewsData?.isClone : window.location.pathname?.includes("/news/clone");
      const { introHtml, bodyHtml } = currentNewsData || [];
      const currentData = this.props.form.getFieldsValue();
      const title = currentData?.title?.trim();
      const link = currentData?.link?.trim();
      const linkText = currentData?.linkText?.trim();
      const hasThumbnail = currentData?.hasThumbnail;
      const publishTime = currentData?.publishTime ? moment.tz(currentData?.publishTime, this.props.timeZoneName) : undefined;
      const expiryTime = currentData?.expiryTime ? moment.tz(currentData?.expiryTime, this.props.timeZoneName) : undefined;
      const notifyUsers = currentData?.notifyUsers;
      const isOverride = currentData?.isOverride;
      const media = currentData?.media;
      const coverMedia = currentData?.coverMedia;
      const roleTemplates = Array.isArray(currentData?.roletemplates) && currentData?.roletemplates?.length > 0

      const isDraftCreatedOutOfParent = (isDraftEdit === false && currentNewsData?.id > 0)

      if ((isOverride === undefined || isOverride === false) &&
        (notifyUsers === undefined || notifyUsers === false) &&
        (introHtml === undefined || introHtml === null) &&
        (bodyHtml === undefined || bodyHtml === null) &&
        media === undefined &&
        coverMedia === undefined &&
        publishTime === undefined &&
        expiryTime === undefined &&
        hasThumbnail === false &&
        (title === '' || title === "" || title === undefined) &&
        roleTemplates === false &&
        (link === '' || link === "" || link === undefined) &&
        (linkText === '' || linkText === "" || linkText === undefined)) {
        pushMessage(CONFIG.messageType.warning, "Draft cannot be empty!");
      }
      else {
        this.props.postDraftNews({
          newsId: (this.props.match.params.newsId || "-1"),
          values: currentData,
          onlyRoletemplateChanged: this.roleTemplatesChanged,
          isDraftEdit,
          parentNewsObj: isDraftCreatedOutOfParent ? { id: currentNewsData?.id } : null,
          isNewsClone
        })
      }
    }

    handleReset = async () => {
      await this.props.deleteTab(this.props.activeKey, true)
      history.replace(this.props.activeKey)
      if (this.props.openedTabs?.length === 0) {
        history.replace('/getStarted');
      }
    }

    getAssignedRoleTemplates = (isSuperAdminUser) => {
      const currentNewsData = this.getCurrentNewsData();
      const { details } = currentNewsData || {};
      const { roleTemplates } = details || [];
      let assignedRoleTemplates = [];
      if (roleTemplates) {
        roleTemplates.forEach(obj => {
          const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
          const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
          if (isSuperAdminUser ? userAccessCondition || adminAccessCondition : userAccessCondition) {
            assignedRoleTemplates.push(obj.roleTemplate.title);
          }
        })
      }
      return assignedRoleTemplates;
    }

    renderAssignButton = (hasDraft) => {
      return <Button disabled={hasDraft} className="primary-action-button-bordered right-border-radius" onClick={this.showModal} id='news-roletemplate-assign'>Assign</Button>;
    }

    renderWarningMessage = () => {
      return <Alert message={CONFIG.systemMessage.ckEditorSourceModeWarning} type="warning" showIcon />;
    }

    handleDelete = (details) => {
      const okButtonText = "Confirm Deletion"
      const content = <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "News Draft")}</h4>
      const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'News Draft');
      openConfirm(okButtonText, () => this.handleDeleteConfirmation(details), null, content, modalTitle);
    }

    handleDeleteConfirmation = async (details) => {
      await this.props.deleteNews(details?.draftNews?.id, true, true);
      await this.props.closeCurrentOpenTab();
      await this.props.handleOnClickAction({
        component: CONFIG.editComponentRoute.news,
        tabTitle: details?.title,
        recordId: details?.id,
      })
    }

    renderDraftWarningMessage = (details) => {
      const { user } = this.props;

      const draftCreator = details?.draftNews?.createdBy?.fullName
      const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
      const selfDraftCreator = user?.id === details?.draftNews?.createdBy?.id;
      return (
        <>
          <Alert
            message={selfDraftCreator ? "Continue from where you left!" : "Can't edit this page"}
            description={
              <p>{selfDraftCreator ? `You have some unsaved changes on this page. ` : isSuperAdminUser ? `This page cannot be edited because another user - ${draftCreator} has unsaved changes.` : `This page cannot be edited because another admin ${draftCreator} has unsaved changes. Please ask ${draftCreator} to publish the page, or delete the draft.`}<br /><br />
                {(selfDraftCreator || isSuperAdminUser) && <span>How do you like to proceed?</span>}<br />
                {(selfDraftCreator || isSuperAdminUser) && <ul className='padding-left-30px'>
                  <li>Continue <span className='link' onClick={async () => {
                    this.props.closeCurrentOpenTab();
                    await this.props.handleOnClickAction({
                      component: CONFIG.draftEditComponentRoute.news,
                      tabTitle: details?.draftNews?.title,
                      recordId: details?.draftNews?.id,
                    })
                  }}
                  >editing </span>existing draft.</li>
                  <li>
                    <span className='link' onClick={async () => this.handleDelete(details)}
                    >Delete </span>existing draft to edit this page.<br />
                  </li>
                </ul>}
              </p>
            }
            type="warning"
            showIcon
          />
          <br />
        </>
      )
    }

    renderMediaMessage = () => {
      return <Alert message=" Please ensure that you have uploaded image before saving the news" type="warning" showIcon />;
    }

    getCurrentNewsData = () => {
      const { newsDetailsTabData } = this.props;
      const isClone = window.location.pathname?.includes("/news/clone");
      const { newsId } = this.props.match.params;
      const id = newsId !== undefined ? newsId : "-1";

      return newsDetailsTabData?.find(item => (
        (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isClone)));
    }

    renderCloneWarningMessage = () => {
      return (
        <Alert
          message="Media will not be cloned. Please upload new media."
          type="warning"
          showIcon
        />
      )
    }

    renderFormButtons = (draftMode, isNewsClone) => {
      return (
        <Row className="right-align vertical-spacing">
          <Button onClick={this.handleCloseTab} className={`cancel-button`} id='news-discard-changes'>
            {'Discard Changes'}
          </Button>
          <Button disabled={draftMode ? false : !this.hasChanged}
            className={`left-spacing primary-action-button-filled`}
            onClick={this.handleSubmit} id='news-action-confirm'>
            {draftMode ? "Create News" : this.isEditMode && !isNewsClone ? "Update News" : "Create News"}
          </Button>
          {<Button
            disabled={!this.hasChanged}
            className="left-spacing primary-action-button-filled button-width"
            id='news-form-save-draft'
            onClick={() => this.handleSaveDraft(draftMode)}>
            {draftMode ? "Update Draft" : "Save Changes as Draft"}
          </Button>}
        </Row>
      )
    }

    renderFormButtonsUnsavedChanges = () => {
      return (
        <Row className="right-align vertical-spacing">
          <Button onClick={() => this.handleCloseTab()} className={`cancel-button button-width`} id='news-form-close'>Close</Button>
        </Row>
      )
    }

    handleDeleteImage = async (isThumbnail, fromTabClose) => {
      const currentNewsData = this.getCurrentNewsData();
      const { originalDetails, details } = currentNewsData || [];
      const isClone = window.location.pathname?.includes("/news/clone");
      const { newsId } = this.props.match.params;
      if (fromTabClose) {
        if ((originalDetails?.mediaPath?.trim() !== details?.mediaPath?.trim() && details?.mediaPath)) {
          const filePath = details?.mediaPath?.slice(details?.mediaPath?.indexOf("NEWS") + 5)
          await this.props.deleteNewsMedia(filePath, newsId, isClone, originalDetails?.mediaPath, true)
        }
        if ((originalDetails?.coverImagePath?.trim() !== details?.coverImagePath?.trim() && details?.coverImagePath)) {
          const filePath = details?.coverImagePath?.slice(details?.coverImagePath?.indexOf("NEWS") + 5)
          await this.props.deleteNewsMedia(filePath, newsId, isClone, originalDetails?.coverImagePath, false)
        }
      }
      else {
        const fileName = isThumbnail ? details?.mediaPath : details?.coverImagePath;
        const filePath = fileName.slice(fileName.indexOf("NEWS") + 5)
        await this.props.deleteNewsMedia(filePath, newsId, isClone, isThumbnail ? originalDetails?.mediaPath : originalDetails?.coverImagePath, isThumbnail)
      }
    }

    handleCloseTab = async () => {
      this.handleDeleteImage(undefined, true);
      this.props.closeCurrentOpenTab();
    }

    renderPanelHeader = (headerText, icon) => {
      return (
        <span className="medium-text">
          <Icon aria-label="aria-menu" type={icon} />
          &nbsp;&nbsp;&nbsp;
          {headerText}
        </span>
      )
    }

    render() {
      const { getFieldDecorator, getFieldValue } = this.props.form;
      const { newsId } = this.props.match.params;
      const { roleTemplates, user, timeZoneName } = this.props;
      const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
      const currentDateTime = getDateTime({ convertTimeZone: true, timeZone: timeZoneName });
      const currentNewsData = this.getCurrentNewsData();
      const { bodyHtml, introHtml, details, isContentLoaded, fetchFromAPI } = currentNewsData || [];
      const isNewsClone = currentNewsData ? currentNewsData?.isClone : window.location.pathname?.includes("/news/clone");
      const assignedRoleTemplates = this.getAssignedRoleTemplates(isSuperAdminUser);
      const { hasDraft } = isNewsClone ? false : details || false;
      const props = {
        disabled: this.state.disabled,
        accept: ".png, .jpg",
        onRemove: () => {
          const content = "This thumbnail will be permanently deleted. You cannot undo this action."
          const modalTitle = "Would you like to delete this thumbnail?"
          openConfirm("Confirm Deletion", () => {
            this.setState({
              media: null,
              imagesList: [],
              imagePreviewUrl: null,
            });
            this.handleDeleteImage(true, false)
          }, null, content, modalTitle);

        },
        fileList: this.state.imagesList ? Array.isArray(this.state.imagesList) ? this.state.imagesList : [this.state.imagesList] : [],
        beforeUpload: (image) => {
          if (this.state.media) {
            pushMessage(CONFIG.messageType.warning, "You can upload only one file")
            return true
          }
          const isWithinUploadLimit = image.size / 1024 / 1024 < CONFIG.fileSize.dynamicEmailAttachment;
          if (!isWithinUploadLimit) {
            pushMessage(CONFIG.messageType.warning, `Thumbnail Image must be smaller than ${CONFIG.fileSize.dynamicEmailAttachment} MB!`)
            return true
          }
          if (image) {
            this.props.toggleIsFetchingNewsFlag(true);
            const isClone = window.location.pathname?.includes("/news/clone");
            const { newsId } = this.props.match.params;
            this.props.putNewsMedia(image, newsId, isClone, true)
              .then(() => { this.props.toggleIsFetchingNewsFlag(false) })

          }
          let reader = new FileReader();
          const imageItem = {
            uid: image.uid,
            name: image.name
          };
          reader.onloadend = () => {
            this.setState({
              imagesList: [imageItem],
              media: image,
              imagePreviewUrl: reader.result,
            });
          }
          reader.readAsDataURL(image);
          return false;
        },
      };

      const coverImageProps = {
        accept: ".png, .jpg",
        onRemove: () => {
          const content = "This cover image will be permanently deleted. You cannot undo this action."
          const modalTitle = "Would you like to delete this cover image?"
          openConfirm("Confirm Deletion", () => {
            this.setState({
              coverMedia: null,
              coverImagePreviewUrl: null,
              coverImageList: [],
            });
            this.handleDeleteImage(false, false)
          }, null, content, modalTitle);

        },
        fileList: this.state.coverImageList ? Array.isArray(this.state.coverImageList) ? this.state.coverImageList : [this.state.coverImageList] : [],
        beforeUpload: (image) => {
          if (this.state.coverMedia) {
            pushMessage(CONFIG.messageType.warning, "You can upload only one file")
            return true
          }
          const isWithinUploadLimit = image.size / 1024 / 1024 < CONFIG.fileSize.news;
          if (!isWithinUploadLimit) {
            pushMessage(CONFIG.messageType.warning, `Cover Image must be smaller than ${CONFIG.fileSize.news} MB!`)
            return true
          }
          if (image) {
            this.props.toggleIsFetchingNewsFlag(true);
            const isClone = window.location.pathname?.includes("/news/clone");
            const { newsId } = this.props.match.params;
            this.props.putNewsMedia(image, newsId, isClone, false)
              .then(() => { this.props.toggleIsFetchingNewsFlag(false) })

          }
          let reader = new FileReader();
          const imageItem = {
            uid: image.uid,
            name: image.name
          };
          reader.onloadend = () => {
            this.setState({
              coverImageList: [imageItem],
              coverMedia: image,
              coverImagePreviewUrl: reader.result,
            });
          }
          reader.readAsDataURL(image);
          return false;
        },
      }


      const { viewport } = this.props;
      const empty = <span style={{ display: "flex", justifyContent: "center", position: "relative", bottom: "15px" }}><NoContent description="No uploaded media" /></span>
      const draftMode = window.location.pathname?.includes("/news/draft");
      return (
        <Spin spinning={this.props.news.isFetching} wrapperClassName="spin-overlay" >
          <div className="content-container">
            {hasDraft ? this.renderFormButtonsUnsavedChanges(draftMode, isNewsClone) : this.renderFormButtons(draftMode, isNewsClone)}
            {hasDraft && this.renderDraftWarningMessage(details)}
            {isNewsClone && <>{this.renderCloneWarningMessage()}<br /></>}
            <Row gutter={48}>
              <Col xl={10} sm={10} xs={24}>
                <Form.Item
                  label="Title">
                  {getFieldDecorator("title", {
                    rules: [
                      {
                        required: true,
                        message: "Please input the title of the news"
                      }
                    ],
                    initialValue: details ? (isNewsClone && fetchFromAPI) ? details?.title + "_clone" : details?.title : ""
                  })(<Input disabled={hasDraft} />)}
                </Form.Item>
              </Col>
              <Col xl={10} sm={10} xs={24}>
                <Form.Item label="Assign Role Template">
                  {getFieldDecorator("roletemplates", {
                    initialValue: assignedRoleTemplates.length !== 0
                      ? assignedRoleTemplates
                      : "None"
                  })(<Input className="role-template-input" disabled addonAfter={this.renderAssignButton(hasDraft)} />)}
                  {<Modal
                    title="Role Template"
                    visible={this.state.visible}
                    onCancel={this.handleRoleTemplatesCancel}
                    footer={<FormActionButtons okText="Assign" handleCancel={this.handleRoleTemplatesCancel} handleSubmit={this.handleRoleTemplatesAssign} />}
                  >
                    <RoleTemplatesTable
                      hideColumns={isSuperAdminUser ? [] : ['admin']}
                      dataKey="roleTemplate"
                      data={roleTemplates.all}
                      pagination={false}
                      assignedData={details?.roleTemplates}
                      allAdminAccess={details?.allRTAdminAccess}
                      allUserAccess={details?.allRTUserAccess}
                      updateRoleTemplateData={this.props.updateNewsRoleTemplate}
                      recordId={newsId ? newsId : "-1"}
                      isClone={this.state.isClone}
                    />
                  </Modal>
                  }
                </Form.Item>
              </Col>
              <Col span={4} sm={4} xs={24}>
                <Form.Item label="Thumbnail">
                  {getFieldDecorator("hasThumbnail", {
                    valuePropName: "checked",
                    initialValue: details ? details?.hasThumbnail : false
                  })(<Switch
                    disabled={hasDraft}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                  />)}
                </Form.Item>
              </Col>
            </Row>
            {!hasDraft && <EmailUpdates
              form={this.props.form}
              showIsOverride={true}
              colSpan={6}
              initialValue={[details?.notifyUsers, details?.isOverride]}
            />}
            <div className="bottom-spacing">
              <h3>Intro</h3>
              {!hasDraft && this.renderWarningMessage()}
              {!hasDraft && <br />}
              {hasDraft ?
                <div className="ckeditor-readonly">
                  <HTML htmlContent={introHtml} /></div>
                : <TextEditor
                  updateEditorContent={this.props.updateNewsEditorTabData}
                  storeKey="newsIntro"
                  componentKey={CONFIG.editorType.intro}
                  editorKey={this.introKey}
                  isEdit={this.isEditMode}
                  recordId={newsId ? newsId : "-1 "}
                  contentData={introHtml}
                  isClone={this.state.isClone}
                  isContentLoaded={isContentLoaded}
                />}
            </div>
            <div className="vertical-spacing">
              <h3>Body</h3>
              {!hasDraft && this.renderWarningMessage()}
              {!hasDraft && <br />}
              {hasDraft ?
                <div className="ckeditor-readonly"><HTML htmlContent={bodyHtml} /></div>
                : <TextEditor
                  updateEditorContent={this.props.updateNewsEditorTabData}
                  storeKey="newsBody"
                  componentKey={CONFIG.editorType.body}
                  editorKey={this.bodyKey}
                  isEdit={this.isEditMode}
                  recordId={newsId ? newsId : "-1"}
                  contentData={bodyHtml}
                  isClone={this.state.isClone}
                  isContentLoaded={isContentLoaded}
                />}
            </div>
            <Row gutter={48}>
              <Col xl={12} sm={12} xs={24} >
                <Form.Item label="Link Title">
                  {getFieldDecorator("linkText", {
                    rules: [
                      {
                        required: getFieldValue('link')?.length > 0 ? true : false,
                        message: "Please input the title of the news"
                      }
                    ],
                    initialValue: details ? details?.linkText : undefined
                  })(<Input disabled={hasDraft} />)}
                </Form.Item>
              </Col>
              <Col xl={12} sm={12} xs={24} >
                <Form.Item label="Link URL">
                  {getFieldDecorator("link", {
                    rules: [
                      {
                        required: getFieldValue('linkText')?.length > 0 ? true : false,
                        message: "Please enter a Valid URL",
                        validator: getFieldValue('linkText')?.length > 0 ? validateURL : false,
                      }
                    ],
                    initialValue: details ? details?.link : undefined
                  })(<Input className="link-url" disabled={hasDraft} />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={48}>
              <Col xl={12} sm={12} xs={24} >
                <Form.Item label="Publish on">
                  {getFieldDecorator("publishTime", {
                    rules: [
                      {
                        required: true,
                        message: "Please select publish date"
                      }
                    ],
                    initialValue: details ? details?.publishTime : undefined
                  })(<DatePicker
                    disabled={hasDraft}
                    className="full-width"
                    disabledDate={this.disabledStartDate}
                    disabledTime={this.disabledPublishTime}
                    showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
                    format={CONFIG.dateFormats.default}
                    placeholder="Publish Date"
                    onChange={this.handleStartChange}
                    onOpenChange={this.handlePublishOpenChange}
                    showToday={false}
                    defaultPickerValue={currentDateTime}
                  />)}
                </Form.Item>
              </Col>
              <Col xl={12} sm={12} xs={24} >
                <Form.Item label="Expire on">
                  {getFieldDecorator("expiryTime", {
                    rules: [
                      {
                        required: true,
                        message: "Please select expiry date"
                      }
                    ],
                    initialValue: details ? details?.expiryTime : undefined
                  })(<DatePicker
                    disabled={hasDraft}
                    className="full-width"
                    disabledTime={this.disabledExpiryTime}
                    disabledDate={this.disabledEndDate}
                    onOpenChange={this.handleExpiryOpenChange}
                    showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
                    format={CONFIG.dateFormats.default}
                    placeholder="Expiry Date"
                    onChange={this.handleEndChange}
                    showToday={false}
                    defaultPickerValue={currentDateTime}
                  />)}
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <div>
              {!hasDraft && <div>
                {this.renderMediaMessage()}
              </div>}
            </div>
            <br />
            <Collapse
              bordered={false}
              style={{ background: "#fafafa" }}
              expandIcon={({ isActive }) => <Icon aria-label="aria-menu" type="caret-right" rotate={isActive ? 90 : 0} />}
            >
              <Panel key="1" header={this.renderPanelHeader("Upload Thumbnail", "file-image")} id='news-form-upload-thumbnail'>
                <Row gutter={48}>
                  <br />
                  <div>
                    {!hasDraft &&
                      <Col xl={12} sm={12} xs={24}>
                        <Form.Item label="Thumbnail Media">
                          {getFieldDecorator("media")(
                            <Dragger  {...props} style={{ height: '500px' }}>
                              <p className="ant-upload-drag-icon" id='news-add-thumbnail'>
                                <Icon aria-label="aria-menu" type="inbox" />
                              </p>
                              <p className="ant-upload-text">Click or drag file to this area to upload</p>
                              <p className="ant-upload-hint">
                                Support for a single upload & .png, .jpg files.
                              </p>
                            </Dragger>)}
                        </Form.Item>
                      </Col>}
                    <Col xl={7} sm={7} xs={24} className={!viewport.isMobileView ? "center-align left-spacing-2 bottom-spacing-2" : "center-align bottom-spacing-2"} >
                      <Card style={{ marginTop: "21px", height: "147px", width: "152px" }} className="news-media">
                        {(details?.mediaPath !== null && details?.mediaPath !== undefined)
                          ? <img alt="Media upload" className="media-dim" src={details?.mediaPath} />
                          : empty
                        }
                      </Card>
                    </Col>
                  </div>
                </Row>
              </Panel>
              <div style={{ height: "10px", background: "white" }}>

              </div>
              <Panel key="2" header={this.renderPanelHeader("Upload Cover Image", "cloud-upload")} id='news-form-upload-cover'>
                <Row gutter={48}>
                  <br />
                  <div>
                    {!hasDraft &&
                      <Col xl={12} sm={12} xs={24}>
                        <Form.Item label="Cover Image Media">
                          {getFieldDecorator("coverMedia")(
                            <Dragger  {...coverImageProps} style={{ height: '500px' }} >
                              <p className="ant-upload-drag-icon" id='news-add-cover-image'>
                                <Icon aria-label="aria-menu" type="inbox" />
                              </p>
                              <p className="ant-upload-text">Click or drag file to this area to upload</p>
                              <p className="ant-upload-hint">
                                Support for a single upload & .png, .jpg files.
                              </p>
                            </Dragger>)}
                        </Form.Item>
                      </Col>}
                    <Col xl={7} sm={7} xs={24} className={!viewport.isMobileView ? "center-align left-spacing-2 bottom-spacing-2" : "center-align bottom-spacing-2"} >
                      <Card style={{ marginTop: "21px", height: "147px", width: "152px" }} className="news-media">
                        {(details?.coverImagePath !== null && details?.coverImagePath !== undefined)
                          ? <img alt="Media upload" className="media-dim" src={details?.coverImagePath} />
                          : empty
                        }
                      </Card>
                    </Col>
                  </div>
                </Row>
              </Panel>
            </Collapse>
            {hasDraft ? this.renderFormButtonsUnsavedChanges(draftMode, isNewsClone) : this.renderFormButtons(draftMode, isNewsClone)}
          </div>
        </Spin >
      );
    }
  })

const mapStateToProps = ({ currentNews, news, roleTemplates, viewport, user, tabsLayout }) => {
  return {
    timeZoneName: user.profile.timezone,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
    user: user.profile,
    news,
    roleTemplates,
    viewport,
    hasSave: tabsLayout.hasSave,
    activeKey: tabsLayout.activeKey,
    openedTabs: tabsLayout.panes,
    previousTabs: tabsLayout?.previousTabs,
    unsavedChanges: tabsLayout?.unsavedChanges,
    newsDetailsTabData: currentNews.newsDetailsTabData,
    originalData: currentNews.newsData,
    newsIntroTabData: currentNews.newsIntroTabData,
    newsBodyTabData: currentNews.newsBodyTabData
  };
};

export default connect(
  mapStateToProps,
  {
    toggleBackButton,
    fetchNews,
    toggleIsFetchingNewsFlag,
    postNews,
    updateNewsDescription,
    updateNewsRoleTemplate,
    fetchRoleTemplates,
    fetchAdminRoleTemplates,
    saveNewsRoleTemplates,
    cancelNewsRoleTemplates,
    clearCurrentNews,
    currentNewsFilter,
    deleteTab,
    updateTabsLocalStorage,
    hasSaveChanges,
    updateNewsDetailsTabData,
    updateNewsEditorTabData,
    toggleHasUnsavedChangesFlag,
    clearFormData,
    closeCurrentOpenTab,
    putNewsMedia,
    deleteNewsMedia,
    deleteNews,
    postDraftNews,
    handleOnClickAction
  }
)(NewsForm);


import React from "react";
import { connect, batch } from 'react-redux';
import { Table, Divider, Dropdown, Popconfirm, Tooltip, Row, Col, Typography, message } from "antd";
import { Button, Icon } from 'antd'
import moment from 'moment-timezone'
import DragListView from 'react-drag-listview';
import {
    fetchAllNews,
    clearCurrentNews,
    toggleIsFetchingNewsFlag,
    deleteNews,
    clearNews,
    toggleBackButton,
    selectTitle,
    fetchActiveNews,
    reorderNews,
    putNewsOrder,
    fetchNews,
    restoreNews,
    handleOnClickAction,
    refreshNews,
    toggleHasNewsUpdated,
    currentNewsFilter,
    updateNewsCurrentPage,
    clearNewsData,
    fetchDraftNews,
} from '../../actions'
import { exportNewsNotificationTable } from "../../actions/news"
import CONFIG from '../../config'
import StatusFilter from '../common/StatusFilter';
import pushMessage from '../common/PushMessage';
import FilterPanel from '../common/FilterPanel';
import openConfirm from '../common/Confirm';
import scrollIntoView from 'scroll-into-view';
import { fetchNamesBySearchString } from '../../actions/userRole'
import { Icon as NeoIcon } from "@avaya/neo-react";
import { exportMenu } from "../../utils/strings";
import { isTabAlreadyOpen } from "../common/TabLayout";
const { Text } = Typography;
const dataIndexMap = {
    title: 'title',
    startTime: "startTime",
    endTime: "endTime",
    updatedTime: "updatedTime",
    applyToAllCheck: 'includeStartAndEndDate',
    viewDeletedItems: 'viewDeletedItems',
    createdBy: 'createdBy.fullName',
    updatedBy: 'updatedBy.fullName',
};

class ManageNews extends React.Component {
    constructor(props) {
        super(props);
        const that = this;
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const newNews = that.props.activeNews;
                const item = newNews.splice(fromIndex, 1)[0];
                newNews.splice(toIndex, 0, item);
                that.props.reorderNews(newNews);
            },
            handleSelector: "i"
        };
        this.state = {
            inActiveNewsData: [],
            isReturnedFromEdit: false,
            isReturnedFromDelete: false,
            deletedRecordIndex: undefined,
            previousStatus: true,
            matchedUsersNames: [],
            showFilter: false,
        }
        this.status = true;
        this.flag = 0;
    }
    tableRef = React.createRef()
    filter = undefined;
    currentPage = 1
    activeFilterSort = {};
    inactiveFilterSort = {};
    draftFilterSort = {};
    fetchUsersName = searchString => {
        this.setState({ matchedUsersNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    this.setState({ matchedUsersNames: [...new Set(result.map(item => item.fullName))] })
                }
                this.setState({ hasEnteredString: false })
            });
    };
    // componentWillUnmount() {
    //     this.props.clearCurrentNews();
    //     this.props.clearNews();
    // }
    componentDidUpdate = async () => {
        if (this.status !== this.state.previousStatus) {
            await this.setState({ previousStatus: this.status })
            if (this.status === false || this.status === undefined) {
                var tableContent = document.querySelector(".inactive .ant-table-body")
                tableContent && tableContent.addEventListener('scroll', this.handleScroll)
            }
        }
        const newsToScrollIntoView = this.state.isReturnedFromDelete
            ? Math.abs(this.state.deletedRecordIndex - 1)
            : this.state.isReturnedFromEdit
                ? this.props.newsIndex
                : 0;
        (this.state.isReturnedFromEdit || this.state.isReturnedFromDelete)
            && this.state.inActiveNewsData?.length > 0
            && newsToScrollIntoView
            && scrollIntoView(document.querySelector(`.scroll-row-${newsToScrollIntoView}`), {
                align: { top: 0, left: 0 },
                behavior: "smooth"
            },
                this.setState({ isReturnedFromEdit: false, isReturnedFromDelete: false })
            )
    }
    handleScroll = (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight
        let currentScroll = event.target.scrollTop;
        if ((event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100) && (currentScroll !== 0 && maxScroll !== 0)) {
            this.flag += 1      //To solve firefox scroll issue (In firefox maxScroll and currentScroll is equated twice)
            this.flag === 1 && this.handleFetch()
        }
    }

    async componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            await this.props.currentNewsFilter(this.activeFilterSort, this.inactiveFilterSort, this.draftFilterSort, this.filter, this.status)
            // This will ensure that we do not increment the pageNumber on Tab Switch.
            const nextPageNumber = this.currentPage > 2 ? this.currentPage - 1 : this.currentPage
            await this.props.updateNewsCurrentPage(nextPageNumber, (nextPageNumber * 12), 0, 0)
            await this.props.clearNewsData();
        } else {
            this.props.clearNews();
        }
    }
    async componentDidMount() {
        const { news, currentPageNumber } = this.props;
        document.getElementById('tab-pane').scrollIntoView();
        this.setState({ currentPath: window.location.pathname })
        if (news.isEdit) {
            this.filter = news?.currentFilter;
            this.status = news?.status;
            this.activeFilterSort = news?.activeFilterSort || {}
            this.inactiveFilterSort = news?.inactiveFilterSort || {}
            this.draftFilterSort = news?.draftFilterSort || {}
            this.currentPage = currentPageNumber + 1 || 1
            this.status !== true && this.fetchInactiveNewsOnMount();
            if (news?.currentFilter && Object.keys(JSON.parse(JSON.stringify(news?.currentFilter)))?.length > 0) {
                const filterObject = JSON.parse(JSON.stringify(news?.currentFilter));
                Object.keys(filterObject)?.length > 0 && this.setState({ showFilter: true })
            }
        }
        batch(() => {
            this.props.toggleBackButton(false);
            this.props.selectTitle("Manage News");
            this.props.toggleIsFetchingNewsFlag(true);
            this.props.fetchActiveNews(this.activeFilterSort);
            this.status === CONFIG.draftStatusDataSet[2].value && this.props.fetchDraftNews(this.draftFilterSort);
        });
    }
    fetchInactiveNewsOnMount = async () => {
        const { isEdit, pageSize, newsIndex } = this.props;
        this.props.toggleIsFetchingNewsFlag(true)
        this.setState({ loading: true })
        await this.props.fetchAllNews({
            pageNumber: ((isEdit) || this.state.isReturnedFromDelete) ? 1 : this.currentPage++,
            pageSize: (isEdit || this.state.isReturnedFromDelete) ? pageSize : CONFIG.lazyLoadPageSize,
            filterSort: this.inactiveFilterSort,
            status: this.status,
            fetchInactiveNews: true
        })
        if (this.props.response === CONFIG.HTTP_STATUS.OK || this.props.response === CONFIG.HTTP_STATUS.NO_CONTENT) {
            this.setState(({ inActiveNewsData }) => ({
                loading: false,
                inActiveNewsData: this.props.inActiveNews ? inActiveNewsData.concat(this.props.inActiveNews) : [],
                isReturnedFromEdit: isEdit && newsIndex !== undefined ? true : false,
            }))
        }
    }
    getNews = async () => {
        this.activeFilterSort = { ...this.activeFilterSort, filter: this.filter }
        this.inactiveFilterSort = { ...this.inactiveFilterSort, filter: this.filter }
        this.draftFilterSort = { ...this.draftFilterSort, filter: this.filter }
        if (this.status === true) {
            this.props.fetchActiveNews(this.activeFilterSort).catch((error) => { pushMessage(CONFIG.messageType.error, error.message) })
        }
        else if (this.status === false) {
            this.handleFetch()
        }
        else if (this.status === CONFIG.draftStatusDataSet[2].value) {
            this.props.fetchDraftNews(this.draftFilterSort);
        }
        else {
            await this.props.fetchActiveNews(this.activeFilterSort)
            this.handleFetch()
        }
    }

    handleFetch = async () => {
        const { inActiveNewsCount } = this.props;
        const countOfRecordsFetched = this.state.inActiveNewsData?.length;
        if ((inActiveNewsCount === undefined && countOfRecordsFetched === undefined) || !(inActiveNewsCount <= countOfRecordsFetched)) {
            this.props.toggleIsFetchingNewsFlag(true);
            await this.props.fetchAllNews({ filterSort: this.inactiveFilterSort, fetchInactiveNews: true, pageSize: CONFIG.lazyLoadPageSize, pageNumber: this.currentPage++ })
                .then(() => {
                    if (this.props.response === CONFIG.HTTP_STATUS.OK || this.props.response === CONFIG.HTTP_STATUS.NO_CONTENT) {
                        this.setState(({ inActiveNewsData }) => ({
                            inActiveNewsData: this.props.inActiveNews ? inActiveNewsData.concat(this.props.inActiveNews) : [],
                        }))
                    }
                })
        }
        this.flag = 0
    }

    getUTCTime = (time) => {
        const { timeZoneName } = this.props;
        return time.tz(timeZoneName, true).toISOString();
    }

    clearCurrentInactiveNews = async () => {
        this.currentPage = 1
        await this.setState({
            inActiveNewsData: [],
        })
    }

    handleFilter = async (filters) => {
        let { title, startEndTime, applyToAllCheck, viewDeletedItems, createdBy, updatedBy } = filters;
        await this.clearCurrentInactiveNews();
        title = title?.trim();
        const isStartEndTimeEmpty = !startEndTime || (startEndTime && startEndTime.length === 0);
        if (!title && isStartEndTimeEmpty && !viewDeletedItems && !createdBy && !updatedBy) {
            pushMessage(CONFIG.messageType.warning, "Please select some fields to filter")
            return;
        }
        if (viewDeletedItems) this.status = undefined
        this.filter = {
            [dataIndexMap.title]: title || undefined,
            [dataIndexMap.startTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[0]) : undefined,
            [dataIndexMap.endTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[1]) : undefined,
            [dataIndexMap.applyToAllCheck]: applyToAllCheck || undefined,
            [dataIndexMap.viewDeletedItems]: viewDeletedItems,
            [dataIndexMap.createdBy]: createdBy || undefined,
            [dataIndexMap.updatedBy]: updatedBy || undefined,
        }
        this.getNews();
        scrollIntoView(document.querySelector(`.ant-table-thead`), {
            align: { top: 10, left: 0 },
            behavior: "smooth"
        })
    }
    handleClear = async (refresh) => {
        this.props.toggleIsFetchingNewsFlag(true);
        if (refresh) {
            this.props.refreshNews();
            this.currentPage = 1
            this.props.toggleHasNewsUpdated(false)
        }
        else {
            this.setState({ matchedUsersNames: [] })
            await this.clearCurrentInactiveNews();
            this.filter = undefined;
            this.activeFilterSort = { ...this.filterSort, filter: undefined };
            this.inactiveFilterSort = { ...this.inactiveFilterSort, filter: undefined };
            this.draftFilterSort = { ...this.draftFilterSort, filter: undefined };
        }
        this.getNews();
    }
    handleChange = async (status, pagination, _, sorter) => {
        this.props.clearNews();
        await this.clearCurrentInactiveNews();
        const type = status ? "activeFilterSort" : "inactiveFilterSort";
        this[type] = {
            ...this[type],
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }
        this.getNews(pagination.current)
    }

    renderUpdateButton = (type) => {
        const isUpdateDisabled = !this.isTableSorted || this.filter !== undefined || this.props.news?.activeNews?.length === 0
        const deleteView = this.filter?.viewDeletedItems;
        return !deleteView && (
            <Button className="primary-action-button-bordered"
                disabled={!this.props.isReordered && isUpdateDisabled}
                style={type === "top" ? { marginBottom: "10px" } : { marginTop: "10px" }}
                onClick={() => this.props.putNewsOrder(this.props.activeNews)}
            >
                Update order
            </Button>
        );
    }

    handleDeleteConfirmation = async (index, isActive) => {
        const draftView = this.status === CONFIG.draftStatusDataSet[2].value;
        const newsIndex = isActive ? undefined : index
        const hardDelete = this.filter?.viewDeletedItems;
        if (this.props.developerMode && hardDelete) {
            pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
        }
        else {
            const response = await this.props.deleteNews(this.newsId, draftView ? true : hardDelete, draftView);
            if (response) {
                this.handleFetchNewsAfterDeleteOrRestore(this.newsId, newsIndex, index)
            }
        }
    }

    handleFetchNewsAfterDeleteOrRestore = async (newsId, newsIndex, index) => {
        if (this.status !== true) {
            this.clearCurrentInactiveNews();
            const pageSize = ((Math.ceil(index / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) + CONFIG.lazyLoadPageSize);
            const currentPageNumber = ((Math.ceil(index / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) / CONFIG.lazyLoadPageSize) + 1;
            await this.props.updateNewsCurrentPage(currentPageNumber, pageSize, newsId, newsIndex);
            await this.props.fetchActiveNews(this.activeFilterSort)
            await this.props.fetchDraftNews(this.draftFilterSort)
            await this.setState({ isReturnedFromDelete: true, deletedRecordIndex: newsIndex })
            this.currentPage = this.props.currentPageNumber + 1
            this.fetchInactiveNewsOnMount();
        }
        else {
            batch(() => {
                this.props.clearNews();
                this.clearCurrentInactiveNews();
                this.props.toggleIsFetchingNewsFlag(true);
                this.getNews();
            })
        }
    }
    handleRestore = async (newsId, index, isActive) => {
        const newsIndex = isActive ? undefined : index
        const response = await this.props.restoreNews(newsId)
        if (response) {
            this.handleFetchNewsAfterDeleteOrRestore(newsId, newsIndex, index)
        }
    }

    handleDelete = (newsId, index, isActive) => {
        const draftView = this.status === CONFIG.draftStatusDataSet[2].value;
        this.newsId = newsId;
        const hardDelete = this.filter?.viewDeletedItems;
        const okButtonText = (hardDelete || draftView) ? "Confirm Permanent Deletion" : "Confirm Deletion"
        const content = (hardDelete || draftView) ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", hardDelete ? "News" : "News Draft")}</h4> : <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "News")}</h4>
        const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', draftView ? 'News Draft' : 'news');
        openConfirm(okButtonText, () => this.handleDeleteConfirmation(index, isActive), null, content, modalTitle);
    }

    handleSelectChange = async (value) => {
        const tableClassName = (this.status === false || this.status === undefined) ? ".inactive" : ".active"
        var tableContent = document.querySelector(`${tableClassName} .ant-table-body`);
        if (tableContent) {
            tableContent.scrollTop = 0
            await tableContent.removeEventListener('scroll', this.handleScroll)
        }
        this.status = value;
        await this.clearCurrentInactiveNews();
        if (value) this.inactiveFilterSort = { ...this.inactiveFilterSort, sort: undefined }
        else if (value === false) this.activeFilterSort = { ...this.activeFilterSort, sort: undefined }
        else if (value === CONFIG.draftStatusDataSet[2].value) this.draftFilterSort = { ...this.draftFilterSort, sort: undefined }
        batch(() => {
            this.props.clearNews();
            this.props.toggleIsFetchingNewsFlag(true);
            this.getNews();
        })
    }
    handleExport = async (fileType) => {
        const timeZone = this.props.timeZoneName;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        exportNewsNotificationTable(CONFIG.exportTable.news.name, CONFIG.exportTable.news.fileName + `.${fileType}`, fileType, this.activeFilterSort, this.status, timeZone)
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }
    render() {
        const { news, userProfile, timeZoneName } = this.props;
        const { inActiveNewsData } = this.state;
        const userRoleTemplate = userProfile ? userProfile.roleTemplate : undefined;
        const isUserSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        this.isTableSorted = this.activeFilterSort?.sort ? Object.keys(this.activeFilterSort?.sort)?.filter(key => this.activeFilterSort?.sort[key] !== undefined)?.length !== 0 : false;
        const isDragDisabled = this.filter !== undefined;
        const adminColumns = [
            {
                title: "",
                key: "operate",
                align: 'center',
                render: () => <Icon aria-label="aria-menu" className="drag-handle" type="drag" />,
                width: 50
            }
        ]

        const draftNewsColumns = isUserSuperAdmin ? [
            {
                title: <span className={this.filter?.[dataIndexMap.updatedTime] ? "filtered-column" : ''}>Last Modified on</span>,
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                align: 'center',
                width: 200,
                render: (updatedTime) => updatedTime ? moment.tz(updatedTime, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-",
                sorter: true
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.createdBy] ? "filtered-column" : ''}>Created By</span>,
                key: "createdBy.fullName",
                dataIndex: "createdBy",
                sorter: true,
                width: 200,
                render: (createdBy) => createdBy ? createdBy?.fullName : "-",
            },
        ] : [{
            title: <span className={this.filter?.[dataIndexMap.updatedTime] ? "filtered-column" : ''}>Last Modified on</span>,
            dataIndex: 'updatedTime',
            key: 'updatedTime',
            align: 'center',
            width: 200,
            render: (updatedTime) => updatedTime ? moment.tz(updatedTime, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-",
            sorter: true
        }]

        const columns = [
            {
                title: 'No.',
                key: 'index',
                align: 'center',
                width: 75,
                render: (text, record, index) => index + 1,
            },
            {
                title: <span className={this.filter?.[dataIndexMap.title] ? "filtered-column" : ''}>Title</span>,
                dataIndex: 'title',
                key: 'title',
                width: 200,
                sorter: true
            },
            {
                title: <span className={this.filter?.[dataIndexMap.startTime] ? "filtered-column" : ''}>Published Date</span>,
                dataIndex: 'publishTime',
                key: 'publishTime',
                align: 'center',
                width: 200,
                render: (publishTime) => publishTime ? moment.tz(publishTime, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-",
                sorter: true
            },
            {
                title: <span className={this.filter?.[dataIndexMap.endTime] ? "filtered-column" : ''}>Expiry Date</span>,
                dataIndex: 'expiryTime',
                key: 'expiryTime',
                align: 'center',
                width: 200,
                render: (expiryTime) => expiryTime ? moment.tz(expiryTime, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-",
                sorter: true
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.createdBy] ? "filtered-column" : ''}>Created By</span>,
                key: "createdBy.fullName",
                dataIndex: "createdBy",
                sorter: true,
                width: 200,
                render: (createdBy) => createdBy ? createdBy?.fullName : "-",
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.updatedBy] ? "filtered-column" : ''}>Updated By</span>,
                key: "updatedBy.fullName",
                dataIndex: "updatedBy",
                sorter: true,
                width: 200,
                render: (updatedBy) => updatedBy ? updatedBy?.fullName : "-",
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 150,
                fixed: 'right',
                render: (text, record, index) => {
                    const { userProfile } = this.props;
                    const { roleTemplate } = userProfile;
                    const deleteView = this.filter?.viewDeletedItems;
                    const draftView = this.status === CONFIG.draftStatusDataSet[2].value;
                    const isEditDisabled = roleTemplate
                        && roleTemplate.templateType !== CONFIG.roleTypes.superAdmin
                        && record.adminAccess === CONFIG.roleTemplateAccess.readOnly;
                    return (
                        <React.Fragment>
                            {!deleteView &&
                                <>
                                    <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                        <button
                                            onClick={async () => {
                                                await this.props.handleOnClickAction({
                                                    component: draftView ? CONFIG.draftEditComponentRoute.news : CONFIG.editComponentRoute.news,
                                                    tabTitle: record?.title ? record?.title : "",
                                                    recordId: record?.id,
                                                })
                                            }}
                                            disabled={isEditDisabled}
                                            id='manage-news-edit'
                                            className={!isEditDisabled ? "link" : "link-disabled"}>
                                            <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                    {" "}
                                    <Divider type="vertical" />
                                </>
                            }
                            {!deleteView && !draftView &&
                                <>
                                    <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                        < button
                                            onClick={async () => {
                                                await this.props.handleOnClickAction({
                                                    component: CONFIG.cloneComponentRoute.news,
                                                    tabTitle: record?.title,
                                                    recordId: record?.id,
                                                    isClone: true,
                                                })
                                            }}
                                            disabled={isEditDisabled}
                                            id='manage-news-clone'
                                            className={!isEditDisabled ? "link" : "link-disabled"}>
                                            <span class="neo-icon-copy" title="Clone" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                    <Divider type="vertical" />
                                </>
                            }
                            <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                {
                                    <>
                                        <button
                                            disabled={isEditDisabled}
                                            className={!isEditDisabled ? "link" : "link-disabled"}
                                            onClick={() => this.handleDelete(record.id, index + 1, record?.isActive)}
                                            id='manage-news-delete'
                                        >
                                            <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span>
                                        </button>
                                        {deleteView && <>
                                            <Divider type="vertical" />
                                            <Popconfirm disabled={isEditDisabled} title="Confirm restore?" onConfirm={() => this.handleRestore(record.id, index + 1, record?.isActive)}>
                                                <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} id='manage-news-restore'><span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span></button>
                                            </Popconfirm>
                                        </>
                                        }
                                    </>
                                }
                            </Tooltip>
                        </React.Fragment>
                    );
                }
            }
        ];
        const formFields = [
            {
                label: "Title",
                type: CONFIG.formFieldTypes.INPUT,
                key: "title",
                colSpan: 8,
                value: this.filter?.title
            },
            {
                label: "Published / Expiry Date",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "startEndTime",
                colSpan: 16,
                value: [this.filter?.startTime, this.filter?.endTime, this.filter?.includeStartAndEndDate]
            },
            {
                label: "Created By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "createdBy",
                data: this.state.matchedUsersNames,
                colSpan: 8,
                value: this.filter?.[dataIndexMap?.createdBy]
            },
            {
                label: "Updated By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "updatedBy",
                data: this.state.matchedUsersNames,
                colSpan: 8,
                value: this.filter?.[dataIndexMap?.updatedBy]
            },

        ]

        const deleteView = this.filter?.viewDeletedItems;
        const { showFilter } = this.state
        return (
            <React.Fragment>
                <Row>
                    <Col xl={4} sm={6} xs={24}>
                        <StatusFilter disabled={this.filter?.viewDeletedItems} dataSet={CONFIG.draftStatusDataSet} onChange={this.handleSelectChange} value={this.status} />
                    </Col>
                    <Col xl={2} sm={6} xs={24} className="left-spacing">
                        <Button className="primary-action-button-filled"
                            onClick={async () => {
                                await this.props.handleOnClickAction({
                                    component: CONFIG.createComponentRoute.news,
                                    tabTitle: "Create"
                                })
                            }} id='manage-create-news'>New
                            <Icon aria-label="aria-menu" type="plus-circle" />
                        </Button>
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoIcon aria-label="menu-aria" title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} id='manage-export-news' />
                        </Dropdown>
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} id='manage-refresh-news' />
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} id='manage-filter-news' />
                    </Col>
                </Row>
                <Row >
                    {this.state.showFilter &&
                        <FilterPanel
                            label='View Deleted News'
                            panelHeader="Filters"
                            formFields={(this.status === CONFIG.draftStatusDataSet[2].value) ? formFields.slice(0, 2) : formFields}
                            handleClear={this.handleClear}
                            handleFilter={this.handleFilter}
                            getSearchData={this.fetchUsersName}
                            timeZone={timeZoneName}
                            hideSoftDeleteCheckbox={(this.status === CONFIG.draftStatusDataSet[2].value) ? true : false}
                            viewDeletedItemsValue={deleteView}
                        />}
                </Row>
                {(this.status === undefined || this.status === true) &&
                    <Row>
                        <div className={"content-container responsive-container"} ref={(this.status === undefined || this.status === true) ? this.tableRef : undefined}>
                            <h3>{this.filter?.viewDeletedItems ? 'Deleted Active News' : 'Active News'}</h3>
                            {isUserSuperAdmin && this.renderUpdateButton("top")}
                            <DragListView {...this.dragProps}>
                                <Table
                                    size='middle'
                                    className="vertical-spacing responsive-container active"
                                    rowKey={(record) => record.id}
                                    bordered
                                    dataSource={news.activeNews}
                                    columns={(isUserSuperAdmin && isDragDisabled === false) ? [...adminColumns, ...columns] : columns}
                                    loading={news.isFetching}
                                    pagination={false}
                                    scroll={{ y: 550, x: "auto" | 800 }}
                                    onChange={(...rest) => this.handleChange(true, ...rest)}
                                />
                            </DragListView>
                            {isUserSuperAdmin && this.renderUpdateButton("bottom")}
                            <br />
                            <br />
                        </div>
                    </Row>
                }
                {(this.status === undefined || this.status === false) &&
                    <div >
                        <Row>
                            <div className={"content-container responsive-container"} ref={this.status === false ? this.tableRef : undefined}>
                                <h3>{this.filter?.viewDeletedItems ? 'Deleted Inactive News' : 'Inactive News'}</h3>
                                <Table
                                    size='middle'
                                    className="inactive"
                                    rowClassName={(record, index) => (`scroll-row-${index + 1}`)}
                                    rowKey={(record) => record.id}
                                    bordered
                                    dataSource={inActiveNewsData}
                                    columns={columns}
                                    loading={news.isFetching}
                                    onChange={(...rest) => this.handleChange(false, ...rest)}
                                    pagination={false}
                                    scroll={{ y: 550, x: "auto" | 800 }}
                                    footer={() => <>{inActiveNewsData?.length !== 0 && <div style={{ textAlign: 'center' }}>
                                        <Text strong>Fetched {inActiveNewsData?.length} out of {this.props.inActiveNewsCount} inactive News</Text>
                                    </div>}
                                    </>}
                                />
                            </div>
                        </Row>
                    </div>
                }
                {(this.status === CONFIG.draftStatusDataSet[2].value) &&
                    <div >
                        <Row>
                            <div className={"content-container responsive-container"} ref={this.status === CONFIG.draftStatusDataSet[2].value ? this.tableRef : undefined}>
                                <h3>{'Draft News'}</h3>
                                <Table
                                    size='middle'
                                    className="vertical-spacing responsive-container"
                                    rowClassName={(record, index) => (`scroll-row-${index + 1}`)}
                                    rowKey={(record) => record.id}
                                    bordered
                                    dataSource={news.draftNews}
                                    columns={[...columns.slice(0, 2), ...draftNewsColumns, ...columns.slice(-1)]}
                                    loading={news.isFetching}
                                    onChange={(...rest) => this.handleChange(false, ...rest)}
                                    pagination={false}
                                    scroll={{ y: 550, x: 800 }}
                                />
                            </div>
                        </Row>
                    </div>
                }
            </React.Fragment >
        );
    }
}
const mapStateToProps = ({ user, news, tabsLayout, currentNews }) => {
    return {
        userProfile: user.profile,
        timeZoneName: user.profile.timezone,
        activeNews: news.activeNews,
        draftNews: news.draftNews,
        isReordered: news.isReordered,
        news,
        developerMode: user.profile?.isDeveloper,
        inActiveNews: news.inActiveNews,
        inActiveNewsCount: news.inActiveNewsCount,
        currentPage: news.currentPage,
        currentPageNumber: news.currentPageNumber,
        currentNewsId: news.currentNewsId,
        newsIndex: news.newsIndex,
        pageSize: news.pageSize,
        isEdit: news.isEdit,
        response: news.response,
        openedTabs: tabsLayout.panes,
        currentNews,
        newsDetailsTabData: currentNews.newsDetailsTabData
    };
};
export default connect(
    mapStateToProps,
    {
        fetchAllNews,
        clearCurrentNews,
        toggleIsFetchingNewsFlag,
        deleteNews,
        clearNews,
        toggleBackButton,
        selectTitle,
        fetchActiveNews,
        reorderNews,
        putNewsOrder,
        fetchNews,
        restoreNews,
        handleOnClickAction,
        refreshNews,
        toggleHasNewsUpdated,
        currentNewsFilter,
        updateNewsCurrentPage,
        clearNewsData,
        fetchDraftNews,
    }
)(ManageNews);
import React from 'react';
import {
    Avatar, Col, Row, Divider, Spin
} from 'antd';
import { connect } from 'react-redux';

import {
    fetchUserNews,
    updateNewsDetails,
    toggleIsFetchingNewsFlag,
    toggleBackButton,
    clearNews,
    clearCurrentNews,
    openLinkInNewTab,
    showUserDashboardNewsSection
} from '../../actions';
import CONFIG from '../../config'
import { analytics } from '../../firebase';
import moment from 'moment-timezone';
import { Icon } from "@avaya/neo-react"
import { LoadingOutlined } from '@ant-design/icons';
import NoData from '../common/NoData';
class DashboardNews extends React.Component {
    constructor() {
        super();
        this.state = {
            activeNews: true
        }
    }

    handleSelectNews = (selectedNews = {}, flag) => {
        const { user } = this.props;
        const news = { ...selectedNews, ...user, userName: user?.firstName + " " + user?.lastName }
        /* Firebase Analytics Log Event */
        const { news: {
            name: eventName,
            parameters: eventParams
        } } = CONFIG.firebaseEvents;
        let parameters = {};
        Object.keys(eventParams).forEach(paramKey => {
            parameters[paramKey] = news[eventParams[paramKey]]
        });
        analytics().logEvent(eventName, parameters);
        this.props.openLinkInNewTab(`News - ${selectedNews?.title}`, `/news/${selectedNews?.id}`)
    }

    renderNewsList = () => {
        const { news } = this.props;
        return (
            news && news?.length > 0 && news?.map((item, index) => {
                const isLinkTextAvailable = typeof item?.linkText === 'string' && item?.linkText?.trim() !== ''
                const avatar = item?.mediaPath !== null && item?.hasThumbnail === true && <Col span={6}><Avatar size="large" src={item?.mediaPath} shape='square' id='user-news-image' /></Col>
                const title = <><span className="dashboard-news-card-title" onClick={() => this.handleSelectNews(item, true)} id='dashboard-news-title'>{item?.title}</span>
                    <br />
                    {isLinkTextAvailable && <a style={{ fontSize: "12px" }} href={item?.link} rel="noopener noreferrer" target="_blank" id='dashboard-news-link'>{item?.linkText}<br /></a>}
                </>
                return (
                    <div key={item?.id}>
                        <Row className="dashboard-news-card-row">
                            {avatar}
                            <Col span={(item?.mediaPath !== null && item?.hasThumbnail === true) ? 18 : 24}>
                                <span className='pointer'>{title}</span>
                                <span className='dashboard-card-details' id='dashboard-news-published-time'>Published {moment.tz(item?.publishTime, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime3)}</span>
                            </Col>
                        </Row>
                        {(index !== news.length - 1) && <Divider className="dashboard-news-divider" type="horizontal" />}
                    </div>
                )
            })
        )
    }


    componentWillUnmount() {
        this.setState({ activeNews: true })
    }

    render() {
        const { news, isFetching } = this.props;
        return (<>{news?.length > 0 && <div id='dashboard-news'>
            <div style={{ display: "flex" }}>
                <div style={{ flex: "1", }} className='dashboard-schedules-category-heading' id='dashboard-news-heading'><Icon aria-label="aria-menu" icon="file" style={{ fontSize: "20px", paddingRight: "10px" }} />News</div>
                <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "flex-end" }} className='news-card-view-all' >
                    <span id='dashboard-news-view-all' onClick={() => { this.props.openLinkInNewTab('News', `/news`) }} style={{ cursor: "pointer" }}>
                        View All
                    </span>
                </div>
            </div>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={isFetching}>
                <div className='dashboard-news-div'>
                    {news?.length > 0 ? this.renderNewsList() : <NoData size="exsm" />}
                </div>
            </Spin>
        </div>}
        </>
        );
    }
}

const mapStateToProps = ({ userNews, user, news }) => {
    return {
        news: userNews.userNewsData,
        isFetching: news.isFetching,
        user: user.profile,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchUserNews,
        updateNewsDetails,
        toggleIsFetchingNewsFlag,
        toggleBackButton,
        clearNews,
        clearCurrentNews,
        openLinkInNewTab,
        showUserDashboardNewsSection
    }
)(DashboardNews);
import React from 'react'
import { connect } from 'react-redux';
import {
    openLinkInNewTab
} from '../../actions';
import { Icon } from '@avaya/neo-react'
import CONFIG from '../../config';

class DailyTestDemoStats extends React.Component {

    renderDemoStatus = (count, status, data, value, isDailyTesting) => {
        const colorIcon = this.getStatusAndIcon(count, value, isDailyTesting, status);
        return (
            <div className='dashboard-demo-stats-col'>
                {<div className='dashboard-demo-stats-rows'><Icon aria-label="aria-menu" style={{
                    fontSize: "22px",
                    color: colorIcon[0]
                }} icon={colorIcon[1]}></Icon></div>
                }
                <div className='dashboard-demo-stats-rows'>{count}</div>
                {isDailyTesting ? <div className='dashboard-demo-stats-rows'><span className={count > 0 ? 'hover-link' : ''} style={count > 0 ? { color: '#007BAF' } : {}}
                    onClick={() => count > 0 ? this.props.handleModalOpen({ data: data, isDailyTesting: true, status: status }) : undefined}>{status}</span></div> :
                    <div className='dashboard-demo-stats-rows'><span className={count > 0 ? 'hover-link' : ''} style={count > 0 ? { color: '#007BAF' } : {}}
                        onClick={() => count > 0 ? this.props.handleModalOpen({ data: data, isDailyTesting: false, status: status === CONFIG.demoStatus.dataCenterTypes.red ? "Open - Not Working" : status === CONFIG.demoStatus.dataCenterTypes.yellow ? "Open - Partially Working" : "Closed" }) : undefined}>{status === CONFIG.demoStatus.dataCenterTypes.red ? "Open - Not Working" : status === CONFIG.demoStatus.dataCenterTypes.yellow ? "Open - Partially Working" : "Closed"}</span></div>}

            </div>
        )
    }

    getStatusAndIcon = (count, value, isDailyTesting, status) => {
        const color = count > 0 ? value === 0 ? `#DA291C`
            : value === 1 ? `#f38d00`
                : value === 2 ? (isDailyTesting ? `#088A08` : `#292929`) : `black`
            : `#C9C9C9`;
        const icon = !isDailyTesting ? (status === CONFIG.demoStatus.dataCenterTypes.red ? `error${count > 0 ? `-filled` : ``}`
            : `warning${count > 0 ? `-filled` : ``}`)
            : (status === CONFIG.dailyTestingStatus.notWorking ? `error${count > 0 ? `-filled` : ``}`
                : status === CONFIG.dailyTestingStatus.partiallyWorking ? `warning${count > 0 ? `-filled` : ``}`
                    : status === CONFIG.dailyTestingStatus.fullyFunctional ? `available${count > 0 ? `-filled` : ``}` :
                        "clipboard");

        return [color, icon];
    }

    render() {
        const { demoStatusData, demoTestResult } = this.props;
        const { notWorkingCount, partiallyWorkingCount, partiallyWorkingList, notWorkingList } = demoStatusData || 0;
        const { notWorkingDemoCount, partiallyWorkingDemoCount, fullyFunctionalDemoCount, notTestedDemoCount, notWorkingDemos, partiallyWorkingDemos, notTestedDemos, fullyFunctionalDemos } = demoTestResult || 0
        return (
            <div>

                {!(Object.keys(demoTestResult).length === 0 && demoTestResult.constructor === Object) && !(Object.keys(demoStatusData).length === 0 && demoStatusData.constructor === Object)
                    ? <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <div className='daily-test-demo-stats'>
                            <span style={{ marginLeft: '8px' }} className='demo-stats-sub-head'> Status</span>
                            <div className='demo-stats-container'>
                                <div className='demo-stats-child-left'>
                                    <div className='dashboard-daily-testing-demo-stats'>
                                        {this.renderDemoStatus(notWorkingCount, CONFIG.demoStatus.dataCenterTypes.red, notWorkingList, 0, false)}
                                    </div>
                                </div>
                                <div className='demo-stats-child-right'>
                                    <div className='dashboard-daily-testing-demo-stats'>
                                        {this.renderDemoStatus(partiallyWorkingCount, CONFIG.demoStatus.dataCenterTypes.yellow, partiallyWorkingList, 1, false)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='daily-test-demo-stats'>
                            <span style={{ marginLeft: '8px' }} className='demo-stats-sub-head'>Today's Demo Test</span>
                            <div className='demo-stats-container'>
                                <div className='demo-stats-child-left'>
                                    <div className='dashboard-daily-testing-demo-stats'>
                                        {this.renderDemoStatus(notWorkingDemoCount, CONFIG.dailyTestingStatus.notWorking, notWorkingDemos, 0, true)}
                                        {this.renderDemoStatus(fullyFunctionalDemoCount, CONFIG.dailyTestingStatus.fullyFunctional, fullyFunctionalDemos, 2, true)}
                                    </div>
                                </div>
                                <div className='demo-stats-child-right'>
                                    <div className='dashboard-daily-testing-demo-stats'>
                                        {this.renderDemoStatus(partiallyWorkingDemoCount, CONFIG.dailyTestingStatus.partiallyWorking, partiallyWorkingDemos, 1, true)}
                                        {this.renderDemoStatus(notTestedDemoCount, CONFIG.dailyTestingStatus.notTested, notTestedDemos, 3, true)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className='dashboard-no-schedules'><Icon aria-label="aria-menu" icon="info" size='md' />No data</div>}
                <div style={{ marginLeft: "8px" }}>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ user, adminDashboard, dailyReport }) => {
    return {
        userDetails: user.profile,
        demoStatusData: adminDashboard.demoStatusData,
        isFetchingDemoStatus: adminDashboard.isFetchingDemoStatus,
        demoTestResult: adminDashboard.demoTestResult,
        isFetchingReport: dailyReport.loading
    };
}

export default connect(mapStateToProps, {
    openLinkInNewTab
})(DailyTestDemoStats);
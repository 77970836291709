import React from "react";
import { connect, batch } from 'react-redux';
import { Table, Row, Button, Icon, Col, Popconfirm, Divider, message, Dropdown, Tooltip } from "antd";
import { Icon as NeoIcon } from "@avaya/neo-react";
import { exportMenu } from "../../utils/strings";
import DragListView from 'react-drag-listview';
import {
    getAllEZDemos,
    fetchDemos,
    toggleIsFetchingEZDemos,
    deleteEZDemoManage,
    clearEZDemos,
    toggleBackButton,
    selectTitle,
    updateEZDemosCurrentPage,
    restoreEZDemo,
    clearEZDemosTableData,
    currentEZDemoFilter,
    updateActiveTab,
    handleOnClickAction,
    fetchSolutionTypes,
    fetchAllEZDemosForExport,
    exportTable,
    reorderEZDemos,
    getAllActiveEZDemos,
    putEZDemosOrder
} from '../../actions';
import CONFIG from '../../config'
import StatusIcon from "../common/StatusIcon";
import pushMessage from '../common/PushMessage';
import FilterPanel from '../common/FilterPanel';
import StatusFilter from '../common/StatusFilter';
import openConfirm from '../common/Confirm';
import scrollIntoView from 'scroll-into-view';
import { isTabAlreadyOpen } from "../common/TabLayout";

const dataIndexMap = {
    title: 'title',
    status: 'isActive',
    viewDeletedItems: 'viewDeletedItems',
    demo: 'demos.demo.title',
    solutionType: 'solutionTypes.solutionType.name',
};

class EZDemosTable extends React.Component {
    constructor(props) {
        super(props);
        const that = this;
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const newEZDemos = that.props.activeEZDemos;
                const item = newEZDemos.splice(fromIndex, 1)[0];
                newEZDemos.splice(toIndex, 0, item);
                that.props.reorderEZDemos(newEZDemos);
            },
            handleSelector: "i"
        };

        this.state = {
            status: true,
            isReturnedFromEdit: false,
            isReturnedFromDelete: false,
            deletedRecordIndex: undefined,
            showFilter: false,
        }
    }


    tableRef = React.createRef()
    filterSort = {};
    activeFilterSort = {};
    flag = 0;

    clearCurrentEZDemos = async () => {
        await this.props.clearEZDemosTableData();
    }

    async componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentEZDemoFilter(this.filterSort, this.state.status)
            // This will ensure that we do not increment the pageNumber on Tab Switch.
            await this.props.clearEZDemosTableData();
        }
        else {
            this.props.clearEZDemos();
        }
    }

    componentDidUpdate = () => {
        const ezDemoToScrollIntoView = this.state.isReturnedFromDelete
            ? Math.abs(this.state.deletedRecordIndex - 1)
            : this.state.isReturnedFromEdit
                ? this.props.ezDemoIndex
                : 0;

        (this.state.isReturnedFromEdit || this.state.isReturnedFromDelete)
            && this.props.ezDemos?.length > 0
            && ezDemoToScrollIntoView
            && scrollIntoView(document.querySelector(`.scroll-row-${ezDemoToScrollIntoView}`), {
                align: { top: 0, left: 0 },
                behavior: "smooth"
            },
                this.setState({ isReturnedFromEdit: false, isReturnedFromDelete: false })
            )
    }

    async componentDidMount() {
        const { currentStatus, isEdit, currentFilter } = this.props;
        this.setState({ loading: true })
        this.setState({ currentPath: window.location.pathname })
        if (isEdit) {
            this.filterSort = currentFilter;
            if (Object.keys(JSON.parse(JSON.stringify(currentFilter || {})))?.length > 0) {
                const filterObject = JSON.parse(JSON.stringify(currentFilter?.filter || {}));
                Object.keys(filterObject)?.length > 0 && this.setState({ showFilter: true })
            }
            if (currentStatus) this.activeFilterSort = currentFilter;
            await this.setState({ status: currentStatus })
        }
        else {
            this.filterSort = {}
        }
        batch(() => {
            this.handleFetchEZDemos();
            this.props.fetchDemos({ getAll: true });
            this.props.fetchSolutionTypes()
            this.props.toggleIsFetchingEZDemos(true);
        });
    }

    handleFetchEZDemos = async (isFilter) => {
        await this.props.toggleIsFetchingEZDemos(true);
        if (this.state.status || this.state.status === undefined) this.props.getAllActiveEZDemos(this.filterSort);
        await this.props.getAllEZDemos({ filterSort: this.filterSort, status: this.state.status })
        // .then(() => {
        //     if (!isFilter) {
        //         var scrollContainer = document.querySelector('.ant-table-body')
        //         scrollContainer.scrollTop = scrollContainer?.scrollHeight - scrollContainer?.clientHeight - scrollContainer?.clientHeight;
        //     }
        // })
        this.flag = 0;
    }

    handleSelectChange = async (value) => {
        await this.clearCurrentEZDemos();
        this.setState({ status: value }, async () => { await this.handleFetchEZDemos() })
    }

    handleFilter = async (filters) => {
        let { title, viewDeletedItems, demo, solutionType } = filters;
        const isDemoTitleEmpty = !demo || (demo && demo.length === 0);
        const isSolutionTypeEmpty = !solutionType || (solutionType && solutionType.length === 0);
        title = title?.trim();
        if (!title && !viewDeletedItems && isDemoTitleEmpty && isSolutionTypeEmpty) {
            pushMessage(CONFIG.messageType.warning, "Please select some fields to filter")
            return;
        }
        await this.clearCurrentEZDemos();
        await this.props.clearEZDemosTableData();

        this.filterSort = {
            filter: {
                [dataIndexMap.title]: title || undefined,
                [dataIndexMap.viewDeletedItems]: viewDeletedItems,
                [dataIndexMap.demo]: !isDemoTitleEmpty ? demo.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.solutionType]: !isSolutionTypeEmpty ? solutionType.join(CONFIG.delimiters.selectFilter) : undefined,
            }
        }
        if (viewDeletedItems) this.setState({ status: false })
        this.handleFetchEZDemos(true);
        scrollIntoView(document.querySelector(`.ant-table-thead`), {
            align: { top: 10, left: 0 },
            behavior: "smooth"
        })

    }

    handleClear = async (refresh) => {
        if (refresh) {
            await this.props.clearEZDemosTableData();
        }
        else {
            await this.props.clearEZDemosTableData();
            await this.clearCurrentEZDemos();
            this.filterSort = { ...this.filterSort, filter: {} };
        }
        this.props.toggleIsFetchingEZDemos(true);
        await this.handleFetchEZDemos();

    }

    handleSortChange = async (pagination, _, sorter) => {
        await this.clearCurrentEZDemos()
        this.filterSort = {
            ...this.filterSort,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }
        if (this.state.status) {
            this.activeFilterSort = {
                ...this.activeFilterSort,
                sort: sorter.columnKey
                    ? {
                        [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                    }
                    : undefined
            }
        }
        this.handleFetchEZDemos();
    }

    handleDelete = (ezDemoId, index) => {
        this.ezDemoId = ezDemoId;
        this.recordIndex = index
        const hardDelete = this.filterSort?.filter?.viewDeletedItems;
        if (this.props.userProfile?.isDeveloper && hardDelete) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
        else {
            const okButtonText = hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
            const content = hardDelete ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "EZ Demo")}</h4> : <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "EZ Demo")}</h4>
            const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'EZ Demo')
            openConfirm(okButtonText, this.handleDeleteConfirmation, null, content, modalTitle);
        }
    }

    handleDeleteConfirmation = async () => {
        const hardDelete = this.filterSort?.filter?.viewDeletedItems;
        const response = await this.props.deleteEZDemoManage(this.ezDemoId, hardDelete);
        if (response) {
            this.handleFetchEZDemosAfterDeleteOrRestore(this.recordIndex)
        }
    }

    handleFetchEZDemosAfterDeleteOrRestore = async (index) => {
        this.clearCurrentEZDemos()
        await this.setState({ isReturnedFromDelete: true, deletedRecordIndex: index })
        this.handleFetchEZDemos();
    }

    handleRestore = async (ezDemoId, index) => {
        const response = await this.props.restoreEZDemo(ezDemoId);
        if (response) {
            this.handleFetchEZDemosAfterDeleteOrRestore(index);
        }
    }

    handleExport = async (fileType) => {
        const timeZone = this.props.timeZoneName;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const data = await this.props.fetchAllEZDemosForExport({ filterSort: this.filterSort, status: this.state.status })
        exportTable(CONFIG.exportTable.ezDemos.name, data, timeZone, CONFIG.exportTable.ezDemos.fileName + `.${fileType}`, fileType)
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }

    renderUpdateButton = (type) => {
        const isFilterEmpty = Object.keys(this.filterSort)?.length !== 0 && (this.filterSort.hasOwnProperty("filter") ? Object.keys(this.filterSort.filter)?.length !== 0 : false);
        const isUpdateDisabled = !this.isTableSorted || isFilterEmpty || this.props.activeEZDemos?.length === 0
        const deleteView = this.filterSort?.filter?.viewDeletedItems;
        return !deleteView && (
            <Button className="primary-action-button-bordered"
                disabled={!this.props.isReordered && isUpdateDisabled}
                style={type === "top" ? { marginBottom: "10px" } : { marginTop: "10px" }}
                onClick={() => this.props.putEZDemosOrder(this.props.activeEZDemos)}
            >
                Update order
            </Button>
        );
    }

    render() {
        const { timeZoneName, ezDemos, allDemos, loading, solutionTypes, userProfile, activeEZDemos } = this.props;
        const activeTitle = `List of ${this.filterSort?.filter?.viewDeletedItems ? "Deleted" : (this.state.status || this.state.status === undefined) ? "Active" : null} EZ Demos`;
        const inactiveTitle = `List of ${this.filterSort?.filter?.viewDeletedItems ? "Deleted" : this.state.status === false ? "Inactive" : this.state.status === undefined ? "All" : null} EZ Demos`;
        const userRoleTemplate = userProfile ? userProfile.roleTemplate : undefined;
        const isUserSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        this.isTableSorted = this.activeFilterSort?.sort ? Object.keys(this.activeFilterSort?.sort)?.filter(key => this.activeFilterSort?.sort[key] !== undefined)?.length !== 0 : false;
        const isDragDisabled = Object.keys(this.filterSort)?.length !== 0 && (this.filterSort.hasOwnProperty("filter") ? Object.keys(this.filterSort.filter)?.length !== 0 : false);
        const adminColumns = [
            {
                title: "",
                key: "operate",
                align: 'center',
                render: () => <Icon aria-label="aria-menu" className="drag-handle" type="drag" />,
                width: 75
            }
        ]
        const columns = [
            {
                title: 'No.',
                key: 'index',
                align: 'center',
                width: 75,
                render: (text, record, index) => index + 1,
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.title] ? "filtered-column" : ''}>Title</span>,
                dataIndex: 'title',
                key: 'title',
                width: 350,
                sorter: true
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.isActive] !== undefined ? "filtered-column" : ''}>Status</span>,
                dataIndex: "isActive",
                key: "isActive",
                width: 100,
                sorter: true,
                render: status => <StatusIcon status={status} />,
                align: "center"
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.solutionType] ? "filtered-column" : ''}>Associated Solution Demos</span>,
                key: 'demos.title',
                width: 350,
                render: (record) => <>
                    <span>
                        {record?.demos?.map(eachDemo => { return eachDemo?.demo?.title }).join(", ")}
                    </span>
                    <span>
                        {(record?.demos?.length > 0 && record?.solutionTypes?.length > 0) && ", "}
                        {
                            record?.solutionTypes?.map(eachSolType => { return eachSolType?.solutionType?.name }).join(`, `)
                        }
                    </span>
                </>,
                sorter: false
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 150,
                render: (text, record, index) => {
                    const { userProfile } = this.props;
                    const { roleTemplate } = userProfile;
                    const deleteView = this.filterSort?.filter?.viewDeletedItems;
                    const viewRatingDisabled = record?.rating === 0;
                    const isEditDisabled = roleTemplate
                        && roleTemplate.templateType !== CONFIG.roleTypes.superAdmin
                        && record.adminAccess === CONFIG.roleTemplateAccess.readOnly;
                    return (
                        <React.Fragment>
                            {!deleteView &&
                                <>
                                    <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                        < button
                                            onClick={() => {
                                                this.props.handleOnClickAction({
                                                    component: CONFIG.editComponentRoute.ezDemos,
                                                    tabTitle: record?.title,
                                                    recordId: record?.id
                                                }
                                                )
                                            }}
                                            disabled={isEditDisabled}
                                            className={!isEditDisabled ? "link" : "link-disabled"}>
                                            <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                    {/* </Link> */}
                                    {" "}
                                    <Divider type="vertical" />
                                </>
                            }
                            < Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id} >
                                <>
                                    <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleDelete(record.id, index + 1)}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                                    {deleteView && <>
                                        <Divider type="vertical" />
                                        <Popconfirm disabled={isEditDisabled} title="Confirm restore?" onConfirm={() => this.handleRestore(record.id, index + 1)}>
                                            <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"}><span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span></button>
                                        </Popconfirm>
                                    </>
                                    }
                                </>
                            </Tooltip>
                            <Divider type="vertical" />
                            {!deleteView &&
                                <>
                                    <Tooltip title={(viewRatingDisabled) ? CONFIG.warningMessages.noRating : isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                        < button
                                            onClick={() => {
                                                this.props.handleOnClickAction({
                                                    component: CONFIG.cloneComponentRoute.ezDemosRating,
                                                    tabTitle: record?.title,
                                                    recordId: record?.id,
                                                }
                                                )
                                            }}
                                            disabled={(isEditDisabled || viewRatingDisabled)}
                                            className={!(isEditDisabled || viewRatingDisabled) ? "link" : "link-disabled"}>
                                            <NeoIcon aria-label="menu-aria" className={(isEditDisabled || viewRatingDisabled) ? "link-disabled" : "link"} disabled={(isEditDisabled || viewRatingDisabled)} title="View Rating" icon="star-half-filled" style={{ fontSize: "22px" }} />
                                        </button>
                                    </Tooltip>
                                </>
                            }
                        </React.Fragment >
                    );
                }
            }
        ];
        const formFields = [
            {
                label: "Title",
                type: CONFIG.formFieldTypes.INPUT,
                key: "title",
                value: this.filterSort?.filter?.title
            },
            {
                label: "Demo",
                type: CONFIG.formFieldTypes.SELECT,
                key: "demo",
                mode: "tags",
                valueKey: "text",
                data: allDemos?.map(demo => ({ text: demo.title })) || [],
                value: this.filterSort?.filter?.demo
            },
            {
                label: "Solutions",
                type: CONFIG.formFieldTypes.SELECT,
                key: "solutionType",
                mode: "tags",
                valueKey: "text",
                data: solutionTypes?.map(sol => ({ text: sol.name })) || [],
                value: this.filterSort?.filter?.solutionType
            }
        ]
        const { showFilter } = this.state;
        return (
            <React.Fragment>
                <Row>
                    <Col xl={3} sm={6} xs={24}>
                        <StatusFilter disabled={this.filterSort?.filter?.viewDeletedItems} onChange={this.handleSelectChange} value={this.state.status} />
                    </Col>
                    <Col xl={2} sm={6} xs={24} className="left-spacing">
                        <Button className="primary-action-button-filled" onClick={() => { this.props.handleOnClickAction({ component: CONFIG.createComponentRoute.ezDemo, tabTitle: "Create" }) }}>New
                            <Icon aria-label="aria-menu" type="plus-circle" />
                        </Button>
                    </Col>
                    {/* for export icon */}
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoIcon aria-label="menu-aria" title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </Dropdown>
                    </Col>
                    {/* for refresh icon */}
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                    {/* for filter icon */}
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                </Row>
                <div className="content-container responsive-container">
                    <Row>
                        {this.state.showFilter &&
                            <FilterPanel
                                label='View Deleted EZ Demos'
                                panelHeader="Filters and Export"
                                formFields={formFields}
                                handleClear={this.handleClear}
                                handleFilter={this.handleFilter}
                                timeZone={timeZoneName}
                                viewDeletedItemsValue={this.filterSort?.filter?.viewDeletedItems}
                            />}
                    </Row>
                    {(this.state.status === true) &&
                        <Row>
                            <div ref={(this.status === undefined || this.status === true) ? this.tableRef : undefined}>
                                <h3>{activeTitle}</h3>
                                {isUserSuperAdmin && this.renderUpdateButton("top")}
                                <DragListView {...this.dragProps}>
                                    <Table
                                        size='middle'
                                        rowKey={(record) => record.id}
                                        bordered
                                        dataSource={activeEZDemos}
                                        columns={(isUserSuperAdmin && isDragDisabled === false) ? [...adminColumns, ...columns] : columns}
                                        loading={loading}
                                        pagination={false}
                                        // scroll={{ y: 550, x: "auto" | 1000 }}
                                        onChange={this.handleSortChange}
                                    />
                                </DragListView>
                                {isUserSuperAdmin && this.renderUpdateButton("bottom")}
                                <br />
                                <br />
                            </div>
                        </Row>
                    }
                    {(this.state.status === false || this.state.status === undefined) && <Row>
                        <h3>{inactiveTitle}</h3>
                        <div ref={this.tableRef}>
                            <Table
                                size='middle'
                                // className="responsive-container"
                                rowClassName={(record, index) => (`scroll-row-${index + 1}`)}
                                scrollToFirstRowOnChange={true}
                                rowKey={(record) => record.id}
                                bordered
                                scroll={{ y: 550, x: "auto" | 1200 }}
                                dataSource={ezDemos || []}
                                columns={columns}
                                loading={loading}
                                onChange={this.handleSortChange}
                                pagination={false}
                            // footer={() => <>{ezDemos?.length !== 0 && <div style={{ textAlign: 'center' }}>
                            //     <Text strong>Fetched {ezDemos?.length} out of {ezDemosCount} EZ Demos</Text>
                            // </div>}
                            // </>}
                            />
                        </div>
                    </Row>}
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ user, manageEZDemos, demos, solutionTypes, tabsLayout }) => {
    return {
        userProfile: user.profile,
        timeZoneName: user.profile.timezone,
        ezDemos: manageEZDemos.data,
        activeEZDemos: manageEZDemos.activeEZDemos,
        allDemos: demos.allDemos,
        solutionTypes: solutionTypes.types,
        currentFilter: manageEZDemos.currentFilter,
        currentStatus: manageEZDemos.status,
        isEdit: manageEZDemos.isEdit,
        loading: manageEZDemos.loading,
        ezDemoIndex: manageEZDemos.ezDemoIndex,
        response: manageEZDemos.response,
        openedTabs: tabsLayout.panes,
        isReordered: manageEZDemos.isReordered
    };
};

export default connect(
    mapStateToProps,
    {
        getAllEZDemos,
        fetchDemos,
        toggleIsFetchingEZDemos,
        deleteEZDemoManage,
        clearEZDemos,
        toggleBackButton,
        selectTitle,
        updateEZDemosCurrentPage,
        restoreEZDemo,
        clearEZDemosTableData,
        currentEZDemoFilter,
        updateActiveTab,
        handleOnClickAction,
        fetchSolutionTypes,
        fetchAllEZDemosForExport,
        getAllActiveEZDemos,
        putEZDemosOrder,
        reorderEZDemos
    }
)(EZDemosTable);

import React from "react";
import { connect, batch } from 'react-redux';
import { Row, Col, Descriptions, Divider, Form, Typography, Input, Upload, Tooltip, Alert, Modal, Icon, Tabs, Checkbox, Select } from 'antd';
import { Image, message } from "antd5"
import CONFIG from "../config"
import TimeZone from './common/TimeZone';
import pushMessage from "./common/PushMessage";
import moment from 'moment-timezone'
import { getUserFromCookies } from '../utils/cookies';
import profile_pic_default from "../assets/images/profile_pic_default.png";
import FormActionButtons from './common/FormActionButtons';
import { Icon as NeoIcon, Button as NeoButton } from "@avaya/neo-react";
import { fetchEmailsBySearchString } from '../actions/reports';
import {
    selectTitle,
    toggleBackButton,
    updateUserTimeZone,
    putUserTimeZone,
    postUserDetails,
    resetLDAPPassword,
    putUserEmailSubscription,
    updateUserEmailSubscription,
    putUserOptOutCalendarInviteSubscription,
    changeLocalUserPassword,
    getDecryptedLdapPassword,
    updateTabLimit,
    updateProfilePicture,
    deleteProfilePicture,
    updateTestingEmails,
    putTestingMode
} from '../actions';

const { Paragraph, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const formItemLayout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 10, offset: 1 }
}

const MyProfile = Form.create({ name: 'my_profile' })(
    class MyProfile extends React.Component {

        constructor(props) {
            super(props);

            this.state = {
                isDisabled: true,
                confirmDirty: false,
                autoCompleteResult: [],
                matchedUserEmails: [],
                visible: false,
                encryptedPassword: "***********",
                showPassword: false,
                isEditDeveloperList: false
            };
        }



        user = getUserFromCookies();


        getProfile = () => {
            const { userDetails, isFirstTimeLogin } = this.props;
            const user = this.user;

            const name = (userDetails && userDetails.firstName && userDetails.lastName)
                ? `${userDetails.firstName} ${userDetails.lastName}`
                : (user && user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : '');
            const country = (userDetails && userDetails.country)
                ? userDetails.country
                : (user && user.country ? user.country : '-');
            const region = (userDetails && userDetails.region) ? userDetails.region.name : '-';
            const contactNumber = (userDetails && userDetails.contactNumber)
                ? userDetails.contactNumber
                : (user && user.contactNumber ? user.contactNumber : '-');
            const email = (userDetails && userDetails.email)
                ? userDetails.email
                : (user && user.email ? user.email : '-');
            const timezone = userDetails ? userDetails.timezone : undefined;
            const passwordModifiedTime = userDetails ? userDetails.passwordModifiedTime : undefined;
            const company = (userDetails && userDetails.companyName)
                ? userDetails.companyName
                : (user && user.companyName ? user.companyName : '-');
            const emailNotificationSubscription = (userDetails && userDetails.emailNotificationSubscription !== undefined) ? userDetails.emailNotificationSubscription : (isFirstTimeLogin ? true : undefined);
            const optOutInviteSubs = (userDetails && userDetails.optOutInviteSubs !== undefined) ? userDetails.optOutInviteSubs : (isFirstTimeLogin ? false : true);
            return { name, country, region, contactNumber, email, timezone, company, emailNotificationSubscription, passwordModifiedTime, optOutInviteSubs };

        }

        componentDidMount() {
            const { userDetails, isFirstTimeLogin } = this.props
            const isLocalUser = userDetails !== undefined ? userDetails.backendUser : false;
            const firstTimeLocalUser = isLocalUser && isFirstTimeLogin;
            batch(() => {
                this.setState({ defaultTab: firstTimeLocalUser ? "3" : "1" })
            });
        }

        handleChange = (timezone) => {
            const { isFirstTimeLogin } = this.props;
            this.setState({ isDisabled: timezone ? false : true })
            if (!isFirstTimeLogin) this.props.putUserTimeZone(timezone, this.props.userDetails.id)
        }

        handleTabLimitSelect = (userType, limit) => {
            if (!this.props.isFirstTimeLogin) this.props.updateTabLimit(userType, limit)
        }

        handleNext = () => {
            const { defaultTab } = this.state
            defaultTab === "1" ? this.setState({ defaultTab: "2" }) : this.setState({ defaultTab: "3" })
        }

        handleSubmit = () => {
            const { isFirstTimeLogin, form, userDetails } = this.props
            const isLocalUser = userDetails !== undefined ? userDetails.backendUser : false;
            const timezone = form.getFieldValue('timezone')
            const emailNotificationSubscription = form.getFieldValue('emailNotificationSubscription')
            const { email } = this.getProfile();

            if (isFirstTimeLogin && !isLocalUser)
                this.props.postUserDetails(timezone, emailNotificationSubscription)
            else {
                form.validateFields((err, values) => {
                    if (err) return;
                    this.props.changeLocalUserPassword(values, email)
                })
            }
        }

        handleEmailSubscription = (e, isFirstTimeLogin, isLocalUser) => {
            (isFirstTimeLogin && !isLocalUser) ? this.props.form.setFieldsValue({ emailNotificationSubscription: e.target.checked }) : this.props.putUserEmailSubscription(e.target.checked)
        }

        handleOptOutCalendarInviteSubscription = (e, isFirstTimeLogin, isLocalUser) => {
            (isFirstTimeLogin && !isLocalUser) ? this.props.form.setFieldsValue({ optOutInviteSubs: !e.target.checked }) : this.props.putUserOptOutCalendarInviteSubscription(!e.target.checked)
        }

        renderSubmitButton = () => {
            const { isFirstTimeLogin, userDetails } = this.props;
            const isLocalUser = userDetails !== undefined ? userDetails.backendUser : false;
            const firstTimeLocalUser = isLocalUser && isFirstTimeLogin;

            if (firstTimeLocalUser && (this.state.defaultTab === "1" || this.state.defaultTab === "2")) return <Row className="right-align"><NeoButton onClick={this.handleNext} id="my-profile-next-button" className="left-spacing primary-action-button-filled"> Next </NeoButton></Row>
            if (firstTimeLocalUser) return <Row className="center-align-content"><NeoButton onClick={this.handleSubmit} className="left-spacing primary-action-button-filled vertical-spacing-2" id="my-profile-submit-button"> Submit </NeoButton></Row>
            else if (isFirstTimeLogin) return <Row className="right-align"><NeoButton disabled={this.state.isDisabled} onClick={this.handleSubmit} className="left-spacing primary-action-button-filled" id="my-profile-save-button"> Save </NeoButton></Row>
        }

        renderWarningMessage = () => {
            return <Alert message={CONFIG.myProfile.firstTimeLoginLocalUser} type="warning" showIcon />;
        }

        handleConfirmBlur = e => {
            const { value } = e.target;
            this.setState({ confirmDirty: this.state.confirmDirty || !!value });
        };

        compareToFirstPassword = (rule, value, callback) => {
            if (value && value !== this.props.form.getFieldValue('newPassword')) {
                callback(CONFIG.myProfile.passwordMismatch);
            } else {
                callback();
            }
        };

        validateToNextPassword = (rule, value, callback) => {
            const { userDetails } = this.props;
            if (value && (value.toLowerCase().includes(userDetails.firstName.toLowerCase()) || value.toLowerCase().includes(userDetails.lastName.toLowerCase()) || value.toLowerCase().includes(userDetails.email.toLowerCase()) || value.toLowerCase().includes(userDetails.sslHandle.toLowerCase()))) {
                callback('Password cannot contain your Name/E-mail/UserName')
            }
            else if (value && value === this.props.form.getFieldValue('currentPassword')) {
                callback('New password cannot be the same as the old password');
            }
            else if (value && !CONFIG.regEx.passwordRequirementPattern.test(value)) {
                callback('Does not meet Password Requirement');
            }
            else if (value && this.state.confirmDirty) {
                this.props.form.validateFields(['confirmPassword'], { force: true });
            }
            callback();
        };

        handleCancel = () => {
            this.props.form.resetFields()
            this.setState({ visible: false });
        };

        handlePasswordChangeSubmit = () => {
            const { form } = this.props;
            const { email } = this.getProfile();
            form.validateFields((err, values) => {
                if (err) return;
                this.props.changeLocalUserPassword(values, email)
                if (this.props.hasPasswordChanged) {
                    form.resetFields()
                    this.setState({ visible: false })
                }
            });
        };


        renderPasswordChangeModal = () => {
            this.props.closeMyProfile();
            this.setState({ visible: true });
        }

        handleResetPassword = () => { this.props.resetLDAPPassword(); }

        toggleViewLDAPPassword = () => {
            this.props.getDecryptedLdapPassword()
                .then(() =>
                    this.setState(prevState => ({ showPassword: !prevState.showPassword }),
                        () => this.setState({ encryptedPassword: this.state.showPassword ? this.props.decryptedLdapPassword : "***********" })));
        }

        getLimitDropdown = (n) => {
            var elements = [];
            for (let i = 5; i <= n; i++) { elements.push(<Option key={i}>{i}</Option>) }
            return elements;
        }

        handleTabClick = (e) => {
            if (e === "5") {
                this.props.closeMyProfile();
                this.props.handleLogout();
            }
        }

        beforeUpload = (file) => {
            const isImage = file.type.startsWith('image/');
            if (!isImage) {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "You can only upload image files")
            }
            return isImage;
        }

        getRoleTemplateNamesString = (roleTemplates, delimiter = ' ', defaultString = '') => {
            return roleTemplates?.map(roleTemplate => roleTemplate?.title)?.join(delimiter) || defaultString;
        }

        fetchUserEmails = searchString => {
            fetchEmailsBySearchString({ searchString })
                .then((result) => { result.length !== 0 ? this.setState({ matchedUserEmails: [...new Set(result.map(item => item.email))] }) : this.setState({ matchedUserEmails: [] }) });
        };

        handleSaveDeveloperList = () => {
            this.props.updateTestingEmails(this.props.form.getFieldValue('testingRecipients'));
            this.setState({ isEditDeveloperList: false })
        }

        handleCancelDeveloperList = () => {
            this.props.form.resetFields(["testingRecipients"]);
            this.setState({ isEditDeveloperList: false })
        }

        handleTestModeToggle = async (flag) => {

            const hasChanged = await this.props.putTestingMode(flag)
            hasChanged && setTimeout(function () {
                window.location.reload();
            }, 3000);
        }

        renderUserEmails = () => {
            if (this.state.matchedUserEmails) {
                return this.state.matchedUserEmails?.map(email => {
                    return (
                        <Option key={email} value={email}>
                            {email}
                        </Option>
                    );
                });
            }
            return;
        }

        renderUserDetails = () => {
            const { form, userDetails, isFirstTimeLogin } = this.props;
            const { name, country, region, contactNumber, email, timezone, company, emailNotificationSubscription, optOutInviteSubs, passwordModifiedTime } = this.getProfile();
            const { getFieldDecorator } = this.props.form;

            const isLocalUser = userDetails !== undefined ? userDetails.backendUser : false;
            const { hasLdapPassword, sslHandle } = userDetails || {};
            const firstTimeLocalUser = isLocalUser && isFirstTimeLogin;

            return (
                <div>
                    <Tabs
                        className="my-profile-tabs font"
                        onTabClick={(e) => this.handleTabClick(e)}
                        activeKey={this.state.defaultTab}
                        tabPosition='left'
                        onChange={(e) => { this.setState({ defaultTab: e }) }} >
                        <Col className="text-color-primary bold" style={{ fontSize: "25px" }}>{name}</Col>
                        {userDetails?.isDeveloper && <Row className="text-color-primary" style={{ fontSize: "15px" }}>{
                            this.getRoleTemplateNamesString(userDetails?.roleTemplates, ', ')}</Row>}
                        <Divider />
                        <TabPane tab="Account" id="my-profile-account-tab" key="1">
                            <Row className="my-profile-account">
                                <Col span={15}>
                                    <Form>
                                        <div className="user-details-row font" >
                                            <Row><Col span={7} className="bold supportLinks">Name</Col><Col span={17}>{name}</Col></Row>
                                            <Row><Col span={7} className="bold supportLinks">{isLocalUser ? "Username" : "SSL Handle"}</Col><Col span={17}>{sslHandle}</Col></Row>
                                            <Row><Col span={7} className="bold supportLinks">Email Address</Col><Col span={17}>{email}</Col></Row>
                                            <Row><Col span={7} className="bold supportLinks">Company</Col><Col span={17}>{company}</Col></Row>
                                            <Row><Col span={7} className="bold supportLinks">Phone Number</Col><Col span={17}>{contactNumber}</Col></Row>
                                            <Row><Col span={7} className="bold supportLinks">Country</Col><Col span={17}>{country}</Col></Row>
                                            <Row><Col span={7} className="bold supportLinks">Region</Col><Col span={17}>{region}</Col></Row>
                                            <Row><Col span={7} className="bold supportLinks">Time Zone</Col><Col span={17}>{(isFirstTimeLogin && isLocalUser) ? timezone : <TimeZone id="my-profile-timezone-select" className="timezone-profile-page" form={form} handleChange={this.handleChange} defaultValue={timezone} />}</Col></Row>
                                        </div>
                                    </Form>
                                </Col>
                                {!isFirstTimeLogin && <Col className="center-align font" span={9}>
                                    <Image
                                        className="profile-pic vertical-spacing-2"
                                        width={150}
                                        preview={false}
                                        height={150}
                                        src={this.props.userProfilePicture || profile_pic_default}
                                    />
                                    <div className="vertical-spacing-2">
                                        <Upload
                                            beforeUpload={this.beforeUpload}
                                            action={(file) => this.props.updateProfilePicture(file)}
                                            showUploadList={false}
                                        >
                                            <div className="profile-pic-div vertical-spacing-2"><NeoButton size="sm" id="my-profile-upload-photo-button" className="link">{this.props.userProfilePicture ? "Change Photo" : "Upload Photo"}</NeoButton>
                                                {this.props.userProfilePicture &&
                                                    <>
                                                        <Divider type="vertical" />
                                                        <NeoIcon aria-label="menu-aria" className="pointer" onClick={(event) => {
                                                            this.props.deleteProfilePicture();
                                                            event.stopPropagation();
                                                        }

                                                        } style={{ 'color': "red", 'fontSize': "20px" }} id="my-profile-delete-profile-picture" icon="trash"></NeoIcon>
                                                    </>}
                                            </div>
                                        </Upload>
                                    </div>
                                </Col>}
                            </Row>
                        </TabPane>
                        {<TabPane tab="Email Settings" id="my-profile-settings-tab" key="2">
                            <span style={{ "font-weight": "600", "color": "black" }}>Email Communications</span>
                            <Descriptions className="user-details">
                                <Descriptions.Item
                                    span={4}>
                                    <Form.Item className={(isFirstTimeLogin && isLocalUser) ? "bottom-spacing" : undefined}>
                                        {getFieldDecorator("emailNotificationSubscription", { valuePropName: "checked", initialValue: emailNotificationSubscription })(
                                            <Checkbox
                                                onClick={(e) => this.handleEmailSubscription(e, isFirstTimeLogin, isLocalUser)}
                                                id="my-profile-email-updates-checkbox"
                                            >
                                                <span>Receive emails about Demo Updates, News, Notifications and Schedule Maintenances <Tooltip title={CONFIG.myProfile.unsubscribePortalEmailsIf} placement="topLeft" overlayStyle={{ minWidth: "500px", minHeight: "30px" }}><Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    span={4}>
                                    <Form.Item className={(isFirstTimeLogin && isLocalUser) ? "bottom-spacing" : undefined}>
                                        {getFieldDecorator("optOutInviteSubs", { valuePropName: "checked", initialValue: !optOutInviteSubs })(
                                            <Checkbox
                                                onClick={(e) => this.handleOptOutCalendarInviteSubscription(e, isFirstTimeLogin, isLocalUser)}
                                                id="my-profile-calendar-updates-checkbox"
                                            >
                                                <span>Receive calendar invites and reminders.<Text type="danger"></Text><Tooltip title={CONFIG.myProfile.calendarInviteOptOutIf} placement="topLeft" overlayStyle={{ minWidth: "500px", minHeight: "30px" }}><Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                </Descriptions.Item>
                            </Descriptions>

                            {/* {isSuperAdmin && <>
                                <Divider /><span style={{ "font-weight": "600", "color": "black" }}>Maximum Tabs allowed</span>
                                <Form className="tab-limit-setting-form font" layout="inline">
                                    <Form.Item label="Super Admin">
                                        {getFieldDecorator("superAdminTabLimit", {
                                            initialValue: superAdminTabLimit
                                        })(
                                            <Select
                                                onSelect={(e) => this.handleTabLimitSelect(CONFIG.roleTypes.superAdmin, e)}
                                                className="tab-limit-select"
                                                size="small"
                                                dropdownMatchSelectWidth
                                            >
                                                {this.getLimitDropdown(20)}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item className="review-Rating" label="Admin">
                                        {getFieldDecorator("adminTabLimit", {
                                            initialValue: adminTabLimit
                                        })(
                                            <Select
                                                onSelect={(e) => this.handleTabLimitSelect(CONFIG.roleTypes.admin, e)}
                                                className="tab-limit-select"
                                                size="small"
                                                dropdownMatchSelectWidth>
                                                {this.getLimitDropdown(20)}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item className="review-Rating" label="Users">
                                        {getFieldDecorator("userTabLimit", {
                                            initialValue: userTabLimit
                                        })(
                                            <Select
                                                onSelect={(e) => this.handleTabLimitSelect(CONFIG.roleTypes.user + "," + CONFIG.roleTypes.default, e)}
                                                className="tab-limit-select"
                                                size="small"
                                                dropdownMatchSelectWidth>
                                                {this.getLimitDropdown(20)}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Form>
                            </>} */}

                        </TabPane>}
                        {isLocalUser && <TabPane id="my-profile-credentials-tab" tab="Credentials" key="3">
                            <Descriptions className="user-details">
                                {!isFirstTimeLogin && isLocalUser && <Descriptions.Item span={4}><span className={isFirstTimeLogin ? "link-disabled" : "link"} onClick={this.renderPasswordChangeModal} id="my-profile-change-password" >Change Login Password</span></Descriptions.Item>}
                                {!isFirstTimeLogin && isLocalUser && < Descriptions.Item span={4} label="Login Password Last changed">{passwordModifiedTime ? moment.tz(passwordModifiedTime, this.props.timeZoneName).format(CONFIG.dateFormats.user) : "Never"}</Descriptions.Item>}
                                {userDetails?.backendUser && hasLdapPassword && <Descriptions.Item label="Demo Username" span={4} ><Paragraph className="demo-credential" copyable={{ text: `${sslHandle}` }}> {sslHandle}</Paragraph></Descriptions.Item>}
                                {hasLdapPassword && <Descriptions.Item label="Demo Password" span={4}>
                                    <Paragraph className="demo-credential" copyable={(this.state.showPassword && this.props.decryptedLdapPassword !== undefined) ? { text: `${this.state.encryptedPassword}` } : false}>
                                        <span className="display-password text-color demo-credential">{this.state.encryptedPassword}</span>&emsp;
                                        <Icon aria-label="aria-menu" type={!this.state.showPassword ? "eye-invisible" : "eye"} theme="filled" onClick={() => this.toggleViewLDAPPassword()} />&emsp;
                                        <span className="link" onClick={() => this.handleResetPassword()}>Reset</span>
                                    </Paragraph>
                                </Descriptions.Item>
                                }
                            </Descriptions>
                            {
                                firstTimeLocalUser &&
                                <Form layout="horizontal" className="reset-default-password-form">
                                    <Row><Col xl={12} xs={24}><Form.Item {...formItemLayout} label={<label className="reset-default-password-form">Current Password</label>} >
                                        {getFieldDecorator("currentPassword", {
                                            rules: [{ required: true, message: "Cannot be blank" }]
                                        })(<Input.Password />)}
                                    </Form.Item>
                                    </Col>
                                    </Row>
                                    <Row><Col xl={12} xs={24}><Form.Item {...formItemLayout}
                                        label={<span><label className="reset-default-password-form">New Password </label><Tooltip title={CONFIG.myProfile.passwordRequirements} placement="rightTop"><Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>} hasFeedback>
                                        {getFieldDecorator("newPassword", {
                                            rules: [
                                                { required: true, message: "Cannot be blank" },
                                                { validator: this.validateToNextPassword, },
                                            ]
                                        })(<Input.Password />)}
                                    </Form.Item>
                                    </Col></Row>

                                    <Row><Col xl={12} xs={24}><Form.Item {...formItemLayout} label={<label className="reset-default-password-form">Confirm Password</label>} hasFeedback>
                                        {getFieldDecorator("confirmPassword", {
                                            rules: [
                                                { required: true, message: "Cannot be blank" },
                                                { validator: this.compareToFirstPassword, },
                                            ]
                                        })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                                    </Form.Item>
                                    </Col></Row>
                                </Form>
                            }

                        </TabPane>}
                        {/* {userDetails?.isDeveloper && <TabPane id="my-profile-developer-tab" tab="Developer Options" key="4">
                            <Alert
                                className="vertical-spacing-2"
                                message="Warning"
                                description={CONFIG.warningMessages.developerOptions}
                                type="warning"
                                showIcon
                            />
                            <Descriptions className="user-details">
                                {!isFirstTimeLogin && isLocalUser && < Descriptions.Item
                                    span={4}
                                    label="Testing Mode">
                                    <Switch
                                        className="small-switch"
                                        defaultChecked={this.props.isTestingMode}
                                        onChange={(e) => this.handleTestModeToggle(e)}
                                    >
                                    </Switch>
                                </Descriptions.Item>}
                            </Descriptions>

                            {!isFirstTimeLogin && isLocalUser &&
                                <div className="testing-recipients">
                                    <Form.Item className="testing-recipients" label={<span className="bold">Test Email Recipients</span>}>
                                        {getFieldDecorator("testingRecipients", {
                                            initialValue: this.props.testingRecipients || []
                                        })(
                                            <Select
                                                disabled={!this.state.isEditDeveloperList}
                                                mode="tags"
                                                style={{
                                                    width: '350px',
                                                }}
                                                onSearch={this.fetchUserEmails}
                                            >
                                                {this.renderUserEmails()}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    {this.state.isEditDeveloperList && <NeoButton onClick={this.handleSaveDeveloperList} className="left-spacing-3" status="default" size="compact">Save</NeoButton>}
                                    {this.state.isEditDeveloperList && <NeoButton onClick={this.handleCancelDeveloperList} className="left-spacing-3" variant="secondary" size="compact">Cancel</NeoButton>}
                                    {!this.state.isEditDeveloperList && <NeoButton onClick={() => this.setState({ isEditDeveloperList: true })} className="left-spacing-3" status="default" size="compact">Edit</NeoButton>}
                                </div>}

                        </TabPane>} */}
                        {!isFirstTimeLogin && <TabPane id="my-profile-logout-tab" tab="Logout" key="5">
                        </TabPane>}
                    </Tabs>

                </div >
            );
        }

        renderFooterDetails = () => {
            const date = new Date();
            const year = date.getFullYear();
            return (
                <div className="footer-class font" style={{ margin: "0.4%" }}>
                    <>
                        <div
                        >
                            {/* eslint-disable-next-line */}
                            {/* <span className="footer-statement"><a class="optanon-show-settings" href="javascript:void(0);" target="_self" title="Cookie Statement" aria-label="Cookie Statement" tabIndex="1">Cookie Statement</a></span> */}
                            {/* eslint-disable-next-line */}
                            <span className="footer-statement"><a href="https://www.avaya.com/en/privacy/website/" rel="noopener noreferrer" target="_blank" className="link">Privacy Statement</a></span>
                            <span className="footer-statement">&copy; {year} Avaya, Inc.</span>
                            <span className="footer-statement">{CONFIG.productVersion}</span>
                        </div>
                        <div
                        // style={{ marginBottom: "-4px" }}
                        >
                        </div>
                    </>
                </div>
            )
        }

        render() {
            const { getFieldDecorator } = this.props.form;
            const okText = "Submit"
            return (
                <React.Fragment>

                    {this.renderUserDetails()}
                    {this.renderSubmitButton()}
                    {this.renderFooterDetails()}
                    <Modal
                        visible={this.state.visible}
                        title="Change Password"
                        okText="Submit"
                        okButtonProps={{ id: "my-profile-pswd-submit-button" }}
                        cancelButtonProps={{ id: "my-profile-pswd-cancel-button" }}
                        onCancel={this.handleCancel}
                        footer={<FormActionButtons okText={okText} handleSubmit={this.handlePasswordChangeSubmit} handleCancel={this.handleCancel} />}
                    >
                        <Form layout="vertical" >
                            <Form.Item label="Current Password" className="bottom-spacing">
                                {getFieldDecorator('currentPassword', {
                                    rules: [
                                        { required: true, message: 'Cannot be blank' },]
                                })(<Input.Password />)}
                            </Form.Item>
                            <Form.Item className="bottom-spacing" hasFeedback
                                label={<span>New Password <Tooltip title={CONFIG.myProfile.passwordRequirements} placement="rightTop"><Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                                {getFieldDecorator('newPassword', {
                                    rules: [
                                        { required: true, message: 'Cannot be blank' },
                                        { validator: this.validateToNextPassword }],
                                })(<Input.Password />)}
                            </Form.Item>
                            <Form.Item label="Confirm Password" className="bottom-spacing" hasFeedback>
                                {getFieldDecorator('confirmPassword', {
                                    rules: [
                                        { required: true, message: 'Cannot be blank' },
                                        { validator: this.compareToFirstPassword }],
                                })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                            </Form.Item>
                        </Form>
                    </Modal>
                </React.Fragment >
            );
        }
    }
);

const mapStateToProps = ({ user, viewport }) => {
    return {
        userDetails: user.profile,
        isDeveloper: user.isDeveloper,
        superAdminTabLimit: user.superAdminTabLimit,
        adminTabLimit: user.adminTabLimit,
        userTabLimit: user.userTabLimit,
        userProfilePicture: user.userProfilePicture,
        viewport,
        testingRecipients: user.testingRecipients,
        isTestingMode: user.isTestingMode,
        isFirstTimeLogin: user.isFirstTimeLogin,
        hasPasswordChanged: user.hasPasswordChanged,
        decryptedLdapPassword: user.decryptedLdapPassword,
        userRoleTemplate: user.roleTemplates
    }
}

export default connect(
    mapStateToProps,
    {
        selectTitle,
        toggleBackButton,
        updateUserTimeZone,
        putUserTimeZone,
        postUserDetails,
        resetLDAPPassword,
        putUserEmailSubscription,
        updateUserEmailSubscription,
        putUserOptOutCalendarInviteSubscription,
        changeLocalUserPassword,
        getDecryptedLdapPassword,
        updateProfilePicture,
        deleteProfilePicture,
        updateTestingEmails,
        updateTabLimit,
        putTestingMode
    }
)(MyProfile);

import React from 'react';
import moment from 'moment-timezone';
import CONFIG from '../../../config';
import { Table, Icon } from "@avaya/neo-react"

class ScheduleOccurenceConfirmation extends React.PureComponent {
    render() {
        const neoColumns = [
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.resource?.name}</span>
                },
                Header: "Resource",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{moment.tz(a?.cell?.row?.original?.startTime, this.props.timezone).format(CONFIG.dateFormats.userDateTime)}</span>
                },
                Header: "Start Time",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{moment.tz(a?.cell?.row?.original?.endTime, this.props.timezone).format(CONFIG.dateFormats.userDateTime)}</span>
                },
                Header: "End Time",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.demoPurpose?.purpose}</span>
                },
                Header: "Purpose",
                disableFilters: true,
                disableSortBy: true

            },
            {
                Header: "Participants",
                disableFilters: true,
                disableSortBy: true,
                Cell: (a) => {
                    const participants = a?.cell?.row?.original?.participants;
                    return <span style={{ textAlign: "center" }}>{participants ? [...participants.eapUsers, ...participants.extUsers].reduce((acc, item) => { acc = acc !== "" ? acc + ", " + item.email : acc + item.email; return acc }, "") : "-"}</span>
                },
            },
            {
                Header: "  ",
                disableFilters: true,
                disableSortBy: true,
                Cell: (a) => {
                    const record = a?.cell?.row?.original;
                    return <span onClick={() => {
                        this.props.handleActivePanelChange(record?.uniqueId, true)
                    }} style={{ textAlign: "center", cursor: "pointer", color: '#1B77AF' }}><Icon aria-label="aria-menu" icon="edit" /></span>
                },
            },
        ];
        const data = this.props.possibleSchedules?.length > 0 ? this.props.possibleSchedules.map((item, index) => { return { ...item, id: index } }) : []
        return (
            <div style={{ marginTop: "16px" }}>
                <Table
                    columns={neoColumns}
                    data={data}
                    itemsPerPageOptions={[10, 20]}
                />
            </div >
        )
    }
}

export default ScheduleOccurenceConfirmation;
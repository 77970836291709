import React from "react";
import { connect } from "react-redux";
import { Input, Row, Col, Switch, Form, Button, InputNumber, Icon, Tooltip, Select } from "antd";

import DemoRoleTemplates from "./DemoRoleTemplates";
import { saveDemoRoleTemplates, cancelDemoRoleTemplates } from "../../actions";
import { checkDemoDependencies } from "../../actions/demos"
import CONFIG from '../../config';
import openInfo from '../common/Info';
const { Option } = Select;
const { TextArea } = Input;
class DemoBasicDetails extends React.Component {
  state = {
    visible: false,
  };


  renderDepedencyList = (dependencyList) => {
    const { maintenanceCount } = dependencyList;

    const showDependencyList = maintenanceCount !== 0;
    const modalTitle = CONFIG.dependencyModalTitle
    const content = showDependencyList &&
      <>
        <h4>Inactivation of selected demo would affect</h4>
        {maintenanceCount} * Future Maintenance Windows<br />
      </>

    if (showDependencyList) openInfo(modalTitle, content, null, null, true);
  }

  handleStatusChange = (flag) => {
    const { isEditMode, demoId, isActive } = this.props;
    if (flag) this.props.form.setFieldsValue({ isExpired: false })
    if (isEditMode && !flag && isActive) {
      checkDemoDependencies(demoId)
        .then(dependencyList => this.renderDepedencyList(dependencyList))
    }
  }

  handleExpiredChange = (flag) => {
    const { isEditMode, demoId, isActive } = this.props;
    if (flag) this.props.form.setFieldsValue({ isActive: false })
    if (isEditMode && flag && isActive) {
      checkDemoDependencies(demoId)
        .then(dependencyList => this.renderDepedencyList(dependencyList))
    }
  }

  handleDemoTypeChange = (value) => {
    if (value === CONFIG.demoTypes.standardDemo) this.props.form.setFieldsValue({ vdiRequired: true })
    else this.props.form.setFieldsValue({ vdiRequired: false })
    this.props.handleDemoTypeChange(value);
    this.setState({ demoType: value })
  }


  handlePreReqToggleChange = (value) => {
    this.props.handlePreRegistrationToggle(value)
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleCancel = e => {
    this.props.cancelDemoRoleTemplates();
    this.setState({
      visible: false
    });
  };

  handleAssign = () => {
    this.props.saveDemoRoleTemplates();
    this.setState({
      visible: false
    });
  }

  handleInputNumberChange = (e, key) => {
    const { form } = this.props;
    let number = e.target.value;
    number = number - (number % CONFIG.bufferTime.step);
    form.setFieldsValue({ [key]: number });
  }

  renderAssignButton = () => {
    return <Button aria-label="Click" id="demo-roletemplate-assign" className="primary-action-button-bordered right-border-radius" onClick={this.showModal}>Assign</Button>;
  }

  getAssignedRoleTemplates = (roleTemplates, userRoleTemplate) => {
    let assignedRoleTemplates = [];
    const isUserSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin
    if (roleTemplates) {
      roleTemplates.forEach(obj => {
        const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
        const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
        if (isUserSuperAdmin ? userAccessCondition || adminAccessCondition : userAccessCondition) {
          assignedRoleTemplates.push(obj.roleTemplate.title)
        }
      })
    }
    return assignedRoleTemplates
  }


  componentDidMount() {
    this.setState({ demoType: this.props.demoType === undefined ? CONFIG.demoTypes.standardDemo : this.props.demoType })
  }

  renderNotes = (notes, demoType) => {
    const { getFieldDecorator } = this.props.form;
    return (
      <Col xl={demoType !== CONFIG.demoTypes.standardDemo ? 18 : 24} lg={demoType !== CONFIG.demoTypes.standardDemo ? 18 : 24} sm={24} xs={24}>
        <Form.Item label="Notes">
          {getFieldDecorator("notes", {
            initialValue: notes ?? ""
          })(
            <TextArea rows={demoType !== CONFIG.demoTypes.standardDemo ? 1 : 2} />
          )}
        </Form.Item>
      </Col>
    )
  }

  render() {
    const { demoId, demoBasicDetails, userRoleTemplate, allRoleTemplates, isDemoClone, fromAPI, isEditMode } = this.props
    const { demoType } = this.state;
    const { title, roleTemplates, isActive, isExpired, startTimeBuffer, endTimeBuffer, vdiRequired, schTimeLimit, isPreRegistration, isOneTime, notes, reminderHours } = demoBasicDetails || [];
    const { getFieldDecorator } = this.props.form;
    let assignedRoleTemplates = this.getAssignedRoleTemplates(roleTemplates, userRoleTemplate)
    return (
      <Form>
        <Row gutter={48}>
          <Col xl={12} lg={12} sm={24} xs={24}>
            <Form.Item label="Title">
              {getFieldDecorator("title", {
                rules: [
                  {
                    required: true,
                    message: "Please input the title of the demo"
                  }
                ],
                initialValue: (isDemoClone && fromAPI) ? title + "_clone" : title ? title : ""
              })(<Input onChange={(e) => this.props.handleDemoTitleChange(e.target.value)} onBlur={this.handleChange} />)}
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} sm={24} xs={24}>
            <Form.Item label="Assign Role Template">
              {getFieldDecorator("roletemplate", {
                initialValue: assignedRoleTemplates.length !== 0
                  ? assignedRoleTemplates
                  : "None"
              })(<Input disabled addonAfter={this.renderAssignButton()} />)}
              <DemoRoleTemplates
                visible={this.state.visible}
                handleCancel={this.handleCancel}
                handleAssign={this.handleAssign}
                assignedRoleTemplates={roleTemplates}
                allRoleTemplates={allRoleTemplates}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xl={6} lg={8} sm={10} xs={24} >
            <Form.Item label="Demo Type">
              {getFieldDecorator("demoType", {
                initialValue: demoType || CONFIG.demoTypes.standardDemo
              })(
                <Select
                  placeholder="Select demo type"
                  onChange={this.handleDemoTypeChange}
                  disabled={!isDemoClone && (isEditMode || demoId)}
                >
                  {CONFIG.demoTypeOptions?.map((item, index) => (
                    <Option key={index} value={item.value} >{item.name}</Option>
                  ))}
                </Select>
              )
              }
            </Form.Item>
          </Col>
          {demoType !== CONFIG.demoTypes.standardDemo && this.renderNotes(notes, demoType)}
          {demoType === CONFIG.demoTypes.standardDemo &&
            <>
              <Col xl={4} lg={6} sm={24} xs={24}>
                <Form.Item label={<span>Start Buffer <Tooltip title={CONFIG.systemMessage.bufferTime + CONFIG.bufferTime.step}><Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                  {getFieldDecorator("startTimeBuffer", {
                    initialValue: startTimeBuffer ?? 0
                  })(
                    <InputNumber onBlur={(e) => this.handleInputNumberChange(e, "startTimeBuffer")} min={CONFIG.bufferTime.min} max={CONFIG.bufferTime.max} step={CONFIG.bufferTime.step} />
                  )}
                </Form.Item>
              </Col>
              <Col xl={4} lg={6} sm={24} xs={24}>
                <Form.Item label={<span>End Buffer <Tooltip title={CONFIG.systemMessage.bufferTime + CONFIG.bufferTime.step}><Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                  {getFieldDecorator("endTimeBuffer", {
                    initialValue: endTimeBuffer ?? 0
                  })(
                    <InputNumber onBlur={(e) => this.handleInputNumberChange(e, "endTimeBuffer")} min={CONFIG.bufferTime.min} max={CONFIG.bufferTime.max} step={CONFIG.bufferTime.step} />
                  )}
                </Form.Item>
              </Col>
              <Col xl={4} lg={6} sm={24} xs={24}>
                <Form.Item label={<span>Time limit <Tooltip title={CONFIG.systemMessage.resourceTimeLimitOverride}><Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                  {getFieldDecorator("schTimeLimit", {
                    initialValue: schTimeLimit ? (schTimeLimit / 60) : 24
                  })(
                    <InputNumber
                      min={CONFIG.schTimeLimit.min}
                      max={CONFIG.schTimeLimit.max}
                      step={CONFIG.schTimeLimit.step}
                      formatter={value => `${value} hour(s)`}
                      parser={value => value.replace(/[^0-9]/g, '')}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xl={4} lg={6} sm={24} xs={24}>
                <Form.Item label={<span>Reminder Hours</span>}>
                  {getFieldDecorator("reminderHours", {
                    initialValue: reminderHours || 2
                  })(
                    <InputNumber
                      min={CONFIG.schTimeLimit.min}
                      step={CONFIG.schTimeLimit.step}
                      formatter={value => `${value} hour(s)`}
                      parser={value => value.replace(/[^0-9]/g, '')}
                    />
                  )}
                </Form.Item>
              </Col>
            </>
          }
        </Row>
        <Row gutter={12}>
          <Col xl={6} lg={8} sm={24} xs={24} >
            <Form.Item label="Status">
              {getFieldDecorator("isActive", {
                valuePropName: "checked",
                initialValue: isDemoClone ? false : isActive
              })(
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  onClick={this.handleStatusChange}
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={6} lg={8} sm={24} xs={24} >
            <Form.Item label="Expired">
              {getFieldDecorator("isExpired", {
                valuePropName: "checked",
                initialValue: isExpired
              })(
                <Switch
                  className="expired"
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onClick={this.handleExpiredChange}
                />
              )}
            </Form.Item>
          </Col>

          <Col xl={6} lg={8} sm={24} xs={24}>
            <Form.Item label="Notify Users">
              {getFieldDecorator("notifyUsers", {
                valuePropName: "checked",
                initialValue: false
              })(
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={6} lg={8} sm={24} xs={24}>
            <Form.Item label="VDI Required">
              {getFieldDecorator("vdiRequired", {
                valuePropName: "checked",
                initialValue: !isEditMode ? false : vdiRequired
              })(
                <Switch
                  disabled={this.props.demoType !== CONFIG.demoTypes.standardDemo}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xl={6} lg={8} sm={24} xs={24}>
            <Form.Item label="Reset Ratings">
              {getFieldDecorator("isRatingReset", {
                valuePropName: "checked",
                initialValue: false
              })(
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={6} lg={8} sm={24} xs={24}>
            <Form.Item label="Reset Created Date">
              {getFieldDecorator("isProduction", {
                valuePropName: "checked",
                initialValue: false
              })(
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={6} lg={8} sm={24} xs={24}>
            <Form.Item label="Pre-registration">
              {getFieldDecorator("isPreRegistration", {
                valuePropName: "checked",
                initialValue: isPreRegistration
              })(
                <Switch
                  onChange={this.handlePreReqToggleChange}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
              )}
            </Form.Item>
          </Col>
          {this.props.form.getFieldValue('isPreRegistration') && <Col xl={6} lg={8} sm={24} xs={24}>
            <Form.Item label="One time registration">
              {getFieldDecorator("isOneTime", {
                valuePropName: "checked",
                initialValue: isOneTime
              })(
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
              )}
            </Form.Item>
          </Col>}
        </Row>
        {this.props.demoType !== CONFIG.demoTypes.standardDemo &&
          <Row gutter={12}>
            <Col xl={4} lg={6} sm={24} xs={24}>
              <Form.Item label={<span>Reminder Hours</span>}>
                {getFieldDecorator("reminderHours", {
                  initialValue: reminderHours || 2
                })(
                  <InputNumber
                    min={CONFIG.schTimeLimit.min}
                    step={CONFIG.schTimeLimit.step}
                    formatter={value => `${value} hour(s)`}
                    parser={value => value.replace(/[^0-9]/g, '')}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        }
        {demoType === CONFIG.demoTypes.standardDemo && this.renderNotes(notes, demoType)}
      </Form>
    );
  }
}

const mapStateToProps = ({ roleTemplates, viewport, user, openTabDemoDetails }) => {
  return {
    demoBasicDetails: openTabDemoDetails?.currentTabData?.demoBasicDetails,
    allRoleTemplates: roleTemplates.all,
    isMobileView: viewport.isMobileView,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
  }
}

const DemoBasicDetailsForm = Form.create()(DemoBasicDetails);

export default connect(
  mapStateToProps,
  { saveDemoRoleTemplates, cancelDemoRoleTemplates }
)(DemoBasicDetailsForm);

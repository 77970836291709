import React from 'react';
import { Spin, message, Dropdown, Tooltip } from 'antd';
import { connect, batch } from 'react-redux';
import { fetchEmailTemplatesLite, toggleBackButton, selectTitle, toggleIsFetchingEmailTemplateFlag, currentEmailtemplateFilter, handleOnClickAction } from '../../actions';
import FilterDrawer from '../common/FilterDrawer';
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config.js';
import { fetchNamesBySearchString } from '../../actions/userRole';
import { exportEmailTemplates } from "../../actions/emailTemplates";
import { exportMenu } from "../../utils/strings";
import { isTabAlreadyOpen } from "../common/TabLayout";
import { otherFields } from '../../utils/neoTableFields';
import throttle from 'lodash/throttle';
import { LoadingOutlined } from '@ant-design/icons';
import { Table, Icon as NeoIcon, Button } from "neo-latest";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const dataIndexMap = {
    title: "title",
    type: "type",
    description: "description",
    updatedBy: "lastUpdatedBy.fullName",
}

class ManageCustomEmailTemplates extends React.Component {

    constructor(props) {
        super(props);
        this.handleClear = throttle(this.handleClear, 1000);
    }

    state = {
        matchedUsersNames: [],
        emailTemplatesData: [],
        showFilter: false,
    }

    filterSort = {};

    componentDidMount() {
        const { currentFilter, isEdit, } = this.props;
        this.setState({ loading: true, currentPath: window.location.pathname })
        if (isEdit) {
            this.filterSort = currentFilter;
            if (Object.keys(JSON.parse(JSON.stringify(currentFilter)))?.length > 0) {
                const filterObject = JSON.parse(JSON.stringify(currentFilter?.filter));
                Object.keys(filterObject)?.length > 0 && this.setState({ showFilter: true })
            }
        }
        else {
            this.filterSort = {}
        }
        batch(() => {
            this.props.toggleIsFetchingEmailTemplateFlag(true);
            this.props.fetchEmailTemplatesLite({ type: 'CUSTOM', filterSort: this.filterSort })
        })
    }


    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentEmailtemplateFilter("custom", this.filterSort)
        }
    }

    handleFilter = async (filters) => {
        let { title, type, description, updatedBy } = filters;
        title = title?.trim();
        type = type?.trim();
        description = description?.trim();

        if (!title && !type && !description && !updatedBy) {
            pushMessage(CONFIG.messageType.warning, `Please select some fields to filter`)
            return;
        }
        this.filterSort = {
            ...this.filterSort,
            filter: {
                [dataIndexMap.title]: title || undefined,
                [dataIndexMap.type]: type || undefined,
                [dataIndexMap.description]: description || undefined,
                [dataIndexMap.updatedBy]: updatedBy || undefined,
            }
        }

        this.props.toggleIsFetchingEmailTemplateFlag(true);
        this.props.fetchEmailTemplatesLite({ type: 'CUSTOM', filterSort: this.filterSort })
    }

    handleClear = async (isRefresh) => {
        if (!isRefresh) {
            this.filterSort = { ...this.filterSort, filter: undefined };
        }
        this.props.toggleIsFetchingEmailTemplateFlag(true);
        this.props.fetchEmailTemplatesLite({ type: 'CUSTOM', filterSort: this.filterSort })
    }

    fetchUsersName = searchString => {
        this.setState({ matchedUsersNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    this.setState({ matchedUsersNames: [...new Set(result.map(item => item.fullName))] })
                }
                this.setState({ hasEnteredString: false })
            });
    };

    handleExport = async (fileType) => {
        const timeZone = this.props.timeZoneName;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        exportEmailTemplates(CONFIG.exportTable.customEmailTemplate.fileName + `.${fileType}`, fileType, this.filterSort, timeZone, "CUSTOM")
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }

    render() {
        const { isFetching, emailTemplates, user } = this.props
        const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;

        const neoColumns = [
            {
                Cell: (a) => {
                    const record = a?.cell?.row?.original;
                    return <span onClick={async () => {
                        if (record?.type === "SCHEDULE_MAINTENANCE_UPDATE" || record?.type === "SCHEDULE_MAINTENANCE_CREATE") {
                            await this.props.handleOnClickAction({
                                component: CONFIG.editComponentRoute.customMaintenanceEmailVersion,
                                tabTitle: record?.title,
                                recordId: record?.id,
                                recordName: record?.type
                            })
                        }
                        else {
                            await this.props.handleOnClickAction({
                                component: CONFIG.editComponentRoute.customEmailVersion,
                                tabTitle: record?.title,
                                recordId: record?.id,
                                recordName: record?.type
                            })
                        }
                    }} style={{ textAlign: "center", color: "#1B77AF", cursor: "pointer" }}>{a?.cell?.row?.original?.title}</span>
                },
                Header: "Title",
                sortType: "string",
                accessor: "title",
                disableFilters: true,
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.type}</span>
                },
                Header: "Email Type",
                sortType: "string",
                disableFilters: true,
                accessor: "type",
            },
            {
                Cell: (a) => {
                    const record = a?.cell?.row?.original;
                    return <div>{(record?.lastUpdatedBy !== null && record?.lastUpdatedTime !== null) ? `${record?.lastUpdatedBy?.fullName} on ${record?.lastUpdatedTime?.split("T", 1)}` : "-"}</div>
                },
                Header: "Updated By",
                sortType: "basic",
                disableFilters: true,
                accessor: "lastUpdatedBy",
            },

            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.description}</span>
                },
                Header: "Description",
                sortType: "string",
                accessor: "description",
                disableFilters: true,
            },
            {
                Header: "  ",
                disableFilters: true,
                disableSortBy: true,
                Cell: (a) => {
                    const record = a.cell.row.original;
                    return (
                        <React.Fragment>
                            <div style={{ display: "flex", gap: "24px" }}>
                                <div>
                                    <Tooltip title="Edit">
                                        <button disabled={!isSuperAdminUser} className={isSuperAdminUser ? "link" : "link-disabled"}
                                            onClick={() => this.props.handleOnClickAction({
                                                component: CONFIG.editComponentRoute.customEmail,
                                                tabTitle: record?.title,
                                                recordId: record?.id
                                            })}>
                                            <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div>
                                    <Tooltip title="View Details">
                                        <button className='link'
                                            onClick={async () => {
                                                if (record?.type === "SCHEDULE_MAINTENANCE_UPDATE" || record?.type === "SCHEDULE_MAINTENANCE_CREATE") {
                                                    await this.props.handleOnClickAction({
                                                        component: CONFIG.editComponentRoute.customMaintenanceEmailVersion,
                                                        tabTitle: record?.title,
                                                        recordId: record?.id,
                                                        recordName: record?.type
                                                    })
                                                }
                                                else {
                                                    await this.props.handleOnClickAction({
                                                        component: CONFIG.editComponentRoute.customEmailVersion,
                                                        tabTitle: record?.title,
                                                        recordId: record?.id,
                                                        recordName: record?.type
                                                    })
                                                }
                                            }}
                                        >
                                            <span class="neo-icon-list-bullet" title="View Details" style={{ fontSize: "20px" }}></span>
                                        </button></Tooltip>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                },
            }
        ]

        const formFields = [
            {
                label: "Title",
                type: CONFIG.formFieldTypes.INPUT,
                key: "title",
                value: this.filterSort?.filter?.title,
                colSpan: 24,
            },
            {
                label: "Email Type",
                type: CONFIG.formFieldTypes.INPUT,
                key: "type",
                colSpan: 24,
                value: this.filterSort?.filter?.type
            },
            {
                label: "Description",
                type: CONFIG.formFieldTypes.INPUT,
                key: "description",
                colSpan: 24,
                value: this.filterSort?.filter?.description
            },
            {
                label: "Last Edited By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                data: this.state.matchedUsersNames,
                key: "updatedBy",
                colSpan: 24,
                value: this.filterSort?.filter?.[dataIndexMap.updatedBy],
            }
        ]
        const drawerContainer = document.getElementById('#component-content');
        return (
            <React.Fragment>
                <Spin spinning={isFetching} indicator={antIcon}>
                    <div style={{ color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px", marginTop: "28px" }}>
                        Manage Custom Templates
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                        <div style={{ display: "flex", width: "100%", flexDirection: "row-reverse", gap: "24px" }}>
                            <div >
                                <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                                    <Button className="export-download-button" icon="chevron-down" variant="secondary">Download</Button>
                                </Dropdown>
                            </div>
                            <div >
                                <NeoIcon aria-label="menu-aria" size="md" className="clickable" onClick={() => this.setState(prevState => ({
                                    showFilter: !prevState.showFilter
                                }))} title="Filter" icon={this.state.showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                            </div>
                            <div >
                                <NeoIcon aria-label="menu-aria" size="md" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                            </div>
                        </div>
                    </div>
                    <div>
                        {this.state.showFilter && (
                            <FilterDrawer
                                visible={this.state.showFilter}
                                width='520'
                                handleCloseFilterDrawer={() => this.setState(prevState => ({
                                    showFilter: false,
                                    record: null
                                }))}
                                placement="right"
                                drawerContainer={drawerContainer}
                                style={{ zIndex: 9999, }}
                                maskClosable={false}
                                panelHeader="Filters and Export"
                                formFields={formFields}
                                handleClear={this.handleClear}
                                timeZone={this.props.timeZoneName}
                                hideSoftDeleteCheckbox={true}
                                hideSave={true}
                                handleFilter={this.handleFilter}
                                getSearchData={this.fetchUsersName}
                            />)}
                        {isFetching ? null : <Table
                            columns={neoColumns}
                            data={emailTemplates || []}
                            className="table-actions"
                            allowColumnFilter={false}
                            itemsPerPageOptions={[10, 20, 50, 100]}
                            showRowSeparator
                            {...otherFields}
                        />}
                    </div>
                </Spin>
            </React.Fragment>
        )
    }

}
const mapStateToProps = ({ customEmailTemplates, emailTemplates, user, tabsLayout }) => {

    return {
        emailTemplates: customEmailTemplates.data,
        isFetching: emailTemplates.isFetching,
        user: user.profile,
        timeZoneName: user.profile.timezone,
        currentFilter: customEmailTemplates.currentCustomEmailFilter,
        isEdit: customEmailTemplates.isEdit,
        openedTabs: tabsLayout.panes
    };
}


export default connect(
    mapStateToProps,
    {
        fetchEmailTemplatesLite,
        selectTitle,
        toggleBackButton,
        toggleIsFetchingEmailTemplateFlag,
        currentEmailtemplateFilter,
        handleOnClickAction
    }
)(ManageCustomEmailTemplates)
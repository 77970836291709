import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Select, Table, Icon, Divider, Popconfirm, Card, Spin, Form, Input, Typography, message, Menu, Dropdown, Button } from 'antd';
import { isTabAlreadyOpen } from "../common/TabLayout";
import {
    fetchNetworkDetails,
    toggleIsFetchingIPAddresses,
    updateIPAddressForm,
    deleteIPAddress,
    fetchIPAddresses,
    putNetworkDetails,
    cancelRoleTemplates,
    clearNetworks,
    clearNetworkData,
    fetchNetworks,
    getNetworkLoginPassword,
    updateNetworkCurrentPage,
    toggleIsIPAddressUpdated,
    restoreIPAddress,
    deleteNetworkAddress,
    exportNetworks,
    currentNetworksFilter,
} from '../../actions';
import DataCenters from '../common/DataCenters';
import IPAddressForm from './IPAddressForm';
import CONFIG from '../../config';
import NetworkRoleTemplates from './NetworkRoleTemplates';
import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';
import openConfirm from '../common/Confirm';
import { camelToTitleCase } from '../../utils/strings'
import scrollIntoView from 'scroll-into-view';


const { Option } = Select;
const { Text, Paragraph } = Typography;

const dataIndexMap = {
    hostAddress: {
        filter: 'hostAddress',
        sort: 'getHostAddress'
    },
    hostName: {
        filter: 'hostName',
        sort: 'getHostName'
    },
    vmhost: {
        filter: 'vmhost',
        sort: 'getVmhost'
    },

    description: {
        filter: 'description',
        sort: 'getDescription'
    },
    loginAccount: {
        filter: 'loginAccount',
        sort: 'getLoginAccount'
    },
    loginPassword: {
        filter: 'loginPassword',
        sort: 'getLoginPassword'
    },
    accessType: {
        filter: 'accessType',
        sort: 'getAccessType'
    },
    accessLink: {
        filter: 'accessLink',
        sort: 'getAccessLink'
    }
};

const EditNetwork = Form.create({ name: 'my_profile' })(
    class EditNetwork extends React.Component {

        state = {
            isEditing: false,
            selectedDataCenter: undefined,
            encryptedPassword: "***********",
            showPassword: false,
            encryptedPasswordRecordId: null,


            previouslySelectedNetwork: undefined,
            showTable: false,
            isReturnedFromDelete: false,
            isReturnedFromEdit: false,
            editedRecordIndex: undefined,
            deletedRecordIndex: undefined
        }

        currentPage = 1;
        filterSort = {}
        selectedNetwork = undefined
        editIndex = undefined
        deleteIndex = undefined
        flag = 0;
        clearCurrentIpAddresses = async () => {
            this.currentPage = 1
            this.setState({ ipAddressesData: [], objectIpAddressesCount: undefined })
        }


        handleFilter = (dataIndex, confirm) => {
            dataIndex = dataIndex?.trim();
            if (!dataIndex) {
                pushMessage(CONFIG.messageType.warning, "Please enter a search string")
                return;
            }
            else confirm()
        };

        handleClear = clearFilters => {
            clearFilters();
        };

        getFilterDropDown = (filterProps, dataIndex) => {
            const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
            return (
                <SearchFilter
                    dataIndex={dataIndex}
                    searchString={selectedKeys[0]}
                    setSearchString={setSelectedKeys}
                    confirm={confirm}
                    handleClear={this.handleClear}
                    handleSearch={this.handleFilter}
                    clearFilters={clearFilters}
                />
            )
        }

        getColumnSearchProps = dataIndex => (
            {
                filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, camelToTitleCase(dataIndex)),
                sorter: (a, b) => a[dataIndex] === null ? -1 : b[dataIndex] === null ? 1 : a[dataIndex]?.toLowerCase().localeCompare(b[dataIndex]?.toLowerCase())
            }
        )

        handleRestore = async (ipAddress, index) => {
            const { selectedNetwork } = this.props;
            this.ipAddress = ipAddress;
            this.deleteIndex = index
            const response = await this.props.restoreIPAddress(ipAddress, selectedNetwork, this.currentPage, 1, true);
            if (response) {
                this.scrollToView = true
                await this.fetchEditAndDeleteRecords();
            }
        }

        handleDelete = (ipAddress, index) => {
            this.ipAddress = ipAddress;
            this.deleteIndex = index
            const { isDeletedView } = this.props;
            if (this.props.userProfile?.isDeveloper && isDeletedView) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
            else {
                const okButtonText = isDeletedView ? "Confirm Permanent Deletion" : "Confirm Deletion"
                const content = isDeletedView ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "IP Address")}</h4> : <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "IP Address")}</h4>
                const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'IP address')
                openConfirm(okButtonText, this.handleDeleteConfirmation, null, content, modalTitle);
            }
        }

        handleDeleteNetwork = () => {
            const { selectedNetwork } = this.props;
            this.network = selectedNetwork;
            const okButtonText = "Confirm Deletion"
            const content = <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "Network Address")}</h4>
            const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'Network')
            openConfirm(okButtonText, this.handleDeleteNetworkConfirmation, null, content, modalTitle);
        }


        handleDeleteNetworkConfirmation = async () => {
            const response = await this.props.deleteNetworkAddress(this.state.selectedDataCenter, this.network, false, this.filterSort);
            if (response) {
                this.props.clearNetworkData();
                this.setState({ showTable: false })
                this.props.fetchNetworks(this.props.isDeletedView);
            }

        }

        handleDeleteConfirmation = async () => {
            const { selectedNetwork, isDeletedView } = this.props;
            const response = await this.props.deleteIPAddress(this.ipAddress, selectedNetwork, this.currentPage, 1, isDeletedView);
            if (response) {
                this.scrollToView = true
                await this.fetchEditAndDeleteRecords();
            }
        }

        fetchEditAndDeleteRecords = async () => {
            const { operation } = this.props;
            const isPUT = operation?.includes('put');
            const isPOST = operation?.includes('post');
            await this.props.toggleIsIPAddressUpdated(false)
            await this.clearCurrentIpAddresses()
            if (isPUT || (this.editIndex || this.deleteIndex)) {
                const index = this.editIndex || this.deleteIndex
                const pageSize = ((Math.ceil(index / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) + CONFIG.lazyLoadPageSize);
                const currentPageNumber = ((Math.ceil(index / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) / CONFIG.lazyLoadPageSize) + 1;
                await this.props.updateNetworkCurrentPage(currentPageNumber, pageSize, this.ipAddress?.id, index)
                if (this.deleteIndex) {
                    await this.setState({ isReturnedFromDelete: true, deletedRecordIndex: index })
                }
                else if (this.editIndex) {
                    await this.setState({ isReturnedFromEdit: true, editedRecordIndex: index })
                }
                this.currentPage = this.props.currentPageNumber + 1
                await this.fetchIpAddressOnUpdate();
            }
            else if (isPOST) {
                await this.fetchIpAddressOnUpdate();
            }
        }


        handleDataCenterChange = async (selectedDataCenter) => {
            await this.clearCurrentIpAddresses()
            selectedDataCenter = JSON.parse(selectedDataCenter)
            this.setState({ showTable: false })
            this.filterSort = {};
            this.setState({ selectedDataCenter })
            this.props.clearNetworkData();
        }

        handleNetworkDetailsChange = () => {
            const { form } = this.props;
            const description = form.getFieldValue('description')
            const gateway = form.getFieldValue('gateway')
            const selectedNetwork = this.props.selectedNetwork
            this.setState({ isEditing: false });
            this.props.putNetworkDetails({ networkObj: selectedNetwork, description, gateway })

        }

        handleNetworkChange = async (selectedNetwork, isEdit) => {
            await this.clearCurrentIpAddresses()
            this.selectedNetwork = isEdit ? selectedNetwork : JSON.parse(selectedNetwork)
            this.currentPage = 1;
            await this.props.fetchNetworkDetails(this.selectedNetwork)
                .then(async (network) => {
                    this.handleFetchIpAddresses(network);
                    this.setState({ showTable: true })
                })
        }


        handleSortChange = async (pagination, filters, sorter) => {
            await this.clearCurrentIpAddresses()
            this.setState(({ showPassword: false }))
            let { hostAddress, hostName, vmhost, description, loginAccount, loginPassword, accessType, accessLink } = filters;
            this.filterSort = {
                filter: {
                    [dataIndexMap.hostAddress.filter]: hostAddress?.[0]?.trim(),
                    [dataIndexMap.hostName.filter]: hostName?.[0]?.trim(),
                    [dataIndexMap.vmhost.filter]: vmhost?.[0]?.trim(),
                    [dataIndexMap.description.filter]: description?.[0]?.trim(),
                    [dataIndexMap.loginAccount.filter]: loginAccount?.[0]?.trim(),
                    [dataIndexMap.loginPassword.filter]: loginPassword?.[0]?.trim(),
                    [dataIndexMap.accessType.filter]: accessType?.[0]?.trim(),
                    [dataIndexMap.accessLink.filter]: accessLink?.[0]?.trim()
                },
                sort: sorter.columnKey
                    ? {
                        [dataIndexMap[sorter.columnKey].sort]: CONFIG.sortMap[sorter.order],
                    }
                    : undefined
            }

            this.handleFetchIpAddresses();
            this.setState({ showTable: true })
        }

        handleExportNetwork = async (fileType) => {
            const { timeZoneName, selectedNetwork, isDeletedView, isReadOnly } = this.props;
            const assigned = isReadOnly || isDeletedView ? CONFIG.assignedOptions[1] : undefined

            pushMessage(CONFIG.messageType.loading, "Exporting", 0);
            exportNetworks({ network: selectedNetwork, assigned, filterSort: this.filterSort, viewDeletedItems: isDeletedView, fileName: CONFIG.exportTable.network.fileName + `.${fileType}`, fileFormat: fileType, timeZone: timeZoneName })
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }


        renderNetworkOptions = () => {
            const { networks, isReadOnly, templateType, deletedNetworks, isDeletedView } = this.props;
            const { selectedDataCenter } = this.state;
            const isSuperAdmin = templateType === CONFIG.roleTypes.superAdmin;
            const networksObj = isDeletedView ? deletedNetworks : networks;

            const networkOptions = networksObj?.length > 0 && networksObj?.filter(network => {
                const filterByDataCenter = network.dataCenter.id === selectedDataCenter?.id;
                const filterByAccess = network?.adminAccess === CONFIG.roleTemplateAccess.fullAccess
                const filterCondition = isReadOnly || isSuperAdmin ? filterByDataCenter : (filterByDataCenter && filterByAccess)
                return filterCondition;
            });
            return networkOptions
                ? networkOptions.map((network, index) => <Option key={index} value={JSON.stringify(network)}>{`${network.networkAddress} - ${network.description}`}</Option>)
                : [];
        }

        fetchIpAddressOnUpdate = async () => {
            const { pageSize, isReadOnly, isDeletedView, selectedNetwork } = this.props;
            const { isReturnedFromDelete, isReturnedFromEdit } = this.state
            const assigned = isReadOnly || isDeletedView ? CONFIG.assignedOptions[1] : undefined
            await this.setState({ loading: true })
            await this.props.fetchIPAddresses({
                network: selectedNetwork,
                pageNumber: (isReturnedFromDelete || isReturnedFromEdit) ? 1 : this.currentPage++,
                pageSize: (isReturnedFromDelete || isReturnedFromEdit) ? pageSize : CONFIG.lazyLoadPageSize,
                assigned,
                filterSort: this.filterSort,
                viewDeletedItems: isDeletedView
            })
                .then(async (ipAddressResponse) => {
                    await this.setState(({ ipAddressesData }) => ({
                        loading: false,
                        ipAddressesData: ipAddressResponse[0] ? ipAddressesData?.concat(ipAddressResponse[0]) : [],
                        objectIpAddressesCount: ipAddressResponse[1],
                    }))
                })
        }

        handleFetchIpAddresses = async (network) => {
            const { isReadOnly, isDeletedView, ipAddressesCount } = this.props;
            const selectedNetwork = network || this.props.selectedNetwork
            const countOfRecordsFetched = this.state.ipAddressesData?.length
            if ((ipAddressesCount === (undefined || 0) && countOfRecordsFetched === (undefined || 0)) || !(ipAddressesCount <= countOfRecordsFetched)) {
                this.setState({ loading: true });
                const assigned = isReadOnly || isDeletedView ? CONFIG.assignedOptions[1] : undefined
                await this.props.fetchIPAddresses({ network: selectedNetwork, pageNumber: this.currentPage++, assigned, filterSort: this.filterSort, viewDeletedItems: isDeletedView })
                    .then(async (ipAddressResponse) => {
                        await this.setState(({ ipAddressesData }) => ({
                            loading: false,
                            ipAddressesData: ipAddressResponse[0] ? ipAddressesData?.concat(ipAddressResponse[0]) : [],
                            objectIpAddressesCount: ipAddressResponse[1],
                        }))
                        this.flag = 0;
                    })
            }

        }

        async componentDidUpdate() {
            const { selectedNetwork } = this.props
            if (this.state.showTable && this.state.previouslySelectedNetwork !== selectedNetwork?.id) {
                await this.setState({ previouslySelectedNetwork: selectedNetwork?.id })
            }

            const ipAddressToScrollIntoView = this.state.isReturnedFromDelete
                ? Math.abs(this.state.deletedRecordIndex - 1)
                : this.state.isReturnedFromEdit
                    ? this.state.editedRecordIndex
                    : 0;

            (this.state.isReturnedFromEdit || this.state.isReturnedFromDelete)
                && this.state.ipAddressesData?.length > 0
                && ipAddressToScrollIntoView
                && this.scrollToView
                && scrollIntoView(document.querySelector(`.scroll-row-${ipAddressToScrollIntoView}`), {
                    align: { top: 0, left: 0 },
                    behavior: "smooth"
                },
                    await this.setState({ isReturnedFromEdit: false, isReturnedFromDelete: false }),
                    this.editIndex = undefined,
                    this.deleteIndex = undefined,
                    this.scrollToView = false
                )

        }

        componentWillUnmount() {
            if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
                const selectedNtwk = this.props.isEdit ? this.props.currentSelectedNetwork : this.props.selectedNetwork

                if (this.state.selectedDataCenter !== undefined && selectedNtwk !== undefined)
                    this.props.currentNetworksFilter(this.state.selectedDataCenter, selectedNtwk)
                else {
                    this.props.clearNetworkData();
                }
            }
            else {
                this.props.clearNetworkData();
            }
        }

        componentDidMount() {
            const { currentSelectedDataCenter, isEdit, currentSelectedNetwork } = this.props;
            this.setState({ loading: true })
            this.setState({ currentPath: window.location.pathname })
            if (isEdit) {
                this.setState({ selectedDataCenter: currentSelectedDataCenter })
                this.handleNetworkChange(currentSelectedNetwork, true)
            }

        }

        toggleViewLoginPassword = (record) => {
            this.props.getNetworkLoginPassword(record.id, false)
                .then((password) => {
                    if (password) {
                        if (this.state.encryptedPasswordRecordId === record.id && this.state.showPassword)
                            this.setState(({ showPassword: false }))
                        else this.setState(({ showPassword: true }))
                        this.setState({
                            encryptedPassword: this.state.showPassword ? password : "***********",
                            encryptedPasswordRecordId: record.id
                        })
                    }
                    else this.setState(({ showPassword: false }))
                });
        }

        handleEdit = async (ipAddress, index) => {
            this.editIndex = index;
            this.ipAddress = ipAddress;
            this.scrollToView = true
        }

        handleMenuClick = (e) => {
            this.handleExportNetwork(e.key)
        }

        render() {
            const { isEdit, selectedNetwork, currentSelectedNetwork, isIpAddressUpdated, ipAddressesCount, isFetching, isReadOnly, isDeletedView, isSuperAdmin } = this.props;
            const selectedNetworkDropdown = isEdit ? currentSelectedNetwork : selectedNetwork;
            const networkDetails = selectedNetworkDropdown;
            isIpAddressUpdated && this.fetchEditAndDeleteRecords();
            this.columns = [
                {
                    title: "Add User Profiles",
                    key: "action",
                    align: 'center',
                    render: ({ hostAddress, hostName, rowSpan }) => {
                        return {
                            children: <Icon aria-label="aria-menu" className="primary-text-color" type="plus" onClick={() => this.props.updateIPAddressForm({ hostAddress, hostName })} />,
                            props: { rowSpan: rowSpan }
                        };
                    },
                    width: '12%',
                },
                {
                    title: 'No.',
                    key: 'index',
                    align: 'center',
                    width: '9%',
                    render: (text, record, index) => index + 1,
                },
                {
                    title: "Host Address",
                    dataIndex: "hostAddress",
                    width: '20%',
                    render: (hostAddress, { rowSpan }) => {
                        return {
                            children: hostAddress,
                            props: { rowSpan }
                        }
                    },
                    ...this.getColumnSearchProps('hostAddress'),
                },
                {
                    title: "Host Name(s)",
                    dataIndex: "hostName",
                    width: '20%',
                    render: (hostName, { rowSpan }) => {
                        return {
                            children: hostName,
                            props: { rowSpan }
                        }

                    },
                    ...this.getColumnSearchProps('hostName'),

                },
                {
                    title: "VM Host",
                    dataIndex: "vmhost",
                    width: '15%',
                    ...this.getColumnSearchProps('vmhost'),
                },
                {
                    title: "Description",
                    dataIndex: "description",
                    width: '25%',
                    ...this.getColumnSearchProps('description'),
                },
                {
                    title: "Login Account",
                    dataIndex: "loginAccount",
                    width: '23%',
                    render: (loginAccount) => {
                        return (<React.Fragment>
                            {loginAccount && <Paragraph copyable={{ text: `${loginAccount}` }} >
                                <p className="display-inline"> {loginAccount} </p>
                            </Paragraph>}
                        </React.Fragment>
                        )
                    },
                    ...this.getColumnSearchProps('loginAccount'),
                },
                {
                    title: "Login Password",
                    dataIndex: "loginPassword",
                    width: '23%',
                    render: (loginPassword, record) => {
                        return (record.id !== 0 ? <>
                            <Paragraph copyable={this.state.encryptedPasswordRecordId === record.id && this.state.encryptedPassword && this.state.showPassword ? { text: `${this.state.encryptedPassword}` } : false}>
                                <span
                                    className="display-password text-color demo-credential">
                                    {this.state.showPassword && this.state.encryptedPasswordRecordId === record.id ? this.state.encryptedPassword : "***********"}&emsp;
                                </span>
                                <Icon aria-label="aria-menu"
                                    type={this.state.showPassword && this.state.encryptedPasswordRecordId === record.id ? "eye" : "eye-invisible"}
                                    theme="filled"
                                    onClick={() => this.toggleViewLoginPassword(record)} />
                            </Paragraph>
                        </> : "")
                    }
                },
                {
                    title: "Access Type",
                    dataIndex: "accessType",
                    width: '20%',
                    ...this.getColumnSearchProps('accessType'),
                },
                {
                    title: "Access Link",
                    width: '25%',
                    dataIndex: "accessLink",
                    //eslint-disable-next-line
                    render: (accessLink) => {
                        return <span><a className="link" href={accessLink} rel="noopener noreferrer" target="_blank">{accessLink}</a></span>
                    },
                },
                {
                    title: 'Actions',
                    key: 'actions',
                    width: '25%',
                    render: (text, record, index) => {
                        return (
                            <span>
                                {!isDeletedView ?
                                    <>
                                        <button
                                            className="link"
                                            onClick={
                                                () => {
                                                    this.props.updateIPAddressForm(record);
                                                    record?.loginAccount && this.props.getNetworkLoginPassword(record.id, true);
                                                    this.handleEdit(record, index + 1)
                                                    this.setState(({ showPassword: false }));
                                                }}><span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span></button>
                                        <Divider className={`edit-row-${index + 1}`} type="vertical" />
                                        <button disabled={record.id === 0} className={record.id === 0 ? 'link-disabled' : 'link'} onClick={() => this.handleDelete(record, index + 1)}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                                    </>
                                    :
                                    <>
                                        <button disabled={record.id === 0} className={record.id === 0 ? 'link-disabled' : 'link'} onClick={() => this.handleDelete(record, index + 1)}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                                        <Divider type="vertical" />
                                        <Popconfirm disabled={record.id === 0} title="Confirm restore?" onConfirm={() => this.handleRestore(record.id, index + 1)}>
                                            <button disabled={record.id === 0} className={record.id === 0 ? "link-disabled" : "link"}><span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span></button>
                                        </Popconfirm>
                                    </>
                                }
                            </span >
                        )
                    },
                    align: 'center'
                }
            ];

            const { isEditing, selectedDataCenter } = this.state;
            const { getFieldDecorator } = this.props.form;
            const menu = (
                <Menu onClick={(e) => this.handleMenuClick(e)}>
                    <Menu.Item key={CONFIG.exportFileType.xlsx.ext}>
                        {CONFIG.exportFileType.xlsx.name}
                    </Menu.Item>
                    <Menu.Item key={CONFIG.exportFileType.csv.ext}>
                        {CONFIG.exportFileType.csv.name}
                    </Menu.Item>
                </Menu>
            );

            return (
                <Form>
                    <Spin spinning={isFetching} wrapperClassName="spin-overlay">
                        <Row gutter={24}>
                            <IPAddressForm currentPage={this.currentPage} />
                            <Col xl={6} sm={12} xs={24} className="vertical-spacing">
                                <DataCenters fromDids={true} form={this.props.form} dataCenter={this.state.selectedDataCenter} network={selectedNetworkDropdown} handleChange={this.handleDataCenterChange} />
                            </Col>
                            <Col style={{ paddingTop: "25px" }} xl={8} sm={12} xs={24} className="vertical-spacing">
                                {
                                    selectedDataCenter &&
                                    <Select
                                        showSearch={true}
                                        placeholder="Select a Network"
                                        onChange={(network) => this.handleNetworkChange(network, false)}
                                        value={selectedNetworkDropdown ? JSON.stringify(selectedNetworkDropdown) : undefined}>
                                        {this.renderNetworkOptions()}
                                    </Select>}
                            </Col>
                            <Col style={{ paddingTop: "25px" }} xl={8} sm={12} xs={24} className="vertical-spacing">
                                {selectedNetworkDropdown &&
                                    <Dropdown overlay={menu}>
                                        <Button className="primary-action-button-filled left-spacing-3">
                                            Export <Icon aria-label="aria-menu" type="down" />
                                        </Button>
                                    </Dropdown>}
                            </Col>
                        </Row>
                        {
                            networkDetails &&
                            <React.Fragment>
                                {!isReadOnly && !isDeletedView && isSuperAdmin &&
                                    < Row >
                                        <Col xl={8} sm={12} xs={24}>
                                            <NetworkRoleTemplates form={this.props.form} isEditing={true} />
                                        </Col>
                                    </Row>
                                }
                                {
                                    !isDeletedView &&
                                    <Card className="network-card">
                                        <Row><span className="medium-text bold">Network : </span>{networkDetails.networkAddress}</Row>
                                        <br />
                                        <Row><span className="medium-text bold">Subnet Mask : </span>{networkDetails.subnetMask}</Row>
                                        <br />
                                        <Row>
                                            {isEditing
                                                ? <Col xxl={3} xl={5} lg={8} md={6} xs={24} className="top-spacing-7 title">Description :</Col>
                                                : <span className="medium-text bold"> Description : </span>
                                            }
                                            {isEditing
                                                ? (
                                                    <Col xl={12} lg={12} md={14} xs={24}>
                                                        <Form.Item className="network-description">
                                                            {getFieldDecorator('description', { initialValue: networkDetails.description })(<Input placeholder="Enter a description" />)}
                                                        </Form.Item>
                                                    </Col>
                                                )
                                                : networkDetails.description}
                                        </Row>
                                        <br />
                                        <Row>
                                            {isEditing
                                                ? <Col xxl={4} xl={7} lg={10} md={8} xs={24} className="top-spacing-7 title"> Default Gateway : </Col>
                                                : <span className="medium-text bold"> Default Gateway : </span>
                                            }
                                            {isEditing
                                                ? (
                                                    <Col xl={12} lg={14} md={14} xs={24}>
                                                        <Form.Item className="network-description">
                                                            {getFieldDecorator('gateway', { initialValue: networkDetails.gateway })(<Input placeholder="Enter a default gateway" />)}
                                                        </Form.Item>
                                                    </Col>
                                                )
                                                : networkDetails.gateway}
                                        </Row>
                                        {!isReadOnly &&
                                            <Row className="float-right top-spacing-3">
                                                {isEditing
                                                    ? (
                                                        <span>
                                                            <span className="link" onClick={this.handleNetworkDetailsChange}>Save</span>
                                                            <Divider type="vertical" />
                                                            <span className="link" onClick={() => this.setState({ isEditing: false })}>Cancel</span>
                                                        </span>
                                                    )
                                                    : <>
                                                        <span className="link" onClick={() => this.setState({ isEditing: true })}>Edit</span>
                                                        {/* <span className="link" onClick={() => this.handleExportNetwork()}>Export</span> */}
                                                        {isSuperAdmin && <Divider type="vertical" />}
                                                        {isSuperAdmin && <button className="link" onClick={() => this.handleDeleteNetwork()}>Delete</button>}
                                                    </>
                                                }
                                            </Row>
                                        }
                                        <br />
                                    </Card>
                                }
                            </React.Fragment>
                        }
                        <br />
                        {
                            this.state.showTable &&
                            <React.Fragment>
                                <Table
                                    size='middle'
                                    bordered
                                    rowClassName={(record, index) => (`scroll-row-${index + 1}`)}
                                    className="responsive-container network-table"
                                    columns={isDeletedView ? this.columns.slice(1, 11) : isReadOnly ? this.columns.slice(1, 10) : this.columns}
                                    dataSource={this.state.ipAddressesData || []}
                                    scroll={{ y: 550, x: true | 1600 }}
                                    rowKey={(record, index) => index + 1}
                                    loading={this.state.loading}
                                    pagination={false}
                                    onChange={this.handleSortChange}
                                    footer={() => <>{this.state.ipAddressesData?.length !== 0 && <div style={{ textAlign: 'center' }}>
                                        <Text strong>Fetched {this.state.ipAddressesData?.length} out of {ipAddressesCount} IP Addresses</Text>
                                    </div>}
                                    </>}
                                />
                            </React.Fragment>
                        }
                    </Spin>
                </Form >
            )
        }
    }
);

const mapStateToProps = ({ networks, user, tabsLayout }) => {
    return {
        networks: networks.data,
        networkDetails: networks.current,
        ipAddresses: networks.ipAddresses,
        ipAddressesCount: networks.ipAddressCount,
        isFetching: networks.isFetchingIPAddresses,
        selectedNetwork: networks.selectedNetwork,
        templateType: user.profile.roleTemplate.templateType,
        userProfile: user.profile,
        deletedNetworks: networks.deletedNetworks,
        pageSize: networks.pageSize,
        currentPageNumber: networks.currentPageNumber,
        isIpAddressUpdated: networks.isIpAddressUpdated,
        editedIpAddressId: networks.networkId,
        editedData: networks.editedData,
        operation: networks.operation,
        openedTabs: tabsLayout.panes,
        isEdit: networks.isEdit,
        currentSelectedDataCenter: networks.currentSelectedDataCenter,
        currentSelectedNetwork: networks.currentSelectedNetwork
    };
};

export default connect(
    mapStateToProps,
    {
        fetchNetworkDetails,
        toggleIsFetchingIPAddresses,
        updateIPAddressForm,
        deleteIPAddress,
        fetchIPAddresses,
        putNetworkDetails,
        cancelRoleTemplates,
        clearNetworks,
        clearNetworkData,
        fetchNetworks,
        getNetworkLoginPassword,
        updateNetworkCurrentPage,
        toggleIsIPAddressUpdated,
        restoreIPAddress,
        deleteNetworkAddress,
        exportNetworks,
        currentNetworksFilter
    }
)(EditNetwork);
import React, { Component } from "react";
import { connect } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Divider } from "antd";
import { Icon } from "@avaya/neo-react";
import ViewSFDCDetailsModal from "./ViewSFDCDetailsModal";
import CONFIG from "../../config";

class AdminSFDCStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      SFDCModalData: [],
      status: "",
    };
  }

  handleModalOpen = async ({ data, status }) => {
    await this.setState({
      status: status,
      isModalVisible: true,
      SFDCModalData: data,
    });
  };

  handleModalClose = () => {
    this.setState({
      isModalVisible: false,
      status: "",
    });
  };

  renderSFDCStatus = (count, status, data, value) => {
    const colorIcon = this.getColorAndIcon(status, value);
    return (
      <div className="dashboard-demo-stats-col">
        <div className="dashboard-demo-stats-rows">
          <Icon aria-label="aria-menu"
            style={{ fontSize: "22px", color: colorIcon[0] }}
            icon={colorIcon[1]}
          />
        </div>
        <div className="dashboard-demo-stats-rows">{count}</div>
        {
          <div className="dashboard-demo-stats-rows">
            <span
              className={count > 0 ? "hover-link" : ""}
              style={count > 0 ? { color: "#007BAF" } : {}}
              onClick={() =>
                count > 0
                  ? this.handleModalOpen({
                    data: data,
                    status:
                      status === CONFIG.SFDCStatus.pending
                        ? "Pending"
                        : "Timed-out",
                  })
                  : undefined
              }
            >
              {status === CONFIG.SFDCStatus.pending ? "Pending" : "Timed-out"}
            </span>
          </div>
        }
      </div>
    );
  };

  getColorAndIcon = (status, value) => {
    const color = value === 0 ? `#AB3FE0` : value === 1 ? `#F38D00` : `black`;
    const icon =
      status === CONFIG.SFDCStatus.pending
        ? `away`
        : status === CONFIG.SFDCStatus.exception
          ? `redirect`
          : ``;
    return [color, icon];
  };

  render() {
    const { SFDCStatusData } = this.props;
    const {
      pendingOpportunityCount,
      exceptionOpportunityCount,
      pendingOpportunityList,
      exceptionOpportunityList,
    } = SFDCStatusData || {};
    return (
      <div>
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          spinning={this.props.isFetchingSFDCStatus}
        >
          <div className="dashboard-schedules-category-heading left-spacing">
            <Icon aria-label="aria-menu"
              icon="pie-chart"
              style={{ fontSize: "19px", paddingRight: "12px" }}
            />
            SFDC Schedules Exception
          </div>
          {SFDCStatusData &&
            Object.keys(SFDCStatusData).length > 0 &&
            SFDCStatusData.constructor === Object ? (
            <div className="daily-test-demo-stats">
              <div className="demo-stats-container">
                <div className="demo-stats-child-left">
                  <div className="dashboard-daily-testing-demo-stats">
                    {this.renderSFDCStatus(
                      pendingOpportunityCount,
                      CONFIG.SFDCStatus.pending,
                      pendingOpportunityList,
                      0
                    )}
                  </div>
                </div>

                <div className="demo-stats-child-right">
                  <div className="dashboard-daily-testing-demo-stats">
                    {this.renderSFDCStatus(
                      exceptionOpportunityCount,
                      CONFIG.SFDCStatus.exception,
                      exceptionOpportunityList,
                      1
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="dashboard-no-schedules">
              <Icon aria-label="aria-menu" icon="info" size="md" />
              No data
            </div>
          )}
          <ViewSFDCDetailsModal
            isModalVisible={this.state.isModalVisible}
            data={this.state.SFDCModalData}
            handleModalClose={this.handleModalClose}
            status={this.state.status}
          />
          <div style={{ marginLeft: "2px" }}>
            <Divider style={{ marginBottom: "6px" }} />
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ adminDashboard }) => {
  return {
    SFDCStatusData: adminDashboard.SFDCStatusData,
    isFetchingSFDCStatus: adminDashboard.isFetchingSFDCStatus,
  };
};

export default connect(mapStateToProps)(AdminSFDCStatistics);

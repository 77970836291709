import {Plugin,ButtonView} from 'ckeditor5';


export default class UnlinkPlugin extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('unlink', (locale) => { 
            const button = new ButtonView(locale);

            button.set({
                label: 'Unlink',
                withText: true,
                tooltip: true
            });

            button.on('execute', () => {
                editor.execute('unlink');
            });

            return button;
        });
    }
}

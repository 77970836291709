import React from 'react';
import { Row, Spin, Table as PrintTable, message, Modal } from 'antd';
import { Dropdown } from 'antd5';
import { connect, batch } from 'react-redux';
import moment from 'moment-timezone';
import CONFIG from '../../config';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Button, Pagination, Icon, Chip } from "neo-latest"
import { toTitleCase, exportMenu } from "../../utils/strings";
import pushMessage from '../common/PushMessage';
import NeoTable from '../common/NeoTable';
import { LoadingOutlined } from '@ant-design/icons';
import { isTabAlreadyOpen } from "../common/TabLayout";
import ScheduledDemoViewDetails from '../ManageScheduledDemo/ScheduledDemoViewDetails';
import FilterDrawer from '../common/FilterDrawer';
import CapabilityRequestModal from '../common/CapabilityRequestModal';
import DemoRegistrationModal from '../common/DemoRegistrationModal';
import {
    fetchMyRequests,
    toggleIsFetchingMyRequests,
    openLinkInNewTab,
    toggleBackButton,
    clearMyRequests,
    fetchMyRequestsForExportPrint,
    fetchAllExistingDemos,
    currentMyRequestsFilter,
    exportTable,
    deleteUserCapabilityRequest,
    deleteDemoSchedule,
    deleteUserRequest
} from '../../actions';

const dataIndexMap = {
    demo: 'demo.title',
    requestedDate: 'createdTime',
    requestedStartDate: 'startRequestedTime',
    requestedEndDate: 'endRequestedTime',
    resolvedStartDate: 'startResolvedTime',
    resolvedEndDate: 'endResolvedTime',
    type: 'type',
    status: 'status',
};

class MyRequests extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            height: `${document.body.clientHeight - (document.body.clientHeight * .2)}px`,
            type: props.type || "All",
            status: props.status || "Pending",
            capabilityModalOpen: false,
            capabilityData: null,
            registrationModalVisible: false,
            demoRequestData: null
        }
    }
    currentPage = 1;
    pageSize = CONFIG.pageSize
    filterSort = {};

    async componentDidMount() {
        const { isEdit, currentFilter, pageNumber, pageSize, status, roleTemplate } = this.props;
        const shouldFetchDemos = roleTemplate && (roleTemplate.templateType === CONFIG.roleTypes.superAdmin || roleTemplate.templateType === CONFIG.roleTypes.admin)

        this.setState({ currentPath: window.location.pathname })
        if (isEdit) {
            await this.setState({ isPendingRequests: status });
            this.currentPage = pageNumber || 1;
            this.pageSize = pageSize || CONFIG.pageSize;
            this.filterSort = currentFilter
            if (currentFilter?.filter !== undefined && (currentFilter?.filter?.[dataIndexMap.demo] || currentFilter?.filter?.[dataIndexMap.requestedEndDate] || currentFilter?.filter?.[dataIndexMap.requestedStartDate])) this.setState({ showFilter: true })
        } else {
            this.filterSort = { filter: { ...this.filterSort.filter, type: this.state.type, status: this.state.status } }
            this.setState({ isPendingRequests: true });
        }

        this.props.toggleBackButton(false, { pathname: '/my-requests' });
        this.props.toggleIsFetchingMyRequests(true);
        batch(async () => {
            await this.props.fetchMyRequests({ pageNumber: this.currentPage, filterSort: this.filterSort })
            shouldFetchDemos && this.props.fetchAllExistingDemos({ getAll: true });
        });
        window.addEventListener("resize", this.handleResize);
    }

    handleResize = (e) => {
        this.setState({ height: document.body.clientHeight > 250 ? `${document.body.clientHeight - (document.body.clientHeight * 0.2)}px` : `250px` });
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentMyRequestsFilter(this.filterSort, this.state.isPendingRequests, this.currentPage, this.pageSize)
        }
        else {
            this.props.clearMyRequests();
        }
        window.removeEventListener("resize", this.handleResize);
    }
    getUTCTime = (time) => {
        const { timeZoneName } = this.props;
        return time.tz(timeZoneName, true).toISOString() || undefined
    }


    handleViewChange = (isPending) => {
        this.setState({ isPendingRequests: isPending });
        this.setState({ status: isPending ? "Pending" : "Completed" });
        this.setState({ type: "All" });
        this.filterSort = { filter: { ...this.filterSort?.filter, status: isPending ? "Pending" : "All", type: this.state.type } }
        this.currentPage = 1;
        this.props.toggleIsFetchingMyRequests(true);
        this.handlefetchMyRequests();
    }

    handleClear = async (isRefresh) => {
        await this.props.clearMyRequests()
        if (!isRefresh) {
            this.filterSort = { ...this.filterSort, filter: { type: "All", status: this.state.isPendingRequests ? "Pending" : "All" } };
            await this.setState({ type: "All" })
        }
        this.handlefetchMyRequests();
    }


    handleFilter = async (filters) => {
        let { demo, type, status, createdTime } = filters;
        const isCreatedTimeEmpty = !createdTime || (createdTime.length === 0);
        const isDemoEmpty = !demo || (demo && demo.length === 0);
        const isTypeEmpty = !type || (type && type.length === 0);
        const isStatusEmpty = !status || (status && status.length === 0);

        const formEmpty = !demo && isCreatedTimeEmpty && isDemoEmpty && isTypeEmpty && isStatusEmpty;

        if (formEmpty) {
            pushMessage(CONFIG.messageType.warning, `Please select some fields}`)
            return;
        }
        this.filterSort = {
            ...this.filterSort,
            filter: {
                [dataIndexMap.demo]: !isDemoEmpty ? typeof demo === 'string' ? demo : demo.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.requestedStartDate]: !isCreatedTimeEmpty ? this.getUTCTime(createdTime?.[0].startOf('day')) : undefined,
                [dataIndexMap.requestedEndDate]: !isCreatedTimeEmpty ? this.getUTCTime(createdTime?.[1].endOf('day')) : undefined,
                [dataIndexMap.status]: !isStatusEmpty ? this.state.isPendingRequests ? "Pending" : status : undefined,
                [dataIndexMap.type]: !isTypeEmpty ? type : undefined
            }
        }
        this.currentPage = 1;
        this.handlefetchMyRequests()

    }

    renderStatusTag = (request) => {
        let status = "Approved";
        if (request?.type === CONFIG.requestType.scheduleApproval) status = toTitleCase(request?.scheduleApprovalRequestStatus)
        else if (request?.type === CONFIG.requestType.demoRequest) status = toTitleCase(request?.statusMgmtStatus)
        else status = toTitleCase(request?.capabilityRequestStatus)
        return <Chip variant={CONFIG.myRequestsChips[status]}>{CONFIG.myRequestsStatus[status]}</Chip>
    }

    handlefetchMyRequests = () => {
        this.props.toggleIsFetchingMyRequests(true);
        this.props.fetchMyRequests({ pageNumber: this.currentPage, pageSize: this.pageSize, filterSort: this.filterSort });
    }

    handleExport = async (fileType) => {
        const endPoint = CONFIG.exportTable.myRequests.name;
        const { timeZoneName } = this.props;
        const exportData = await this.props.fetchMyRequestsForExportPrint({ filterSort: this.filterSort });
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        exportTable(endPoint, exportData, timeZoneName, CONFIG.exportTable.myRequests.fileName + `.${fileType}`, fileType)
            .then(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.success, "Table exported successfully")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }

    handleTablePrint = async (handlePrint) => {
        this.printData = await this.props.fetchMyRequestsForExportPrint({ filterSort: this.filterSort });
        this.setState({ printPreview: true }, () => { handlePrint() })
    }


    closePrint = () => {
        this.setState({ printPreview: false })
    }

    handleCloseFilterDrawer = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }))
    }


    handleTableSortChange = async (pagination, _, sorter) => {
        await this.props.clearMyRequests();
        this.filterSort = {
            ...this.filterSort,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }

        this.handlefetchMyRequests();
    }

    setTablePrintHeading = () => {
        return <span className="center-align"><h3>List of {this.state.status} requests</h3></span>;
    }


    handlePagination = async (_, pageNumber) => {
        this.currentPage = pageNumber
        this.handlefetchMyRequests();
    }

    onItemsPerPageChange = async (_, pageSize) => {
        this.pageSize = pageSize
        this.currentPage = 1
        this.handlefetchMyRequests();
    }

    renderScheduleDetails = () => {
        return (
            <Modal
                className='extra-wide-modal'
                visible={this.state.viewScheduleDetails}
                onCancel={() => this.setState({ viewScheduleDetailsRecordId: undefined, viewScheduleDetails: false })}
                footer={[
                    <Button id="view-details-close" className="primary-action-button-filled" key="back" onClick={() => this.setState({ viewScheduleDetailsRecordId: undefined, viewScheduleDetails: false })}>
                        Close
                    </Button>]}

            >
                <ScheduledDemoViewDetails
                    fromDashboard={true}
                    scheduleId={this.state.viewScheduleDetailsRecordId}
                />
            </Modal>
        )
    }


    handleEditScheduleApproval = async (schedule) => {
        this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/lab/${schedule?.id}/edit/isReschedule=${false}/fromManageSide=${false}`)
    }

    handleCapabilityRequestEdit = (record, viewOnly) => {
        this.setState({
            capabilityData: { ...record, viewOnly },
            capabilityModalOpen: true
        })
    }

    handleDemoRequestEdit = (record, viewOnly) => {
        this.setState({
            demoRequestData: { ...record, viewOnly },
            registrationModalVisible: true
        })
    }

    renderActions = (record) => {
        const menuItems = [
            {
                key: 'details',
                label: (
                    <span
                        className='full-width-options'
                        onClick={() => {
                            if (record?.type === CONFIG.requestType.demoRequest) {
                                this.handleDemoRequestEdit(record, true)
                            }
                            else if (record?.type === CONFIG.requestType.scheduleApproval) {
                                this.setState({
                                    viewScheduleDetails: true,
                                    viewScheduleDetailsRecordId: record?.schedule?.id
                                })
                            }
                            else {
                                this.handleCapabilityRequestEdit(record, true)
                            }
                        }
                        }
                    ><Icon aria-label="aria-menu" icon="alignment-left" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>View Details</span>
                    </span>
                ),
            },
            {
                key: 'edit',
                label: (
                    <span
                        className='full-width-options'
                        onClick={() => {
                            if (record?.type === CONFIG.requestType.demoRequest) {
                                this.handleDemoRequestEdit(record, false)
                            }
                            else if (record?.type === CONFIG.requestType.scheduleApproval) {
                                this.handleEditScheduleApproval(record?.schedule)
                            }
                            else {
                                this.handleCapabilityRequestEdit(record, false)
                            }
                        }
                        }
                    ><Icon aria-label="aria-menu" icon="edit" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Edit</span>
                    </span >
                )
            },
            {
                key: 'delete',
                label: (
                    <span
                        className='full-width-options' onClick={
                            async () => {
                                record?.type === CONFIG.requestType.demoRequest
                                    ? await this.props.deleteUserRequest(record?.id)
                                    : record?.type === CONFIG.requestType.scheduleApproval
                                        ? await this.props.deleteDemoSchedule(record?.schedule?.id, false, CONFIG.demoTypes.labPocDemo)
                                        : await this.props.deleteUserCapabilityRequest(record?.id);
                                this.handleClear(true)
                            }

                        }><Icon aria-label="aria-menu" icon="backspace" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Cancel Request</span></span>
                ),
            }
        ];

        const items = this.state.isPendingRequests ? menuItems : [menuItems[0]]
        return (<Dropdown
            overlayClassName='my-schedules-table-actions-menu'
            trigger="click"
            menu={{ items }}
            placement="bottomLeft"
            arrow
        >
            <Icon aria-label="aria-menu" icon="ellipses-horizontal" className='pointer' style={{ fontSize: "19px", color: "#1B77AF" }} />
        </Dropdown>
        )
    }

    handleModalClose = () => {
        this.setState({ capabilityModalOpen: false, capabilityData: null })
    }

    handleDemoRequestModalClose = () => {
        this.setState({ demoRequestData: null, registrationModalVisible: false })
    }

    handleDemoRequestSubmit = () => {
        this.handleDemoRequestModalClose();
        this.handleClear(true)
    }

    handlePostCapabilityRequestSubmit = () => {
        this.handleModalClose();
        this.handleClear(true)
    }

    render() {
        const { requestsCount, requests, isFetching, roleTemplate } = this.props
        const shouldFetchDemos = roleTemplate && (roleTemplate.templateType === CONFIG.roleTypes.superAdmin || roleTemplate.templateType === CONFIG.roleTypes.admin)
        const { capabilityData, capabilityModalOpen, registrationModalVisible, demoRequestData } = this.state;
        const columns = [
            {
                title: <span className={this.filterSort.filter?.[dataIndexMap.demo] ? "filtered-column" : ''}>Demo</span>,
                key: dataIndexMap.demo,
                align: 'left',
                width: "30%",
                render: (record) => record?.demo?.title,
                sorter: true,
            },
            {
                title: <span className={this.filterSort.filter?.[dataIndexMap.requestedDate] ? "filtered-column" : ''}>Requested Date</span>,
                dataIndex: 'createdTime',
                key: 'createdTime',
                align: 'left',
                width: "18%",
                sorter: true,
                render: (createdTime) => moment.tz(createdTime, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)
            },
            {
                title: <span className={this.filterSort.filter?.[dataIndexMap.type] ? "filtered-column" : ''}>Type</span>,
                key: 'type',
                width: "20%",
                align: 'left',
                sorter: true,
                render: (record) => record?.type
            },
            {
                title: "Status",
                key: 'status',
                width: "20%",
                align: 'left',
                render: (record) => this.renderStatusTag(record)
            },
            {
                title: "",
                key: 'action',
                width: "5%",
                align: 'left',
                render: (record) => this.renderActions(record)
            },
        ];

        const formFields = [

            {
                label: "Demo",
                type: shouldFetchDemos ? CONFIG.formFieldTypes.SELECT : CONFIG.formFieldTypes.INPUT,
                key: "demo",
                mode: "tags",
                valueKey: "text",
                data: (this.props.demos) ? this.props.demos.map(demo => ({ text: demo.title })) : [],
                colSpan: '24',
                value: this.filterSort.filter?.[dataIndexMap.demo]
            },
            {
                label: "Date Range",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "createdTime",
                value: this.filterSort?.filter?.status === "Pending"
                    ? [this.filterSort?.filter?.[dataIndexMap.requestedStartDate], this.filterSort?.filter?.[dataIndexMap.requestedEndDate]]
                    : [this.filterSort?.filter?.[dataIndexMap.resolvedStartDate], this.filterSort?.filter?.[dataIndexMap.resolvedEndDate]],
                colSpan: '24',
            },
            {
                label: "Type",
                type: CONFIG.formFieldTypes.DROPDOWN,
                key: "type",
                mode: "default",
                valueKey: "text",
                data: CONFIG.requestTypeFilter,
                value: this.filterSort.filter?.[dataIndexMap.type],
                colSpan: '24',
            },
            {
                label: "Status",
                disabled: this.state.isPendingRequests,
                type: CONFIG.formFieldTypes.DROPDOWN,
                key: "status",
                mode: "default",
                valueKey: "text",
                data: CONFIG.myRequestsCompletedStatusFilter,
                value: this.filterSort.filter?.[dataIndexMap.status],
                colSpan: '24',
            }
        ];

        return (
            <div className="approvals-pagination-row" style={{ maxHeight: this.state.height }}>
                <span className='dashboard-schedules-category-heading' style={{ fontSize: '19px' }}>My Requests</span>
                <span>
                    {!this.state.isPendingRequests && <div className="schedules-table-view-button left-spacing-3">
                    </div>}
                </span>
                <div className='items-on-either-side margin-top-1'>
                    <div className='width-30percent'>
                        <Button aria-label="Pending" className={this.state.isPendingRequests && "neo-button-selected"} variant="secondary" onClick={() => this.handleViewChange(true)} >Pending</Button>
                        <Button aria-label="Completed" className={!this.state.isPendingRequests ? "neo-button-selected left-spacing-3" : "left-spacing-3"} variant="secondary" onClick={() => this.handleViewChange(false)}>Completed</Button>
                    </div>
                    <div>
                        <Icon aria-label="aria-menu" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                        <ReactToPrint
                            content={() => this.componentRef}
                            documentTitle={"My Requestd"}
                            onAfterPrint={this.closePrint}
                            pageStyle={CONFIG.dashboard.schedulePrintStyle}>
                            <PrintContextConsumer>
                                {({ handlePrint }) => (
                                    <Icon aria-label="aria-menu" className="clickable" title="print" icon="print" onClick={() => { this.handleTablePrint(handlePrint) }} style={{ fontSize: "23px", color: '#0b67bd', 'margin-left': '1em' }} />
                                )}
                            </PrintContextConsumer>
                        </ReactToPrint>
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <Icon aria-label="aria-menu" title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd', 'margin-left': '1em' }} />
                        </Dropdown>
                        <Icon aria-label="aria-menu" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={this.state.showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd', 'margin-left': '1em' }} />
                    </div>
                </div>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={isFetching}>
                    <div>
                        <div>
                            {this.state.showFilter &&
                                <div><FilterDrawer
                                    visible={this.state.showFilter}
                                    width="520"
                                    handleCloseFilterDrawer={this.handleCloseFilterDrawer}
                                    placement="right"
                                    style={{ position: 'absolute', zIndex: 9999, }}
                                    maskClosable={false}
                                    panelHeader="Filters and Export"
                                    formFields={formFields}
                                    handleClear={this.handleClear}
                                    handleFilter={this.handleFilter}
                                    timeZone={this?.props?.timeZoneName}
                                    hideSoftDeleteCheckbox={true}
                                    hideApplyToAll={true}
                                /></div>}
                        </div>
                        <NeoTable
                            cn={!this.state.showFilter && "top-spacing-2"}
                            columns={columns}
                            dataSource={requests}
                            size='small'
                            onChange={this.handleTableSortChange}

                        />
                        <Row
                            className="approval-pagination"
                        >
                            {<Pagination
                                alwaysShowPagination={false}
                                itemsPerPageLabel="Items per page: "
                                currentPageIndex={this.currentPage || 1}
                                id="templated-pagination"
                                itemCount={requestsCount || 0}
                                itemsPerPage={this.pageSize || 1}
                                itemsPerPageOptions={[5, 10, 15, 20]}
                                onItemsPerPageChange={(e, pageIndex) => this.onItemsPerPageChange(e, pageIndex)}
                                onPageChange={(e, pageIndex) => this.handlePagination(e, pageIndex)}
                            />}

                            <Row hidden={true}>
                                {this.state.printPreview && <PrintTable
                                    ref={el => (this.componentRef = el)}
                                    title={() => this.setTablePrintHeading()}
                                    className="responsive-container ant-table-wrapper"
                                    rowKey={(record) => record.id}
                                    columns={columns?.slice(0, -1)}
                                    dataSource={this.printData}
                                    bordered
                                    pagination={false}
                                />}
                            </Row>
                            {this.renderScheduleDetails()}
                        </Row>
                    </div>
                    {
                        capabilityModalOpen && <CapabilityRequestModal
                            visible={capabilityModalOpen}
                            handleCancel={this.handleModalClose}
                            isEdit={true}
                            data={capabilityData?.capability}
                            demo={capabilityData?.demo}
                            requestId={capabilityData?.id}
                            handlePostFeedbackSubmit={this.handlePostCapabilityRequestSubmit}
                            rawData={capabilityData}
                        />
                    }
                    {
                        registrationModalVisible &&
                        <DemoRegistrationModal
                            visible={registrationModalVisible}
                            handleDemoRequestSubmit={this.handleDemoRequestSubmit}
                            demo={demoRequestData?.demo}
                            isEdit={true}
                            rawData={demoRequestData}
                            handleCancel={this.handleDemoRequestModalClose}
                        />
                    }
                </Spin>
            </div >
        );
    }
}

const mapStateToProps = ({ myRequests, user, filterPanel, tabsLayout }) => {
    return {
        requests: myRequests?.data || [],
        requestsCount: myRequests?.count || 0,
        demos: filterPanel.allDemos,
        isFetching: myRequests?.isFetching,
        user: user.profile,
        timeZoneName: user.profile?.timezone,
        roleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
        isEdit: myRequests?.isEdit,
        currentFilter: myRequests?.currentFilter,
        pageNumber: myRequests?.pageNumber,
        pageSize: myRequests?.pageSize,
        status: myRequests?.status
    };
}

export default connect(
    mapStateToProps,
    {
        fetchMyRequests,
        fetchMyRequestsForExportPrint,
        toggleIsFetchingMyRequests,
        clearMyRequests,
        fetchAllExistingDemos,
        openLinkInNewTab,
        toggleBackButton,
        currentMyRequestsFilter,
        exportTable,
        deleteUserCapabilityRequest,
        deleteDemoSchedule,
        deleteUserRequest
    }
)(MyRequests);
import React, { useState } from 'react';
import { getDisplayTime } from '../../utils/status';
import { Popover, Row, Icon, Col } from 'antd';
import CONFIG from '../../config';
import { Button, Tooltip, Chip } from "@avaya/neo-react";

import { checkResourceAvailability } from '../../actions/dashboard';

const DemoStatusTagNeo = ({ demoResourceDCStatus, timezone, scheduleId, scheduleObject, handleReschedule, disabledReschedule, toggleIsReschedule, isActiveSchedules, toggleIsResourceReschedule, errorMessage, roleTemplate, showCheckAvailbility, showReschedule, handleUpdate }) => {
    const [loading, setloading] = useState(false);
    const [data, setData] = useState([]);
    let arr = [];
    const handleOnClick = () => {
        setloading(true);
        checkResourceAvailability(scheduleId)
            .then((response) => {
                arr[scheduleId] = response;
                setData(arr)
                setloading(false)
            })
    }
    const StatusRow = <Row className="bottom-spacing-3 font">
        {demoResourceDCStatus && <div className="vertical-spacing-3 font">
            <Chip variant={CONFIG.demoStatus.tagChips[demoResourceDCStatus.status].toLowerCase()} >{demoResourceDCStatus.dataCenter.title}</Chip>
            {getDisplayTime(demoResourceDCStatus.description, timezone)}
        </div>
        }
        {!(data[scheduleId]) && !showReschedule &&
            <>
                {showCheckAvailbility && <><p style={{ fontFamily: "Noto Sans", fontSize: "13px" }} className="vertical-spacing-3 font">{CONFIG.systemMessage.demoDCStatus}</p>
                    <Button aria-label="avail" type="primary" title={(roleTemplate?.templateType === CONFIG.roleTypes.superAdmin && errorMessage[0]?.includes("access")) ? CONFIG.warningMessages.solutionInactive : errorMessage?.length > 1 ? <>{errorMessage?.map((message, index) => (<div key={index}>&#8226;&nbsp;{message}</div>))}</> : errorMessage[0]} disabled={!disabledReschedule} onClick={handleOnClick} loading={loading} >Check Availability</Button>
                </>}</>
        }
        {
            data[scheduleId] &&
            <>
                {data[scheduleId]?.length !== 0 ? <p style={{ fontFamily: "Noto Sans", fontSize: "13px" }} className="vertical-spacing-3 font">{CONFIG.systemMessage.resourceAvailable}</p>
                    : <p style={{ fontFamily: "Noto Sans", fontSize: "13px" }} className="vertical-spacing-3 font">{CONFIG.systemMessage.resourceUnavailable}</p>
                }
                {data[scheduleId]?.map(item => {
                    return (
                        <div className="vertical-spacing-3 font">
                            <Chip variant={CONFIG.demoStatus.tagChips[item.status].toLowerCase()}>{item.dataCenter.title}</Chip>
                            {getDisplayTime(item.description, timezone)}
                        </div>
                    );
                })
                }
                {data[scheduleId]?.length !== 0 && <span style={{ fontSize: "13px" }} disabled={!disabledReschedule} className={disabledReschedule ? "link font" : "link-disabled font"} onClick={() => {
                    if (disabledReschedule) {
                        // !isActiveSchedules && toggleIsReschedule(true)
                        // toggleIsResourceReschedule(true)
                        handleUpdate(scheduleObject, CONFIG.demoTypes.standardDemo)
                    }
                }}>Click here to reschedule on different resource</span>}
            </>
        }

        {((data[scheduleId]?.length === 0 || data[scheduleId] === undefined) && showReschedule) && < Button aria-label="reschedule" type="primary" disabled={!disabledReschedule} onClick={() => {
            if (disabledReschedule) {
                !isActiveSchedules && toggleIsReschedule(true)
                toggleIsResourceReschedule(true)
                handleReschedule(scheduleId, scheduleObject)
            }
        }}>Reschedule</Button>
        }
    </Row >

    const StatusTag = <div className='dashboard-demo-status-row font'>
        {demoResourceDCStatus?.status !== CONFIG.demoStatus.dataCenterTypes.green
            ? <Popover className='font' content={StatusRow} placement="bottom" overlayStyle={{
                // width: "60vw",
                overflow: 'auto'
            }}>
                <Col>
                    <Chip variant={CONFIG.demoStatus.tagChips[demoResourceDCStatus?.status]}>{CONFIG.demoStatus.alertMessage[demoResourceDCStatus?.status]}</Chip>
                </Col>
            </Popover>
            : <Chip variant={CONFIG.demoStatus.tagChips[demoResourceDCStatus?.status]}> {CONFIG.demoStatus.alertMessage[demoResourceDCStatus?.status]}</Chip>
        }

    </div>
    return (
        !demoResourceDCStatus
            ? <div className='dashboard-demo-status-row font'>
                <Popover className='font' content={StatusRow} placement="right">
                    <Col><Chip variant="alert">{CONFIG.demoStatus.alertMessage[0]}</Chip></Col>
                </Popover>
                <Tooltip className='font' label={CONFIG.demoStatus.tooltipMessage[0]}>
                    <Icon aria-label="aria-menu" type="info-circle" className="left-spacing-6 text-color-primary" />
                </Tooltip>
            </div>
            : StatusTag
    )
}

export default DemoStatusTagNeo;
import React, { Component } from 'react';
import { connect, batch } from 'react-redux';
import { Table, Col, Divider, Popconfirm, Row, Tooltip, Dropdown, message, Typography } from "antd";
import moment from 'moment-timezone'
import scrollIntoView from 'scroll-into-view';
import { Icon as NeoIcon } from "@avaya/neo-react";
import CONFIG from '../../config';
import pushMessage from '../common/PushMessage';
import FilterPanel from '../common/FilterPanel';
import openConfirm from '../common/Confirm';
import { fetchNamesBySearchString } from '../../actions/userRole'
import {
    selectTitle,
    toggleBackButton,
    toggleIsFetchingReportFlag,
    updateCustomReportsCurrentPage,
    deleteReport,
    exportTable,
    restoreReport,
    clearReports,
    fetchUserCreatedReports,
    getAllUserCreatedReports,
    currentReportFilter
} from "../../actions";
import { exportMenu } from "../../utils/strings";
import { isTabAlreadyOpen } from "../common/TabLayout";
const dataIndexMap = {
    name: 'name',
    parentReport: 'parentReport.name',
    viewDeletedItems: 'viewDeletedItems',
    startCreatedTime: "startCreatedTime",
    endCreatedTime: "endCreatedTime",
    startUpdatedTime: "startUpdatedTime",
    endUpdatedTime: "endUpdatedTime",
    startLastRunTime: "startLastRunTime",
    endLastRunTime: "endLastRunTime",
    createdBy: "createdBy.fullName",
};

const { Text } = Typography
class UserCreatedReports extends Component {
    state = {
        matchedUsersNames: [],
        searchString: [],
        hasEnteredString: false,
        isReturnedFromDelete: false,
        deletedRecordIndex: undefined,
        userCreatedReportsData: [],
        showFilter: false,
    };
    currentPage = 1
    filterSort = {}
    tableRef = React.createRef()
    flag = 0
    clearCurrentUserCreatedReports = async () => {
        this.currentPage = 1
        this.setState({ userCreatedReportsData: [] })
    }
    async componentDidMount() {
        const { currentStatus, isEdit, currentFilter, currentPageNumber } = this.props;
        this.setState({ loading: true })
        this.setState({ currentPath: window.location.pathname })
        var tableContent = document.querySelector('.ant-table-body');
        tableContent && tableContent.addEventListener('scroll', (event) => {
            let maxScroll = event.target.scrollHeight - event.target.clientHeight
            let currentScroll = event.target.scrollTop
            if ((event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100) && (maxScroll !== 0 && currentScroll !== 0)) {
                this.flag += 1;
                this.flag === 1 && this.handleFetchUserCreatedReports()
            }
        })
        if (isEdit) {
            this.currentPage = currentPageNumber ?? 1;
            this.filterSort = currentFilter;
            if (this.filterSort?.filter !== undefined) this.setState({ showFilter: true })
            await this.setState({ status: currentStatus })
        }
        else {
            this.filterSort = {}
        }
        batch(() => {
            this.fetchCustomReportsOnMount();
            this.props.selectTitle("Manage User Created Reports");
            this.props.toggleIsFetchingReportFlag(true);
        })
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            const filterSort = {
                ...this.filterSort,
                sorter: this.state.sortedInfo ?? undefined
            }
            this.props.currentReportFilter(filterSort, this.state.status, false, this.currentPage, (this.currentPage * 12))
        }
        else {
            this.props.clearReports();
        }
    }


    componentDidUpdate = () => {
        const customReportToScrollIntoView = this.state.isReturnedFromDelete ? Math.abs(this.state.deletedRecordIndex - 1) : 0;
        this.state.isReturnedFromDelete
            && this.state.userCreatedReportsData?.length > 0
            && customReportToScrollIntoView
            && scrollIntoView(document.querySelector(`.scroll-row-${customReportToScrollIntoView}`), {
                align: { top: 0, left: 0 },
                behavior: "smooth"
            },
                this.setState({ isReturnedFromDelete: false })
            )
    }

    handleFetchUserCreatedReports = async (isFilter) => {
        const { userCreatedReportsCount } = this.props
        const countOfRecordsFetched = this.state.userCreatedReportsData?.length
        if ((userCreatedReportsCount === undefined && countOfRecordsFetched === undefined) || !(userCreatedReportsCount <= countOfRecordsFetched)) {
            this.setState({ loading: true });
            await this.props.fetchUserCreatedReports({ pageNumber: this.currentPage++, filterSort: this.filterSort })
                .then(() => {
                    this.setState(({ userCreatedReportsData }) => ({
                        loading: false,
                        userCreatedReportsData: this.props.userCreatedReports ? userCreatedReportsData?.concat(this.props.userCreatedReports) : [],
                    }))
                    this.flag = 0
                }).then(() => {
                    if (!isFilter) {
                        var scrollContainer = document.querySelector('.ant-table-body')
                        scrollContainer.scrollTop = scrollContainer?.scrollHeight - scrollContainer?.clientHeight - scrollContainer?.clientHeight;
                    }
                })
        }
    }

    fetchCustomReportsOnMount = async () => {
        const { pageSize } = this.props;
        this.setState({ loading: true })
        await this.props.fetchUserCreatedReports({
            pageNumber: (this.state.isReturnedFromDelete) ? 1 : this.currentPage++,
            pageSize: (this.state.isReturnedFromDelete) ? pageSize : CONFIG.lazyLoadPageSize,
            filterSort: this.filterSort,
        })
            .then(() => {
                this.setState(({ userCreatedReportsData }) => ({
                    loading: false,
                    userCreatedReportsData: this.props.userCreatedReports ? userCreatedReportsData?.concat(this.props.userCreatedReports) : [],
                }))
            })
    }

    handleSortChange = async (pagination, _, sorter) => {
        await this.clearCurrentUserCreatedReports()
        this.filterSort = {
            ...this.filterSort,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }
        this.handleFetchUserCreatedReports();
    }

    handleClear = async () => {
        await this.clearCurrentUserCreatedReports()
        this.filterSort = { ...this.filterSort, filter: undefined };
        await this.setState({ matchedUsersNames: [] });
        this.handleFetchUserCreatedReports();
    }

    getUTCTime = (time) => {
        const { timeZoneName } = this.props;
        return time.tz(timeZoneName, true).toISOString() || undefined
    }

    handleFilter = async (filters) => {
        let { name, parentReport, viewDeletedItems, createdTime, updatedTime, lastRunTime, createdBy, updatedBy, lastRunBy } = filters;
        name = name?.trim();
        parentReport = parentReport?.trim();
        createdBy = createdBy?.trim();
        updatedBy = updatedBy?.trim();
        lastRunBy = lastRunBy?.trim();
        const iscreatedTimeEmpty = !createdTime || (createdTime && createdTime.length === 0);
        const isupdatedTimeEmpty = !updatedTime || (updatedTime && updatedTime.length === 0);
        const islastRunTimeEmpty = !lastRunTime || (lastRunTime && lastRunTime.length === 0);
        if (!name && !parentReport && !viewDeletedItems && !createdBy && iscreatedTimeEmpty && isupdatedTimeEmpty && islastRunTimeEmpty && !lastRunBy && !updatedBy) {
            pushMessage(CONFIG.messageType.warning, "Please select some fields to filter")
            return;
        }
        this.filterSort = {
            filter: {
                [dataIndexMap.name]: name || undefined,
                [dataIndexMap.parentReport]: parentReport || undefined,
                [dataIndexMap.viewDeletedItems]: viewDeletedItems,
                [dataIndexMap.startCreatedTime]: !iscreatedTimeEmpty ? this.getUTCTime(createdTime[0]) : undefined,
                [dataIndexMap.endCreatedTime]: !iscreatedTimeEmpty ? this.getUTCTime(createdTime[1]) : undefined,
                [dataIndexMap.startUpdatedTime]: !isupdatedTimeEmpty ? this.getUTCTime(updatedTime[0]) : undefined,
                [dataIndexMap.endUpdatedTime]: !isupdatedTimeEmpty ? this.getUTCTime(updatedTime[1]) : undefined,
                [dataIndexMap.startLastRunTime]: !islastRunTimeEmpty ? this.getUTCTime(lastRunTime[0]) : undefined,
                [dataIndexMap.endLastRunTime]: !islastRunTimeEmpty ? this.getUTCTime(lastRunTime[1]) : undefined,
                [dataIndexMap.updatedBy]: updatedBy || undefined,
                [dataIndexMap.createdBy]: createdBy || undefined,
                [dataIndexMap.lastRunBy]: lastRunBy || undefined,
                updatedBy: updatedBy || undefined,
                createdBy: createdBy || undefined,
                lastRunBy: lastRunBy || undefined,
                [dataIndexMap.viewDeletedItems]: viewDeletedItems,
            }
        }

        this.setState({ matchedUsersNames: [] })
        await this.clearCurrentUserCreatedReports();
        this.handleFetchUserCreatedReports(true);
        scrollIntoView(document.querySelector(`.ant-table-thead`), {
            align: { top: 10, left: 0 },
            behavior: "smooth"
        })
    }


    fetchUsersName = searchString => {
        this.setState({ matchedUsersNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    this.setState({ matchedUsersNames: [...new Set(result.map(item => item.fullName))] })
                }
                this.setState({ hasEnteredString: false })
            });
    };

    handleDeleteConfirmation = async () => {
        const hardDelete = this.filterSort?.filter?.viewDeletedItems;
        const response = await this.props.deleteReport(this.reportId, hardDelete, true);
        if (response) {
            this.clearCurrentUserCreatedReports()
            const pageSize = ((Math.ceil(this.recordIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) + CONFIG.lazyLoadPageSize);
            const currentPageNumber = this.currentPage = ((Math.ceil(this.recordIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) / CONFIG.lazyLoadPageSize) + 1;
            await this.props.updateCustomReportsCurrentPage(currentPageNumber, pageSize, this.reportId, this.recordIndex)
            await this.setState({ isReturnedFromDelete: true, deletedRecordIndex: this.recordIndex })
            await this.fetchCustomReportsOnMount();
        }
    }

    handleRestore = async (reportId, index) => {
        const response = await this.props.restoreReport(reportId);
        if (response) {
            this.clearCurrentUserCreatedReports()
            const pageSize = ((Math.ceil(index / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) + CONFIG.lazyLoadPageSize);
            const currentPageNumber = this.currentPage = ((Math.ceil(index / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) / CONFIG.lazyLoadPageSize) + 1;
            await this.props.updateCustomReportsCurrentPage(currentPageNumber, pageSize, reportId, index)
            await this.setState({ isReturnedFromDelete: true, deletedRecordIndex: index })
            await this.fetchCustomReportsOnMount();
        }
    }

    handleDelete = (reportId, index) => {
        this.reportId = reportId;
        this.recordIndex = index
        const hardDelete = this.filterSort?.filter?.viewDeletedItems;
        if (this.props.userProfile?.isDeveloper && hardDelete) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
        else {
            const okButtonText = hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
            const content = hardDelete ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Report")}</h4> : <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "Report")}</h4>
            const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'report')
            openConfirm(okButtonText, this.handleDeleteConfirmation, null, content, modalTitle);
        }
    }


    handleTableExport = (fileType) => {
        const { timeZoneName } = this.props;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        getAllUserCreatedReports({ filterSort: this.filterSort })
            .then((reports) => exportTable(CONFIG.exportTable.userCreatedReports.name, reports, timeZoneName, CONFIG.exportTable.userCreatedReports.fileName + `.${fileType}`, fileType))
            .then(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.success, "Table exported successfully")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })

    }

    render() {
        const { userCreatedReportsCount, timeZoneName } = this.props;
        const { userCreatedReportsData, loading } = this.state
        const title = this.filterSort?.filter?.viewDeletedItems ? 'List of Deleted Reports' : 'List of All User Created Reports';
        const columns = [
            {
                title: 'No.',
                key: 'index',
                align: 'center',
                width: 70,
                render: (text, record, index) => index + 1,
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.name] ? "filtered-column" : ''}>Title</span>,
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                width: 250,
                align: 'left',
            },
            {
                title: 'Parent Report',
                dataIndex: 'parentReport',
                key: 'parentReport.name',
                sorter: true,
                // width: "25%",
                align: 'left',
                render: parentReport => parentReport?.name

            },
            {
                title: 'Created By',
                dataIndex: 'createdBy',
                key: 'createdBy.fullName',
                sorter: true,
                align: 'left',
                width: "20%",
                render: createdBy => createdBy?.fullName
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.startCreatedTime] ? "filtered-column" : ''}>Created Time</span>,
                dataIndex: 'createdTime',
                key: 'createdTime',
                sorter: true,
                align: 'center',
                // width: "20%",
                render: (publishTime) => moment.tz(publishTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)
            },
            {
                title: 'Updated Time',
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                sorter: true,
                align: 'center',
                // width: "20%",
                render: (updatedTime) => updatedTime !== null ? moment.tz(updatedTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"
            },
            {
                title: 'Last Run Time',
                dataIndex: 'lastRunTime',
                key: 'lastRunTime',
                sorter: true,
                align: 'center',
                // width: "20%",
                render: (lastRunTime) => lastRunTime !== null ? moment.tz(lastRunTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"
            },
            {
                title: 'Actions',
                key: 'actions',
                align: 'center',
                width: this.filterSort?.filter?.viewDeletedItems ? 200 : 120,
                render: (text, record, index) => {
                    const { userProfile } = this.props;
                    const { roleTemplate } = userProfile;
                    const deleteView = this.filterSort?.filter?.viewDeletedItems;
                    const isEditDisabled = roleTemplate && roleTemplate.templateType !== CONFIG.roleTypes.superAdmin && record?.parentReport?.adminAccess === CONFIG.roleTemplateAccess.readOnly;

                    return (
                        <React.Fragment>
                            <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                <>
                                    <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleDelete(record.id, index + 1)}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                                    {deleteView && <>
                                        <Divider type="vertical" />
                                        <Popconfirm disabled={isEditDisabled} title="Confirm restore?" onConfirm={() => this.handleRestore(record.id, index + 1)}>
                                            <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"}><span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span></button>
                                        </Popconfirm>
                                    </>
                                    }
                                </>
                            </Tooltip>
                        </React.Fragment>


                    )
                }
            },
        ];

        const formFields = [
            {
                label: "Report Name",
                type: CONFIG.formFieldTypes.INPUT,
                key: "name",
                value: this.filterSort?.filter?.name
            },
            {
                label: "Parent Report Name",
                type: CONFIG.formFieldTypes.INPUT,
                key: "parentReport",
                value: this.filterSort?.filter?.parentReport
            },
            {
                label: "Created By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "createdBy",
                data: this.state.matchedUsersNames,
                value: this.filterSort?.filter?.createdBy
            },
            {
                label: "Created Time",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "createdTime",
                value: [this.filterSort?.filter?.startCreatedTime, this.filterSort?.filter?.endCreatedTime],
            },
            {
                label: "Updated Time",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "updatedTime",
                value: [this.filterSort?.filter?.startUpdatedTime, this.filterSort?.filter?.endUpdatedTime]
            },
            {
                label: "Last Run Time",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "lastRunTime",
                value: [this.filterSort?.filter?.startLastRunTime, this.filterSort?.filter?.endLastRunTime]
            }
        ]
        const { showFilter } = this.state
        return (
            <React.Fragment>
                <Row className="vertical-spacing">
                    {/* for export icon */}
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <Dropdown overlay={exportMenu(this.handleTableExport)} trigger="click">
                            <NeoIcon aria-label="menu-aria" title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </Dropdown>
                    </Col>
                    {/* for refresh icon */}
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                    {/* for filter icon */}
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                </Row>
                <div className="content-container responsive-container">

                    <Row>
                        {this.state.showFilter &&
                            <FilterPanel
                                label='View Deleted Reports'
                                formFields={formFields}
                                handleClear={this.handleClear}
                                handleFilter={this.handleFilter}
                                getSearchData={this.fetchUsersName}
                                hideApplyToAll={true}
                                panelHeader="Filters and Export"
                                timeZone={timeZoneName}
                                viewDeletedItemsValue={this.filterSort?.filter?.viewDeletedItems}
                            />}
                    </Row>
                    <br />
                    <Row>
                        <h3>{title}</h3>
                        <div ref={this.tableRef}>
                            <Table
                                size='middle'
                                className="responsive-container"
                                rowClassName={(record, index) => (`scroll-row-${index + 1}`)}
                                scrollToFirstRowOnChange={true}
                                rowKey={(record) => record.id}
                                bordered
                                scroll={{ y: 550, scrollToFirstRowOnChange: false, x: true | 1500 }}
                                dataSource={userCreatedReportsData}
                                columns={columns}
                                loading={loading}
                                onChange={this.handleSortChange}
                                pagination={false}
                                footer={() => <>{userCreatedReportsData?.length !== 0 && <div style={{ textAlign: 'center' }}>
                                    <Text strong>Fetched {userCreatedReportsData?.length} out of {userCreatedReportsCount} User Created Reports</Text>
                                </div>}
                                </>}
                            />
                        </div>
                    </Row>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ reports, userCreatedReports, user, tabsLayout }) => {
    return {
        userProfile: user.profile,
        timeZoneName: user.profile.timezone,
        currentStatus: userCreatedReports.status,
        currentFilter: userCreatedReports.currentFilter,
        isEdit: userCreatedReports.isEditUser,
        currentPageNumber: userCreatedReports.currentPageNumber,
        pageSize: userCreatedReports.pageSize,
        customReportIndex: userCreatedReports.customReportIndex,
        userCreatedReportsCount: userCreatedReports.count,
        userCreatedReports: userCreatedReports.data,
        openedTabs: tabsLayout.panes
    }
}

export default connect(mapStateToProps, {
    selectTitle,
    toggleBackButton,
    toggleIsFetchingReportFlag,
    updateCustomReportsCurrentPage,
    deleteReport,
    exportTable,
    restoreReport,
    clearReports,
    fetchUserCreatedReports,
    getAllUserCreatedReports,
    currentReportFilter
})(UserCreatedReports);
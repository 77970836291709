import React from "react";
import { Table, Form, Button, Divider, Icon, Row, Col, DatePicker, Drawer, Dropdown, message, Tooltip, Collapse, Select } from "antd";
import {
    toggleBackButton,
    selectTitle,
    getAllDailyReport,
    toggleIsFetchingDailyReports,
    clearReportForm,
    clearDailyReport,
    deleteDailyReport,
    getColAttributeMapping,
    getNotTestedProducts,
    exportTable,
    currentDailyReportFilter,
    refreshDailyReport,
    handleOnClickAction,
} from "../../actions";
import { connect, batch } from "react-redux";
import pushMessage from '../common/PushMessage';
import openConfirm from '../common/Confirm';
import CONFIG from '../../config';
import moment from 'moment-timezone';
import { exportMenu, formatTime } from "../../utils/strings";
import { getDateTime } from '../../utils/datepicker';
import { computeTime } from '../../utils/scheduler';
import { Icon as NeoIcon } from "@avaya/neo-react";
import { isTabAlreadyOpen } from "../common/TabLayout";
const { Panel } = Collapse;
const { Option } = Select;
export const ManageDailyReportContext = React.createContext();

class ManageDailyReports extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            date: moment(moment()).tz("UTC").format("YYYY-MM-DD"),
            sortedInfo: null,
            isViewDeleted: false,
            isModalOpen: false,
            colAtrObj: [],
            timeZone: "UTC"
        }

        this.tableRef = React.createRef();
    }


    async componentDidMount() {
        let startTime = moment.tz(moment(), this.state.timeZone).startOf('day').toISOString();
        let endTime = moment.tz(moment(), this.state.timeZone).endOf('day').toISOString();
        const { date, sortInfo, timeZone } = this.props;
        if (date) {
            await this.setState({
                date: date,
                sortInfo: sortInfo || null,
                timeZone: timeZone
            })
            const [start, end] = computeTime("day", null, this.state.date, this.state.timeZone);
            startTime = start;
            endTime = end;
        }
        this.setState({ currentPath: window.location.pathname })
        batch(async () => {
            this.props.toggleBackButton(false);
            this.props.selectTitle('Manage Daily Reports');
            this.props.toggleIsFetchingDailyReports(true);
            await this.props.getAllDailyReport(startTime, endTime);
            await this.props.getNotTestedProducts(startTime, endTime);
        });
    }

    handleRefresh = async () => {
        const [start, end] = computeTime("day", null, this.state.date, this.state.timeZone);
        await this.props.refreshDailyReport();
        this.props.toggleIsFetchingDailyReports(true);
        await this.props.getAllDailyReport(start, end);
        await this.props.getNotTestedProducts(start, end);
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentDailyReportFilter(this.state.date, this.state.sortedInfo, this.state.timeZone);
            this.props.refreshDailyReport();
        } else {
            this.props.clearDailyReport();
        }
    }

    handleDateChange = (value, dateString) => {
        const [start, end] = computeTime("day", value, this.state.date, this.state.timeZone)
        if (value) {
            this.setState({ date: dateString }, async () => {
                this.props.toggleIsFetchingDailyReports(true);
                await this.props.getAllDailyReport(start, end);
                await this.props.getNotTestedProducts(start, end);
            });
        }
    }

    handleTimeZoneChange = async (timeZone) => {
        await this.setState({ timeZone: timeZone });
        const [start, end] = computeTime("day", null, this.state.date, timeZone)
        this.props.toggleIsFetchingDailyReports(true);
        await this.props.getAllDailyReport(start, end);
        await this.props.getNotTestedProducts(start, end);
    }

    handleChange = (_, __, sorter) => {
        this.setState({
            sortedInfo: sorter
        });
    }

    handleDelete = (id) => {
        if (this.props.loggedInUser?.developerMode) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
        else {
            const modalTitle = `Would you like to delete this Report?`;
            openConfirm("Yes", () => this.handleConfirmDelete(id), null, null, modalTitle, null, "No");
        }
    }

    handleConfirmDelete = async (id) => {
        const [start, end] = computeTime("day", null, this.state.date, this.state.timeZone)
        // const newDate = moment.tz(this.state.date, this.props.timeZoneName).utc().format("YYYY-MM-DD");
        await this.props.deleteDailyReport(id);
        this.props.toggleIsFetchingDailyReports(true);
        await this.props.getAllDailyReport(start, end);
        await this.props.getNotTestedProducts(start, end);
    }

    getAttributeNameStatus(record) {
        const mapObj = this.props.colAttributeMap;
        const data = []
        Object.keys(mapObj).forEach((key) => {
            Object.keys(record).forEach((key1) => {
                if (key === key1 && record[key1] !== null) {
                    return record?.productTestingIssues?.forEach((issue) => {
                        if (issue?.attribute?.dataColumn === key) {
                            let tempObj = {
                                attribute: mapObj[key],
                                value: record[key],
                                description: issue?.issueDescription,
                                link: issue?.link,
                                product: record?.product,
                                createdTime: record?.createdTime,
                                createdBy: record?.createdBy,
                                dataColumn: issue?.attribute?.dataColumn,
                                status: record?.status,
                                outageHours: record?.outageHours,
                                demoDCStatusDescription: record?.demoDCStatusDescription,
                                location: record?.location
                            }
                            data.push(tempObj)
                        }
                    })
                }
            })
        });
        return data;
    }

    getWorkingData = (record, data) => {
        const oneMoreData = [];
        const mapObj = this.props.colAttributeMap;
        const actualObjlen = Object.keys(mapObj)?.length;
        const dataLen = data?.length;
        if (actualObjlen !== dataLen) {
            Object.keys(mapObj).forEach((key) => {
                const isKeyNotFound = data?.find(item => item?.dataColumn === key);
                if (isKeyNotFound === undefined && record[key] !== null) {
                    let newObj = {
                        attribute: mapObj[key],
                        value: record[key],
                        description: null,
                        link: null,
                        product: record?.product,
                        createdTime: record?.createdTime,
                        createdBy: record?.createdBy,
                        dataColumn: key,
                        status: record?.status,
                        outageHours: record?.outageHours,
                        demoDCStatusDescription: record?.demoDCStatusDescription,
                        location: record?.location
                    }
                    oneMoreData.push(newObj)
                }
            })
        }
        return oneMoreData;
    }

    handleDisplayModal = (record) => {
        this.setState({ isModalOpen: true, colAtrObj: record });
    }

    handleCancel = () => {
        this.setState({ isModalOpen: false })
    }

    handleExport = async (fileType) => {
        const dailyReportdata = this.props.data;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        if (dailyReportdata?.length > 0 && dailyReportdata !== undefined) {
            exportTable(CONFIG.exportTable.dailyTestReport.name, dailyReportdata, this.state.timeZone, CONFIG.exportTable.dailyTestReport.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    renderTimeZoneOptions = () => {
        const timezones = moment.tz.names();
        return timezones.map((timezone, index) => {
            return <Option key={index} value={timezone}>{timezone}</Option>;
        });
    }

    render() {
        const dateFormat = 'YYYY-MM-DD';
        const tableData = this.props.data;
        const { loggedInUser, untestedProduct } = this.props;
        const { loading } = this.props;
        const { userRoleTemplate } = this.props;
        const timeZoneName = this.state.timeZone;
        let { sortedInfo, colAtrObj, date } = this.state;
        const data = colAtrObj || [];
        const columns = [

            {
                title: <span >Title</span>,
                dataIndex: "product",
                align: 'left',
                key: "product",
                width: 250,
                sorter: (a, b) => a.product.title.toLowerCase().localeCompare(b.product.title.toLowerCase()),
                sortOrder: sortedInfo?.columnKey === 'product' && sortedInfo.order,
                render: (record) => {
                    return (
                        <span>{record?.title} <span>
                            {!record?.isActive && <Icon aria-label="aria-menu" type={CONFIG.reportIconType.icons[0]} className={CONFIG.reportIconType.colors[0]} title="Inactive" />}
                        </span> </span>
                    )
                }
            },
            {
                title: <span >Status</span>,
                key: "status",
                align: 'center',
                width: 80,
                render: record => {
                    const value = record.status === 0 ? "Not Working" : record.status === 1 ? "Partially Working" : record.status === 2 ? "Working" : record.status === 3 ? "Not Tested" : undefined;
                    return <Icon aria-label="aria-menu" type={CONFIG.reportIconType.icons[record.status]} className={CONFIG.reportIconType.colors[record.status]} title={value} style={{ fontSize: "20px" }} />;
                },
            },
            {
                title: <span >Test Engineer</span>,
                key: "createdBy",
                align: 'center',
                width: 150,
                render: record => {
                    return (
                        record.createdBy.firstName + " " + record.createdBy.lastName
                    );
                },
            },
            {
                title: <span >Location/Resource</span>,
                key: "location",
                align: 'center',
                width: 170,
                render: record => record?.location || "-"
            },
            {
                title: <span >Created Time (UTC)</span>,
                key: "createdTime",
                align: 'center',
                width: 180,
                render: (record) => record?.createdTime ? moment.tz(record.createdTime, this.state.timeZone).format(CONFIG.dateFormats.userDateTime) : '-'
            },
            {
                title: <span >Outage
                </span>,
                key: "outageHours",
                align: 'center',
                width: 150,
                render: (record) => record?.outageHours ? formatTime(record?.outageHours) || '-' : '-'
            },
            {
                title: <span >Active Issues</span>,
                dataIndex: "activeIssueDescription",
                key: "activeIssueDescription",
                align: 'left',
                width: 300,
                render: (record) => {
                    const issues = record?.split("\\") || "";
                    return (
                        <>
                            {
                                issues?.length > 0 ? issues?.map((item) => {
                                    const column = item?.substr(0, item?.indexOf("-"));
                                    const filteredColumn = item?.replace(column, "")
                                    return <div><span style={{ fontWeight: 500, fontFamily: 'Noto Sans', }}>{column}</span><span style={{ fontWeight: 200, fontFamily: 'Noto Sans', }}>{filteredColumn}</span></div>
                                })
                                    : "-"
                            }
                        </>
                    )
                }
            },
            {
                title: <span >Demo Status</span>,
                key: "demoStatus",
                align: 'center',
                width: 120,
                render: record => {
                    const description = record?.demoDCStatusDescription;
                    const title = (description?.length > 0 && description !== null) && record?.product?.demoStatus !== null ?
                        description?.map((eachItem) => {
                            return (
                                <div>
                                    <b>{eachItem?.dataCenterTitle}</b> - {eachItem?.demoDCDescription}
                                </div>
                            )
                        })
                        : null

                    return record?.product?.demoStatus !== null ?
                        <Tooltip title={title} >
                            <Icon aria-label="aria-menu" type={CONFIG.demoStatus.icons[record?.product?.demoStatus]} className={CONFIG.demoStatus.colors[record?.product?.demoStatus]} style={{ fontSize: "20px" }} />
                        </Tooltip>
                        : "-";
                },
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 130,
                render: (record) => {
                    const isSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
                    const isEditDeleteEnabled = ((record.createdBy?.id === loggedInUser?.id || isSuperAdmin) && date === moment.tz(moment(), this.state.timeZone).format(dateFormat)) ? true : false;
                    const isProductActive = record?.product?.isActive;
                    return (
                        <React.Fragment>
                            <>
                                <button disabled={(!isEditDeleteEnabled || !isProductActive)}
                                    onClick={async () => {
                                        await this.props.handleOnClickAction({
                                            component: CONFIG.editComponentRoute.dailyReport,
                                            tabTitle: record?.product.title,
                                            recordId: record?.id
                                        }
                                        )
                                    }}
                                    className={(isEditDeleteEnabled && isProductActive) ? "link" : "link-disabled"}>
                                    <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span></button>
                                <Divider type="vertical" />
                                < button
                                    disabled={!isEditDeleteEnabled}
                                    onClick={() => this.handleDelete(record.id)}
                                    className={isEditDeleteEnabled ? "link" : "link-disabled"}
                                >
                                    <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span>
                                </button >
                                <Divider type="vertical" />
                                < button
                                    onClick={() => this.handleDisplayModal(record)}
                                    className="link"
                                >
                                    <span class="neo-icon-list-bullet" title="View Details" style={{ fontSize: "20px" }}></span>
                                </button >
                            </>
                        </React.Fragment>

                    );
                }
            }
        ];

        const drawerColumns = [
            {
                title: <span className="thin-bold">Attribute</span>,
                dataIndex: 'attribute',
                key: 'attribute.attributeName',
                align: 'left',
                width: 100,
                render: (attribute) => <span className="thin">{attribute?.attributeName}</span>
            },
            {
                title: <span className="thin-bold">Status</span>,
                dataIndex: 'attributeStatus',
                key: 'attributeStatus',
                width: 75,
                align: 'center',
                render: (attributeStatus) => <Icon aria-label="aria-menu" type={CONFIG.reportViewDetailsMap.icons[CONFIG.reportStausCode[attributeStatus]]} className={CONFIG.reportViewDetailsMap.colors[CONFIG.reportStausCode[attributeStatus]]} title={attributeStatus} />
            },
            {
                title: <span className="thin-bold">Description</span>,
                dataIndex: 'issueDescription',
                width: 250,
                key: 'issueDescription',
                align: 'left',
                render: (issueDescription) => <span className="thin">{issueDescription || "-"}</span>
            },
            {
                title: <span className="thin-bold">Ticket No. / URL</span>,
                dataIndex: 'link',
                key: 'link',
                width: 100,
                align: 'center',
                render: (link) => link && <Tooltip placement="topRight" mouseLeaveDelay={1} title={link}><a href={link} target="_blank" rel="noopener noreferrer" title={link}><Icon aria-label="aria-menu" type="link" className="link" /></a></Tooltip>
            }
        ]
        const description = data?.demoDCStatusDescription;
        const title = (description?.length > 0 && description !== null) && data?.product?.demoStatus !== null ?
            description?.map((eachItem) => {
                return (
                    <div>
                        <b>{eachItem?.dataCenterTitle}</b> - {eachItem?.demoDCDescription}
                    </div>
                )
            })
            : null

        return (
            < React.Fragment >
                <Row>
                    <Col xl={4} sm={6} xs={24}>
                        <DatePicker allowClear={false} defaultValue={getDateTime({ convertTimeZone: true, timeZone: timeZoneName })} value={moment(this.state.date).tz(timeZoneName, true)} format={dateFormat} onChange={this.handleDateChange} />
                    </Col>
                    <Col xl={2} sm={6} xs={24} className="left-spacing">
                        <Button className="primary-action-button-filled"
                            onClick={async () => {
                                await this.props.handleOnClickAction({
                                    component: CONFIG.createComponentRoute.dailyReport,
                                    tabTitle: "Create"
                                })
                            }}
                        >
                            New
                            <Icon aria-label="aria-menu" type="plus-circle" />
                        </Button>
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        {
                            <Dropdown overlay={exportMenu(this.handleExport)}>
                                <NeoIcon aria-label="menu-aria" title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
                            </Dropdown>
                        }
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" title="Refresh" onClick={() => this.handleRefresh()} icon="refresh" style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                </Row>
                {
                    <>
                        <div className="responsive-container content-container">
                            {
                                untestedProduct && untestedProduct?.length > 0 &&
                                <div>
                                    <Collapse bordered={false}>
                                        <Panel header={<span><Icon aria-label="aria-menu" type="exclamation-circle" className="warning-text" /><span style={{ margin: "1%" }}>List of Not Tested</span></span>}>
                                            <div className="two-columns">
                                                <ul>
                                                    {untestedProduct?.map((item) => {
                                                        return <li>{item?.title}</li>
                                                    })}
                                                </ul>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </div>
                            }
                            <h3>Daily Testing Reports</h3>
                            <ManageDailyReportContext.Provider value={this.props.form}>
                                <Table
                                    rowClassName={(record, index) => (`scroll-row-${index + 1}`)}
                                    rowKey={(record) => record.id}
                                    className="responsive-container"
                                    size="small"
                                    bordered
                                    loading={loading}
                                    dataSource={tableData}
                                    columns={columns}
                                    onChange={this.handleChange}
                                    pagination={false}
                                    scroll={{ y: 550, scrollToFirstRowOnChange: false, x: true | 1000 }}

                                />
                            </ManageDailyReportContext.Provider>
                        </div>
                    </>

                }
                <div className="daily-report-modal">

                    {this.state.isModalOpen &&

                        <Drawer
                            title={<span className="daily-drawer-title">Report Details - {data?.product?.title}</span>}
                            width={650}
                            placement={"right"}
                            closable={false}
                            onClose={this.handleCancel}
                            visible={this.state.isModalOpen}
                        >
                            <div >
                                <div className="supportLinks">
                                    <div className="margin-ten">
                                        <span className="semi-bold">Product / Demo:</span> <span className="daily-report-drawer-table">
                                            {data?.product?.title}
                                            <span> {!data?.product?.isActive && <Icon aria-label="aria-menu" type={CONFIG.reportIconType.icons[0]} className={CONFIG.reportIconType.colors[0]} title="Inactive" />}</span>
                                        </span>
                                    </div>
                                    <div className="margin-ten">
                                        <span className="semi-bold">Test Engineer:</span> <span className="daily-report-drawer-table">{data?.createdBy?.fullName}</span>
                                    </div>
                                    <div className="margin-ten">
                                        <span className="semi-bold">Location / Resource:</span> <span className="daily-report-drawer-table">{data?.location || "-"}</span>
                                    </div>
                                    <div className="margin-ten">
                                        <span className="semi-bold">Created Time (UTC):</span><span className="daily-report-drawer-table">{moment.tz(data.createdTime, this.state.timeZone).format(CONFIG.dateFormats.userDateTime) || '-'}</span>
                                    </div>
                                    <div className="margin-ten">
                                        <span className="semi-bold">Overall Status:</span> <span className="daily-report-drawer-table">{data.status === 0 ? "Not Working" : data.status === 1 ? "Partially Working" : data.status === 2 ? "Working" : data?.status === 3 ? "Not Tested" : undefined}</span> <Icon aria-label="aria-menu" type={CONFIG.reportIconType.icons[data?.status]} className={CONFIG.reportIconType.colors[data?.status]} style={{ fontSize: "20px" }} />
                                    </div>
                                    <div className="margin-ten">
                                        <span className="semi-bold">Outage:</span> <span>{formatTime(data?.outageHours) || '-'}</span>
                                    </div>
                                    <div className="margin-ten">
                                        <span className="semi-bold">Demo Status:</span> <span>
                                            {data?.product?.demoStatus !== null ?
                                                <Tooltip title={title}>
                                                    < Icon type={CONFIG.demoStatus.icons[data?.product?.demoStatus]} className={CONFIG.demoStatus.colors[data?.product?.demoStatus]} style={{ fontSize: "18px", marginLeft: "5px" }} />
                                                </Tooltip>
                                                : "-"}
                                        </span>
                                    </div>
                                </div>
                                <div style={{ marginTop: "20px", width: "100%" }}>
                                    <Table
                                        className="responsive-container"
                                        size="small"
                                        dataSource={colAtrObj?.testingDataAttributes || []}
                                        columns={drawerColumns}
                                        bordered
                                        pagination={false}
                                        scroll={{ y: 350, scrollToFirstRowOnChange: false }}
                                    />
                                </div>
                            </div>
                        </Drawer>
                    }
                </div >
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ user, dailyReport, tabsLayout }) => {
    return {
        data: dailyReport.data,
        loading: dailyReport.loading,
        colAttributeMap: dailyReport.colAttributeMap,
        loggedInUser: user.profile,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        untestedProduct: dailyReport.untestedProduct,
        sortInfo: dailyReport.sortInfo,
        date: dailyReport.date,
        timeZone: dailyReport.timeZone,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey
    };
};

const EditableFormTable = Form.create()(ManageDailyReports);

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        selectTitle,
        getAllDailyReport,
        toggleIsFetchingDailyReports,
        clearReportForm,
        clearDailyReport,
        deleteDailyReport,
        getColAttributeMapping,
        getNotTestedProducts,
        currentDailyReportFilter,
        refreshDailyReport,
        handleOnClickAction,
    }
)(EditableFormTable);





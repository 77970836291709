import React from "react";
import { Modal, Tooltip } from "antd";
import { Table, Button as NeoButton, Icon, Icon as NeoIcon } from "neo-latest";
import { resendNotificationToAccountOwner, sendSFDCReminderToAll, openLinkInNewTab, fetchSFDCStatsData, currentSFDCStatsFilter } from "../../actions";
import { connect } from "react-redux";
import moment from "moment-timezone";
import CONFIG from "../../config";
import openConfirm from "../common/Confirm";
import ROUTE from "../../components/ContentRouteConfig";

class ViewSFDCDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerVisible: false
    };
  }

  renderSFDCDetails = () => {
    const { data, timeZoneName } = this.props;
    const SFDCStatusColumns = [
      {
        Cell: (a) => {
          return (
            <span style={{ textAlign: "center" }}>
              {a?.cell?.row?.original?.demo?.title}
            </span>
          );
        },
        Header: "Demo Title",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Cell: (a) => {
          return (
            <span style={{ textAlign: "center" }}>
              {a?.cell?.row?.original?.startTime
                ? moment
                  .tz(a?.cell?.row?.original?.startTime, timeZoneName)
                  .format(CONFIG.dateFormats.userDateTime)
                : "-"}
            </span>
          );
        },
        Header: "Start Time",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Cell: (a) => {
          return (
            <span style={{ textAlign: "center" }}>
              {a?.cell?.row?.original?.endTime
                ? moment
                  .tz(a?.cell?.row?.original?.endTime, timeZoneName)
                  .format(CONFIG.dateFormats.userDateTime)
                : "-"}
            </span>
          );
        },
        Header: "End Time",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Cell: (a) => {
          return (
            <span style={{ textAlign: "center" }}>
              {a?.cell?.row?.original?.createdBy?.fullName}
            </span>
          );
        },
        Header: "Created By",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Cell: (a) => {
          return (
            <span style={{ textAlign: "center" }}>
              {a?.cell?.row?.original?.opportunity?.accountOwnerEmail}
            </span>
          );
        },
        Header: "Owner",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Cell: (a) => {
          const record = a?.cell?.row?.original;
          const isAccountOwnerEmail =
            a?.cell?.row?.original?.opportunity?.accountOwnerEmail === null;
          return isAccountOwnerEmail ? null : (
            <React.Fragment>
              <div
                style={{ display: "flex", alignItems: "center", gap: "24px" }}
              >
                <Tooltip title="Edit">
                  <NeoIcon aria-label="menu-aria"
                    size="md"
                    style={{ cursor: "pointer", color: "#1B77AF", zIndex: "1" }}
                    icon="edit"
                    onClick={() => this.handleEditRecord(record)} />
                </Tooltip>

                <Tooltip title="Send Reminder">
                  <NeoIcon aria-label="menu-aria"
                    size="md"
                    style={{ cursor: "pointer", color: "#1B77AF" }}
                    icon="email-forwarded"
                    onClick={() => {
                      resendNotificationToAccountOwner(record?.id);
                    }}
                  />
                </Tooltip>
              </div>
            </React.Fragment>
          );
        },
        Header: "  ",
        disableFilters: true,
        disableSortBy: true,
      },
    ];
    return (
      <>
        {data && data?.length > 0 ? (
          <Table
            columns={SFDCStatusColumns}
            data={data}
            showRowSeparator={true}
            rowHeight="compact"
            showPagination={true}
          />
        ) : (
          <div className="dashboard-no-schedules">
            <Icon aria-label="aria-menu" icon="info" size="md" />
            No data
          </div>
        )}
      </>
    );
  };

  handleEditRecord = async (record) => {
    const { status } = this.props;
    await this.props.currentSFDCStatsFilter({ filter: {} }, status === "Pending" ? "pending" : "timedOut", 0, true, record)
    await this.props.openLinkInNewTab("Schedule Opportunities", ROUTE.sfdcStats);
    this.setState({ isDrawerVisible: true });
  }

  reDirectToManageSection = async () => {
    const { status } = this.props;
    await this.props.currentSFDCStatsFilter({ filter: {} }, status === "Pending" ? "pending" : "timedOut", 0)
    await this.props.openLinkInNewTab("Schedule Opportunities", ROUTE.sfdcStats);
  }

  renderFooterButtons = () => {
    const { status } = this.props;
    return (
      <React.Fragment>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <NeoButton key="Confirm" onClick={() => this.reDirectToManageSection()} variant="tertiary">
            View all
          </NeoButton>
          <NeoButton onClick={() => {
            const modalTitle = `Would you like to notify all the below account owners ? `;
            openConfirm("Notify", () => this.props.sendSFDCReminderToAll(status && status === "Pending" ? "pending" : "timedOut"), null, null, modalTitle, null, "Cancel");
          }} className="remove-btn-index" icon="email" variant="secondary">
            Notify Everyone
          </NeoButton>
          <NeoButton key="Close" onClick={() => this.props.handleModalClose()}>
            Close
          </NeoButton>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { isModalVisible, handleModalClose, status } = this.props;
    const modalTitle = <span className="modal-title">{status}</span>;

    return (
      <div className="dashboard-demo-stats-modal">
        <div>
          <Modal
            title={modalTitle}
            visible={isModalVisible}
            className="demo-stats-view-details"
            wrapClassName="dashboard-demo-stats-modal-wrap"
            bodyStyle={{
              maxHeight: `${document.body.clientHeight - 100}px`,
              overflowY: "auto",
            }}
            onCancel={handleModalClose}
            footer={this.renderFooterButtons()}
          >
            {this.renderSFDCDetails()}
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    timeZoneName: user.profile ? user?.profile?.timezone : undefined,
  };
};

export default connect(mapStateToProps, {
  openLinkInNewTab,
  fetchSFDCStatsData,
  sendSFDCReminderToAll,
  currentSFDCStatsFilter
})(ViewSFDCDetailsModal);

import React from 'react';
import { Table, Typography, Row, message, Dropdown, Col } from 'antd';
import { connect, batch } from 'react-redux';
import { fetchEmailTemplates, toggleBackButton, selectTitle, fetchEmailTypeCategories, toggleIsFetchingEmailTemplateFlag, clearStandardEmailTemplates, currentEmailtemplateFilter, handleOnClickAction } from '../../actions';
import scrollIntoView from 'scroll-into-view';
import FilterPanel from '../common/FilterPanel';
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config.js';
import { fetchNamesBySearchString } from '../../actions/userRole';
import { exportEmailTemplates } from "../../actions/emailTemplates";
import { Icon as NeoIcon, AppLayout, LeftNav } from "@avaya/neo-react";
import { isTabAlreadyOpen } from "../common/TabLayout";
import { exportMenu } from "../../utils/strings";
import throttle from 'lodash/throttle';

const { Text } = Typography

const dataIndexMap = {
    title: "title",
    description: "description",
    emailCategory: "emailCategory"
}
class ManageStandardEmailTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.handleClear = throttle(this.handleClear, 1000);
    }

    state = {
        emailTemplatesData: [],
        loading: false,
        isReturnedFromEdit: false,
        matchedUsersNames: [],
        selectedCategory: undefined,
        showFilter: false,
    }

    currentPage = 1
    filterSort = {};
    tableRef = React.createRef()
    flag = 0

    handleFetchEmailTemplates = () => {
        const { emailTemplatesCount } = this.props
        const countOfRecordsFetched = this.state.emailTemplatesData?.length
        if ((emailTemplatesCount === undefined && countOfRecordsFetched === undefined) || !(emailTemplatesCount <= countOfRecordsFetched)) {
            this.setState({ loading: true });
            this.props.fetchEmailTemplates({ pageNumber: this.currentPage++, filterSort: this.filterSort, type: 'PROCESS' })
                .then(() => {
                    setTimeout(() => {
                        this.setState(({ emailTemplatesData }) => ({
                            loading: false,
                            emailTemplatesData: this.props.emailTemplates ? emailTemplatesData?.concat(this.props.emailTemplates) : [],
                        }))
                    }, 100)
                    this.flag = 0
                }).then(() => {
                    var scrollContainer = document.querySelector('.ant-table-body')
                    scrollContainer.scrollTop = scrollContainer?.scrollHeight - scrollContainer?.clientHeight - scrollContainer?.clientHeight;
                })
        }
    }

    handleFilter = async (filters) => {
        let { title, description } = filters;
        title = title?.trim();
        description = description?.trim();

        if (!title && !description) {
            pushMessage(CONFIG.messageType.warning, `Please select some fields to filter`)
            return;
        }
        await this.clearCurrentEmailTemplates()
        this.filterSort = {
            ...this.filterSort,
            filter: {
                [dataIndexMap.title]: title || undefined,
                [dataIndexMap.description]: description || undefined,
            }
        }

        this.handleFetchEmailTemplates();
        this.tableRef.current.scrollIntoView({
            behavior: 'smooth',
        })
    }

    handleClear = async (isRefresh) => {
        await this.clearCurrentEmailTemplates();
        if (!isRefresh) {
            this.filterSort = { ...this.filterSort, filter: { emailCategory: this.state.selectedCategory } };
        }
        this.handleFetchEmailTemplates();
    }

    clearCurrentEmailTemplates = async () => {
        this.currentPage = 1
        this.setState({ emailTemplatesData: [] })
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            const nextPageNumber = this.currentPage > 2 ? this.currentPage - 1 : this.currentPage;
            this.props.currentEmailtemplateFilter("standard", this.filterSort, nextPageNumber, (nextPageNumber * 12))
        }
        else {
            this.props.clearStandardEmailTemplates();
        }
    }

    componentDidUpdate = () => {
        const emailTemplateToScrollIntoView = this.state.isReturnedFromEdit
            ? this.props.resourceIndex
            : 0;

        (this.state.isReturnedFromEdit)
            && this.state.emailTemplatesData?.length > 0
            && emailTemplateToScrollIntoView
            && scrollIntoView(document.querySelector(`.scroll-row-${emailTemplateToScrollIntoView}`), {
                align: { top: 0, left: 0 },
                behavior: "smooth"
            },
                this.setState({ isReturnedFromEdit: false })
            )
    }

    fetchEmailTemplatesOnMount = async () => {
        const { isEdit, pageSize } = this.props;
        this.setState({ loading: true })
        await this.props.fetchEmailTemplates({
            pageNumber: isEdit ? 1 : this.currentPage++,
            pageSize: isEdit ? pageSize : CONFIG.lazyLoadPageSize,
            filterSort: this.filterSort,
            type: 'PROCESS'
        })
        setTimeout(() => {
            this.setState(({ emailTemplatesData }) => ({
                loading: false,
                emailTemplatesData: this.props.emailTemplates ? emailTemplatesData?.concat(this.props.emailTemplates) : [],
                isReturnedFromEdit: isEdit ? true : false,
            }))
        }, 100)
    }

    async componentDidMount() {
        const { isEdit, emailTemplatesFilter, currentPageNumber } = this.props;
        this.setState({ loading: true, currentPath: window.location.pathname })

        var tableContent = document.querySelector('.ant-table-body');
        tableContent && tableContent.addEventListener('scroll', (event) => {
            let maxScroll = event.target.scrollHeight - event.target.clientHeight
            let currentScroll = event.target.scrollTop
            if ((event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100) && (maxScroll !== 0 && currentScroll !== 0)) {
                this.flag += 1;
                this.flag === 1 && this.handleFetchEmailTemplates()
            }
        })

        if (isEdit) {
            this.currentPage = currentPageNumber + 1;
            this.filterSort = emailTemplatesFilter;
            if (Object.keys(JSON.parse(JSON.stringify(emailTemplatesFilter)))?.length > 0) {
                const filterObject = JSON.parse(JSON.stringify(emailTemplatesFilter?.filter));
                if (Object.keys(filterObject)?.length > 0) {
                    if (!(Object.keys(filterObject)?.length === 1 && filterObject.hasOwnProperty("emailCategory"))) this.setState({ showFilter: true })
                }
                this.setState({ selectedCategory: emailTemplatesFilter?.filter?.emailCategory })
            }
        }
        else {
            this.filterSort = {}
        }
        this.fetchEmailTemplatesOnMount()
        batch(() => {
            this.props.toggleIsFetchingEmailTemplateFlag(true);
            this.props.fetchEmailTypeCategories();
            this.props.selectTitle('Manage Standard Templates');
        })
    }

    fetchUsersName = searchString => {
        this.setState({ matchedUsersNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    this.setState({ matchedUsersNames: [...new Set(result.map(item => item.fullName))] })
                }
                this.setState({ hasEnteredString: false })
            });
    };

    handleSortChange = async (pagination, _, sorter) => {
        await this.clearCurrentEmailTemplates();
        this.filterSort = {
            ...this.filterSort,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }
        this.handleFetchEmailTemplates();
    }

    handleExport = async (fileType) => {
        const timeZone = this.props.timeZoneName;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        exportEmailTemplates(CONFIG.exportTable.stdEmailTemplate.fileName + `.${fileType}`, fileType, this.filterSort, timeZone, "PROCESS")
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }

    handleCategorySelection = async (id, category) => {
        await this.clearCurrentEmailTemplates();
        this.setState({ selectedCategory: category === "all" ? undefined : category })
        this.filterSort = {
            ...this.filterSort,
            filter: { ...this.filterSort?.filter, emailCategory: category === "all" ? undefined : category }
        };

        this.handleFetchEmailTemplates();
    }

    render() {
        const emailTemplateCategories = () => {
            return <div className='email-templates-category-div'>
                <LeftNav
                    aria-label="Main Navigation"
                    currentUrl={this.state.selectedCategory || "all"}
                    onNavigate={(id, category) => this.handleCategorySelection(id, category)}
                >
                    <LeftNav.TopLinkItem label="All" href="all" />
                    {this.props.emailCategories?.map((eachType) => { return (<LeftNav.TopLinkItem label={eachType} href={eachType} />) })}
                </LeftNav>
            </div>
        }

        const columns = [
            {
                title: 'No.',
                key: 'index',
                align: 'center',
                width: 50,
                render: (text, record, index) => index + 1,
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.title] ? "filtered-column" : ''}>Title</span>,
                dataIndex: 'title',
                key: 'title',
                width: 250,
                align: 'left',
                sorter: true,
                render: description => description
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.description] ? "filtered-column" : ''}>Description</span>,
                dataIndex: 'description',
                width: 300,
                align: 'left',
                key: 'description',
                sorter: true,
                render: description => description
            },
            {
                title: "Action",
                dataCenter: "action",
                width: 100,
                align: 'center',
                render: (record) => {
                    return <React.Fragment>
                        <button
                            onClick={() => {
                                this.props.handleOnClickAction({
                                    component: CONFIG.editComponentRoute.standardEmail,
                                    tabTitle: record?.title,
                                    recordId: record?.id
                                })
                            }}
                            className="link">
                            <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                        </button>
                    </React.Fragment>
                }
            }
        ]

        const formFields = [
            {
                label: "Title",
                type: CONFIG.formFieldTypes.INPUT,
                key: "title",
                value: this.filterSort?.filter?.title
            },
            {
                label: "Description",
                type: CONFIG.formFieldTypes.INPUT,
                key: "description",
                value: this.filterSort?.filter?.description
            }
        ]
        const { showFilter } = this.state
        return (
            <React.Fragment>
                <Row className="vertical-spacing">
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoIcon aria-label="menu-aria" title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </Dropdown>
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                </Row>
                <div className="content-container responsive-container">
                    <Row >
                        {this.state.showFilter && <FilterPanel
                            panelHeader="Filters and Export"
                            formFields={formFields}
                            handleClear={this.handleClear}
                            handleFilter={this.handleFilter}
                            hideSoftDeleteCheckbox={true}
                            getSearchData={this.fetchUsersName}
                        />}
                    </Row>
                    <Row>
                        <h3>List of Standard Email Templates</h3>
                        <div ref={this.tableRef}>
                            <AppLayout
                                leftPanel={emailTemplateCategories()}
                                mainContent={<Table
                                    className="responsive-container"
                                    rowClassName={(record, index) => (`scroll-row-${index + 1}`)}
                                    scrollToFirstRowOnChange={true}
                                    rowKey={(record) => record.id}
                                    loading={this.state.loading}
                                    columns={columns}
                                    scroll={{ y: 550, x: 850 }}
                                    dataSource={this.state.emailTemplatesData}
                                    bordered
                                    size='small'
                                    onChange={this.handleSortChange}
                                    pagination={false}
                                    footer={() => <>{this.state.emailTemplatesData?.length !== 0 && <div style={{ textAlign: 'center' }}>
                                        <Text strong>Fetched {this.state.emailTemplatesData?.length} out of {this.props.emailTemplatesCount} Email Templates</Text>
                                    </div>}
                                    </>}
                                />}
                            />

                        </div>
                    </Row>
                </div>
            </React.Fragment >
        )
    }

}
const mapStateToProps = ({ standardEmailTemplates, user, tabsLayout }) => {

    return {
        emailTemplates: standardEmailTemplates.data,
        isFetching: standardEmailTemplates.isFetching,
        emailTemplatesCount: standardEmailTemplates.count,
        currentPage: standardEmailTemplates.currentPage,
        emailTemplatesFilter: standardEmailTemplates.currentStandardEmailFilter,
        isEdit: standardEmailTemplates.isEdit,
        currentPageNumber: standardEmailTemplates.currentPageNumber,
        pageSize: standardEmailTemplates.currentPageSize,
        emailTemplateIndex: standardEmailTemplates.emailTemplateIndex,
        emailCategories: standardEmailTemplates.emailCategories,
        timeZoneName: user.profile.timezone,
        openedTabs: tabsLayout.panes
    };
}


export default connect(
    mapStateToProps,
    {
        fetchEmailTemplates,
        clearStandardEmailTemplates,
        selectTitle,
        toggleBackButton,
        toggleIsFetchingEmailTemplateFlag,
        currentEmailtemplateFilter,
        handleOnClickAction,
        fetchEmailTypeCategories
    }
)(ManageStandardEmailTemplate)
import React, { Component } from 'react'
import { Tag, Icon, Spin } from 'antd';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import CONFIG from '../../config';
import HTML from './HTML'
import { fetchDemoAccessInformation } from '../../actions'
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Button } from "@avaya/neo-react"
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class DemoAccess extends Component {

    state = { printPreview: false, loading: true };

    async componentDidMount() {
        const { demoId } = this.props.match.params
        await this.props.fetchDemoAccessInformation(demoId);
        this.setState({
            loading: false
        })
    }

    handleTablePrint = (handlePrint) => {
        this.setState({ printPreview: true }, () => { handlePrint() })
    }

    render() {
        const { timeZoneName, demoAccessInformation } = this.props;
        const currentDemo = demoAccessInformation;
        const accessUpdatedTime = currentDemo?.accessUpdatedTime;
        const accessUpdatedTSTag = `Updated: ${moment.tz(accessUpdatedTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}`;
        const title = currentDemo?.title;
        const access = currentDemo?.connectivityDetails;
        const printTitle = <> <span style={{ fontSize: "18px", fontWeight: "500", color: "black", fontFamily: 'Noto Sans', }}>Access - {title} </span>
            {accessUpdatedTime && <Tag color={CONFIG.colors.userDemo} className="bottom-spacing-3 right-spacing-5" >{accessUpdatedTSTag}</Tag>}
        </>
        return (
            <div className="content-container">
                <Spin spinning={this.state.loading} indicator={antIcon}>
                    <div>
                        <span>
                            {!this.state.printPreview && printTitle}
                            <ReactToPrint
                                onAfterPrint={() => { this.setState({ printPreview: false }) }}
                                content={() => this.componentRef}
                                pageStyle="@media print { 
                                                @page {
                                                    margin:72px 72px 72px 72px;
                                                }
                                                body { 
                                                    -webkit-print-color-adjust: exact;
                                                }
                                            }"
                            >
                                <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                        <Button className="primary-action-button-filled float-right" onClick={() => { this.handleTablePrint(handlePrint) }}>Print <Icon aria-label="aria-menu" type="printer" /></Button>
                                    )}
                                </PrintContextConsumer>
                            </ReactToPrint>
                        </span>
                    </div>
                    <div className="responsive-container" ref={el => (this.componentRef = el)}>
                        <div>
                            {this.state.printPreview && printTitle}
                            <HTML htmlContent={access} />
                        </div>
                    </div>
                </Spin>
            </div >
        )
    }
}

const mapStateToProps = ({ demoSolutionAccess, demoSolutions, user }) => {
    return {
        demoAccessInformation: demoSolutionAccess.demoAccessInformation,
        timeZoneName: user.profile ? user.profile.timezone : undefined,

    };
};

export default connect(
    mapStateToProps,
    {
        fetchDemoAccessInformation
    }
)(DemoAccess);
import React from "react";
import { Icon, Dropdown, List, Divider, Badge, Collapse, Alert, Tooltip, Card, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { Button } from "@avaya/neo-react"
import { withRouter } from "react-router-dom";
import { Icon as NeoIcon } from "@avaya/neo-react";

import {
    toggleIsFetchingBellNotificationsFlag,
    fetchUserBellNotifications,
    updateUserNotificationCount,
    clearUserBellNotifications,
    fetchUserNews,
    toggleBackButton,
    fetchUserNewsAccess,
    fetchUserNotificationAccess,
    fetchUserEZDemoAccess,
    fetchUserDemoAccess,
    fetchSolutionDemo,
    openLinkInNewTab
} from "../actions";
import { getDemo } from '../actions/demos';
import { getNews } from '../actions/news'
import { getNotification, clearAllNotifications } from '../actions/notifications';
import CONFIG from '../config'
import { getNotificationObj, handleViewDetails, getAccessList, checkNotificationValid } from '../utils/notifications';
import { NotificationModal } from './common/NotificationModal';
import moment from 'moment-timezone';
import HTML from './common/HTML'


const { Panel } = Collapse;

class NotificationDropdown extends React.Component {

    state = {
        visible: false,
        modalVisible: false,
        viewDetailsClick: false,
        notificationAccess: true,
    }

    pageNumber = 1;

    shouldDisplayCount = true;

    handleLoadMore = () => {
        this.props.toggleIsFetchingBellNotificationsFlag(true);
        this.props.fetchUserBellNotifications(this.pageNumber++);
    };

    handleVisibleChange = (visible) => {
        this.setState({ visible })
        if (visible) {
            this.props.clearUserBellNotifications();
            this.props.toggleIsFetchingBellNotificationsFlag(true);
            this.props.fetchUserBellNotifications()
                .then(() => {
                    if (this.props.unreadMessageCount) {
                        this.props.updateUserNotificationCount(this.props.messages)
                    }
                })
            this.pageNumber++;
            this.setState({ viewDetailsClick: false })
        }
        else {
            this.pageNumber = 1;
            this.shouldDisplayCount = false;
        }
    }

    getNotifications = (notifications) => {
        return notifications.map(notification => {
            const notificationObj = getNotificationObj(notification)
            return notificationObj;
        })
    }

    getMaintainanceNotifications = (notifications) => {
        return notifications.map(notification => {
            return {
                ...notification,
                title: notification.title,
                messageType: CONFIG.notificationTypes.maintenance[0]
            }
        })
    }

    componentDidMount() {
        this.props.toggleIsFetchingBellNotificationsFlag();
        this.props.fetchUserBellNotifications();
    }

    handleViewDetailsClick = () => {
        this.setState({ viewDetailsClick: true })
    }

    handleModalOpen = ({ closeDropDown = false }) => {
        this.setState({ modalVisible: true });
        closeDropDown && this.handleDropdownClose()
    }

    handleCancel = () => {
        this.setState({
            modalVisible: false,
            visible: true,
            viewDetailsClick: false,
            notificationAccess: true,
        })
        this.selectedNotification = '';
    }

    handleDropdownClose = () => {
        this.setState({ visible: false })
    }

    resetPageNumber = () => {
        this.pageNumber = 1;
    }

    setSelectedNotification = (selectedNotification) => {
        const access = selectedNotification.access;
        this.setState({ notificationAccess: access })
        this.selectedNotification = selectedNotification;
    }

    /**
     * 
     * @param {*} notification 
     * Calling the API based on Type of Notification
     */
    handleNotificationType = async (notification) => {
        let data;
        const notificationType = (notification.access_level && notification.access_level !== CONFIG.manage) ? notification?.type?.toLowerCase() : notification?.messageType?.toLowerCase();
        const notificationId = notification.elementId;
        if (notificationType.includes("news"))
            data = await fetchUserNewsAccess(notificationId);

        else if (notificationType.includes("ez"))
            data = await fetchUserEZDemoAccess(notification.ezDemoId);

        else if (notificationType.includes("rating"))
            data = await fetchUserDemoAccess(notification.demoId);

        else if (notificationType.includes("feedback"))
            data = this.props.isRatingEnabled ? await fetchUserDemoAccess(notification.demoId) : { userAccess: 0, adminAccess: 0 }

        else if (notificationType.includes("maintenance"))
            data = { userAccess: 0, adminAccess: 2 }

        else if (notificationType.includes("schedule"))
            data = { userAccess: 0, adminAccess: 2 }

        else if (notificationType.includes("DC") || notificationType.includes("Resource Status"))
            data = { userAccess: 0, adminAccess: 2 }

        else if (notificationType.includes("demo"))
            data = await fetchUserDemoAccess(notificationId);

        else if (notificationType.includes("notification"))
            data = await fetchUserNotificationAccess(notificationId)

        else if (notificationType.includes("capability"))
            data = { userAccess: 0, adminAccess: 1 }

        return checkNotificationValid(data)

    }
    delay = (ms) => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    handleClearItems = async () => {
        const hasDeleted = await clearAllNotifications();
        if (hasDeleted) {
            this.setState({ clearedItems: true })
            await this.delay(500);
            this.setState({ visible: false })
            this.setState({ clearedItems: false })
        }
    }

    renderNotificationsList = () => {
        const { isLoading, messages, count, maintainanceMessages, timeZoneName } = this.props;
        const dropdownDivHeight = count > 0 ? "80vh" : "80px"
        const overflowY = count > 0 ? "auto" : "hidden"
        const pathname = this.props.location?.pathname
        const notifications = this.getNotifications(messages);
        const maintainanceNotifications = this.getMaintainanceNotifications(maintainanceMessages)
        const loadMore =
            !isLoading && count > 0 && (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 12,
                        height: 32,
                        lineHeight: '32px',
                    }}
                >
                    <Button aria-label="lode-more" variant="secondary" size="compact" onClick={this.handleLoadMore} disabled={messages?.length === count} >See more</Button>
                </div>
            );

        return <Card
            title={<div className="notification-dropdown-header">
                {!isLoading && count > 0 && <span className="size-18px bold">Activity</span>}
                {!isLoading && count > 0 && <span className="link bold red-font clear-all-notification" onClick={this.handleClearItems}>Clear All</span>}
            </div>}
            className={count > 0 && "notification-dropdown-card"}
            bodyStyle={{ height: dropdownDivHeight, overflowY: overflowY }}
            actions={[loadMore]}
        // loading={isLoading}
        >

            {!isLoading && count > 0 ? <List
                className={`push-notification-dropdown-list ${this.state.clearedItems ? 'clear-all' : undefined}`}
                itemLayout="horizontal"
                loading={isLoading}
                dataSource={[...maintainanceNotifications, ...notifications]}
                renderItem={(item, index) => {
                    const shouldDisplayWarningMessage = item.messageType === CONFIG.notificationTypes.access
                    const accessDescription = (item?.messageType === CONFIG.notificationTypes.access) ? JSON.parse(item?.description) : undefined;
                    const ListItem = <List.Item
                        className={`${item.messageType === CONFIG.notificationTypes.maintenance[0] ?
                            "left-border-red"
                            : item?.userPersona === "Admin"
                                ? "left-border-admin"
                                : "left-border-user"
                            } padding-left-10px notification-dropdown-list`}

                    >
                        <List.Item.Meta
                            title={<span>
                                {item.tag &&
                                    <div className="bottom-spacing-3 font-12 primary-text-color">
                                        <Tooltip
                                            title={moment.tz(item.timestamp, timeZoneName).format(CONFIG.dateFormats.userDateTime)}>
                                            Updated {item.notificationAging}
                                        </Tooltip>
                                    </div>
                                }
                                <span className="text-color-black bold">{item.messageType}</span>
                                <span
                                    className="bottom-spacing-3 font-12 float-right link"
                                    onClick={async (e) => {
                                        if (!accessDescription && !isLoading) {
                                            this.notification = item;
                                            this.handleViewDetailsClick();
                                            let access = await this.handleNotificationType(this.notification);
                                            if ((access.userAccess && access.adminAccess) || (access.userAccess && !access.adminAccess)) {
                                                this.setState({ notificationAccess: access.userAccess })
                                                handleViewDetails(e, item, pathname, this.handleModalOpen, this.props.toggleBackButton, this.props.fetchUserNews, this.props.fetchSolutionDemo, this.resetPageNumber, this.handleDropdownClose, access, false, this.props.openLinkInNewTab)
                                            }
                                            else if (!access.userAccess && access.adminAccess) {
                                                this.setState({ notificationAccess: access.adminAccess }, this.handleModalOpen({ closeDropDown: false }));
                                            }
                                            else if (!access.userAccess && !access.adminAccess) this.setState({ notificationAccess: access.userAccess && access.adminAccess }, () => this.handleModalOpen({ closeDropDown: false }))
                                        }
                                    }}

                                >View Details</span>
                            </span>}
                            description={<span className="text-color-black font-12">
                                {accessDescription
                                    ? CONFIG.listOfAccess
                                    : <span>{item?.title || item?.demoTitle} <br />{item?.extraText && <HTML color="text-color-grey" htmlContent={item?.extraText} />}</span>}
                            </span>}
                        />
                    </List.Item>
                    return < div >
                        {item.messageType === CONFIG.notificationTypes.access

                            ? <Collapse
                                accordion
                                bordered={false}
                                expandIcon={({ isActive }) => <Icon aria-label="aria-menu" type="caret-right" rotate={isActive ? 90 : 0} />}
                                expandIconPosition="right"
                                className="notification"
                            >
                                <Panel
                                    className="white-panel"
                                    header={ListItem}
                                    key={index}
                                >
                                    {this.isAdmin && shouldDisplayWarningMessage && <Alert message={CONFIG.warningMessages.notifications[item.messageType]} type="warning" showIcon />}
                                    <br />
                                    {accessDescription && getAccessList(accessDescription?.demos, "Demos", pathname, this.props.toggleBackButton, this.props.fetchSolutionDemo, this.handleModalOpen, this.setSelectedNotification, getDemo, this.resetPageNumber, this.handleDropdownClose)}
                                    {accessDescription && getAccessList(accessDescription?.news, "News", pathname, this.props.toggleBackButton, this.props.fetchUserNews, this.handleModalOpen, this.setSelectedNotification, getNews, this.resetPageNumber, this.handleDropdownClose)}
                                    {accessDescription && getAccessList(accessDescription?.notifications, "Notifications", pathname, this.props.toggleBackButton, null, this.handleModalOpen, this.setSelectedNotification, getNotification, this.resetPageNumber, this.handleDropdownClose)}
                                </Panel>
                            </Collapse>
                            : ListItem
                        }
                        < Divider className="notification-divider" />
                    </ div>
                }}
            />
                :
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={isLoading}><div className="dropdown-notification-empty"><NeoIcon aria-label="menu-aria" icon="notifications-alerting" style={{ fontSize: '30px' }} /><span className="bold">No Activities</span></div></Spin>
            }
        </Card>
    }

    render() {
        const { unreadMessageCount, timeZoneName } = this.props;
        const { viewDetailsClick } = this.state;
        return (
            <>
                <Dropdown
                    overlayClassName="dropdown-width"
                    overlay={this.renderNotificationsList()}
                    trigger={['click']} id="notification"
                    onVisibleChange={this.handleVisibleChange}
                    visible={this.state.visible}
                    placement="bottomLeft"
                >
                    <Badge count={unreadMessageCount ?? 0} style={{ color: "white" }} className="top-spacing-14">
                        <NeoIcon aria-label="menu-aria" style={{ color: "#000000" }} icon={"notifications-on"} size="md" />
                    </Badge>
                </Dropdown>
                {this.state.modalVisible && <NotificationModal notification={!viewDetailsClick ? this.selectedNotification : this.notification} visible={this.state.modalVisible} timeZoneName={timeZoneName} onCancel={this.handleCancel} notificationAccess={this.state.notificationAccess} handleDropdownClose={this.handleDropdownClose} />}

            </>
        );
    }
}

const mapStateToProps = ({ userBellNotifications, user }) => {
    return {
        isLoading: userBellNotifications.isLoading,
        messages: userBellNotifications.data,
        unreadMessageCount: userBellNotifications.unreadMessageCount,
        count: userBellNotifications.count,
        isLoginComplete: user.isLoginComplete,
        maintainanceMessages: userBellNotifications.maintainanceMessages,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        isRatingEnabled: user.isRatingEnabled,
    };
};

export default connect(mapStateToProps, {
    toggleIsFetchingBellNotificationsFlag,
    fetchUserBellNotifications,
    updateUserNotificationCount,
    clearUserBellNotifications,
    fetchUserNews,
    toggleBackButton,
    fetchSolutionDemo,
    fetchUserNewsAccess,
    fetchUserNotificationAccess,
    fetchUserEZDemoAccess,
    fetchUserDemoAccess,
    openLinkInNewTab,
})(withRouter(NotificationDropdown));
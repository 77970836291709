import React from "react";
import { connect, batch } from "react-redux";
import { Row, Button, Icon, message, Dropdown, Tooltip, Spin, Tabs } from "antd";
import DragListView from "react-drag-listview";
import CONFIG from "../../config";
import { Button as NeoButton, Icon as NeoIcon } from "neo-latest"
import {
  selectTitle,
  toggleBackButton,
  fetchSolutionTypes,
  toggleIsFetchingSolutionTypes,
  reorderSolutionTypes,
  toggleIsSolutionTypesReordered,
  putSolutionTypesOrder,
  deleteSolutionType,
  restoreSolutionType,
  clearSolutionTypeForm,
  clearSolutionType,
  exportTable,
  refreshSolutionTypes,
  toggleHasSolutionTypesUpdated,
  currentSolutionTypeFilter,
  handleOnClickAction,
} from "../../actions";
import pushMessage from "../common/PushMessage";
import FilterDrawer from '../common/FilterDrawer';
import openConfirm from '../common/Confirm';
import { exportMenu } from "../../utils/strings";
import { checkSolutionTypeDependencies } from '../../actions/solutionType'
import { isTabAlreadyOpen } from "../common/TabLayout";
import { LoadingOutlined } from '@ant-design/icons';
import { checkIfFilterSortIsEmpty } from '../../utils/formChanged';
import NeoTable from "../common/NeoTable";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;
class ManageSolutionTypes extends React.Component {
  constructor(props) {
    super(props);
    const that = this;
    this.dragProps = {
      async onDragEnd(fromIndex, toIndex) {
        const newSolutionTypes = that.props.activeTypes;
        const item = newSolutionTypes.splice(fromIndex, 1)[0];
        newSolutionTypes.splice(toIndex, 0, item);
        await that.props.reorderSolutionTypes(newSolutionTypes);

      },
      handleSelector: "i",
    };
    this.state = {
      sortedInfo: null,
      filteredData: undefined,
      status: "true",
      showFilter: false
    };

    this.filterSort = {}
    this.tableRef = React.createRef();
  }

  handleChange = (_, __, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  isViewDeletedItemsChecked = undefined;
  solutionTypeId = undefined;

  getSortedSolutionTypes = () => {
    const { activeTypes } = this.props;
    const { sortedInfo } = this.state;
    if (this.isTableSorted)
      activeTypes.sort((a, b) => {
        if (sortedInfo.order === "descend") [a, b] = [b, a];
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    return activeTypes;
  };

  getFilteredData = (title, data) => {
    let filteredData = title
      ? data?.filter(solutionType => solutionType.name.toLowerCase().includes(title.toLowerCase()))
      : data;
    return filteredData
  }

  handleStatusChange = (value) => {
    this.setState({ status: value })
  }

  filterRecords = (filters) => {
    let { title, viewDeletedItems } = filters;
    // const data = this.getData();
    this.isViewDeletedItemsChecked = viewDeletedItems;
    title = title?.trim();
    if (!title && !viewDeletedItems) {
      // if (!data)
      pushMessage(
        CONFIG.messageType.warning,
        "Please select some fields to filter"
      );
      this.handleClear();
      return;
    }
    this.titleSearchString = title;
    this.filterSort = { ...this.filterSort, title, viewDeletedItems }
    this.setState(prevState => ({
      status: viewDeletedItems ? "false" : prevState.status
    }));
  }

  handleFilter = (filters) => {
    let { viewDeletedItems } = filters;
    if (viewDeletedItems || this.isViewDeletedItemsChecked) {
      this.props.toggleIsFetchingSolutionTypes(true);
      this.props.fetchSolutionTypes(undefined, viewDeletedItems).then(() => {
        this.isViewDeletedItemsChecked = viewDeletedItems;
        this.filterRecords(filters);
      })
    } else this.filterRecords(filters);
  };

  handleClear = async (refresh) => {
    if (refresh) {
      this.props.refreshSolutionTypes();
      this.currentPage = 1;
      this.props.toggleIsFetchingSolutionTypes(true);
      await this.props.fetchSolutionTypes(undefined, this.isViewDeletedItemsChecked);
      this.props.toggleHasSolutionTypesUpdated(false);
      const currentFilterSize = this.filterSort?.filter !== undefined ? Object.keys(this.filterSort?.filter)?.length : 0;
      currentFilterSize > 0 && this.filterRecords(this.filterSort)
    }
    else {
      this.titleSearchString = undefined;
      this.filterStatusTypes = undefined;
      this.formFilters = undefined;
      this.filterSave = {};
      this.filterSort = {}
      if (this.isViewDeletedItemsChecked) {
        this.props.toggleIsFetchingSolutionTypes(true);
        await this.props.fetchSolutionTypes();
        this.isViewDeletedItemsChecked = undefined;
      }
      this.isViewDeletedItemsChecked = undefined;
      this.setState({ filteredData: undefined });
    }
  };

  handleExport = async (fileType) => {
    const { timeZoneName } = this.props;
    const data = this.getData();
    const exportData = this.getFilteredData(this.titleSearchString, data);
    pushMessage(CONFIG.messageType.loading, "Exporting", 0);
    if (exportData?.length > 0 && exportData !== undefined) {
      exportTable(CONFIG.exportTable.solutionTypes.name, exportData, timeZoneName, CONFIG.exportTable.solutionTypes.fileName + `.${fileType}`, fileType)
        .then((response) => {
          message.destroy()
          const isExport = response.status === CONFIG.HTTP_STATUS.OK
          pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
        })
        .catch(() => {
          message.destroy()
          pushMessage(CONFIG.messageType.error, "Unable to export table")
        })
    }
    else {
      message.destroy()
      pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
    }
  }

  renderUpdateButton = () => {
    const isUpdateDisabled = !this.isTableSorted || this.titleSearchString !== undefined || this.state.status !== "true" || this.isViewDeletedItemsChecked !== undefined
      || this.props.activeTypes?.length === 0;
    const deleteView = this.isViewDeletedItemsChecked;
    return !deleteView && (
      <div style={{ margin: "16px" }}>
        <Button
          className="primary-action-button-bordered"
          disabled={!this.props.isReordered && isUpdateDisabled}
          onClick={() =>
            this.props.putSolutionTypesOrder(this.getSortedSolutionTypes())
          }
        >
          Update order
        </Button>
      </div>
    );
  };

  handleDeleteConfirmation = () => {
    const hardDelete = this.isViewDeletedItemsChecked;
    if (this.props.userProfile?.isDeveloper && hardDelete) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
    else {
      this.props.deleteSolutionType(this.solutionTypeId, hardDelete)
        .then(async () => {
          this.props.toggleIsFetchingSolutionTypes(true);
          await this.props.fetchSolutionTypes(undefined, this.isViewDeletedItemsChecked);
        })
    }
  }

  handleRestoreConfirmation = () => {
    this.props.restoreSolutionType(this.solutionTypeId)
      .then(async () => {
        this.props.toggleIsFetchingSolutionTypes(true);
        await this.props.fetchSolutionTypes(undefined, true);
      })
  }

  handleDeleteRestore = (solutionTypeId, isRestore) => {
    this.solutionTypeId = solutionTypeId;
    checkSolutionTypeDependencies(solutionTypeId)
      .then(dependencyList => this.renderDeleteRestoreConfirmation(dependencyList, isRestore))
      .catch((err) => pushMessage(CONFIG.messageType.error, err.message))
  }

  renderDeleteRestoreConfirmation = (dependencyList, isRestore) => {
    const { scheduleDemosCount, maintenancesCount, demosCount, solutionsCount } = dependencyList;
    const hardDelete = this.isViewDeletedItemsChecked;
    const okButtonText = isRestore ? "Confirm Restore" : hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
    const text = isRestore ? "Restoration" : "Deletion"

    const showDependencyList = scheduleDemosCount !== 0 || maintenancesCount !== 0 || demosCount !== 0 || solutionsCount !== 0
    const modalTitle = isRestore ? CONFIG.restoreModalTitle?.replace('<placeholder>', 'solution type') : showDependencyList ? CONFIG.dependencyModalTitle : CONFIG.deleteModalTitle?.replace('<placeholder>', 'solution type')
    const content = <>
      {hardDelete && !isRestore ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Solution Type")}</h4> : !isRestore ? <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "Solution Type")}</h4> : ""}
      {showDependencyList &&
        <>
          <h4>{text} of selected solution type would affect</h4>
          {!hardDelete && <>{scheduleDemosCount} * Future Scheduled Demos <br /></>}
          {!hardDelete && <> {maintenancesCount} * Future Maintenance Windows<br /></>}
          {demosCount} * Demos <br />
          {solutionsCount} * Solutions <br />
        </>
      }
    </>
    openConfirm(okButtonText, isRestore ? this.handleRestoreConfirmation : this.handleDeleteConfirmation, null, content, modalTitle);
  }

  async componentDidMount() {
    const { solutionType } = this.props;
    this.setState({ currentPath: window.location.pathname })
    if (solutionType?.isEdit) {
      this.filterSort = solutionType.currentFilter;
      const { sorter, ...filterSort } = solutionType.currentFilter;
      const currentFilterSize = filterSort !== undefined ? Object.keys(filterSort)?.length : 0;
      await this.setState({ status: solutionType.status });
      if (currentFilterSize > 0) {
        this.filterSort = filterSort;
        this.filterRecords(this.filterSort)
        this.setState({ showFilter: true });
      }
      this.setState({ sortedInfo: solutionType.currentFilter.sorter })
    }
    batch(() => {
      this.props.selectTitle("Manage Solution Types");
      this.props.toggleIsFetchingSolutionTypes(true);
      this.props.fetchSolutionTypes(undefined, solutionType?.currentFilter?.viewDeletedItems || false);
    });
  }

  componentWillUnmount() {
    if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
      const filterSort = {
        ...this.filterSort,
        sorter: this.state.sortedInfo ?? undefined
      }
      this.props.currentSolutionTypeFilter(filterSort, this.state.status)
    }
    else {
      this.props.clearSolutionType();
    }
  }

  getData = () => {
    const { solutionTypes, activeTypes, inactiveTypes } = this.props;
    return (this.isViewDeletedItemsChecked || this.state.status === "all") ? solutionTypes : this.state.status === "true" ? activeTypes : inactiveTypes;
  }

  renderFilterPanel = () => {
    return (
      <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
        <div style={{ display: "flex", flex: "1" }}>
          <NeoButton id="solution-types-create-new" onClick={() => {
            this.props.handleOnClickAction({
              component: CONFIG.createComponentRoute.solutionsType,
              tabTitle: "Create"
            })
          }}>Create Solution Type</NeoButton>
        </div>
        <div style={{ display: "flex", width: "100%", flex: "1", flexDirection: "row-reverse", gap: "24px" }}>
          <div >
            <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
              <NeoButton className="export-download-button" icon="chevron-down" variant="secondary">Download</NeoButton>
            </Dropdown>
          </div>
          <div >
            <NeoIcon aria-label="menu-aria" size="md" className="clickable" onClick={() => this.setState(prevState => ({
              showFilter: !prevState.showFilter
            }))} title="Filter" icon={(this.state.showFilter || (!checkIfFilterSortIsEmpty({ filter: this.filterSort }, ["sorter"]))) ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
          </div>
          <div >
            <NeoIcon aria-label="menu-aria" size="md" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
          </div>
        </div>
      </div>
    )
  }

  renderTable = () => {
    const { isFetching, userRoleTemplate } = this.props;
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const data = this.getData();
    const tableData = this.getFilteredData(this.titleSearchString, data);
    const deleteView = this.isViewDeletedItemsChecked;
    const isUserSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
    const isDragDisabled = this.titleSearchString !== undefined || this.state.status !== "true" || this.isViewDeletedItemsChecked !== undefined
    this.isTableSorted = Object.keys(sortedInfo).length > 2;
    const adminColumns = [
      {
        title: "",
        key: "operate",
        align: "center",
        render: () => <div>
          <Icon aria-label="aria-menu" style={{ marginRight: "-10px" }} className="drag-handle" type="more" />
          <Icon aria-label="aria-menu" className="drag-handle" type="more" />
        </div>,
        width: "5%",
      },
    ];
    const columns = [
      {
        title: (
          <span className={this.titleSearchString ? "filtered-column" : ""}>
            Solution Type
          </span>
        ),
        dataIndex: "name",
        key: "name",
        width: "20%",
        align: "left",
        sorter: (a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      },
      {
        title: (
          <span className={this.filterStatusTypes ? "filtered-column" : ""}>
            Status
          </span>
        ),
        dataIndex: "isActive",
        key: "isActive",
        width: "15%",
        render: (status) => <NeoIcon aria-label="menu-aria" style={status ? { color: "#088A08", fontSize: "20px !important" } : { color: "#DA291C", fontSize: "20px !important" }} icon={status ? "available" : "missed"} />,
        // align: "center",
        sorter: (a, b) => a.isActive.toString().localeCompare(b.isActive.toString()),
        sortOrder: sortedInfo.columnKey === 'isActive' && sortedInfo.order,
      },
      {
        title: " ",
        key: "actions",
        width: "15%",
        render: (record) => {
          const isEditDisabled =
            userRoleTemplate &&
            userRoleTemplate.templateType !== CONFIG.roleTypes.superAdmin &&
            record.adminAccess ===
            CONFIG.roleTemplateAccess.readOnly;
          const isDeleteDisabled = record?.id === 1;
          return (
            <div>
              {!deleteView &&
                <span style={{ marginRight: "24px" }}>
                  <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                    <button
                      id="solution-type-edit"
                      onClick={() => {
                        this.props.handleOnClickAction({
                          component: CONFIG.editComponentRoute.solutionsType,
                          tabTitle: record?.name,
                          recordId: record?.id
                        })
                      }}
                      disabled={isEditDisabled}
                      className={!isEditDisabled ? "link" : "link-disabled"}>
                      <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                    </button>
                  </Tooltip>
                </span>
              }
              <>
                <Tooltip
                  title={
                    (isEditDisabled || isDeleteDisabled) ? CONFIG.warningMessages.noAccess : undefined
                  }
                  key={record.id}
                >
                  <button
                    id="solution-type-delete"
                    disabled={isEditDisabled || isDeleteDisabled}
                    className={isDeleteDisabled ? "link-disabled" : !isEditDisabled ? "link" : "link-disabled"}
                    onClick={() => this.handleDeleteRestore(record.id)}
                  >
                    <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span>
                  </button>

                </Tooltip>
                {deleteView &&
                  <span style={{ marginLeft: "24px" }}>
                    <button
                      disabled={isEditDisabled}
                      className={!isEditDisabled ? "link" : "link-disabled"}
                      onClick={() => this.handleDeleteRestore(record.id, true)}
                    ><span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span>
                    </button>
                  </span>
                }
              </>
            </div>
          );
        },
        align: "center",
      },
    ];
    return (
      isFetching ? null :
        <Row>
          <div ref={this.tableRef}>
            {isUserSuperAdmin && this.renderUpdateButton()}
            <DragListView {...this.dragProps}>
              <NeoTable
                size='middle'
                className="responsive-container vertical-spacing"
                columns={
                  isUserSuperAdmin && isDragDisabled === false
                    ? [...adminColumns, ...columns]
                    : columns
                }
                dataSource={tableData}
                bordered
                pagination={false}
                rowKey={(record) => record.id}
                loading={this.props.isFetching}
                onChange={this.handleChange}
                noDataText='No Data'
              />
            </DragListView>

            {isUserSuperAdmin && this.renderUpdateButton()}
          </div>
        </Row>
    )
  }

  renderTabContents = () => {
    return (
      <React.Fragment>
        <div>
          {this.renderFilterPanel()}
        </div>
        <div className="height-neo-table">
          {this.renderTable()}
        </div>
      </React.Fragment>
    )
  }

  render() {
    const formFields = [
      {
        label: "Title",
        type: CONFIG.formFieldTypes.INPUT,
        key: "title",
        value: this.filterSort?.title,
        colSpan: 24,
      }
    ];

    const drawerContainer = document.getElementById('#component-content');

    return (
      <React.Fragment>
        <div style={{ color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px", marginTop: "28px" }}>
          Manage Solution Types
        </div>
        <Spin spinning={this.props.isFetching} indicator={antIcon}>
          <div style={{ marginTop: "16px" }}>
            <Tabs disabled={this.isViewDeletedItemsChecked} activeKey={this.state.status} className='demo-status-tabs' defaultActiveKey={this.state.status} onChange={this.handleStatusChange}>
              <TabPane disabled={this.isViewDeletedItemsChecked} tab="Active" key="true">
                {this.renderTabContents()}
              </TabPane>
              <TabPane tab="Inactive" key="false">
                {this.renderTabContents()}
              </TabPane>
              <TabPane disabled={this.isViewDeletedItemsChecked} tab="All" key="all">
                {this.renderTabContents()}
              </TabPane>
            </Tabs>
          </div>
        </Spin>
        <div>
          <Row>
            {this.state.showFilter && (
              <FilterDrawer
                visible={this.state.showFilter}
                width='520'
                handleCloseFilterDrawer={() => this.setState(prevState => ({
                  showFilter: false,
                  record: null
                }))}
                placement="right"
                drawerContainer={drawerContainer}
                style={{ zIndex: 9999, }}
                maskClosable={false}
                panelHeader="Filters and Export"
                formFields={formFields}
                handleClear={this.handleClear}
                timeZone={this.props.timeZoneName}
                hideSoftDeleteCheckbox={false}
                label='View Deleted Solution Types'
                hideSave={true}
                viewDeletedItemsValue={this.filterSort?.viewDeletedItems}
                handleSave={null}
                handleFilter={this.handleFilter}
                getSearchData={null}
              />)}
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ user, solutionTypes, tabsLayout }) => {
  return {
    solutionTypes: solutionTypes.types,
    isFetching: solutionTypes.isFetching,
    isReordered: solutionTypes.isReordered,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
    solutionType: solutionTypes,
    timeZoneName: user.profile ? user.profile.timezone : undefined,
    userProfile: user.profile,
    openedTabs: tabsLayout.panes,
    activeTypes: solutionTypes.activeTypes,
    inactiveTypes: solutionTypes.inactiveTypes
  };
};

export default connect(mapStateToProps, {
  selectTitle,
  toggleBackButton,
  fetchSolutionTypes,
  toggleIsFetchingSolutionTypes,
  reorderSolutionTypes,
  toggleIsSolutionTypesReordered,
  putSolutionTypesOrder,
  deleteSolutionType,
  restoreSolutionType,
  clearSolutionTypeForm,
  clearSolutionType,
  exportTable,
  refreshSolutionTypes,
  toggleHasSolutionTypesUpdated,
  handleOnClickAction,
  currentSolutionTypeFilter,
})(ManageSolutionTypes);

import React from "react";
// import { Icon } from 'antd';
import { Switch, Route } from "react-router-dom";
import Dashboard from './Dashboard';
import AdminDashboard from './AdminDashboard';
import ManageDemo from "./ManageDemo";
import DemoApprovals from "./DemoApprovals";
import DemoApprovalDetails from "./DemoApprovals/DemoApprovalDetails";
import DemoForm from "./ManageDemo/DemoForm";
import ManageResources from "./ManageResource";
import ResourceForm from './ManageResource/ResourceForm'
import FeedbackQuestionsForm from './ManageFeedbackQuestions/FeedbackQuestionsForm'
import DemoQuestionForm from './ManageDemoQuestions/DemoQuestionForm'
import ScheduleMaintenance from './ScheduleMaintenance';
import ScheduleMaintenanceForm from './ScheduleMaintenance/ScheduleMaintenanceForm';
import ManageNews from './ManageNews'
import NewsForm from './ManageNews/NewsForm'
import ManageSolutions from './ManageSolutions';
import SolutionForm from './ManageSolutions/SolutionForm';
import ManageScheduledDemos from './ManageScheduledDemo';
import ScheduledDemoViewDetails from './ManageScheduledDemo/ScheduledDemoViewDetails';
import DemoSolutions from './DemoSolutions';
import ManageNotifications from './ManageNotifications';
import NotificationsForm from './ManageNotifications/NotificationsForm';
// import ScheduleStandardDemo from './ScheduleDemo/StandardDemo';
import NeoScheduleStandardDemo from './ScheduleDemo/NeoStandardDemo';
import NeoScheduleLabDemo from './ScheduleDemo/NeoLabDemo';
import ScheduleLabDemo from './ScheduleDemo/LabDemo';
// import Support from './Support';
import ManageSupport from './ManageSupport';
import SupportForm from './ManageSupport/SupportForm';
import ManageUsers from './ManageUsers';
import News from './News'
import ManageRoleTemplates from './ManageRoleTemplates';
import RoleTemplatesForm from './ManageRoleTemplates/RoleTemplatesForm';
import ManageDataCenters from './ManageDataCenters';
import ManageDIDs from './ManageDIDs';
import ManageNetworks from './ManageNetworks';
import MyProfile from './MyProfile';
// import Home from './Home';
import HomeNeo from "./HomeNeo";
import AboutUs from './AboutUs';
import ManageSolutionTypes from './ManageSolutionTypes';
import ManageDemoPurpose from './ManageDemoPurpose';
import DemoPurposeForm from './ManageDemoPurpose/DemoPurposeForm';
import SolutionTypeForm from './ManageSolutionTypes/SolutionTypeForm';
import NoSolutions from './NoSolutions';
import ManageStandardEmailTemplate from './ManageEmailTemplates/ManageStandardEmailTemplate';
import ManageCustomEmailTemplates from "./ManageEmailTemplates/ManageCustomEmailTemplates";
import ManageTemplateVersions from "./ManageEmailTemplates/ManageTemplateVersions";
import ManageMaintenanceTemplateVersions from "./ManageEmailTemplates/ManageMaintenanceTemplateVersions";
import EmailTemplateForm from './ManageEmailTemplates/EmailTemplateForm'
import ManageLocalUsers from './ManageLocalUsers';
import LocalUsersForm from './ManageLocalUsers/LocalUsersForm';
// import EZDemos from './EZDemos';
import EZDemosNeo from "./EZDemosNeo"
import TipForm from './ManageTips/TipForm';
import ManageTips from "./ManageTips";
import ManageReports from "./ManageReports";
import UserCreatedReports from "./ManageReports/UserCreatedReports";
import ManageGeneralReports from "./ManageGeneralReports";
import ReportForm from "./ManageReports/ReportForm";
import Reports from "./Reports";
import SaveReport from "./common/SaveReport"
import ViewReport from "./ViewReport";
import MyReports from "./MyReports";
import ManageTearDown from './ManageTearDown';
import DemoFeedbackForm from './ManageDemo/DemoFeedbackForm';
import DemoRatingsAndReviews from './ManageDemo/DemoRatingsAndReviews';
// import DemoDataCenter from './ManageDemo/DemoDataCenter';
import ManageFeedbackQuestions from './ManageFeedbackQuestions';
import ManageDemoQuestions from './ManageDemoQuestions';
import ManageManualDemoBuilds from "./ManageManualDemoBuilds";
import ManualDemoBuildsEmailFlow from "./ManageManualDemoBuilds/DynamicEmailTemplateFlow";
import DemoRequests from "./DemoRequests";
import DemoRequestDetails from "./DemoRequests/DemoRequestDetails";
import FallbackUI from "./FallbackUI";
import DemoAccess from "./common/DemoAccess";
import ManageDailyReports from "./ManageDailyReports";
import DailyReportForm from "./ManageDailyReports/DailyReportForm";
import ManageDailyReportAttributes from "./ManageDailyReportAttributes";
import DailyReportAttributeForm from "./ManageDailyReportAttributes/DailyReportAttributeForm";
import ManageProducts from "./ManageDailyReportProducts";
import ManageDemoCapability from "./ManageDemoCapability";
import ROUTE from "../components/ContentRouteConfig";
import DemoCapabilityForm from "./ManageDemoCapability/DemoCapabilityForm"
import CapabilityRequests from "./CapabilityRequests";
import CapabilityRequestDetails from "./CapabilityRequests/CapabilityRequestDetails"
import EZDemosTable from "./ManageEZDemos";
import EZDemosForm from "./ManageEZDemos/EZDemosForm";
import EZDemoReviews from "./ManageEZDemos/EZDemoReviews";
import MySchedules from "./MyDemos/MySchedules"
import SupportNeo from "./SupportNeo";
import MyRequests from "./MyDemos/MyRequests";
import UserReports from "./common/UserReports";
import DemoStatusTable from "./ManageDemoStatus";
import ReportFormNeo from "./ManageReportsNeo/ReportFormNeo";
import ManageSFDCStats from "./ManageSFDCStats"
import AdminSettings from "./AdminSettings";
// import HomeNeo from "./HomeNeo";
class Content extends React.Component {
  state = {
    isVisible: false,
    isErrorHappend: false,
    height: `${document.body.clientHeight - 123}px`,
  };

  handleTop = () => {
    document.getElementById("tab-pane").scrollIntoView({ behavior: "smooth" });
  }

  handleScroll = (event) => {
    let currentScroll = event.target.scrollTop
    if (currentScroll > 900 && !this.state.isVisible) this.setState({ isVisible: true })
    if (currentScroll <= 900 && this.state.isVisible) this.setState({ isVisible: false })
  }

  componentDidMount() {
    const content = document.querySelector(".content");
    content && content.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);

  }

  componentWillUnmount() {
    const content = document.querySelector(".content");
    content && content.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidCatch() {
    this.setState({
      isErrorHappend: true
    })
  }

  handleResize = (e) => {
    this.setState({ height: document.body.clientHeight > 250 ? `${document.body.clientHeight - 123}px` : `250px` });
  }

  render() {
    const { isErrorHappend } = this.state;
    const { activeKey, isAdmin } = this.props;
    return (
      <div >
        {isErrorHappend ? <FallbackUI /> : <div id="container" style={{ height: this.state.height }}>
          <Switch>

            {/* Dashboard */}
            <Route path={ROUTE.dashboard} exact render={(props) => <>{activeKey === ROUTE.dashboard && (isAdmin ? <AdminDashboard {...props} /> : <Dashboard {...props} />)}</>} />

            {/* My Demos */}
            <Route path={ROUTE.mySchedules} exact render={(props) => <>{activeKey === ROUTE.mySchedules && <MySchedules {...props} />}</>} />
            <Route path={ROUTE.myRequests} exact render={(props) => <>{activeKey === ROUTE.myRequests && <MyRequests {...props} />}</>} />

            {/* Solutions, Solution Types */}
            <Route path="/solutions" exact render={(props) => <>{activeKey === "/solutions" && <NoSolutions {...props} />}</>} />
            <Route path="/solutions/demo/:demoId" exact render={(props) => <>{activeKey === `/solutions/demo/${props.match.params.demoId}` && <DemoSolutions key={props.match.params.demoId} {...props} />}</>} />
            <Route path="/solutions/demo/:activeTab/:demoId" exact render={(props) => <>{activeKey === `/solutions/demo/${props.match.params.activeTab}/${props.match.params.demoId}` && <DemoSolutions key={props.match.params.activeTab} {...props} />}</>} />
            <Route path="/solutions/demos/:solutiontypeId/:solutionId" exact render={(props) => <>{activeKey === `/solutions/demos/${props.match.params.solutiontypeId}/${props.match.params.solutionId}` && <DemoSolutions key={props.match.params.solutiontypeId} {...props} />}</>} />
            <Route path="/solutions/:solutionType/:solutionId" exact render={(props) => <>{activeKey === `/solutions/${props.match.params.solutionType}/${props.match.params.solutionId}` && <DemoSolutions key={props.match.params.solutionType} {...props} />}</>} />
            <Route path="/solutions/:searchString" exact render={(props) => <>{activeKey === `/solutions/${props.match.params.searchString}` && <DemoSolutions key={props.match.params.searchString} {...props} />}</>} />
            <Route path="/solutions/demo/:demoId/:scheduleId/:isRatingRedirect/:isLowRatingAlert" exact render={(props) => <>{activeKey === `/solutions/demo/${props.match.params.demoId}/${props.match.params.scheduleId}/${props.match.params.isRatingRedirect}/${props.match.params.isLowRatingAlert}` && <DemoSolutions key={props.match.params.demoId} {...props} />}</>} />

            {/*User Schedules */}
            {/* <Route path="/scheduler/standard/:demoId/new" exact component={NeoScheduleStandardDemo} /> */}
            {/* <Route path="/scheduler/standard/:scheduleId/edit" exact component={ScheduleStandardDemo} /> */}
            {/* <Route path="/scheduler/lab/:demoId/new" exact component={ScheduleLabDemo} /> */}
            <Route path="/scheduler/lab/:scheduleId/edit" exact component={ScheduleLabDemo} />
            {/* <Route path="/lab-schedule-demo" exact component={ScheduleLabDemo} /> */}
            <Route path="/scheduler/standard/:demoId/new" render={(props) => <>{activeKey === `/scheduler/standard/${props.match.params.demoId}/new` && <NeoScheduleStandardDemo key={props.match.params.demoId} {...props} />}</>} />
            <Route path="/scheduler/standard/:scheduleId/edit/isReschedule=:isReschedule/fromManageSide=:fromManageSide" render={(props) => <>{activeKey === `/scheduler/standard/${props.match.params.scheduleId}/edit/isReschedule=${props.match.params.isReschedule}/fromManageSide=${props.match.params.fromManageSide}` && <NeoScheduleStandardDemo key={props.match.params.scheduleId} {...props} />}</>} />
            <Route path="/scheduler/standard/:scheduleId/edit/isReschedule=:isReschedule/isBatchEdit=:isBatchEdit/batchId=:batchId" render={(props) => <>{activeKey === `/scheduler/standard/${props.match.params.scheduleId}/edit/isReschedule=${props.match.params.isReschedule}/isBatchEdit=${props.match.params.isBatchEdit}/batchId=${props.match.params.batchId}` && <NeoScheduleStandardDemo key={props.match.params.scheduleId} {...props} />}</>} />

            <Route path="/scheduler/lab/:demoId/new" render={(props) => <>{activeKey === `/scheduler/lab/${props.match.params.demoId}/new` && <NeoScheduleLabDemo key={props.match.params.demoId} {...props} />}</>} />
            <Route path="/scheduler/lab/:scheduleId/edit/isReschedule=:isReschedule/fromManageSide=:fromManageSide" render={(props) => <>{activeKey === `/scheduler/lab/${props.match.params.scheduleId}/edit/isReschedule=${props.match.params.isReschedule}/fromManageSide=${props.match.params.fromManageSide}` && <NeoScheduleLabDemo key={props.match.params.scheduleId} {...props} />}</>} />

            {/* <Route path="/scheduler/lab/:demoId/new" render={(props) => <>{activeKey === `/scheduler/lab/${props.match.params.demoId}/new` && <ScheduleLabDemo key={props.match.params.demoId} {...props} />}</>} /> */}

            {/* <Route path="/scheduler/standard/:demoId/new" render={(props) => <>{activeKey === `/scheduler/standard/${props.match.params.demoId}/new` && <ScheduleStandardDemo key={props.match.params.demoId} {...props} />}</>} /> */}
            {/* <Route path="/scheduler/lab/:demoId/new" render={(props) => <>{activeKey === `/scheduler/lab/${props.match.params.demoId}/new` && <ScheduleLabDemo key={props.match.params.demoId} {...props} />}</>} /> */}


            {/* User Side Interaction */}
            <Route path="/user/demo/access/:demoId" exact render={(props) => <>{activeKey === `/user/demo/access/${props.match.params.demoId}` && <DemoAccess key={props.match.params.demoId} {...props} />}</>} />
            <Route path="/demo-feedback/:scheduleFeedbackId" exact render={(props) => <>{activeKey === `/emailRedirection/demo-feedback/${props.match.params.scheduleFeedbackId}` && <DemoFeedbackForm key={props.match.params.scheduleFeedbackId} {...props} />}</>} />
            <Route path="/demo-feedback/:scheduleFeedbackId/:isModifyRating" exact render={(props) => <>{activeKey === `/demo-feedback/${props.match.params.scheduleFeedbackId}/${props.match.params.isModifyRating}` && <DemoFeedbackForm key={props.match.params.scheduleFeedbackId} {...props} />}</>} />
            <Route path="/manage/demo/ratings/:demoId" exact render={(props) => <>{activeKey === `/manage/demo/ratings/${props.match.params.demoId}` && <DemoRatingsAndReviews key={props.match.params.demoId} {...props} />}</>} />
            <Route path="/demo-ratings/:demoId/:scheduleId/:isRatingRedirect/:isLowRatingAlert" exact render={(props) => <>{activeKey === `/demo-ratings/${props.match.params.demoId}/${props.match.params.scheduleId}/${props.match.params.isRatingRedirect}/${props.match.params.isLowRatingAlert}` && <DemoRatingsAndReviews key={props.match.params.demoId} {...props} />}</>} />

            {/* EZ demo */}
            <Route path={ROUTE.userEZDemos} exact render={(props) => <>{activeKey === ROUTE.userEZDemos && <EZDemosNeo key="-1" {...props} />}</>} />

            {/* <Route path={ROUTE.userEZDemos} exact render={(props) => <>{activeKey === ROUTE.userEZDemos && <EZDemos key="-1" {...props} />}</>} /> */}
            <Route path="/ez-demos/:salesVideoId" exact render={(props) => <>{activeKey === `/ez-demos/${props.match.params.salesVideoId}` && <EZDemosNeo key={props.match.params.salesVideoId} {...props} />}</>} />


            {/* User Side Reports */}
            <Route path="/my-reports" exact render={(props) => <>{activeKey === "/my-reports" && <MyReports {...props} />}</>} />
            <Route path="/my-reports/:reportId" exact render={(props) => <>{activeKey === `/my-reports/${props.match.params.reportId}` && <MyReports key={props.match.params.reportId} {...props} />}</>} />
            <Route path={ROUTE.userStandardReport} exact render={(props) => <>{activeKey === ROUTE.userStandardReport && <Reports {...props} />}</>} />
            <Route path="/standard-reports/:reportId" exact render={(props) => <>{activeKey === `/standard-reports/${props.match.params.reportId}` && <Reports key={props.match.params.reportId} {...props} />}</>} />
            {/* <Route path="/reports/view/:reportId/" exact component={ViewReport} /> */}
            <Route path="/reports/view/:reportId/:isMyReport" exact render={(props) => <>{activeKey === `/reports/view/${props.match.params.reportId}/${props.match.params.isMyReport}` && <ViewReport key={props.match.params.reportId} {...props} />}</>} />
            <Route path={ROUTE.userReports} exact render={(props) => <>{activeKey === ROUTE.userReports && <UserReports {...props} />}</>} />

            {/* User News */}
            <Route path={ROUTE.userNews} exact render={(props) => <>{activeKey === ROUTE.userNews && <News {...props} />}</>} />
            <Route path="/news/:newsId" exact render={(props) => <>{activeKey === `/news/${props.match.params.newsId}` && <News key={props.match.params.newsId} {...props} />}</>} />
            <Route path="/inactive-news/:newsId" exact render={(props) => <>{activeKey === `/inactive-news/${props.match.params.newsId}` && <News key={props.match.params.newsId} {...props} />}</>} />

            {/* User Support */}
            {/* {!isLoginComplete && <Route path={ROUTE.userSupport} exact component={Support} />}
            <Route path={ROUTE.userSupport} exact render={(props) => <>{activeKey === ROUTE.userSupport && <Support {...props} />}</>} />
            <Route path="/support/:guideType/:guideId" exact render={(props) => <>{activeKey === `/support/${props.match.params.guideType}/${props.match.params.guideId}` && <Support key={props.match.params.guideId} {...props} />}</>} />
            <Route path="/support/:state" exact render={(props) => <>{activeKey === `/support/${props.match.params.state}` && <Support key={props.match.params.state} {...props} />}</>} /> */}

            <Route path={ROUTE.userSupport} exact render={(props) => <>{activeKey === ROUTE.userSupport && <SupportNeo {...props} />}</>} />
            <Route path="/support/:guideType" exact render={(props) => <>{activeKey === `/support/${props.match.params.guideType}` && <SupportNeo key={props.match.params.guideType} {...props} />}</>} />
            <Route path="/support/:guideType/:guideId" exact render={(props) => <>{activeKey === `/support/${props.match.params.guideType}/${props.match.params.guideId}` && <SupportNeo key={props.match.params.guideId} {...props} />}</>} />

            {/* Approvals */}
            <Route path={ROUTE.scheduleApproval} exact render={(props) => <>{activeKey === ROUTE.scheduleApproval && <DemoApprovals {...props} />}</>} />
            <Route path="/schedule-approvals/details/:scheduleId" exact render={(props) => <>{activeKey === `/schedule-approvals/details/${props.match.params.scheduleId}` && <DemoApprovalDetails key={props.match.params.scheduleId} {...props} />}</>} />
            <Route path="/emailRedirection/schedule-approvals/details/:scheduleId" exact render={(props) => <>{activeKey === `/emailRedirection/schedule-approvals/details/${props.match.params.scheduleId}` && <DemoApprovalDetails key={props.match.params.scheduleId} {...props} />}</>} />


            {/* Manage SSO Users */}
            <Route path={ROUTE.ssoUsers} exact render={(props) => <>{activeKey === ROUTE.ssoUsers && <ManageUsers {...props} />}</>} />

            {/* Manage Local Users Users */}
            <Route path={ROUTE.nonSsoUsers} exact render={(props) => <>{activeKey === ROUTE.nonSsoUsers && <ManageLocalUsers {...props} />}</>} />
            <Route path="/manage/users/non-sso/edit/:userId" exact render={(props) => <>{activeKey === `/manage/users/non-sso/edit/${props.match.params.userId}` && <LocalUsersForm key={props.match.params.userId} {...props} />}</>} />
            <Route path="/manage/users/non-sso/create" exact render={(props) => <>{activeKey === "/manage/users/non-sso/create" && <LocalUsersForm key="-1" {...props} />}</>} />

            {/* Manage Role Tenplates */}
            <Route path={ROUTE.roleTemplates} exact render={(props) => <>{activeKey === ROUTE.roleTemplates && <ManageRoleTemplates {...props} />}</>} />
            <Route path="/manage/role-templates/edit/:roleTemplateId" exact render={(props) => <>{activeKey === `/manage/role-templates/edit/${props.match.params.roleTemplateId}` && <RoleTemplatesForm key={props.match.params.roleTemplateId} {...props} />}</>} />
            <Route path="/manage/role-templates/create" exact render={(props) => <>{activeKey === "/manage/role-templates/create" && <RoleTemplatesForm key="-1" {...props} />}</>} />

            {/* Manage Demos */}
            <Route path={ROUTE.demos} exact render={(props) => <>{activeKey === ROUTE.demos && <ManageDemo {...props} />}</>} />
            <Route path="/manage/demos/create" exact render={(props) => <>{activeKey === "/manage/demos/create" && <DemoForm key="-1" {...props} />}</>} />
            <Route path="/manage/demos/edit/:demoId" exact render={(props) => <>{activeKey === `/manage/demos/edit/${props.match.params.demoId}` && <DemoForm key={props.match.params.demoId} {...props} />}</>} />
            <Route path="/manage/demos/clone/:demoId" exact render={(props) => <>{activeKey === `/manage/demos/clone/${props.match.params.demoId}` && <DemoForm key={props.match.params.demoId} {...props} />}</>} />
            <Route path="/manage/demo/rating" exact render={(props) => <>{activeKey === "/manage/demo/rating" && <ManageDemo {...props} />}</>} />

            {/* Scheduled Demos */}
            <Route path={ROUTE.scheduleDemos} exact render={(props) => <>{activeKey === ROUTE.scheduleDemos && <ManageScheduledDemos {...props} />}</>} />
            <Route path="/manage/schedules/view/:scheduleId" exact render={(props) => <>{activeKey === `/manage/schedules/view/${props.match.params.scheduleId}` && <ScheduledDemoViewDetails key={props.match.params.scheduleId} {...props} />}</>} />


            {/* Manage Demo Resources */}
            <Route path={ROUTE.resources} exact render={(props) => <>{activeKey === ROUTE.resources && <ManageResources {...props} />}</>} />
            <Route path="/manage/demo-resources/create" exact render={(props) => <>{activeKey === "/manage/demo-resources/create" && <ResourceForm key="-1" {...props} />}</>} />
            <Route path="/manage/demo-resources/edit/:resourceId" exact render={(props) => <>{activeKey === `/manage/demo-resources/edit/${props.match.params.resourceId}` && <ResourceForm key={props.match.params.resourceId} {...props} />}</>} />
            <Route path="/manage/demo-resources/clone/:resourceId" exact render={(props) => <>{activeKey === `/manage/demo-resources/clone/${props.match.params.resourceId}` && <ResourceForm key={props.match.params.resourceId} {...props} />}</>} />

            {/* Manage Solutions */}
            <Route path={ROUTE.solutions} exact render={(props) => <>{activeKey === ROUTE.solutions && <ManageSolutions {...props} />}</>} />
            <Route path="/manage/demo-solutions/create" exact render={(props) => <>{activeKey === "/manage/demo-solutions/create" && <SolutionForm key="-1" {...props} />}</>} />
            <Route path="/manage/demo-solutions/edit/:solutionId" exact render={(props) => <>{activeKey === `/manage/demo-solutions/edit/${props.match.params.solutionId}` && <SolutionForm key={props.match.params.solutionId} {...props} />}</>} />

            {/* Manage Solution Type */}
            <Route path={ROUTE.solutionTypes} exact render={(props) => <>{activeKey === ROUTE.solutionTypes && <ManageSolutionTypes {...props} />}</>} />
            <Route path="/manage/solution-type/create" exact render={(props) => <>{activeKey === "/manage/solution-type/create" && <SolutionTypeForm key="-1" {...props} />}</>} />
            <Route path="/manage/solution-type/edit/:solutionTypeId" exact render={(props) => <>{activeKey === `/manage/solution-type/edit/${props.match.params.solutionTypeId}` && <SolutionTypeForm key={props.match.params.solutionTypeId} {...props} />}</>} />

            {/* Manage Builds */}
            <Route path={ROUTE.automaticBuilds} exact render={(props) => <>{activeKey === ROUTE.automaticBuilds && <ManageTearDown {...props} />}</>} />
            <Route path={ROUTE.manualBuilds} exact render={(props) => <>{activeKey === ROUTE.manualBuilds && <ManageManualDemoBuilds {...props} />}</>} />
            <Route path="/manage/:type/email" exact component={ManualDemoBuildsEmailFlow} />


            {/* Manage Purpose */}
            <Route path={ROUTE.purpose} exact render={(props) => <>{activeKey === ROUTE.purpose && <ManageDemoPurpose {...props} />}</>} />
            <Route path="/manage/purpose/create" exact render={(props) => <>{activeKey === "/manage/purpose/create" && <DemoPurposeForm key="-1" {...props} />}</>} />
            <Route path="/manage/purpose/edit/:purposeId" exact render={(props) => <>{activeKey === `/manage/purpose/edit/${props.match.params.purposeId}` && <DemoPurposeForm key={props.match.params.purposeId} {...props} />}</>} />

            {/* Manage Demo Status */}
            <Route path={ROUTE.demoStatus} exact render={(props) => <>{activeKey === ROUTE.demoStatus && <DemoStatusTable {...props} />}</>} />

            {/* Manage Demo & Feedback Questions */}
            <Route path={ROUTE.feedbackQuestions} exact render={(props) => <>{activeKey === ROUTE.feedbackQuestions && <ManageFeedbackQuestions {...props} />}</>} />
            <Route path="/manage/feedback-question/create" exact render={(props) => <>{activeKey === "/manage/feedback-question/create" && <FeedbackQuestionsForm key="-1" {...props} />}</>} />
            <Route path="/manage/feedback-question/edit/:feedbackQuestionId" exact render={(props) => <>{activeKey === `/manage/feedback-question/edit/${props.match.params.feedbackQuestionId}` && <FeedbackQuestionsForm key={props.match.params.feedbackQuestionId} {...props} />}</>} />
            <Route path={ROUTE.demoQuestions} exact render={(props) => <>{activeKey === ROUTE.demoQuestions && <ManageDemoQuestions {...props} />}</>} />
            <Route path="/manage/demo-question/create" exact render={(props) => <>{activeKey === "/manage/demo-question/create" && <DemoQuestionForm key="-1" {...props} />}</>} />
            <Route path="/manage/demo-question/edit/:demoQuestionId" exact render={(props) => <>{activeKey === `/manage/demo-question/edit/${props.match.params.demoQuestionId}` && <DemoQuestionForm key={props.match.params.feedbackQuestionId} {...props} />}</>} />


            {/* Demo Requests */}
            <Route path={ROUTE.demoRequests} exact render={(props) => <>{activeKey === ROUTE.demoRequests && <DemoRequests {...props} />}</>} />
            <Route path="/manage/demo-request/details/:demoRequestId" exact render={(props) => <>{activeKey === `/manage/demo-request/details/${props.match.params.demoRequestId}` && <DemoRequestDetails key={props.match.params.demoRequestId} {...props} />}</>} />
            <Route path="/emailRedirection/demo-request-details/:demoRequestId" exact render={(props) => <>{activeKey === `/emailRedirection/demo-request-details/${props.match.params.demoRequestId}` && <DemoRequestDetails key={props.match.params.demoRequestId} {...props} />}</>} />


            {/* Manage News */}
            <Route path={ROUTE.news} exact render={(props) => <>{activeKey === ROUTE.news && <ManageNews {...props} />}</>} />
            <Route path="/manage/news/edit/:newsId" exact render={(props) => <>{activeKey === `/manage/news/edit/${props.match.params.newsId}` && <NewsForm key={props.match.params.newsId} {...props} />}</>} />
            <Route path="/manage/news/draft/:newsId" exact render={(props) => <>{activeKey === `/manage/news/draft/${props.match.params.newsId}` && <NewsForm key={props.match.params.newsId} {...props} />}</>} />
            <Route path="/manage/news/clone/:newsId" exact render={(props) => <>{activeKey === `/manage/news/clone/${props.match.params.newsId}` && <NewsForm key={props.match.params.newsId} {...props} />}</>} />
            <Route path="/manage/news/create" exact render={(props) => <>{activeKey === "/manage/news/create" && <NewsForm key="-1" {...props} />}</>} />


            {/* Manage Notifications */}
            <Route path={ROUTE.notification} exact render={(props) => <>{activeKey === ROUTE.notification && <ManageNotifications {...props} />}</>} />
            <Route path="/manage/notifications/create" exact render={(props) => <>{activeKey === "/manage/notifications/create" && <NotificationsForm key="-1" {...props} />}</>} />
            <Route path="/manage/notifications/edit/:notificationId" exact render={(props) => <>{activeKey === `/manage/notifications/edit/${props.match.params.notificationId}` && <NotificationsForm key={props.match.params.notificationId} {...props} />}</>} />
            <Route path="/manage/notifications/draft/:notificationId" exact render={(props) => <>{activeKey === `/manage/notifications/draft/${props.match.params.notificationId}` && <NotificationsForm key={props.match.params.notificationId} {...props} />}</>} />
            <Route path="/manage/notifications/clone/:notificationId" exact render={(props) => <>{activeKey === `/manage/notifications/clone/${props.match.params.notificationId}` && <NotificationsForm key={props.match.params.notificationId} {...props} />}</>} />

            {/* Manage Support */}
            <Route path={ROUTE.support} exact render={(props) => <>{activeKey === ROUTE.support && <ManageSupport {...props} />}</>} />
            <Route path="/manage/support/create" exact render={(props) => <>{activeKey === "/manage/support/create" && <SupportForm key="-1" {...props} />}</>} />
            <Route path="/manage/support/edit/:supportId" exact render={(props) => <>{activeKey === `/manage/support/edit/${props.match.params.supportId}` && <SupportForm key={props.match.params.supportId} {...props} />}</>} />


            {/* Email Templates */}
            {/* Standard */}
            <Route path={ROUTE.standardEmail} exact render={(props) => <>{activeKey === ROUTE.standardEmail && <ManageStandardEmailTemplate {...props} />}</>} />
            <Route path="/manage/emailTemplates/standard/edit/:emailTemplateId" exact render={(props) => <>{activeKey === `/manage/emailTemplates/standard/edit/${props.match.params.emailTemplateId}` && <EmailTemplateForm key={props.match.params.emailTemplateId} {...props} />}</>} />

            {/* Custom */}
            <Route path={ROUTE.customEmail} exact render={(props) => <>{activeKey === ROUTE.customEmail && <ManageCustomEmailTemplates {...props} />}</>} />
            <Route path="/manage/emailTemplates/custom/edit/:emailTemplateId" exact render={(props) => <>{activeKey === `/manage/emailTemplates/custom/edit/${props.match.params.emailTemplateId}` && <EmailTemplateForm key={props.match.params.emailTemplateId} {...props} />}</>} />
            <Route path="/manage/emailTemplates/custom/version-details/:emailTemplateType/:emailTemplateId" exact render={(props) => <>{activeKey === `/manage/emailTemplates/custom/version-details/${props.match.params.emailTemplateType}/${props.match.params.emailTemplateId}` && <ManageTemplateVersions key={props.match.params.emailTemplateId} {...props} />}</>} />
            <Route path="/manage/emailTemplates/custom/maintenance/version-details/:emailTemplateType/:emailTemplateId" exact render={(props) => <>{activeKey === `/manage/emailTemplates/custom/maintenance/version-details/${props.match.params.emailTemplateType}/${props.match.params.emailTemplateId}` && <ManageMaintenanceTemplateVersions key={props.match.params.emailTemplateId} {...props} />}</>} />

            {/* Schedule Maintenance */}
            <Route path={ROUTE.maintenance} exact render={(props) => <>{activeKey === ROUTE.maintenance && <ScheduleMaintenance {...props} />}</>} />
            <Route path="/manage/schedule-maintenance/form/create" exact render={(props) => <>{activeKey === "/manage/schedule-maintenance/form/create" && <ScheduleMaintenanceForm key="-1" {...props} />}</>} />
            <Route path="/manage/schedule-maintenance/form/edit/:recordId" exact render={(props) => <>{activeKey === `/manage/schedule-maintenance/form/edit/${props.match.params.recordId}` && <ScheduleMaintenanceForm key={props.match.params.recordId} {...props} />}</>} />
            <Route path="/manage/schedule-maintenance/form/clone/:recordId" exact render={(props) => <>{activeKey === `/manage/schedule-maintenance/form/clone/${props.match.params.recordId}` && <ScheduleMaintenanceForm key={props.match.params.recordId} {...props} />}</>} />

            {/* Manage Data Center */}
            <Route path={ROUTE.dataCenter} exact render={(props) => <>{activeKey === ROUTE.dataCenter && <ManageDataCenters {...props} />}</>} />

            {/* Manage DIDS */}
            <Route path={ROUTE.dids} exact render={(props) => <>{activeKey === ROUTE.dids && <ManageDIDs {...props} />}</>} />

            {/* Manage Networks */}
            <Route path={ROUTE.networks} exact render={(props) => <>{activeKey === ROUTE.networks && <ManageNetworks {...props} />}</>} />

            {/* Manage Tips */}
            <Route path={ROUTE.tips} exact render={(props) => <>{activeKey === ROUTE.tips && <ManageTips {...props} />}</>} />
            <Route path="/manage/tips/create" exact render={(props) => <>{activeKey === "/manage/tips/create" && <TipForm key="-1" {...props} />}</>} />
            <Route path="/manage/tips/edit/:tipId" exact render={(props) => <>{activeKey === `/manage/tips/edit/${props.match.params.tipId}` && <TipForm key={props.match.params.tipId} {...props} />}</>} />

            {/* Manage Reports */}
            <Route path={ROUTE.customReports} exact render={(props) => <>{activeKey === ROUTE.customReports && <ManageReports {...props} />}</>} />
            <Route path={ROUTE.standardReports} exact render={(props) => <>{activeKey === ROUTE.standardReports && <ManageGeneralReports {...props} />}</>} />
            <Route path={ROUTE.userCreatedReports} exact render={(props) => <>{activeKey === ROUTE.userCreatedReports && <UserCreatedReports {...props} />}</>} />
            <Route path="/manage/reports/create" exact render={(props) => <>{activeKey === "/manage/reports/create" && <ReportForm key="-1" {...props} />}</>} />
            <Route path="/manage/reports/edit/:reportType/:reportId" exact render={(props) => <>{activeKey === `/manage/reports/edit/${props.match.params.reportType}/${props.match.params.reportId}` && <ReportForm key={props.match.params.reportId} {...props} />}</>} />
            <Route path="/manage/reports/clone/:reportType/:reportId" exact render={(props) => <>{activeKey === `/manage/reports/clone/${props.match.params.reportType}/${props.match.params.reportId}` && <ReportForm key={props.match.params.reportId} {...props} />}</>} />
            <Route path="/reports/save" exact render={(props) => <>{activeKey === "/reports/save" && <SaveReport {...props} />}</>} />

            <Route path="/manage/reports/neo/edit/:reportType/:reportId" exact render={(props) => <>{activeKey === `/manage/reports/neo/edit/${props.match.params.reportType}/${props.match.params.reportId}` && <ReportFormNeo key={props.match.params.reportId} {...props} />}</>} />

            {/* Manage Daily Testing */}
            <Route path={ROUTE.dailyReport} exact render={(props) => <>{activeKey === ROUTE.dailyReport && <ManageDailyReports {...props} />}</>} />
            <Route path={ROUTE.products} exact render={(props) => <>{activeKey === ROUTE.products && <ManageProducts {...props} />}</>} />
            <Route path={ROUTE.attributes} exact render={(props) => <>{activeKey === ROUTE.attributes && <ManageDailyReportAttributes {...props} />}</>} />
            <Route path="/manage/daily-attributes-form/create" exact render={(props) => <>{activeKey === "/manage/daily-attributes-form/create" && <DailyReportAttributeForm key="-1" {...props} />}</>} />
            <Route path="/manage/daily-attributes-form/edit/:attributeId" exact render={(props) => <>{activeKey === `/manage/daily-attributes-form/edit/${props.match.params.attributeId}` && <DailyReportAttributeForm key={props.match.params.attributeId} {...props} />}</>} />
            <Route path="/manage/daily-reports-form/create" exact render={(props) => <>{activeKey === "/manage/daily-reports-form/create" && <DailyReportForm key="-1" {...props} />}</>} />
            <Route path="/manage/daily-reports-form/edit/:reportId" exact render={(props) => <>{activeKey === `/manage/daily-reports-form/edit/${props.match.params.reportId}` && <DailyReportForm key={props.match.params.reportId} {...props} />}</>} />

            { /* Manage Demo Capability */}
            <Route path={ROUTE.demoCapability} exact render={(props) => <>{activeKey === ROUTE.demoCapability && <ManageDemoCapability {...props} />}</>} />
            <Route path="/manage/demo-capability-form/create" exact render={(props) => <>{activeKey === "/manage/demo-capability-form/create" && <DemoCapabilityForm key="-1" {...props} />}</>} />
            <Route path="/manage/demo-capability-form/edit/:capabilityId" exact render={(props) => <>{activeKey === `/manage/demo-capability-form/edit/${props.match.params.capabilityId}` && <DemoCapabilityForm key={props.match.params.capabilityId} {...props} />}</>} />

            <Route path="/manage/capability-request/details/:capabilityId" exact render={(props) => <>{activeKey === `/manage/capability-request/details/${props.match.params.capabilityId}` && <CapabilityRequestDetails key={props.match.params.capabilityId} {...props} />}</>} />
            { /* Manage Demo Capability Requests*/}
            <Route path={ROUTE.capabilityRequest} exact render={(props) => <>{activeKey === ROUTE.capabilityRequest && <CapabilityRequests {...props} />}</>} />

            { /* Manage Demo Capability Requests Details*/}
            <Route path="/manage/capability-request/details/:capabilityId" exact render={(props) => <>{activeKey === `/manage/capability-request/details/${props.match.params.capabilityId}` && <CapabilityRequestDetails key={props.match.params.capabilityId} {...props} />}</>} />
            <Route path="/emailRedirection/capability-details/:capabilityId" exact render={(props) => <>{activeKey === `/emailRedirection/capability-details/${props.match.params.capabilityId}` && <CapabilityRequestDetails key={props.match.params.capabilityId} {...props} />}</>} />

            {/* Manage EZ Demos */}
            <Route path={ROUTE.ezDemos} exact render={(props) => <>{activeKey === ROUTE.ezDemos && <EZDemosTable {...props} />}</>} />
            <Route path="/manage/ez-demos/create" exact render={(props) => <>{activeKey === "/manage/ez-demos/create" && <EZDemosForm key="-1" {...props} />}</>} />
            <Route path="/manage/ez-demos/edit/:ezDemoId" exact render={(props) => <>{activeKey === `/manage/ez-demos/edit/${props.match.params.ezDemoId}` && <EZDemosForm key={props.match.params.ezDemoId} {...props} />}</>} />
            <Route path="/manage/ez-demos/ratings/:ezDemoId" exact render={(props) => <>{activeKey === `/manage/ez-demos/ratings/${props.match.params.ezDemoId}` && <EZDemoReviews key={props.match.params.ezDemoId} {...props} />}</>} />

            {/* Manage SFDC Stats */}
            <Route path={ROUTE.sfdcStats} exact render={(props) => <>{activeKey === ROUTE.sfdcStats && <ManageSFDCStats {...props} />}</>} />
            <Route path={ROUTE.adminSettings} exact render={(props) => <>{activeKey === ROUTE.adminSettings && <AdminSettings {...props} />}</>} />

            {/* Misc */}
            <Route path="/my-profile" exact component={MyProfile} />
            <Route path="/home" exact component={HomeNeo} />
            <Route path="/home/non-sso-users" exact component={HomeNeo} />
            <Route path="/about-us" exact component={AboutUs} />

          </Switch>
        </div>}
      </div>
    );
  }
}

export default Content;

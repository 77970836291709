import React from "react";
import { Table, Form, Button, Divider, Icon, Row, Col, message, Dropdown } from "antd";
import { Icon as NeoIcon } from "@avaya/neo-react";
import { exportMenu } from "../../utils/strings";
import {
    toggleBackButton,
    selectTitle,
    toggleIsFetchingDailyAttributes,
    getAllDailyAttributes,
    clearAttributes,
    deleteDailyReportAttribute,
    restoreDailyAttribute,
    exportTable,
    currentAttributeFilters,
    handleOnClickAction,
} from "../../actions";
import { connect, batch } from "react-redux";
import FilterPanel from '../common/FilterPanel';
import pushMessage from '../common/PushMessage';
import openConfirm from '../common/Confirm';
import CONFIG from '../../config';
import { isTabAlreadyOpen } from "../common/TabLayout";
export const ManageDailyReportAttributesContext = React.createContext();

class ManageDailyReportAttributes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filteredData: undefined,
            sortedInfo: {},
            showFilter: false,
            filters: {},
            isViewDeletedItemsChecked: false,
        }

        this.tableRef = React.createRef();

    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentAttributeFilters(this.state.filters, this.state.sortedInfo, this.state.isViewDeletedItemsChecked, this.titleSearchString);
        } else {
            this.props.clearAttributes();
        }
    }

    async componentDidMount() {
        const { lastFilter, isTabOpen, searchString, lastSortInfo, isViewDeleted } = this.props;
        if (isTabOpen) {
            await this.setState({

                filters: lastFilter,
                sortedInfo: lastSortInfo,
                isViewDeletedItemsChecked: isViewDeleted

            })

            const currentFilterSize = lastFilter !== undefined ? Object.keys(lastFilter)?.length : 0;
            if (currentFilterSize > 0) {
                this.setState({ showFilter: true })
            }
            this.titleSearchString = searchString
        }
        this.setState({ currentPath: window.location.pathname })
        batch(async () => {
            this.props.toggleBackButton(false);
            this.props.selectTitle('Manage Attributes');
            this.props.toggleIsFetchingDailyAttributes(true);
            !this.state.isViewDeletedItemsChecked ? await this.props.getAllDailyAttributes() : await this.props.getAllDailyAttributes(this.state.isViewDeletedItemsChecked)
        });

    }

    getFilteredData = (attributeName) => {
        const { data } = this.props;
        let filteredData = attributeName ?
            data?.filter(attribute => attribute?.attributeName?.toLowerCase().includes(attributeName?.toLowerCase()))
            : data;

        return filteredData
    }

    filterRecords = (filters) => {
        this.setState({ filters: filters })
        let { attributeName, viewDeletedItems } = filters;
        attributeName = attributeName?.trim();
        if (!attributeName && !viewDeletedItems) {
            pushMessage(CONFIG.messageType.warning, "Please enter search text to filter");
            return;
        }
        this.titleSearchString = attributeName;
        let filteredData = this.getFilteredData(attributeName)
        this.setState({ filteredData });
    }

    renderDeletedRecord = (filters) => {
        const { viewDeletedItems } = filters || {};
        (viewDeletedItems || this.state.isViewDeletedItemsChecked) ? this.props.getAllDailyAttributes(viewDeletedItems).then(async () => {
            await this.setState({ isViewDeletedItemsChecked: viewDeletedItems })
            this.filterRecords(filters);
        }) : this.filterRecords(filters);
    }

    handleFilter = (filters) => {
        this.renderDeletedRecord(filters);
    }

    handleClear = async (isRefresh) => {
        await this.props.toggleIsFetchingDailyAttributes(true);
        if (!isRefresh) {
            await this.setState({ filters: {} })
            this.titleSearchString = undefined;
            this.state.isViewDeletedItemsChecked && await this.props.getAllDailyAttributes();
            await this.setState({ isViewDeletedItemsChecked: false })
            this.setState({ filteredData: undefined });
        } else {
            !this.state.isViewDeletedItemsChecked ? await this.props.getAllDailyAttributes() : await this.props.getAllDailyAttributes(this.state.isViewDeletedItemsChecked)
        }
        await this.props.toggleIsFetchingDailyAttributes(false);
    }

    handleChange = (_, __, sorter) => {
        this.setState({
            sortedInfo: sorter
        });
    }

    handleDeleteRestore = (id, isRestore) => {
        const modalTitle = `Would you like to ${isRestore ? "restore" : this.state.isViewDeletedItemsChecked ? ' permanently delete' : 'delete'} this attribute?`;
        openConfirm("Yes", () => this.handleConfirmDeleteRestore(id, isRestore), null, null, modalTitle, null, "No");
    }

    handleConfirmDeleteRestore = async (id, isRestore) => {
        isRestore ? await this.props.restoreDailyAttribute(id) : this.state.isViewDeletedItemsChecked ? await this.props.deleteDailyReportAttribute(id, true) : await this.props.deleteDailyReportAttribute(id);
        this.props.toggleIsFetchingDailyAttributes(true);
        await this.props.getAllDailyAttributes(isRestore);
    }

    handleExport = async (fileType) => {
        const { timeZoneName } = this.props;
        const exportData = this.getFilteredData(this.titleSearchString)
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        if (exportData?.length > 0 && exportData !== undefined) {
            exportTable(CONFIG.exportTable.attributes.name, exportData, timeZoneName, CONFIG.exportTable.attributes.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }


    render() {
        const { loading } = this.props;
        let { sortedInfo } = this.state;
        const tableData = this.getFilteredData(this.titleSearchString)
        const deleteView = this.state.isViewDeletedItemsChecked;
        sortedInfo = sortedInfo || {};

        const formFields = [
            {
                label: "Title",
                type: CONFIG.formFieldTypes.INPUT,
                key: "attributeName",
                value: this.state.filters.attributeName || ""
            }
        ]

        const columns = [
            {
                title: <span className={this.titleSearchString ? "filtered-column" : ''}>Title</span>,
                dataIndex: "attributeName",
                width: 120,
                sorter: (a, b) => a?.attributeName.toLowerCase().localeCompare(b?.attributeName.toLowerCase()),
                sortOrder: sortedInfo.columnKey === 'attributeName' && sortedInfo.order,
            },
            {
                title: <span>Associated Products</span>,
                dataIndex: "productAttributes",
                key: "productAttributes",
                width: 200,
                render: (productAttributes) => productAttributes?.map(product => { return product?.product?.title }).join(", "),
            },
            {
                title: "Actions",
                align: "center",
                width: 100,
                render: (record) => {
                    return (
                        <div>
                            {!deleteView &&
                                <>
                                    <button className={"link"}
                                        onClick={async () => {
                                            await this.props.handleOnClickAction({
                                                component: CONFIG.editComponentRoute.attribute,
                                                tabTitle: record?.attributeName,
                                                recordId: record?.id
                                            }
                                            )
                                        }}
                                    >
                                        <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span></button>
                                    {" "}
                                    <Divider type="vertical" />
                                    < button
                                        onClick={() => this.handleDeleteRestore(record.id, false)}
                                        className="link"
                                    >
                                        <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span>
                                    </button >
                                </>
                            }

                            {deleteView &&
                                <>
                                    < button
                                        onClick={() => this.handleDeleteRestore(record.id, true)}
                                        className="link"
                                    >
                                        <span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span>
                                    </button >
                                    {" "}
                                    <Divider type="vertical" />
                                    < button
                                        onClick={() => this.handleDeleteRestore(record.id, false)}
                                        className="link"
                                    >
                                        <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span>
                                    </button >
                                </>
                            }
                        </div >
                    );
                }
            }
        ];
        const { showFilter } = this.state
        return (
            <div>
                <Row className="vertical-spacing">
                    <Col xl={2} sm={6} xs={24} className="left-spacing">
                        <Button className="primary-action-button-filled"
                            onClick={async () => {
                                await this.props.handleOnClickAction({
                                    component: CONFIG.createComponentRoute.attribute,
                                    tabTitle: "Create"
                                })
                            }}
                        >
                            New
                            <Icon aria-label="aria-menu" type="plus-circle" />
                        </Button>
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoIcon aria-label="menu-aria" title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </Dropdown>
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                </Row>
                {
                    <>
                        <div className="responsive-container content-container">
                            <Row className="bottom-spacing">
                                <Col lg={20} xs={24} >
                                    {this.state.showFilter && <FilterPanel
                                        panelHeader="Filters and Export"
                                        label="View Deleted Attributes"
                                        formFields={formFields}
                                        handleClear={this.handleClear}
                                        handleFilter={this.handleFilter}
                                        viewDeletedItemsValue={this.state.isViewDeletedItemsChecked}
                                    />}
                                </Col>
                            </Row>
                            <Row><h3>{this.isViewDeletedItemsChecked ? 'List of Deleted Attributes' : "List of Attributes"}</h3>
                                <Col lg={20} xs={24} >
                                    <ManageDailyReportAttributesContext.Provider value={this.props.form}>
                                        <Table
                                            rowKey={(record) => record.id}
                                            className="responsive-container medium-content"
                                            bordered
                                            size="small"
                                            loading={loading}
                                            dataSource={tableData}
                                            columns={columns}
                                            onChange={this.handleChange}
                                            pagination={false}
                                            scroll={{ y: 550, scrollToFirstRowOnChange: false }}

                                        />
                                    </ManageDailyReportAttributesContext.Provider>
                                </Col>
                            </Row>
                        </div>
                    </>
                }
            </div >
        );
    }
}

const mapStateToProps = ({ user, dailyAttribute, tabsLayout }) => {
    return {
        data: dailyAttribute.data,
        loading: dailyAttribute.loading,
        templateType: user.profile.roleTemplate.templateType,
        lastFilter: dailyAttribute.filter,
        lastSortInfo: dailyAttribute.sortInfo,
        isViewDeleted: dailyAttribute.isViewDeleted,
        isTabOpen: dailyAttribute.isTabOpen,
        openedTabs: tabsLayout.panes,
        searchString: dailyAttribute.searchString
    };
};

const EditableFormTable = Form.create()(ManageDailyReportAttributes);

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        selectTitle,
        toggleIsFetchingDailyAttributes,
        getAllDailyAttributes,
        clearAttributes,
        deleteDailyReportAttribute,
        restoreDailyAttribute,
        currentAttributeFilters,
        handleOnClickAction,
    }
)(EditableFormTable);

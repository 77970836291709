import React from "react";
import { connect, batch } from "react-redux";
import { Row, Spin, Collapse, Form, Modal, Input, } from 'antd';
import { Table } from 'antd5';
import { Icon as NeoIcon, Button, Chip } from '@avaya/neo-react';
import { LoadingOutlined } from '@ant-design/icons';
import {
    selectTitle,
    toggleBackButton,
    fetchDemos,
    fetchDemosApprovalRequestById,
    toggleIsFetchingApprovalRequestsFlag,
    clearDemoApprovals,
    currentDemoApprovalFilter,
    refreshDemoApprovals,
    handleOnClickAction,
    toggleSaveReportVisible,
    approveOrDeclineScheduleRequest
}
    from '../../actions';
import CONFIG from '../../config';
import moment from 'moment-timezone';
import { toTitleCase } from "../../utils/strings";
import NoData from "../common/NoData";
import { getUserInformationCard } from "../../utils/getUserInformationCard";
const { Panel } = Collapse;
const { TextArea } = Input;
class DemoApprovalsDetails extends React.Component {

    constructor() {
        super()
        this.state = {
            loading: false,
            showCommentModal: false,
            clickedRecord: undefined,
            showFilter: false,
            collapsedRecord: [],
            height: `${document.body.clientHeight - 175}px`,
            drawerHeight: `${document.body.clientHeight - 100}px`,
        }
    }


    handleFetchApprovals = () => {
        this.props.toggleIsFetchingApprovalRequestsFlag(true);
        this.props.fetchDemosApprovalRequestById(this.props.match.params.scheduleId)
    }


    handleResize = (e) => {
        this.setState({ height: document.body.clientHeight > 250 ? `${document.body.clientHeight - 175}px` : `250px` });
        this.setState({ drawerHeight: document.body.clientHeight > 250 ? `${document.body.clientHeight - 50}px` : `250px` });
    }

    componentWillUnmount() {
        this.props.clearDemoApprovals();
    }

    async componentDidMount() {
        this.setState({ currentPath: window.location.pathname })
        this.setState({ loading: true })
        batch(() => {
            this.props.selectTitle("Schedule Approvals");
            this.handleFetchApprovals();
            this.props.toggleIsFetchingApprovalRequestsFlag(true);
            this.props.toggleBackButton(false);
        });
        localStorage.removeItem('redirectToApprovalPage')
        localStorage.removeItem('scheduleApprovalId');
        window.addEventListener("resize", this.handleResize);

    }

    handleActionButton = (typeOfApprove, approvalObj) => {
        this.setState({
            showCommentModal: true,
            clickedRecord: approvalObj,
            modalButtonText: typeOfApprove === "DECLINED" ? "Decline" : "Approve",
            typeOfApprove: typeOfApprove
        })
    }
    handleSubmit = () => {
        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            else {
                let userComment = this.props.form.getFieldValue('comment')
                userComment = userComment?.trim();
                let payload = {
                    ...this.state.clickedRecord,
                    "status": this.state.typeOfApprove,
                    "approversComment": userComment?.length > 0 ? userComment : undefined,
                    "scheduleId": this.state.clickedRecord?.scheduleId?.id
                }
                await this.props.approveOrDeclineScheduleRequest(payload)
                await this.setState({ showCommentModal: false })
                setTimeout(() => this.setState({
                    clickedRecord: undefined,
                    modalButtonText: undefined,
                    typeOfApprove: undefined
                }), 100)
                this.props.form.resetFields();
                this.handleFetchApprovals(true)
            }
        })
    }

    handleCancel = async () => {
        await this.setState({ showCommentModal: false })
        setTimeout(() => this.setState({
            clickedRecord: undefined,
            modalButtonText: undefined,
            typeOfApprove: undefined
        }), 100)
        this.props.form.resetFields();
    }

    renderRowInformation = (title, data) => {

        return (
            <div className="approval-details-column font-13px">
                <span className="bold">{title}:</span><br />{data}
            </div>
        )
    }

    handleCollapseChange = (keys) => {
        this.setState({ collapsedRecord: keys });
    };

    isPanelOpen = (panelKey) => {
        const { collapsedRecord } = this.state;
        return collapsedRecord.includes(panelKey);
    };



    fetchApprovalList = (schedule) => {
        let pastScheduleApprovers = schedule?.scheduleId?.scheduleApproval
        let futureScheduleApprovers;

        // pastScheduleApprovers contains approved as well as current level(Pending). Below logic will move pending object to futureScheduleApprovers
        if (pastScheduleApprovers?.length > 1) {
            futureScheduleApprovers = [...pastScheduleApprovers.slice(-1),
            ...schedule?.scheduleId?.demo?.demoApprovers?.filter(eachLevel => pastScheduleApprovers?.map(obj => obj.level).indexOf(eachLevel.level) === -1)]
            pastScheduleApprovers = pastScheduleApprovers.slice(0, -1)
        }
        else {
            futureScheduleApprovers = schedule?.scheduleId?.demo?.demoApprovers?.filter(eachLevel => pastScheduleApprovers?.map(obj => obj.level).indexOf(eachLevel.level) === -1)

        }

        // combining both pastScheduleApprovers & futureScheduleApprovers for easy looping
        let approversTree = [
            ...pastScheduleApprovers,
            ...futureScheduleApprovers
        ];

        let isRequestApproved = false
        let scheduleApprovers = [];

        approversTree.sort((a, b) => a.level - b.level);

        // Below logic is to create a simple object with User's detail's and status. We do not need details of remaining users, once SA overrides the request.
        // eslint-disable-next-line
        approversTree.some(function (obj) {
            if (!isRequestApproved) {
                const { level, status, approver, approvedDate, approversList, approvedBy } = obj;
                // Once the approval has reached its end before all approvers have acted, no need to show future pending approvers emails.
                if (status === "APPROVED_SA" || status === "DECLINED")
                    isRequestApproved = true;
                scheduleApprovers.push({
                    level,
                    approvedDate,
                    status: toTitleCase(status === undefined ? "PENDING" : status),
                    approver:
                        approversList
                            ? (status === "APPROVED" || status === "DECLINED") ? approvedBy?.email : approversList?.map(item => item.approversEmailId).join(', ')
                            : approver
                });
            }
        });
        return scheduleApprovers;

    }

    renderApprovalsCard = () => {
        const { timeZone, userRoleTemplate, user } = this.props

        let card = [];

        const demoApprovalsData = this.props.demoApprovalRequestData;

        for (let i = 0; i < demoApprovalsData?.length; i++) {
            const eachCard = demoApprovalsData?.[i]
            let scheduleApprovers = this.fetchApprovalList(eachCard)

            // Compute each Object
            let scheduleDetailsObj = {
                "Request No": eachCard?.scheduleId?.referenceNo,
                "Requested by": getUserInformationCard(eachCard?.scheduleId?.createdBy, eachCard?.scheduleId?.createdBy?.fullName),
                "Requested on": moment.tz(eachCard?.scheduleId?.createdTime, timeZone).format(CONFIG.dateFormats.userDateTime),
                "Schedule Start Time": moment.tz(eachCard?.scheduleId?.startTime, timeZone).format(CONFIG.dateFormats.userDateTime),
                "Schedule End Time": moment.tz(eachCard?.scheduleId?.endTime, timeZone).format(CONFIG.dateFormats.userDateTime),
                "Country": eachCard?.scheduleId?.country?.name,
                "Region": eachCard?.scheduleId?.region,
                "Selected Capabilities": eachCard?.scheduleId?.parameters ? eachCard?.scheduleId?.parameters?.replaceAll("\\", ", ") : null,
                "Manager's Email": eachCard?.scheduleId?.managerEmail,
                "Regional Prime Email": eachCard?.scheduleId?.regionalPrimeEmail,
                "Assignee": eachCard?.scheduleId?.assignedUser?.fullName,
                "Build Status": eachCard?.scheduleId?.buildStatus,
                "INC/IT No": eachCard?.scheduleId?.incNo,
                "Tenant Name": eachCard?.scheduleId?.tenantName,
                "Demo Purpose": eachCard?.scheduleId?.demoPurpose?.purpose,

            }
            let demoPurposeAttributes = {};
            eachCard?.scheduleId?.purposeAttributesSchedule?.forEach((item) => {
                demoPurposeAttributes[item?.demoPurposeAttribute?.attributeLabel] = item?.attributeValue
            });

            const approvalDetailsObj = { ...scheduleDetailsObj, ...demoPurposeAttributes }
            const rows = [];
            let currentRow = [];

            let detailObjCount = 0;
            for (const [key, value] of Object.entries(Object.fromEntries(
                Object.entries(approvalDetailsObj).filter(([key, value]) => value !== undefined && value !== null)
            ))) {
                currentRow.push({ [key]: value });
                detailObjCount++;

                if (detailObjCount % 3 === 0) {
                    rows.push(currentRow);
                    currentRow = [];
                }
            }

            if (currentRow.length > 0) {
                rows.push(currentRow);
            }

            const unCollapsedRow = rows?.slice(0, 2);

            const collapsedRow = rows?.slice(2);
            const { collapsedRecord } = this.state;

            const isRequestPending = eachCard?.status === CONFIG.approvalStatusTypes.pendingRequests.value
            const isSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin
            const isUserPartOfApprovalList = eachCard?.approversList?.map(eachUser => eachUser.approversEmailId?.toLowerCase()).includes(user?.profile?.email?.toLowerCase());
            const showApproveButton = isRequestPending && isUserPartOfApprovalList
            const showDeclineButton = (isSuperAdmin && isRequestPending) || (isRequestPending && isUserPartOfApprovalList)
            const showOverrideButton = (isSuperAdmin && isRequestPending)
            const eachCardJSX = (<div className="approval-details-card">
                <Collapse
                    activeKey={collapsedRecord} onChange={this.handleCollapseChange}
                    className="approvals-collapse"
                    bordered={false}
                >

                    <div className="approval-div">
                        <span className="items-on-either-side">
                            <h3>{eachCard?.scheduleId?.demo?.title}</h3>
                            <Chip variant={CONFIG.approvalRequestColors[`${this.state.approvalStatusTypes}`]} className="bottom-spacing-3">{CONFIG.approvalRequestStatus[`${this.state.approvalStatusTypes}`]}</Chip>
                        </span>
                        {unCollapsedRow.map((row, rowIndex) => (
                            <div key={rowIndex} className="approvals-row">
                                {row.map((col, colIndex) => (
                                    <div key={col.id} className="approval-details-column">
                                        {this.renderRowInformation(Object.keys(col), Object.values(col))}
                                    </div>
                                ))}

                            </div>
                        ))}
                        {!this.isPanelOpen(eachCard?.scheduleId?.id.toString()) ? <Row className="approvals-action-button right-align">
                            {showDeclineButton && <Button onClick={() => this.handleActionButton("DECLINED", eachCard)} status='alert' size="compact" className="left-spacing-3" variant="secondary"  ><NeoIcon aria-label="menu-aria" className="action-neo-button" icon="close" /> Decline</Button>}
                            {showApproveButton && <Button onClick={() => this.handleActionButton("APPROVED", eachCard)} status='default' size="compact" className="left-spacing-3 avaya-default-button" ><NeoIcon aria-label="menu-aria" className="action-neo-button" icon="check" />Approve</Button>}
                            {showOverrideButton && <Button onClick={() => this.handleActionButton("APPROVED_SA", eachCard)} status='default' size="compact" className="left-spacing-3 avaya-default-button" ><NeoIcon aria-label="menu-aria" className="action-neo-button" icon="message-read" />Override Approve</Button>}
                        </Row> : <br />}
                    </div>
                    <Panel
                        key={eachCard?.scheduleId?.id}
                        header={<div
                            className="font-13px neo-link no-underline">
                            {this.isPanelOpen(eachCard?.scheduleId?.id.toString()) ? "Hide information" : "View more information"}
                        </div>}>
                        {collapsedRow.map((row, rowIndex) => (
                            <>
                                <div key={rowIndex} className="approvals-row">
                                    {row.map((col, colIndex) => (
                                        <div key={col.id} className="approval-details-column">
                                            {this.renderRowInformation(Object.keys(col), Object.values(col))}
                                        </div>
                                    ))}
                                </div>
                            </>))
                        }
                        <br />
                        <div>
                            <span className="bold">Approval list</span>
                            <br />
                            <Table
                                className="approval-details-table"
                                pagination={false}
                                size="small"
                                columns={[
                                    {
                                        title: "Level",
                                        dataIndex: 'level',
                                        key: "level",
                                        align: "center",
                                        width: 50,
                                    },
                                    {
                                        title: "Status",
                                        dataIndex: 'status',
                                        key: "status",
                                        align: "center",
                                        width: 150,
                                        render: (status) => <>
                                            {status === CONFIG.approvalStatusTypes.pendingRequests.status
                                                ? <NeoIcon aria-label="menu-aria" icon="away" style={{ color: "#CE94EB", fontSize: "20px" }} />
                                                : (status === CONFIG.approvalStatusTypes.approvedRequests.status || status === "Approved_sa")
                                                    ? <NeoIcon aria-label="menu-aria" icon="available" style={{ color: "#088A08", fontSize: "20px" }} />
                                                    : <NeoIcon aria-label="menu-aria" icon="missed" style={{ color: "#DA291C", fontSize: "20px" }} />}&nbsp;{status === "Approved_sa" ? "Approved by Admin" : status}</>
                                    },
                                    {
                                        title: "Email ID",
                                        dataIndex: 'approver',
                                        key: "approver",
                                        align: "center",
                                        width: 50,
                                    },
                                    {
                                        title: "Date",
                                        dataIndex: 'approvedDate',
                                        key: "approvedDate",
                                        align: "center",
                                        width: 200,
                                        render: (date) => date && moment.tz(date, timeZone).format(CONFIG.dateFormats.userDateTime)
                                    }
                                ]}

                                dataSource={scheduleApprovers}
                            />
                        </div>
                        <Row className="right-align">
                            {showDeclineButton && <Button onClick={() => this.handleActionButton("DECLINED", eachCard)} status='alert' size="compact" className="left-spacing-3" variant="secondary"  ><NeoIcon aria-label="menu-aria" className="action-neo-button" icon="close" /> Decline</Button>}
                            {showApproveButton && <Button onClick={() => this.handleActionButton("APPROVED", eachCard)} status='default' size="compact" className="left-spacing-3 avaya-default-button" ><NeoIcon aria-label="menu-aria" className="action-neo-button" icon="check" />Approve</Button>}
                            {showOverrideButton && <Button onClick={() => this.handleActionButton("APPROVED_SA", eachCard)} status='default' size="compact" className="left-spacing-3 avaya-default-button" ><NeoIcon aria-label="menu-aria" className="action-neo-button" icon="message-read" />Override Approve</Button>}
                        </Row>
                    </Panel>
                </Collapse>
            </div>)
            card.push(eachCardJSX)
        }
        return card;
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div className="bottom-spacing">
                    <div>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.props.isFetching}>
                            <div id="component-content" className="approvals-pagination-row" style={{ maxHeight: this.state.height }}>
                                {this.props.demoApprovalRequestData?.length > 0
                                    ? this.renderApprovalsCard()
                                    : <NoData size="lg" />}
                            </div>
                        </Spin>
                        <Modal
                            title="Confirmation"
                            visible={this.state.showCommentModal}
                            onCancel={() => this.setState({ showCommentModal: false })}
                            footer={[
                                <Button variant="secondary" size="compact" className="left-spacing-3 avaya-default-button" onClick={this.handleCancel}>
                                    Cancel
                                </Button>,
                                <Button status='default' size="compact" className="left-spacing-3 avaya-default-button" onClick={this.handleSubmit} >
                                    {`${this.state.modalButtonText}`}
                                </Button>,
                            ]}
                        >
                            <Form.Item label={`Comment ${this.state.modalButtonText !== "Decline" ? " (Optional)" : ""}`}>
                                {getFieldDecorator("comment", {
                                    rules: [
                                        {
                                            required: this.state.modalButtonText === "Decline",
                                            message: "Comment is mandatory!",
                                        }
                                    ],
                                })(<TextArea />)}
                            </Form.Item>

                        </Modal>
                    </div>
                </div>
            </div>

        );
    }
}

let DemoApprovalsDetailsRef = Form.create()(DemoApprovalsDetails);

const mapStateToProps = ({ demoApprovals, user }) => {
    return {
        user,
        timeZone: user.profile.timezone,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        isFetching: demoApprovals.isFetching,
        demoApprovalRequestData: demoApprovals.demoApprovalRequestData
    };
};

export default connect(
    mapStateToProps,
    {
        selectTitle,
        toggleBackButton,
        fetchDemos,
        fetchDemosApprovalRequestById,
        toggleIsFetchingApprovalRequestsFlag,
        clearDemoApprovals,
        currentDemoApprovalFilter,
        refreshDemoApprovals,
        handleOnClickAction,
        toggleSaveReportVisible,
        approveOrDeclineScheduleRequest
    }
)(DemoApprovalsDetailsRef);
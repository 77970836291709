import React from 'react';
import { Row, Col, Alert, Form, DatePicker, Spin, Tooltip } from 'antd';
import { TextInput, Icon, Button, Spinner } from "@avaya/neo-react"
import { connect, batch } from 'react-redux';
import { Select } from 'antd5';
import CONFIG from '../../../config';
import moment from 'moment-timezone';
import debounce from 'lodash/debounce';
import {
    refreshScheduler,
    updateSchedulerTimezone,
    updateSchedulerStartEndTime,
    fecthDemoPurposeTypesLite,
    closeCurrentOpenTab,
    postNewDemoSchedule,
    clearAllNewEvents,
    fetchScheduleById,
    toggleIsFetchingSchedulerData,
    fetchAllCountries,
    deleteDashboardSchedule,
    toggleIsFetchingDashboardSchedules
} from '../../../actions';
import pushMessage from '../../common/PushMessage';
import { fetchUserNamesBySearchString, getUserDemoAccess, fetchTimeslotForLabDemos } from '../../../actions/scheduler'
import NeoLabDemoDetailsPage from './NeoLabDemoDetailsPage';
import { createScheduleObject, getInitialValue, checkDeletedPurpose } from "../../common/Scheduler"
import LabDemoConfirmationModal from './LabDemoConfirmationModal';
import { LoadingOutlined } from '@ant-design/icons';
import ScheduleTimeDetails from './ScheduleTimeDetails';
import ScheduleDeleteConfirmation from '../../common/ScheduleDeleteConfirmation';
import SchedulerHelpButton from '../../common/SchedulerHelpButton';
import SchedulingAssistant from '../NeoStandardDemo/SchedulingAssistant';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option, OptGroup } = Select;

class NeoLabDemoScheduleForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: props?.schedule ? moment.tz(props?.schedule.startTime, props.timezone) : moment().tz(props.timezone).add(1, 'day').startOf('day'),
            endDateTime: props?.schedule ? moment.tz(props?.schedule.startTime, props.timezone) : null,
            scheduleParticipants: props?.currentSchedule?.participants ? [...props.currentSchedule?.participants?.eapUsers, ...props.currentSchedule?.participants?.extUsers] : [],
            matchedUserNames: [],
            isLoadingUser: false,
            isStepOne: true,
            demoRequestStatus: 2,
            demoAccess: true,
            isActiveUser: true,
            isFormDisabled: false,
            isModalVisible: false,
            isLoadingPage: false,
            selectedUser: props?.currentSchedule ? props?.currentSchedule?.createdBy : props?.user,
            selectedPurpose: [],
            isCurrentSchedule: false,
            managers: props?.schedule ? (props?.schedule?.demo?.regionalPrimes || []) : (props?.demo?.regionalPrimes || []),
            suggestedTimeSlots: [],
            isUserSelectedTimeAvailable: true,
            isFetchingAvailableTime: false,
            deleteModalOpen: false,
            showPulse: true,
            scheduleAssistantModal: false
        }
        this.fetchUserNames = debounce(this.fetchUserNames, 800);
    }

    handleCurrentScheduleChange = async (flag) => {
        await this.setState({ isCurrentSchedule: flag })
    }

    async componentDidMount() {
        const { timezone, userTimeZone, demo, currentSchedule, isReschedule, schedule } = this.props;
        const isEditSchedule = window.location.pathname.includes("/scheduler/lab") && window.location.pathname.includes("edit");
        if (currentSchedule !== undefined && currentSchedule !== null) {
            this.setState({
                selectedPurpose: currentSchedule?.demoPurpose,
                isCurrentSchedule: true
            })
            await this.props.fecthDemoPurposeTypesLite(currentSchedule?.createdBy?.id);
        }
        !currentSchedule && this.handleCheckTestingPurpose();

        let schedulerStartTime;
        let schedulerEndTime;

        if (isReschedule) {
            this.handleCheckTestingPurpose();
            this.setState({
                selectedPurpose: undefined,
                isCurrentSchedule: false,
                scheduleParticipants: []
            })
        }

        await this.setState({ isFetchingAvailableTime: true })
        const suggestedTimeSlots = await fetchTimeslotForLabDemos({ demoId: demo.id, organizerId: (this?.state?.selectedUser?.id !== this?.props?.user?.id) ? this?.state?.selectedUser?.id : undefined, startTime: moment().tz(timezone).add(1, 'day').startOf('day').toISOString() });
        await this.setState({ isFetchingAvailableTime: false })
        if (!isEditSchedule || isReschedule) {

            const intialTime = suggestedTimeSlots?.recommendedTimes?.[0];
            schedulerStartTime = intialTime ? moment(intialTime).tz(timezone).toISOString() : moment().tz(timezone).add(1, 'day').startOf('day').toISOString();
            schedulerEndTime = moment(schedulerStartTime).tz(timezone).add(isEditSchedule ? schedule?.demo?.scheduleRange : demo?.scheduleRange, "day").toISOString();
            this.setState({
                endDateTime: moment(schedulerEndTime).tz(timezone),
                startTime: moment(schedulerStartTime).tz(timezone),
                startDate: moment(schedulerStartTime).tz(timezone).startOf('day'),
                suggestedTimeSlots: suggestedTimeSlots?.recommendedTimes || []
            })
        } else {
            schedulerStartTime = this.state.startDate.tz(timezone).toISOString();
            schedulerEndTime = moment(schedulerStartTime).tz(timezone).add(isEditSchedule ? schedule?.demo?.scheduleRange : demo?.scheduleRange, "day").toISOString();
            this.setState({ endDateTime: moment(schedulerEndTime).tz(timezone), suggestedTimeSlots: suggestedTimeSlots?.recommendedTimes || [] })
        }

        const isActivePurpose = this.props.userPurposeTypes?.find((purpose) => purpose.id === currentSchedule?.demoPurpose?.id);
        this.isPurposeDeleted = currentSchedule ? (isActivePurpose === undefined || checkDeletedPurpose(currentSchedule?.demoPurpose)) : false;

        await this.props.updateSchedulerTimezone(timezone || userTimeZone);
        await this.props.fetchAllCountries();
        setTimeout(() => {
            this.setState({
                showPulse: false
            })
        }, 6700)
    }

    handleStartDateChange = async (date) => {
        await this.setState({ startDate: date.startOf('day') })
        this.handleDateTimeChange();
    };

    handleStartTimeChange = async (time) => {
        await this.setState({ startTime: time })
        this.handleDateTimeChange();
    };

    handleDateTimeChange = async () => {
        // Convert the start and end dates to moment objects
        const { startDate } = this.state
        const { timezone, demo, schedule } = this.props;
        const isEditSchedule = window.location.pathname.includes("/scheduler/lab") && window.location.pathname.includes("edit");

        const startDateTime = startDate.tz(timezone).toISOString();
        const endDateTime = moment(startDateTime).tz(timezone).add(isEditSchedule ? schedule?.demo?.scheduleRange : demo?.scheduleRange, "day").toISOString();
        const bufferTime = demo?.startTimeBuffer / 60;
        const start = moment(startDateTime).tz(timezone).subtract(bufferTime, "hours");
        const startBufferTime = moment.tz(moment(), timezone).add(7, "minutes");
        if (start < startBufferTime) {
            pushMessage(CONFIG.messageType.warning, "Selected time conflicts with start buffer time.")
            this.setState({ endDateTime: null, isFormDisabled: true })
            return;
        }
        await this.setState({ isFetchingAvailableTime: true });
        const suggestedTimeSlots = await fetchTimeslotForLabDemos({ demoId: demo.id, startTime: startDateTime, scheduleId: isEditSchedule ? schedule?.id : undefined, organizerId: (this?.state?.selectedUser?.id !== this?.props?.user?.id) ? this?.state?.selectedUser?.id : undefined });
        await this.setState({ isFetchingAvailableTime: false });
        if (!suggestedTimeSlots?.userSelectedTime || suggestedTimeSlots?.userSelectedTime == null) {
            pushMessage(CONFIG.messageType.warning, "Selected time not available please select different time")
            await this.setState({ endDateTime: null, suggestedTimeSlots: suggestedTimeSlots?.recommendedTimes, isFormDisabled: true, isUserSelectedTimeAvailable: false })
        } else {
            await this.setState({ isCurrentSchedule: false, endDateTime: moment(endDateTime).tz(timezone), isFormDisabled: false, isUserSelectedTimeAvailable: true, suggestedTimeSlots: suggestedTimeSlots?.recommendedTimes })
        }
    }

    renderTimeZoneOptions = () => {
        const timezones = moment.tz.names();
        return timezones.map((timezone, index) => {
            return <Option key={index} value={timezone}>{timezone}</Option>;
        });
    }

    handleTimezoneChange = async (value) => {
        const { timezone, demo } = this.props;
        let { endDateTime, startDate, schedule } = this.state;
        this.props.timeZoneChanged(false);
        // Convert utc back to the start time visible to the user on the scheduler
        const oldTZStartTime = startDate.tz(timezone);
        const oldTZEndTime = endDateTime;
        const isEditSchedule = window.location.pathname.includes("/scheduler/lab") && window.location.pathname.includes("edit");

        // Keep the same start time visible to the user while changing only the timezone and then convert to utc
        const newStartTime = oldTZStartTime.tz(value, true);
        const newEndTime = oldTZEndTime.tz(value, true);
        await this.setState({ isFetchingAvailableTime: true });
        const suggestedTimeSlots = await fetchTimeslotForLabDemos({ demoId: demo.id, startTime: newStartTime?.toISOString(), scheduleId: isEditSchedule ? schedule?.id : undefined, organizerId: (this?.state?.selectedUser?.id !== this?.props?.user?.id) ? this?.state?.selectedUser?.id : undefined });
        await this.setState({ isFetchingAvailableTime: false });
        if (!suggestedTimeSlots?.userSelectedTime || suggestedTimeSlots?.userSelectedTime == null) {
            pushMessage(CONFIG.messageType.warning, "Selected time not available please select different time")
            await this.setState({ endDateTime: null, suggestedTimeSlots: suggestedTimeSlots?.recommendedTimes, isFormDisabled: true, isUserSelectedTimeAvailable: false })
        } else {
            await this.setState({ isCurrentSchedule: false, endDateTime: newEndTime, isFormDisabled: false, isUserSelectedTimeAvailable: true, suggestedTimeSlots: suggestedTimeSlots?.recommendedTimes })
        }

        batch(async () => {
            this.props.updateSchedulerTimezone(value);
            await this.setState({ isCurrentSchedule: false, endDateTime: newEndTime, startDate: newStartTime })
            this.props.refreshScheduler(true);
            setTimeout(() => {
                this.props.timeZoneChanged(true);
            }, 1000);
        })
    }

    handleAssigneeChange = async (userObj) => {
        const user = JSON.parse(userObj);
        const { demo, schedule } = this.props;
        const isEditSchedule = window.location.pathname.includes("/scheduler/lab") && window.location.pathname.includes("edit");
        const { startDate } = this.state;
        await this.props.fecthDemoPurposeTypesLite(user?.id);
        await this.setState({ selectedUser: user })
        if (user.isActive) {
            await getUserDemoAccess(demo.id, user).then(result => {
                const demoAccess = result[0];
                const demoRequestStatus = result[1];
                const { isPreRegistrationRequired } = demo || false;


                if (demoAccess) {
                    this.setState({ isFormDisabled: false, demoAccess: true, isActiveUser: true })
                    if (user?.id !== this.props.user?.id && isPreRegistrationRequired) {
                        if (demoRequestStatus < CONFIG.demoRequestAccessStatus.approved) {
                            this.setState({ isFormDisabled: true, demoRequestStatus: demoRequestStatus })
                            return;
                        }
                        else this.setState({ isFormDisabled: false, demoRequestStatus: demoRequestStatus })
                    }
                }
                else this.setState({ isFormDisabled: true, demoAccess: false, isActiveUser: true })
            })
        }
        else {
            this.setState({ isFormDisabled: true, isActiveUser: false, demoAccess: true })
        }
        await this.setState({ isFetchingAvailableTime: true });
        const suggestedTimeSlots = await fetchTimeslotForLabDemos({ demoId: demo.id, startTime: startDate.toISOString(), scheduleId: isEditSchedule ? schedule?.id : undefined, organizerId: (this?.state?.selectedUser?.id !== this?.props?.user?.id) ? this?.state?.selectedUser?.id : undefined });
        await this.setState({ isFetchingAvailableTime: false });
        if (!suggestedTimeSlots?.userSelectedTime || suggestedTimeSlots?.userSelectedTime == null) {
            pushMessage(CONFIG.messageType.warning, "Selected time not available please select different time")
            await this.setState({ endDateTime: null, suggestedTimeSlots: suggestedTimeSlots?.recommendedTimes, isFormDisabled: true, isUserSelectedTimeAvailable: false })
        } else {
            await this.setState({ isCurrentSchedule: false, isFormDisabled: false, isUserSelectedTimeAvailable: true, suggestedTimeSlots: suggestedTimeSlots?.recommendedTimes })
        }
        //check the user access for the demo if is active.
        await this.setState({ isCurrentSchedule: false })
    }

    renderUserNamesOptions = () => {
        return this.state.matchedUserNames?.map((item, index) => {
            return <Option key={index} value={JSON.stringify(item)} title={item.fullName}>
                {`${item.fullName} (${item.sslHandle})`}
            </Option>
        })
    }

    fetchUserNames = searchString => {
        this.setState({ matchedUserNames: [], isLoadingUser: true });
        fetchUserNamesBySearchString(searchString)
            .then((result) => {
                this.setState({ isLoadingUser: false });
                if (result?.length !== 0) {
                    this.setState({ matchedUserNames: result })
                }
            });
    };

    disabledStartDate = (start) => {
        const { timezone } = this.props;
        const now = start && start < (moment.tz(moment().add(1, 'day'), timezone).endOf('day').subtract(1, 'day'))
        return now
    };

    disabledEndDate = end => {
        const { timezone } = this.props;
        const now = end && end < (moment.tz(moment(this.state.startDate), timezone).endOf('day').subtract(1, 'day'))
        return now
    };

    calculateDisabledHoursAndMinutes = () => {
        const { timezone } = this.props;
        const disabledHours = [];
        const disabledMinutes = [];
        const startDate = this.props.form.getFieldValue("startDate");
        const now = moment.tz(moment(), timezone);

        if (startDate.tz(timezone).isBefore(now)) {
            for (let i = 0; i < now.hour(); i++) {
                disabledHours.push(i);
            }
            if (disabledHours.includes(now.hour())) {
                for (let i = 0; i <= now.minute(); i += 30) {
                    disabledMinutes.push(i);
                }
            }
        }

        return [
            disabledHours,
            disabledMinutes,
        ];
    }

    calculateDisabledHoursAndMinutesForEndTime = () => {
        const { timezone } = this.props;
        const disabledHours = [];
        const disabledMinutes = [];
        const startDateTime = moment(this.props.startTime).tz(timezone);
        const endDate = this.props.form.getFieldValue("endDate");

        if (endDate.tz(timezone).isBefore(startDateTime)) {

            for (let i = 0; i < startDateTime.hour(); i++) {
                disabledHours.push(i);
            }
            if (disabledHours.includes(startDateTime.hour())) {
                for (let i = 0; i <= startDateTime.minute(); i += 30) {
                    disabledMinutes.push(i);
                }
            }
        }

        return [
            disabledHours,
            disabledMinutes,
        ];
    }

    handleSchedule = (approvalRequired) => {
        const { form, demo, timezone, user, isReschedule, currentSchedule, schedule, customerInfo } = this.props;
        const { additionalDemoQuestions } = demo || false;
        const isEditSchedule = window.location.pathname.includes("/scheduler/lab") && window.location.pathname.includes("edit");
        this.handleModalClose();
        form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoadingPage: true })
                const currentStartTime = this.state.startDate.tz(timezone).toISOString();
                const currentEndTime = this.state.endDateTime?.toISOString();
                const country = values.country !== undefined ? JSON.parse(values.country) : null
                const demoParameters = values?.parameters && values.parameters !== "" ? values.parameters.join(CONFIG.delimiters.selectFilter) : ""
                const selectedPurpose = this.props.form.getFieldValue("demoPurpose");
                const data = getInitialValue(selectedPurpose ? JSON.parse(this.props.form.getFieldValue("demoPurpose")) : null, this.props.form);
                const purposeAttributesSchedule = createScheduleObject(data, false);

                let userAnswers = {}
                const preRequisiteQuestionObj = additionalDemoQuestions;
                if (preRequisiteQuestionObj?.length > 0) {
                    const formValues = Object.entries(values).map(([questionId, answerText]) => ({ questionId, answerText }))?.filter((el) => {
                        return preRequisiteQuestionObj?.some((f) => {
                            return f?.question?.id === parseInt(el?.questionId);
                        });
                    });

                    const userAnswersObj = formValues.map(fv => {
                        fv.questionOptions = preRequisiteQuestionObj?.filter(dq => dq.question?.id === parseInt(fv.questionId))?.[0]?.question?.answers?.map(a => a.title).join('//') || null;
                        return fv;
                    })


                    userAnswers = userAnswersObj?.map(obj => ({
                        question: { id: parseInt(obj.questionId) },
                        questionOptions: obj.questionOptions,
                        answerText: Array.isArray(obj?.answerText) ? obj?.answerText.join('//') : obj?.answerText
                    }))
                }

                let opportunity;
                if (values?.doNotHaveOpportunity && values.doNotHaveOpportunity === true) {
                    opportunity = {
                        id: currentSchedule && currentSchedule?.opportunity ? isReschedule ? undefined : currentSchedule?.opportunity?.id : undefined,
                        accountOwnerEmail: values?.accountOwnerEmail ? values?.accountOwnerEmail : isReschedule ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.accountOwnerEmail : undefined,
                        customerName: this.props.form.getFieldValue(`Customer Name`)?.trim()
                    }
                } else {
                    opportunity = {
                        id: currentSchedule && currentSchedule?.opportunity ? isReschedule ? undefined : currentSchedule?.opportunity?.id : undefined,
                        opportunityNumber: customerInfo ? customerInfo?.opportunity?.otnNumber : isReschedule ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.opportunityNumber : undefined,
                        sfdcOpportunityId: customerInfo ? customerInfo?.opportunity?.id : isReschedule ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.sfdcOpportunityId : undefined,
                        validatedBy: customerInfo && customerInfo?.opportunity ? user?.email : isReschedule ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.validatedBy : undefined,
                        accountOwnerEmail: values?.accountOwnerEmail ? values?.accountOwnerEmail : isReschedule ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.accountOwnerEmail : undefined,
                        sfdcTaskId: isReschedule ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.sfdcTaskId : undefined,
                        sfdcTaskStatus: isReschedule ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.sfdcTaskStatus : undefined,
                        customerName: customerInfo && customerInfo?.opportunity ? customerInfo?.opportunity?.AccountName : this.props.form.getFieldValue(`Customer Name`)?.trim()
                    }
                }
                const requireOpportunity = values.demoPurpose ? JSON.parse(values.demoPurpose)?.demoPurposeAttributes?.some(attribute =>
                    attribute.hasOwnProperty('type') && attribute.type !== null && attribute.type !== '' && attribute.type !== undefined
                ) : false;

                const scheduleObj = {
                    createdBy: isReschedule ? user : currentSchedule ? currentSchedule.createdBy : values.assignee ? JSON.parse(values.assignee) : user,
                    demoPurpose: JSON.parse(values.demoPurpose),
                    endTimeBuffer: 0,
                    id: isReschedule ? undefined : currentSchedule ? currentSchedule?.id : undefined,
                    purposeAttributesSchedule: purposeAttributesSchedule,
                    startTime: currentStartTime,
                    endTime: currentEndTime,
                    startTimeBuffer: demo.startTimeBuffer || 0,
                    demo,
                    userAnswers: Object.keys(userAnswers)?.length === 0 ? null : userAnswers,
                    isPreRegistration: demo?.isPreRegistration,
                    country,
                    approvalRequired: approvalRequired,
                    scheduleApproval: !isReschedule ? (isEditSchedule ? schedule?.scheduleApproval : demo?.scheduleApproval) : undefined,
                    managerEmail: values.managersEmail ? values.managersEmail.trim() : undefined,
                    regionalPrimeEmail: values?.regionalPrimeEmail ? values?.regionalPrimeEmail?.trim() : undefined,
                    parameters: demoParameters,
                    opportunity: requireOpportunity ? opportunity && !Object.values(opportunity).every(value => value === undefined) ? opportunity : undefined : undefined,
                    notes: values?.notes
                };

                await this.props.postNewDemoSchedule(scheduleObj, false, demo?.demoType, false, approvalRequired)
                    .then(async (scheduleId) => {

                        this.props.clearAllNewEvents();
                        this.props.refreshScheduler(true);
                        form.resetFields();
                    })
                this.setState({ isLoadingPage: false });

            }
            else {
                pushMessage(CONFIG.messageType.warning, 'Please verify the fields');
                this.handleCheckTestingPurpose();
            }
        });
    }

    handleCheckTestingPurpose = async () => {
        this.testingGroup = this.props.userPurposeTypes.filter((purposeType) => { return (purposeType.purpose === "Testing" || purposeType.id === 5) })
        if (this.testingGroup?.length > 0) {
            await this.setState({
                selectedPurpose: this.testingGroup[0]
            });
        }
    }

    renderDemoErrorAlert = () => {
        const { demoAccess, isActiveUser } = this.state;
        const message = !demoAccess ? "The selected user does not have access to the demo" : !isActiveUser ? "The selected user is currently inactive" : null;
        return (<Alert
            message={message}
            type="error"
            showIcon
        />)
    }

    renderDemoRequestErrorAlert = () => {
        const { demoRequestStatus, isActiveUser } = this.state;
        const message = demoRequestStatus === 0
            ? "This demo needs to be requested by the selected user before scheduling."
            : demoRequestStatus === 1
                ? "Request for this demo by the selected user is yet to be approved."
                : !isActiveUser ? "The selected user is currently inactive" : null;
        return (<Alert
            message={message}
            type="error"
            showIcon
        />)
    }

    handleModalClose = async () => {
        await this.setState({ isModalVisible: false });
    }

    handleParticipantsChange = (participants) => {
        this.setState({
            scheduleParticipants: participants,
            isCurrentSchedule: false
        })
    }

    renderCountryOptions = () => {
        const { countries } = this.props;
        return countries?.map(country => {
            return (<Option key={country.id} value={JSON.stringify({ id: country.id, name: country.name })}>{country.name}</Option>
            );
        });
    }

    renderManagers = () => {
        const { managers } = this.state;
        const { profile } = this.props;
        let keys = Object.keys(managers);
        keys.sort((a, b) => {
            if (profile?.region?.name === a?.replace('List', "").toUpperCase()) return -1;
            if (profile?.region?.name !== a?.replace('List', "").toUpperCase()) return 1;
            return 0;
        })
        if (keys.length > 0) {
            //eslint-disable-next-line
            return keys?.map(key => {
                if (managers[key]?.length > 0) {
                    return <OptGroup
                        label={<span style={{ fontWeight: "600", color: "black", fontFamily: 'Noto Sans', }}>{key?.replace('List', "").toUpperCase()} {profile?.region?.name === key?.replace('List', "").toUpperCase() ? "(Recommended)" : null}</span>}
                    >
                        {
                            managers[key]?.map((manager, index) => {
                                return (
                                    <Option key={index} value={manager} title={manager}>
                                        {manager}
                                    </Option>
                                )
                            })
                        }
                    </OptGroup>
                }
            })
        }
        else return []
    }

    handleSuggestedTimeSelect = (selectedTime) => {
        const { timezone, schedule, demo, form } = this.props;
        const isEditSchedule = window.location.pathname.includes("/scheduler/lab") && window.location.pathname.includes("edit");
        const startDateTime = moment(selectedTime).tz(timezone);
        const endDateTime = moment(startDateTime).tz(timezone).add(isEditSchedule ? schedule?.demo?.scheduleRange : demo?.scheduleRange, "day")
        this.setState({
            endDateTime: endDateTime,
            startTime: startDateTime,
            startDate: startDateTime,
            isFormDisabled: false,
            isUserSelectedTimeAvailable: true
        })
        form.setFieldsValue({ startDate: startDateTime })
    }

    handleNext = () => {
        const { form } = this.props;
        form.validateFields(['country', 'assignee', 'startDate', 'managersEmail', 'regionalPrimeEmail'], async (err) => {
            if (err) {
                pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                return
            }
            else {
                this.setState({ isStepOne: false })
            }
        })
    }

    handleDeleteSchedule = async () => {
        const record = this.props.currentSchedule;
        this.props.toggleIsFetchingDashboardSchedules(true)
        await this.props.deleteDashboardSchedule(record?.id, record?.demo?.demoType).then(() => {
            this.props.closeCurrentOpenTab()
        })
        this.setState({ deleteModalOpen: false });
    }

    handleOpenConfirmationSchedule = () => {
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                return;
            }
            else this.setState({ isModalVisible: true })
        });
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { demo, timezone, userTimeZone, user, currentSchedule, isReschedule, schedule } = this.props;
        const { scheduleParticipants } = this.state;
        const isEditSchedule = window.location.pathname.includes("/scheduler/lab") && window.location.pathname.includes("edit");
        const isAdmin = (user.roleTemplate.templateType === CONFIG.roleTypes.admin || user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin)
        const showOrganiser = isAdmin && !currentSchedule && (scheduleParticipants && scheduleParticipants?.length === 0)
        const { isManagerEmail, isManagerMandatory, regionalPrimeTitle, managerTitle, isRegionalPrime } = (isEditSchedule ? schedule?.demo : demo) || []
        const { suggestedTimeSlots, isUserSelectedTimeAvailable } = this.state;
        const isSelectedTimeAvailable = isUserSelectedTimeAvailable;
        const managerEmail = getFieldValue("managersEmail");
        const regionalPrimeEmail = getFieldValue("regionalPrimeEmail");
        const approvalRequired = !isReschedule ? isEditSchedule ? schedule?.demo?.approvalRequired : demo.approvalRequired : demo.approvalRequired;
        const ScheduleButtonText = demo.demoType === CONFIG.demoTypes.restfulApiDemo
            ? ((currentSchedule && !isReschedule) ? 'Update' : 'Schedule')
            : ((currentSchedule && !isReschedule) ? `Update ${approvalRequired ? "Request" : "Schedule"}` : approvalRequired ? 'Request Approval' : "Schedule")
        const available = <span><Icon aria-label="aria-menu" icon="available-filled" /> Available</span>;

        const notAvailable = <span><Icon aria-label="aria-menu" icon="warning-filled" /> Not Available <Tooltip title="Selected date is not available to schedule due to maximum number of simultaneous PoCs allowed reached. Please select from one of the suggested dates below." placement="topLeft" overlayStyle={{ minWidth: "500px", minHeight: "30px" }}><Icon aria-label="aria-menu" icon="info" /></Tooltip></span>
        return (
            <Spin indicator={antIcon} spinning={this.state.isLoadingPage}>
                <div className='neo-scheduler-form'>
                    <Row>
                        <div>
                            <span className='large-text schedule-demo-title'><span style={{ color: "#1B77AF" }}>Solution Demos</span> / Schedule {demo.title} Demo</span>
                        </div>
                        <div style={{ display: "flex", marginTop: "12px" }}>
                            <div style={{ display: "flex", width: "100%", justifyContent: "flex-start" }}>
                                <Button animation={this.state.showPulse ? "pulse" : "none"} variant="primary" icon="calendar" onClick={() => this.setState({ scheduleAssistantModal: true })} >Calendar View</Button>
                            </div>
                            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                                <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                                    {(currentSchedule && !isReschedule) && <Button id="schedule-delete-button" variant="tertiary" icon="trash" onClick={() => { this.setState({ deleteModalOpen: true }) }} className="float-right left-spacing-3">Delete Schedule</Button>}
                                    <Button className="float-right left-spacing-3" variant="secondary" onClick={() => { this.state.isStepOne ? this.props.closeCurrentOpenTab() : this.setState({ isStepOne: true }) }}>{this.state.isStepOne ? "Discard & Close" : "Back"}</Button>
                                    <Button id="schedule-confirm-button" onClick={() => { this.state.isStepOne ? this.handleNext() : this.handleOpenConfirmationSchedule() }} className="float-right left-spacing-3" disabled={this.state.isFormDisabled}> {this.state.isStepOne ? "Schedule" : ScheduleButtonText}</Button>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row style={{ marginBottom: '5px', marginTop: '5px' }}>
                        <>
                            {(!this.state.demoAccess || !this.state.isActiveUser) && this.renderDemoErrorAlert()}
                            {this.state.demoRequestStatus < 2 && this.renderDemoRequestErrorAlert()}
                        </>
                    </Row>

                    <div style={!this.state.isStepOne ? { display: "none" } : {}}>
                        <Form>
                            <Row gutter={16} style={{ marginBottom: '16px' }}>
                                <Col lg={5} sm={12} xs={24}>
                                    <Form.Item label="Time zone">
                                        {getFieldDecorator("timezone", {
                                            rules: [{
                                                required: true,
                                                message: "Please select a TimeZone"
                                            }],
                                            initialValue: timezone ?? userTimeZone
                                        })(
                                            <Select
                                                showSearch
                                                bordered={false}
                                                suffixIcon={<Icon aria-label="aria-menu" style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                                                className='organiser-select'
                                                size='large'
                                                onChange={this.handleTimezoneChange}
                                            >
                                                {this.renderTimeZoneOptions()}
                                            </Select>)}
                                    </Form.Item>
                                </Col>
                                <Col lg={5} sm={12} xs={24}>
                                    <Form.Item
                                        label="Country"
                                    > {getFieldDecorator("country", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please enter a country"
                                            }
                                        ],
                                        initialValue: !isReschedule ? schedule?.country ? JSON.stringify(schedule?.country) : undefined : undefined,
                                    })
                                        (<Select
                                            showSearch
                                            id='country'
                                            bordered={false}
                                            suffixIcon={<Icon aria-label="aria-menu" style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                                            className='organiser-select country'
                                            size='large'
                                            placeholder="Select a country"
                                        >
                                            {this.renderCountryOptions()}
                                        </Select>)
                                        }
                                    </Form.Item>
                                </Col>
                                {showOrganiser && <Col lg={5} sm={12} xs={24}>

                                    <Form.Item label="Organiser ">
                                        {
                                            getFieldDecorator('assignee', {
                                                rules: [{ required: true, message: 'Please select an Organiser' }],
                                                initialValue: isReschedule
                                                    ? JSON.stringify(user) : currentSchedule ? currentSchedule.createdBy.fullName : this.state.selectedUser ? JSON.stringify(this.state.selectedUser) : JSON.stringify(user)
                                            })(
                                                <Select
                                                    showSearch
                                                    bordered={false}
                                                    placeholder={`Type name to search`}
                                                    suffixIcon={<Icon aria-label="aria-menu" style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                                                    onSearch={this.fetchUserNames}
                                                    notFoundContent="No Match found!"
                                                    className='organiser-select'
                                                    size='large'
                                                    onChange={this.handleAssigneeChange}
                                                    disabled={currentSchedule || scheduleParticipants?.length > 0}
                                                    loading={this.state.isLoadingUser}
                                                >
                                                    {<Option value={JSON.stringify(user)} style={{ color: "#242424", fontWeight: "400" }} title="Myself"> Myself</Option>}
                                                    {this.renderUserNamesOptions()}
                                                </Select>
                                            )
                                        }
                                    </Form.Item>
                                </Col>}
                            </Row>

                            <Row gutter={16}>
                                <div className='scheduler-range-picker'>
                                    <Col lg={5} sm={12} xs={24}>
                                        <Form.Item label="Select a start date ">
                                            {
                                                getFieldDecorator("startDate", {
                                                    rules: [{ required: true, message: 'Please select the start date' }],
                                                    initialValue: this.state.startDate
                                                })(
                                                    <DatePicker
                                                        placeholder="Start Date"
                                                        style={{ display: "grid" }}
                                                        format={CONFIG.dateFormats.schedulerDate}
                                                        onChange={this.handleStartDateChange}
                                                        allowClear={false}
                                                        disabledDate={this.disabledStartDate}
                                                    />
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col lg={4} sm={12} xs={24} className='time-available-check'>
                                        {
                                            this.state.isFetchingAvailableTime ? <Spinner /> : <span style={isUserSelectedTimeAvailable ? { color: '#088A08' } : { color: '#D93D32' }}>{isUserSelectedTimeAvailable ? available : notAvailable}</span>
                                        }
                                    </Col>
                                    <Col lg={3} sm={12} xs={24}>
                                        <Form.Item >
                                            {getFieldDecorator("endTime", {
                                                rules: [{
                                                }], initialValue: this?.state?.endDateTime?.format(CONFIG.dateFormats.schedulerDate)
                                            })(
                                                <TextInput readOnly label="Expiry Date" defaultValue={this?.state?.endDateTime?.format(CONFIG.dateFormats.schedulerDate)} value={this?.state?.endDateTime?.format(CONFIG.dateFormats.schedulerDate)} />)
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3} sm={12} xs={24}>
                                        <Form.Item >
                                            {getFieldDecorator("defaultDuration", {
                                                rules: [{
                                                }], initialValue: isEditSchedule ? `${schedule?.demo?.scheduleRange} Day(s)` : `${demo?.scheduleRange} Day(s)`
                                            })(
                                                <TextInput
                                                    readOnly
                                                    label={<span>Demo Duration <Tooltip title={CONFIG.systemMessage.demoDuration}><Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}
                                                    defaultValue={isEditSchedule ? `${schedule?.demo?.scheduleRange} Day(s)` : `${demo?.scheduleRange} Day(s)`}
                                                    value={isEditSchedule ? `${schedule?.demo?.scheduleRange} Day(s)` : `${demo?.scheduleRange} Day(s)`} />)
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3} sm={12} xs={24}>
                                        <Form.Item label={
                                            <>
                                                Buffer Time {" "}
                                                <Tooltip title={CONFIG.bufferTimeInfo.labBufferTime} placement="topLeft" overlayStyle={{ minWidth: "500px", minHeight: "30px" }}>
                                                    <Icon aria-label="aria-menu" className="text-color-primary" icon="info-outline" />
                                                </Tooltip>
                                            </>
                                        }>
                                            {getFieldDecorator("bufferTime", {
                                                rules: [{
                                                }], initialValue: `${demo?.startTimeBuffer / 60} Hour(s)`
                                            })(
                                                <TextInput
                                                    readOnly
                                                    aria-label="Buffer Time"
                                                    defaultValue={`${demo?.startTimeBuffer / 60} Hour(s)`}
                                                    value={`${demo?.startTimeBuffer / 60} Hour(s)`} />)
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col lg={4} sm={12} xs={24}>
                                        <Form.Item >
                                            {getFieldDecorator("maxSchudule", {
                                                rules: [{
                                                }], initialValue: isEditSchedule ? JSON.stringify(schedule?.demo?.maxSchedule) : JSON.stringify(demo?.maxSchedule)
                                            })(
                                                <TextInput
                                                    readOnly
                                                    label="Max Simultaneous Demos"
                                                    defaultValue={isEditSchedule ? JSON.stringify(schedule?.demo?.maxSchedule) : JSON.stringify(demo?.maxSchedule)}
                                                    value={isEditSchedule ? JSON.stringify(schedule?.demo?.maxSchedule) : JSON.stringify(demo?.maxSchedule)} />)
                                            }
                                        </Form.Item>
                                    </Col>
                                </div>
                            </Row>
                            {(!isSelectedTimeAvailable && suggestedTimeSlots && suggestedTimeSlots?.length !== 0) &&
                                <Row gutter={16}
                                    style={{ marginBottom: '16px' }}
                                >
                                    <div>
                                        <span style={{ marginLeft: '8px', marginRight: '5px' }}>Suggested: </span>

                                        {suggestedTimeSlots?.map((suggestedTimeSlot,) => (
                                            <span style={{ margin: '5px' }} className='neo-link' onClick={() => this.handleSuggestedTimeSelect(suggestedTimeSlot)}>{moment(suggestedTimeSlot).tz(timezone).format(CONFIG.dateFormats.schedulerDate)}</span>
                                        ))}
                                    </div>
                                </Row>}
                            <Row gutter={16} style={{ marginBottom: '16px' }}>
                                {isManagerEmail && <Col lg={5} sm={12} xs={24}>
                                    <Form.Item
                                        label={managerTitle}
                                    >
                                        {getFieldDecorator("managersEmail", {
                                            rules: [
                                                {
                                                    required: isManagerMandatory,
                                                    type: "email",
                                                    message: "Please enter a valid email-id"
                                                }
                                            ],
                                            initialValue: !isReschedule ? schedule?.managerEmail : undefined
                                        })
                                            (<TextInput
                                                aria-label={managerTitle}
                                                defaultValue={isReschedule ? schedule?.managerEmail : undefined}
                                                value={isReschedule ? schedule?.managerEmail : undefined} />)
                                        }
                                    </Form.Item>
                                </Col>}
                                {isRegionalPrime && <Col lg={5} sm={12} xs={24}>
                                    <Form.Item
                                        label={regionalPrimeTitle}
                                    >
                                        {getFieldDecorator("regionalPrimeEmail", {
                                            rules: [
                                                {
                                                    required: true,
                                                    type: "email",
                                                    message: "Please enter a valid email-id"
                                                }
                                            ],
                                            initialValue: !isReschedule ? schedule?.regionalPrimeEmail : undefined
                                        })
                                            (
                                                <Select
                                                    showSearch
                                                    bordered={false}
                                                    suffixIcon={<Icon aria-label="aria-menu" style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                                                    className='organiser-select'
                                                    size='large'
                                                    placeholder="Please select regional prime"
                                                >
                                                    {this.renderManagers()}
                                                </Select>
                                            )
                                        }
                                    </Form.Item>
                                </Col>}
                            </Row>
                            {demo?.notes &&
                                <Row style={{ marginBottom: '16px' }}>
                                    <Col>
                                        <Row style={{ marginBottom: '5px' }}>
                                            <span style={{ fontSize: "12px" }}>Notes:</span>
                                        </Row>
                                        <Row>
                                            <span style={{ paddingBottom: '10px' }}>{demo.notes}</span>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                        </Form>
                        {isSelectedTimeAvailable ? <div className='schedule-details'>
                            <ScheduleTimeDetails
                                demo={demo}
                                timezone={timezone}
                                startTime={this.state.startDate.tz(timezone).toISOString()}
                                endTime={this?.state?.endDateTime?.toISOString()}
                            />
                        </div> : <div className='no-resource-div'><Icon aria-label="aria-menu" icon='change-password' className='no-resource-icon' /><span className='no-resource-text'><span>Selected date is not available to schedule due to maximum number of simultaneous PoCs allowed reached.</span><br /> <span>Please select from one of the suggested dates below.</span></span></div>}
                    </div>
                </div >
                <div style={this.state.isStepOne ? { display: "none" } : {}}>
                    <NeoLabDemoDetailsPage
                        handleParticipantsChange={this.handleParticipantsChange}
                        form={this.props.form}
                        demo={demo}
                        handleCurrentScheduleChange={this.handleCurrentScheduleChange}
                        isCurrentSchedule={this.state.isCurrentSchedule}
                        startTime={this.state.startDate.tz(timezone).toISOString()}
                        endTime={this?.state?.endDateTime?.toISOString()}
                        isReschedule={isReschedule} />
                </div>
                {this.state.isModalVisible &&
                    <LabDemoConfirmationModal
                        handleSchedule={this.handleSchedule}
                        demo={demo}
                        handleModalClose={this.handleModalClose}
                        isModalVisible={this.state.isModalVisible}
                        managerEmail={managerEmail}
                        regionalPrimeEmail={regionalPrimeEmail}
                        startTime={this.state.startDate.tz(timezone).toISOString()}
                        endTime={this?.state?.endDateTime?.toISOString()}
                        approvalRequired={approvalRequired}
                        form={this.props.form}
                        isReschedule={isReschedule} />
                }
                {
                    this.state.deleteModalOpen && <ScheduleDeleteConfirmation
                        schedule={currentSchedule}
                        handleModalClose={() => { this.setState({ deleteModalOpen: false }) }}
                        isBatchDelete={false}
                        isLoggedInUserCreator={currentSchedule?.createdBy?.id === user?.id}
                        hasParticipants={false}
                        handleDelete={this.handleDeleteSchedule}
                        isModalVisible={this.state.deleteModalOpen}
                    />
                }
                {
                    this.state.scheduleAssistantModal && <SchedulingAssistant
                        demo={demo}
                        startTime={moment().tz(timezone).add(1, 'day').startOf('day')}
                        timezone={timezone}
                        visible={this.state.scheduleAssistantModal}
                        handleCloseSchedulingAssistant={() => this.setState({ scheduleAssistantModal: false })}
                        className="cloud"
                    />
                }
                <SchedulerHelpButton />
            </Spin>
        );
    }
}

const NeoLabDemoScheduleFormRef = Form.create()(NeoLabDemoScheduleForm);

const mapStateToProps = ({ scheduler, user, newSchedule, localUsersForm }) => {
    return {
        timezone: scheduler.selectedTimezone,
        startTime: scheduler.startTime,
        endTime: scheduler.endTime,
        resourceType: scheduler.resourceType,
        isMultipleDays: user.isMultipleDays,
        userTimeZone: user.profile ? user.profile.timezone : undefined,
        user: user.profile,
        selectedResource: scheduler.selectedResource,
        currentSchedule: newSchedule.currentSchedule,
        userPurposeTypes: newSchedule.userPurposeTypes ? newSchedule.userPurposeTypes : [],
        countries: localUsersForm?.countries,
        profile: user?.profile,
        customerInfo: newSchedule?.customerInfo
    };
}

export default connect(
    mapStateToProps, {
    refreshScheduler,
    updateSchedulerTimezone,
    updateSchedulerStartEndTime,
    fecthDemoPurposeTypesLite,
    closeCurrentOpenTab,
    postNewDemoSchedule,
    clearAllNewEvents,
    fetchScheduleById,
    toggleIsFetchingSchedulerData,
    fetchAllCountries,
    deleteDashboardSchedule,
    toggleIsFetchingDashboardSchedules
}

)(NeoLabDemoScheduleFormRef);
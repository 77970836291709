let config = {
  brandName: "Experience Avaya Demo Portal",
  productVersion: "v7.0.1.1004-beta-cr",
  revampPortalPublishedDate: "2023-09-25T00:00:00.000+0000",
  demoStatus: {
    types: ["Red", "Yellow", "Green"],
    tagColors: ["red", "orange", "green"],
    tagChips: ["alert", "warning", "success"],
    tagIcons: ["close", "info", "check"],
    tagBorderColors: ["#FA6156", "#F38D00", "#1e7c1ea6"],
    default: 2,
    descText: "[Updated: <timestamp>] ",
    dataCenterTypes: {
      red: 0,
      yellow: 1,
      green: 2,
    },
    icons: ["close-circle", "warning", "check-circle"],
    colors: ["error-text", "warning-text", "success-text"],
    alertType: ["error", "warning", "success"],
    alertMessage: ["Not Available", "Partially Available", "Available"],
    tooltipMessage: ["The demo is currently unavailable. Please hover over the demo status for more information.", "Some of the demo features might not be available. Please hover over the demo status for more information."]
  },
  dailyTestingStatus: {
    notWorking: "Not Working",
    partiallyWorking: "Partially Working",
    fullyFunctional: "Fully Functional",
    notTested: "Not Tested"
  },
  selectAll: "#select-all#",
  ratingTooltip: ['Very Unsatisfied', 'Unsatisfied', 'Neutral', 'Satisfied', 'Very Satisfied'],
  crmStatus: [
    {
      text: "Not Created",
      value: "Not Created"
    },
    {
      text: "Created by EAP",
      value: "Created by EAP"
    },
    {
      text: "Created Manually",
      value: "Created Manually"
    }
  ],
  didStatus: {
    types: [false, true]
  },
  schedulerViews: [
    {
      text: "Day",
      value: "day",
    },
    {
      text: "Work Week",
      value: "workweek",
    },
    {
      text: "Week",
      value: "week",
    },
    {
      text: "Month",
      value: "month",
    },
  ],
  statsBGColors: ["#0f9d58", "#4285f4", "#f4b400"],
  statsIcon: ["raise-hand", "sign-in", "user-return"],
  statscolor: ["#088A08", "#1B77AF", "#AB3FE0"],
  reportDatePickerEntities: ["Scheduled Demo", "Scheduled Maintenance", "Daily Test Report", "Daily Testing Audit", "EZ Demo Audit"],
  maintenanceViews: [
    {
      text: "Month",
      value: "month",
    },
    // {
    //   text: "Week",
    //   value: "week",
    // },
    // {
    //   text: "Day",
    //   value: "day",
    // },
    {
      text: "Agenda",
      value: "agenda",
    },
  ],
  demoResourceStatus: {
    types: ["Red", "Green"],
    tagTypes: ["warning", "success"],
    resourceTypes: {
      red: 0,
      green: 1
    }
  },
  editorType: {
    intro: "intro",
    body: "body",
    standardEmailBody: "standardEmailBody",
    notificationDescription: "notificationDescription",
    demoDescription: "description",
    demoAccess: "access",
    demoComponent: "component",
    capabilityGeneralInstruction: 'capabilityGeneralInstruction',
    specificCapabilityInstruction: 'specificCapabilityInstruction',

  },
  regEx: {
    getTime: /\[Updated: ([^\]]+)\]/,
    // eslint-disable-next-line
    urlValidation: /^(http|https):\/\/.+[\.{1}].*$/,
    searchText: /(?![^<]*>)searchText/,
    getFileName: /[0-9]+[a-zA-Z0-9_]+\..*/g,
    whiteSpace: /\s/g,
    passwordRequirementPattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,16}$/,
    phoneNumber: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g,
    email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    demoPurpose: /^([\w\s])+$/i,
  },
  solution: {
    vertical: {
      id: 1,
      title: 'Vertical'
    },
    portfolio: {
      id: 2,
      title: 'Portfolio'
    }
  },
  demoAccess: {
    none: 0,
    readOnly: 1,
    fullAccess: 2
  },
  pageSize: 10,
  lazyLoadPageSize: 12,
  fetchCompaniesSize: 200,
  shortPageSize: 4,
  cardPageSize: 5,
  previousSchedulesCardSize: 4,
  maintenenceCardSize: 5,
  messageType: {
    error: 0,
    success: 1,
    warning: 2,
    info: 3,
    loading: 4
  },
  roleTemplateAccess: {
    none: 0,
    readOnly: 1,
    fullAccess: 2
  },

  demoRequestAccessStatus: {
    request: 0,
    yetToApprove: 1,
    approved: 2
  },
  environmentsTableData: [
    {
      key: 'dataCentersAccess',
      category: 'Data Centers Access',
      isWriteDisabled: true,
    },
    {
      key: 'didsAccess',
      category: 'DIDs Access',
      isWriteDisabled: false,
    }
  ],
  messageDelay: 3,
  HTTP_STATUS: {
    OK: 200,
    CREATED: 201,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    NOT_ACCEPTABLE: 406,
    REQUEST_TIMEOUT: 408,
    CONFLICT: 409,
    PRECONDITION_FAILED: 412,
    UNSUPPORTED_MEDIA_TYPE: 415,
    UNPROCESSABLE_ENTITY: 422
  },
  maintenanceSuccessMessage: "All instances are available!",
  maintenanceErrorMessage: "Maintenance window(s) already scheduled for the selected time!",
  roleTemplatesType: {
    demos: 0,
    news: 1,
    notification: 1
  },
  demoPurposeTypes: {
    training: "Training",
    customerDemo: "Customer Demo",
    tradeshow: "Trade Show"
  },
  verticalId: 1,
  Textbox: "Textbox",
  Vertical: "Vertical",
  supportVideos: [],
  homePageLinks: [
    {
      title: 'Avaya Sales & Partner Portal',
      desc: '',
      url: 'https://sales.avaya.com/en/general/experience-avaya'

    },
    // {
    //   title: 'Avaya Collaboratory',
    //   desc: '',
    //   url: 'https://avayacollaboratory.com'
    // },
    {},
    {}
  ],
  remoteSupportLink: "https://get.teamviewer.com/experienceavaya",
  contactUsLink: "https://dasupport.demoavaya.com/support/home",
  navigationMenuWidth: 250,
  dateFormats: {
    scheduler: "YYYY-MM-DD HH:mm:ss",
    default: "YYYY-MM-DD hh:mm A",
    crossBrowserDefault: "YYYY/MM/DD hh:mm A",
    calendar: "Do MMM hh:mm A",
    user: "Do MMM, YYYY",
    neouser: "MMM Do, YYYY",
    USFormat: "MM-DD-YYYY hh:mm A",
    userDateTime: "Do MMM, YYYY hh:mm A",
    userDateTime2: "MMMM Do, YYYY hh:mm A",
    userDateTime3: "Do MMM, hh:mm A",
    USformat: "MM-DD-YYYY hh:mm A",
    userDateTimeWithTZ: "MMMM Do, YYYY hh:mm A z",
    userDateTimeWithTZ2: "Do MMM, YYYY hh:mm A z",
    userTime: "hh:mm A",
    calendarDate: "YYYY-MM-DD",
    month: "YYYYMM",
    userMonth: "MMM",
    neoFormat: "Do MMM YYYY - hh:mm A",
    schedulerDate: "MMM DD, YYYY"
  },
  timeFormats: {
    default: "hh:mm A"
  },
  demoScheduler: {
    eventText: {
      demo: 'Demo',
      maintenance: 'Maintenance',
      new: 'New Schedule',
      unavaialble: "Unavailable",
    },
    config: {
      schedulerWidth: '74%',
      dayResourceTableWidth: 300,
      schedulerMaxHeight: 400,
      dayMaxEvents: 5,
      weekMaxEvents: 5,
      views: [],
      displayWeekend: true,
      resourceName: 'Demo Resources',
      nonAgendaDayCellHeaderFormat: 'h A',
      checkConflict: true,
      eventItemHeight: 18,
      scrollToSpecialMomentEnabled: false,
      dayCellWidth: 30,
      startResizable: true,
      endResizable: true,
      movable: true,
      crossResourceMove: true
    },
    configLab: {
      schedulerWidth: '74%',
      dayResourceTableWidth: 0,
      schedulerMaxHeight: 400,
      dayMaxEvents: 1000,
      weekMaxEvents: 1000,
      views: [],
      displayWeekend: true,
      resourceName: null,
      checkConflict: true,
      eventItemHeight: 18,
      nonAgendaDayCellHeaderFormat: 'h A',
      scrollToSpecialMomentEnabled: false,
      dayCellWidth: 30,
      startResizable: false,
      endResizable: false,
      movable: false,
      creatable: false,
    },
    scheduleDurationLimit: 24 //hours
  },
  newsSubheading: {
    active: "View Active News",
    inactive: "View Previous News"
  },
  newsheading: {
    active: "Active News",
    inactive: "Previous News"
  },

  dashboard: {
    cardView: 0,
    tableView: 1,
    calendarView: 2,
    upcomingDemosCount: {
      desktop: 10,
      mobile: 1
    },
    schedulesCardMessage: {
      DEMO_INACTIVE: "This demo is currently inactive.",
      DEMO_DELETED: "This demo is no longer available.",
      DEMO_NO_ACCESS: "You do not have access to this demo.",
      DEMO_EXPIRED: "This demo has expired.",
      RESOURCE_INACTIVE: "This resource is currently inactive. Please schedule on a different resource",
      LDAP_NOT_ASSIGNED: "LDAP group is not assigned to this resource.",
      RESOURCE_NO_ACCESS: "This resource is no longer available. Please schedule on a different resource.",
      RESOURCE_OVERRIDE: "Temporary access for this resource assigned."
    },
    schedulesViewType: {
      TABLE: "tableView",
      CARD: "cardView",
      CALENDAR: "calendarView",
    },
    previousDemosCount: {
      desktop: 2,
      mobile: 1
    },
    scheduleType: {
      DEMO: "demo",
      MAINTENANCE: "maintenance",
    },
    schedulePrintStyle: `@media print { 
      @page 
      {
          margin:30px 30px 30px 30px;
          @bottom-left {
            content: counter(page) "/" counter(pages);
        }
      }
      body { 
          -webkit-print-color-adjust: exact;
      }}`
  },
  guideType: {
    gettingStarted: {
      text: "Getting Started",
      value: "GETTING_STARTED"
    },
    quickSetup: {
      text: "Quick Setup",
      value: "QUICK_SETUP"
    },
    videoLink: {
      text: "Instructional Videos",
      value: "VIDEO_LINK"
    },
    selfHelpGuides: {
      text: "Self Help Guides",
      value: "SELF_HELP"
    }
  },
  pushNotificationDelay: 100,
  cookieParams: {
    path: '/',
    // httpOnly: true,
    secure: true,
  },
  ALL: 0,
  colors: {
    demo: 'rgb(150 147 147)',
    maintenance: '#da291c',
    buffer: '#FFFF00',
    userDemo: '#6fd1ff',
    primaryColor: '#1B77AF',
    success: '#52c41a',
    failed: '#f5222d',
    unavailable: 'rgb(255 186 190)',
    icon: {
      selected: '#1890ff'
    }
  },
  roleTypes: {
    superAdmin: 'SUPER_ADMIN',
    admin: 'ADMIN',
    user: 'NORMAL_USER',
    default: 'DEFAULT',
    eaAdmin: 'EA Admin'
  },
  assignedOptions: [0, 1],
  warningMessages: {
    developerOptions: "These developer options are solely for testing and debugging purposes. Making changes to these options can lead to unintended consequences and may adversely affect critical aspects of the website. Proceed with caution only if you understand the consequences.",
    demoActivate: "There are no <placeholder> assigned. Do you still want to activate this demo?",
    solutionInactive: "The demo associated solution is inactive/deleted",
    demoDelete: "There are maintenance/demo schedules for this demo. Do you still want to delete?",
    resourceDelete: "There are demo schedules for this resource. Do you still want to delete?",
    title: "Do you want to proceed?",
    changesLost: "Your changes will be lost. Do you want to proceed?",
    maxLimitWarning: "You have too many tabs open! Please close other tabs to open a new tab.",
    deleteDataCenter: "This will delete all the associated Resources, Scheduled Demos, Collaterals, Networks, IP addresses, DIDs",
    partialImportDIDs: "This will add new DIDs or update the existing DIDs.",
    fullImportDIDs: "This will delete all the existing DIDs and add new ones. You can export existing DIDs before importing.",
    errorImportDIDTitle: "Error while Importing DIDs",
    errorImportDIDHeader: "The following DID(s) failed to import due to Invalid ENV or Invalid Provider:",
    eapOfflineCloneDisabled: "EAP-offline notifications can not be cloned.",

    userMigratedToSSO: {
      title: "Attention",
      content: `You have successfully logged in through SSO. Please continue to use SSO for future logins. Kindly note that, you will no longer be able to login using your non-SSO credentials.`
    },
    invalidBPCertification: {
      title: "Invalid BP Certification",
      content: "You do not have a valid BP certification. Please contact support to access the portal"
    },
    invalidSSO: {
      title: "Avaya SSO Needs Attention",
      content: "Your Avaya SSO Information needs attention to proceed to log into Experience Avaya. Avaya Associates – Review/Edit your contact information in Talent Maker. Distributors and Business Partners – Contact your Channel Account Manager to update your Avaya SSO information."
    },
    scedulerInvalidDate: {
      title: "WARNING",
      content: 'Start/End date before the current date.'
    },
    redResource: {
      title: "WARNING",
      content: 'This resource or demo is not available for scheduling at this time.'
    },
    scheduleAccess: {
      title: "WARNING",
      content: 'This demo needs to be requested before scheduling.'
    },
    scheduleTimeLimitExceed: {
      title: "WARNING",
      content: 'You cannot schedule on this resource for a duration more than <placeholder> hours. Please contact administrator for more details.'
    },
    duplicate_title: {
      errorCode: "DUPLICATE_TITLE",
      title: "WARNING",
      content: 'A template with the specified title already exists.'
    },
    scheduleMultipleDaysRestriction: {
      errorCode: "ROLE_TEMPLATE_MULTIPLE_DAYS_RESTRICTION",
      title: "WARNING",
      content: 'Scheduler does not have access to schedule for more than 24 hours. Please contact administrator for more details.'
    },
    previousSchedules: {
      title: "WARNING",
      content: 'You cannot modify previous schedule.'
    },
    temporaryAccess: {
      title: "WARNING",
      content: 'You do not have access to schedule on the resource.'
    },
    LabSchedulerInvalidDate: {
      title: 'WARNING',
      content: "Selected time conflicts with start buffer time."
    },
    scedulerConflict: {
      title: "WARNING",
      content: "Conflict! Please select a different slot."
    },
    demoTestConnection: {
      title: "WARNING",
      content: "Please make sure you have run the Test Connection before activating this demo."
    },
    availableText: {
      title: "Information",
      content: "The user will be notified of the build status via email."
    },
    noAccess: "You do not have enough access to perform this operation",
    noRating: "No rating available yet",
    ratingExpired: "Feedback link has expired.",
    readOnlyEnvironmentAccess: "You do not have enough access to perform edit operation",
    noEnvironmentAccess: "You do not have enough access to view this section",
    noSolutionTypeAccess: "You do not have access to any solution type",
    forbidden: "You are not authorized to perform this operation",
    unauthorized: "There was a problem verifing your account. Please login again.",
    requestTimeout: "Your session has expired. Please login again",
    incorrectCredentials: "Incorrect credentials : Please verify username and password",
    ldapFailure: {
      title: "Could not connect to LDAP service",
      content: "Please contact administrator to access the demo resource"
    },
    ldapResetPasswordFailure: {
      title: "Could not reset demo password",
      content: "There is a demo scheduled from <startTimePlaceholder> to <endTimePlaceholder>. Please try again later."
    },
    noSolutions: "You do not have enough access to view this section",
    hardDelete: "This <placeHolder> will be permanently deleted. You cannot undo this action.",
    demoApproveTitle: "Would you like to approve this approval request?",
    demoDeclineTitle: "Would you like to decline this approval request?",
    requestApproveTitle: "Would you like to approve this capability request?",
    requestDeclineTitle: "Would you like to decline this capability request?",
    requestRevokeTitle: "Would you like to revoke this capability request?",
    registerTitle: "Would you like to acknowledge this demo request?",
    demoDeclineComment: "Please add a comment before declining this request",
    demoApprove: "Request for <placeHolder> will be approved. You cannot undo this action.",
    requestApprove: "Request for <placeHolder> will be approved. You cannot undo this action.",
    demoDecline: "Request for <placeHolder> will be declined. You cannot undo this action.",
    requestRevoke: "Request for <placeHolder> will be revoked. You cannot undo this action.",
    softDelete: "You can restore the deleted <placeHolder> from delete view.",
    notifications: {
      "Experience Avaya has posted a News Item": "You only have manage access to the news",
      "Experience Avaya has updated a News Item": "You only have manage access to the news",
      "NEWS": "You only have manage access to the news",
      "New Demo Available": "You only have manage access to the demo",
      "Demo Update": "You only have manage access to the demo",
      "DEMO": "You only have manage access to the demo",
      userManagement: "You have been assigned a new role, please refresh the page to access these items"
    }
  },
  demoAccessTemplate: `<h2><strong>Pilot Numbers</strong></h2>
  
  <table border="1" cellpadding="1" cellspacing="1" style="width:500px">
    <tbody>
      <tr>
        <td><strong>Description</strong></td>
        <td><strong>Dial-In</strong></td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
    </tbody>
  </table>
  
  <h2><strong>Links</strong></h2>
  
  <table border="1" cellpadding="1" cellspacing="1" style="width:500px">
    <tbody>
      <tr>
        <td><strong>Links</strong></td>
        <td><strong>Requirements</strong></td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
    </tbody>
  </table>
  
  <p>&nbsp;</p>`,
  didUploadType: {
    partial: 0,
    full: 1
  },
  newDemoDuration: 30, //days
  mobileMenu: [
    {
      "title": "My Profile",
      "icon": "user",
      "url": "/my-profile",
      "subMenu": []
    },
    {
      "title": "Logout",
      "icon": "logout",
      "url": "/logout",
      "subMenu": []
    },
  ],

  ssoHomePage: [
    {
      title: "Home",
      url: "/home",
      icon: "home",
      subMenu: []
    },
  ],
  nonssoHomePage: [
    {
      title: "Home",
      url: "/home/non-sso-users",
      icon: "home",
      subMenu: []
    },
  ],
  landingPageMenu: [

    {
      title: "About Us",
      url: "/about-us",
      icon: "user-conference",
      subMenu: []
    },
    {
      title: "Support",
      url: "/support",
      icon: "support",
      subMenu: []
    }
  ],
  supportMenus: {
    title: "Support",
    url: process.env.REACT_APP_SUPPORT_URL,
    icon: "support",
    subMenu: [],
    link: true
  },
  helpMenus: {
    title: "Help",
    url: null,
    icon: "help",
    subMenu: [
      {
        title: "Self Help Guides",
        url: "/support/self-help",
        id: "support-selfHelp",
      },
      {
        title: "Getting Started",
        url: "/support/getting-started",
        id: "support-getting-started",
      },
      {
        title: "Quick Setup",
        url: "/support/quick-setup",
        id: "support-quick-setup",
      },
      {
        title: "Instructional Videos",
        url: "/support/video-link",
        id: "support-videos",
      },
    ],
    link: false,
  },
  supportNames: {
    "self-help": "Self Help Guides",
    "getting-started": "Getting Started",
    "quick-setup": "Quick Setup Guides",
    "video-link": "Instructional Videos"
  },
  systemMessage: {
    resourceAvailable: "The demo is available on other resources",
    resourceUnavailable: "The demo is not available on other resources",
    demoDCStatus: "The demo may be available on other resources",
    navBar: "Welcome to Experience Avaya portal!",
    firstTimeLoginTitle: "Experience Avaya Portal",
    firstTimeLoginContentSSO: "Welcome to the Experience Avaya program! To get started, please select the timezone.",
    firstTimeLoginContentnonSSO: "Welcome to the Experience Avaya program! To get started, you are required to change your password.",
    bufferTime: "Please input multiples of ",
    applyToAllCheck: "If checked, all the date fields in the record have to satisfy the selected date range condition",
    demoDuration: "No. of days for which the demo should be scheduled",
    ckEditorSourceModeWarning: "Please ensure that you are not in ‘Source’ mode before proceeding.",
    demoTrendReportMessage: "The data displayed in the graph corresponds to the actual count of demo schedules. For further insights into trends, refer to the table.",
    buildDuration: "No. of hours required for the demo to build",
    autoDestroy: "If enabled, build will be torn down automatically post schedule end time",
    manualFlow: "If enabled, build cycle is handled manually",
    dcDescription: "This information will be shown to the user",
    required: "if enabled, this field is mandatory for user to fill",
    isVisible: "If enabled, this field is visible to the user",
    resourceTimeLimit: "No. of hours the resource can be scheduled for",
    resourceTimeLimitOverride: "No. of hours the resource can be scheduled for. Note that the resource level time limit takes precedence over demo level time limit.",
    alreadyDemoRequestedTitle: "Unable to re-schedule",
    alreadyDemoRequested: "You have already requested for this demo. You can schedule once your request is acknowledged.",
    ezDemoDesc: " EZ Demos are guided interactive demos that are created from Experience Avaya production demos. EZ Demos allow users to demonstrate Avaya solutions and products without connectivity to the actual demo for an interactive experience and do not require to be scheduled."
  },

  myProfile: {
    firstTimeLoginLocalUser: "You are required to change your password before continuing.",
    emailNotificationOverride: "By enabling, you will receive the following types of emails to keep you informed about the program, new demos, updates to demos, news items, notifications items and scheduled maintenance on your scheduled demo.  If you chose not to opt-in for these messages, there will be circumstances in which you will receive an email overriding your preference.",
    unsubscribePortalEmailsIf: "If you wish to opt-out, you will no longer receive emails informing you about the program, new demos, updates to demos, news items, notifications items and scheduled maintenance on your scheduled demo.  But, there could be circumstances in which you will receive an email overriding your preference.",
    unsubscribePortalEmails: "By opting out, you will no longer receive emails informing you about the program, new demos, updates to demos, news items, notifications items and scheduled maintenance on your scheduled demo.  But, there could be circumstances in which you will receive an email overriding your preference.",
    unsubscribeAllEmails: "By opting out, you will no longer receive emails informing you about the program, new demos, updates to demos, news items, notifications items and scheduled maintenance on your scheduled demo. Also, you will no longer receive invites and reminder emails for your scheduled demo.  But, there could be circumstances in which you will receive an email overriding your preference.",
    unsubscribeCalendar: "By opting out of calendar invites, you will no longer receive invites and reminder emails for your scheduled demo. NOT RECOMMENDED!",
    calendarInviteOptOutIf: "If you wish to opt-out, you will no longer receive calendar invites and reminder emails for your scheduled demo. We do NOT RECOMMEND opting-out.",
    calendarInviteOptOut: "By opting out, you will no longer receive calendar invites and reminder emails for your scheduled demo. NOT RECOMMENDED",
    passwordRequirements: "Password must be 8-16 characters and contain at least one lowercase letter, one uppercase letter, one digit and one symbol",
    localUserLoginPageURL: "/home/non-sso-users",
    passwordChangeSuccessfull: {
      title: "PASSWORD SUCCESSFULLY CHANGED",
      content: "Your account password has been successfully changed.",
    },
    invalidEmailID: "The entered email ID is not registered with Avaya Experience Portal",
    defaultEmailInfo: "Please enter your registered Email ID.",
    resetEmailLinkSent: "Reset password link will be sent to <placeholder> ",
    passwordMismatch: "The passwords entered do not match!"
  },

  localUser: {
    createNewCompany: "The selected company - <placeHolder> does not exist. Do you want to create a new one?"
  },

  scheduleMaintenanceOperation: {
    POST: 'post',
    PUT: 'put'
  },

  emailUpdates: {
    notifyUsers: "Notify Users",
    isOverride: "Override User's Opt-Out Preference",
    maintenanceDowntime: "EAP Offline",
  },
  assignedFilters: {
    available: 0,
    assigned: 1
  },
  subnetMasks: [
    {
      title: '255.255.254.0 with 510 Usable Addresses',
      value: 23
    },
    {
      title: '255.255.255.0 with 254 Usable Addresses',
      value: 24
    },
    {
      title: '255.255.255.128 with 126 Usable Addresses',
      value: 25
    },
    {
      title: '255.255.255.192 with 62 Usable Addresses',
      value: 26
    },
    {
      title: '255.255.255.224 with 30 Usable Addresses',
      value: 27
    },
    {
      title: '255.255.255.240 with 14 Usable Addresses',
      value: 28
    },
    {
      title: '255.255.255.248 with 6 Usable Addresses',
      value: 29
    },
    {
      title: '255.255.255.252 with 2 Usable Addresses',
      value: 30
    }
  ],
  exceptionCookies: ['OptanonAlertBoxClosed', 'OptanonConsent'],
  bufferTime: {
    min: 0,
    max: 300,
    step: 5
  },
  schTimeLimit: {
    min: 1,
    step: 1,
    max: 24
  },
  scheduleRange: {
    min: 1,
    step: 1
  },
  fileSize: {
    collateral: 1024,
    news: 50,
    support: 50,
    dynamicEmailAttachment: 5
  },
  schedulerJobFrequency: 5,
  formFieldTypes: {
    INPUT: "INPUT",
    SELECT: "SELECT",
    DROPDOWN: "DROPDOWN",
    SWITCH: "SWITCH",
    DURATION: 'duration',
    RANGE_PICKER: "RANGE_PICKER",
    CHECKBOX: "CHECKBOX",
    SEARCH_SELECT: "SEARCH_SELECT",
    EMAIL_SEARCH: "EMAIL_SEARCH",
    TEXT: "text",
    DATE: "datepicker",
    AUTOCOMPLETE: "autocomplete",
    MULTISELECT: "multiselect",
    SINGLESELECT: "singleselect",
    BOOLEAN: "boolean",
    NUMBER: "number",
    MONTH: "monthpicker",
    PACKAGE: "package"
  },
  sortMap: {
    ascend: "asc",
    descend: "desc"
  },
  filterDataSet: {
    status: [
      {
        text: "Active",
        value: "true"
      },
      {
        text: "Inactive",
        value: "false"
      }
    ],
    dcStatus: [
      {
        text: "Red",
        value: "0"
      },
      {
        text: "Yellow",
        value: "1"
      },
    ],
    demoStatus: [
      {
        text: "Demo Resource",
        value: "Demo Resource"
      },
      {
        text: "Demo DC",
        value: "Demo DC"
      },
    ],
    demoQuestionAnswerTypes: [
      {
        text: "Text Box",
        value: "TextBox",
        id: "1",
      },
      {
        text: "Checkbox",
        value: "Checkbox",
        id: "2",
      },
      {
        text: "Radio Button",
        value: "Radio Button",
        id: "3",
      }
    ],
    guideTypes: [
      {
        text: "Quick Setup",
        value: "QUICK_SETUP"
      },
      {
        text: "Getting Started",
        value: "GETTING_STARTED"
      },
      {
        text: "Instructional Videos",
        value: "VIDEO_LINK"
      },
      {
        text: "Self Help Guides",
        value: "SELF_HELP"
      }
    ],
    didAssigned: [
      {
        text: "Available",
        value: "0"
      },
      {
        text: "Assigned",
        value: "1"
      },

    ],
    demoTypes: [
      {
        text: "Restful API Demo",
        value: "Restful API Demo"
      },
      {
        text: "Lab & PoC Demo",
        value: "Lab & PoC Demo"
      }
    ],
    allDemoTypes: [
      {
        text: "Standard Demo",
        value: "Standard Demo"
      },
      {
        text: "Restful API Demo",
        value: "Restful API Demo"
      },
      {
        text: "Lab & PoC Demo",
        value: "Lab & PoC Demo"
      }
    ],
    manualDemoBuildStatus: [
      {
        text: "New",
        value: "New",
        color: 'blue',
        variant: "info",
        desc: 'New schedule',
        id: 1,
      },
      {
        text: "Assigned",
        value: "Assigned",
        variant: "default",
        color: 'blue',
        id: 2,
        desc: 'Build assigned to an admin'
      },
      {
        text: "In-Progress",
        value: "In-Progress",
        variant: "default",
        id: 3,
        color: 'blue',
        desc: 'Build triggered'
      },
      {
        text: "Available",
        id: 4,
        value: "Available",
        variant: "success",
        color: 'green',
        desc: 'Environment available for demo'
      },
      {
        text: "Torn Down",
        variant: "warning",
        value: "Torn Down",
        id: 5,
        color: 'red',
        desc: 'Build torn down'
      },
      {
        text: "Expired",
        variant: "warning",
        id: 6,
        value: "Expired",
        color: 'red',
        desc: 'Demo schedule time has elapsed'
      },
      {
        text: "Canceled",
        variant: "alert",
        value: "Canceled",
        id: 7,
        color: 'red',
        desc: 'User deleted the schedule'
      },
      {
        text: "Closed",
        variant: "alert",
        value: "Closed",
        id: 8,
        color: 'red',
        desc: 'Build closed'
      }
    ],
    automaticBuildStatus: [{
      text: "In-Progress",
      value: "In-Progress",
      color: 'blue',
      desc: "Build Triggered"
    },
    {
      text: "Available",
      value: "Available",
      color: 'green',
      desc: 'Environment available for demo'
    },
    {
      text: "Expired",
      value: "Expired",
      color: 'red',
      desc: 'Demo schedule time has elapsed'
    },
    {
      text: "Canceled (In-Progress)",
      value: "Canceled (In-Progress)",
      color: 'red',
      desc: 'User deleted the schedule while the build was in progress'
    },
    {
      text: "Canceled (Available)",
      value: "Canceled (Available)",
      color: 'red',
      desc: 'User deleted the schedule after the build was available'
    },
    {
      text: "Canceled (Failed)",
      value: "Canceled (Failed)",
      color: 'red',
      desc: 'User deleted the schedule after the build failed'
    },
    {
      text: "Failed",
      value: "Failed",
      color: 'red',
      desc: 'Build create API call failed'
    },
    {
      text: "Failed (In-Progress)",
      value: "Failed (In-Progress)",
      color: 'red',
      desc: 'Build create API call was successful but the external sub-system did not respond with build status'
    },
    {
      text: "Torn Down (Manual)",
      value: "Torn Down (Manual)",
      color: 'red',
      desc: 'Build torn down manually'
    },
    {
      text: "Torn Down (Auto)",
      value: "Torn Down (Auto)",
      color: 'red',
      desc: 'Build torn down automatically'
    },
    {
      text: "Torn Down (Canceled)",
      value: "Torn Down (Canceled)",
      color: 'red',
      desc: 'Deleted schedule build either torn down manually or automatically'
    },
    {
      text: "Closed",
      value: "Closed",
      color: 'red',
      desc: 'Build closed'
    }],
    demoBuildStatus: [
      {
        text: "New",
        value: "New",
        color: 'blue',
        desc: "New Schedule"
      },
      {
        text: "Assigned",
        value: "Assigned",
        color: 'blue',
        desc: "Build assigned to admin (manual flow)"
      },
      {
        text: "In-Progress",
        value: "In-Progress",
        color: 'blue',
        desc: "Build Triggered"
      },
      {
        text: "Available",
        value: "Available",
        color: 'green',
        desc: 'Environment available for demo'
      },
      {
        text: "Expired",
        value: "Expired",
        color: 'red',
        desc: 'Demo schedule time has elapsed'
      },
      {
        text: "Canceled",
        value: "Canceled (In-Progress)",
        color: 'red',
        desc: 'User deleted the schedule'
      },
      {
        text: "Canceled (In-Progress)",
        value: "Canceled (In-Progress)",
        color: 'red',
        desc: 'User deleted the schedule while the build was in progress'
      },
      {
        text: "Canceled (Available)",
        value: "Canceled (Available)",
        color: 'red',
        desc: 'User deleted the schedule after the build was available'
      },
      {
        text: "Canceled (Failed)",
        value: "Canceled (Failed)",
        color: 'red',
        desc: 'User deleted the schedule after the build failed'
      },
      {
        text: "Failed",
        value: "Failed",
        color: 'red',
        desc: 'Build create API call failed'
      },
      {
        text: "Failed (In-Progress)",
        value: "Failed (In-Progress)",
        color: 'red',
        desc: 'Build create API call was successful but the external sub-system did not respond with build status'
      },
      {
        text: "Torn Down",
        value: "Torn Down",
        color: 'red',
        desc: 'Build torn down'
      },
      {
        text: "Torn Down (Manual)",
        value: "Torn Down (Manual)",
        color: 'red',
        desc: 'Build torn down manually'
      },
      {
        text: "Torn Down (Auto)",
        value: "Torn Down (Auto)",
        color: 'red',
        desc: 'Build torn down automatically'
      },
      {
        text: "Torn Down (Canceled)",
        value: "Torn Down (Canceled)",
        color: 'red',
        desc: 'Deleted schedule build either torn down manually or automatically'
      },
      {
        text: "Closed",
        value: "Closed",
        color: 'red',
        desc: 'Build closed'
      }
    ]
  },
  delimiters: {
    selectFilter: "\\",
    filePath: "\\",
    saveFilter: "\\\\"
  },
  exportTable: {
    roleTemplates: {
      name: "roleTemplate",
      fileName: "RoleTemplates"
    },
    ssoUsers: {
      name: "ssoUsers",
      fileName: "SSO_Users"
    },
    nonSsoUsers: {
      name: "nonSsoUsers",
      fileName: "Non_SSO_Users"
    },
    demo: {
      name: "demos",
      fileName: "Demos"
    },
    solutions: {
      name: "solutions",
      fileName: "Solutions"
    },
    solutionTypes: {
      name: "solutionType",
      fileName: "SolutionTypes"
    },
    automaticBuilds: {
      name: "automaticBuilds",
      fileName: "AutomaticBuilds"
    },
    manualBuilds: {
      name: "manualBuilds",
      fileName: "ManualBuilds"
    },
    purpose: {
      name: "purpose",
      fileName: "Purpose"
    },
    network: {
      name: "network",
      fileName: "Network"
    },
    feedbackQuestion: {
      name: "feedbackQuestion",
      fileName: "FeedbackQuestions"
    },

    demoQuestion: {
      name: "demoQuestion",
      fileName: "DemoQuestions"
    },
    demoStatus: {
      name: "statusMgmt",
      fileName: "DemoStatusMgmt"
    },
    demoRequest: {
      name: "demoRequest",
      fileName: "DemoRequest"
    },

    demoCapability: {
      name: "capability",
      fileName: "DemoCapability"
    },
    capabilityRequest: {
      name: "capabilityRequest",
      fileName: "CapabilityRequest"
    },

    providers: {
      name: "providers",
      fileName: "Providers"
    },
    support: {
      name: "support",
      fileName: "Support"
    },
    demoSchedules: {
      name: "scheduleDemos",
      fileName: "Scheduled Demos"
    },

    dataCenters: {
      name: "dataCenters",
      fileName: "Data Centers"
    },
    dashboardSchedules: {
      calendar: "calendarSchedules",
      demo: "tableScheduleDemos",
      maintenance: "maintenance",
      fileName: "Schedules"
    },

    myRequests: {
      name: "myRequest",
      fileName: "My Requests"
    },
    resources: {
      name: "resources",
      fileName: "Demo Resources"
    },
    maintenanceSchedules: {
      name: "maintenance",
      fileName: "Maintenance Schedules"
    },
    report: {
      name: "reports",
      fileName: "Report"
    },
    reports: {
      name: "report",
      fileName: "Report"
    },
    userCreatedReports: {
      name: "userCreatedReports",
      fileName: "UserCreatedReports"
    },
    approvals: {
      name: "scheduleApproval",
      fileName: "Approvals"
    },
    news: {
      name: "news",
      fileName: "News"
    },
    notifications: {
      name: "notifications",
      fileName: "Notifications"
    },
    tips: {
      name: "tips",
      fileName: "Tips"
    },
    ezDemos: {
      name: "ezDemo",
      fileName: "EZ Demos"
    },
    stdEmailTemplate: {
      name: "Standard Email Template",
      fileName: "StandardEmailTemplate"
    },
    customEmailTemplate: {
      name: "Custom Email Template",
      fileName: "CustomEmailTemplate"
    },
    dailyTestReport: {
      name: "dailyTestReport",
      fileName: "DailyTestReport"
    },
    products: {
      name: "product",
      fileName: "ReportProducts"
    },
    attributes: {
      name: "attribute",
      fileName: "ReportAttributes"
    },
    sfdcStats: {
      name: "sfdcSchedule",
      fileName: "Schedule Opportunities"
    },
  },


  generalReportNames: {
    demoCount: "Demo Count",
    demoHours: "Demo Hours",
    partnerTop5: "Partner's Top 5",
    associateTop5: "Associate's Top 5",
    trendingDemos: "Trending Demo's",
    demoUsagePerQuarter: "Demo Usage Per Quarter",
    demoUsagePerQuarterGrouped: "Demo Usage Per Quarter Group By Solution And Demo"
  },

  statusDataSet: [
    {
      text: "Active",
      value: true
    },
    {
      text: "Inactive",
      value: false
    },
    {
      text: "All",
      value: undefined
    }

  ],

  expired: {
    true: 1,
    false: 0
  },

  resourceTypes: {
    recommended: {
      title: "Recommended Resources",
      key: "userRegionResources"
    },
    all: {
      title: "All Resources",
      key: "allRegionsResources"
    }
  },
  firebaseEvents: {
    demoGuide: {
      name: 'demo_guide_clicked',
      parameters: {
        "Guide Name": "title",
        "Guide Link": "fileName"
      }
    },
    news: {
      name: 'news_clicked',
      parameters: {
        "News Name": "title",
        "User Name": "userName",
        "User Email": "email",
        "User Country": "country",
        "User Company": "companyName"
      }
    },
    supportGuide: {
      name: 'support_guide_clicked',
      parameters: {
        "Guide Name": "title",
        "Guide Link": "fileName"
      }
    },
    contactUs: {
      name: 'contact_us_clicked',
      parameters: {
        "User Name": "userName",
        "User Email": "email"
      }
    },
    tip: {
      name: 'tip_clicked',
      parameters: {
        "Tip Name": "title",
        "User Name": "userName",
        "User Email": "email",
        "User Country": "country",
        "User Company": "companyName"
      }
    },
  },
  exceptionRoutes: ["^/my-profile$", "^/solutions/[^/]+$"],
  demoldapGroupNone: "None",
  realTimePushNotificationKey: "realTimeKey <messageId>",
  notificationTypes: {
    news: ["Experience Avaya has posted a News Item", "Experience Avaya has updated a News Item"],
    notification: ["Experience Avaya has a new Notification", "Experience Avaya has updated a Notification"],
    demo: ["New Demo Available", "Demo Update"],
    maintenance: ["Maintenance scheduled for your scheduled demo", "Update to Maintenance scheduled for your scheduled demo"],
    dcResourceUpdate: ["Resource Status for your scheduled Demo has been updated", "Your scheduled Demo has been updated"],
    access: "User Role Change",
    rating: ["Admin replied to your Schedule Feedback", "Low Rating for Schedule Demo"],
    ezDemoRating: ["Admin replied to your EZ Demo Feedback", "Low Rating for EZ Demo"],
    adminSchedule: ["Schedule created by Admin on your behalf", "Your schedule is updated by Admin.", "Your schedule has been deleted by Admin."],
    participantAddedSchedule: ["You have been added to a schedule."],
    capabilityRequests: ["Capability request has been raised by user", "Capability request has been assigned to you", "Status of your capability request"]
  },
  pushNotificationTypes: {
    SCHEDULE_PARTICIPANT_ADDED: "SCHEDULE_PARTICIPANT_ADDED",
    DEMO_MEETING_ON_BEHALF_OF_USER: "DEMO_MEETING_ON_BEHALF_OF_USER",
    SCHEDULE_UPDATE_ON_BEHALF_OF_USER: "SCHEDULE_UPDATE_ON_BEHALF_OF_USER",
    SCHEDULE_DELETE_ON_BEHALF_OF_USER: "SCHEDULE_DELETE_ON_BEHALF_OF_USER",
    NOTIFICATION_CAROUSEL_UDPATE: "NOTIFICATION_CAROUSEL_UDPATE",
  },
  capabilityNotificationTypes: ["CAPABILITY_REQUEST_ADMINS", "CAPABILITY_REQUEST_ASSIGNEE", "CAPABILITY_REQUEST_COMPLETE_USER"],
  ezDemoRatingNotificationTypes: ["EZ_DEMO_LOW_RATING", "EZ_DEMO_FEEDBACK_REPLY"],
  accessNotificationTypes: {
    demos: 'Demos',
    news: 'News',
    notifications: 'Notifications'
  },
  manage: "MANAGE",
  listOfAccess: "You have been assigned a new role and now have access to the following items: ",
  notificationsPageSize: 5,
  publicRoutes: ["/home", "/home/non-sso-users", "/about-us", "/support"],
  trendingDemosTimeline: ["Last 7 days", "Last 30 days", "Last 3 Months", "Last 6 Months", "Last Year", "Custom Month"],
  mostUsedDemosTimeline: ["Last 30 Days", "Last 6 Months", "Last 1 Year"],
  typesOfRequests: ["Schedule Approval Request", "Demo Request", "Capability Request"],
  mostPolularDemosTimeline: ["Last 3 Days", "Last 7 Days", "Last 14 Days"],
  graphTypes: {
    pieChart: "Pie Chart",
    graph: "Graph",
    lineGraph: "Line Graph",
    barGraph: "Bar Graph",
    dualGraph: "Dual Graph"
  },
  emailTemplateType: {
    CUSTOM: "custom",
    STANDARD: "standard"
  },
  graphViews: {
    "Last 7 days": "dayWiseTrendingDemos",
    "Last 30 days": "weekWiseTrendingDemos",
    "Last 3 Months": "monthWiseTrendingDemos",
    "Last 6 Months": "monthWiseTrendingDemos",
    "Last Year": "monthWiseTrendingDemos",
    "Custom Month": "weekWiseTrendingDemos"
  },
  unableToFetch: "Unable To Fetch",
  comingSoon: "Coming Soon",
  operators: {
    text: [
      {
        operator: "=",
        operatorText: "Is Equal To"
      },
      {
        operator: "like",
        operatorText: "Like"
      },
      {
        operator: "!=",
        operatorText: "Not Equal To"
      }
    ],
    datepicker: [
      {
        operator: "<",
        operatorText: "Less Than"
      },
      {
        operator: ">",
        operatorText: "Greater Than"
      },
      {
        operator: "<=",
        operatorText: "Less Than Equal To"
      },
      {
        operator: ">=",
        operatorText: "Greater Than Equal To"
      },
      {
        operator: "IN",
        operatorText: "Date Range"
      }
    ],
    monthpicker: [
      {
        operator: "<",
        operatorText: "Less Than"
      },
      {
        operator: ">",
        operatorText: "Greater Than"
      },
      {
        operator: "<=",
        operatorText: "Less Than Equal To"
      },
      {
        operator: ">=",
        operatorText: "Greater Than Equal To"
      },
      {
        operator: "=",
        operatorText: "Is Equal To"
      }
    ],
    number: [
      {
        operator: "<",
        operatorText: "Less Than"
      },
      {
        operator: ">",
        operatorText: "Greater Than"
      },
      {
        operator: "<=",
        operatorText: "Less Than Equal To"
      },
      {
        operator: ">=",
        operatorText: "Greater Than Equal To"
      },
      {
        operator: "=",
        operatorText: "Is Equal To"
      },
      {
        operator: "!=",
        operatorText: "Not Equal To"
      }
    ],
    duration: [
      {
        operator: "<",
        operatorText: "Less Than"
      },
      {
        operator: ">",
        operatorText: "Greater Than"
      },
      {
        operator: "<=",
        operatorText: "Less Than Equal To"
      },
      {
        operator: ">=",
        operatorText: "Greater Than Equal To"
      },
      {
        operator: "=",
        operatorText: "Is Equal To"
      },
      {
        operator: "!=",
        operatorText: "Not Equal To"
      }
    ],
    multiselect: [
      {
        operator: "=",
        operatorText: "Is Equal To"
      },
      {
        operator: "!=",
        operatorText: "Not Equal To"
      }
    ],
    singleselect: [
      {
        operator: "=",
        operatorText: "Is Equal To"
      },
      {
        operator: "!=",
        operatorText: "Not Equal To"
      }
    ],
    package: [
      {
        operator: "=",
        operatorText: "Is Equal To"
      },
      {
        operator: "!=",
        operatorText: "Not Equal To"
      }
    ],
    boolean: [
      {
        operator: "=",
        operatorText: "Is Equal To"
      }
    ],
    autocomplete: [
      {
        operator: "=",
        operatorText: "Is Equal To"
      },
      {
        operator: "!=",
        operatorText: "Not Equal To"
      }
    ],
  },
  booleanValue: [true, false],
  searchRoutes: {
    Demo: "/solutions/demo/activeTab/",
    Solution: "/solutions/demos/solutionTypeId/",
    EzDemo: "/ez-demos/",
    News: "/news/",
    Support: "/support/guideType/",
    MyReports: "/my-reports/",
    StandardReports: "/standard-reports/"
  },
  searchTags: {
    Demo: "D",
    Solution: "S",
    News: "N",
    Notification: "NT",
    Tip: "T",
    Support: "SP",
    EzDemo: "EZ",
    Report: "R"
  },

  searchTagsColor: {
    Demo: "#108ee9",
    Solution: "#f50",
    News: "#009999",
    Notification: "#cc0099",
    Support: "#993333",
    EzDemo: "#87d068",
    Report: "#cc9900"
  },

  dependencyModalTitle: "Dependencies Affected",
  deleteModalTitle: "Would you like to delete this <placeholder>?",
  restoreModalTitle: "Would you like to restore this <placeholder>?",
  demoSections: {
    "access": "Access",
    "downloads": "Documents",
    "title, description": "Title/Description",
    "components": "Component"
  },

  approvalStatusTypes: {
    myApprovals: {
      value: "MY_APPROVALS",
      status: "My Approvals"
    },
    pendingRequests: {
      value: "PENDING",
      status: "Pending"
    },
    approvedRequests: {
      value: "APPROVED",
      status: "Approved"
    },
    declinedRequests: {
      value: "DECLINED",
      status: "Declined"
    },
    expiredRequests: {
      value: "EXPIRED",
      status: "Expired"
    }
  },

  dataCenterStatus: {
    unResolved: {
      value: "open",
      status: "Open"
    },
    resolved: {
      value: "closed",
      status: "Closed"
    },
  },

  demoRequestStatus: {
    assigned: {
      value: "assigned",
      status: "Assigned"
    },
    pending: {
      value: "pending",
      status: "Pending"
    },
    approved: {
      value: "approved",
      status: "Approved"
    },
    declined: {
      value: "declined",
      status: "Declined"
    },
    all: {
      value: "pending,approved,declined",
      status: "All"
    }
  },

  capabilityRequestStatus: {
    assigned: {
      value: "Assigned",
      status: "Assigned"
    },
    pending: {
      value: "Pending",
      status: "Pending"
    },
    completed: {
      value: "Approved",
      status: "Approved"
    },
    declined: {
      value: "Declined",
      status: "Declined"
    },
    expired: {
      value: "Expired",
      status: "Expired"
    },
    all:
    {
      value: "All",
      status: "All"
    }
  },

  approvalBuildStatus: {
    buildFailed: "Incomplete Build",
    buildComplete: "Build Triggered"
  },

  demoTypeOptions: [
    {
      name: "Standard Demo",
      value: "Standard Demo"
    },
    {
      name: "Restful API Demo",
      value: "Restful API Demo"
    },
    {
      name: "Lab & PoC Demo",
      value: "Lab & PoC Demo"
    }
  ],
  demoTypes: {
    restfulApiDemo: "Restful API Demo",
    standardDemo: "Standard Demo",
    labPocDemo: "Lab & PoC Demo",
    restfulPoC: "Restful&PoC"
  },
  requestType: {
    scheduleApproval: "Schedule Approval Request",
    demoRequest: "Demo Request",
    capabilityRequest: "Capability Request",
    pocRequest: "POC Request"
  },

  requestTypeFilter: [
    {
      text: "All",
      value: "All"
    },
    {
      text: "Schedule Approval Request",
      value: "scheduleApproval"
    }, {
      text: "Demo Request",
      value: "demoRequest"
    }, {
      text: "Capability Request",
      value: "capabilityRequest"
    }],

  methodTypes: ["POST", "DELETE", "PUT", "GET"],

  maintenanceErrorTypes: {
    maintenance: "maintenance",
    labDemos: "labDemos",
    resourceDemos: "resourceDemos&PoC",
  },
  tagColors: {
    pending: "geekblue",
    appoved: "green",
    declined: "red",
    expired: "gold"
  },
  tagChips: {
    pending: "info",
    appoved: "success",
    declined: "alert",
    expired: "warning"
  },
  APISampleJsonPlaceholder: "{ \"key1\": \"value1\",\"key2\": \"value2\"}",
  viewType: {
    TABLE: "tableView",
    CALENDAR: "calenderView"
  },
  maintenanceTypeOptions: [
    {
      value: "Demo DC Resource",
      name: "Demo in a specific Datacenter / Resource in a Demo"
    },
    {
      value: "Demo",
      name: "Demo across all Data Centers"
    }
  ],
  maintenanceTypes: {
    demo: "Demo",
    demoDCResource: "Demo DC Resource"
  },
  demoBuildStatusTagColors: {
    'In-Progress': 'blue',
    'Available': 'green',
    'Expired': 'red',
    'Canceled (In-Progress)': 'red',
    'Canceled (Available)': 'red',
    'Canceled (Failed)': 'red',
    'Failed': 'red',
    'Failed (In-Progress)': 'red',
    'Torn Down (Manual)': 'red',
    'Torn Down (Auto)': 'red',
    'Torn Down (Canceled)': 'red',
    'Closed': 'red',
    'New': 'blue',
    "Assigned": 'blue',
    "Torn Down": 'red', "Canceled": 'red'
  },
  demoBuildStatusChips: {
    'In-Progress': 'default',
    'Available': 'success',
    'Expired': 'warning',
    'Canceled (In-Progress)': 'alert',
    'Canceled (Available)': 'alert',
    'Canceled (Failed)': 'alert',
    'Failed': 'alert',
    'Failed (In-Progress)': 'alert',
    'Torn Down (Manual)': 'warning',
    'Torn Down (Auto)': 'warning',
    'Torn Down (Canceled)': 'alert',
    'Closed': 'alert',
    'New': 'info',
    "Assigned": 'default',
    "Torn Down": 'warning',
    "Canceled": 'alert'
  },

  demoBuildStatusCardRibbonColors: {
    'In-Progress': '#a1dbff',
    'Available': 'green',
    'Expired': 'red',
    'Canceled (In-Progress)': 'red',
    'Canceled (Available)': 'red',
    'Canceled (Failed)': 'red',
    'Failed': 'red',
    'Failed (In-Progress)': 'red',
    'Torn Down (Manual)': 'red',
    'Torn Down (Auto)': 'red',
    'Torn Down (Canceled)': 'red',
    'Closed': 'red',
    'New': '#a1dbff',
    "Assigned": '#a1dbff',
    "Torn Down": 'red', "Canceled": 'red'
  },

  manualBuildStatusDataSet: {
    "Assigned": ["In-Progress", "Available", "Torn Down", "Closed"],
    "In-Progress": ["Available", "Torn Down"],
    "Available": ["Torn Down"],
    "Expired": ["Torn Down", "Closed"],
    "Canceled": ["Torn Down", "Closed"]
  },
  demoQuestionAnswerTypes: [
    {
      name: "Textbox",
      value: "Textbox"
    },
    {
      name: "Checkbox",
      value: "Checkbox"
    },
    {
      name: "Radio Button",
      value: "Radio Button"
    }
  ],

  demoAnswerTypes: {
    Textbox: "Textbox",
    Checkbox: "Checkbox",
    RadioButton: "Radio Button"
  },

  demoQuestionTypes: [
    {
      name: "Pre-Requisite",
      value: "preRequisite"
    },
    {
      name: "Pre-Register",
      value: "preRegister"
    }
  ],

  emailTemplateEventType: {
    "manualBuildAvailable": "MANUAL_BUILD_AVAILABLE",
    "demoRequestCompleteUser": "DEMO_REQUEST_COMPLETE_USER",
    "maintenanceCreate": "SCHEDULE_MAINTENANCE_CREATE",
    "maintenanceUpdate": "SCHEDULE_MAINTENANCE_UPDATE"
  },


  customErrorCode: {
    "duplicate_title": "DUPLICATE_TITLE",
    "multiple_days_restriction": "DUPLICATE_TITLE"
  },
  exportFileType: {
    "csv": {
      name: "CSV",
      ext: "csv"
    },
    "xlsx": {
      name: "XLSX",
      ext: "xlsx"
    },
  },

  editComponentRoute: {
    demoAccess: {
      title: "Access - ",
      key: "/user/demo/access/"
    },
    nonSsoUsers: {
      title: "Non-SSO Users - ",
      key: "/manage/users/non-sso/edit/"
    },
    resources: {
      title: "Resources - ",
      key: "/manage/demo-resources/edit/"
    },
    tips: {
      title: "Tips - ",
      key: "/manage/tips/edit/"
    },
    roleTemplates: {
      title: "Role Templates - ",
      key: "/manage/role-templates/edit/"
    },
    approvals: {
      title: "Approvals - ",
      key: "/schedule-approvals/details/"
    },
    demos: {
      title: "Demos - ",
      key: "/manage/demos/edit/"
    },
    news: {
      title: "News - ",
      key: "/manage/news/edit/"
    },
    scheduledDemos: {
      title: "View Schedule Details - ",
      key: "/manage/schedules/view/"
    },
    solutions: {
      title: "Solutions - ",
      key: "/manage/demo-solutions/edit/"
    },
    support: {
      title: "Support - ",
      key: "/manage/support/edit/"
    },
    solutionsType: {
      title: "Solution Type - ",
      key: "/manage/solution-type/edit/"
    },
    purpose: {
      title: "Purpose - ",
      key: "/manage/purpose/edit/"
    },
    demoQuestions: {
      title: "Demo Question - ",
      key: "/manage/demo-question/edit/"
    },
    feedbackQuestions: {
      title: "Feedback Question - ",
      key: "/manage/feedback-question/edit/"
    },
    demoRequests: {
      title: "Requests - ",
      key: "/manage/demo-request/details/"
    },
    manualBuilds: {
      title: "Builds - ",
      key: "/manage/emailType/email/"
    },
    notifications: {
      title: "Notifications - ",
      key: "/manage/notifications/edit/"
    },
    standardEmail: {
      title: "Email Template - ",
      key: "/manage/emailTemplates/standard/edit/"
    },
    customEmail: {
      title: "Email Template - ",
      key: "/manage/emailTemplates/custom/edit/"
    },
    customEmailVersion: {
      title: "Versions - ",
      key: "/manage/emailTemplates/custom/version-details/"
    },
    customMaintenanceEmailVersion: {
      title: "Versions - ",
      key: "/manage/emailTemplates/custom/maintenance/version-details/"
    },
    reports: {
      title: "Report - ",
      key: "/manage/reports/edit/"
    },
    neoReports: {
      title: "Report - ",
      key: "/manage/reports/neo/edit/"
    },
    userCustomReport: {
      title: "Report - ",
      key: "/reports/custom/view/"
    },
    userStandardReport: {
      title: "Report - ",
      key: "/reports/standard/view/"
    },
    scheduleMaintenance: {
      title: "Maintenance - ",
      key: "/manage/schedule-maintenance/form/edit/"
    },
    attribute: {
      title: "Attribute - ",
      key: "/manage/daily-attributes-form/edit/"
    },
    dailyReport: {
      title: "Daily Report - ",
      key: "/manage/daily-reports-form/edit/"
    },
    demoCapability: {
      title: "Capability - ",
      key: "/manage/demo-capability-form/edit/"
    },
    capabilityRequestDetails: {
      title: "Request Details - ",
      key: "/manage/capability-request/details/"
    },
    ezDemos: {
      title: "EZ Demo - ",
      key: "/manage/ez-demos/edit/"
    }
  },

  createComponentRoute: {
    demoAccess: {
      title: "Access",
      key: "/user/demo/access/"
    },
    nonSsoUsers: {
      title: "Non-SSO User",
      key: "/manage/users/non-sso/create"
    },
    resources: {
      title: "Resource",
      key: "/manage/demo-resources/create"
    },
    tips: {
      title: "Tip",
      key: "/manage/tips/create"
    },
    roleTemplates: {
      title: "Role Template",
      key: "/manage/role-templates/create"
    },
    demos: {
      title: "Demo",
      key: "/manage/demos/create"
    },
    userNews: {
      title: "News",
      key: "/news"
    },
    userInactiveNews: {
      title: "Previous News",
      key: "/inactive-news"
    },
    news: {
      title: "News",
      key: "/manage/news/create"
    },
    solutions: {
      title: "Solution",
      key: "/manage/demo-solutions/create"
    },
    solutionsType: {
      title: "Solution Type",
      key: "/manage/solution-type/create"
    },
    purpose: {
      title: "Purpose",
      key: "/manage/purpose/create"
    },
    demoQuestions: {
      title: "Demo Question",
      key: "/manage/demo-question/create"
    },
    feedbackQuestions: {
      title: "Feedback Question",
      key: "/manage/feedback-question/create"
    },
    support: {
      title: "Support",
      key: "/manage/support/create"
    },
    notifications: {
      title: "Notification",
      key: "/manage/notifications/create"
    },
    reports: {
      title: "Report",
      key: "/manage/reports/create"
    },
    scheduleMaintenance: {
      title: "Maintenance",
      key: "/manage/schedule-maintenance/form/create"
    },
    attribute: {
      title: "Attribute",
      key: "/manage/daily-attributes-form/create"
    },
    dailyReport: {
      title: "Daily Report",
      key: "/manage/daily-reports-form/create"
    },
    demoCapability: {
      title: "Capability",
      key: "/manage/demo-capability-form/create"
    },
    ezDemo: {
      title: "EZ Demo",
      key: "/manage/ez-demos/create"
    },
    adminSettings: {
      title: "- Settings",
      key: "/admin/settings"
    }
  },


  draftEditComponentRoute: {
    news: {
      title: "News Draft-",
      key: "/manage/news/draft/"
    },
    notification: {
      title: "Notification Draft-",
      key: "/manage/notifications/draft/"
    }
  },

  cloneComponentRoute: {
    resources: {
      title: "Resource",
      key: "/manage/demo-resources/clone/"
    },
    news: {
      title: "News",
      key: "/manage/news/clone/"
    },
    demos: {
      title: "Demo",
      key: "/manage/demos/clone/"
    },
    demosRating: {
      title: "View Ratings - ",
      key: "/manage/demo/ratings/"
    },
    ezDemosRating: {
      title: "View Ratings - ",
      key: "/manage/ez-demos/ratings/"
    },
    notifications: {
      title: "Notification",
      key: "/manage/notifications/clone/"
    },
    customReports: {
      title: "Report",
      key: "/manage/custom-reports/clone"
    },
    scheduleMaintenance: {
      title: "Schedule Maintenance",
      key: "/manage/schedule-maintenance/form/clone/"
    },
    reports: {
      title: "Report",
      key: "/manage/reports/clone/"
    },
  },
  maximumTabLimit: 12,

  reportStatusColor: {
    'Working': 'green',
    'Not Working': 'red',
    'Not Tested': 'blue'
  },

  reportIconType: {
    icons: ["close-circle", "warning", "check-circle", "warning"],
    colors: ["error-text", "warning-text", "success-text", "not-tested"]
  },

  reportViewDetailsMap: {
    icons: ["close-circle", "exclamation-circle", "check-circle"],
    colors: ["error-text", "warning-text", "success-text",]
  },

  reportStausCode: {
    'Not Working': 0,
    'Not Tested': 1,
    'Working': 2,
    'Others': 3,
  },

  dailyReportAttrStatus: {
    'status': ['Not Working', 'Not Tested', 'Working']
  },
  ezDemoType: [
    {
      text: "File",
      value: true
    },
    {
      text: "Link",
      value: false
    }
  ],
  capabilityRequestColors: {
    "Pending": "orange",
    "Completed": "green",
    "Approved": "green",
    "Declined": "red"
  },

  myRequestsColors: {
    "Pending": "blue",
    "Completed": "green",
    "Approved": "green",
    "Declined": "red",
    "Expired": "orange",
    "Approved_sa": "green"
  },
  myRequestsChips: {
    "Pending": "info",
    "Completed": "success",
    "Approved": "success",
    "Declined": "alert",
    "Expired": "warning",
    "Approved_sa": "success"
  },
  myRequestsStatus: {
    Pending: "Pending",
    Completed: "Completed",
    Approved: "Approved",
    Expired: "Expired",
    Declined: "Declined",
    Revoked: "Revoked",
    Approved_sa: "Approved"
  },

  myRequestsCompletedStatusFilter: [
    {
      text: "All",
      value: "completed"
    }, {
      text: "Approved",
      value: "Approved"
    }, {
      text: "Expired",
      value: "Expired"
    },
    {
      text: "Declined",
      value: "Declined"
    }
  ],

  dcStatus: [
    {
      text: "Red",
      value: "0"
    },
    {
      text: "Yellow",
      value: "1"
    },
  ],
  approvalRequestColors: {
    PENDING: "info",
    MY_APPROVALS: "info",
    EXPIRED: "warning",
    APPROVED: "success",
    DECLINED: "alert"
  },

  approvalRequestStatus: {
    PENDING: "Pending",
    EXPIRED: "Expired",
    APPROVED: "Approved",
    DECLINED: "Declined",
    MY_APPROVALS: "Pending",
  },
  capabilityDetailsRequestStatus: {
    Pending: "Pending",
    Approved: "Approved",
    Expired: "Expired",
    Declined: "Declined",
    Revoked: "Revoked"
  },
  draftStatusDataSet: [
    {
      text: "Active",
      value: true
    },
    {
      text: "Inactive",
      value: false
    },
    {
      text: "Draft",
      value: "Draft"
    },
    {
      text: "All",
      value: undefined
    }
  ],
  capabilityMessage: "Capability not required to use demo or to the schedule demo. It is a optional capability for additional demo capabilities if needed.",
  neoPaginationCard: [5, 10, 15, 20],
  yearArray: Array.from({ length: new Date().getFullYear() - 2018 }, (_, index) => new Date().getFullYear() - index),
  userTypes: [
    {
      key: "Avaya",
      value: "avaya"
    },
    {
      key: "Business Partner",
      value: "bp"
    }
  ],
  bufferTimeInfo: {
    startBufferTime: "Start buffer time is the additional time included before your scheduled slot to allow you to prepare, ensuring a smooth demo start and preventing scheduling conflicts.",
    endBufferTime: "End buffer time is the additional time included after your scheduled slot to account for potential delays or unforeseen circumstances.",
    labBufferTime: "Buffer time is the additional time allocated before and after a scheduled event to account for potential delays or unforeseen circumstances."
  },
  standardReportKeys: {
    demoCount: "demoCount",
    demoHours: "demoHours",
    associatesTop5: "associatesTop5",
    partnerTop5: "partnerTop5",
    trendingDemos: "trendingDemos",
    demoUsage: 'demoUsage',
    demoUsageSolutionDemo: 'demoUsageSolutionDemo',
    demoScheduleTrend: "demoScheduleTrend",
    userDistribution: "userDistribution"
  },
  standardReportTypes: {
    demoCount: "Demo Count",
    demoHours: "Demo Hours",
    associatesTop5: "Associate Top 5",
    partnerTop5: "Partner Top 5",
    trendingDemos: "Trending Demos",
    demoUsage: 'Demo Usage Per Quarter',
    demoUsageSolutionDemo: 'Demo Usage Grouped By Demo/Solutions',
    demoScheduleTrend: "Demo Schedules Trend",
    userDistribution: "User Distribution"
  },
  purposeAttributeTypes: {
    opportunityNumber: "Opportunity Number",
    customerName: "Customer Name",
    customerVertical: "Customer Vertical",
  },
  batchInfo: "This schedule is part of a batch, meaning the same demo is scheduled for the same time on different resources.",
  pastelColors: ["#FF8C66", "#6FA6D6", "#9ACD32", "#FF9B7D", "#86B5E4", "#A9D66B", "#FFAB91", "#A2C0F4", "#B6E082", "#FFBAA6", "#B0C9F8", "#C2E493", "#FFCAA2", "#BBCFF9", "#CDEAA4", "#FFD9B8", "#C8D7FB", "#D9F3B6", "#FFE9C4", "#D3E0FC", "#E5FDC9", "#FFF3D2", "#DEE8FC", "#F0FFDA", "#FFFCE8", "#E9F2FD", "#FBFFEC", "#F8FFEE", "#ECF5FD", "#FDFFEE", "#F5FFEF", "#F0F7FD", "#FEFFF1", "#F2FFF3", "#F3F8FD", "#FEFFF4", "#F8FFF6", "#F7FAFD", "#FEFFF9", "#FBFFFA", "#FAFBFD", "#FEFFFC", "#FCFFFB", "#FCFDFF", "#FEFFFD", "#FDFFFE", "#FDFEFF", "#FEFFFE", "#FEFFFE", "#FEFFFE", "#FEFFFE"],
  footerProductLinks: [{ "id": 1, "name": "Avaya Experience Platform", "link": "https://www.avaya.com/en/products/experience-platform/" }, { "id": 2, "name": "Avaya Communications APIs", "link": "https://www.avaya.com/en/products/communications-apis/" }, { "id": 3, "name": "Avaya Cloud Office", "link": "https://www.avaya.com/en/products/ucaas/cloud-office/" }, { "id": 4, "name": "Avaya Spaces", "link": "https://www.avaya.com/en/products/ucaas/spaces/" }, { "id": 5, "name": "Avaya Services", "link": "https://www.avaya.com/en/services/" }, { "id": 6, "name": "Avaya Enterprise Cloud", "link": "https://www.avaya.com/en/enterprise-cloud/" }, { "id": 7, "name": "Devices Catalog", "link": "https://www.avaya.com/en/devices-and-phones/" }, { "id": 8, "name": "Avaya Call Center Elite", "link": "https://www.avaya.com/en/products/call-center-elite/" }, { "id": 9, "name": "Avaya Aura Platform", "link": "https://www.avaya.com/en/products/aura/" }, { "id": 10, "name": "Avaya IP Office", "link": "https://www.avaya.com/en/products/ip-office/" }, { "id": 11, "name": "Innovation Without Disruption", "link": "https://www.avaya.com/en/solutions/innovation-without-disruption/" }],
  footerPartnerLinks: [{ "id": 1, "name": "Partner Locator", "link": "https://www.avaya.com/en/partner-locator/" }, { "id": 2, "name": "DevConnect", "link": "https://www.avaya.com/en/partners/devconnect/" }, { "id": 3, "name": "Alliances", "link": "https://www.avaya.com/en/partners/alliances/" }, { "id": 4, "name": "All Programs", "link": "https://www.avaya.com/en/partners/" }, { "id": 5, "name": "Channel Partners", "link": "https://www.avaya.com/en/partners/channelpartners/" }, { "id": 6, "name": "Sales Agents", "link": "https://www.avaya.com/en/partners/salesagents/" }],
  footerCompanyLinks: [{ "id": 1, "name": "About Avaya", "link": "https://www.avaya.com/en/about-avaya/" }, { "id": 2, "name": "Avaya Life", "link": "https://www.avaya.com/en/about-avaya/avaya-life/" }, { "id": 3, "name": "Diversity, Equity, Inclusion, Belonging", "link": "https://www.avaya.com/en/about-avaya/diversity-and-inclusion/" }, { "id": 4, "name": "Sustainability", "link": "https://www.avaya.com/en/about-avaya/sustainability/" }, { "id": 5, "name": "Newsroom", "link": "https://www.avaya.com/en/about-avaya/newsroom/" }, { "id": 6, "name": "Investor Relations", "link": "https://www.avaya.com/en/investor-relations/" }, { "id": 7, "name": "Awards", "link": "https://www.avaya.com/en/awards/" }, { "id": 8, "name": "Contact Avaya", "link": "https://www.avaya.com/en/contacts/" }, { "id": 9, "name": "Careers—We're Hiring!", "link": "https://www.avaya.com/en/about-avaya/careers/welcome/" }],
  footerResourceLinks: [{ "id": 1, "name": "Support", "link": "https://support.avaya.com/" }, { "id": 2, "name": "Blog", "link": "https://www.avaya.com/blogs/" }, { "id": 3, "name": "Videos", "link": "https://www.avaya.com/en/videos/" }, { "id": 4, "name": "Customer Stories", "link": "https://www.avaya.com/en/success-stories/" }, { "id": 5, "name": "Events", "link": "https://www.avaya.com/en/about-avaya/events/" }, { "id": 6, "name": "Executive Briefing Centers", "link": "https://www.avaya.com/en/executive-briefing-centers/" }, { "id": 7, "name": "Avaya Trust Center", "link": "https://www.avaya.com/en/executive-briefing-centers/" }, { "id": 8, "name": "Avaya Legal", "link": "https://www.avaya.com/en/legal/" }, { "id": 9, "name": "Documentation Center", "link": "https://documentation.avaya.com/" }],
  neoGraphColors: [
    "#FA6156", "#1B77AF", "#51A651", "#51A651", "#FFC46C",
    "#BF73E5", "#896C15", "#1CC6D7", "#D51588", "#757474",
    "#2E9FDB", "#600B0C", "#2B6B2B", "#F38D00", "#4C1D63",
    "#775D13", "#1CC6D7", "#680A42", "#5E5E5E", "#FF9D98",
    "#1B77AF", "#215321", "#FFC46C", "#AB3FE0", "#5C480F",
    "#50DFED", "#D51588", "#2E9FDB", "#FFC46C", "#215321",
    "#FFC0BE", "#78C1E8", "#9CD99C", "#FFC46C", "#E0BBF2",
    "#C8AC65", "#ABF2F9", "#F476C3", "#757474", "#002837",
    "#AB3FE0", "#B31272", "#087781", "#E4D8AB", "#ABD8F1",
    "#512A00", "#CE94EB", "#0A8E9B", "#F8AAD9", "#C9C9C9",
    "#583D00"
  ],
  SFDCStatus: {
    pending: 0,
    exception: 1
  },
  countryLayers: ["0-15", "15-30", "30-50", "50-80", "80-100",
    "100-150", "150-200", "200-350", "350-500", "500-700",
    "700-950", "950-1200", "1200-1450", "1450-1800", "1800-2100",
    "2100-2400", "2400-2800", "2800-3000", "3000-5000", "5000-10000"
  ],
  regionColors: [
    "#FFC0BE", "#78C1E8", "#9CD99C", "#FFC46C", "#E0BBF2",
    "#FA6156", "#1B77AF", "#51A651", "#51A651", "#FFC46C",
    "#BF73E5", "#896C15", "#1CC6D7", "#D51588", "#757474",
    "#2E9FDB", "#600B0C", "#2B6B2B", "#F38D00", "#4C1D63",
    "#775D13", "#1CC6D7", "#680A42", "#5E5E5E", "#FF9D98",
    "#1B77AF", "#215321", "#FFC46C", "#AB3FE0", "#5C480F",
    "#50DFED", "#D51588", "#2E9FDB", "#FFC46C", "#215321",
    "#FFC0BE", "#78C1E8", "#9CD99C", "#FFC46C", "#E0BBF2",
    "#C8AC65", "#ABF2F9", "#F476C3", "#757474", "#002837",
    "#AB3FE0", "#B31272", "#087781", "#E4D8AB", "#ABD8F1",
    "#512A00", "#CE94EB", "#0A8E9B", "#F8AAD9", "#C9C9C9",
    "#583D00"
  ]
}

export default config;

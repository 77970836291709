import React, { useEffect, useState } from 'react';
import { Row, Col, Switch, Form, InputNumber, Icon, Tooltip, Tabs, Button, Modal, Card, Checkbox, Input, Spin } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import {
    toggleIsFetchingDemosFlag,
    fetchAllUsersForDemo
} from '../../actions';
import CONFIG from '../../config';
import RestfulAPIDetails from './RestfulAPIDetails';
import pushMessage from '../../components/common/PushMessage';
import { Select } from "antd5"
const { Option } = Select;
const { TabPane } = Tabs;

let id = 0;

export default function RestfulAPIDemoDetails({ form, fetchUserEmails, matchedUserEmails, demoType, isEditMode, createStatus, deleteStatus, setStatus, demoId }) {
    let demoApproversList = [[]];
    const dispatch = useDispatch();
    const demoBasicDetails = useSelector(({ openTabDemoDetails }) => openTabDemoDetails?.currentTabData?.demoBasicDetails);
    const allUsers = useSelector(({ capabilityDemos }) => capabilityDemos?.allUsers);
    const { scheduleRange, startTimeBuffer, maxSchedule, autoDestroy, demoApprovers, parameters, isManual, isManagerMandatory, approvalRequired, managerTitle, regionalPrimeTitle, isMultipleApprover, isManagerEmail, isRegionalPrime, demoRegionalPrimes } = demoBasicDetails || {};
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const isManualFlow = form.getFieldValue("isManual");
    const isPrimeOn = getFieldValue('isRegionalPrime');
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [managerEditingKey, setManagerEditingKey] = useState(false);
    const [primeEditingKey, setPrimeEditingKey] = useState(false);

    useEffect(() => {
        if (isEditMode) id = demoApproversList.length - 1;
        dispatch(toggleIsFetchingDemosFlag(true));
        const fetchUsers = async () => {
            setLoading(true);
            await dispatch(fetchAllUsersForDemo()).then(() => setLoading(false));
        }
        fetchUsers();
        setTimeout(() => {
            dispatch(toggleIsFetchingDemosFlag(false))
        }, 2500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (demoApprovers) {
        demoApprovers.forEach(item => demoApproversList[item.level] ? demoApproversList[item.level].push(item.approver) : demoApproversList[item.level] = [item.approver])
    }

    getFieldDecorator('keys', { initialValue: demoApproversList?.length > 2 ? [...Array(demoApproversList?.length - 2).keys()] : [] });
    const keys = getFieldValue('keys') || [];
    const isApprovalToggleOn = getFieldValue('approvalRequired') || false;
    const isManagerToggleOn = ((getFieldValue('isManagerMandatory') && getFieldValue('isManagerEmail')) || getFieldValue('isRegionalPrime')) || false;

    const remove = k => {
        const keys = getFieldValue('keys');
        setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    const add = () => {
        const keys = getFieldValue('keys');
        const nextKeys = keys.concat(id++);
        setFieldsValue({
            keys: nextKeys,
        });
    };

    const handleEmailValidation = (values, level) => {
        if (!values.match(CONFIG.regEx.email)) {
            pushMessage(CONFIG.messageType.warning, "Please input a valid Email ID");
            setFieldsValue({ [`demoApprovers[${level}]`]: getFieldValue(`demoApprovers[${level}]`).slice(0, -1) });
        }
    }

    const getInitialPrimeValues = () => {
        return allUsers?.filter(eachUser => demoRegionalPrimes?.find(item => item?.regionalPrime?.id === eachUser?.id)).map(item => JSON.stringify(item))
    }

    const initialPrimeValue = demoRegionalPrimes ? getInitialPrimeValues() : []

    const handleAutoDestroy = (value) => {
        if (value) form.setFieldsValue({ isManual: false });
    }

    const handleManualFlow = (value) => {
        if (value) form.setFieldsValue({ autoDestroy: false })
    }

    const handleApprovalRequiredChange = (value) => {
        if (!value) {
            form.setFieldsValue({
                isManagerEmail: false,
                isRegionalPrime: false,
                isMultipleApprover: false,
                isManagerMandatory: false
            })
        }
        else {
            resetFields();
        }
    }

    const checkFormWarnings = () => {
        const keys = getFieldValue("keys")
        const otherLevels = keys.map((key, index) => `demoApprovers[${index + 2}]`)
        form.validateFields(["managerTitle", "regionalPrimeTitle", "regionalPrimes", "demoApprovers[1]", ...(otherLevels?.length > 0 ? otherLevels : [])], (errors, values) => {
            if (errors === null) {
                const isPrimeOn = getFieldValue('isRegionalPrime');
                const isManagerEmail = getFieldValue('isManagerEmail');
                const isManagerMandatory = getFieldValue('isManagerMandatory');
                const isMultipleApprover = getFieldValue('isMultipleApprover');
                if (isManagerEmail && !isManagerMandatory && !isMultipleApprover && !isPrimeOn) {
                    pushMessage(CONFIG.messageType.warning, "Since manager email is not mandatory you need to enable regional prime or multi-level approval");
                }
                else {
                    setShowModal(false)
                    checkApprovalRequiredFlag();
                }
            }
        })
    }

    const checkApprovalRequiredFlag = () => {
        const isToggleOff = !getFieldValue('isManagerEmail') && !getFieldValue('isRegionalPrime') && !getFieldValue('isMultipleApprover');
        if (isToggleOff) setFieldsValue({ approvalRequired: false })
    }

    const resetFields = () => {
        const keys = getFieldValue("keys")
        const otherLevels = keys.map((key, index) => `demoApprovers[${index + 2}]`)
        form.resetFields([
            "managerTitle",
            "regionalPrimeTitle",
            "regionalPrimes",
            "isManagerEmail",
            "isRegionalPrime",
            "isMultipleApprover",
            "isManagerMandatory",
            "keys",
            "demoApprovers[1]",
            ...(otherLevels?.length > 0 ? otherLevels : [])
        ]);
    }

    const closeModal = () => {
        resetFields();
        setShowModal(false);
        checkApprovalRequiredFlag();
    }

    const getNumberWithOrdinal = (number) => {
        let strings = ["th", "st", "nd", "rd"],
            value = number % 100;
        return number + (strings[(value - 20) % 10] || strings[value] || strings[0]);
    }

    const renderRegionalPrimes = () => {
        return <Col xl={20} xs={24}>
            <Spin spinning={loading}>
                <Form.Item label="Add Managers">
                    {getFieldDecorator("regionalPrimes", {
                        initialValue: initialPrimeValue,
                        rules: [{
                            type: 'array',
                            required: isPrimeOn,
                            message: "Please select a value",
                        },
                        ],
                    })(
                        <Select disabled={!getFieldValue('isRegionalPrime')} mode="tags">
                            {
                                allUsers?.map((item, index) =>
                                    <Option key={item?.id} title={item?.email} value={JSON.stringify(item)}>{item?.email}</Option>
                                )
                            }
                        </Select>
                    )}
                </Form.Item>
            </Spin>
        </Col>
    }

    const renderApproversList = (level, validation = true, key, totalLevels) => {
        return <React.Fragment key={level}>
            <Col xl={20} xs={24}>
                <Form.Item label={`${getNumberWithOrdinal(level)} Approver`}>
                    {
                        getFieldDecorator(`demoApprovers[${level}]`, {
                            // eslint-disable-next-line
                            initialValue: eval(`demoApproversList[${level}]`),
                            rules: validation && [
                                (level === 1 && isManagerToggleOn) ?
                                    {
                                        required: false,
                                        type: 'array'
                                    }
                                    :
                                    {
                                        type: 'array',
                                        required: getFieldValue('isMultipleApprover') ? true : false,
                                        whitespace: true,
                                        message: "Please select a value",
                                    },
                            ],
                        })(
                            <Select
                                disabled={!getFieldValue('isMultipleApprover')}
                                mode="tags"
                                showSearch={true}
                                placeholder='Type value to search'
                                onSearch={fetchUserEmails}
                                notFoundContent="No Match found!"
                                onSelect={(value) => handleEmailValidation(value, level)}
                            >
                                {
                                    matchedUserEmails?.map((item, index) =>
                                        <Option key={index} title={item} value={item}>{item}</Option>
                                    )
                                }
                            </Select>
                        )
                    }
                </Form.Item>
            </Col>
            {(totalLevels === level && getFieldValue('isMultipleApprover')) && <> <Col xl={1} xs={24} style={{ marginTop: 35, marginLeft: 12 }}>
                <Icon aria-label="aria-menu"
                    className="dynamic-delete-button"
                    type="plus-circle-o"
                    onClick={add}
                />
            </Col>
                <Col xl={1} xs={24} style={{ marginTop: 35, marginLeft: 12 }}>
                    {level !== 1 &&

                        <Icon aria-label="aria-menu"
                            className="dynamic-delete-button"
                            type="minus-circle-o"
                            onClick={() => remove(key)}
                        />
                    }
                </Col></>}
        </React.Fragment>
    }

    return (
        <>
            <Row gutter={12}>
                <Col xl={5} lg={12} md={12} sm={12} xs={12}>
                    <Form.Item label={<span>Demo Duration <Tooltip title={CONFIG.systemMessage.demoDuration}><Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                        {getFieldDecorator("scheduleRange", {
                            rules: [{ required: true, message: "This field cannot be empty" }],
                            initialValue: scheduleRange ?? 1
                        })(
                            <InputNumber
                                min={CONFIG.scheduleRange.min}
                                step={CONFIG.scheduleRange.step}
                                formatter={value => `${value} day(s)`}
                                parser={value => value.replace(/[^0-9]/g, '')}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col xl={5} lg={12} md={12} sm={12} xs={12}>
                    <Form.Item label={<span>Build Duration <Tooltip title={CONFIG.systemMessage.buildDuration}><Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                        {getFieldDecorator("buildDuration", {
                            rules: [{ required: true, message: "This field cannot be empty" }],
                            initialValue: startTimeBuffer ? startTimeBuffer / 60 : 1
                        })(
                            <InputNumber
                                min={CONFIG.scheduleRange.min}
                                step={CONFIG.scheduleRange.step}
                                formatter={value => `${value} hour(s)`}
                                parser={value => value.replace(/[^0-9]/g, '')}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col xl={6} sm={5} xs={12}>
                    <Form.Item label="Max Simultaneous Demos">
                        {getFieldDecorator("maxSchedule", {
                            rules: [{ required: true, message: "This field cannot be empty" }],
                            initialValue: maxSchedule ?? 1
                        })(
                            <InputNumber min={CONFIG.scheduleRange.min} step={CONFIG.scheduleRange.step} />
                        )}
                    </Form.Item>
                </Col>
                <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                    <Form.Item label='Checkbox Group'>
                        {
                            getFieldDecorator('parameters', {
                                initialValue: parameters?.split(CONFIG.delimiters.selectFilter)
                            })(
                                <Select
                                    mode="tags"
                                    placeholder='Add Checkboxes'
                                    showSearch={true}
                                />
                            )
                        }
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col xl={5} lg={6} sm={6} xs={12} >
                    <Form.Item label={<span><Tooltip title={CONFIG.systemMessage.autoDestroy} >Auto Destroy <Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                        {getFieldDecorator("autoDestroy", {
                            valuePropName: "checked",
                            initialValue: autoDestroy ?? false
                        })(
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onChange={handleAutoDestroy}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col xl={5} lg={6} sm={6} xs={12} >
                    <Form.Item label={<span><Tooltip title={CONFIG.systemMessage.manualFlow} >Manual Flow <Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                        {getFieldDecorator("isManual", {
                            valuePropName: "checked",
                            initialValue: isManual ?? false
                        })(
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onChange={handleManualFlow}
                            />
                        )}
                    </Form.Item>
                </Col>
                {demoType === CONFIG.demoTypes.labPocDemo && <Col xl={6} lg={6} sm={6} xs={12} >
                    <Form.Item label="Approval Required">
                        {getFieldDecorator("approvalRequired", {
                            valuePropName: "checked",
                            initialValue: approvalRequired ?? false
                        })(
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onChange={handleApprovalRequiredChange}
                            />
                        )}
                    </Form.Item>
                </Col>}

                {(demoType === CONFIG.demoTypes.labPocDemo && isApprovalToggleOn) &&
                    <Col style={{ marginTop: "17px", paddingLeft: "0px" }} xl={6} lg={8} sm={24} xs={24}>
                        <Button aria-label="Click" onClick={() => setShowModal(true)} className="primary-action-button-bordered horizontal-spacing antd-button-fix">
                            Manage Approvers
                            <Icon aria-label="aria-menu" type="plus-circle" />
                        </Button>
                    </Col>
                }
            </Row>
            <Modal
                visible={showModal}
                onOk={() => checkFormWarnings()}
                onCancel={() => closeModal()}
                okText="Save & Close"
                cancelText="Discard & Close"
                okButtonProps={{ className: "primary-action-button-filled" }}
                title="Manage Approvers"
                className='medium-modal approver-modal'
            >
                <div className='vertical-spacing-3'>
                    <Card className='glow2 demo-form-approvers'>
                        <div style={{ margin: 5, fontWeight: "500", fontFamily: 'Noto Sans', }}>
                            <Form.Item style={{ display: "inline-block" }}>
                                {getFieldDecorator("managerTitle", {
                                    initialValue: managerTitle || "Manager Approval",
                                    rules: [{
                                        required: true,
                                        message: "Manager title is required"
                                    }]
                                })(
                                    <Input className={managerEditingKey ? '' : 'input-borderless'} />
                                )}
                            </Form.Item>
                            <span>
                                <button aria-label="Click" className="link">
                                    {!managerEditingKey ? <span onClick={() => setManagerEditingKey(true)} class="neo-icon-edit" title="Edit" style={{ marginLeft: "5px", fontSize: "20px" }}></span> :
                                        <>
                                            <span onClick={() => setManagerEditingKey(false)} class="neo-icon-save" title="Save" style={{ marginLeft: "5px", fontSize: "20px" }}></span>
                                            <span onClick={() => setManagerEditingKey(false)} class="neo-icon-close" title="Close" style={{ marginLeft: "5px", fontSize: "20px" }}></span>
                                        </>
                                    }
                                </button>
                            </span>
                            <span className='float-right'>
                                <Form.Item>
                                    {getFieldDecorator("isManagerEmail", {
                                        valuePropName: "checked",
                                        initialValue: isManagerEmail ?? false
                                    })(
                                        <Switch
                                            style={{ width: "0px" }}
                                        />
                                    )}
                                </Form.Item>
                            </span>
                        </div>
                        <div style={{ marginLeft: 15 }}>
                            <Form.Item>
                                {getFieldDecorator("isManagerMandatory", {
                                    valuePropName: "checked",
                                    initialValue: isManagerMandatory
                                })(
                                    <Checkbox disabled={!getFieldValue('isManagerEmail')}>Manager Email Mandatory</Checkbox>
                                )}
                            </Form.Item>
                        </div>
                    </Card>
                </div>
                <div className='vertical-spacing-3'>
                    <Card className='glow2 demo-form-approvers'>
                        <div style={{ margin: 5, fontWeight: "500", fontFamily: 'Noto Sans', }}>
                            <Form.Item style={{ display: "inline-block" }}>
                                {getFieldDecorator("regionalPrimeTitle", {
                                    initialValue: regionalPrimeTitle || "Regional Prime Approval",
                                    rules: [{
                                        required: true,
                                        message: "Regional Prime title is required"
                                    }]
                                })(
                                    <Input className={primeEditingKey ? '' : 'input-borderless'} />
                                )}
                            </Form.Item>
                            <span>
                                <button aria-label="Click" className="link">
                                    {!primeEditingKey ? <span onClick={() => setPrimeEditingKey(true)} class="neo-icon-edit" title="Edit" style={{ marginLeft: "5px", fontSize: "20px" }}></span> :
                                        <>
                                            <span onClick={() => setPrimeEditingKey(false)} class="neo-icon-save" title="Save" style={{ marginLeft: "5px", fontSize: "20px" }}></span>
                                            <span onClick={() => setPrimeEditingKey(false)} class="neo-icon-close" title="Close" style={{ marginLeft: "5px", fontSize: "20px" }}></span>
                                        </>
                                    }
                                </button>
                            </span>
                            <span className='float-right'>
                                <Form.Item>
                                    {getFieldDecorator("isRegionalPrime", {
                                        valuePropName: "checked",
                                        initialValue: isRegionalPrime ?? false
                                    })(
                                        <Switch
                                            style={{ width: "0px" }}
                                        />
                                    )}
                                </Form.Item>
                            </span>
                        </div>
                        <div style={{ marginLeft: 15 }}>
                            {renderRegionalPrimes()}
                        </div>
                    </Card>
                </div>
                <div className='vertical-spacing-3'>
                    <Card className='glow2 demo-form-approvers'>
                        <div style={{ margin: 5, fontWeight: "500", fontFamily: 'Noto Sans', }}>
                            Multi-level Approvers
                            <span className='float-right'>
                                <Form.Item>
                                    {getFieldDecorator("isMultipleApprover", {
                                        valuePropName: "checked",
                                        initialValue: isMultipleApprover ?? false
                                    })(
                                        <Switch
                                            style={{ width: "0px" }}
                                        />
                                    )}
                                </Form.Item>
                            </span>
                        </div>
                        <Row style={{ marginLeft: "15px" }}>
                            {renderApproversList(1, true, null, keys?.length + 1)}
                            {keys.map((key, index) => {
                                return renderApproversList(index + 2, true, key, keys?.length + 1)
                            })}
                        </Row>
                    </Card>
                </div>
            </Modal>

            {!isManualFlow && <><h3>API Details</h3>
                <Tabs defaultActiveKey={"create"} >
                    <TabPane tab="Create API" key="create" forceRender={true}>
                        {<RestfulAPIDetails form={form} requestType="create" demoBasicDetails={demoBasicDetails} isEditMode={isEditMode} createStatus={createStatus} deleteStatus={deleteStatus} setStatus={setStatus} />}
                    </TabPane>
                    <TabPane tab="Delete API" key="delete" forceRender={true}>
                        {<RestfulAPIDetails form={form} requestType="delete" demoBasicDetails={demoBasicDetails} isEditMode={isEditMode} createStatus={createStatus} deleteStatus={deleteStatus} setStatus={setStatus} />}
                    </TabPane>
                </Tabs></>}
        </>
    )
}

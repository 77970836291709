import React from "react";
import { connect, batch } from "react-redux";
import { Row, Form, message, Dropdown, Spin, Tooltip, Tabs } from 'antd';
import {
    selectTitle,
    toggleBackButton,
    getAllDemosLite,
    updateDemoRequestEditingKey,
    fetchDemoRequests,
    fetchUsersForDemoDataCenter,
    putAssignedUserDataCenter,
    getAllDemosRequests,
    exportTable,
    clearDemoRequestData,
    toggleIsFetchingDemoRequest,
    toggleIsFetchingDemoRequestUsers,
    handleOnClickAction,
    currentDemoRequestFilter,
    updateDemoRequestCurrentPage,
    toggleHasUnsavedChangesFlag,
    toggleSaveReportVisible
} from '../../actions';
import CONFIG from '../../config';
import { otherFields } from '../../utils/neoTableFields';
import { checkIfFilterSortIsEmpty } from '../../utils/formChanged';
import { Icon as NeoIcon, Button as NeoButton, Table as NeoTable, TextInput, Form as NeoForm, Select, SelectOption } from "neo-latest"
import FilterDrawer from "../common/FilterDrawer";
import moment from 'moment-timezone';
import { fetchNamesBySearchString } from '../../actions/userRole'
import pushMessage from '../common/PushMessage';
import { exportMenu } from "../../utils/strings";
import { isTabAlreadyOpen } from "../common/TabLayout";
import { LoadingOutlined } from '@ant-design/icons';
import SaveReport from "../common/SaveReport";
import { BasicModal } from "@avaya/neo-react";
export const ManageDemoRequestsContext = React.createContext();
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;


const dataIndexMap = {
    demo: 'demo.title',
    updatedBy: 'updatedBy.fullName',
    updatedDate: 'updatedDate',
    description: 'description',
    assigned: "assignedUser.fullName",
    resolvedBy: "resolvedBy.fullName",
    resolvedDate: "resolvedDate",
    startUpdatedTime: "startUpdatedTime",
    endUpdatedTime: "endUpdatedTime",
    startResolvedTime: "startResolvedTime",
    endResolvedTime: "endResolvedTime",
    status: "status",
    type: "type",
    dcTitle: "dataCenter.title",
    resourceTitle: "resource.name",
    viewDeletedItems: 'viewDeletedItems',
};

class DemoRequests extends React.Component {

    tableRef = React.createRef();

    constructor() {
        super()
        this.state = {
            demoRequestStatus: CONFIG.demoRequestStatus.assigned.value,
            requestData: [],
            count: 0,
            matchedUsersNames: [],
            showFilter: false,
            showEdit: false,
            record: null,
            currentPage: 0,
            currentPath: null,
        }
        this.filterSort = { filter: { type: "Demo Request" } };
        this.filterSave = { filter: { type: "Demo Request" } };
        this.flag = 0;
    }

    clearCurrentRequestData = async () => {
        await this.props.clearDemoRequestData();
    }

    handleFetchRequestData = async () => {
        await this.props.toggleIsFetchingDemoRequest(true);
        await this.props.fetchDemoRequests({ filterSort: this.filterSort, status: this.state.demoRequestStatus })
    }

    componentDidUpdate() {
        const { openedTabs, activeKey } = this.props;
        const formChanged = this.props.editingKey !== "" && this.props.editingKey !== undefined;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && formChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, formChanged);
        else if (hasUnsavedChanges && !formChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, formChanged);
        const dropdownLinks = document.querySelectorAll(
            '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
        );

        if (dropdownLinks?.length > 0) {
            dropdownLinks.forEach((link) => {
                link.addEventListener('click', this.handleDatesSortForNeoTable);
            });
        }
    }

    handleDatesSortForNeoTable = (event) => {
        const targetElement = event.target;
        const sortingDateChild = targetElement.querySelector('#sorting-date');
        const arrowChild = ((targetElement.classList?.contains("neo-icon--small") || (targetElement.classList?.contains("neo-icon-arrow-down")) ||
            (targetElement.classList?.contains("neo-icon-arrow-up"))) && ((targetElement?.offsetParent?.firstChild?.innerHTML?.includes("sorting-date")) || (targetElement?.offsetParent?.lastChild?.innerHTML?.includes("sorting-date"))));
        if (sortingDateChild || arrowChild) {
            setTimeout(() => {
                const myElement = document.querySelector('.neo-dropdown__content');
                if (myElement) {
                    myElement.classList.add('sorting-classes');
                    const parentItem = document.querySelector(".sorting-classes");
                    const children = parentItem.children;
                    children[0].textContent = 'Clear Sort';
                    children[1].textContent = 'Older to Newer';
                    children[2].textContent = 'Newer to Older';
                }
            }, 10)
        }
    }


    cleanup = () => {
        const dropdownLinks = document.querySelectorAll(
            '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
        );
        if (dropdownLinks?.length > 0) {
            dropdownLinks.forEach((link) => {
                link.addEventListener('click', this.handleDatesSortForNeoTable);
            });
        }
    };


    async componentDidMount() {
        const { isEdit, filterSort, status, currentPageNumber } = this.props;
        this.setState({ currentPath: window.location.pathname })
        batch(async () => {
            const filterSort = {
                filter: { demoType: CONFIG.demoTypes.standardDemo }
            }
            await this.props.getAllDemosLite(filterSort);
        });
        if (isEdit) {
            this.filterSort = filterSort;
            await this.setState({ demoRequestStatus: status, currentPage: currentPageNumber || 0 })
            if (Object.keys(JSON.parse(JSON.stringify(filterSort)))?.length > 0) {
                const filterObject = JSON.parse(JSON.stringify(filterSort?.filter));
                Object.keys(filterObject)?.length > 1 && this.setState({ showFilter: true })
            }
        }
        else {
            this.filterSort = { filter: { type: "Demo Request" } };
        }
        await this.fetchstatusOnMount();
    }

    async componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentDemoRequestFilter(this.filterSort, this.state.demoRequestStatus);
            this.props.updateDemoRequestCurrentPage(this.state.currentPage);
        }
        else this.props.clearDemoRequestData();
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        this.cleanup();
    }

    fetchstatusOnMount = async () => {
        this.props.toggleIsFetchingDemoRequest(true);
        await this.props.fetchDemoRequests({
            filterSort: this.filterSort,
            status: this.state.demoRequestStatus
        })
    }

    handleRequestStatusChange = async (type) => {
        await this.clearCurrentRequestData()
        this.setState({ demoRequestStatus: type }, async () => await this.fetchstatusOnMount())
    }

    handleClear = async (isRefresh) => {
        await this.clearCurrentRequestData()
        this.props.updateDemoRequestEditingKey("");
        if (!isRefresh) {
            this.filterSort = { ...this.filterSort, filter: { "type": "Demo Request" } };
            this.filterSave = {}
        }
        this.setState({ matchedUsersNames: [] })
        this.handleFetchRequestData()
    }

    fetchUsersName = searchString => {
        this.setState({ matchedUsersNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    this.setState({ matchedUsersNames: [...new Set(result.map(item => item.fullName))] })
                }
                this.setState({ hasEnteredString: false })
            });
    };

    handleSave = (record, form) => {
        form.validateFields(async (error, values) => {
            if (error) {
                return;
            }
            else {
                let data = {
                    ...record,
                    assignedUser: JSON.parse(values.assignedUser),
                }
                const isUpdated = await this.props.putAssignedUserDataCenter(data);
                if (isUpdated) {
                    this.handleClear(true)
                }
                this.setState({ showEdit: false, record: null })
            }
        });
    }

    handeEditRecord = async (record) => {
        this.props.toggleIsFetchingDemoRequest(true)
        await this.props.fetchUsersForDemoDataCenter(record).then(() => this.props.toggleIsFetchingDemoRequest(false))
        this.setState({
            showEdit: true,
            record
        })
    }

    getUTCTime = (time) => {
        const { timeZoneName } = this.props;
        return time.tz(timeZoneName, true).toISOString() || undefined
    }

    handleSaveFilters = (filters) => {
        this.handleFilter(filters, true)
    }

    handleFilter = async (filters, isSave) => {
        const { timeZoneName } = this.props;
        let { demo, resolvedBy, updatedBy, assignedUser, updatedTime, resolvedTime, viewDeletedItems } = filters;
        assignedUser = assignedUser?.trim();
        updatedBy = updatedBy?.trim();
        resolvedBy = resolvedBy?.trim();
        const isFilter = isSave ? "save" : "filter";
        const isDemoEmpty = !demo || (demo && demo.length === 0);
        const isresolvedTimeEmpty = !resolvedTime || (resolvedTime && resolvedTime.length === 0);
        const isupdatedTimeEmpty = !updatedTime || (updatedTime && updatedTime.length === 0);
        if (!assignedUser && !resolvedBy && !updatedBy && isresolvedTimeEmpty && isupdatedTimeEmpty && isDemoEmpty && !viewDeletedItems) {
            if (Object.keys(this.filterSort.filter)?.length <= 1) pushMessage(CONFIG.messageType.warning, `Please select some fields to ${isFilter}`)
            return;
        }
        if (viewDeletedItems) this.setState({ demoRequestStatus: "pending,approved,declined" })
        if (isSave) {
            this.filterSave = {
                ...this.filterSave,
                filter: {
                    ...(!isDemoEmpty && {
                        [dataIndexMap.demo]: {
                            operator: "=",
                            oprVal: demo.join(CONFIG.delimiters.saveFilter) || undefined
                        }
                    }),
                    ...(resolvedBy && {
                        [dataIndexMap.resolvedBy]: {
                            operator: "=",
                            oprVal: resolvedBy || undefined
                        }
                    }),
                    ...(assignedUser && {
                        [dataIndexMap.assigned]: {
                            operator: "=",
                            oprVal: assignedUser || undefined
                        }
                    }),
                    ...(updatedBy && {
                        [dataIndexMap.updatedBy]: {
                            operator: "=",
                            oprVal: updatedBy || undefined
                        }
                    }),
                    ...(!isupdatedTimeEmpty && {
                        updatedTime: {
                            operator: "IN",
                            oprVal: !isupdatedTimeEmpty ? updatedTime[0].tz(timeZoneName, true).startOf('day').toISOString() + '@' + updatedTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
                        }
                    }),
                    ...(!isresolvedTimeEmpty && {
                        resolvedTime: {
                            operator: "IN",
                            oprVal: !isresolvedTimeEmpty ? resolvedTime[0].tz(timeZoneName, true).startOf('day').toISOString() + '@' + resolvedTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
                        }
                    }),
                    ...(viewDeletedItems && {
                        isSoftDelete: {
                            operator: "=",
                            oprVal: viewDeletedItems
                        }
                    })
                },
                filterTable: {
                    id: 14,
                    tableName: "Demo Request"
                },
            }
            this.props.toggleSaveReportVisible(true)
            this.setState({ saveReport: true })
        }
        else {
            await this.clearCurrentRequestData();
            this.filterSort = {
                filter: {
                    [dataIndexMap.demo]: !isDemoEmpty ? demo.join(CONFIG.delimiters.selectFilter) : undefined,
                    [dataIndexMap.startUpdatedTime]: !isupdatedTimeEmpty ? this.getUTCTime(updatedTime[0]) : undefined,
                    [dataIndexMap.endUpdatedTime]: !isupdatedTimeEmpty ? this.getUTCTime(updatedTime[1]) : undefined,
                    [dataIndexMap.startResolvedTime]: !isresolvedTimeEmpty ? this.getUTCTime(resolvedTime[0]) : undefined,
                    [dataIndexMap.endResolvedTime]: !isresolvedTimeEmpty ? this.getUTCTime(resolvedTime[1]) : undefined,
                    [dataIndexMap.updatedBy]: updatedBy || undefined,
                    [dataIndexMap.resolvedBy]: resolvedBy || undefined,
                    [dataIndexMap.assigned]: assignedUser || undefined,
                    [dataIndexMap.viewDeletedItems]: viewDeletedItems,
                    type: "Demo Request"
                }
            }
            this.setState({ matchedUsersNames: [] })
            this.handleFetchRequestData(true);
        }
    }

    handleExport = async (fileType) => {
        const { timeZoneName } = this.props;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const demos = await this.props.getAllDemosRequests({ filterSort: this.filterSort, status: this.state.demoRequestStatus });
        if (demos?.length > 0 && demos !== undefined) {
            exportTable(CONFIG.exportTable.demoRequest?.name, demos, timeZoneName, CONFIG.exportTable.demoRequest.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    handleChangeSortName = () => {
        setTimeout(() => {
            const myElement = document.querySelector('.neo-dropdown__content');
            if (myElement) {
                myElement.classList.add('sorting-classes');
                const parentItem = document.querySelector(".sorting-classes");
                const children = parentItem.children;
                children[0].textContent = 'Clear Sort';
                children[1].textContent = 'Older to Newer';
                children[2].textContent = 'Newer to Older';
            }
        }, 10)
    }

    renderTable = () => {
        const { timeZoneName, demoRequests, isFetchingDemoRequests } = this.props;
        const { demoRequestStatus } = this.state;
        const isUnresolved = ((demoRequestStatus === CONFIG.demoRequestStatus.pending.value) || (demoRequestStatus === CONFIG.demoRequestStatus.assigned.value)) ? true : false;
        const isApprovedTable = demoRequestStatus === (CONFIG.demoRequestStatus.approved.value);
        const isDeclinedTable = demoRequestStatus === (CONFIG.demoRequestStatus.declined.value);
        const deleteView = this.filterSort?.filter?.viewDeletedItems;

        const isAllTable = demoRequestStatus === (CONFIG.demoRequestStatus.all.value);
        const titleColumns = [
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    return <span onClick={() => {
                        this.props.handleOnClickAction({
                            component: CONFIG.editComponentRoute.demoRequests,
                            tabTitle: record?.demo?.title,
                            recordId: record?.id
                        })
                    }} style={{ textAlign: "center", color: "#1B77AF", cursor: "pointer" }}>{a?.cell?.row?.original?.demo?.title}</span>
                },
                Header: "Demo Title",
                sortType: "string",
                accessor: "demo.title",
                disableFilters: true,
            },
        ];

        const neoColumns = [
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.updatedBy?.fullName}</span>
                },
                Header: "Requested By",
                sortType: "alphanumeric",
                accessor: "updatedBy.fullName",
                disableFilters: true,
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.updatedTime ? moment.tz(a?.cell?.row?.original?.updatedTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"}</span>
                },
                Header: <span onClick={this.handleChangeSortName} id='sorting-date'>Requested Date</span>,
                accessor: "updatedTime",
                disableFilters: true,
                sortType: (rowA, rowB) => {
                    const startTimeA = moment.tz(rowA?.original?.updatedTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    const startTimeB = moment.tz(rowB?.original?.updatedTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    if (startTimeA.isBefore(startTimeB)) {
                        return -1;
                    }
                    if (startTimeA.isAfter(startTimeB)) {
                        return 1;
                    }
                    return 0;
                }
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.assignedUser?.fullName}</span>
                },
                Header: "Assigned User",
                sortType: "alphanumeric",
                disableFilters: true,
                accessor: "assignedUser.fullName",
            },
        ];

        const resolvedColumns = [
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.resolvedBy?.fullName}</span>
                },
                Header: isDeclinedTable ? "Declined By" : isApprovedTable ? "Approved By" : "Approved / Declined By",
                sortType: "alphanumeric",
                disableFilters: true,
                accessor: "resolvedBy.fullName",
            },
        ];

        const actions = [
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    const showEdit = record?.statusMgmtStatus === "pending";
                    return (
                        <React.Fragment>
                            <div style={{ display: "flex", gap: "24px" }}>
                                {showEdit && <div>
                                    <Tooltip title="Assign User"><NeoIcon aria-label="menu-aria" size="md" style={{ cursor: "pointer", color: "#1B77AF" }} icon="user-return"
                                        onClick={() => this.handeEditRecord(record, false)}
                                    /></Tooltip>
                                </div>}
                                <div>
                                    <Tooltip title="View Details"><NeoIcon aria-label="menu-aria" size="md" style={{ cursor: "pointer", color: "#1B77AF" }} icon="list-bullet"
                                        onClick={() => {
                                            this.props.handleOnClickAction({
                                                component: CONFIG.editComponentRoute.demoRequests,
                                                tabTitle: record?.demo?.title,
                                                recordId: record?.id
                                            })
                                        }}
                                    /></Tooltip>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                },
                Header: "  ",
                disableFilters: true,
                disableSortBy: true
            }
        ];

        return (
            isFetchingDemoRequests ? null :
                <NeoTable
                    columns={!deleteView ? isUnresolved ? [...titleColumns, ...neoColumns, ...actions] : isAllTable ? [...titleColumns, ...neoColumns, ...resolvedColumns, ...actions] : (isApprovedTable || isDeclinedTable) ? [...titleColumns, ...neoColumns, ...resolvedColumns, ...actions] : [...titleColumns, ...neoColumns, ...actions] : [...titleColumns, ...neoColumns]}
                    data={demoRequests || []}
                    className="table-actions"
                    allowColumnFilter={false}
                    itemsPerPageOptions={[10, 20, 50, 100]}
                    showRowSeparator
                    initialStatePageIndex={this.state.currentPage}
                    handlePageChange={(newPageIndex, newPageSize) => {
                        if (newPageIndex >= 0) this.setState({ currentPage: newPageIndex })
                    }}
                    {...otherFields}
                />
        )
    }


    renderFilterPanel = () => {
        return (
            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                <div style={{ display: "flex", width: "100%", flex: "1", flexDirection: "row-reverse", gap: "24px" }}>
                    <div >
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoButton className="export-download-button" icon="chevron-down" variant="secondary">Download</NeoButton>
                        </Dropdown>
                    </div>
                    <div >
                        <NeoIcon aria-label="menu-aria" size="md" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={(this.state.showFilter || (!checkIfFilterSortIsEmpty(this.filterSort, ["type"]))) ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                    <div >
                        <NeoIcon aria-label="menu-aria" size="md" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                </div>
            </div>
        )
    }

    renderTabContents = () => {
        return (
            <React.Fragment>
                <div>
                    {this.renderFilterPanel()}
                </div>
                <div className="height-neo-table">
                    {this.renderTable()}
                </div>
            </React.Fragment>
        )
    }

    renderUsers = () => {
        const users = this.props.assignedUserDropdown;
        if (users && users?.length > 0) {
            return users?.map(user => {
                return (
                    <SelectOption key={user.id} value={JSON.stringify({ id: user?.id, fullName: user?.fullName })}>
                        {user.fullName}
                    </SelectOption>
                );
            });
        }
        return;
    };

    render() {
        const { demos, isFetchingDemoRequests } = this.props;
        const { getFieldDecorator } = this.props.form;
        const formFields = [
            {
                label: "Demo Title",
                type: CONFIG.formFieldTypes.SELECT,
                key: "demo",
                mode: "tags",
                valueKey: "text",
                data: (demos) ? demos.map(demo => ({ text: demo?.title })) : [],
                colSpan: 24,
                value: this.filterSort?.filter?.[dataIndexMap.demo]
            },
            {
                label: "Requested By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "updatedBy",
                data: this.state.matchedUsersNames,
                colSpan: 24,
                value: this.filterSort?.filter?.[dataIndexMap.updatedBy]
            },

            {
                label: "Assigned User",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "assignedUser",
                data: this.state.matchedUsersNames,
                colSpan: 24,
                value: this.filterSort?.filter?.[dataIndexMap.assigned]
            },
            {
                label: "Approved / Declined By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "resolvedBy",
                data: this.state.matchedUsersNames,
                colSpan: 24,
                value: this.filterSort?.filter?.[dataIndexMap.resolvedBy]
            },
            {
                label: "Requested Date",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "updatedTime",
                value: [this.filterSort.filter?.startUpdatedTime, this.filterSort.filter.endUpdatedTime],
                colSpan: 24
            },
            {
                label: "Approved / Declined Date",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "resolvedTime",
                value: [this.filterSort.filter?.startResolvedTime, this.filterSort.filter.endResolvedTime],
                colSpan: 24,
            },
        ];
        const drawerContainer = document.getElementById('#component-content');
        return (
            <React.Fragment>
                <div style={{ color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px", marginTop: "28px" }}>
                    Demo Requests
                </div>
                <Spin spinning={isFetchingDemoRequests} indicator={antIcon}>
                    <div style={{ marginTop: "16px" }}>
                        <Tabs activeKey={this.state.demoRequestStatus} className='demo-status-tabs' defaultActiveKey={this.state.demoRequestStatus} onChange={this.handleRequestStatusChange}>
                            <TabPane tab="Assigned" key={CONFIG.demoRequestStatus.assigned.value}>
                                {this.renderTabContents()}
                            </TabPane>
                            <TabPane tab="Pending" key={CONFIG.demoRequestStatus.pending.value}>
                                {this.renderTabContents()}
                            </TabPane>
                            <TabPane tab="Approved" key={CONFIG.demoRequestStatus.approved.value}>
                                {this.renderTabContents()}
                            </TabPane>
                            <TabPane tab="Declined" key={CONFIG.demoRequestStatus.declined.value}>
                                {this.renderTabContents()}
                            </TabPane>
                            <TabPane tab="All" key={CONFIG.demoRequestStatus.all.value}>
                                {this.renderTabContents()}
                            </TabPane>
                        </Tabs>
                    </div>
                </Spin>
                <div >
                    {this.state.showFilter && (
                        <FilterDrawer
                            visible={this.state.showFilter}
                            width='520'
                            handleCloseFilterDrawer={() => this.setState(prevState => ({
                                showFilter: false,
                                record: null
                            }))}
                            placement="right"
                            drawerContainer={drawerContainer}
                            style={{ zIndex: 9999, }}
                            maskClosable={false}
                            panelHeader="Filters and Export"
                            formFields={formFields}
                            handleClear={this.handleClear}
                            timeZone={this.props.timeZoneName}
                            hideSoftDeleteCheckbox={true}
                            hideSave={false}
                            handleSave={this.handleSaveFilters}
                            handleFilter={this.handleFilter}
                            getSearchData={this.fetchUsersName}
                            label="View Deleted Demo Requests"
                            hideApplyToAll={true}
                            viewDeletedItemsValue={this.filterSort?.filter?.viewDeletedItems}
                        />)}
                    <Row>
                        {
                            (this.state.showEdit) &&
                            <BasicModal
                                open={(this.state.showEdit)}
                                title="Assign the User"
                                className="neo-modal conflicting-modal min-height-modal"
                                onClose={() => this.setState({ showEdit: false, record: null })}
                                actions={[
                                    <NeoButton onClick={() => this.handleSave(this.state.record, this.props.form)} variant="primary">Save</NeoButton>
                                ]}
                            >
                                <div>
                                    <Form>
                                        <NeoForm>
                                            <div style={{ marginTop: "16px" }}>
                                                <TextInput label="Requested Demo" value={(this.state.record?.demo?.title || "-")} readOnly />
                                            </div>
                                            <div style={{ marginTop: "16px", display: "flex" }}>
                                                <div style={{ display: "flex", flex: "1" }}>
                                                    <TextInput label="Currently Assigned user" value={(this.state.record?.assignedUser?.fullName || "-")} readOnly />
                                                </div>
                                                <div style={{ display: "flex", flex: "1" }}>
                                                    <Form.Item style={{ width: "100%" }}>
                                                        {
                                                            getFieldDecorator("assignedUser", {
                                                                rules: [
                                                                    {
                                                                        type: 'string',
                                                                        required: true,
                                                                        message: `Please select a user!`
                                                                    }
                                                                ],
                                                                initialValue: JSON.stringify({ id: this.state.record?.assignedUser?.id, fullName: this.state.record?.assignedUser?.fullName })
                                                            })(<Select
                                                                // searchable={true}
                                                                required className="remove-padding-style" label="Assigned User">{this.renderUsers()}</Select>)
                                                        }
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "16px", display: "flex" }}>
                                                <div style={{ display: "flex", flex: "1" }}>
                                                    <TextInput label="Requested By" value={(this.state.record?.updatedBy?.fullName || "-")} readOnly />
                                                </div>
                                                <div style={{ display: "flex", flex: "1" }}>
                                                    <TextInput label="Requested Date" value={(this.state.record?.updatedTime ? moment.tz(this.state.record?.updatedTime, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-")} readOnly />
                                                </div>
                                            </div>
                                        </NeoForm>
                                    </Form>
                                </div>
                            </BasicModal>
                        }
                        {this.state.saveReport && <SaveReport closeModal={() => this.setState({ saveReport: false })} reportFilter={this.filterSave} />}
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ user, demos, demoRequest, tabsLayout, dataCenterStatus }) => {
    return {
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        editingKey: demoRequest.editingKey,
        isFetchingUser: demoRequest.isFetchingUser,
        isFetchingDemoRequests: demoRequest.isFetchingDemoRequests,
        demoRequests: demoRequest.demoRequests,
        demoRequestCount: demoRequest.demoRequestCount,
        timeZoneName: user.profile?.timezone,
        demos: demos.allDemos,
        assignedUserDropdown: dataCenterStatus.users,
        filterSort: demoRequest.filters,
        status: demoRequest.status,
        isEdit: demoRequest.isEdit,
        currentPage: demoRequest.currentPage,
        currentPageNumber: demoRequest.currentPageNumber,
        pageSize: demoRequest.pageSize,
        requestIndex: demoRequest.requestIndex,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey
    };
};

const EditableFormDemoRequestsTable = Form.create()(DemoRequests);

export default connect(
    mapStateToProps,
    {
        selectTitle,
        toggleBackButton,
        getAllDemosLite,
        updateDemoRequestEditingKey,
        fetchDemoRequests,
        fetchUsersForDemoDataCenter,
        putAssignedUserDataCenter,
        getAllDemosRequests,
        clearDemoRequestData,
        toggleIsFetchingDemoRequest,
        toggleIsFetchingDemoRequestUsers,
        handleOnClickAction,
        currentDemoRequestFilter,
        updateDemoRequestCurrentPage,
        toggleHasUnsavedChangesFlag,
        toggleSaveReportVisible
    }
)(EditableFormDemoRequestsTable);
import React from "react";
import {
  Form,
  Row,
  message,
  Dropdown,
  Tooltip,
  notification,
  Spin,
  Tabs,
} from "antd";
import { fetchNamesBySearchString } from "../../actions/userRole";
import {
  fetchAllUsers,
  fetchLocalUser,
  fetchRoleTemplates,
  postLocalUser,
  refreshLocalUsers,
  toggleBackButton,
  selectTitle,
  clearLocalUsers,
  toggleIsFetchingLocalUsersFlag,
  resetLDAPPassword,
  exportTable,
  getAllUsers,
  currentLocalUsersFilter,
  handleOnClickAction,
  toggleHasLocalUsersUpdated,
  toggleSaveReportVisible,
  getAllLiteUsers,
} from "../../actions";
import { connect, batch } from "react-redux";
import CONFIG from "../../config.js";
import pushMessage from "../common/PushMessage";
import moment from "moment-timezone";
import { isTabAlreadyOpen } from "../common/TabLayout";
import { exportMenu } from "../../utils/strings";
import SaveReport from "../common/SaveReport";
import throttle from "lodash/throttle";
import { checkIfFilterSortIsEmpty } from "../../utils/formChanged.js";
import { LoadingOutlined } from "@ant-design/icons";
import FilterDrawer from "../common/FilterDrawer";
import {
  Table as NeoTable,
  Icon as NeoIcon,
  Button as NeoButton,
} from "neo-latest";
import { otherFields } from "../../utils/neoTableFields";
export const ManageLocalUserContext = React.createContext();
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;

const dataIndexMap = {
  name: "name",
  firstName: "firstName",
  fullName: "fullName",
  lastName: "lastName",
  email: "email",
  roleTemplate: "userRoleTemplates.roleTemplate.title",
  isActive: "isActive",
  sslHandle: "sslHandle",
  username: "userName",
  emailNotificationSubscription: "emailNotificationSubscription",
  optOutInviteSubs: "optOutInviteSubs",
};

class ManageLocalUsers extends React.Component {
  constructor(props) {
    super(props);
    this.handleClear = throttle(this.handleClear, 1000);
  }

  state = {
    matchedUsersNames: [],
    searchString: [],
    hasEnteredString: false,
    status: "true",
    localUsersData: [],
    loading: false,
    clickedRecord: undefined,
    showFilter: false,
    objectNonSSOUsersCount: undefined,
    currentPage: 0,
    currentPath: null
  };

  flag = 0;
  filterSort = {};
  filterSave = {};
  tableRef = React.createRef();
  refreshCount = 0;

  clearCurrentLocalUsers = async () => {
    await this.setState({ localUsersData: [], currentPage: 0 });
  };

  handleSelectChange = async (value) => {
    await this.clearCurrentLocalUsers();
    this.setState({ status: value }, () => {
      this.props.toggleIsFetchingLocalUsersFlag(true);
      setTimeout(() => {
        this.handleFetchLocalUsers();
      })
    });
  };

  handleFilter = async (filters, isSave) => {
    let { name, email, roleTemplate, sslHandle } = filters;
    email = email?.trim();
    sslHandle = sslHandle?.trim();
    const isRTEmpty =
      !roleTemplate || (roleTemplate && roleTemplate.length === 0);
    const isFilter = isSave ? "save" : "filter";

    if (!name && !email && !sslHandle && isRTEmpty) {
      pushMessage(
        CONFIG.messageType.warning,
        `Please select some fields to ${isFilter}`
      );
      return;
    } else {
      await this.clearCurrentLocalUsers();
      this.filterSort = {
        ...this.filterSort,
        filter: {
          [dataIndexMap.fullName]: name || undefined,
          [dataIndexMap.sslHandle]: sslHandle || undefined,
          [dataIndexMap.email]: email || undefined,
          [dataIndexMap.roleTemplate]: isRTEmpty
            ? undefined
            : roleTemplate.join(CONFIG.delimiters.selectFilter),
          roleTemplate: isRTEmpty
            ? undefined
            : roleTemplate.join(CONFIG.delimiters.selectFilter),
        },
      };
      this.props.clearLocalUsers();
      this.props.currentLocalUsersFilter(this.filterSort, 1, 12);
      this.setState({ loading: true, localUsersData: [] });
      await this.props.getAllLiteUsers({
        filterSort: this.filterSort,
        isBackendUser: true,
        status: this.state.status,
      });
      this.setState({ loading: false, localUsersData: this.props.users || [] });
      this.setState({ editingKey: "" });
    }
  };

  getFullName = (item) => {
    var fullName = [item.firstName, item.lastName].join(" ");
    return fullName;
  };

  handleExport = async (fileType) => {
    const { timeZoneName } = this.props;
    pushMessage(CONFIG.messageType.loading, "Exporting", 0);
    const users = await getAllUsers({
      filterSort: this.filterSort,
      isBackendUser: true,
      status: this.state.status,
    });
    if (users?.length > 0 && users !== undefined) {
      exportTable(
        CONFIG.exportTable.nonSsoUsers.name,
        users,
        timeZoneName,
        CONFIG.exportTable.nonSsoUsers.fileName + `.${fileType}`,
        fileType
      )
        .then((response) => {
          message.destroy();
          const isExport = response.status === CONFIG.HTTP_STATUS.OK;
          pushMessage(
            isExport ? CONFIG.messageType.success : CONFIG.messageType.warning,
            isExport
              ? "Table exported successfully"
              : "There is no data to export. Please verify the filters"
          );
        })
        .catch(() => {
          message.destroy();
          pushMessage(CONFIG.messageType.error, "Unable to export table");
        });
    } else {
      message.destroy();
      pushMessage(
        CONFIG.messageType.warning,
        "There is no data to export. Please verify the filters"
      );
    }
  };

  fetchUsersName = (searchString) => {
    this.setState({ matchedUsersNames: [] });
    fetchNamesBySearchString({ searchString, isBackendUser: true }).then(
      (result) => {
        if (result.length !== 0) {
          delete result.id;
          this.setState({
            matchedUsersNames: [...new Set(result.map(this.getFullName))],
          });
        }
        this.setState({ hasEnteredString: false });
      }
    );
  };

  handleClear = async (refresh) => {
    if (refresh) {
      await this.props.refreshLocalUsers();
      this.handleFetchLocalUsers();
    } else {
      this.filterSort = { ...this.filterSort, filter: undefined };
      this.filterSave = {};
      this.setState({ matchedUsersNames: [] });
      await this.clearCurrentLocalUsers();
      this.props.currentLocalUsersFilter(
        this.filterSort,
        this.state.status,
        1,
        12
      );
      this.handleFetchLocalUsers();
    }
  };

  handleSaveUser = async (filters) => {
    let { name, email, roleTemplate, sslHandle } = filters;
    email = email?.trim();
    sslHandle = sslHandle?.trim();
    const isRTEmpty =
      !roleTemplate || (roleTemplate && roleTemplate.length === 0);
    if (!name && !email && isRTEmpty && !sslHandle) {
      if (!this.filterSort.filter)
        pushMessage(
          CONFIG.messageType.warning,
          `Please select some fields to save`
        );
      else this.handleClear();
      return;
    } else {
      this.filterSave = {
        ...this.filterSave,
        filter: {
          ...(name && {
            [dataIndexMap.fullName]: {
              operator: "=",
              oprVal: name || undefined,
            },
          }),
          ...(email && {
            [dataIndexMap.email]: {
              operator: "=",
              oprVal: email || undefined,
            },
          }),
          ...(!isRTEmpty && {
            [dataIndexMap.roleTemplate]: {
              operator: "=",
              oprVal: isRTEmpty
                ? undefined
                : roleTemplate.join(CONFIG.delimiters.saveFilter),
            },
          }),
          ...(sslHandle && {
            [dataIndexMap.username]: {
              operator: "=",
              oprVal: sslHandle || undefined,
            },
          }),
        },
        filterTable: {
          id: 8,
          tableName: "User",
        },
      };
      this.props.toggleSaveReportVisible(true);
      this.setState({ saveReport: true, showFilter: false });
    }
  };

  handleFetchLocalUsers = async (isFilter) => {
    this.props.toggleIsFetchingLocalUsersFlag(true);
    this.props
      .getAllLiteUsers({
        filterSort: this.filterSort,
        isBackendUser: true,
        status: this.state.status,
      })
  };

  componentWillUnmount() {
    if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
      this.props.clearLocalUsers();
      this.props.currentLocalUsersFilter(this.filterSort, this.state.status, this.state.currentPage, (this.currentPage * CONFIG.lazyLoadPageSize));
    } else {
      this.props.clearLocalUsers();
    }
    notification.destroy();
  }

  closeFilterDrawer = () => {
    this.setState({ showFilter: false });
  };

  fetchLocalUsersOnMount = async () => {
    this.setState({ loading: true });
    await this.props.getAllLiteUsers({
      filterSort: this.filterSort,
      isBackendUser: true,
      status: this.state.status,
    });
  };
  isViewDeletedItemsChecked = false;

  async componentDidMount() {
    const { currentFilter, isEdit, currentPageNumber, currentStatus } =
      this.props;
    this.setState({ currentPath: window.location.pathname });
    if (isEdit) {
      await this.setState({ currentPage: currentPageNumber });
      this.filterSort = currentFilter;
      await this.setState({ status: currentStatus });
      if (currentFilter?.filter !== undefined)
        this.setState({ showFilter: true });
    } else {
      this.filterSort = {};
    }
    this.setState({ loading: true });
    await this.props.getAllLiteUsers({
      filterSort: this.filterSort,
      isBackendUser: true,
      status: this.state.status,
    });
    this.setState({
      loading: false,
      localUsersData: this.props.localUsers || [],
    });
    batch(() => {
      this.props.toggleIsFetchingLocalUsersFlag(false);
      this.props.fetchRoleTemplates(false);
    });
    this.setState({
      objectNonSSOUsersCount: this.props.count,
    });
  }

  handleClick = (event) => {
    const targetElement = event.target;
    const sortingDateChild = targetElement.querySelector("#sorting-date");
    const arrowChild =
      (targetElement.classList?.contains("neo-icon--small") ||
        targetElement.classList?.contains("neo-icon-arrow-down") ||
        targetElement.classList?.contains("neo-icon-arrow-up")) &&
      (targetElement?.offsetParent?.firstChild?.innerHTML?.includes(
        "sorting-date"
      ) ||
        targetElement?.offsetParent?.lastChild?.innerHTML?.includes(
          "sorting-date"
        ));
    if (sortingDateChild || arrowChild) {
      setTimeout(() => {
        const myElement = document.querySelector(".neo-dropdown__content");
        if (myElement) {
          myElement.classList.add("sorting-classes");
          const parentItem = document.querySelector(".sorting-classes");
          const children = parentItem.children;
          children[0].textContent = "Clear Sort";
          children[1].textContent = "Older to Newer";
          children[2].textContent = "Newer to Older";
        }
      }, 10);
    }
  };

  getRoleTemplateNamesString = (
    roleTemplates,
    delimiter = " ",
    defaultString = ""
  ) => {
    return (
      roleTemplates
        ?.map((roleTemplate) => roleTemplate.title)
        ?.join(delimiter) || defaultString
    );
  };

  handleCloseFilterDrawer = () => {
    this.setState((prevState) => ({
      showFilter: !prevState.showFilter,
    }));
  };

  handleEditClick = (record, isReadOnly) => {
    this.setState({
      showEdit: true,
      isReadOnlyEdit: isReadOnly,
      record,
    });
  };

  handeEditUser = (record, isEdit, isEditDisabled) => {
    this.setState({
      showEdit: true,
      isReadOnlyEdit: isEdit,
      record,
      isEditDisabled,
    });
  };

  renderFilterPanel = () => {
    const { userRoleTemplate } = this.props;
    return (
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
      >
        {(userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin || userRoleTemplate.templateType === CONFIG.roleTypes.eaAdmin) &&
          <div style={{ display: "flex", flex: "1", zIndex: "1", position: "relative" }}>
            <NeoButton
              onClick={() => {
                this.props.handleOnClickAction({
                  component: CONFIG.createComponentRoute.nonSsoUsers,
                  tabTitle: "Create",
                });
              }}
            >
              Create Non-SSO User
            </NeoButton>
          </div>
        }
        <div
          style={{
            display: "flex",
            width: "100%",
            flex: "1",
            flexDirection: "row-reverse",
            gap: "24px",
          }}
        >
          <div>
            <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
              <NeoButton
                className="export-download-button"
                icon="chevron-down"
                variant="secondary"
              >
                Download
              </NeoButton>
            </Dropdown>
          </div>
          <div>
            <NeoIcon aria-label="menu-aria"
              size="md"
              className="clickable"
              onClick={() =>
                this.setState((prevState) => ({
                  showFilter: !prevState.showFilter,
                }))
              }
              title="Filter"
              icon={
                this.state.showFilter ||
                  !checkIfFilterSortIsEmpty({ filter: this.filterSort })
                  ? "filter-filled"
                  : "filter"
              }
              style={{ fontSize: "23px", color: "#0b67bd" }}
            />
          </div>
          <div>
            <NeoIcon aria-label="menu-aria"
              size="md"
              className="clickable"
              title="Refresh"
              icon="refresh"
              onClick={() => this.handleClear(true)}
              style={{ fontSize: "23px", color: "#0b67bd" }}
            />
          </div>
        </div>
      </div>
    );
  };

  renderTabContents = () => {
    return (
      <React.Fragment>
        <div>{this.renderFilterPanel()}</div>
        <div className="height-neo-table">{this.renderTable()}</div>
      </React.Fragment>
    );
  };

  renderTable = () => {
    const { userRoleTemplate, timeZoneName, localUsers, isFetching } = this.props;
    const neoColumns = [
      {
        Cell: (a) => {
          return (
            <span style={{ textAlign: "center" }}>
              {a?.cell?.row?.original?.fullName}
            </span>
          );
        },
        Header: "Name",
        sortType: "string",
        accessor: "fullName",
        disableFilters: true,
      },
      {
        Cell: (a) => {
          return (
            <span style={{ textAlign: "center" }}>
              {a?.cell?.row?.original?.email}
            </span>
          );
        },
        Header: "Email",
        disableFilters: true,
        accessor: "email",
        sortType: (rowA, rowB) => {
          const emailA = rowA?.original?.email?.toLowerCase() || "";
          const emailB = rowB?.original?.email?.toLowerCase() || "";
          return emailA.localeCompare(emailB);
        },
      },
      {
        Cell: (a) => {
          const roleTemplateNames = this.getRoleTemplateNamesString(
            a?.cell?.row?.original?.roleTemplates,
            ", "
          );
          return (
            <span style={{ textAlign: "center" }}>{roleTemplateNames}</span>
          );
        },
        Header: "Role Templates",
        accessor: "roleTemplates",
        disableFilters: true,
        sortType: (rowA, rowB) => {
          const roleTemplateNamesA = this.getRoleTemplateNamesString(
            rowA?.original?.roleTemplates,
            ", "
          )?.toLowerCase();
          const roleTemplateNamesB = this.getRoleTemplateNamesString(
            rowB?.original?.roleTemplates,
            ", "
          )?.toLowerCase();
          if (roleTemplateNamesA < roleTemplateNamesB) {
            return -1;
          }
          if (roleTemplateNamesA > roleTemplateNamesB) {
            return 1;
          }
          return 0;
        },
      },
      {
        Cell: (a) => {
          return (
            <span style={{ textAlign: "center" }}>
              {
                <NeoIcon aria-label="menu-aria"
                  style={
                    a?.cell?.row?.original?.isActive
                      ? { color: "#088A08" }
                      : { color: "#DA291C" }
                  }
                  icon={
                    a?.cell?.row?.original?.isActive ? "available" : "missed"
                  }
                />
              }
            </span>
          );
        },
        sortType: "basic",
        Header: "Status",
        accessor: "crmUserStatus",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Cell: (a) => {
          return (
            <span style={{ textAlign: "center" }}>
              {" "}
              {a?.cell?.row?.original?.lastLoginTime
                ? moment
                  .tz(a?.cell?.row?.original?.lastLoginTime, timeZoneName)
                  .format(CONFIG.dateFormats.userDateTime)
                : "-"}
            </span>
          );
        },
        Header: (
          <span onClick={this.handleChangeSortName} id="sorting-date">
            Last Login Time
          </span>
        ),
        accessor: "lastLoginTime",
        disableFilters: true,
        sortType: (rowA, rowB) => {
          const startTimeA = moment.tz(
            rowA?.original?.lastLoginTime,
            "YYYY-MM-DDTHH:mm:ss.SSSZ",
            timeZoneName
          );
          const startTimeB = moment.tz(
            rowB?.original?.lastLoginTime,
            "YYYY-MM-DDTHH:mm:ss.SSSZ",
            timeZoneName
          );
          if (startTimeA.isBefore(startTimeB)) {
            return -1;
          }
          if (startTimeA.isAfter(startTimeB)) {
            return 1;
          }
          return 0;
        },
      },
      {
        Cell: (a) => {
          const record = a.cell.row.original;
          const isEditDisabled =
            userRoleTemplate.templateType !== CONFIG.roleTypes.superAdmin;
          return isEditDisabled ? null : (
            <React.Fragment>
              <div
                style={{ display: "flex", alignItems: "center", gap: "24px" }}
              >
                <Tooltip title="Edit">
                  <NeoIcon aria-label="menu-aria"
                    size="md"
                    style={{ cursor: "pointer", color: "#1B77AF", zIndex: "1" }}
                    icon="edit"
                    onClick={() => {
                      this.props.handleOnClickAction({
                        component: CONFIG.editComponentRoute.nonSsoUsers,
                        tabTitle: record?.fullName,
                        recordId: record?.id,
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip
                  title={
                    userRoleTemplate.templateType !==
                      CONFIG.roleTypes.superAdmin
                      ? CONFIG.warningMessages.noAccess
                      : undefined
                  }
                  key={record.id}
                >
                  <button
                    disabled={
                      userRoleTemplate.templateType !==
                      CONFIG.roleTypes.superAdmin
                    }
                    onClick={() => this.props.resetLDAPPassword(record.id)}
                    className={!isEditDisabled ? "link" : "link-disabled"}
                  >
                    <span
                      class="neo-icon-change-password"
                      title="Reset Demo Password"
                      style={{ fontSize: "20px" }}
                    ></span>
                  </button>
                </Tooltip>
              </div>
            </React.Fragment>
          );
        },
        Header: "  ",
        disableFilters: true,
        disableSortBy: true,
      },
    ];
    return ((this.state.loading || isFetching) ?
      <Spin
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        spinning={(this.state.loading || isFetching)}
        indicator={antIcon}
      />
      :
      <NeoTable
        columns={neoColumns}
        data={localUsers || []}
        className="table-actions"
        allowColumnFilter={false}
        itemsPerPageOptions={[10, 20, 50, 100]}
        showRowSeparator
        initialStatePageIndex={this.state.currentPage}
        handlePageChange={(newPageIndex) => {
          if (newPageIndex >= 0) this.setState({ currentPage: newPageIndex })
        }}
        {...otherFields}
      />
    );
  };

  render() {
    const formFields = [
      {
        label: "Name",
        type: CONFIG.formFieldTypes.SEARCH_SELECT,
        key: "name",
        colSpan: 24,
        data: this.state.matchedUsersNames,
        value: this.filterSort?.filter?.fullName,
      },
      {
        label: "Handle",
        type: CONFIG.formFieldTypes.INPUT,
        key: "sslHandle",
        colSpan: 24,
        value: this.filterSort?.filter?.sslHandle,
      },
      {
        label: "Email",
        type: CONFIG.formFieldTypes.INPUT,
        key: "email",
        colSpan: 24,
        value: this.filterSort?.filter?.email,
      },
      {
        label: "Role Template",
        colSpan: 24,
        type: CONFIG.formFieldTypes.SELECT,
        key: "roleTemplate",
        value: this.filterSort?.filter?.roleTemplate,
        data: this.props.roleTemplates?.map((rT) => ({
          text: rT.title,
          value: rT.title,
        })),
      },
    ];
    const { userRoleTemplate, isFetching } = this.props;
    const shouldFetchDemos =
      userRoleTemplate &&
      (userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin ||
        userRoleTemplate.templateType === CONFIG.roleTypes.admin);
    return (
      <React.Fragment>
        <div
          style={{
            color: "#242424",
            fontSize: "19px",
            fontWeight: "400",
            lineHeight: "28px",
            marginTop: "28px",
          }}
        >
          Manage Non-SSO Users
        </div>
        <Spin spinning={isFetching} indicator={antIcon}>
          <div style={{ marginTop: "16px" }}>
            <Tabs
              disabled={this.isViewDeletedItemsChecked}
              activeKey={this.state.status}
              className="demo-status-tabs"
              defaultActiveKey={this.state.status}
              onChange={this.handleSelectChange}
            >
              <TabPane
                disabled={this.isViewDeletedItemsChecked}
                tab="Active"
                key="true"
              >
                {this.renderTabContents()}
              </TabPane>
              <TabPane tab="Inactive" key="false">
                {this.renderTabContents()}
              </TabPane>
              <TabPane
                disabled={this.isViewDeletedItemsChecked}
                tab="All"
                key="all"
              >
                {this.renderTabContents()}
              </TabPane>
            </Tabs>
          </div>
          <div id="component-content" className="approvals-pagination-row">
            <div></div>
            <Row>
              {this.state.showFilter && (
                <FilterDrawer
                  visible={this.state.showFilter}
                  width="520"
                  handleCloseFilterDrawer={this.handleCloseFilterDrawer}
                  placement="right"
                  maskClosable={true}
                  panelHeader="Filters and Export"
                  formFields={formFields}
                  handleClear={this.handleClear}
                  handleFilter={this.handleFilter}
                  handleSave={
                    shouldFetchDemos ? this.handleSaveUser : undefined
                  }
                  timeZone={this.props.timeZoneName}
                  hideSoftDeleteCheckbox={true}
                  hideSave={false}
                  getSearchData={this.fetchUsersName}
                />
              )}
            </Row>
          </div>
        </Spin>

        <br />
        {this.state.saveReport && (
          <SaveReport
            closeModal={() => this.setState({ saveReport: false })}
            reportFilter={this.filterSave}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ roleTemplates, localUsers, user, tabsLayout }) => {
  return {
    userProfile: user.profile,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
    localUsers: localUsers.data,
    localUsersCount: localUsers.count,
    currentPage: localUsers.currentPage,
    isFetching: localUsers.isFetching,
    hasUpdated: localUsers.hasUpdated,
    roleTemplates: roleTemplates.all,
    timeZoneName: user.profile?.timezone,
    currentPageNumber: localUsers.currentPageNumber,
    currentFilter: localUsers.currentFilter,
    currentStatus: localUsers.status,
    isEdit: localUsers.isEdit,
    pageSize: localUsers.pageSize,
    localUserIndex: localUsers.localUserIndex,
    openedTabs: tabsLayout.panes,
  };
};

const EditableFormTable = Form.create()(ManageLocalUsers);

export default connect(mapStateToProps, {
  fetchAllUsers,
  fetchLocalUser,
  fetchRoleTemplates,
  postLocalUser,
  toggleBackButton,
  refreshLocalUsers,
  selectTitle,
  clearLocalUsers,
  toggleIsFetchingLocalUsersFlag,
  resetLDAPPassword,
  exportTable,
  getAllUsers,
  currentLocalUsersFilter,
  handleOnClickAction,
  toggleSaveReportVisible,
  toggleHasLocalUsersUpdated,
  getAllLiteUsers,
})(EditableFormTable);

import React from 'react';
import { Select, Button, Icon, Row, Col, Popconfirm, Table, Form, Tooltip, Divider, Input, Switch, Modal, Typography } from 'antd';
import { connect } from 'react-redux';

import {
    deleteDemoResource,
    addNewDemoResource,
    editDemoResource,
    syncUserGroups,
    updateSchedulerStartEndTime,
    fetchSchedulesOverDuration,
    updateCurrentDemoSchedule,
    fetchActiveDemoResources,
    openLinkInNewTab
} from '../../actions';
import { checkDemoResourceDependencies, checkDemoResourceInactiveDependencies } from '../../actions/demos'
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config';
import DemoResourcesTableCell from './DemoResourcesTableCell';
import openConfirm from '../common/Confirm';
import moment from 'moment-timezone';
const { Text
} = Typography;


const { Option } = Select;

export const ManageDemoResourceContext = React.createContext();

class DemoResources extends React.Component {

    state = {
        editingKey: ""
    };
    resourceId = null;

    columns = [
        {
            title: 'Name',
            dataIndex: 'resource',
            key: 'name',
            align: 'left',
            width: 250,
            render: resource => {
                return (
                    <div>
                        {resource.name}
                        {!(resource?.active || resource?.isActive) ?
                            < Tooltip
                                title="This resource is currently inactive">
                                &nbsp;< Icon type="warning" theme="twoTone" twoToneColor="#ff9000" />
                            </Tooltip>
                            : null}
                    </div>
                )
            }
        },
        {
            title: 'Data Center',
            key: 'dataCenter',
            align: 'center',
            width: 150,
            dataIndex: "resource",
            render: resource => {
                return (
                    <span >
                        {resource.dataCenter.title}
                        &nbsp;&nbsp;&nbsp;
                        {!resource.dataCenter.active &&
                            <Tooltip title="Inactive">
                                <Icon aria-label="aria-menu" type="warning" className="small-text warning-text" />
                            </Tooltip>
                        }
                    </span>
                )
            },
        },
        {
            title: 'User Groups',
            key: "ldapGroup",
            width: 250,
            align: "center",
            dataIndex: "ldapGroup",
            render: ldapGroup => {
                return (
                    <span >
                        {ldapGroup ? ldapGroup?.name : <Text italic >None</Text>}
                    </span>
                )
            },
        },
        {
            title: <Tooltip title={CONFIG.systemMessage.dcDescription} >Description  <Icon aria-label="aria-menu" className="text-color-primary" type="info-circle" width={1} /></Tooltip>,
            dataIndex: 'description',
            key: 'description',
            align: 'left',
            editable: true,
            width: 300,
            render: (description, record) => {
                return <Input disabled={!this.isEditing(record)} value={description} />
            }
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            editable: true,
            align: 'center',
            width: 125,
            render: (isActive, record) => {
                return (
                    <Switch
                        style={{ width: '40px' }}
                        disabled={!this.isEditing(record)}
                        size="small"
                        checked={isActive}
                    />)
            }
        },
        {
            title: 'Action',
            key: 'action',
            width: 150,
            render: (option, record) => {
                const { userRoleTemplate, allResources } = this.props;
                const editable = this.isEditing(record);
                const hasFullAccess = allResources && allResources.find(resource => resource.id === record.resource.id) !== undefined;
                const isDisabled = userRoleTemplate && userRoleTemplate.templateType !== CONFIG.roleTypes.superAdmin && !hasFullAccess

                return editable
                    ? (
                        <span>
                            <ManageDemoResourceContext.Consumer>
                                {form => (
                                    <span className="link" onClick={() => this.handleSave(record, form)}>
                                        <span class="neo-icon-save" title="Save" style={{ fontSize: "20px" }}></span>
                                    </span>
                                )}
                            </ManageDemoResourceContext.Consumer>
                            <Divider type="vertical" />
                            <Popconfirm title="Confirm Cancel?" okText="Yes" cancelText="No" onConfirm={() => this.handleCancel(record)}>
                                <span className="link"><span class="neo-icon-close" title="Cancel" style={{ fontSize: "20px" }}></span></span>
                            </Popconfirm>
                        </span>
                    )
                    : (
                        <div>
                            <Tooltip title={isDisabled ? CONFIG.warningMessages.noAccess : undefined} key={Math.random()}>
                                <button
                                    aria-label="Click"
                                    disabled={isDisabled}
                                    onClick={() => this.setState({ editingKey: record.resource.id })}
                                    className={isDisabled ? "link-disabled" : "link"}
                                >
                                    <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                </button>
                            </Tooltip>
                            {<Divider type="vertical" />}
                            <Tooltip title={isDisabled ? CONFIG.warningMessages.noAccess : undefined} key={Math.random()}>
                                <button aria-label="Click" disabled={isDisabled} className={!isDisabled ? "link" : "link-disabled"} onClick={() => this.handleDelete(record)}> <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                            </Tooltip>
                        </div >
                    );
            },
            align: 'center'
        }
    ];

    handleSave = (record, form) => {
        const isActive = form.getFieldValue("isActive");
        if (record.isActive && !isActive)
            checkDemoResourceInactiveDependencies(this.props.demoBasicDetails?.id, record.resource.id)
                .then(dependencyList => {
                    if (dependencyList) this.renderResourcesInactivationConfirmation(dependencyList, record, form)
                    else this.handleSaveConfirmation(record, form)
                })
        else this.handleSaveConfirmation(record, form)
    }

    handleSaveConfirmation = (record, form) => {
        const isActive = form.getFieldValue("isActive");
        const description = form.getFieldValue("description");

        this.props.editDemoResource({
            ...record,
            description: description || "",
            isActive: isActive
        });
        this.setState({ editingKey: "" });
    }

    handleDemoScheduleUpdate = (scheduleId, schedule) => {
        Modal.destroyAll();
        const { demo } = this.props;
        const { timeZoneName } = this.props;

        const schedulerStartTime = moment(schedule.startTime).tz(timeZoneName).startOf('day').toISOString()
        const schedulerEndTime = moment(schedule.startTime).tz(timeZoneName).endOf('day').toISOString()

        const startTime = moment(schedule.startTime).tz(timeZoneName).startOf('day').toISOString();
        const endTime = moment(schedule.startTime).tz(timeZoneName).endOf('day').toISOString();

        this.props.updateSchedulerStartEndTime(schedulerStartTime, schedulerEndTime);
        this.props.fetchActiveDemoResources(demo?.id)
            .then(() => this.props.fetchSchedulesOverDuration(demo?.id, startTime, endTime))
            .then(() => this.props.updateCurrentDemoSchedule({ ...schedule, id: scheduleId }))
            .then(() => this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/standard/${scheduleId}/edit/isReschedule=${false}/fromManageSide=${false}`))
    }


    renderResourcesInactivationConfirmation = (dependencyList, record, form) => {

        const columns = [
            {
                title: 'Email',
                key: 'email',
                width: "25%",
                render: (record) => {
                    const userProfile = (record.updatedBy === undefined || record.updatedBy === null) ? record.createdBy : record.updatedBy;
                    return userProfile.email
                }
            },
            {
                title: 'Start Date',
                dataIndex: 'startTime',
                width: "25%",
                key: 'startDate',
                align: 'center',
                render: (startTime) => moment.tz(startTime, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)
            },
            {
                title: 'End Date',
                dataIndex: 'endTime',
                key: 'endDate',
                width: "25%",
                align: 'center',
                render: (endTime) => moment.tz(endTime, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)
            },
            {
                title: 'Action',
                key: 'action',
                width: "25%",
                render: (option, record) => {

                    return (
                        <button
                            aria-label="Click"
                            onClick={() => this.handleDemoScheduleUpdate(record.id, record)}
                            className="link"
                        >
                            Edit
                        </button>
                    );
                },
                align: 'center'
            }
        ];

        const okButtonText = "Confirm Resource Update"
        const modalTitle = `There are future schedules on ${record.resource.name} resource. Move those schedules to another resource and then proceed with this action.`
        const content = <Table
            size='middle'
            className="responsive-container vertical-spacing"
            columns={columns}
            dataSource={dependencyList}
            bordered
            pagination={false}
        />
        openConfirm(okButtonText, () => this.handleSaveConfirmation(record, form), null, content, modalTitle, "demo-resource-dependancyList-modal");
    }

    handleCancel = () => this.setState({ editingKey: "" })

    handleDelete = (record) => {
        this.resourceId = record?.resource?.id;
        checkDemoResourceDependencies(this.props.demoBasicDetails?.id, this.resourceId)
            .then(dependencyList => this.renderDeleteRestoreConfirmation(dependencyList))
            .catch((err) => pushMessage(CONFIG.messageType.error, err.message))

    }
    renderDeleteRestoreConfirmation = (dependencyList) => {
        const { scheduleCount, maintenanceCount } = dependencyList;
        const okButtonText = "Confirm Deletion"
        const text = "Deletion"
        const showDependencyList = scheduleCount !== 0 || maintenanceCount !== 0;
        const modalTitle = showDependencyList ? CONFIG.dependencyModalTitle : CONFIG.deleteModalTitle?.replace('<placeholder>', 'resource')
        const content = <>
            {showDependencyList &&
                <>
                    <h4>{text} of selected resource would affect</h4>
                    {<>{scheduleCount} * Future Scheduled Demos <br /></>}
                    {<>{maintenanceCount} * Future Maintenance Windows<br /></>}
                </>
            }
        </>
        openConfirm(okButtonText, this.handleDeleteConfirmation, null, content, modalTitle);
    }

    handleDeleteConfirmation = async () => {
        await this.props.deleteDemoResource(this.resourceId);
    }

    handleAdd = () => {
        const { form } = this.props;
        const resource = form.getFieldsValue().resource;
        resource.forEach(res => {
            const resourceToAdd = JSON.parse(res);
            const resourceLdapGroup = resourceToAdd?.ldapGroupAtom;
            resourceToAdd.isActive = true;
            this.props.addNewDemoResource(resourceToAdd, resourceLdapGroup);
        })
        form.resetFields();
    }


    isEditing = record => record.resource.id === this.state.editingKey;

    renderResourceOptions = () => {
        const { allResources, resources } = this.props;
        const filteredRes = allResources?.length > 0 && allResources?.filter(ar =>
            resources.every(res => res.resource.id !== ar.id));

        return filteredRes
            ? filteredRes?.map((resource, index) => {
                return <Option key={index} value={JSON.stringify(resource)}>{resource.name}</Option>;
            })
            : [];
    }

    renderResourcesSelect = () => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Row gutter={24} className="vertical-spacing">
                <Col xl={12} sm={12} xs={24}>
                    <Form id="demoResourceForm">
                        <Form.Item>
                            {
                                getFieldDecorator('resource')(
                                    <Select showSearch={true}
                                        // getPopupContainer={() => document.getElementById('demoResourceForm')}
                                        id='resource-select'
                                        autoClearSearchValue={false}
                                        mode="multiple"
                                        placeholder="Select one or more resources"
                                        disabled={this.state.editingKey !== ""}>
                                        {this.renderResourceOptions()}
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Form>
                </Col>
                <Col xl={2} sm={2} xs={24}>
                    <Button aria-label="Click" id='resource-add' className="primary-action-button-bordered horizontal-spacing antd-button-fix" onClick={this.handleAdd} disabled={(!form.getFieldValue('resource') || form.getFieldValue('resource')?.length === 0)}>
                        Add
                        <Icon aria-label="aria-menu" type="plus-circle" />
                    </Button>
                </Col>
            </Row>
        )
    }


    render() {
        const { resources, userGroups } = this.props;
        const components = {
            body: {
                cell: DemoResourcesTableCell
            }
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    editing: this.isEditing(record),
                    usergroups: userGroups,
                    form: this.props.form,
                })
            };
        });
        return (
            <React.Fragment>
                {this.renderResourcesSelect()}
                <ManageDemoResourceContext.Provider value={this.props.form}>
                    <Table
                        size='middle'
                        rowKey={(record) => record.resource.id}
                        className="responsive-container"
                        components={components}
                        bordered
                        loading={this.props.isFetching}
                        dataSource={resources}
                        columns={columns}
                        pagination={false}
                        scroll={{ y: 550, scrollToFirstRowOnChange: false, x: 1200 }}
                    />
                </ManageDemoResourceContext.Provider>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ allResources, viewport, user, openTabDemoDetails, demos }) => {
    const { demoResources, demoBasicDetails } = openTabDemoDetails?.currentTabData
    return {
        allResources,
        resources: demoResources || [],
        userGroups: demos.userGroups,
        viewport,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        isSyncing: user.isLoading,
        demoBasicDetails,
        demo: demoBasicDetails?.demo,
        timeZoneName: user.profile?.timezone,
    };
}
const EditableFormTable = Form.create()(DemoResources);

export default connect(
    mapStateToProps,
    {
        deleteDemoResource,
        addNewDemoResource,
        editDemoResource,
        syncUserGroups,
        updateSchedulerStartEndTime,
        fetchSchedulesOverDuration,
        fetchActiveDemoResources,
        updateCurrentDemoSchedule,
        openLinkInNewTab
    }
)(EditableFormTable);

import React from 'react';
import { connect, batch } from 'react-redux';
import { Form, Row, Collapse, Icon, Spin } from 'antd';

import {
    toggleBackButton,
    fetchRoleTemplate,
    fetchDomains,
    postRoleTemplate,
    clearRoleTemplate,
    toggleIsFetchingRoleTemplatesFlag,
    selectTitle,
    currentRoleTemplatesFilter,
    updateActiveTab,
    hasSaveChanges,
    deleteTab,
    toggleHasUnsavedChangesFlag,
    closeCurrentOpenTab,
    clearRtResource
} from '../../actions';
import RoleTemplatesBasicDetails from './RoleTemplatesBasicDetails';
import RoleTemplatesDemos from './RoleTemplatesDemos';
import RoleTemplatesNews from './RoleTemplatesNews';
import RoleTemplatesTips from './RoleTemplatesTips';
import RoleTemplatesNotifications from './RoleTemplatesNotifications';
import RoleTemplatesEnvironments from './RoleTemplatesEnvironments';
import RoleTemplateResources from './RoleTemplateResources';
import RoleTemplatesSupport from './RoleTemplatesSupport';
import RoleTemplatesDemoQuestions from './RoleTemplatesDemoQuestions';
import RoleTemplateSolutions from './RoleTemplateSolutions';
import AdminRoleTemplates from './AdminRoleTemplates';
import RoleTemplatesNetworks from './RoleTemplatesNetworks';
import RoleTemplateDemoPurpose from "./RoleTemplateDemoPurpose"
import RoleTemplateFeedbackQuestions from "./RoleTemplateFeedbackQuestions"
import RoleTemplatesSolutionTypes from './RoleTemplatesSolutionTypes'
import RoleTemplatesReports from './RoleTemplatesReports';
import RoleTemplateCapability from "./RoleTemplateCapability"
import RoleTemplateEZDemos from "./RoleTemplateEZDemos"
import AdminExclusiveTag from '../common/AdminExclusiveTag';
import CONFIG from '../../config';
import history from '../../history';
import FormActionButtons from '../common/FormActionButtons';
import { checkRoleTemplateChanges } from "../../utils/status";

const { Panel } = Collapse;

class RoleTemplatesForm extends React.Component {
    state = {
        isFetching: false,
        modalOpen: false,
    }

    hasChanged = false;

    componentDidMount() {
        const { roleTemplateId } = this.props.match.params;
        batch(() => {
            document.getElementById("tab-pane").scrollIntoView();
            // roleTemplateId && this.props.currentRoleTemplatesFilter(filterSort);
            const currentPath = window.location.pathname
            this.setState({ currentPath: currentPath })
            this.props.selectTitle("Manage Role Templates")
            if (roleTemplateId) {
                batch(async () => {
                    this.props.toggleIsFetchingRoleTemplatesFlag(true)
                    await this.props.fetchRoleTemplate(roleTemplateId);
                    setTimeout(() => {
                        this.setState({
                            fetched: true
                        })
                    }, 500)
                })
            }
            this.props.fetchDomains();
        });
    }

    handleClose = () => {
        this.props.closeCurrentOpenTab();
    }

    onTabClose = async () => {
        await this.props.deleteTab(this.props.activeKey, true)
        history.replace(this.props.activeKey)
        if (this.props.openedTabs?.length === 0) {
            history.replace('/getStarted');
            // this.props.selectTitle(this.getTitle());
        }
    }

    handleOk = () => {
        this.onTabClose();
        this.setState({ modalOpen: false });
        this.props.hasSaveChanges(false);
    }

    handleCancel = () => {
        this.props.hasSaveChanges(false);
        this.setState({ modalOpen: false })
    };

    handleSave = () => {
        const { form, templateType } = this.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            const basicDetails = {
                title: values.roleTemplateName,
                templateType: templateType === CONFIG.roleTypes.superAdmin || templateType === CONFIG.roleTypes.default
                    ? templateType
                    : values.isAdmin ? CONFIG.roleTypes.admin : CONFIG.roleTypes.user,
                domains: values.domains,
                networksAccess: values.networksAccess ? 1 : 0,
                isRatingEnabled: values.isRatingEnabled ? 1 : 0,
                isViewCommentEnabled: values.isViewCommentEnabled ? 1 : 0,
                isAddParticipantsEnabled: values.isAddParticipantsEnabled ? 1 : 0,
                isPeriodicEnabled: values.isPeriodicEnabled ? 1 : 0,
                isMultipleDays: values.isMultipleDays ? 1 : 0,
                description: values.description,
                isEzDemoRatingEnabled: values?.isEzDemoRatingEnabled,
                isViewEzDemoComment: values?.isViewEzDemoComment
            };
            this.props.toggleIsFetchingRoleTemplatesFlag(true)
            this.props.postRoleTemplate(basicDetails);
        });
    }

    renderFormButtons = () => {
        const { viewport } = this.props;
        const isMobileView = viewport.isMobileView
        return (
            <Row className="right-align vertical-spacing">
                <FormActionButtons isDisabled={!this.hasChanged} size={!isMobileView ? "medium" : undefined} handleSubmit={this.handleSave} cancelText={"Discard & Close"} handleCancel={this.handleClose} okText="Save" />
            </Row>
        );
    }

    getCurrentData = (rtDetails) => {
        const { roleTemplate, templateType } = this.props;
        return {
            title: rtDetails.roleTemplateName,
            templateType: templateType === CONFIG.roleTypes.superAdmin || templateType === CONFIG.roleTypes.default
                ? templateType
                : rtDetails.isAdmin ? CONFIG.roleTypes.admin : CONFIG.roleTypes.user,
            networksAccess: rtDetails.networksAccess ? 1 : 0,
            isRatingEnabled: rtDetails.isRatingEnabled,
            isViewCommentEnabled: rtDetails.isViewCommentEnabled,
            isEzDemoRatingEnabled: rtDetails.isEzDemoRatingEnabled,
            isViewEzDemoComment: rtDetails.isViewEzDemoComment,
            description: rtDetails.description,
            domains: [],
            demos: roleTemplate.demos,
            news: roleTemplate.news,
            reports: roleTemplate.reports,
            notifications: roleTemplate.notifications,
            tips: roleTemplate.tips,
            resources: roleTemplate.resources,
            solutions: roleTemplate.solutions,
            dataCentersAccess: roleTemplate.dataCentersAccess,
            didsAccess: roleTemplate.didsAccess,
            networks: roleTemplate.networks,
            supportGuides: roleTemplate.supportGuides,
            roleTemplates: roleTemplate.roleTemplates,
            solutionTypes: roleTemplate.solutionTypes,
            demoPurposes: roleTemplate.demoPurposes,
            questions: roleTemplate.questions,
            demoQuestions: roleTemplate.demoQuestions,
            capabilities: roleTemplate.capabilities,
            ezDemos: roleTemplate.ezDemos,
        }
    }


    componentWillUnmount() {
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        this.props.clearRoleTemplate();
        this.props.clearRtResource();
    }

    componentDidUpdate() {
        // const { hasSave } = this.props;
        // const { roleTemplateId } = this.props.match.params;
        // if (roleTemplateId) {
        //     if (!hasSave && this.hasChanged) this.props.hasSaveChanges(true);
        //     if (hasSave && !this.hasChanged) this.props.hasSaveChanges(false);
        // }
        // else this.props.hasSaveChanges(true);
        const { openedTabs, activeKey } = this.props;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
        else if (hasUnsavedChanges && !this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
    }

    checkRoleTemplateFormChanged = (data, isSuperAdmin) => {

        const { oldData } = this.props;
        let hasChanged = false;
        hasChanged = data?.title?.trim() !== oldData?.title?.trim()
            || data?.description?.trim() !== (oldData?.description === null ? "" : oldData?.description?.trim())
            || data?.isRatingEnabled !== oldData?.isRatingEnabled
            || data?.isViewCommentEnabled !== oldData?.isViewCommentEnabled
            || data?.templateType?.trim() !== oldData?.templateType?.trim()
            || data?.networksAccess !== oldData?.networksAccess
            || data?.isEzDemoRatingEnabled !== oldData?.isEzDemoRatingEnabled
            || data?.isViewEzDemoComment !== oldData?.isViewEzDemoComment

        if (hasChanged) return hasChanged;

        hasChanged = checkRoleTemplateChanges(oldData?.demos, data?.demos, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.news, data?.news, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.reports, data?.reports, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.notifications, data?.notifications, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.tips, data?.tips, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.resources, data?.resources, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.solutions, data?.solutions, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = oldData?.dataCentersAccess !== data?.dataCentersAccess
        if (hasChanged) return hasChanged;
        hasChanged = oldData?.didsAccess !== data?.didsAccess
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.networks, data?.networks, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.supportGuides, data?.supportGuides, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.roleTemplates, data?.roleTemplates, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.solutionTypes, data?.solutionTypes, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.demoPurposes, data?.demoPurposes, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.questions, data?.questions, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.demoQuestions, data?.demoQuestions, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.capabilities, data?.capabilities, isSuperAdmin);
        if (hasChanged) return hasChanged;
        hasChanged = checkRoleTemplateChanges(oldData?.ezDemos, data?.ezDemos, isSuperAdmin);
        if (hasChanged) return hasChanged;

        return hasChanged
    }

    render() {
        const { templateType, isFetching, networksAccess, oldData } = this.props;
        const { roleTemplateId } = this.props.match.params;
        const isSuperAdmin = templateType === CONFIG.roleTypes.superAdmin;
        const isAdmin = templateType === CONFIG.roleTypes.admin;
        const currentData = this.getCurrentData(this.props.form.getFieldsValue());
        this.hasChanged = roleTemplateId ? oldData && this.checkRoleTemplateFormChanged(currentData, isSuperAdmin) : true;

        return (
            <Spin spinning={isFetching} wrapperClassName="spin-overlay">
                <div className="content-container">
                    {!isSuperAdmin && this.renderFormButtons()}
                    <RoleTemplatesBasicDetails form={this.props.form} />
                    {!isSuperAdmin &&
                        <Collapse
                            className="role-template-form-collapse"
                            bordered={false}
                            destroyInactivePanel={true}
                            expandIconPosition='right'
                            expandIcon={({ isActive }) => <Icon aria-label="aria-menu" type="caret-right" rotate={isActive ? 90 : 0} />}
                            accordion
                        >
                            <Panel header={<span className="large-text">Demos</span>} key="1">
                                <RoleTemplatesDemos />
                            </Panel>
                            <Panel className="top-spacing" header={<span className="large-text">News</span>} key="2">
                                <RoleTemplatesNews />
                            </Panel>
                            <Panel className="top-spacing" header={<span className="large-text">Notifications</span>} key="3">
                                <RoleTemplatesNotifications />
                            </Panel>
                            <Panel className="top-spacing" header={<span className="large-text">Tips</span>} key="4">
                                <RoleTemplatesTips />
                            </Panel>
                            <Panel className="top-spacing" header={<span className="large-text">Resources</span>} key="5">
                                <RoleTemplateResources />
                            </Panel>
                            <Panel className="top-spacing" header={<span className="large-text">Solutions</span>} key="6">
                                <RoleTemplateSolutions />
                            </Panel>
                            <Panel className="top-spacing" header={<span className="large-text">Solution Types</span>} key="7">
                                <RoleTemplatesSolutionTypes />
                            </Panel>
                            <Panel className="top-spacing" header={<span className="large-text">Reports</span>} key="8">
                                <RoleTemplatesReports />
                            </Panel>
                            <Panel className="top-spacing" header={<span className="large-text">Demo Purpose</span>} key="9">
                                <RoleTemplateDemoPurpose />
                            </Panel>
                            <Panel className="top-spacing" header={<span className="large-text">Feedback Questions</span>} key="10">
                                <RoleTemplateFeedbackQuestions />
                            </Panel>
                            <Panel className="top-spacing" header={<span className="large-text"> EZ Demos</span>} key="13">
                                <RoleTemplateEZDemos />
                            </Panel>
                            {isAdmin &&
                                <Panel className="top-spacing" header={<span className="large-text"><AdminExclusiveTag /> Demo Questions</span>} key="11">
                                    <RoleTemplatesDemoQuestions />
                                </Panel>
                            }
                            {isAdmin &&
                                <Panel className="top-spacing" header={<span className="large-text"><AdminExclusiveTag /> Demo Capabilities</span>} key="12">
                                    <RoleTemplateCapability />
                                </Panel>
                            }
                            {isAdmin &&
                                <Panel className="top-spacing" header={<span className="large-text"><AdminExclusiveTag /> Environments</span>} key="14">
                                    <RoleTemplatesEnvironments />
                                </Panel>
                            }
                            {isAdmin && networksAccess &&
                                <Panel className="top-spacing" header={<span className="large-text"><AdminExclusiveTag /> Networks</span>} key="15">
                                    <RoleTemplatesNetworks />
                                </Panel>
                            }
                            {isAdmin &&
                                <Panel className="top-spacing" header={<span className="large-text"><AdminExclusiveTag /> Support</span>} key="16">
                                    <RoleTemplatesSupport />
                                </Panel>
                            }
                            {isAdmin &&
                                <Panel className="top-spacing" header={<span className="large-text"><AdminExclusiveTag /> Role Templates</span>} key="17">
                                    <AdminRoleTemplates roleTemplateId={roleTemplateId} />
                                </Panel>
                            }
                        </Collapse>
                    }
                    {this.renderFormButtons()}
                </div>
            </Spin>
        );
    }
}

const RoleTemplatesFormContainer = Form.create()(RoleTemplatesForm);

const mapStateToProps = ({ roleTemplate, viewport, tabsLayout, user }) => {
    return {
        templateType: roleTemplate.templateType,
        isFetching: roleTemplate.isFetching,
        networksAccess: roleTemplate.networksAccess,
        viewport,
        oldData: roleTemplate.data,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        roleTemplate,
        hasSave: tabsLayout.hasSave,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
    }
}

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        fetchRoleTemplate,
        fetchDomains,
        postRoleTemplate,
        toggleIsFetchingRoleTemplatesFlag,
        selectTitle,
        currentRoleTemplatesFilter,
        updateActiveTab,
        hasSaveChanges,
        deleteTab,
        toggleHasUnsavedChangesFlag,
        closeCurrentOpenTab,
        clearRtResource,
        clearRoleTemplate
    }
)(RoleTemplatesFormContainer);
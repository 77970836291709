import React from "react";
import { connect, batch } from 'react-redux';
import { Table, Row, Button, Icon, Col, Popconfirm, Typography, Divider, message, Dropdown, Tooltip } from "antd";
import { Icon as NeoIcon } from "@avaya/neo-react";
import { exportMenu } from "../../utils/strings";

import {
    fetchAllTips,
    fetchTip,
    toggleIsFetchingTipsFlag,
    deleteTip,
    clearTips,
    toggleBackButton,
    selectTitle,
    clearCurrentTip,
    updateTipsCurrentPage,
    restoreTip,
    clearTipsTableData,
    currentTipFilter,
    updateActiveTab,
    handleOnClickAction,
} from '../../actions';
import { exportTips } from "../../actions/tips";
import CONFIG from '../../config'
import StatusIcon from "../common/StatusIcon";
import pushMessage from '../common/PushMessage';
import FilterPanel from '../common/FilterPanel';
import StatusFilter from '../common/StatusFilter';
import openConfirm from '../common/Confirm';
import scrollIntoView from 'scroll-into-view';
import { isTabAlreadyOpen } from "../common/TabLayout";

const { Text } = Typography;

const dataIndexMap = {
    title: 'title',
    status: 'isActive',
    viewDeletedItems: 'viewDeletedItems'
};

class ManageTips extends React.Component {

    state = {
        status: true,
        isReturnedFromEdit: false,
        isReturnedFromDelete: false,
        deletedRecordIndex: undefined,
        showFilter: false,
    }

    tableRef = React.createRef()
    filterSort = {};
    currentPage = 1
    flag = 0;

    clearCurrentTips = async () => {
        this.currentPage = 1;
        await this.props.clearTipsTableData();
    }

    async componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentTipFilter(this.filterSort, this.state.status)
            // This will ensure that we do not increment the pageNumber on Tab Switch.
            const nextPageNumber = this.currentPage > 2 ? this.currentPage - 1 : this.currentPage
            this.props.updateTipsCurrentPage(nextPageNumber, (nextPageNumber * 12), 0, 0)
            await this.props.clearTipsTableData();
        }
        else {
            this.props.clearTips();
        }
    }

    componentDidUpdate = () => {
        const tipToScrollIntoView = this.state.isReturnedFromDelete
            ? Math.abs(this.state.deletedRecordIndex - 1)
            : this.state.isReturnedFromEdit
                ? this.props.tipIndex
                : 0;

        (this.state.isReturnedFromEdit || this.state.isReturnedFromDelete)
            && this.props.tips?.length > 0
            && tipToScrollIntoView
            && scrollIntoView(document.querySelector(`.scroll-row-${tipToScrollIntoView}`), {
                align: { top: 0, left: 0 },
                behavior: "smooth"
            },
                this.setState({ isReturnedFromEdit: false, isReturnedFromDelete: false })
            )
    }

    fetchTipsOnMount = async () => {
        const { isEdit, pageSize } = this.props;
        this.props.toggleIsFetchingTipsFlag(true)
        await this.props.fetchAllTips({
            pageNumber: (isEdit || this.state.isReturnedFromDelete) ? 1 : this.currentPage++,
            pageSize: (isEdit || this.state.isReturnedFromDelete) ? pageSize : CONFIG.lazyLoadPageSize,
            filterSort: this.filterSort,
            status: this.state.status
        })
    }

    async componentDidMount() {
        const { currentStatus, isEdit, currentFilter, currentPageNumber } = this.props;
        this.setState({ loading: true })
        this.setState({ currentPath: window.location.pathname })
        this.props.selectTitle('Tips Management');
        var tableContent = document.querySelector('.ant-table-body');
        tableContent && tableContent.addEventListener('scroll', (event) => {
            let maxScroll = event.target.scrollHeight - event.target.clientHeight
            let currentScroll = event.target.scrollTop
            if ((event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100) && (maxScroll !== 0 && currentScroll !== 0)) {
                this.flag += 1;
                this.flag === 1 && this.handleFetchTips()
            }
        })

        if (isEdit) {
            this.currentPage = currentPageNumber + 1;
            this.filterSort = currentFilter;
            if (Object.keys(JSON.parse(JSON.stringify(currentFilter)))?.length > 0) {
                const filterObject = JSON.parse(JSON.stringify(currentFilter?.filter));
                Object.keys(filterObject)?.length > 0 && this.setState({ showFilter: true })
            }
            await this.setState({ status: currentStatus })
        }
        else {
            this.filterSort = {}
        }
        batch(() => {
            this.fetchTipsOnMount();
            this.props.selectTitle("Manage Tips");
            this.props.toggleIsFetchingTipsFlag(true);
        });
    }

    handleFetchTips = (isFilter) => {
        const { tipsCount, tips } = this.props
        const countOfRecordsFetched = tips?.length
        if (countOfRecordsFetched < tipsCount) {
            this.props.toggleIsFetchingTipsFlag(true);
            this.props.fetchAllTips({ pageNumber: this.currentPage++, filterSort: this.filterSort, status: this.state.status })
                .then(() => {
                    if (!isFilter) {
                        var scrollContainer = document.querySelector('.ant-table-body')
                        scrollContainer.scrollTop = scrollContainer?.scrollHeight - scrollContainer?.clientHeight - scrollContainer?.clientHeight;
                    }
                })
            this.flag = 0;
        }
    }

    handleSelectChange = async (value) => {
        await this.clearCurrentTips()
        this.setState({ status: value }, () => { this.handleFetchTips() })
    }

    handleFilter = async (filters) => {
        let { title, viewDeletedItems } = filters;
        title = title?.trim();
        await this.clearCurrentTips();
        await this.props.clearTipsTableData();
        if (!title && !viewDeletedItems) {
            pushMessage(CONFIG.messageType.warning, "Please select some fields to filter")
            return;
        }

        this.filterSort = {
            filter: {
                [dataIndexMap.title]: title || undefined,
                [dataIndexMap.viewDeletedItems]: viewDeletedItems,
            }
        }
        if (viewDeletedItems) this.setState({ status: false })
        this.handleFetchTips(true);
        scrollIntoView(document.querySelector(`.ant-table-thead`), {
            align: { top: 10, left: 0 },
            behavior: "smooth"
        })

    }

    handleClear = async (refresh) => {
        if (refresh) {
            await this.props.clearTipsTableData();
            this.currentPage = 1;
        }
        else {
            await this.props.clearTipsTableData();
            await this.clearCurrentTips();
            this.filterSort = { ...this.filterSort, filter: {} };

        }
        await this.fetchTipsOnMount();

    }

    handleSortChange = async (pagination, _, sorter) => {
        await this.clearCurrentTips()
        this.filterSort = {
            ...this.filterSort,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }
        this.handleFetchTips();
    }

    handleDelete = (tipId, index) => {
        this.tipId = tipId;
        this.recordIndex = index
        const hardDelete = this.filterSort?.filter?.viewDeletedItems;
        if (this.props.userProfile?.isDeveloper && hardDelete) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
        else {
            const okButtonText = hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
            const content = hardDelete ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Tip")}</h4> : <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "Tip")}</h4>
            const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'tip')
            openConfirm(okButtonText, this.handleDeleteConfirmation, null, content, modalTitle);
        }
    }

    handleDeleteConfirmation = async () => {
        const hardDelete = this.filterSort?.filter?.viewDeletedItems;
        const response = await this.props.deleteTip(this.tipId, hardDelete);
        if (response) {
            this.handleFetchTipsAfterDeleteOrRestore(this.recordIndex)
        }
    }

    handleFetchTipsAfterDeleteOrRestore = async (index) => {
        this.clearCurrentTips()
        const pageSize = ((Math.ceil(index / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) + CONFIG.lazyLoadPageSize);
        const currentPageNumber = ((Math.ceil(index / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) / CONFIG.lazyLoadPageSize) + 1;
        await this.props.updateTipsCurrentPage(currentPageNumber, pageSize, this.tipId, index)
        await this.setState({ isReturnedFromDelete: true, deletedRecordIndex: index })
        this.currentPage = this.props.currentPageNumber + 1
        this.fetchTipsOnMount();
    }

    handleRestore = async (tipId, index) => {
        const response = await this.props.restoreTip(tipId);
        if (response) {
            this.handleFetchTipsAfterDeleteOrRestore(index);
        }
    }

    handleExport = async (fileType) => {
        const timeZone = this.props.timeZoneName;
        const filterSort = {
            ...this.filterSort,
            filter: {
                ...this.filterSort.filter,
                isActive: this.state.status
            }
        }
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        exportTips(CONFIG.exportTable.tips.fileName + `.${fileType}`, fileType, filterSort, timeZone)
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }

    render() {
        const { timeZoneName, tipsCount, tips, fetching } = this.props;
        const title = this.filterSort?.filter?.viewDeletedItems ? 'List of Deleted Tips' : this.state.status ? 'List of Active Tips' : this.state.status === false ? 'List of Inactive Tips' : 'List of All Tips';
        const columns = [
            {
                title: 'No.',
                key: 'index',
                align: 'center',
                width: "10%",
                render: (text, record, index) => index + 1,
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.title] ? "filtered-column" : ''}>Title</span>,
                dataIndex: 'title',
                key: 'title',
                width: "60%",
                sorter: true
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.isActive] !== undefined ? "filtered-column" : ''}>Status</span>,
                dataIndex: "isActive",
                key: "isActive",
                width: "15%",
                sorter: true,
                render: status => <StatusIcon status={status} />,
                align: "center"
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: "15%",
                render: (text, record, index) => {
                    const { userProfile } = this.props;
                    const { roleTemplate } = userProfile;
                    const deleteView = this.filterSort?.filter?.viewDeletedItems;
                    const isEditDisabled = roleTemplate
                        && roleTemplate.templateType !== CONFIG.roleTypes.superAdmin
                        && record.adminAccess === CONFIG.roleTemplateAccess.readOnly;
                    return (
                        <React.Fragment>
                            {!deleteView &&
                                <>
                                    <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                        < button
                                            onClick={() => {
                                                this.props.handleOnClickAction({
                                                    component: CONFIG.editComponentRoute.tips,
                                                    tabTitle: record?.title,
                                                    recordId: record?.id
                                                }
                                                )
                                            }}
                                            disabled={isEditDisabled}
                                            className={!isEditDisabled ? "link" : "link-disabled"}>
                                            <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                    {/* </Link> */}
                                    {" "}
                                    <Divider type="vertical" />
                                </>
                            }
                            < Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id} >
                                <>
                                    <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleDelete(record.id, index + 1)}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                                    {deleteView && <>
                                        <Divider type="vertical" />
                                        <Popconfirm disabled={isEditDisabled} title="Confirm restore?" onConfirm={() => this.handleRestore(record.id, index + 1)}>
                                            <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"}><span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span></button>
                                        </Popconfirm>
                                    </>
                                    }
                                </>
                            </Tooltip>
                        </React.Fragment >
                    );
                }
            }
        ];
        const formFields = [
            {
                label: "Title",
                type: CONFIG.formFieldTypes.INPUT,
                key: "title",
                value: this.filterSort?.filter?.title
            }
        ]
        const { showFilter } = this.state;
        return (
            <React.Fragment>
                <Row className="vertical-spacing">
                    <Col xl={3} sm={6} xs={24}>
                        <StatusFilter disabled={this.filterSort?.filter?.viewDeletedItems} onChange={this.handleSelectChange} value={this.state.status} />
                    </Col>
                    <Col xl={2} sm={6} xs={24} className="left-spacing">
                        <Button className="primary-action-button-filled" onClick={() => { this.props.handleOnClickAction({ component: CONFIG.createComponentRoute.tips, tabTitle: "Create" }) }}>New
                            <Icon aria-label="aria-menu" type="plus-circle" />
                        </Button>
                    </Col>
                    {/* for export icon */}
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoIcon aria-label="menu-aria" title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </Dropdown>
                    </Col>
                    {/* for refresh icon */}
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                    {/* for filter icon */}
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                </Row>
                <div className="content-container responsive-container">
                    <Row>
                        {this.state.showFilter &&
                            <FilterPanel
                                label='View Deleted Tips'
                                panelHeader="Filters and Export"
                                formFields={formFields}
                                handleClear={this.handleClear}
                                handleFilter={this.handleFilter}
                                timeZone={timeZoneName}
                                viewDeletedItemsValue={this.filterSort?.filter?.viewDeletedItems}
                            />}
                    </Row>
                    <Row>
                        <h3>{title}</h3>
                        <div ref={this.tableRef}>
                            <Table
                                size='middle'
                                // className="responsive-container"
                                rowClassName={(record, index) => (`scroll-row-${index + 1}`)}
                                scrollToFirstRowOnChange={true}
                                rowKey={(record) => record.id}
                                bordered
                                scroll={{ y: 550, x: "auto" | 800 }}
                                dataSource={tips || []}
                                columns={columns}
                                loading={fetching}
                                onChange={this.handleSortChange}
                                pagination={false}
                                footer={() => <>{tips?.length !== 0 && <div style={{ textAlign: 'center' }}>
                                    <Text strong>Fetched {tips?.length} out of {tipsCount} Tips</Text>
                                </div>}
                                </>}
                            />
                        </div>
                    </Row>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ user, tips, tabsLayout }) => {
    return {
        userProfile: user.profile,
        timeZoneName: user.profile.timezone,
        currentPage: tips.currentPage,
        tips: tips.data,
        tipsCount: tips.count,
        currentFilter: tips.currentFilter,
        currentStatus: tips.status,
        isEdit: tips.isEdit,
        currentPageNumber: tips.currentPageNumber,
        pageSize: tips.pageSize,
        fetching: tips.isFetching,
        tipIndex: tips.tipIndex,
        response: tips.response,
        openedTabs: tabsLayout.panes
    };
};

export default connect(
    mapStateToProps,
    {
        fetchAllTips,
        fetchTip,
        toggleIsFetchingTipsFlag,
        deleteTip, clearTips,
        toggleBackButton,
        selectTitle,
        clearCurrentTip,
        updateTipsCurrentPage,
        restoreTip,
        clearTipsTableData,
        currentTipFilter,
        updateActiveTab,
        handleOnClickAction,
    }
)(ManageTips);

import React from 'react';
import { Tooltip, Select, Icon, Form } from 'antd';

const { Option } = Select;

const SolutionTypes = (props) => {
    const renderSolutionTypeOptions = () => {
        return props.solutionTypes.map((type, index) => (
            <Option key={index} value={JSON.stringify({ id: type?.id, name: type?.name, isActive: type?.isActive })}>
                <div>
                    {type.name}
                    {
                        <span className="float-right">
                            <Tooltip title={type.isActive ? "Active" : "Inactive"} key={type.id}>
                                {
                                    type.isActive
                                        ? <Icon aria-label="aria-menu" type="check" className="small-text success-text" />
                                        : <Icon aria-label="aria-menu" type="warning" className="small-text warning-text" />
                                }
                            </Tooltip>
                        </span>
                    }
                </div>
            </Option>
        ));
    }
    const { form, handleSelectChange, selectedSolutionType, solutionType, isMandatory } = props;

    return (
        form ? (
            <Form.Item label="Solution Type">
                {
                    form.getFieldDecorator('solutionTypes', {
                        rules: [
                            { required: { isMandatory }, message: "Please select a Solution Type" },
                        ],
                        initialValue: JSON.stringify(solutionType ? { id: solutionType?.id, name: solutionType?.name, isActive: solutionType?.isActive } : undefined)

                    })(
                        <Select showSearch={true} id='select-solution-types' className="active-inactive-select" placeholder="Select Solution Type">
                            {renderSolutionTypeOptions()}
                        </Select>
                    )
                }
            </Form.Item>
        ) :
            (
                <Select className="active-inactive-select" showSearch={true} placeholder="Select a solution type" onChange={handleSelectChange} value={JSON.stringify(selectedSolutionType ? { id: selectedSolutionType?.id, name: selectedSolutionType?.name, isActive: selectedSolutionType?.isActive } : undefined)}>
                    {renderSolutionTypeOptions()}
                </Select>
            )
    )
}

export default SolutionTypes;
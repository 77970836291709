import React, { Component } from 'react'
import { Modal, Alert } from "antd";
import TextEditor from '../common/TextEditor';
import CONFIG from '../../config';
import { connect } from "react-redux";
import {
    updateCapabilityDetailsDescription,
    clearCapabilityComments
} from "../../actions"
import pushMessage from '../common/PushMessage';
import { Button } from "@avaya/neo-react";

class CapabilityDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            introKey: Math.random().toString()
        }
    }

    renderWarningMessage = () => {
        return <Alert message=" Please ensure that you are not in source mode before saving the tip" type="warning" showIcon />;
    }

    componentWillUnmount() {
        this.props.clearCapabilityComments();
    }

    handleOk = (action) => {
        const { handleApproveOrDeclineConfirmation, comments } = this.props;
        let comment = comments?.trim();
        if (action !== CONFIG.myRequestsStatus.Approved) {
            if (typeof (comment) === "string" && comment?.length > 0) {
                handleApproveOrDeclineConfirmation(action);
            }
            else pushMessage(CONFIG.messageType.warning, `Please add comments before ${action.substring(0, action?.length - 2).toLowerCase()}ing the request`)
        }
        else handleApproveOrDeclineConfirmation(action);
    }

    renderFooterButtons = () => {
        const { action } = this.props.data || {};
        const okText = action.substring(0, action.length - 1)

        return (
            <>
                <Button aria-label="cancel" key="cancel" variant="secondary" onClick={() => this.props.setShowModal(false)} > Cancel </Button>
                <Button aria-label="next" className="left-spacing" key="cancel" variant="primary"
                    status={action === CONFIG.myRequestsStatus.Approved ? "success" : "alert"}
                    onClick={() => this.handleOk(action)}
                > {okText} </Button>
            </>
        )
    }

    render() {
        const { showModal, setShowModal, data, comments } = this.props;
        const { content, modalTitle } = data || {};
        return (
            <div>
                <Modal
                    title={<div style={{ color: "black" }}>{modalTitle}</div>}
                    visible={showModal}
                    footer={this.renderFooterButtons()}
                    onCancel={() => setShowModal(false)}
                    className="wide-modal"
                    bodyStyle={{ background: "#fcfcfc" }}
                >
                    <div className="bottom-spacing">
                        <div>{content}</div>
                        <br />
                        <div style={{ color: "black" }}>Comments</div>
                        {this.renderWarningMessage()}
                        <br />
                        <TextEditor
                            updateEditorContent={this.props.updateCapabilityDetailsDescription}
                            storeKey="capabilityCommentDetails"
                            editorKey={this.state.introKey}
                            isEdit={false}
                            recordId="-1"
                            contentData={comments}
                            isClone={false}
                        />
                    </div>
                </Modal>
            </div >
        )
    }
}

const mapStateToProps = ({ capabilityCommentDetails }) => {
    return {
        comments: capabilityCommentDetails?.html
    }
}

export default connect(mapStateToProps, {
    updateCapabilityDetailsDescription,
    clearCapabilityComments
})(CapabilityDetailsModal);
import React from 'react';
import { Input, Form, Row, Col, Select, Upload, Button, Icon, Modal, Spin } from 'antd';
import { connect, batch } from 'react-redux';

import CONFIG from '../../config';
import FormActionButtons from '../common/FormActionButtons';
import {
    postSupportGuide,
    fetchRoleTemplates,
    toggleBackButton,
    updateSupportRoleTemplate,
    fetchSupportGuide,
    saveSupportRoleTemplates,
    cancelSupportRoleTemplates,
    updateSelectedGuideType,
    currentSupportFilter,
    clearSupportForm,
    deleteTab,
    toggleHasUnsavedChangesFlag,
    closeCurrentOpenTab
} from '../../actions';
import pushMessage from '../common/PushMessage';
import RoleTemplatesTable from '../common/RoleTemplatesTable';
// import history from '../../history';
import { getFileName } from '../../utils/file';
import { checkRoleTemplateChanges } from "../../utils/status";


const { Option } = Select;
const { Dragger } = Upload;

const SupportForm = Form.create({ name: 'form_in_modal' })(
    class extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                file: props?.currentGuide?.path,
                visible: false
            }
        }

        handleAdd = () => {
            const { form, currentGuide } = this.props;
            form.validateFields((err, values) => {

                if (err) {
                    return;
                } else if (values.guideType !== CONFIG.guideType.videoLink.value && !this.state.file) {
                    form.setFields({
                        file: { errors: [Error("Please upload a file")] }
                    });
                    return;
                }
                let guideId = currentGuide ? currentGuide.id : undefined;
                form.resetFields();
                this.props.postSupportGuide(this.state.file, values, guideId)
                this.props.updateSelectedGuideType(values.guideType);
            });
        };

        handleCancel = async () => {
            const { form } = this.props;
            form.resetFields();
            this.props.closeCurrentOpenTab()
        };

        getAssignedRoleTemplates = (isSuperAdminUser) => {
            const { currentGuide } = this.props;
            let assignedRoleTemplates = [];
            if (currentGuide && currentGuide.roleTemplates) {
                currentGuide.roleTemplates.forEach(obj => {
                    const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
                    const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
                    if (isSuperAdminUser ? userAccessCondition || adminAccessCondition : userAccessCondition) {
                        assignedRoleTemplates.push(obj.roleTemplate.title);
                    }
                })
            }
            return assignedRoleTemplates;
        }

        showModal = () => {
            this.setState({
                visible: true
            });
        };

        renderGuideTypes = () => {
            return Object.values(CONFIG.guideType).map((type) => {
                return <Option value={type.value}>{type.text}</Option>
            });
        }

        handleRoleTemplatesCancel = e => {
            this.props.cancelSupportRoleTemplates();
            this.setState({
                visible: false
            });
        };

        handleRoleTemplatesAssign = () => {
            this.props.saveSupportRoleTemplates();
            this.setState({ visible: false });
        }

        componentDidUpdate(prevProps) {
            const { openedTabs, activeKey } = this.props;
            const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
            if (prevProps.match.params.supportId !== this.props.match.params.supportId) {
                this.props.fetchRoleTemplates();
                this.props.fetchSupportGuide(this.props.match.params.supportId);
            }
            if (!hasUnsavedChanges && this.hasChanged)
                this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
            else if (hasUnsavedChanges && !this.hasChanged)
                this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
        }


        async componentDidMount() {
            const { supportId } = this.props.match.params;
            const currentPath = window.location.pathname
            this.setState({ currentPath: currentPath })

            batch(async () => {
                this.props.toggleBackButton(false);
                this.props.fetchRoleTemplates();
                supportId && await this.props.fetchSupportGuide(supportId);
                const { currentGuide } = this.props;
                if (currentGuide && currentGuide.path && currentGuide.guideType !== CONFIG.guideType.videoLink.value) {
                    let mediaFile;
                    mediaFile = new File([], getFileName(currentGuide.path));
                    mediaFile.uid = Date.now();
                    this.setState({ file: mediaFile });
                }
            });
        }

        getCurrentData = (supportFormDetails) => {
            return {
                title: supportFormDetails.title,
                roleTemplates: supportFormDetails?.roleTemplates,
                guideType: supportFormDetails.guideType,
            }
        }

        checkSupportFormChanged = (newData, isSuperAdminUser) => {
            const { title, guideType, name } = this.props.currentGuide || {}
            const { currentGuide, supportObj } = this.props || {}
            const { supportId } = this.props.match.params;
            let hasChanged = false;
            if (supportId) {
                hasChanged = newData?.title?.trim() !== title
                    || newData?.guideType !== guideType
                    || newData?.file?.name !== name
                    || this.state.file?.name !== getFileName(this.props.currentGuide?.path)
                    || checkRoleTemplateChanges(supportObj?.roleTemplates, currentGuide?.roleTemplates, isSuperAdminUser)
            }
            else {
                hasChanged = newData?.title?.trim() !== ""
                    || newData?.guideType !== undefined
                    || newData?.file?.name !== undefined
                    || checkRoleTemplateChanges(supportObj?.roleTemplates, currentGuide?.roleTemplates, isSuperAdminUser)
            }
            if (hasChanged) return hasChanged;
            return hasChanged;
        }

        componentWillUnmount() {
            this.props.clearSupportForm();
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        }

        renderAssignButton = () => {
            return <Button className="primary-action-button-bordered right-border-radius" onClick={this.showModal}>Assign</Button>;
        }

        render() {
            const { form, user, roleTemplates, allRTAdminAccess, currentGuide, isFetching } = this.props;
            const { getFieldDecorator } = form;
            const { supportId } = this.props.match.params;
            const okButtonText = supportId ? 'Update' : 'Create';
            const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
            const assignedRoleTemplates = this.getAssignedRoleTemplates(isSuperAdminUser);
            const { guideType: guideTypes } = CONFIG;
            const formData = this.getCurrentData(this.props.form.getFieldsValue());
            this.hasChanged = supportId ? this.props.currentGuide && this.checkSupportFormChanged(formData, isSuperAdminUser) : true;
            const uploadProps = {
                multiple: false,
                listType: "picture",
                fileList: this.state.file ? [this.state.file] : [],
                beforeUpload: (file) => {
                    const { form } = this.props;
                    const isWithinUploadLimit = file.size / 1024 / 1024 < CONFIG.fileSize.support;
                    if (!isWithinUploadLimit) {
                        pushMessage(CONFIG.messageType.warning, `File must be smaller than ${CONFIG.fileSize.support} MB`)
                        return true;
                    }
                    this.setState({
                        file
                    });
                    form.setFields({
                        file: { errors: [] }
                    });
                    return false;
                },
                onRemove: () => {
                    this.setState({ file: undefined })
                }
            };



            return (
                <div className="content-container">
                    <Spin spinning={isFetching} wrapperClassName="spin-overlay">
                        <Form>
                            <Row gutter={24}>
                                <Col xl={12} xs={24}>
                                    <Form.Item label="Title">
                                        {getFieldDecorator('title', {
                                            rules: [{ required: true, message: 'Please input the title of the guide' }],
                                            initialValue: currentGuide ? currentGuide.title : undefined
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {
                                isSuperAdminUser &&
                                <Row>
                                    <Col xl={12} xs={24}>
                                        <Form.Item label="Assign Role Template">
                                            {getFieldDecorator("roleTemplates", {
                                                initialValue: assignedRoleTemplates.length !== 0
                                                    ? assignedRoleTemplates
                                                    : "None"
                                            })(<Input className="role-template-input" disabled addonAfter={this.renderAssignButton()} />)}
                                            {<Modal
                                                title="Role Template"
                                                visible={this.state.visible}
                                                onCancel={this.handleRoleTemplatesCancel}
                                                footer={<FormActionButtons okText="Assign" handleCancel={this.handleRoleTemplatesCancel} handleSubmit={this.handleRoleTemplatesAssign} />}
                                            >
                                                <RoleTemplatesTable
                                                    dataKey="roleTemplate"
                                                    data={roleTemplates.all}
                                                    pagination={false}
                                                    assignedData={currentGuide && currentGuide.roleTemplates ? currentGuide.roleTemplates : []}
                                                    allAdminAccess={allRTAdminAccess}
                                                    hideColumns={['user']}
                                                    updateRoleTemplateData={this.props.updateSupportRoleTemplate}
                                                />
                                            </Modal>
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            <Row gutter={24}>
                                <Col xl={12} xs={24}>
                                    <Form.Item label="Type">
                                        {getFieldDecorator('guideType', {
                                            rules: [{ required: true, message: 'Please select a type' }],
                                            initialValue: currentGuide ? currentGuide.guideType : undefined
                                        })(
                                            <Select showSearch={true} placeholder="Select a type">
                                                {this.renderGuideTypes()}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={12} xs={24}>
                                    {
                                        form.getFieldValue('guideType') === guideTypes.videoLink.value ?
                                            <Form.Item label="Video Link" required>
                                                {getFieldDecorator('path', {
                                                    rules: [
                                                        { required: true, message: 'Please input the video link' },
                                                        { type: "url", message: "Please input a valid URL" }
                                                    ],
                                                    initialValue: currentGuide?.guideType === guideTypes.videoLink.value ? currentGuide.path : undefined
                                                })(<Input />)}
                                            </Form.Item>
                                            :

                                            <Form.Item label="File" required>
                                                {
                                                    getFieldDecorator('file')(
                                                        <Dragger {...uploadProps}>
                                                            <p className="ant-upload-drag-icon">
                                                                <Icon aria-label="aria-menu" type="inbox" />
                                                            </p>
                                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>

                                                        </Dragger>
                                                    )
                                                }
                                            </Form.Item>
                                    }
                                </Col>
                            </Row>
                            <Row className="vertical-spacing right-align">
                                <FormActionButtons handleSubmit={this.handleAdd} handleCancel={this.handleCancel} isDisabled={!this.hasChanged} okText={okButtonText} cancelText={"Discard & Close"} />
                            </Row>
                        </Form>
                    </Spin>
                </div>
            );
        }
    },
);

const mapStateToProps = ({ user, roleTemplates, support, supportForm, tabsLayout }) => {
    return {
        user: user.profile,
        roleTemplates,
        allRTAdminAccess: supportForm.allRTAdminAccess,
        currentGuide: supportForm.currentGuide,
        supportObj: supportForm.supportObj,
        isFetching: support.isFetching,
        hasSave: tabsLayout.hasSave,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
    }
}

const SupportFormRef = Form.create()(SupportForm);

export default connect(
    mapStateToProps,
    {
        postSupportGuide,
        fetchRoleTemplates,
        toggleBackButton,
        updateSupportRoleTemplate,
        fetchSupportGuide,
        saveSupportRoleTemplates,
        cancelSupportRoleTemplates,
        updateSelectedGuideType,
        currentSupportFilter,
        clearSupportForm,
        deleteTab,
        toggleHasUnsavedChangesFlag,
        closeCurrentOpenTab
    }
)(SupportFormRef);
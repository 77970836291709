import React from "react";
import { Collapse, Icon, Upload, Row, Button, Radio } from "antd";
import CONFIG from "../../config";

const BulkUpload = ({
  handleImport,
  uploadProps,
  isfileUploaded,
  panelHeader,
  handleClear,
  handleRadioChange
}) => {

  const { Panel } = Collapse;

  return (
    <React.Fragment>
      <Collapse
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <Icon aria-label="aria-menu" type="caret-right" rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel
          header={
            <span className="large-text">{panelHeader || "BulkUploads"}</span>
          }
          key="1"
        >
          <Row vertical-spacing left-align>
            <Radio.Group defaultValue={CONFIG.didUploadType.full} onChange={handleRadioChange}>
              <Radio value={CONFIG.didUploadType.full}>Full Upload</Radio>
              <Radio value={CONFIG.didUploadType.partial}>Partial Upload</Radio>
            </Radio.Group>
          </Row>

          <Row className="left-align">
            <Upload {...uploadProps}>
              <Button className="primary-action-button-bordered fixed-width-btn button-width top-spacing-2">
                Upload CSV File
              </Button>
            </Upload>
          </Row>
          <Row className="right-align">
            <Button
              onClick={handleClear}
              className={"cancel-button right-spacing"}
            >
              Clear
            </Button>
            <Button.Group className="left-spacing">
              {handleImport && (
                <Button
                  className={`primary-action-button-filled`}
                  onClick={handleImport}
                  disabled={!isfileUploaded}
                >
                  Import
                </Button>
              )}
            </Button.Group>
          </Row>
        </Panel>
      </Collapse>
    </React.Fragment>
  );
};

export default BulkUpload;

import React from "react";
import { Tooltip, Form, Row, message, Dropdown, Spin } from "antd";
import {
    getAllUsers,
    fetchRoleTemplates,
    putUser,
    clearUsers,
    exportTable,
    currentSSOUsersFilter,
    toggleHasUnsavedChangesFlag,
    toggleSaveReportVisible,
    getAllLiteUsers
} from "../../actions";
import "neo-latest/avaya-neo-react.css"
import { connect, batch } from "react-redux";
import CONFIG from '../../config.js';
import pushMessage from '../common/PushMessage';
import FilterDrawer from "../common/FilterDrawer";
import EditDrawer from "../common/EditDrawer";
import moment from 'moment-timezone';
import { fetchNamesBySearchString } from '../../actions/userRole'
import { exportMenu } from "../../utils/strings";
import { checkIfFilterSortIsEmpty } from "../../utils/formChanged.js"
import { isTabAlreadyOpen } from "../common/TabLayout";
import SaveReport from "../common/SaveReport";
import throttle from 'lodash/throttle';
import { Button, Table as NeoTable, Icon as NeoIcon } from "neo-latest";
import { LoadingOutlined } from '@ant-design/icons';
import { otherFields } from "../../utils/neoTableFields";
import UserDetails from "./UserDetails";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const ManageUserContext = React.createContext();

const dataIndexMap = {
    name: "name",
    fullName: "fullName",
    firstName: "firstName",
    lastName: "lastName",
    email: "email",
    roleTemplate: "userRoleTemplates.roleTemplate.title",
    isMultipleDays: 'isMultipleDays',
    isPeriodicEnabled: 'isPeriodicEnabled',
    isAddParticipantsEnabled: 'isAddParticipantsEnabled',
    isMultipleScheduleBooking: 'isMultipleScheduleBooking',
    emailNotificationSubscription: 'emailNotificationSubscription',
    optOutInviteSubs: 'optOutInviteSubs',
    sslHandle: 'sslHandle',
    username: 'userName',
    crmUserStatus: 'crmUserStatus'
}

class ManageUsers extends React.Component {

    constructor(props) {
        super(props);
        this.handleClear = throttle(this.handleClear, 1000);
    }

    state = {
        matchedUsersNames: [],
        searchString: [],
        status: true,
        SSOUsersData: [],
        loading: false,
        objectSSOUsersCount: undefined,
        editingKey: "",
        showFilter: false,
        currentPath: null,
        height: `${document.body.clientHeight - 175}px`,
        drawerHeight: `${document.body.clientHeight - 100}px`,
        showEdit: false,
        isReadOnlyEdit: false,
        record: null,
        formLoading: false,
        isEditDisabled: false,
        currentPage: 0
    };

    filterSort = {}
    filterSave = {}
    tableRef = React.createRef()
    flag = 0

    clearCurrentSSOUsers = async () => {
        this.setState({ SSOUsersData: [], currentPage: 0 })
    }

    handleSaveFilter = async (filters) => {
        let { name, email, roleTemplate, sslHandle, crmUserStatus } = filters;
        email = email?.trim();
        sslHandle = sslHandle?.trim()
        const isRTEmpty = !roleTemplate || (roleTemplate && roleTemplate.length === 0);
        const isCRMEmpty = !crmUserStatus || (crmUserStatus && crmUserStatus.length === 0);

        if (!name && !email && isRTEmpty && !sslHandle && isCRMEmpty) {
            if (!this.filterSort.filter) pushMessage(CONFIG.messageType.warning, `Please select some fields to save`)
            else this.handleClear();
            return;
        }
        else {
            this.filterSave = {
                ...this.filterSave,
                filter: {
                    ...(name && {
                        [dataIndexMap.fullName]: {
                            operator: "=",
                            oprVal: name || undefined
                        }
                    }),
                    ...(email && {
                        [dataIndexMap.email]: {
                            operator: "=",
                            oprVal: email || undefined
                        }
                    }),
                    ...(!isRTEmpty && {
                        [dataIndexMap.roleTemplate]: {
                            operator: "=",
                            oprVal: isRTEmpty ? undefined : roleTemplate.join(CONFIG.delimiters.saveFilter)
                        }
                    }),
                    ...(sslHandle && {
                        [dataIndexMap.username]: {
                            operator: "=",
                            oprVal: sslHandle || undefined
                        }
                    }),
                    ...(!isCRMEmpty && {
                        [dataIndexMap.crmUserStatus]: {
                            operator: "=",
                            oprVal: isCRMEmpty ? undefined : crmUserStatus.join(CONFIG.delimiters.saveFilter)
                        }
                    }),
                },
                filterTable: {
                    id: 8,
                    tableName: "User"
                },
            }
            this.props.toggleSaveReportVisible(true)
            this.setState({ saveReport: true, showFilter: false })
        }
    }

    handleFilter = async (filters, isSave) => {
        let { name, email, roleTemplate, sslHandle, crmUserStatus } = filters;
        email = email?.trim();
        sslHandle = sslHandle?.trim()
        const isRTEmpty = !roleTemplate || (roleTemplate && roleTemplate.length === 0);
        const isCRMEmpty = !crmUserStatus || (crmUserStatus && crmUserStatus.length === 0);
        const isFilter = isSave ? "save" : "filter";

        if (!name && !email && isRTEmpty && !sslHandle && isCRMEmpty) {
            pushMessage(CONFIG.messageType.warning, `Please select some fields to ${isFilter}`)
            return;
        }

        else {
            await this.clearCurrentSSOUsers()
            this.filterSort = {
                ...this.filterSort,
                filter: {
                    [dataIndexMap.fullName]: name || undefined,
                    [dataIndexMap.sslHandle]: sslHandle || undefined,
                    [dataIndexMap.email]: email || undefined,
                    [dataIndexMap.roleTemplate]: isRTEmpty ? undefined : roleTemplate.join(CONFIG.delimiters.selectFilter),
                    roleTemplate: isRTEmpty ? undefined : roleTemplate.join(CONFIG.delimiters.selectFilter),
                    [dataIndexMap.crmUserStatus]: isCRMEmpty ? undefined : crmUserStatus.join(CONFIG.delimiters.selectFilter)
                }
            }
            this.props.clearUsers();
            this.props.currentSSOUsersFilter(this.filterSort, 1, 12)
            this.setState({ loading: true, SSOUsersData: [] });
            await this.props.getAllLiteUsers({ filterSort: this.filterSort, isBackendUser: false })
            this.setState({ loading: false, SSOUsersData: this.props.users || [] })
            this.setState({ editingKey: "" });
        }
    }

    getFullName = (item) => {
        var fullName = [item.firstName, item.lastName].join(" ");
        return fullName
    }

    fetchUsersName = searchString => {
        this.setState({ matchedUsersNames: [] });
        fetchNamesBySearchString({ searchString, isBackendUser: false })
            .then((result) => {
                if (result.length !== 0) {
                    delete result.id
                    this.setState({ matchedUsersNames: [...new Set(result.map(this.getFullName))] })
                }
            });
    };

    handleClear = async (refresh) => {
        if (refresh) {
            this.setState({ loading: true, SSOUsersData: [] });
            await this.props.getAllLiteUsers({ filterSort: this.filterSort, isBackendUser: false })
            this.setState({ loading: false, SSOUsersData: this.props.users || [] })
        }
        else {
            await this.clearCurrentSSOUsers();
            this.props.clearUsers();
            this.setState({ loading: true, SSOUsersData: [], matchedUsersNames: [] });
            this.filterSort = { ...this.filterSort, filter: undefined };
            this.filterSave = {};
            this.props.currentSSOUsersFilter(this.filterSort, 1, 12)
            await this.props.getAllLiteUsers({ filterSort: this.filterSort, isBackendUser: false })
            this.setState({ loading: false, SSOUsersData: this.props.users || [] })
        }
    }

    modifyUserRoleTemplates = async () => {
        await this.clearCurrentSSOUsers();
        this.props.clearUsers();
        this.setState({ loading: true, SSOUsersData: [], matchedUsersNames: [] });
        await this.props.getAllLiteUsers({ filterSort: this.filterSort, isBackendUser: false })
        this.setState({ loading: false })
    }

    handleExport = async (fileType) => {
        const { timeZoneName } = this.props;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const users = await getAllUsers({ filterSort: this.filterSort, isBackendUser: false, status: this.state.status });
        if (users?.length > 0 && users !== undefined) {
            exportTable(CONFIG.exportTable.ssoUsers.name, users, timeZoneName, CONFIG.exportTable.ssoUsers.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    handleSave = (record) => {
        this.props.form.validateFields(async (error) => {
            if (error) {
                return;
            }
            this.setState({ formLoading: true })
            const userId = record?.id;
            const isRTUpdated = await this.props.putUser(this.props.form.getFieldsValue(), userId, record);
            if (isRTUpdated) {
                this.modifyUserRoleTemplates()
            }
            this.setState({ formLoading: false })
        });
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.clearUsers()
            this.props.currentSSOUsersFilter(this.filterSort, this.state.currentPage, (this.currentPage * CONFIG.lazyLoadPageSize))
        }
        else {
            this.props.clearUsers();
        }
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        this.cleanup();
    }

    componentDidUpdate() {
        const { openedTabs, activeKey } = this.props;
        const formChanged = this.props.editingKey !== "" && this.props.editingKey !== undefined;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && formChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, formChanged);
        else if (hasUnsavedChanges && !formChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, formChanged);
    }

    async componentDidMount() {
        const { pageNumber, filterSort, isEdit } = this.props;
        this.setState({ currentPath: window.location.pathname })
        if (isEdit) {
            this.setState({ currentPage: pageNumber })
            this.filterSort = filterSort;
            if (filterSort?.filter !== undefined) this.setState({ showFilter: true })
        }
        else {
            this.filterSort = {}
        }

        this.setState({ loading: true });
        await this.props.getAllLiteUsers({ filterSort: this.filterSort, isBackendUser: false })
        this.setState({ loading: false, SSOUsersData: this.props.users || [] })
        batch(() => {
            this.props.fetchRoleTemplates(false);
        });
        this.setState({
            objectSSOUsersCount: this.props.count
        });

        const dropdownLinks = document.querySelectorAll(
            '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
        );

        if (dropdownLinks?.length > 0) {
            dropdownLinks.forEach((link) => {
                link.addEventListener('click', this.handleDatesSortForNeoTable);
            });
        }
    }

    handleDatesSortForNeoTable = (event) => {
        const targetElement = event.target;
        const sortingDateChild = targetElement.querySelector('#sorting-date');
        const arrowChild = ((targetElement.classList?.contains("neo-icon--small") || (targetElement.classList?.contains("neo-icon-arrow-down")) ||
            (targetElement.classList?.contains("neo-icon-arrow-up"))) && ((targetElement?.offsetParent?.firstChild?.innerHTML?.includes("sorting-date")) || (targetElement?.offsetParent?.lastChild?.innerHTML?.includes("sorting-date"))));
        if (sortingDateChild || arrowChild) {
            setTimeout(() => {
                const myElement = document.querySelector('.neo-dropdown__content');
                if (myElement) {
                    myElement.classList.add('sorting-classes');
                    const parentItem = document.querySelector(".sorting-classes");
                    const children = parentItem.children;
                    children[0].textContent = 'Clear Sort';
                    children[1].textContent = 'Older to Newer';
                    children[2].textContent = 'Newer to Older';
                }
            }, 10)
        }
    }

    cleanup = () => {
        const dropdownLinks = document.querySelectorAll(
            '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
        );
        if (dropdownLinks?.length > 0) {
            dropdownLinks.forEach((link) => {
                link.addEventListener('click', this.handleDatesSortForNeoTable);
            });
        }
    };

    getRoleTemplateNamesString = (roleTemplates, delimiter = ' ', defaultString = '') => {
        return roleTemplates?.map(roleTemplate => roleTemplate.title)?.join(delimiter) || defaultString;
    }

    closeFilterDrawer = () => {
        this.setState({ showFilter: false })
    }


    handleCloseFilterDrawer = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }))
    }

    handeEditUser = (record, isEdit, isEditDisabled) => {
        this.setState({
            showEdit: true,
            isReadOnlyEdit: isEdit,
            record,
            isEditDisabled
        })
    }

    handleEditClick = () => {
        this.setState({
            showEdit: true,
            isReadOnlyEdit: false,
        })
    }

    handleChangeSortName = () => {
        setTimeout(() => {
            const myElement = document.querySelector('.neo-dropdown__content');
            if (myElement) {
                myElement.classList.add('sorting-classes');
                const parentItem = document.querySelector(".sorting-classes");
                const children = parentItem.children;
                children[0].textContent = 'Clear Sort';
                children[1].textContent = 'Older to Newer';
                children[2].textContent = 'Newer to Older';
            }
        }, 10)
    }

    render() {
        const { userRoleTemplate, timeZoneName } = this.props;

        const neoColumns = [
            {
                Cell: (a) => {
                    const isEditDisabled = (userRoleTemplate.templateType !== CONFIG.roleTypes.superAdmin)
                    return <span onClick={() => this.handeEditUser(a?.cell?.row?.original, true, isEditDisabled)} style={{ textAlign: "center", color: "#1B77AF", cursor: "pointer" }}>{a?.cell?.row?.original?.fullName}</span>
                },
                Header: "Name",
                sortType: "string",
                accessor: "fullName",
                disableFilters: true,
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.email}</span>
                },
                Header: "Email",
                accessor: "email",
                disableFilters: true,
                sortType: (rowA, rowB) => {
                    const emailA = rowA?.original?.email?.toLowerCase() || '';
                    const emailB = rowB?.original?.email?.toLowerCase() || '';
                    return emailA.localeCompare(emailB);
                }
            },
            {
                Cell: (a) => {
                    const roleTemplateNames = this.getRoleTemplateNamesString(a?.cell?.row?.original?.roleTemplates, ', ');
                    return <span style={{ textAlign: "center" }}>{roleTemplateNames}</span>
                },
                Header: "Role Templates",
                disableFilters: true,
                accessor: "roleTemplates",
                sortType: (rowA, rowB) => {
                    const roleTemplateNamesA = this.getRoleTemplateNamesString(rowA?.original?.roleTemplates, ', ')?.toLowerCase();
                    const roleTemplateNamesB = this.getRoleTemplateNamesString(rowB?.original?.roleTemplates, ', ')?.toLowerCase();
                    if (roleTemplateNamesA < roleTemplateNamesB) {
                        return -1;
                    }
                    if (roleTemplateNamesA > roleTemplateNamesB) {
                        return 1;
                    }
                    return 0;
                }
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.crmUserStatus}</span>
                },
                sortType: "basic",
                Header: "CRM Status",
                accessor: "crmUserStatus",
                disableFilters: true,
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}> {a?.cell?.row?.original?.lastLoginTime ? moment.tz(a?.cell?.row?.original?.lastLoginTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"}</span>
                },
                Header: <span onClick={this.handleChangeSortName} id='sorting-date'>Last Login Time</span>,
                accessor: "lastLoginTime",
                disableFilters: true,
                sortType: (rowA, rowB) => {
                    const startTimeA = moment.tz(rowA?.original?.lastLoginTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    const startTimeB = moment.tz(rowB?.original?.lastLoginTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    if (startTimeA.isBefore(startTimeB)) {
                        return -1;
                    }
                    if (startTimeA.isAfter(startTimeB)) {
                        return 1;
                    }
                    return 0;
                }
            },
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    const isEditDisabled = (userRoleTemplate.templateType !== CONFIG.roleTypes.superAdmin)
                    return (
                        isEditDisabled ? null : <React.Fragment>
                            <Tooltip title="Edit"><NeoIcon aria-label="aria-menu" size="md" style={{ cursor: "pointer", color: "#1B77AF" }} icon="edit" onClick={() => this.handeEditUser(record, false, isEditDisabled)}
                            /></Tooltip>
                        </React.Fragment>
                    )
                },
                Header: "  ",
                disableFilters: true,
                disableSortBy: true
            }
        ]

        const formFields = [
            {
                label: "Name",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "name",
                data: this.state.matchedUsersNames,
                value: this.filterSort?.filter?.fullName,
                colSpan: 24,
            },
            {
                label: "Handle",
                type: CONFIG.formFieldTypes.INPUT,
                key: "sslHandle",
                value: this.filterSort?.filter?.sslHandle,
                colSpan: 24,
            },
            {
                label: "Email",
                type: CONFIG.formFieldTypes.INPUT,
                key: "email",
                value: this.filterSort?.filter?.email,
                colSpan: 24,
            },
            {
                label: "Role Template",
                type: CONFIG.formFieldTypes.SELECT,
                key: "roleTemplate",
                data: this.props.roleTemplates?.map(rT => ({
                    text: rT.title,
                    value: rT.title
                })),
                value: this.filterSort?.filter?.roleTemplate,
                colSpan: 24,
            },
            {
                label: "CRM Status",
                type: CONFIG.formFieldTypes.SELECT,
                key: "crmUserStatus",
                data: CONFIG.crmStatus,
                value: this.filterSort?.filter?.crmUserStatus,
                colSpan: 24,
            }
        ];

        const drawerContainer = document.getElementById('#component-content');
        const shouldFetchDemos = userRoleTemplate && (userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin || userRoleTemplate.templateType === CONFIG.roleTypes.admin)
        return (
            <React.Fragment>
                <div style={{ color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px", marginTop: "28px" }}>
                    Manage SSO Users
                </div>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                    <div style={{ display: "flex", width: "100%", flexDirection: "row-reverse", gap: "24px" }}>
                        <div >
                            <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                                <Button aria-label="export-button" className="export-download-button" icon="chevron-down" variant="secondary">Download</Button>
                            </Dropdown>
                        </div>
                        <div >
                            <NeoIcon aria-label="filter" size="md" className="clickable" onClick={() => this.setState(prevState => ({
                                showFilter: !prevState.showFilter
                            }))} title="Filter" icon={(this.state.showFilter || (!checkIfFilterSortIsEmpty(this.filterSort))) ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </div>
                        <div >
                            <NeoIcon aria-label="aria-menu" size="md" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </div>
                    </div>
                </div>
                <div id="component-content" className="approvals-pagination-row" >
                    <div>
                    </div>
                    <Row>
                        {this.state.showFilter && (
                            <FilterDrawer
                                visible={this.state.showFilter}
                                width='520'
                                handleCloseFilterDrawer={this.handleCloseFilterDrawer}
                                placement="right"
                                drawerContainer={drawerContainer}
                                style={{ zIndex: 9999, }}
                                maskClosable={false}
                                panelHeader="Filters and Export"
                                formFields={formFields}
                                handleClear={this.handleClear}
                                handleFilter={this.handleFilter}
                                handleSave={shouldFetchDemos ? this.handleSaveFilter : undefined}
                                timeZone={this.props.timeZoneName}
                                hideSoftDeleteCheckbox={true}
                                hideSave={false}
                                getSearchData={this.fetchUsersName}
                            />)}
                    </Row>
                    <Row>
                        {
                            this.state.loading ? <Spin style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} spinning={this.state.loading} indicator={antIcon} />
                                : <div className="height-neo-table">
                                    <NeoTable
                                        columns={neoColumns}
                                        data={this.props.users || []}
                                        className="table-actions"
                                        allowColumnFilter={false}
                                        itemsPerPageOptions={[10, 20, 50, 100]}
                                        showRowSeparator
                                        initialStatePageIndex={this.state.currentPage}
                                        handlePageChange={(newPageIndex, newPageSize) => {
                                            if (newPageIndex >= 0) this.setState({ currentPage: newPageIndex })
                                        }}
                                        {...otherFields}
                                    />
                                </div>
                        }
                        {
                            this.state.showEdit && <EditDrawer
                                visible={this.state.showEdit}
                                width='520'
                                handleCloseFilterDrawer={() => this.setState({ showEdit: false, record: null, isEditDisabled: false })}
                                placement="right"
                                drawerContainer={drawerContainer}
                                style={{ zIndex: 9999, }}
                                maskClosable={false}
                                timeZone={this.props.timeZoneName}
                                readOnly={this.state.isReadOnlyEdit}
                                form={this.props.form}
                                handleEditClick={this.handleEditClick}
                                handleSave={this.handleSave}
                                showEdit={true}
                                isEditDisabled={this.state.isEditDisabled}
                                record={this.state.record}
                                filterSort={this.filterSort}
                                title={`${(!this.state.isReadOnlyEdit) ? "Edit" : ""} SSO User Details`}
                            >
                                <Spin spinning={this.state.formLoading} indicator={antIcon}>
                                    <UserDetails roleTemplates={this.props.roleTemplates} form={this.props.form} timezone={this.props.timeZoneName} record={this.state.record} readOnly={this.state.isReadOnlyEdit} />
                                </Spin>
                            </EditDrawer>
                        }
                    </Row>
                </div>
                <br />
                {this.state.saveReport && <SaveReport closeModal={() => this.setState({ saveReport: false })} reportFilter={this.filterSave} />}
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ userRole, roleTemplates, user, tabsLayout }) => {
    return {
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        users: userRole.data,
        count: userRole.count,
        currentPage: userRole.currentPage,
        isFetching: userRole.isFetching,
        editingKey: userRole.editingKey,
        roleTemplates: roleTemplates.all,
        timeZoneName: user.profile?.timezone,
        userDetails: user.profile,
        hasSave: tabsLayout.hasSave,
        filterSort: userRole.filterSort,
        pageNumber: userRole.pageNumber,
        isEdit: userRole.isEdit,
        pageSize: userRole.pageSize,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey
    };
};

const EditableFormTable = Form.create()(ManageUsers);

export default connect(
    mapStateToProps,
    {
        getAllUsers,
        fetchRoleTemplates,
        putUser,
        clearUsers,
        exportTable,
        currentSSOUsersFilter,
        toggleHasUnsavedChangesFlag,
        toggleSaveReportVisible,
        getAllLiteUsers
    }
)(EditableFormTable);

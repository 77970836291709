import { Popover, Avatar, Typography } from "antd";
import { Icon } from "@avaya/neo-react";
const { Text } = Typography;

export const getUserInformationCard = (userObject, title) => {
    const avatarStyle = {
        backgroundColor: '#dceeff', // You can choose any background color you like
        verticalAlign: 'middle',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '50px',
        marginRight: '20px'

    };

    return <Popover content={
        <div className="contact-card">
            {userObject?.profilePictureLink
                ? <img src={userObject?.profilePictureLink} alt="Profile" className="profile-image" />
                :
                <Avatar style={avatarStyle} size={100}>
                    <Text strong>
                        {userObject?.firstName ? userObject?.firstName.charAt(0).toUpperCase() : ''}
                        {userObject?.firstName ? userObject?.lastName.charAt(0).toUpperCase() : ''}
                    </Text>
                </Avatar>}
            <div className="contact-details">
                <span className="heading">{userObject?.fullName}</span><br />
                <span>{userObject?.userName}</span><br /><br />
                <div className="supportLinks">
                    <Icon aria-label="aria-menu" className="right-spacing" icon="email" style={{ fontSize: "20px" }}></Icon>{userObject?.email}<br />
                    {userObject?.company?.company && <><Icon aria-label="aria-menu" className="right-spacing" icon="business" style={{ fontSize: "20px" }}></Icon>{userObject?.company?.company}<br /></>}
                    {userObject?.region?.name && <><Icon aria-label="aria-menu" className="right-spacing" icon="address" style={{ fontSize: "20px" }}></Icon>{userObject?.region?.name}<br /></>}
                    {userObject?.region?.name && <><Icon aria-label="aria-menu" className="right-spacing" icon="global" style={{ fontSize: "20px" }}></Icon>{userObject?.timezone}</>}
                </div>
            </div>
        </div>} trigger="hover" placement="right">
        <span className="hoverable-text">{title}</span>
    </Popover>

}
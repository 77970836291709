import React, { Component } from 'react'
import { Modal, Button, Icon } from 'antd';
import CONFIG from '../../config';
export default class WarningModal extends Component {
    render() {
        const { modalOpen, handleOk, handleCancel } = this.props;
        return (
            <Modal
                title={<p><span style={{ color: "darksalmon" }}><Icon aria-label="aria-menu" type="warning" /></span> Warning</p>}
                visible={modalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="primary-action-button-filled" type="primary" onClick={handleOk}>
                        Ok
                    </Button>,
                ]}
            >
                <p>{CONFIG.warningMessages.changesLost}</p>
            </Modal>
        )
    }
}

import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider, Icon, Popover, Spin, Typography, Modal, Tooltip } from 'antd';
import moment from 'moment-timezone';
import { Button } from '@avaya/neo-react'
import ROUTE from "../../components/ContentRouteConfig";
import { Icon as NeoIcon, Tooltip as NeoTooltip, Chip } from "@avaya/neo-react";
import { LoadingOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd5'
import {
    toggleIsActiveSchedules,
    toggleIsFetchingPreviousSchedules,
    toggleIsReschedule,
    updateUserSchedulesParams,
    toggleIsFetchingMaintenanceSchedule,
    fetchSchedulesOverDuration,
    toggleIsResourceReschedule,
    openLinkInNewTab,
    handleOnClickAction,
    deleteDashboardSchedule,
    toggleIsFetchingDashboardSchedules,
    optOutSchedule,
    fetchDashboardActiveSchedules,
    retainMyScheduleFilter,
    deleteBatchDemoSchedule,
    optOutBatchDemoSchedule,
    clearDashboardSchedules
} from '../../actions';
import CONFIG from '../../config';
// import NoContent from '../common/NoContent';
import DemoStatusTag from '../common/DemoStatusTag';
import { getDemoCardWarnings } from '../../utils/dashboardCardMessage';
import { renderStatusTag, getApprovalStatus } from '../../utils/dashboard';
import ScheduleDeleteConfirmation from '../common/ScheduleDeleteConfirmation';
import ScheduledDemoViewDetails from '../ManageScheduledDemo/ScheduledDemoViewDetails';
const { Text } = Typography;


class DashboardSchedulesCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteOptionsVisible: false,
            recordToDelete: undefined,
            viewScheduleDetailsRecordId: undefined,
            viewScheduleDetails: false,
            deleteModalVisible: false,
            isBatchDelete: false,
            isLoggedInUserCreator: false,
            hasParticipants: false
        }
    }

    handleUpdate = async (schedule, type, isBatchEdit) => {
        if (type !== CONFIG.demoTypes.standardDemo && !schedule?.isMaintenance) {
            this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/lab/${schedule?.id}/edit/isReschedule=${false}/fromManageSide=${false}`)
            return
        }

        if (type === CONFIG.demoTypes.standardDemo && !schedule?.isMaintenance) {
            this.props.handleDemoScheduleUpdate(schedule.id, schedule, false, true, false, isBatchEdit);
        }
        else {
            await this.props.handleOnClickAction({
                component: CONFIG.editComponentRoute.scheduleMaintenance,
                tabTitle: schedule?.title,
                recordId: schedule?.id
            }
            )
        }
    }

    renderScheduleDetails = () => {
        return (
            <Modal
                className='extra-wide-modal'
                visible={this.state.viewScheduleDetails}
                onCancel={() => this.setState({ viewScheduleDetailsRecordId: undefined, viewScheduleDetails: false })}
                footer={[
                    <Button id="view-details-close" className="primary-action-button-filled" key="back" onClick={() => this.setState({ viewScheduleDetailsRecordId: undefined, viewScheduleDetails: false })}>
                        Close
                    </Button>]}

            >
                <ScheduledDemoViewDetails
                    fromDashboard={true}
                    scheduleId={this.state.viewScheduleDetailsRecordId}
                />
            </Modal>
        )
    }

    renderDeleteOptions = () => {
        return (
            <Modal
                width={'25%'}
                title="Confirm Delete?"
                visible={this.state.deleteOptionsVisible}
                footer={null}
                onCancel={() => this.setState({ deleteOptionsVisible: false })}
            >
                <div className="delete-option-modal">

                    <div className='schedule-delete-button'><Button
                        className="delete-option-button-email primary-action-button-filled"
                        onClick={this.handleRemove} > Delete for me
                    </Button><br />
                        <Button
                            className="delete-option-button-email primary-action-button-filled"
                            onClick={this.handleScheduleDelete} > Delete for Everyone
                        </Button>
                    </div><br />
                    <Button
                        className="delete-cancel-button cancel-button"
                        onClick={() => this.setState({ deleteOptionsVisible: false })} > Cancel
                    </Button>
                </div>
            </Modal>)
    }

    handleDeleteSharedSchedule = (record) => {
        this.setState({
            recordToDelete: record,
            deleteOptionsVisible: true
        })
    }

    handleCloseDeleteSharedSchedule = () => {
        this.setState({
            recordToDelete: undefined,
            deleteOptionsVisible: false
        })
    }

    handleScheduleDelete = () => {
        const record = this.state.recordToDelete
        const isDemoSchedule = !record?.isMaintenance;
        this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.CARD });
        this.handleCancel(record, isDemoSchedule, record?.demo?.demoType);
        this.handleCloseDeleteSharedSchedule();
    }

    handleDelete = (isDeleteForEveryone) => {
        const { recordToDelete, hasParticipants, isBatchDelete, isLoggedInUserCreator } = this.state;
        const record = recordToDelete;
        const isDemoSchedule = !record?.isMaintenance;
        if (!isBatchDelete) {
            if (!hasParticipants) {
                this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.CARD });
                this.handleCancel(record, isDemoSchedule, record?.demo?.demoType);
                this.handleDeleteModalClose();
            }
            else {
                if (!isDeleteForEveryone && !isLoggedInUserCreator) {
                    this.handleRemove();
                }
                else {
                    const demoType = record?.demo?.demoType
                    this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.CARD });
                    this.handleCancel(record, isDemoSchedule, demoType);
                    this.handleDeleteModalClose();
                }
            }
        }
        else {
            this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.CARD });
            this.handleDemoBatchScheduleCancel(record?.batchNumber);
            this.handleDeleteModalClose();
        }
    }

    handleDeleteModalClose = async () => {
        await this.setState({
            deleteModalVisible: false,
            recordToDelete: undefined,
            isBatchDelete: false,
            hasParticipants: false,
            isLoggedInUserCreator: false
        })
    }

    handleRemove = () => {
        const record = this.state.recordToDelete
        const { user } = this.props;
        const eapUsers = record?.participants?.eapUsers?.filter((eachUser) => eachUser?.id !== user?.id);
        const schedule = {
            createdBy: record?.createdBy,
            demo: record?.demo,
            demoPurpose: record?.demoPurpose,
            endTime: record?.endTime,
            batchNumber: record?.batchNumber,
            endTimeBuffer: record?.endTimeBuffer,
            id: record?.id,
            isOneTime: record?.isOneTime,
            isPreRegistration: record?.isPreRegistration,
            purposeAttributesSchedule: record?.purposeAttributesSchedule,
            resource: record?.resource,
            startTime: record?.startTime,
            startTimeBuffer: record?.startTimeBuffer,
            userAnswers: record?.userAnswers,
            participants: {
                ...record.participants,
                eapUsers
            } || null
        }

        this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.CARD });
        this.handleOptout(schedule, record?.demoType);
        this.handleCloseDeleteSharedSchedule();
        this.handleDeleteModalClose();
    }

    handleOptout = async (record, demoType) => {
        this.props.toggleIsFetchingDashboardSchedules(true);
        await this.props.optOutSchedule(record, demoType);
        await this.props.fetchDashboardActiveSchedules();
        this.props.toggleIsFetchingDashboardSchedules(false);
    }

    handleBatchOptout = async (batchNumber) => {
        this.props.toggleIsFetchingDashboardSchedules(true);
        await this.props.optOutBatchDemoSchedule(batchNumber);
        await this.props.fetchDashboardActiveSchedules();
        this.props.toggleIsFetchingDashboardSchedules(false);
        this.handleDeleteModalClose();
    }

    handleCancel = async (schedule, isDemoSchedule, demoType) => {
        if (isDemoSchedule) {
            this.props.toggleIsFetchingDashboardSchedules(true)
            await this.props.deleteDashboardSchedule(schedule?.id, demoType)
        }
        else this.props.handleMaintenanceCancel(schedule.id);
    }

    handleDemoBatchScheduleCancel = async (batchNumber) => {
        // fromEmail && await this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.TABLE })
        this.props.toggleIsFetchingDashboardSchedules(true)
        await this.props.deleteBatchDemoSchedule(batchNumber)
        await this.props.clearDashboardSchedules();
        await this.props.fetchDashboardActiveSchedules();
        this.props.toggleIsFetchingDashboardSchedules(false)
    }

    handleSelectSchedule = (selectedDemo) => {
        this.props.openLinkInNewTab(selectedDemo.demo?.title, `/solutions/demo/${selectedDemo.demo?.id}`)
    }

    componentDidMount() {
        this.props.toggleIsActiveSchedules(true);
        this.props.toggleIsFetchingPreviousSchedules(false);
    }


    isAdminModified = (schedule) => {
        const {
            // createdBy, updatedBy, adminCreatedBy, 
            oldStartTime, oldEndTime, oldResource, resource, startTime, endTime, isAdminUpdatedSchedule, isAdminCreatedSchedule } = schedule;
        const { timeZoneName } = this.props;
        // const participants = schedule?.participants !== null && schedule?.participants?.eapUsers
        const modifiedContent = (
            <div className="maintenance-card-demo-list">
                {<React.Fragment>
                    {oldResource && <div>&#8226; Resource changed from <Text strong>{oldResource?.name}</Text> to <Text strong>{resource?.name}</Text></div>}
                    {(!!oldStartTime && !!oldEndTime) && <div>&#8226; Schedule time changed from <Text strong>
                        {moment.tz(oldStartTime ? oldStartTime : startTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)} - {moment.tz(oldEndTime ? oldEndTime : endTime, timeZoneName).format(CONFIG.dateFormats.userTime)}
                    </Text> to <Text strong>
                            {moment.tz(startTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)} - {moment.tz(endTime, timeZoneName).format(CONFIG.dateFormats.userTime)}
                        </Text>
                    </div>}
                </React.Fragment>}
            </div>
        );

        if (isAdminCreatedSchedule)
            return <span className='admin-schedule-tag'><Chip variant="info">Scheduled by Admin</Chip><br /></span>
        else if (isAdminUpdatedSchedule)
            return <span className='admin-schedule-tag'>
                {(!!oldResource || !!oldStartTime || !!oldEndTime) ? <Popover
                    placement="right"
                    content={modifiedContent}
                    trigger="hover">
                    <Chip variant="info">Updated by Admin </Chip>
                </Popover> : <Chip variant="info">Updated by Admin </Chip>}
                <br /></span>
    }


    getCardRibbonColor = (schedule, demoResourceDCStatus) => {
        const isMaintenance = schedule?.isMaintenance;

        if (isMaintenance) {
            return CONFIG.tagColors.pending
        }

        else {
            const buildStatus = schedule?.buildStatus;
            const { demoType } = schedule?.demo
            if (demoType === CONFIG.demoTypes.standardDemo) {
                return CONFIG.demoStatus.tagBorderColors[demoResourceDCStatus?.status]
            }
            else if (buildStatus !== null) {
                if (buildStatus === "Assigned" || buildStatus === "New") {
                    return CONFIG.demoBuildStatusCardRibbonColors[buildStatus]
                }
                else return CONFIG.demoBuildStatusCardRibbonColors[buildStatus]
            }
            else if (schedule?.status === CONFIG.approvalStatusTypes.pendingRequests.value) {
                return "#b9ceff"
            }
            else if (schedule?.status?.includes(CONFIG.approvalStatusTypes.approvedRequests.value))
                return CONFIG.tagColors.appoved
            else if (schedule?.status === CONFIG.approvalStatusTypes.declinedRequests.value)
                return CONFIG.tagColors.declined
            else if (schedule?.status === CONFIG.approvalStatusTypes.expiredRequests.value)
                return CONFIG.tagColors.expired
        }

    }

    renderDemoCards = (isMaintenanceSection) => {
        const { timeZoneName, allDataCenters, upcomingSchedules, upcomingMaintenances, todaySchedules, user, section, isMultipleScheduleBooking } = this.props;
        let scheduleCards = [];
        // JSX for Maintenance Card
        if (isMaintenanceSection) {
            if (upcomingMaintenances) {
                const length = (upcomingMaintenances.length > CONFIG.maintenenceCardSize) ? CONFIG.maintenenceCardSize : upcomingMaintenances.length;
                for (let i = 0; i < (this.showViewMore ? 2 : length); i++) {
                    const startDateTime = moment.tz(upcomingMaintenances[i].startTime, timeZoneName).format(CONFIG.dateFormats.userDateTime3);
                    const endDateTime = moment.tz(upcomingMaintenances[i].endTime, timeZoneName).format(CONFIG.dateFormats.userDateTime);
                    let card = (
                        <>
                            <Row id={`dashboard-maintenance-card-${i + 1}`}>
                                <Col span={24} className='test-div dashboard-schedule-card font' >

                                    <span >
                                        <span>
                                            <span className="dashboard-maintenance-title">{upcomingMaintenances[i]?.title}
                                            </span>
                                        </span>
                                        {upcomingMaintenances[i]?.maintenanceRelations?.length > 0 && <Popover
                                            placement="right"
                                            content={<div style={{ color: "black", fontSize: "13px" }} className="maintenance-card-demo-list font">{upcomingMaintenances[i].maintenanceRelations?.map((eachItem, index) => (
                                                <React.Fragment key={index}>
                                                    <div> Demo :  {eachItem?.demo?.title || "-"} </div>
                                                    <div> Data Center(s) : {eachItem?.dataCenter?.title || "ALL"} </div>
                                                    <div>Resource(s) : {eachItem?.resource?.name || "ALL"} </div>
                                                    {upcomingMaintenances[i]?.maintenanceRelations?.length > 1 && <Divider />}
                                                </React.Fragment>))}</div>}
                                            title="List of items under Maintenance" trigger="click">
                                            &nbsp;<span className="small-text"> <Icon aria-label="aria-menu" type="info-circle" className="text-color-primary" width={1} /></span>
                                        </Popover>}
                                    </span>
                                    <div className='dashboard-schedule-card-details'>
                                        <span>{<div className="demo-card-timings">
                                            <Row className="top-spacing-6">
                                                <Col span={24}>
                                                    <span className=' bottom-spacing-2' style={{ color: "#5E5E5E", fontSize: "14px" }}>
                                                        {startDateTime} - {endDateTime}</span>
                                                </Col>
                                            </Row>
                                        </div >}

                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            <Divider style={{ marginLeft: "16px" }} className="dashboard-schedule-divider" />
                        </>
                    )
                    scheduleCards.push(card);
                }
            }
            return scheduleCards;
        }

        // JSX for Schedule Demo Card
        else {
            const schedulesCard = section === "Today"
                ? todaySchedules : section === "Upcoming"
                    ? upcomingSchedules
                    : (todaySchedules ?? []).concat(upcomingSchedules ?? [])
            this.showViewMore = (section === "All" && (schedulesCard?.length > 3));

            if (timeZoneName && schedulesCard) {
                for (let i = 0; i < (this.showViewMore ? 3 : schedulesCard?.length); i++) {
                    let card;
                    let tooltipMessages, cardRibbonColor, showEditOrRescheduleButton, showCheckAvailbility, disableEditButtonAfterApproval, isDeclinedOrExpired, isEditDisabled, showReschedule, demoResourceDCStatus, isBufferTimeOver, hasParticipants, scheduleCreator, isDemoActive, DemoStatusTooltipMessage, resourceTooltipMessage, demoUpdatedTime, demoBuildId, isLoggedInUserCreator, scheduleParticipants, bufferStartTime, bufferEndTime;


                    const demoType = schedulesCard[i].demo?.demoType;
                    const isDemoSchedule = !schedulesCard[i]?.isMaintenance;
                    const startDateTime = moment.tz(schedulesCard[i].startTime, timeZoneName).format(CONFIG.dateFormats.userDateTime3);
                    const endDateTime = moment.tz(schedulesCard[i].endTime, timeZoneName).format(CONFIG.dateFormats.userDateTime);
                    const adminModifiedStatus = this.isAdminModified(schedulesCard[i]);
                    const batchNumber = schedulesCard[i]?.batchNumber;
                    const isBatchSchedule = batchNumber > 0 && schedulesCard[i]?.scheduledResources !== null;
                    if (isDemoSchedule) {
                        isDemoActive = schedulesCard[i].demo?.isActive
                        demoUpdatedTime = schedulesCard[i].demo?.updatedTime ? moment.tz(schedulesCard[i].demo?.updatedTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : undefined
                        const bufferStart = schedulesCard[i]?.startTimeBuffer || 0;
                        const bufferEnd = schedulesCard[i]?.endTimeBuffer || 0;
                        bufferStartTime = bufferStart > 0 ? moment.tz(schedulesCard[i].startTime, timeZoneName).subtract(bufferStart, 'm') : moment.tz(schedulesCard[i].startTime, timeZoneName);
                        bufferEndTime = bufferEnd > 0 ? moment.tz(schedulesCard[i].endTime, timeZoneName).add(bufferEnd, 'm') : moment.tz(schedulesCard[i].endTime, timeZoneName);
                        const isCreator = schedulesCard[i]?.createdBy?.id === user?.id;
                        hasParticipants = schedulesCard[i]?.participants !== null;
                        scheduleCreator = hasParticipants && [...schedulesCard[i]?.participants?.eapUsers, ...schedulesCard[i]?.participants?.extUsers]?.filter(par => par?.isCreator === true)?.[0];
                        isLoggedInUserCreator = scheduleCreator?.id === user?.id
                        scheduleParticipants = hasParticipants && [...schedulesCard[i]?.participants?.eapUsers, ...schedulesCard[i]?.participants?.extUsers]?.filter(par => par?.isCreator !== true);

                        tooltipMessages = getDemoCardWarnings(schedulesCard[i])
                        DemoStatusTooltipMessage = tooltipMessages[0]
                        resourceTooltipMessage = tooltipMessages[1]
                        showEditOrRescheduleButton = isDemoSchedule ? tooltipMessages?.[2] : true

                        if (demoType === CONFIG.demoTypes.standardDemo) {


                            const activeDataCenters = allDataCenters?.filter(datacenter => datacenter.active === true) || [];
                            const demoDCStatuses = activeDataCenters?.map(dataCenter => schedulesCard[i].demo?.demoDCStatuses?.find(demoDCStatus => demoDCStatus.dataCenter.id === dataCenter.id) || { dataCenter, status: 2 });
                            const scheduledDC = demoDCStatuses?.find((dataCenters) => {
                                return dataCenters?.dataCenter?.id === schedulesCard[i]?.resource?.dataCenter?.id
                            })
                            let count = 0, dataCenter;
                            demoDCStatuses.forEach((item) => {
                                if (item.status === CONFIG.demoStatus.dataCenterTypes.green || item.status === CONFIG.demoStatus.dataCenterTypes.yellow) {
                                    count += 1;
                                    dataCenter = item;
                                }
                            })
                            showCheckAvailbility = (count > 1 || (count === 1 && (dataCenter?.dataCenter?.id !== scheduledDC?.dataCenter?.id)))
                            showReschedule = (count === 1 && (dataCenter?.dataCenter?.id === scheduledDC?.dataCenter?.id))
                            demoResourceDCStatus = demoDCStatuses.find(demoDCStatus => demoDCStatus?.dataCenter?.id === schedulesCard[i].resource?.dataCenter?.id)
                        }
                        else {
                            demoBuildId = schedulesCard[i]?.demoBuildId;
                            isBufferTimeOver = !(moment().tz(timeZoneName) < bufferStartTime)
                            // isBufferTimeStarted = !(moment().tz(timeZoneName) < bufferStartTime)
                            disableEditButtonAfterApproval = (schedulesCard[i]?.status?.includes(CONFIG.approvalStatusTypes.approvedRequests.value) || getApprovalStatus(schedulesCard[i]?.scheduleApproval))
                            isDeclinedOrExpired = demoType === CONFIG.demoTypes.labPocDemo
                                ? (schedulesCard[i]?.status === CONFIG.approvalStatusTypes.declinedRequests.value || schedulesCard[i]?.status === CONFIG.approvalStatusTypes.expiredRequests.value)
                                : false

                        }
                        isEditDisabled = isDemoSchedule && (demoBuildId || !showEditOrRescheduleButton || disableEditButtonAfterApproval || schedulesCard[i].demoBuildId || (isBufferTimeOver && (disableEditButtonAfterApproval || demoType === CONFIG.demoTypes.restfulApiDemo)))
                        cardRibbonColor = this.getCardRibbonColor(schedulesCard?.[i], demoResourceDCStatus)

                        const items = [
                            {
                                key: 'details',
                                label: (
                                    <span className='full-width-options'
                                        id="dashboard-schedule-view-details"
                                        onClick={() => this.setState({
                                            viewScheduleDetails: true,
                                            viewScheduleDetailsRecordId: schedulesCard?.[i]?.id
                                        })}
                                    ><NeoIcon aria-label="menu-aria" icon="alignment-left" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>View Details</span>
                                    </span>
                                ),
                            },
                            {
                                key: 'edit',
                                label: (batchNumber > 0 && isCreator && isMultipleScheduleBooking) ? <span id="dashboard-schedule-edit"><NeoIcon aria-label="menu-aria" icon="edit" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Edit</span></span> : (
                                    <span className='full-width-options'
                                        id="dashboard-schedule-edit"
                                        disabled={isEditDisabled}
                                        onClick={() => !isEditDisabled && this.handleUpdate(schedulesCard[i], demoType, false)}
                                    >
                                        <span className='edit-dropdown'
                                            title={isDemoSchedule && demoType !== CONFIG.demoTypes.standardDemo ? (isBufferTimeOver && ((disableEditButtonAfterApproval && !isDeclinedOrExpired) || demoType === CONFIG.demoTypes.restfulApiDemo)) ? "Schedule started" : disableEditButtonAfterApproval ? "Approval Process Started" : null : null}
                                        ><NeoIcon aria-label="menu-aria" icon="edit" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Edit</span></span>
                                    </span>
                                ),
                                children: (batchNumber > 0 && isCreator && isMultipleScheduleBooking) ? [
                                    {
                                        key: 'occurence',
                                        label: <span className='full-width-options'
                                            disabled={isEditDisabled}
                                            onClick={() => !isEditDisabled && this.handleUpdate(schedulesCard[i], demoType, false)}>
                                            Occurence
                                        </span>,
                                    },
                                    {
                                        key: 'series',
                                        label: <span className='full-width-options'
                                            disabled={isEditDisabled}
                                            onClick={() => !isEditDisabled && this.handleUpdate(schedulesCard[i], demoType, true)}>
                                            Series
                                        </span>,
                                    },
                                ] : null,
                                disabled: isEditDisabled
                            },
                            {
                                key: 'delete',
                                label: ((isBatchSchedule && isMultipleScheduleBooking && (!hasParticipants || isLoggedInUserCreator)) ?
                                    <span className='full-width-options'
                                        id="dashboard-schedule-delete"
                                    ><NeoIcon aria-label="menu-aria" icon="backspace" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span>Delete</span></span>
                                    :
                                    <span className='full-width-options'
                                        id="dashboard-schedule-delete"
                                        onClick={async () => await this.setState({ recordToDelete: schedulesCard[i], isBatchDelete: false, deleteModalVisible: true, isLoggedInUserCreator: isLoggedInUserCreator, hasParticipants: hasParticipants })}
                                    ><NeoIcon aria-label="menu-aria" icon="backspace" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span>Delete</span></span>),
                                children: (isBatchSchedule && isMultipleScheduleBooking && (!hasParticipants || isLoggedInUserCreator)) ? [
                                    {
                                        key: 'occurence',
                                        label: <span
                                            className='full-width-options'
                                            onClick={async () => await this.setState({ recordToDelete: schedulesCard[i], isBatchDelete: false, deleteModalVisible: true, isLoggedInUserCreator: isLoggedInUserCreator, hasParticipants: hasParticipants })}
                                        ><span>Occurence</span></span>,
                                    },
                                    {
                                        key: 'series',
                                        label: <span
                                            className='full-width-options'
                                            onClick={async () => await this.setState({ recordToDelete: schedulesCard[i], isBatchDelete: true, deleteModalVisible: true, isLoggedInUserCreator: isLoggedInUserCreator, hasParticipants: hasParticipants })}
                                        ><span>Series</span></span>,
                                    },
                                ] : null,
                            }
                        ];

                        card = (
                            <>
                                <Row id={`dashboard-schedule-card-${i + 1}`}>
                                    <Col className='test-div dashboard-schedule-card' style={{ borderColor: `${cardRibbonColor}` }}>
                                        <Row>
                                            {hasParticipants && <span> <Popover
                                                placement="right"
                                                content={<React.Fragment>
                                                    <p><Text underline>Creator</Text><br />{`${scheduleCreator?.fullName} (${scheduleCreator?.email})`}</p>
                                                    <p><Text underline>Participants</Text><br />{scheduleParticipants?.map(par => (<div>{par?.fullName} ( {par?.email} )<br /></div>))}</p>
                                                </React.Fragment>}
                                                trigger="hover"
                                            >
                                                <NeoIcon aria-label="menu-aria" icon="user-conference" size='md' style={{ paddingRight: "5px" }} />
                                            </Popover></span>}
                                            {adminModifiedStatus}
                                            <span>{
                                                <span
                                                    id={`${section?.toLowerCase()}-dashboard-schedule-demo-title-${i + 1}`}
                                                    className={isDemoActive ? "hover-link medium-text bold dashboard-schedules-demo-title" : undefined}
                                                    onClick={() => isDemoActive && this.handleSelectSchedule(schedulesCard[i])}>
                                                    {schedulesCard[i].demo?.title}
                                                </span>
                                                // Maintenance Card
                                            }
                                                {isDemoSchedule && DemoStatusTooltipMessage?.length > 0 && < NeoTooltip
                                                    label={(this.props.roleTemplate?.templateType === CONFIG.roleTypes.superAdmin && DemoStatusTooltipMessage[0]?.includes("access")) ? CONFIG.warningMessages.solutionInactive : DemoStatusTooltipMessage.length > 1 ? <>{DemoStatusTooltipMessage?.map((message, index) => (<div key={index}>&#8226;&nbsp;{message}</div>))}</> : DemoStatusTooltipMessage[0]}>
                                                    &nbsp;< Icon type="warning" theme="twoTone" twoToneColor="#ff9000" />
                                                </NeoTooltip>}


                                                <>
                                                    <span className="dashboard-schedule-more-options float-right">
                                                        <Dropdown
                                                            // overlayClassName='my-schedules-table-actions-menu'
                                                            trigger="click"
                                                            menu={{ items }}

                                                            placement="bottomLeft"
                                                            arrow
                                                            className='dashboard-action-dropdown'
                                                        >
                                                            <NeoIcon aria-label="menu-aria" icon="ellipses-vertical" id={`${section?.toLowerCase()}-dashboard-schedules-actions-${i + 1}`} className='pointer' style={{ fontSize: "19px", color: "#1B77AF" }} />
                                                        </Dropdown>

                                                    </span>
                                                    {batchNumber > 0 && <span className='float-right'>
                                                        <Tooltip title={CONFIG.batchInfo}><NeoIcon aria-label="menu-aria" style={{ color: "#5e5e5e" }} icon="queue-internal-call" size='md' /></Tooltip>
                                                    </span>}
                                                    <span className='dashboard-card-status-tag float-right'>
                                                        {isDemoSchedule ? demoType === CONFIG.demoTypes.standardDemo
                                                            ?
                                                            < DemoStatusTag
                                                                demoResourceDCStatus={demoResourceDCStatus}
                                                                timezone={this.props.timeZoneName}
                                                                scheduleId={schedulesCard[i].id}
                                                                scheduleObject={schedulesCard[i]}
                                                                handleReschedule={this.props.handleDemoScheduleUpdate}
                                                                handleUpdate={this.handleUpdate}
                                                                disabledReschedule={showEditOrRescheduleButton}
                                                                toggleIsReschedule={this.props.toggleIsReschedule}
                                                                toggleIsResourceReschedule={this.props.toggleIsResourceReschedule}
                                                                errorMessage={DemoStatusTooltipMessage}
                                                                roleTemplate={this.props.roleTemplate}
                                                                showCheckAvailbility={showCheckAvailbility}
                                                                showReschedule={showReschedule}
                                                                itemId={i + 1}
                                                                section={section}
                                                            />
                                                            : (demoType === CONFIG.demoTypes.labPocDemo || demoType === CONFIG.demoTypes.restfulApiDemo) &&
                                                            renderStatusTag(schedulesCard[i], false, i + 1)
                                                            : undefined
                                                        }</span >


                                                </>
                                            </span>
                                        </Row>
                                        <div className='dashboard-schedule-card-details'>
                                            <span>{<div className="demo-card-timings">
                                                <Row className="top-spacing-6">
                                                    <Col span={24} className='bold bottom-spacing-2 font-14px'>
                                                        <span>

                                                            {(bufferStart > 0 || bufferEnd > 0) ? <Tooltip
                                                                className='custom-tooltip'
                                                                title={<>
                                                                    <div>Buffer Start Time  - {bufferStartTime?.format(CONFIG.dateFormats.userDateTime)}</div>
                                                                    {demoType === CONFIG.demoTypes.standardDemo && <div>Buffer End Time  - {bufferEndTime?.format(CONFIG.dateFormats.userDateTime)}</div>
                                                                    }</>}>{startDateTime} - {endDateTime}
                                                            </Tooltip> :
                                                                <span>{startDateTime} - {endDateTime} </span>}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                {
                                                    isDemoSchedule && demoType === CONFIG.demoTypes.standardDemo && <span className="demoApprovedStatus">
                                                        <span className="bold">Resource: </span>
                                                        <span id={`${section?.toLowerCase()}-dashboard-schedule-resource-name-${i + 1}`}>{schedulesCard[i].resource?.name}
                                                            {resourceTooltipMessage?.length > 0 && < NeoTooltip
                                                                label={resourceTooltipMessage.length > 1 ? <>{resourceTooltipMessage?.map((message, index) => (<div key={index}>&#8226;&nbsp;{message}</div>))}</> : resourceTooltipMessage[0]}>
                                                                &nbsp;< Icon type="warning" theme="twoTone" twoToneColor="#ff9000" />
                                                            </NeoTooltip>}
                                                        </span><br />
                                                    </span>
                                                }
                                                {
                                                    isDemoSchedule && demoUpdatedTime && <span>
                                                        <span className="bold">Demo Last Updated: </span>
                                                        <span >{demoUpdatedTime}</span><br />
                                                    </span>
                                                }
                                                {
                                                    demoBuildId && <span>
                                                        <span className="bold">Build ID: </span>
                                                        <span>{demoBuildId}</span>
                                                    </span>
                                                }

                                            </div >}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Divider style={{ marginLeft: "16px" }} className="dashboard-schedule-divider" />
                            </>
                        );
                    }
                    scheduleCards.push(card);
                }

            }

        }
        return scheduleCards;
    }

    render() {
        const { upcomingSchedules, todaySchedules, fetchingDashboardSchedules, upcomingMaintenances, section, isAdmin } = this.props;
        const scheduleCount = section === "Today" ? todaySchedules?.length : upcomingSchedules?.length
        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        return (
            <Spin indicator={antIcon} spinning={fetchingDashboardSchedules} >
                {section === "Maintenance" ?
                    <div>
                        <span className='dashboard-schedules-category-heading'><NeoIcon aria-label="menu-aria" icon="configure" style={{ fontSize: "20px", paddingRight: "10px" }} />
                            Upcoming Maintenance</span>
                        {!upcomingMaintenances?.length > 0
                            ? <><div className='dashboard-no-schedules'><NeoIcon aria-label="menu-aria" icon="info" size='md' />No maintenances Scheduled</div><div style={{ marginLeft: "6px" }}>
                                <Divider style={{ marginBottom: "0px" }} />
                            </div></>
                            : <div>
                                {this.renderDemoCards(true)}
                                {(upcomingMaintenances?.length >= CONFIG.maintenenceCardSize) && <div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
                                    <Button onClick={() => {
                                        this.props.openLinkInNewTab('Schedule Maintenance', ROUTE.maintenance)
                                    }} variant="secondary" size="compact">
                                        View All Upcoming Maintenances
                                    </Button>
                                </div>}
                            </div>
                        }
                    </div>
                    :
                    <div>
                        {!isAdmin ?
                            <div >
                                {scheduleCount > 0 && <span className='dashboard-schedules-category-heading'>
                                    <NeoIcon aria-label="menu-aria" icon="calendar" style={{ fontSize: "20px", paddingRight: "10px" }} />{section === "Today" ? "Today's Schedules" : "Upcoming Schedules"}</span>}
                                {this.renderDemoCards()}
                            </div>
                            : undefined
                        }
                        {isAdmin &&
                            <div>
                                {
                                    (todaySchedules?.length > 0 || upcomingSchedules?.length > 0)
                                        ? <div>
                                            <span className='dashboard-schedules-category-heading'><NeoIcon aria-label="menu-aria" icon="calendar" style={{ fontSize: "20px", paddingRight: "10px" }} />Upcoming Schedules</span> {this.renderDemoCards()}
                                            {<div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
                                                <Button onClick={() => {
                                                    this.props.openLinkInNewTab('Schedules', ROUTE.mySchedules);
                                                    this.props.retainMyScheduleFilter({ filter: { isActive: true } }, true)
                                                }} variant="secondary" size="compact">
                                                    View All Upcoming Schedules
                                                </Button>
                                            </div>}
                                        </div>
                                        : <div>
                                            <span className='dashboard-schedules-category-heading'><NeoIcon aria-label="menu-aria" icon="calendar" style={{ fontSize: "20px", paddingRight: "10px" }} />Upcoming Schedules</span> {this.renderDemoCards()}
                                            <div className='dashboard-no-schedules'><NeoIcon aria-label="menu-aria" icon="info" size='md' />No demos scheduled</div>
                                            <Divider style={{ marginBottom: "0px" }} />

                                        </div>
                                }
                            </div>}
                        {this.renderDeleteOptions()}
                        {this.renderScheduleDetails()}
                        {
                            this.state.deleteModalVisible
                            &&
                            <ScheduleDeleteConfirmation
                                schedule={this.state.recordToDelete}
                                handleModalClose={this.handleDeleteModalClose}
                                isBatchDelete={this.state.isBatchDelete}
                                isActiveSchedules={this.state.isActiveSchedules}
                                isLoggedInUserCreator={this.state.isLoggedInUserCreator}
                                hasParticipants={this.state.hasParticipants}
                                handleDelete={this.handleDelete}
                                isModalVisible={this.state.deleteModalVisible}
                            />
                        }
                    </div>}
            </Spin>
        );
    }
}


const mapStateToProps = ({ dashboard, user, dataCenters, mostScheduledDemos, trendingDemos, adminDashboard }) => {
    return {
        upcomingSchedules: dashboard.dashboardUpcomingSchedules,
        todaySchedules: dashboard.dashboardTodaySchedules,
        upcomingMaintenances: adminDashboard.upcomingMaintenances,
        mostScheduledDemos: mostScheduledDemos.mostScheduledDemos,
        fetchingDashboardSchedules: dashboard.fetchingDashboardSchedules,
        previousSchedules: dashboard.previousSchedulesCard,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        isRatingEnabled: user.isRatingEnabled,
        isViewCommentEnabled: user.isViewCommentEnabled,
        roleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        isLoading: dashboard.isLoading,
        isActiveSchedules: dashboard.isActiveSchedules,
        allDataCenters: dataCenters.data,
        isMostUsedDemosLoading: trendingDemos?.isLoading,
        isFetchingPreviousSchedules: dashboard.isFetchingPreviousSchedules,
        user: user.profile,
        isAdmin: user.isAdmin,
        menu: user?.menu,
        isMultipleScheduleBooking: user.profile?.isMultipleScheduleBooking || false,
    };
}

export default connect(mapStateToProps,
    {
        toggleIsActiveSchedules,
        toggleIsFetchingPreviousSchedules,
        toggleIsReschedule,
        toggleIsResourceReschedule,
        updateUserSchedulesParams,
        toggleIsFetchingMaintenanceSchedule,
        fetchSchedulesOverDuration,
        openLinkInNewTab,
        handleOnClickAction,
        deleteDashboardSchedule,
        toggleIsFetchingDashboardSchedules,
        optOutSchedule,
        fetchDashboardActiveSchedules,
        retainMyScheduleFilter,
        deleteBatchDemoSchedule,
        optOutBatchDemoSchedule,
        clearDashboardSchedules
    }
)(DashboardSchedulesCard);
import React, { Component } from 'react'
import { Comment, Tooltip, Rate, List, Progress, Form, Input, Popconfirm, Avatar, Icon, Spin, Collapse, Divider, BackTop, Typography, Popover } from 'antd';
import moment from 'moment-timezone';
import { connect, batch } from "react-redux";
import { getAvatarColor } from '../../utils/avatarColorPicker'
import CONFIG from '../../config';
import { Icon as NeoIcon, Button } from "@avaya/neo-react";
import { LoadingOutlined } from '@ant-design/icons';
import {
    getAllUserReviews,
    getUserReviews,
    deleteUserScheduleFeedback,
    toggleIsFetchingDemoReviews,
    clearDemoSchedules,
    postAdminReply,
    getDemoRatingSplitUp,
    getScheduleFeedbackByScheduleId,
    openLinkInNewTab
} from "../../actions";
const { TextArea } = Input;
const { Panel } = Collapse;
const { Text } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const DemoReviews = Form.create({ name: 'demo_reviews' })(
    class DemoReviews extends Component {

        constructor(props) {
            super(props)

            this.state = {
                replySectionVisible: false,
                replySectionRecordId: undefined,
                displayQARating: undefined,
                updateReplyVisible: false,
                allUsersReviews: [],
                isRedirectedFromPushNotification: this.props.isRatingRedirect
            }
        }

        currentPage = 1;

        clearAllUserReviews = () => {
            this.currentPage = 1;
            this.setState({ allUsersReviews: [] })
        }

        editor = (superAdminReplyObj, record, userCommentObj) => {
            const superAdminObject = superAdminReplyObj?.scheduleComments[0]?.replyByAdmin;
            const { getFieldDecorator } = this.props.form;
            if (this.state?.replySectionVisible) {
                return (
                    <div>
                        <Form.Item >
                            {getFieldDecorator("superAdminReply",
                                {
                                    initialValue: superAdminObject?.comment,
                                    rules: [
                                        { required: true, message: "Reply cannot be blank." },
                                    ]
                                })
                                (<TextArea rows={4} />)
                            }
                        </Form.Item>
                        <Form.Item>
                            <Button className="primary-action-button-filled" htmlType="submit" type="primary" onClick={() => this.handleReplySubmit(record, this.state.updateReplyVisible, userCommentObj)} >{this.state.updateReplyVisible ? "Update Comment" : "Add Comment"}</Button>
                            <Button className="left-spacing cancel-button" htmlType="submit" type="primary" onClick={() => this.setState({ replySectionVisible: false, replySectionRecordId: undefined, updateReplyVisible: false })} >Cancel</Button>
                        </Form.Item>
                    </div>
                )
            }
        }



        handleReplySubmit = async (record, isEdit, commenterObj) => {
            const commenterId = commenterObj?.commenter?.id;
            // API call to submit Super Admin's reply
            this.props.form.validateFields(async (err, values) => {
                if (err) return;
                await this.props.postAdminReply({ reply: values?.superAdminReply, scheduleId: record?.id, isEdit, demoId: record?.demo?.id, commenterId })
                this.setState({
                    replySectionVisible: false,
                    replySectionRecordId: undefined,
                    updateReplyVisible: false,
                    isRedirectedFromPushNotification: false
                })
                await this.clearAllUserReviews();
                await this.handleLoadMore();
            });
        }

        handleReplyReview = (record) => {
            this.setState({
                replySectionVisible: true,
                replySectionRecordId: (record?.id + record?.userCommentObj?.commenter?.id)
            })
        }


        async componentDidMount() {
            const { reviewDemoId, demoId, fromManageDemos, scheduleId } = this.props;
            const ratingDemoId = reviewDemoId || demoId;
            batch(async () => {
                this.state.isRedirectedFromPushNotification && this.props.getScheduleFeedbackByScheduleId(scheduleId)
                this.props.toggleIsFetchingDemoReviews(true)
                await this.props.getAllUserReviews({ pageNumber: this.currentPage++, pageSize: CONFIG.pageSize, demoId: ratingDemoId, isManageSection: fromManageDemos });
                !fromManageDemos && this.props.getUserReviews(ratingDemoId);
                this.props.getDemoRatingSplitUp(ratingDemoId)
            }).then(() => {
                this.setState({
                    allUsersReviews: this.props.allUsersReviews
                })
            })
        }

        componentWillUnmount() {
            this.props.clearDemoSchedules()
        }


        handleDeleteReview = async (record, isSuperAdminReply) => {

            const { reviewDemoId, demoId } = this.props
            const ratingDemoId = reviewDemoId || demoId;
            const commenterId = record?.userCommentObj?.commenter?.id;
            const replyForId = record?.superAdminReplyObj?.commenter?.id;
            // API to delete review either by user himself -- from My reviews, or by Super Admin --from All Reviews
            await this.props.deleteUserScheduleFeedback(record?.id, ratingDemoId, isSuperAdminReply, commenterId, replyForId)
            this.setState({ isRedirectedFromPushNotification: false })
            await this.clearAllUserReviews();
            await this.handleLoadMore();
        }

        handleEditReview = (record, isMyReviews) => {
            const { userRoleTemplate } = this.props
            const isSuperAdminUser = userRoleTemplate && (userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin || userRoleTemplate.templateType === CONFIG.roleTypes.admin)
            // Redirect the user to feedback form.
            // Enable comment section for Super Admin.
            if (isSuperAdminUser && !isMyReviews) {
                this.setState({ updateReplyVisible: true })
                this.handleReplyReview(record)
            }
            else {
                this.props.openLinkInNewTab("Demo feedback", `/demo-feedback/${record?.id}/${true}`)
            }
        }

        handleLoadMore = async (params) => {
            // API call to fetch more reviews. Similar to pagination.
            const { reviewDemoId, demoId, fromManageDemos } = this.props
            const ratingDemoId = reviewDemoId || demoId
            this.props.toggleIsFetchingDemoReviews(true);
            await this.props.getAllUserReviews({ pageNumber: this.currentPage++, pageSize: CONFIG.pageSize, demoId: ratingDemoId, isManageSection: fromManageDemos })
                .then(() => {
                    this.setState(({ allUsersReviews }) => ({
                        allUsersReviews: this.props.allUsersReviews ? allUsersReviews.concat(this.props.allUsersReviews) : []
                    }))
                })
            this.props.toggleIsFetchingDemoReviews(false);
        }


        // Action list to be shown only to SuperAdmin
        actionList = (item) => {
            const { fromManageDemos, userRoleTemplate } = this.props
            const isSuperAdminUser = userRoleTemplate && (userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin)
            return (fromManageDemos ?
                [item?.superAdminReplyObj === null && !this.state.replySectionVisible && <span key="comment-list-reply-to-0" onClick={() => this.handleReplyReview(item)}>Reply</span>,
                isSuperAdminUser && <Popconfirm title="Confirm Delete?" onConfirm={() => this.handleDeleteReview(item, false)} okText="Yes" cancelText="No">
                    {!this.state.replySectionVisible && <span key="comment-list-reply-to-0">Delete</span>}
                </Popconfirm>]
                : []
            )
        }

        createCommentObject = (items, isMyReviews) => {
            const { timeZone, fromManageDemos } = this.props;
            const updateReply = this.state.updateReplyVisible
            const replyingRecord = this.state.replySectionRecordId;
            return items?.map(eachReview => {
                const userCommentObj = eachReview?.scheduleComments[0];
                const superAdminReplyObj = eachReview;
                const reviewersUserDetails = <>
                    <span><Text strong>Full Name:</Text> {userCommentObj?.commenter?.firstName + " " + userCommentObj?.commenter?.lastName}</span><br />
                    <span><Text strong>Handle: </Text> {userCommentObj?.commenter?.userName}</span><br />
                    <span><Text strong>Email:</Text> {userCommentObj?.commenter?.email}</span>
                </>
                return ({
                    id: eachReview?.id,
                    hasRatingDisabled: eachReview?.isOutOfRatingWindow,
                    hasParticipants: eachReview?.hasParticipants,
                    hasReply: eachReview?.scheduleComments?.some(comment => comment['isReply'] === true),
                    ratedAuthor: <span className="rating-author-title">
                        {userCommentObj?.commenter?.firstName}
                        {fromManageDemos && (isMyReviews !== "myReviews") &&
                            <Popover content={reviewersUserDetails} title="User Details">
                                <Icon aria-label="aria-menu" style={{ paddingLeft: '5px', color: "#da291c" }} type="info-circle" width={1} /></Popover>}
                        <Rate style={{ color: "#F38D00" }}
                            className="review-Rating"
                            disabled allowHalf
                            defaultValue={userCommentObj?.rating} />
                        {<Tooltip title={moment.tz(userCommentObj?.ratingTime, timeZone).format(CONFIG.dateFormats.userDateTime)}><span>{moment.tz(userCommentObj?.ratingTime, timeZone).fromNow()}</span></Tooltip>}
                        {(fromManageDemos || (isMyReviews === "myReviews")) && eachReview?.questionRatings?.length > 0 && <Collapse
                            defaultActiveKey={['2']}
                            bordered={false}
                            expandIcon={({ isActive }) => <Icon aria-label="aria-menu" type="caret-right" rotate={isActive ? 90 : 0} />}
                        >
                            <Panel
                                header={<span className="link">See Detailed Ratings</span>}
                                key="1">
                                {
                                    eachReview?.questionRatings?.map(eachQARating =>
                                        <>
                                            {eachQARating?.question?.title}<Rate style={{ color: "#F38D00" }}
                                                className="review-Rating"
                                                disabled allowHalf
                                                defaultValue={eachQARating.rating} />
                                            <br />
                                        </>)
                                }
                            </Panel>
                        </Collapse>}
                    </span>,
                    ratedAvatar: <Avatar style={{ backgroundColor: getAvatarColor(userCommentObj?.commenter?.firstName?.charAt(0)) }}>{userCommentObj?.commenter?.firstName ? userCommentObj?.commenter?.firstName?.charAt(0) : ''}</Avatar>,
                    rating: eachReview?.rating,
                    userReviewComment: <>
                        <p>{userCommentObj?.comment}</p>
                        {fromManageDemos && !eachReview?.comment?.replies !== null && !updateReply && replyingRecord === (eachReview?.id + userCommentObj?.commenter?.id) && this.editor(superAdminReplyObj, eachReview, userCommentObj)}
                        {fromManageDemos && updateReply && replyingRecord === (eachReview?.id + userCommentObj?.commenter?.id) && this.editor(superAdminReplyObj, eachReview, userCommentObj)}
                    </>,
                    repliedAuthor: <span className="rating-author-title">{replyingRecord !== (eachReview?.id + userCommentObj?.commenter?.id) && <span className="rating-author-title">{superAdminReplyObj?.scheduleComments[0]?.replyByAdmin?.commenter?.fullName + " "}</span>}<Tooltip title={moment.tz(superAdminReplyObj?.scheduleComments[0]?.replyByAdmin?.commentDate, timeZone).format(CONFIG.dateFormats.userDateTime)}><span>{moment.tz(superAdminReplyObj?.scheduleComments[0]?.replyByAdmin?.commentDate, timeZone).fromNow()}</span></Tooltip></span>,
                    superAdminReply: replyingRecord !== (eachReview?.id + userCommentObj?.commenter?.id) && superAdminReplyObj?.scheduleComments[0]?.replyByAdmin?.comment,
                    repliedByAvatar: replyingRecord !== (eachReview?.id + userCommentObj?.commenter?.id) && <Avatar className="avatar-fill-color">{superAdminReplyObj?.scheduleComments[0]?.replyByAdmin?.commenter?.fullName ? superAdminReplyObj?.scheduleComments[0]?.replyByAdmin?.commenter?.firstName?.charAt(0) : ''}</Avatar>,
                    userCommentObj,
                    superAdminReplyObj: superAdminReplyObj?.scheduleComments[0]?.replyByAdmin
                })
            }
            )
        }

        getUserCommentObj = (record, isMyReviews) => {
            const { user } = this.props;
            let userCommentArray = [];
            const schedulesNoParticipants = record?.filter((eachRecord) => eachRecord?.hasParticipants !== true);
            const schedulesWithParticipants = record?.filter((eachRecord) => eachRecord?.hasParticipants === true);
            let myReviewsWithParticipants = []
            for (let index = 0; index < schedulesWithParticipants?.length; index++) {
                let record = schedulesWithParticipants[index];
                for (let i = 0; i < record?.scheduleComments?.length; i++) {
                    if (record?.scheduleComments[i]?.commenter?.id === user?.id) {
                        myReviewsWithParticipants.push({
                            ...record,
                            scheduleComments: [record?.scheduleComments[i]],
                        })
                    }
                }

            }
            let allReviewsWithParticipants = []

            for (let aIndex = 0; aIndex < schedulesWithParticipants?.length; aIndex++) {
                let record = schedulesWithParticipants[aIndex];
                for (let i = 0; i < record?.scheduleComments?.length; i++) {
                    allReviewsWithParticipants.push({
                        ...record,
                        scheduleComments: [record?.scheduleComments[i]],
                    })
                }
            }

            // userCommentArray = this.createCommentObject(schedulesWithParticipants, isMyReviews);

            if (isMyReviews === "updatedReviews") {
                const { isRedirectedFromPushNotification } = this.state;
                const { isLowRatingAlert } = this.props;
                if (isLowRatingAlert) {
                    let records = [];
                    for (let i = 0; i < record[0]?.scheduleComments?.length; i++) {
                        if (record[0]?.scheduleComments[i]?.rating < 3) {
                            records.push({
                                ...record[0],
                                scheduleComments: [record[0]?.scheduleComments[i]],
                            })
                        }
                    }
                    userCommentArray = this.createCommentObject(records, isMyReviews);
                }

                if (isRedirectedFromPushNotification && !isLowRatingAlert) {
                    let records = [];
                    for (let i = 0; i < record[0]?.scheduleComments?.length; i++) {
                        if (record[0]?.scheduleComments[i]?.commenter?.id === user?.id) {
                            records.push({
                                ...record[0],
                                scheduleComments: [record[0]?.scheduleComments[i]],
                            })
                        }
                    }
                    userCommentArray = this.createCommentObject(records, isMyReviews);
                }

                return userCommentArray;
            }

            if (isMyReviews === "myReviews") {
                userCommentArray = this.createCommentObject([...schedulesNoParticipants, ...myReviewsWithParticipants], isMyReviews);
                return userCommentArray;
            }

            if (isMyReviews === "allReviews") {
                userCommentArray = this.createCommentObject([...schedulesNoParticipants, ...allReviewsWithParticipants], isMyReviews);
                return userCommentArray;
            }
        }

        renderReviewsList = ({ isMyReviews, dataSource }) => {
            const { fromManageDemos } = this.props
            // Only super admins can delete user's comment
            // But both super admins and admins with full access can reply on User's comment
            // But both super admins and admins with full access can edit and delete reply.
            return (<List
                itemLayout="horizontal"
                dataSource={dataSource}
                renderItem={item => (
                    item?.userReviewComment !== undefined && <li>
                        <Comment
                            author={item?.ratedAuthor}
                            avatar={item?.ratedAvatar}
                            content={<span style={{ fontSize: "10px" }} className='rating-comment'>{item?.userReviewComment}</span>}
                            actions={!isMyReviews
                                ? this.actionList(item)
                                : (!this.state.replySectionVisible && !item?.superAdminReplyObj) &&
                                [<Tooltip title={item?.hasRatingDisabled ? CONFIG.warningMessages.ratingExpired : undefined}><span className={item?.hasRatingDisabled ? "comments-action-button-disabled" : undefined} key="comment-list-reply-to-0" onClick={() => !item?.hasRatingDisabled ? this.handleEditReview(item, isMyReviews) : undefined} >Edit</span></Tooltip>,
                                <Tooltip title={item?.hasRatingDisabled ? CONFIG.warningMessages.ratingExpired : undefined}>{!item?.hasRatingDisabled ? <Popconfirm title="Confirm Delete?" onConfirm={() => this.handleDeleteReview(item, false)}><span key="comment-list-reply-to-0">Delete</span></Popconfirm> : <span className="comments-action-button-disabled">Delete</span>}</Tooltip>]
                            }
                        >
                            {item?.superAdminReplyObj && <>
                                <Divider className="ez-demo-divider" />
                                <Comment
                                    author={item?.repliedAuthor}
                                    avatar={item?.repliedByAvatar}
                                    content={<div className=' rating-reply-box'><span style={{ fontSize: "10px" }} className='rating-comment'>{item?.superAdminReply}</span></div>}
                                    actions={fromManageDemos && !this.state.replySectionVisible &&
                                        [<span key="comment-list-reply-to-0" onClick={() => this.handleEditReview(item, isMyReviews)} >Edit</span>,
                                        <Popconfirm title="Confirm Delete?" okText="Yes" cancelText="No" onConfirm={() => this.handleDeleteReview(item, true)}><span key="comment-list-reply-to-0">Delete</span></Popconfirm>]
                                    }
                                ></Comment>
                            </>}
                        </Comment>
                        <Divider className="ez-demo-divider" />
                    </li>
                )}
            />)
        }

        getRatingSplitUp = (ratingSplitMap) => {
            let splitUpArray = []
            for (let i = 0; i <= 4; i++) {
                splitUpArray.push(ratingSplitMap?.find(item => item?.rating === (i + 1))?.ratingCount || 0)
            }
            return splitUpArray
        }


        renderRatingSplitUp = (splitUpArray, totalRating) => {
            let progressBar = []
            for (let i = 4; i >= 0; i--) {
                const progress = (
                    <Progress className='font' percent={Math.ceil((splitUpArray[i] * 100) / totalRating)} format={() => splitUpArray[i]} strokeColor={'#F38D00'} />
                )
                progressBar.push(progress)
            }
            return progressBar
        }

        renderRatingsTitle = () => {
            const { ratingsCount } = this.props.ratingSplitUp
            return <h4 style={{ color: '#242424' }}>{ratingsCount} Ratings</h4>
        }

        render() {
            const { fromManageDemos, userReviews, ratingSplitUp, isFetchingReviews, allUsersReviewsCount, scheduleFeedback, isLowRatingAlert } = this.props || [];
            const { avgRating, ratingsCount } = this.props.ratingSplitUp || []
            let updatedReviews = [];
            const empty = <div className='dashboard-no-schedules'><NeoIcon aria-label="menu-aria" icon="info" size='md' />No Ratings Available</div>
            this.state.isRedirectedFromPushNotification && (scheduleFeedback?.length > 0 || (scheduleFeedback?.id !== undefined && scheduleFeedback?.rating !== 0)) && updatedReviews.push(scheduleFeedback)
            const allUsersReviewsData = this.getUserCommentObj(this.state.allUsersReviews, "allReviews")
            const myReviewsData = this.getUserCommentObj(userReviews, "myReviews")
            const updatedReviewsData = this.getUserCommentObj(updatedReviews, "updatedReviews");
            const ratingStar = ['5 ★', '4 ★', '3 ★', '2 ★', '1 ★']
            const listOfStars = ratingStar.map((star) => <>{star}<br /></>)
            const eachStarSplitArray = this.getRatingSplitUp(ratingSplitUp?.ratingSplitForDemos)

            return (
                <div className='font' style={{ marginTop: "10px" }}>
                    <Spin indicator={antIcon} spinning={isFetchingReviews}>
                        <BackTop visibilityHeight />
                        {ratingSplitUp?.ratingSplitForDemos?.length > 0 && <div className="rating-split-up">
                            <div className="rating-section">
                                <div className="rating-container">
                                    <div className="rating-average-demo-review font">
                                        {avgRating}
                                        <Rate style={{ color: '#F38D00', fontSize: '32px' }} className="review-Rating" disabled allowHalf defaultValue={avgRating} />
                                    </div>
                                    {this.renderRatingsTitle()}
                                </div>
                                <div className="rating-details">
                                    <div className="star-count">
                                        {listOfStars}
                                    </div>
                                    <div className="rating-breakdown">
                                        {this.renderRatingSplitUp(eachStarSplitArray, ratingsCount)}
                                    </div>
                                </div>
                            </div>
                            <Divider />
                        </div>}
                        <div className="reviews-section-panel">
                            <Collapse bordered={false} defaultActiveKey={this.state.isRedirectedFromPushNotification ? ['0'] : ['2']}>
                                {this.state.isRedirectedFromPushNotification && updatedReviewsData?.length > 0 && <Panel header={<span style={{ display: "flex", fontSize: "13px" }}><Icon aria-label="aria-menu" className="reviews-panel-header" type="user" /><h3>{isLowRatingAlert ? "Low Rating Alert" : "Recently Updated"}</h3></span>} key="0">
                                    {this.renderReviewsList({ isMyReviews: fromManageDemos ? false : true, dataSource: updatedReviewsData })}
                                    <Divider />
                                </Panel>}
                                {!fromManageDemos && myReviewsData?.length > 0 && <Panel header={<span style={{ display: "flex", fontSize: "13px" }}><Icon aria-label="aria-menu" className="reviews-panel-header" type="user" /><div>My Ratings</div></span>} key="1">
                                    {this.renderReviewsList({ isMyReviews: true, dataSource: myReviewsData })}
                                    <Divider />
                                </Panel>}
                                <Panel header={<span style={{ display: "flex", fontSize: "13px" }}><Icon aria-label="aria-menu" className="reviews-panel-header" type="team" /><div>All User's Ratings</div></span>} key="2">
                                    {<div>
                                        {allUsersReviewsData?.length > 0 ? this.renderReviewsList({ isMyReviews: false, dataSource: allUsersReviewsData }) : empty}
                                        <div style={{ textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px' }}>
                                            {(this.state.allUsersReviews?.length < allUsersReviewsCount) && <Button className="primary-action-button-bordered" onClick={this.handleLoadMore} >See more</Button>}
                                        </div>
                                    </div>}
                                </Panel>
                            </Collapse>
                        </div>
                    </Spin>
                </div >

            )
        }
    })

const mapStateToProps = ({ demoSchedules, user }) => {
    return {
        allUsersReviews: demoSchedules.allUsersReviews,
        allUsersReviewsCount: demoSchedules.allUsersReviewsCount,
        scheduleFeedback: demoSchedules.scheduleFeedback,
        userReviews: demoSchedules.userReviews,
        isFetchingReviews: demoSchedules.isFetchingReviews,
        ratingSplitUp: demoSchedules.ratingSplitUp,
        timeZone: user.profile.timezone,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        isRatingEnabled: user.isRatingEnabled,
        isViewCommentEnabled: user.isViewCommentEnabled,
        demoSchedules,
        user: user?.profile
    };
};

export default connect(
    mapStateToProps,
    {
        getAllUserReviews,
        getUserReviews,
        toggleIsFetchingDemoReviews,
        clearDemoSchedules,
        postAdminReply,
        deleteUserScheduleFeedback,
        getDemoRatingSplitUp,
        getScheduleFeedbackByScheduleId,
        openLinkInNewTab
    }
)(DemoReviews);
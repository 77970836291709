import React, { useState } from 'react';
import { Drawer } from 'antd5';
import { Form, Tooltip, Spin, Radio } from "antd";
import { Button, TextInput, Form as NeoForm, Icon } from "neo-latest"
import moment from 'moment-timezone';
import CONFIG from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { validateOpportunityNumberForExternalUser, updateScheduleForExternalUser, updateAccountOwnerEmail } from '../../actions';
import pushMessage from './PushMessage';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function SFDCOppDrawer(props) {
    const { visible, handleCloseSFDCDrawer, schedule, timezone, handleFetchSchedules } = props;
    const [customerName, setCustomerName] = useState(schedule?.opportunity?.customerName || null);
    const [isErr, setErr] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [oppData, setOppData] = useState({});
    const [isValidOpp, setValidOpp] = useState(false);
    const [isValidEmail, setValidEmail] = useState(false);
    const [isEmailUpdate, setEmailUpdate] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const user = useSelector(({ user }) => user?.profile);
    const { form } = props;
    const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
    const dispatch = useDispatch();
    const opportunityNumberPattern = /^000\d{7}$/;
    const drawerContainer = document.getElementById('#component-content');

    const handleOppNumberChange = async (event) => {
        const opportunityNumber = event.target.value;
        setFieldsValue({ customerName: "-" });
        setValidOpp(false);
        setCustomerName("-");
        if (opportunityNumber.length > 10) {
            setErr(true);
            setErrorMessage("Opportunity number must not exceed 10 digits");
            setFieldsValue({ customerName: "-" });
            setIsFetching(false);
            return;
        }
        else if (opportunityNumber.length > 2 && opportunityNumber.slice(0, 3) !== "000") {
            setErr(true);
            setErrorMessage("Opportunity number must begin with 000")
        }
        else {
            setErr(false);
            setErrorMessage("")
            if (opportunityNumberPattern.test(opportunityNumber)) {
                setIsFetching(true);
                try {
                    const customerInfo = await dispatch(validateOpportunityNumberForExternalUser(opportunityNumber, undefined));
                    setOppData(customerInfo);
                    setValidOpp(customerInfo?.status === "Success");
                    if (customerInfo?.status === "Success") {
                        setCustomerName(customerInfo?.opportunity?.AccountName);
                        setFieldsValue({ customerName: customerInfo?.opportunity?.AccountName });
                        setErr(false);
                        setErrorMessage("")
                    }
                    else {
                        setFieldsValue({ customerName: "-" });
                        setErr(true);
                        setCustomerName("-");
                        setErrorMessage("Invalid Opportunity Number Entered")
                    }
                    setIsFetching(false);
                }
                catch (err) {
                    setIsFetching(false);
                    pushMessage(CONFIG.messageType.error, "Unable to validate the Opportunity Number");
                }
            }
        }
    };

    const handleScheduleSave = () => {
        form.validateFields(async (err, values) => {
            if (isEmailUpdate === "email-update") {
                if (err || (!validateEmail(values?.accountOwnerEmail))) {
                    pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                    return;
                }
                else {
                    setIsFetching(true);
                    const response = await dispatch(updateAccountOwnerEmail(parseInt(schedule?.id), values?.accountOwnerEmail));
                    if (response) {
                        handleCloseSFDCDrawer();
                        handleFetchSchedules()
                    }
                    setIsFetching(false);
                }
            }
            else {
                if (err || values?.customerName === "-") {
                    pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                    setErr(true);
                    setErrorMessage("Invalid Opportunity Number Entered")
                    return
                }
                else {
                    const scheduleData = {
                        scheduleId: parseInt(schedule?.id),
                        opportunityNumber: oppData?.opportunity?.otnNumber,
                        sfdcOpportunityId: oppData?.opportunity?.id,
                        customerName: oppData?.opportunity?.AccountName,
                        validatedBy: user?.email
                    }
                    setIsFetching(true);
                    const response = await dispatch(updateScheduleForExternalUser(parseInt(schedule?.id), scheduleData, undefined));
                    if (response) {
                        handleCloseSFDCDrawer();
                        handleFetchSchedules()
                    }
                    setIsFetching(false);
                }
            }
        })
    }

    const handleEmailChange = (event) => {
        const email = event.target.value;
        setValidEmail(validateEmail(email));
    }

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return visible && (
        <Drawer
            className="filter-drawer"
            closeIcon={null}
            open={visible}
            width={520}
            onClose={handleCloseSFDCDrawer}
            placement={"right"}
            getContainer={drawerContainer}
            style={{ zIndex: 9999, }}
            title={<span style={{ color: "#242424", fontSize: "19px", fontWeight: "400" }}>Update SFDC Details</span>}
            extra={
                <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }} className='float-right'>
                    <Button onClick={() => { handleCloseSFDCDrawer() }} variant="secondary" className="left-spacing-3 avaya-default-button" >
                        Cancel
                    </Button>
                    <Button disabled={isEmailUpdate === "email-update" ? !isValidEmail : !isValidOpp} onClick={handleScheduleSave} status='default' className="left-spacing-3 avaya-default-button" >
                        Save
                    </Button>

                </div>
            }
            maskClosable={false}
        >
            <Spin spinning={isFetching} indicator={antIcon}>
                <div>
                    <div>
                        <TextInput label='Demo Title' value={schedule?.demo?.title} readOnly />
                    </div>
                    <div style={{ marginTop: "16px" }}>
                        <TextInput label='Start Date' value={schedule?.startTime ? moment.tz(schedule?.startTime, timezone).format(CONFIG.dateFormats.userDateTime) : "-"} readOnly />
                    </div>
                    <div style={{ marginTop: "16px" }}>
                        <TextInput label='End Date' value={schedule?.endTime ? moment.tz(schedule?.endTime, timezone).format(CONFIG.dateFormats.userDateTime) : "-"} readOnly />
                    </div>
                    <div style={{ marginTop: "16px" }}>
                        <TextInput label='Created By' value={schedule?.createdBy?.fullName} readOnly />
                    </div>
                    <div style={{ margin: "16px 0" }}>
                        <div style={{ margin: "16px 0" }}>
                            <Radio.Group name="radiogroup" onChange={(event) => {
                                const value = event.target.value;
                                setEmailUpdate(value);
                            }} value={isEmailUpdate}>
                                <Radio style={{ color: "#242424" }} value="opp-update">Update Opportunity Number</Radio>
                                <Radio style={{ color: "#242424" }} value="email-update">Update Account Owner Email</Radio>
                            </Radio.Group>
                        </div>
                        <Form>
                            <NeoForm>
                                {isEmailUpdate === "opp-update" && <div>
                                    <div style={{ width: "50%" }}>
                                        <Form.Item>
                                            {
                                                getFieldDecorator("oppNo", { initialValue: schedule?.opportunity?.opportunityNumber })(
                                                    <TextInput
                                                        maxLength={10}
                                                        error={isErr}
                                                        helperText={errorMessage?.length > 0 ? errorMessage : null}
                                                        onChange={handleOppNumberChange} label="Opportunity Number" required />
                                                )
                                            }
                                        </Form.Item>
                                    </div>
                                    <div style={{ margin: "16px 0" }}>
                                        <Form.Item>
                                            {
                                                getFieldDecorator("customerName", { initialValue: customerName })(
                                                    <TextInput label={<span style={{ color: "black", pointerEvents: "all" }}>Customer Name <span>
                                                        <Tooltip placement='topLeft' title="The name will be automatically filled in when the correct Opportunity Number is entered.">
                                                            <Icon aria-label="aria-menu" icon="info" />
                                                        </Tooltip>
                                                    </span>
                                                    </span>}
                                                        required
                                                        value={customerName} readOnly defaultValue={"-"} />
                                                )
                                            }
                                        </Form.Item>
                                    </div>
                                </div>}{isEmailUpdate === "email-update" && <div>
                                    <div style={{ width: "100%" }}>
                                        <Form.Item>
                                            {
                                                getFieldDecorator("accountOwnerEmail", { initialValue: schedule?.opportunity?.accountOwnerEmail })(
                                                    <TextInput
                                                        onChange={handleEmailChange}
                                                        error={!validateEmail(getFieldValue("accountOwnerEmail"))}
                                                        type="email"
                                                        helperText={validateEmail(getFieldValue("accountOwnerEmail")) ? null : "Please enter valid e-mail id"}
                                                        label="Account Owner Email" required />
                                                )
                                            }
                                        </Form.Item>
                                    </div>
                                </div>}
                            </NeoForm>
                        </Form>
                    </div>
                </div>
            </Spin>
        </Drawer>
    )
}

const SFDCOppDrawerForm = Form.create()(SFDCOppDrawer);

export default SFDCOppDrawerForm;
import React, { Component } from 'react';
import { Modal, Steps, Input, Spin, Alert, Tabs, Select, Descriptions } from "antd5";
import { Form, Icon, Upload } from "antd"
import { Button } from "@avaya/neo-react";
import { connect } from "react-redux";
import pushMessage from '../../common/PushMessage';
import CONFIG from '../../../config';
import {
    getEmailTemplateForDemoRequest,
    updateDemoRequestEmailPreviewEditorContent,
    postDemoRequest,
    sendEmailToUser
} from "../../../actions";
import { fetchEmailsBySearchString } from '../../../actions/reports';
import TextEditor from "../../common/TextEditor"
import { LoadingOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Dragger } = Upload;
const { Option } = Select;
const { Step } = Steps;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class DemoRequestModal extends Component {

    state = {
        currentStep: 0,
        loading: false,
        currentEmailData: {},
        file: undefined,
        matchedUserEmails: [],
        comment: undefined,
        buttonLoading: false
    }

    componentDidMount() {
        this.emailKeyPreview = (Math.random() + 1).toString();
    }

    fetchUserEmails = searchString => {
        fetchEmailsBySearchString({ searchString })
            .then((result) => { result.length !== 0 ? this.setState({ matchedUserEmails: [...new Set(result.map(item => item.email))] }) : this.setState({ matchedUserEmails: [] }) });
    };

    renderCommentDetails = () => {
        const { getFieldDecorator } = this.props.form;
        return (
            <div style={{ marginTop: "10px" }}>
                <Form.Item label={`Comment ${this.props.modalButtonText !== "Decline" ? " (Optional)" : ""}`}>
                    {getFieldDecorator("comment", {
                        rules: [
                            {
                                required: this.props.modalButtonText === "Decline",
                                message: "Comment is mandatory!",
                            }
                        ],
                        initialValue: this.state.comment ?? undefined
                    })(<TextArea />)}
                </Form.Item>
            </div>
        )
    }

    handleEmailValidation = (value, field) => {
        if (!value.match(CONFIG.regEx.email)) {
            pushMessage(CONFIG.messageType.warning, "Please input a valid Email ID");
            this.props.form.setFieldsValue({ [field]: this.props.form.getFieldValue(field).slice(0, -1) })
        }
    }

    renderEmailSenderDetails = () => {
        const { getFieldDecorator } = this.props.form;
        const { record } = this.props;
        const toRecipient = record?.createdBy;
        const { currentEmailData, matchedUserEmails } = this.state;
        const uploadProps = {
            multiple: false,
            listType: "picture",
            fileList: this.state.file ? [this.state.file] : [],
            beforeUpload: (file) => {
                const { form } = this.props;
                const isWithinUploadLimit = file.size / 1024 / 1024 < CONFIG.fileSize.dynamicEmailAttachment;
                if (!isWithinUploadLimit) {
                    pushMessage(CONFIG.messageType.warning, `File must be smaller than ${CONFIG.fileSize.dynamicEmailAttachment} MB`)
                    return true;
                }
                this.setState({
                    file
                });
                form.setFields({
                    file: { errors: [] }
                });
                return false;
            },
            onRemove: () => {
                this.setState({ file: undefined })
            }
        };

        const formItemLayout = {
            labelCol: { span: 2 },
            wrapperCol: { span: 14 },
        }
        return (<>
            <Form className='email-recipients-form' layout={formItemLayout}>
                <Form.Item style={{ pointerEvents: 'none' }} {...formItemLayout} label="To">
                    {getFieldDecorator('to', {
                        rules: [{ required: true, message: 'Recipient cannot be empty!' }],
                        initialValue: `${toRecipient?.fullName} (${toRecipient?.email})`
                    })(<Select style={{ pointerEvents: 'none' }} mode='tags' showSearch={false} popupClassName="select_dropdown" />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="CC">
                    {getFieldDecorator('cc')(<Select
                        mode='tags'
                        showSearch
                        placeholder="Optional"
                        onSearch={this.fetchUserEmails}
                        onSelect={(field) => this.handleEmailValidation(field, "cc")}
                        notFoundContent="No Match found!"
                    >
                        {
                            matchedUserEmails?.map((item, index) =>
                                <Option key={index} title={item} value={item}>{item}</Option>
                            )
                        }
                    </Select>)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Bcc">
                    {getFieldDecorator('bcc')(<Select
                        mode='tags'
                        showSearch
                        placeholder="Optional"
                        onSearch={this.fetchUserEmails}
                        onSelect={(field) => this.handleEmailValidation(field, "bcc")}
                        notFoundContent="No Match found!"
                    >
                        {
                            matchedUserEmails?.map((item, index) =>
                                <Option key={index} title={item} value={item}>{item}</Option>
                            )
                        }
                    </Select>)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Subject">
                    {getFieldDecorator('subject', {
                        rules: [{ required: true, message: 'Subject cannot be empty!', }],
                        initialValue: currentEmailData?.subject
                    })(<Input />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="File">
                    {
                        getFieldDecorator('file')(
                            <Dragger {...uploadProps}>
                                <p className="ant-upload-drag-icon">
                                    <Icon aria-label="aria-menu" type="inbox" />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Maximum upload file size : {CONFIG.fileSize.dynamicEmailAttachment} MB<br />
                                    Allowed Types : ppt, pptx, doc, docx, txt, csv, xlx, xlsx, pdf, epub, mp4, mpg, mpv, avi, gif, jpg, jpeg, png, zip, tar, gz, rar
                                </p>
                            </Dragger>
                        )
                    }
                </Form.Item>
            </Form>
        </>)
    }

    renderEmailPreviewDetails = () => {
        const { form } = this.props;
        const { file } = this.state;
        const recepientValues = form.getFieldsValue(['to', 'bcc', 'cc', 'subject'])
        return (
            <>
                <Descriptions className="demo-approvers-details" >
                    {
                        <>
                            <Descriptions.Item style={{ color: "black" }} span={4} label="To">{recepientValues?.to}</Descriptions.Item>
                            {recepientValues?.cc && <Descriptions.Item style={{ color: "black" }} span={4} label="Cc">{recepientValues?.cc?.join(', ')}</Descriptions.Item>}
                            {recepientValues?.bcc && <Descriptions.Item style={{ color: "black" }} span={4} label="Bcc">{recepientValues?.bcc?.join(', ')}</Descriptions.Item>}
                            <Descriptions.Item style={{ color: "black" }} span={4} label="Subject">{recepientValues?.subject}</Descriptions.Item>
                            {file && <Descriptions.Item style={{ color: "black" }} span={4} label="Attachments"><Icon aria-label="aria-menu" type="file-done" />{file?.name}</Descriptions.Item>}
                        </>
                    }
                </Descriptions>
                <TextEditor
                    customEmail={true}
                    updateEditorContent={this.props.updateDemoRequestEmailPreviewEditorContent}
                    storeKey="demoRequestEmailPreview"
                    editorKey={this.emailKeyPreview}
                    isEdit={true}
                    emailType={CONFIG.emailTemplateEventType.demoRequestCompleteUser}
                    isContentLoaded={!this.state.loading}
                />
            </>
        )
    }

    renderEmailDetails = () => {
        return (
            <Spin indicator={antIcon} spinning={this.state.loading} >
                <div style={{ marginTop: "10px" }}>
                    <Tabs type='card'>
                        <TabPane tab={<span><Icon aria-label="aria-menu" type="team" />&nbsp;Details</span>} key="1" >
                            {this.renderEmailSenderDetails()}
                        </TabPane>
                        <TabPane tab={<span><Icon aria-label="aria-menu" type="file-search" />&nbsp;Preview</span>} key="2" >
                            {this.renderEmailPreviewDetails()}
                        </TabPane>
                    </Tabs>
                </div>
            </Spin>
        )
    }

    getEmailTemplateDetails = async () => {
        const { form, record } = this.props;
        const comment = form.getFieldValue("comment") ?? undefined;
        this.setState({ loading: true });
        const data = {
            eventType: CONFIG.emailTemplateEventType.demoRequestCompleteUser,
            statusMgmtId: record?.id,
            notes: comment,
            demoRequestStatus: this.props.modalButtonText + "d",
        }
        await this.props.getEmailTemplateForDemoRequest(record?.demo?.id, data).then((data) => { this.setState({ loading: false, currentEmailData: data }) })
    }

    incrementStep = () => {
        this.setState((prevState) => ({ currentStep: prevState.currentStep + 1, comment: this.props.form.getFieldValue("comment") ?? undefined }))
    }

    handleErrorSendingEmail = async () => {
        this.setState({
            buttonLoading: false
        });
    }

    handleSend = async () => {
        let recepientValues = this.props.form.getFieldsValue(['bcc', 'cc', 'subject']);
        const { record, modalButtonText } = this.props;
        this.setState({ buttonLoading: true })
        const comment = this.state.comment ?? undefined;
        const data = {
            id: record?.id,
            statusMgmtStatus: modalButtonText === "Approve" ? "approved" : "declined",
            notes: comment,
        }
        const isRecordUpdated = await this.props.postDemoRequest(data, modalButtonText === "Approve" ? "approved" : "declined", true);
        if (isRecordUpdated) {
            const response = await this.props.sendEmailToUser(recepientValues, record?.createdBy?.email, data?.id, this.state.file, CONFIG.emailTemplateEventType.demoRequestCompleteUser, true);
            if (response) {
                await this.props.fetchAdminApprovals()
                this.props.onCancel();
                this.setState({
                    currentEmailData: {},
                    file: undefined,
                    matchedUserEmails: [],
                    buttonLoading: false
                });
            }
            else {
                this.handleErrorSendingEmail()
            }
        }
        else {
            this.handleErrorSendingEmail()
        }

    }

    renderFooterButtons = () => {
        const { currentStep, buttonLoading } = this.state;
        const { modalButtonText } = this.props;
        if (currentStep === 0) {
            return (
                <>
                    <Button aria-label="cancel" key="cancel" variant="secondary" onClick={this.props.onCancel} > Cancel </Button>
                    <Button aria-label="next" className="left-spacing" key="cancel" variant="primary" onClick={async () => {
                        if (this.props.modalButtonText === "Decline") {
                            this.props.form.validateFields(async (err) => {
                                if (err) {
                                    pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                                    return
                                }
                                else {
                                    await this.getEmailTemplateDetails();
                                    this.incrementStep()
                                }
                            })
                        }
                        else {
                            await this.getEmailTemplateDetails();
                            this.incrementStep()
                        }
                    }} > Next </Button>
                </>
            )
        }
        if (currentStep === 1) {
            return (
                <>
                    <Button aria-label="cancel" key="cancel" status="alert" variant="secondary" onClick={this.props.onCancel} > Cancel </Button>
                    <Button className="left-spacing" aria-label="cancel" key="cancel" variant="secondary" onClick={() => {
                        this.props.form.setFieldsValue({ comment: this.state.comment ?? undefined });
                        this.setState((prevState) => ({ currentStep: prevState.currentStep - 1 }));
                    }} > Prev </Button>
                    <Button disabled={buttonLoading} animation={buttonLoading ? "spinner" : "none"} onClick={this.handleSend} className="left-spacing" aria-label="next" status={modalButtonText === "Approve" ? "success" : "alert"} key="cancel" variant="primary" > {buttonLoading ? "Sending..." : modalButtonText + " & Send"} </Button>
                </>
            )
        }
    }

    render() {
        const { currentStep } = this.state;
        const { record } = this.props;
        return (
            <Modal
                className="demo-request-modal"
                closable={false}
                footer={this.renderFooterButtons()}
                open={this.props.open}
                title={<div style={{ textAlign: "center" }}>{`${this.props.modalButtonText} the Demo Request for ${record?.demo?.title}`}</div>}
                onCancel={this.props.onCancel}
            >
                <>
                    <Steps current={currentStep}>
                        <Step title={<div style={{ color: "black", fontSize: "14px" }}>Add Comment</div>} />
                        <Step title={<div style={{ color: "black", fontSize: "14px" }}>Email Details</div>} />
                    </Steps>
                    <div style={{ marginTop: "10px" }}>
                        <Alert message="The Recommended email template version is used for sending the emails" type="info" showIcon />
                    </div>
                    <div>
                        {currentStep === 0 && this.renderCommentDetails()}
                        {currentStep === 1 && this.renderEmailDetails()}
                    </div>
                </>
            </Modal>
        )
    }
}

const mapStateToProps = ({ demoRequestEmailPreview, user }) => {
    return {
        templateHtml: demoRequestEmailPreview?.html,
        user: user.profile,
        timeZoneName: user.profile.timezone,
    };
}

const DemoRequestModalRef = Form.create()(DemoRequestModal);

export default connect(mapStateToProps, {
    getEmailTemplateForDemoRequest,
    updateDemoRequestEmailPreviewEditorContent,
    postDemoRequest,
    sendEmailToUser
})(DemoRequestModalRef);
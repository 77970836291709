import React from "react";
import { Form, Select, Tooltip, Icon, Input, Switch } from "antd";
import { ManageDemoResourceContext } from "./DemoResources";
import CONFIG from '../../config';

const { Option } = Select;

export default class DemoResourcesTableCell extends React.Component {

    state = {
        recordDesc: false
    };

    renderCellInput = (dataIndex) => {
        if (dataIndex === "ldapGroup")
            return (<Select className="active-inactive-select" showSearch={true} placeholder="User Group">
                <Option key="none" value={CONFIG.demoldapGroupNone}>None</Option>
                {this.renderUserGroups(this.props.usergroups)}
            </Select>)
        else if (dataIndex === "description")
            return <Input />
        else return (<Switch
            style={{ width: '40px' }}
            size="small"
            onChange={this.handleStatusChange}
        />
        );
    };

    handleStatusChange = (flag) => {
        const { form } = this.props;
        if (flag) {
            form.setFieldsValue({ description: "" })
        }
    }

    renderCell = ({ getFieldDecorator }) => {
        const { editing, dataIndex, title, inputType, record, children, ...restProps } = this.props;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item>
                        {getFieldDecorator(dataIndex, {
                            ...(dataIndex === "isActive" && { valuePropName: "checked" }),
                            initialValue: dataIndex === "ldapGroup" ? record[dataIndex] === null ? "None" : JSON.stringify(record[dataIndex]) : dataIndex === "description" ? record.description : record?.isActive === undefined ? true : record?.isActive
                        })(this.renderCellInput(dataIndex))}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    renderUserGroups = userGroups => {
        if (userGroups) {
            return userGroups.map(userGroup => {
                return (
                    <Option key={userGroup.id} value={JSON.stringify(userGroup)}>
                        <div>
                            {userGroup.name}
                            {
                                <span className="float-right">
                                    <Tooltip title={userGroup.active ? "Active" : "Inactive"} key={userGroup.id}>
                                        {
                                            userGroup.active
                                                ? <Icon aria-label="aria-menu" type="check" className="small-text success-text" />
                                                : <Icon aria-label="aria-menu" type="warning" className="small-text warning-text" />
                                        }
                                    </Tooltip>
                                </span>
                            }
                        </div>
                    </Option>
                );
            });
        }
        return;
    };

    render() {
        return <ManageDemoResourceContext.Consumer>{this.renderCell}</ManageDemoResourceContext.Consumer>;
    }
}

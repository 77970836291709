import { Collapse, Col, Row, Form, Checkbox, Radio, Divider } from 'antd'
import React from 'react';
import { Dropdown } from 'antd5'
import { connect } from 'react-redux';
import CONFIG from '../../../config';
import moment from 'moment-timezone';
import { Icon } from "@avaya/neo-react"
import { getUserInformationCard } from "../../../utils/getUserInformationCard";
import {
    openLinkInNewTab,
} from '../../../actions'
import DemoRequestModal from './DemoRequestModal';
const { Panel } = Collapse;

class DemoRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            showMore: true,
            showCommentModal: false,
            clickedRecord: undefined
        };
    }

    toggleCollapse = () => {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed,
        }));
        setTimeout(() => {
            this.setState(prevState => ({
                showMore: !prevState.showMore,
            }));
        }, 100);
    };


    renderRowInformation = (title, data) => {
        return (
            data && <div className=" font-12px">
                <span className="bold">{title}: </span>{data}
            </div>
        )
    }

    handleActionButton = (typeOfApprove, approvalObj) => {
        this.setState({
            showCommentModal: true,
            clickedRecord: approvalObj,
            modalButtonText: typeOfApprove === "DECLINED" ? "Decline" : "Approve",
            typeOfApprove: typeOfApprove
        })
    }

    renderScheduleInformation = () => {
        const { collapsed, showMore } = this.state;
        const { demoApprovalsData, userObj, isPending } = this.props
        const { timezone } = userObj || []
        const eachCard = demoApprovalsData;

        const isCompleted = eachCard.requestStatus?.toLowerCase()?.includes("approved");

        let completedApprovalsObject = {
            [isCompleted ? "Completed By" : "Declined By"]: getUserInformationCard(eachCard?.resolvedBy, eachCard?.resolvedBy?.fullName),
            [isCompleted ? "Completed On" : "Declined On"]: moment.tz(eachCard?.resolvedTime, timezone).format(CONFIG.dateFormats.userDateTimeWithTZ2)
        }
        // Compute each Object

        let demoRequestBasicDetails = {
            "Requested by": getUserInformationCard(eachCard?.createdBy, eachCard?.createdBy?.fullName),
            "Requested on": moment.tz(eachCard?.createdTime, timezone).format(CONFIG.dateFormats.userDateTimeWithTZ2),
            "Assigned to": eachCard?.assignedUser && getUserInformationCard(eachCard?.assignedUser, eachCard?.assignedUser?.fullName)
        }

        let demoRequestUserResponse = {}

        eachCard?.userAnswers?.forEach(item => {
            demoRequestUserResponse[item.question.title] = item.answerText;
        });
        const items = [
            {
                key: 'details',
                openLinkInNewTab,
                label: (<span className="font-12px full-width-options" onClick={() => this.props.openLinkInNewTab("Demo Request", `/manage/demo-request/details/${eachCard?.id}`)}><Icon aria-label="aria-menu" icon="forward-skip" style={{ fontSize: "19px", paddingRight: "8px" }} />Go to Request</span>),
            },
            isPending ? {
                key: 'edit',
                label: (<span className="font-12px full-width-options" onClick={() => this.handleActionButton("APPROVED", eachCard)}><Icon aria-label="aria-menu" icon="check" style={{ fontSize: "19px", paddingRight: "8px" }} />Approve</span>)
            } : undefined,
            isPending ? {
                key: 'delete',
                label: (<span className="font-12px full-width-options" onClick={() => this.handleActionButton("DECLINED", eachCard)}><Icon aria-label="aria-menu" icon="close" style={{ fontSize: "19px", paddingRight: "8px" }} />Decline</span>)
            } : undefined
        ];

        const renderAdditionalQuestions = (questions) => {
            return (
                questions?.map(item => {
                    const { question, answerText, questionOptions } = item;
                    const { title, id, answerType } = question
                    const answers = answerType !== CONFIG.demoAnswerTypes.Textbox && questionOptions?.split("//")
                    let userSubmittedAnswers = answerType === CONFIG.demoAnswerTypes.Checkbox ? answerText?.split("//") : answerText;
                    return (
                        <React.Fragment key={id}>
                            <Form.Item className='admin-dashboard-request-user-response' label={<span className="font-12px">○ {title}</span>}>
                                <div style={{ marginLeft: "25px" }}>{answerType === CONFIG.demoAnswerTypes.Checkbox
                                    ?
                                    (<Checkbox.Group value={userSubmittedAnswers} className="font-12px"
                                        options={answers}
                                    >

                                        {/* {answers?.map(opt => (<Checkbox style={{ color: "black" }} key={opt?.title} label={opt?.title} value={opt?.title}>{opt?.title}</Checkbox>))} */}
                                    </Checkbox.Group>)
                                    : answerType === CONFIG.demoAnswerTypes.RadioButton
                                        ? (<Radio.Group value={userSubmittedAnswers} className="font-12px"
                                            options={answers}

                                        >

                                        </Radio.Group>)
                                        : (<div className="font-12" style={{ width: '700px' }}>{userSubmittedAnswers}</div>)
                                }</div>
                            </Form.Item>
                        </React.Fragment>
                    )

                })
            )
        }


        const eachCardJSX = (<div >
            <Collapse
                bordered={false}
                className='admin-dashoard-requests-collapse'
                activeKey={collapsed ? [] : ['1']}
                expandIconPosition="right"
            >
                <Panel
                    header={
                        <div style={{ paddingBottom: "1%" }}>
                            <Row>
                                <Col span={17}>
                                    <Row> <span className='dashboard-requests-sub-heading'>Demo Request</span></Row>
                                    <span className='medium-text dashboard-schedules-demo-title '>{demoApprovalsData?.demo?.title}</span>
                                    <Row className='baseline-flex'>
                                        <Col span={1}>
                                            <Icon aria-label="aria-menu" icon="screenshare-on" style={{ fontSize: "18px" }} />
                                        </Col>
                                        <Col style={{ paddingLeft: "7px" }} span={23}>
                                            {Object.entries(demoRequestBasicDetails).map(([key, value]) => (
                                                <div>
                                                    {this.renderRowInformation(key, value)}
                                                </div>
                                            ))}
                                            {
                                                !isPending ?
                                                    Object.entries(completedApprovalsObject).map(([key, value]) => (
                                                        <div>
                                                            {this.renderRowInformation(key, value)}
                                                        </div>
                                                    ))
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                    {(showMore && isPending) && (
                                        <div className="font-12px vertical-spacing-3 view-more-link" onClick={this.toggleCollapse}>
                                            {showMore ? 'View More' : 'View Less'}
                                        </div>
                                    )}
                                </Col>
                                <Col className="dashboard-daily-testing-row" span={7}>
                                    <Dropdown
                                        trigger="click"
                                        menu={{ items }}
                                        placement="bottomLeft"
                                        arrow
                                    >
                                        <Icon aria-label="aria-menu" icon="ellipses-vertical" className='pointer' style={{ fontSize: "19px", color: "#1B77AF" }} />
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    }
                    key="1"
                >
                    {isPending ? <div >
                        <Row className='vertical-spacing-3 baseline-flex'>
                            <Col span={1}>
                                <Icon aria-label="aria-menu" icon="calendar" style={{ fontSize: "18px" }} />
                            </Col>
                            <Col span={23}>
                                <div className=" font-12px">
                                    <div className="bold">Request Details: </div>
                                    {renderAdditionalQuestions(eachCard?.userAnswers)}
                                </div>
                            </Col>
                        </Row>
                        <div className="vertical-spacing-3 view-more-link font-12px" onClick={this.toggleCollapse}>
                            {showMore ? 'View More' : 'View Less'}
                        </div>
                    </div> : null}
                </Panel>
            </Collapse>
            <Divider style={{ margin: 0, marginBottom: "5px" }} />
        </div >);

        return eachCardJSX;
    }
    render() {
        return (
            <div>
                {this.renderScheduleInformation()}
                {this.state.showCommentModal && <DemoRequestModal
                    open={this.state.showCommentModal}
                    onCancel={() => this.setState({ showCommentModal: false })}
                    record={this.state.clickedRecord}
                    modalButtonText={this.state.modalButtonText}
                    typeOfApprove={this.state.typeOfApprove}
                    fetchAdminApprovals={this.props.fetchAdminApprovals}
                />}
            </div>
        )
    }
}

const DemoRequestRef = Form.create()(DemoRequest);

export default connect(
    null,
    {
        openLinkInNewTab,
    }
)(DemoRequestRef);
import React, { useEffect, useReducer, useState } from 'react';
import { Dropdown, message, Popover, Popconfirm, Tooltip, Modal, Radio, Form, Row, Result, Alert, Tabs, Spin } from 'antd';
import { Button, Table, Icon as NeoIcon, Chip, Form as NeoForm } from 'neo-latest';
import { exportMenu } from '../../utils/strings';
import pushMessage from '../common/PushMessage';
import { exportEmailTemplateVersion } from "../../actions/emailTemplates";
import moment from 'moment-timezone';
import { TextInput, } from "@avaya/neo-react"
import TextEditor from '../common/TextEditor';

import {
    fetchEmailTemplateVersionsByType,
    updateCustomMaintenanceEmailTemplateEditorContent,
    updateCustomEmailTemplateEditorContent,
    clearDynamicEmailTemplate,
    clearDynamicEmailPreview,
    clearCustomEmailTemplates,
    updateCustomEmailTemplateVersion,
    deleteEmailTemplateVersion,
    updateEmailTemplateDefaultVersion,
    toggleIsFetchingEmailTemplateVersions,
    fetchCustomEmailTemplateById,
    clearDynamicTemplateVersion,
} from '../../actions';
import CONFIG from '../../config';
import { otherFields } from '../../utils/neoTableFields';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;

function ManageMaintenanceTemplateVersions(props) {

    const dispatch = useDispatch();
    const timezone = useSelector(({ user }) => user?.profile?.timezone);
    const currentEmailTemplate = useSelector(({ customEmailTemplate }) => customEmailTemplate?.customEmailTemplate);
    const templateHtml = useSelector(({ customEmailTemplate }) => customEmailTemplate?.html);
    const isFetching = useSelector(({ dynamicEmailTemplate }) => dynamicEmailTemplate?.isFetching);
    const demoBuildVersions = useSelector(({ dynamicEmail }) => dynamicEmail?.versionsData);
    const user = useSelector(({ user }) => user?.profile);
    const [isContentLoaded, setContentLoaded] = useState(true);
    const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
    const { form } = props || {};

    const { getFieldDecorator } = form || {};
    const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
        selectedVersion: undefined,
        recordToClone: undefined,
        recordToEdit: undefined,
        isModalVisible: false,
        defaultActiveKey: "1",
        isTemplateSaved: false,
        hasFormChanged: false,
        hideTemplateTab: false,
        isEdit: false,
        isClone: false,
        file: undefined,
        clonedTitle: undefined,
        clonedDescrption: undefined,
        matchedUserEmails: [],
        disableUpdateButton: false,
        demos: [],
        hasCkEditorContentChanged: false,
        emailKeyPreview: null,
        emailKey: null,
        loading: true
    });

    const handleExport = (fileType) => {
        const emailType = props?.match?.params?.emailTemplateType || props?.emailType
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        exportEmailTemplateVersion(CONFIG.exportTable.customEmailTemplate.fileName + `-${"Maintenance Custom Templates"}.${fileType}`, fileType, undefined, timezone, emailType)
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }

    useEffect(() => {
        const emailType = props?.match?.params?.emailTemplateType || props?.emailType
        dispatch(toggleIsFetchingEmailTemplateVersions(true));
        setContentLoaded(false);
        (async () => {
            await dispatch(fetchEmailTemplateVersionsByType(undefined, emailType))
            setContentLoaded(true);
        })();
        setState({
            hasCkEditorContentChanged: currentEmailTemplate?.body === templateHtml ? false : true,
            emailKey: Math.random().toString(),
            emailKeyPreview: Math.random().toString(),
            loading: false
        });
        const cleanUpComponent = () => {
            dispatch(clearDynamicTemplateVersion());
            dispatch(clearDynamicEmailTemplate());
            dispatch(clearDynamicEmailPreview());
        }
        return cleanUpComponent;
        // eslint-disable-next-line
    }, []);

    const handleDefaultTemplateChange = async (event, record) => {
        const emailType = props?.match?.params?.emailTemplateType || props?.emailType
        dispatch(toggleIsFetchingEmailTemplateVersions(true));
        setState({ loading: true })
        setContentLoaded(false);
        const result = await dispatch(updateEmailTemplateDefaultVersion(record?.id, emailType, undefined, record?.isVersion));
        if (!result) {
            const radioButton = document.getElementById(`recommended-radio-${record?.id}`);
            if (radioButton) {
                radioButton.value = false;
                radioButton.checked = false;
                setState({ file: undefined })
            }
        }
        setState({ loading: false });
        setContentLoaded(true);
    }

    const handleDeleteVersion = async (record) => {
        await dispatch(deleteEmailTemplateVersion(record?.id, record?.type, undefined));
    }

    const handleEdit = async (versionId) => {
        await dispatch(fetchCustomEmailTemplateById(versionId)).then(async (currentEmailTemplate) => {
            await dispatch(updateCustomEmailTemplateEditorContent(currentEmailTemplate?.body))
            await setState({
                selectedVersion: currentEmailTemplate,
                isEdit: true,
                disableUpdateButton: true,
            });
            await setState({ isModalVisible: true });
        })
    }

    const handleClone = async (versionId) => {
        dispatch(fetchCustomEmailTemplateById(versionId))
            .then(async (currentEmailTemplate) => {
                await dispatch(updateCustomEmailTemplateEditorContent(currentEmailTemplate?.body))
                setState({
                    isModalVisible: true,
                    selectedVersion: currentEmailTemplate,
                    isClone: true,
                    disableUpdateButton: true
                })
            })
    }

    const columns = [
        {
            Header: "  ",
            disableFilters: true,
            disableSortBy: true,
            accessor: "isDefault",
            Cell: (a) => {
                const record = a?.cell?.row?.original;
                return <Radio id={`recommended-radio-${record?.id}`} onChange={(event) => fromMaintenanceForm ? props.handleEmailTemplateSelect(record?.id) : handleDefaultTemplateChange(event, record)} defaultChecked={fromMaintenanceForm ? record?.id === props?.emailTemplateId : record?.isDefault} value={fromMaintenanceForm ? record?.id === props?.emailTemplateId : record?.isDefault}></Radio>
            }
        },
        {
            Cell: (a) => {
                const record = a?.cell?.row?.original;
                return <React.Fragment>
                    <div style={{ display: "flex", gap: "24px" }}>
                        <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.title}</span>
                        {!record?.isVersion && <span><Chip variant='success'>Original</Chip></span>}
                        {record?.isDefault && <span><Chip variant='info'>Recommended</Chip></span>}
                    </div>
                </React.Fragment>
            },
            Header: "Versions",
            sortType: "string",
            accessor: "type",
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Cell: (a) => {
                return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.lastUpdatedBy?.fullName}</span>
            },
            Header: "Last Updated By",
            sortType: "string",
            accessor: "lastUpdatedBy",
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Cell: (a) => {
                return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.lastUpdatedTime ? moment.tz(a?.cell?.row?.original?.lastUpdatedTime, timezone).format(CONFIG.dateFormats.userDateTime) : "-"}</span>
            },
            Header: "Last Updated Time",
            sortType: "string",
            accessor: "lastUpdatedTime",
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "    ",
            disableFilters: true,
            disableSortBy: true,
            Cell: (a) => {
                const record = a?.cell?.row?.original;
                const isDeleteDisabled = (user?.id !== record?.createdBy?.id && !isSuperAdminUser)
                return (<span style={{ display: "flex", gap: "24px" }} className="demo-card-body-details">
                    <Popover content={<span style={{ color: "#242424" }}>{record?.description}</span>} title="Description">
                        <NeoIcon aria-label="menu-aria" className="link" title="Description" icon="help" size="md" />
                    </Popover>

                    <NeoIcon aria-label="menu-aria" className="link" onClick={() => { handleClone(record?.id) }} title="Clone" icon="copy" size="md" />

                    <NeoIcon aria-label="menu-aria" className={!record?.isVersion ? "link-disabled" : "link"} onClick={() => { record?.isVersion && handleEdit(record?.id) }} disabled={!record?.isVersion} title="Edit" icon="edit" size="md" />
                    {<>
                        <Popconfirm
                            disabled={isDeleteDisabled || !record?.isVersion}
                            placement="right"
                            title="Confirm Delete?"
                            okText="Yes" cancelText="No"
                            onConfirm={() => { handleDeleteVersion(record) }}>
                            <Tooltip title={(isDeleteDisabled || !record?.isVersion) ? CONFIG.warningMessages.noAccess : undefined} key={Math.random()}>
                                <NeoIcon aria-label="menu-aria" className={(isDeleteDisabled || !record?.isVersion) ? "link-disabled" : "link"} disabled={isDeleteDisabled || !record?.isVersion} icon="trash" size="md" />
                            </Tooltip>
                        </Popconfirm></>}
                </span>)
            }
        }
    ];

    const handleCancel = () => {
        dispatch(clearDynamicEmailTemplate())
        dispatch(clearDynamicEmailPreview())
        dispatch(clearCustomEmailTemplates())
        setState({
            selectedVersion: undefined,
            recordToClone: undefined,
            recordToEdit: undefined,
            isModalVisible: false,
            defaultActiveKey: "1",
            isTemplateSaved: false,
            hasFormChanged: false,
            hideTemplateTab: false,
            isEdit: false,
            isClone: false,
            file: undefined,
            clonedTitle: undefined,
            clonedDescrption: undefined,
            matchedUserEmails: [],
            disableUpdateButton: false
        })
    }

    const handleSaveAs = (isPostVersion) => {
        const { form } = props;
        form.validateFields(['title', 'description'], async (err, values) => {
            if (err) {
                return;
            }

            setState({ hasCkEditorContentChanged: false, clonedTitle: values?.title, clonedDescrption: values?.description, disableUpdateButton: true })
            const response = await dispatch(updateCustomEmailTemplateVersion(state.selectedVersion, values, undefined, isPostVersion))
            // if (response !== "FAILED") {
            //     handleEdit(response || state.selectedVersion?.id);
            // }
            response !== "FAILED" && setState({ isTemplateSaved: true })
            setState({ loading: true })
            await setContentLoaded(false);
            await dispatch(fetchEmailTemplateVersionsByType(undefined, state.selectedVersion?.type));
            dispatch(toggleIsFetchingEmailTemplateVersions(false));
            await setContentLoaded(true);
            setState({ loading: false });
        })
    }

    const onTabChange = (value) => {
        setState({
            defaultActiveKey: value,
        })
    }

    const showErrorPage = () => {
        return (
            <Result
                status="warning"
                title="Sorry, something went wrong!"
            />
        )
    }

    const renderWarningMessage = () => {
        return (
            <Alert
                message={<>On click of <b>Save/Update Template</b>, only the changes made to the Template will saved, and not the changes made to the Preview.</>}
                type="warning"
                showIcon
            />
        )
    }

    const renderEditor = () => {
        const { defaultActiveKey, selectedVersion, hideTemplateTab } = state;
        return (
            <div className="card-container">
                <Tabs type="card" activeKey={defaultActiveKey} onChange={onTabChange}>
                    {!hideTemplateTab && <TabPane tab={<span><NeoIcon aria-label="menu-aria" icon="file" />&nbsp;Template</span>} key="1">
                        {selectedVersion === undefined
                            ? showErrorPage()
                            :
                            <>
                                <Row className='bottom-spacing-3'>{renderWarningMessage()}</Row>
                                <TextEditor
                                    customEmail={true}
                                    updateEditorContent={dispatch(updateCustomMaintenanceEmailTemplateEditorContent)}
                                    storeKey="customEmailTemplate"
                                    editorKey={state.emailKey}
                                    isEdit={true}
                                    emailType={state.selectedVersion?.type}
                                    placeholders={selectedVersion?.emailPlaceholders} /></>
                        }
                    </TabPane>}
                </Tabs>
            </div >
        )
    }

    const renderModalFooterButtons = () => {
        const { defaultActiveKey, isClone, isTemplateSaved, isEdit, selectedVersion } = state;
        if (defaultActiveKey === "1") {
            if (isClone || isEdit) {
                return (
                    <>
                        <Button variant='secondary' onClick={() => handleCancel()}> Cancel </Button>
                        {(isTemplateSaved || isEdit)
                            ? <Button disabled={selectedVersion === undefined} key="update" onClick={() => handleSaveAs()} className="left-spacing primary-action-button-filled" >Update Template</Button>
                            : <Button disabled={selectedVersion === undefined} key="save" onClick={() => { isClone ? handleSaveAs(true) : handleSaveAs() }} className="left-spacing primary-action-button-filled" >Save Template</Button>}
                    </>)
            }
        }
        // else if (defaultActiveKey === "2") {
        //     return (
        //         <>
        //             {/* Cancel Button is common for all the actions*/}
        //             <Button variant='secondary' onClick={() => handleCancel()}> Cancel </Button>
        //             {/* <Button disabled={selectedVersion === undefined} key="Preview" onClick={() => handlePreview()} className="left-spacing primary-action-button-filled" > Preview </Button> */}
        //         </>)
        // }
    }

    const handleVersionDetailsChange = () => {
        setState({ disableUpdateButton: false })
    }

    const { isClone, isModalVisible, selectedVersion, clonedTitle, clonedDescrption, hideTemplateTab } = state;
    const { fromMaintenanceForm } = props;
    return (
        <div>
            <Spin spinning={state.loading || isFetching} indicator={antIcon}>
                {!fromMaintenanceForm && <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ fontSize: "19px", color: "#242424" }}>
                        <span style={{ color: "#1B77AF" }}>Custom Email Templates </span> / Versions
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Dropdown overlay={exportMenu(handleExport)} trigger="click">
                            <Button className="export-download-button" icon="chevron-down" variant="secondary">Download</Button>
                        </Dropdown>
                    </div>
                </div>}
                <div style={{ marginTop: "16px" }}>
                    {(state.loading || isFetching) ? null : <Table
                        columns={(!isSuperAdminUser && !fromMaintenanceForm) ? columns?.slice(1) : columns}
                        data={demoBuildVersions || []}
                        className="table-actions"
                        allowColumnFilter={false}
                        showPagination={false}
                        showRowSeparator
                        {...otherFields}
                    />}
                </div>
                {state.isModalVisible && <Modal
                    footer={renderModalFooterButtons()}
                    title={
                        <Form>
                            <NeoForm>
                                <div style={{ display: "flex", gap: "24px" }}>
                                    <div style={{ flex: "1" }}>
                                        <Form.Item>
                                            {getFieldDecorator("title", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Please provide a title!"
                                                    }
                                                ], initialValue: isClone ? clonedTitle : selectedVersion?.title
                                            })(<TextInput className='flexed-inputs' label="Title" required onChange={() => handleVersionDetailsChange()} disabled={hideTemplateTab} />)}
                                        </Form.Item>
                                    </div>
                                    <div style={{ flex: "2" }}>
                                        <Form.Item>
                                            {getFieldDecorator("description", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Please provide a description!"
                                                    }
                                                ], initialValue: isClone ? clonedDescrption : selectedVersion?.description
                                            })(<TextInput className='flexed-inputs' label="Description" required onChange={() => handleVersionDetailsChange()} disabled={hideTemplateTab} />)}
                                        </Form.Item>
                                    </div>
                                </div>
                            </NeoForm>
                        </Form>}

                    visible={isModalVisible}
                    onCancel={handleCancel}
                    closable={false}
                    className="scheduler-modal"
                    maskClosable={false}
                >
                    {renderEditor()}
                </Modal>}
            </Spin>
            {isContentLoaded && <React.Fragment></React.Fragment>}
        </div>
    )
}

const ManageMaintenanceTemplateVersionsRef = Form.create()(ManageMaintenanceTemplateVersions);
export default ManageMaintenanceTemplateVersionsRef;
import React from "react";
import { Form, Row, Alert, message, Dropdown, Tooltip, Tabs, Spin, Modal } from "antd";
import { exportMenu } from "../../utils/strings";
import { isTabAlreadyOpen } from "../common/TabLayout";
import { otherFields } from "../../utils/neoTableFields";
import {
    fetchDataCenters,
    addDataCenter,
    updateDataCenter,
    removeEmptyDataCenter,
    postNewDataCenter,
    toggleIsFetchingDataCentersFlag,
    putDataCenter,
    updateDataCenterEditingKey,
    toggleBackButton,
    selectTitle,
    clearDataCenters,
    deleteDataCenter,
    restoreDataCenter,
    toggleShouldUpdateFilteredDataFlag,
    exportTable,
    refreshDataCenters,
    toggleHasDataCentersUpdated,
    currentDataCentersFilter,
    toggleHasUnsavedChangesFlag
} from "../../actions";
import { connect, batch } from "react-redux";

import openConfirm from '../common/Confirm';
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config';
import { checkIfFilterSortIsEmpty } from '../../utils/formChanged';
import { Table as NeoTable, Button as NeoButton, Icon as NeoIcon } from "neo-latest"
import { checkDataCenterDependencies } from '../../actions/dataCenters';
import { LoadingOutlined } from '@ant-design/icons';
import FilterDrawer from '../common/FilterDrawer';
import EditDrawer from '../common/EditDrawer';
import DataCenterDetails from "./DataCenterDetails";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;
export const ManageDataCentersContext = React.createContext();

class ManageDataCenters extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filteredData: undefined,
            status: "true",
            showFilter: false,
            showEdit: false,
            isReadOnlyEdit: false,
            record: null,
            height: `${document.body.clientHeight - 175}px`,
            drawerHeight: `${document.body.clientHeight - 100}px`,
            formLoading: false,
            currentPage: 0,
            currentPath: null,
        }

        this.filters = {}

    }
    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            const filterSort = {
                ...this.filterSort,
            }
            const filterObject = JSON.parse(JSON.stringify(filterSort))
            this.props.currentDataCentersFilter(filterObject, this.state.status, this.state.currentPage);
        }
        else {
            this.props.clearDataCenters();
        }
    }


    componentDidUpdate() {
        const { openedTabs, activeKey } = this.props;
        const formChanged = this.props.editingKey !== "" && this.props.editingKey !== undefined;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && formChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, formChanged);
        else if (hasUnsavedChanges && !formChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, formChanged);
    }

    componentDidMount() {
        const { isEdit, currentFilter, status, currentPage } = this.props;
        this.setState({ currentPath: window.location.pathname });
        document.getElementById('tab-pane').scrollIntoView();
        if (isEdit) {
            this.filterSort = currentFilter;
            const { sorter, ...filterSort } = currentFilter;
            let filterObject = {
                ...filterSort,
                regions: filterSort.regions ? filterSort.regions?.split("\\") : undefined
            }
            filterObject = JSON.parse(JSON.stringify(filterObject))
            const currentFilterSize = filterObject !== undefined ? Object.keys(filterObject)?.length : 0;
            if (currentFilterSize > 0) {
                this.filterRecords(filterObject)
                this.setState({ showFilter: true });
                this.filterSort = currentFilter;
            }
            this.setState({ status, currentPage: currentPage })
        }
        batch(() => {
            this.props.toggleIsFetchingDataCentersFlag(true);
            this.props.fetchDataCenters(currentFilter?.viewDeletedItems || false);
        });
    }

    getFilteredData = (title, stateStatus, regions) => {
        const status = stateStatus === "all" ? stateStatus : stateStatus === "true" ? true : false;
        const { dataCenters } = this.props;
        const isRegionEmpty = (!regions || (regions && regions.length === 0));
        title = title?.trim();
        let filteredData = title ?
            dataCenters.filter(dataCenter => dataCenter.title.toLowerCase().includes(title.toLowerCase()))
            : dataCenters;

        filteredData = status !== "all" && !this.isViewDeletedItemsChecked
            ? filteredData?.filter(dataCenter => dataCenter.active === status || dataCenter.id < 0)
            : filteredData;

        if (!isRegionEmpty) {
            const filteredDataCenters = [];
            filteredData.forEach(dataCenter => {
                dataCenter.regions.forEach(region => {
                    if (regions.includes(region.name)) {
                        filteredDataCenters.push(dataCenter);
                    }
                })
            })
            filteredData = Array.from(new Set(filteredDataCenters.map(dataCenter => dataCenter.id)))
                .map(id => {
                    return {
                        id: id,
                        ...filteredDataCenters.find(dataCenter => dataCenter.id === id)
                    }
                })
        }
        this.filterSort = {
            ...this.filterSort,
            title,
            regions: !isRegionEmpty ? regions?.join(CONFIG.delimiters.selectFilter) : undefined,
        };
        return filteredData
    }

    handleExport = async (fileType) => {
        const { timeZoneName } = this.props;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const exportData = this.getFilteredData(this.titleSearchString, this.state.status, this.regionsSelected)

        if (exportData?.length > 0 && exportData !== undefined) {
            exportTable(CONFIG.exportTable.dataCenters.name, exportData, timeZoneName, CONFIG.exportTable.dataCenters.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    handleStatusChange = async (value) => {
        let filteredData = this.getFilteredData(this.titleSearchString, value, this.regionsSelected)
        await this.setState({ status: value, filteredData })
    }

    getRegionNamesString = (regions, delimiter = ' ', defaultString = '') => {
        return regions?.map(region => region.name)?.join(delimiter) || defaultString;
    }
    isViewDeletedItemsChecked = false;

    filterRecords = (filters) => {
        this.filters = filters;
        const { shouldUpdateFilteredData } = this.props;
        let { title, regions, viewDeletedItems } = filters;
        this.isViewDeletedItemsChecked = viewDeletedItems;
        const isRegionEmpty = (!regions || (regions && regions.length === 0));
        title = title?.trim();
        if (!title && isRegionEmpty && !viewDeletedItems) {
            // if (!this.state.filteredData) 
            pushMessage(CONFIG.messageType.warning, "Please enter search text to filter");
            // this.handleClear()
            return;
        }
        this.titleSearchString = title;
        this.regionsSelected = !isRegionEmpty ? regions : undefined;
        let filteredData = this.getFilteredData(title, this.state.status, regions)
        if (shouldUpdateFilteredData) this.props.toggleShouldUpdateFilteredDataFlag(false);
        this.setState(prevState => ({ filteredData, status: viewDeletedItems ? "false" : prevState.status }));
    }

    renderDeletedRecord = (filters) => {
        const { viewDeletedItems } = filters || {};
        if (viewDeletedItems || this.isViewDeletedItemsChecked) {
            this.props.toggleIsFetchingDataCentersFlag(true);
            this.props.fetchDataCenters(viewDeletedItems).then(() => {
                this.isViewDeletedItemsChecked = viewDeletedItems;
                this.filterRecords(filters);
            })
        }
        else this.filterRecords(filters);

    }

    handleFilter = (filters) => {
        let { title, viewDeletedItems } = filters;
        title = title?.trim();
        this.renderDeletedRecord(filters);
        this.filterSort = { ...this.filterSort, title, viewDeletedItems }
    }

    handleClear = (refresh) => {
        if (refresh) {
            this.props.refreshDataCenters();
            this.currentPage = 1;
            this.props.toggleIsFetchingDataCentersFlag(true);
            this.props.fetchDataCenters(this.isViewDeletedItemsChecked);
            this.props.toggleHasDataCentersUpdated(false)

        }
        else {
            this.titleSearchString = undefined;
            this.regionsSelected = undefined;
            this.statusSelected = undefined;
            if (this.isViewDeletedItemsChecked) {
                this.props.toggleIsFetchingDataCentersFlag(true);
                this.props.fetchDataCenters();
            }
            this.isViewDeletedItemsChecked = undefined;
            this.filterSort = {};
            this.setState({ filteredData: undefined });
        }

    }

    renderWarningMessage = (hasViewAccess) => {
        return (
            <Alert
                style={{ marginTop: "16px" }}
                message={hasViewAccess ? CONFIG.warningMessages.readOnlyEnvironmentAccess : CONFIG.warningMessages.noEnvironmentAccess}
                type="warning"
                showIcon
            />
        )
    }

    handleSave = () => {
        const dataCenterId = this.state.record?.id || -1;
        this.props.form.validateFields(async (error, values) => {
            if (error) {
                return;
            }
            const regions = values.regions.map(region => JSON.parse(region));
            const dataCenter = {
                id: dataCenterId,
                title: values.title,
                active: values.active ?? false,
                regions,
            }
            this.props.toggleIsFetchingDataCentersFlag(true);
            this.setState({
                formLoading: true, showEdit: false,
                isReadOnlyEdit: false,
            })
            if (dataCenterId < 0) {
                await this.props.postNewDataCenter(dataCenter)
            }
            else {
                await this.setState({ filteredData: undefined });
                await this.props.putDataCenter(dataCenter)
            }
            this.setState({
                record: null,
                formLoading: false,
            })
        });
    }

    handleDeleteRestore = (dataCenterId, isRestore = false) => {
        const hardDelete = this.isViewDeletedItemsChecked;
        if (this.props.userProfile?.isDeveloper && !isRestore && hardDelete) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
        else {
            checkDataCenterDependencies(dataCenterId, hardDelete)
                .then(dependencyList => this.renderDeleteRestoreConfirmation(dependencyList, dataCenterId, isRestore))
                .catch((err) => pushMessage(CONFIG.messageType.error, err.message))
        }
    }

    renderDeleteRestoreConfirmation = (dependencyList, dataCenterId, isRestore) => {
        const { resourcesCount, didsCount, networksCount, demoCollaterals, maintenanceCount } = dependencyList;
        const hardDelete = this.isViewDeletedItemsChecked;
        const text = isRestore ? "Restoration" : "Deletion"
        const okButtonText = isRestore ? "Confirm Restore" : hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
        let collaterals = [];

        demoCollaterals.forEach(demo => {
            let democollateral = demo.collaterals?.map(collateral => ({ ...collateral, demoTitle: demo.demoTitle }))
            collaterals.push(...democollateral)
        })

        const showDependencyList = resourcesCount !== 0 || didsCount !== 0 || networksCount !== 0 || demoCollaterals.length !== 0 || maintenanceCount !== 0;
        const modalTitle = isRestore ? CONFIG.restoreModalTitle.replace('<placeholder>', 'data center') : showDependencyList ? CONFIG.dependencyModalTitle : CONFIG.deleteModalTitle.replace('<placeholder>', 'data center')
        const content = <>
            {hardDelete && !isRestore ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Data Center")}</h4> : !isRestore ? <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "Data Center")}</h4> : ""}
            {showDependencyList &&
                <span>
                    <h4>{text} of selected data center would affect</h4>
                    <span style={{ color: "#242424" }}>
                        {resourcesCount} * Resources <br />
                        {didsCount} * DIDs <br />
                        {networksCount} * Networks <br />
                        {maintenanceCount} * Maintenances <br />
                        {collaterals.length} * Collaterals <br /><br />
                        {collaterals.map((collateral, index) => {
                            return <ul>
                                <li key={index}>
                                    {collateral.demoTitle + ' - ' + collateral.title}
                                </li>
                            </ul>
                        })}
                    </span>
                </span>
            }
        </>
        openConfirm(okButtonText,
            () => {
                this.props.toggleIsFetchingDataCentersFlag(true);
                if (isRestore) {
                    this.props.restoreDataCenter(dataCenterId)
                } else this.props.deleteDataCenter(dataCenterId, hardDelete)
                    .then(async (response) => response === CONFIG.HTTP_STATUS.OK && await this.setState({ filteredData: undefined }))
            }
            , () => Modal.destroyAll(), content, modalTitle);
    }


    renderFilterPanel = () => {
        const { templateType, dataCentersAccess } = this.props;
        const isSuperAdmin = templateType === CONFIG.roleTypes.superAdmin;
        const hasEditAccess = isSuperAdmin || dataCentersAccess === CONFIG.roleTemplateAccess.fullAccess;
        return (
            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                {hasEditAccess && <div style={{ display: "flex", flex: "1" }}>
                    <NeoButton onClick={() => this.handleEditClick(null, true)}>Create Data Center</NeoButton>
                </div>}
                <div style={{ display: "flex", width: "100%", flex: "1", flexDirection: "row-reverse", gap: "24px" }}>
                    <div >
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoButton className="export-download-button" icon="chevron-down" variant="secondary">Download</NeoButton>
                        </Dropdown>
                    </div>
                    <div >
                        <NeoIcon aria-label="menu-aria" size="md" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={(this.state.showFilter || (!checkIfFilterSortIsEmpty({ filter: this.filterSort }))) ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                    <div >
                        <NeoIcon aria-label="menu-aria" size="md" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                </div>
            </div>
        )
    }

    renderTabContents = () => {
        return (
            <React.Fragment>
                <div>
                    {this.renderFilterPanel()}
                </div>
                <div className="height-neo-table">
                    {this.renderTable()}
                </div>
            </React.Fragment>
        )
    }

    handleEditClick = (record, isReadOnly) => {
        this.setState({
            showEdit: true,
            isReadOnlyEdit: isReadOnly,
            record
        })
    }

    renderTable = () => {
        const tableData = this.getFilteredData(this.titleSearchString, this.state.status, this.regionsSelected)
        const deleteView = this.isViewDeletedItemsChecked;
        const { templateType, dataCentersAccess, isFetching } = this.props;
        const isSuperAdmin = templateType === CONFIG.roleTypes.superAdmin;
        const hasEditAccess = isSuperAdmin || dataCentersAccess === CONFIG.roleTemplateAccess.fullAccess;
        const isDisabled = !hasEditAccess;;
        const neoColumns = [
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center", }}>{a?.cell?.row?.original?.title}</span>
                },
                Header: "Title",
                sortType: "string",
                accessor: "title",
                disableFilters: true,
            },
            {
                Cell: (a) => {
                    const regions = a?.cell?.row?.original?.regions;
                    return <span style={{ textAlign: "center" }}>{regions ? regions.map((region) => region.name).join(", ") : undefined}</span>
                },
                Header: "Regions",
                accessor: "regions",
                disableFilters: true,
                sortType: (rowA, rowB) => {
                    const regionA = this.getRegionNamesString(rowA?.original?.regions);
                    const regionB = this.getRegionNamesString(rowB?.original?.regions);
                    if (regionA < regionB) {
                        return -1;
                    }
                    if (regionA > regionB) {
                        return 1;
                    }
                    return 0;
                }
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{<NeoIcon aria-label="menu-aria" style={a?.cell?.row?.original?.active ? { color: "#088A08" } : { color: "#DA291C" }} icon={a?.cell?.row?.original?.active ? "available" : "missed"} />}</span>
                },
                Header: "Status",
                sortType: "basic",
                disableFilters: true,
                accessor: "isActive",
            },
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    return (
                        <React.Fragment>
                            <div style={{ display: "flex", gap: "24px" }}>
                                {!deleteView && <div>
                                    <Tooltip title={!hasEditAccess ? CONFIG.warningMessages.noAccess : "Edit"}>
                                        <button
                                            disabled={isDisabled}
                                            onClick={() => this.handleEditClick(record, false)}
                                            className={isDisabled ? "link-disabled" : "link"}
                                        >
                                            <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                </div>}
                                <div>
                                    <Tooltip title={!hasEditAccess ? CONFIG.warningMessages.noAccess : "Delete"}>
                                        <button
                                            disabled={isDisabled}
                                            onClick={() => { this.handleDeleteRestore(record.id) }}
                                            className={isDisabled ? "link-disabled" : "link"}
                                        >
                                            <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                </div>
                                {deleteView &&
                                    <Tooltip title={!hasEditAccess ? CONFIG.warningMessages.noAccess : "Restore"}>
                                        <button
                                            disabled={isDisabled}
                                            onClick={() => this.handleDeleteRestore(record.id, true)}
                                            className={isDisabled ? "link-disabled" : "link"}
                                        >
                                            <span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span>
                                        </button >
                                    </Tooltip>
                                }
                            </div>
                        </React.Fragment>
                    )
                },
                Header: "  ",
                disableFilters: true,
                disableSortBy: true
            }
        ]
        return (
            isFetching ? null : <NeoTable
                columns={neoColumns}
                data={tableData || []}
                className="table-actions"
                allowColumnFilter={false}
                itemsPerPageOptions={[10, 20, 50, 100]}
                showRowSeparator
                initialStatePageIndex={this.state.currentPage}
                handlePageChange={(newPageIndex, newPageSize) => {
                    this.setState({ currentPage: newPageIndex })
                }}
                {...otherFields}
            />
        )
    }

    render() {
        const { templateType, dataCentersAccess } = this.props;
        const isSuperAdmin = templateType === CONFIG.roleTypes.superAdmin;
        const hasViewAccess = isSuperAdmin || dataCentersAccess === CONFIG.roleTemplateAccess.readOnly;
        const hasEditAccess = isSuperAdmin || dataCentersAccess === CONFIG.roleTemplateAccess.fullAccess;

        const formFields = [
            {
                label: "Title",
                type: CONFIG.formFieldTypes.INPUT,
                key: "title",
                value: this.filterSort?.title,
                colSpan: 24,
            },
            {
                label: "Regions",
                type: CONFIG.formFieldTypes.SELECT,
                key: "regions",
                data: [...this.props.regions.filter(region => region.name === 'ALL'), ...this.props.regions.filter(region => region.name !== 'ALL')],
                valueKey: "name",
                colSpan: 24,
                dataKey: "name",
                value: this.filterSort?.regions
            }
        ]
        const drawerContainer = document.getElementById('#component-content');
        return (
            <div>
                <div style={{ color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px", marginTop: "28px" }}>
                    Manage Data Center
                </div>
                <Spin spinning={this.props.isFetching} indicator={antIcon}>
                    {
                        (hasEditAccess || hasViewAccess) ? <>{!hasEditAccess && <Row >{this.renderWarningMessage(hasViewAccess)}</Row>}
                            <div style={{ marginTop: "16px" }}>
                                <Tabs disabled={this.isViewDeletedItemsChecked} activeKey={this.state.status} className='demo-status-tabs' defaultActiveKey={this.state.status} onChange={this.handleStatusChange}>
                                    <TabPane disabled={this.isViewDeletedItemsChecked} tab="Active" key="true">
                                        {this.renderTabContents()}
                                    </TabPane>
                                    <TabPane tab="Inactive" key="false">
                                        {this.renderTabContents()}
                                    </TabPane>
                                    <TabPane disabled={this.isViewDeletedItemsChecked} tab="All" key="all">
                                        {this.renderTabContents()}
                                    </TabPane>
                                </Tabs>
                            </div>
                            <Row>
                                {this.state.showFilter && (
                                    <FilterDrawer
                                        visible={this.state.showFilter}
                                        width='520'
                                        handleCloseFilterDrawer={() => this.setState(prevState => ({
                                            showFilter: false,
                                            record: null
                                        }))}
                                        placement="right"
                                        drawerContainer={drawerContainer}
                                        style={{ zIndex: 9999, }}
                                        maskClosable={false}
                                        panelHeader="Filters and Export"
                                        formFields={formFields}
                                        handleClear={this.handleClear}
                                        timeZone={this.props.timeZoneName}
                                        hideSoftDeleteCheckbox={false}
                                        label="View Deleted Data Centers"
                                        hideSave={true}
                                        viewDeletedItemsValue={this.filterSort?.viewDeletedItems}
                                        handleSave={null}
                                        handleFilter={this.handleFilter}
                                        getSearchData={null}
                                    />)}
                            </Row>
                            {
                                this.state.showEdit && <EditDrawer
                                    visible={this.state.showEdit}
                                    width='520'
                                    handleCloseFilterDrawer={() => this.setState({ showEdit: false })}
                                    placement="right"
                                    drawerContainer={drawerContainer}
                                    style={{ zIndex: 9999, }}
                                    maskClosable={false}
                                    timeZone={this.props.timeZoneName}
                                    readOnly={false}
                                    form={this.props.form}
                                    handleEditClick={this.handleEditClick}
                                    handleSave={this.handleSave}
                                    record={this.state.record}
                                    okButtonText={(!this.state.isReadOnlyEdit) ? "Save" : "Create Data Center"}
                                    cancelButtonText="Close"
                                    showEdit={true}
                                    filterSort={this.filterSort}
                                    title={`${(!this.state.isReadOnlyEdit) ? "Edit" : "Create"} Data Center`}
                                >
                                    <Spin spinning={this.state.formLoading} indicator={antIcon}>
                                        <DataCenterDetails
                                            form={this.props.form}
                                            record={this.state.record}
                                            regions={this.props.regions}
                                            readOnly={this.state.isReadOnlyEdit} />
                                    </Spin>
                                </EditDrawer>
                            }
                            <br />
                        </>
                            :
                            this.renderWarningMessage()
                    }
                </Spin>
            </div >
        );
    }
}

const mapStateToProps = ({ dataCenters, user, tabsLayout }) => {
    return {
        dataCenters: dataCenters.data,
        isFetching: dataCenters.isFetching,
        editingKey: dataCenters.editingKey,
        templateType: user.profile.roleTemplate.templateType,
        userProfile: user.profile,
        dataCentersAccess: user.profile.dataCentersAccess,
        regions: user.regions,
        shouldUpdateFilteredData: dataCenters.shouldUpdateFilteredData,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
        isEdit: dataCenters.isEdit,
        status: dataCenters.status,
        currentFilter: dataCenters.currentFilter,
        currentPage: dataCenters.currentPage,
    };
};

const EditableFormTable = Form.create()(ManageDataCenters);

export default connect(
    mapStateToProps,
    {
        fetchDataCenters,
        addDataCenter,
        updateDataCenter,
        removeEmptyDataCenter,
        postNewDataCenter,
        toggleIsFetchingDataCentersFlag,
        putDataCenter,
        updateDataCenterEditingKey,
        toggleBackButton,
        selectTitle,
        clearDataCenters,
        deleteDataCenter,
        restoreDataCenter,
        toggleShouldUpdateFilteredDataFlag,
        exportTable,
        refreshDataCenters,
        toggleHasDataCentersUpdated,
        currentDataCentersFilter,
        toggleHasUnsavedChangesFlag
    }
)(EditableFormTable);

import React from "react";
import { batch, connect } from "react-redux";
import { Form, Spin, Modal, Row, Collapse, Divider, Icon, Alert, Button } from 'antd';
import {
  toggleBackButton,
  fetchDemo,
  postNewDemo,
  toggleIsFetchingDemosFlag,
  updateDemoSaveWarning,
  deleteUnassociatedCollaterals,
  fetchRoleTemplates,
  fetchAdminRoleTemplates,
  getLDAPUserGroups,
  toggleIsUserLoading,
  clearCurrentDemo,
  fetchDataCenters,
  clearNewDemoCollaterals,
  resetDemoStatus,
  currentDemoFilter,
  updateDemosCurrentPage,
  selectTitle,
  fetchActiveResources,
  fetchActiveCapabilities,
  fetchAllDemoQuestions,
  clearDemoQuestions,
  currentActivePanel,
  addDemoTabDetails,
  updateTabsLocalStorage,
  fetchCurrentOpenTabDemoDetails,
  updateCurrentTabDemoDetails,
  fetchDemoCollateralTypes,
  addDemoTabDetailsOnCreate,
  closeCurrentOpenTab,
  handleOnClickAction,
  resetDemoForm,
  toggleHasUnsavedChangesFlag,
  closeActiveTab,
  fetchAllUsersForDemo,
  fetchAllManageEZDemos
} from '../../actions';
import DemoCollateral from './DemoCollateral';
import DemoDetails from './DemoDetails';
import DemoResources from './DemoResources';
import DemoQuestions from './DemoQuestions';
import DemoBasicDetails from "./DemoBasicDetails";
import DemoCapability from "./DemoCapability";
import RestfulAPIDemoDetails from './RestfulAPIDemoDetails';
import EZDemos from "./EZDemos";
import openConfirm from '../common/Confirm';
import openInfo from '../common/Info';
import CONFIG from "../../config";
import history from '../../history';
import pushMessage from '../common/PushMessage';
import { fetchEmailsBySearchString } from '../../actions/reports';
import { isTabAlreadyOpen, isTabRestoredFromLocalStorage } from "../common/TabLayout";
import { checkIfDemoHasChanged, getCollateralObjs } from "../../utils/formChanged";
const { Panel } = Collapse;
const DemoForm = Form.create({ name: 'demo_form' })(
  class DemoForm extends React.Component {
    constructor(props) {
      super(props);
      this.isEditMode = false;
      this.isProduction = false;
      this.isRatingReset = false;
      this.state = {
        demoType: CONFIG.demoTypes.standardDemo,
        matchedUserNames: [],
        createStatus: undefined,
        deleteStatus: undefined,
        showPreRegistrationPanel: false,
        currentActiveKey: "2",
        title: "",
        isDemoClone: false,
        fromAPI: false,
        loading: true
      }
    }
    warningDisplayed = false;

    handleDemoTypeChange = (value) => {
      this.setState({ demoType: value })
    }

    renderWarningMessage = () => {
      return (
        <Alert
          message="Collaterals, Questions and Capabilities will not be cloned. Please add new collaterals, questions and capabilities."
          type="warning"
          showIcon
        />
      )
    }

    handlePreRegistrationToggle = (value) => {
      this.setState({ showPreRegistrationPanel: value })
    }

    handleClose = () => history.goBack();

    handleStatusChange = (type, value) => {
      this.setState({ [type]: value })
    }

    handleSave = () => {
      const { createStatus, deleteStatus } = this.state;
      const { oldDemo } = this.props;
      const demo = this.getDemoObject(this.props.form.getFieldsValue());
      this.props.form.validateFields(async (err, values) => {
        if (err) {
          pushMessage(CONFIG.messageType.warning, 'Certain form fields require your attention. Please review.');
          return
        }
        if (!err && (values.isActive && !values.isManual && values.demoType !== CONFIG.demoTypes.standardDemo ? !(createStatus && deleteStatus) : false)) {
          let hasChanged = JSON.stringify(demo?.externalApis) !== JSON.stringify(oldDemo?.demoBasicDetails?.externalApis);
          if (!this.isEditMode) {
            if (!(createStatus && deleteStatus)) openInfo(CONFIG.warningMessages.demoTestConnection.title, CONFIG.warningMessages.demoTestConnection.content, null, "OK");
          }
          else {
            if (hasChanged) {
              if (!(createStatus && deleteStatus)) openInfo(CONFIG.warningMessages.demoTestConnection.title, CONFIG.warningMessages.demoTestConnection.content, null, "OK");
            }
            else this.handleConfirmation(values);
          }
        }
        else if (!err) {
          this.handleConfirmation(values);
        }
      });
    }

    handleConfirmation = async (values) => {
      const isDemoClone = window.location.pathname.split('/').slice(-2)[0] === "clone";
      this.isProduction = this.props.form.getFieldValue('isProduction');
      this.isRatingReset = this.props.form.getFieldValue('isRatingReset');
      this.props.form.setFieldsValue({ isProduction: false });
      await this.props.postNewDemo(values, null, isDemoClone, this.props.match.params?.demoId)
        .then((response) => {
          this.handlePostResponse(response);
        })
    }

    handleConfirmOK = async () => {
      const isDemoClone = window.location.pathname.split('/').slice(-2)[0] === "clone";
      await this.props.postNewDemo(this.props.form.getFieldsValue(), false, isDemoClone, this.props.match.params?.demoId)
        .then((response) => this.handlePostResponse(response))
        .then(
          () => {
            this.props.form.setFieldsValue({ isProduction: false })
            this.props.form.setFieldsValue({ isRatingReset: false })
          }
        );
    }

    handleConfirmCancel = () => {
      this.props.updateDemoSaveWarning();
      Modal.destroyAll();
    }

    handlePostResponse = async (response) => {
      if (response !== undefined) {
        await this.props.toggleIsFetchingDemosFlag(true);
        const currentTitle = this.props.form.getFieldValue('title');
        await this.props.closeCurrentOpenTab();
        setTimeout(async () => {
          await this.props.handleOnClickAction({
            component: CONFIG.editComponentRoute.demos,
            tabTitle: currentTitle,
            recordId: response?.demoId
          })
          if (response.collaterals) {
            this.props.deleteUnassociatedCollaterals(response.collaterals)
          }
        }, 100)
        // await this.props.toggleIsFetchingDemosFlag(false);
      }

      if (this.props.warningMessage) {
        this.props.updateDemoSaveWarning();
      }
      else {
        const value = this.props.form.getFieldValue('isRatingReset') || this.props.form.getFieldValue('notifyUsers');
        if (value) {
          this.props.form.setFieldsValue({ isRatingReset: false, notifyUsers: false });
        }
      }
    }

    renderActivationConfrim = () => {
      const { warningMessage } = this.props;
      openConfirm('Yes', this.handleConfirmOK, this.handleConfirmCancel, warningMessage);
    }

    handleCancel = async () => {
      await this.checkCollateralsHasChanged();
      this.props.closeCurrentOpenTab();
    }

    renderFormButtons = () => {
      return (
        <div>
          <Row className="right-align vertical-spacing" >
            <Button onClick={this.handleCancel} className={`cancel-button button-width-medium`} id='demo-discard-changes'>
              {'Discard & Close'}
            </Button>
            <Button disabled={!this.hasDemoChanged}
              className={`left-spacing primary-action-button-filled button-width-medium`}
              onClick={this.handleSave} id='demo-save-changes'>
              {"Save"}
            </Button>
          </Row>
        </div>
      );
    }

    async componentWillUnmount() {
      const { currentTabData } = this.props;
      const isDemoClone = window.location.pathname.split('/').slice(-2)[0] === "clone";
      const newCollaterals = this.props.newCollaterals
      if (newCollaterals) this.props.deleteUnassociatedCollaterals(newCollaterals);
      this.props.toggleIsUserLoading(false);

      if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: window.location.pathname })) {
        this.props.addDemoTabDetails({
          demoId: this.props.match.params?.demoId !== undefined ? this.props.match.params?.demoId : -1,
          basicDetails: { ...currentTabData?.demoBasicDetails, ...this.getDemoObject(this.props.form.getFieldsValue()), currentCollapsePanelKey: this.state.currentActiveKey || "1" },
          demo: currentTabData?.originalDemoObj || [],
          fromAPI: false,
          isDemoClone
        })
      }
      else {
        const { demoId } = this.props.match.params;
        const id = demoId !== undefined ? demoId : -1;
        await this.props.resetDemoForm(id, isDemoClone);
      }
      this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
    }

    checkCollateralsHasChanged = async () => {
      const { oldDemo, currentTabData } = this.props;
      let newCollaterals = currentTabData?.demoCollaterals?.assignedCollaterals;
      let oldCollaterals = oldDemo?.demoCollaterals?.assignedCollaterals;
      newCollaterals = getCollateralObjs(newCollaterals);
      oldCollaterals = getCollateralObjs(oldCollaterals);
      let hasChanged = JSON.stringify(newCollaterals) !== JSON.stringify(oldCollaterals);
      if (hasChanged) {
        const results = newCollaterals?.filter(({ id: id1 }) => !oldCollaterals?.some(({ id: id2 }) => id2 === id1));
        if (results) {
          await this.props.deleteUnassociatedCollaterals(results);
        }
      }
    }

    fetchDemosFromAPI = async (adminAccess) => {
      const isDemoClone = window.location.pathname.split('/').slice(-2)[0] === "clone";
      const { demoId } = this.props.match.params;
      this.props.toggleIsFetchingDemosFlag(true);
      await this.props.fetchDemo({ demoId, updateCollateral: null, isDemoClone })
        .then(async () => {
          await this.props.fetchCurrentOpenTabDemoDetails(demoId, isDemoClone);
        })
        .then(async () => {
          const { demoType, title, isPreRegistration, currentCollapsePanelKey } = this.props
          await this.setState({ demoType: demoType, title: isDemoClone ? (title + "_clone") : title });
          this.setState({ showPreRegistrationPanel: isPreRegistration })
          setTimeout(() => {
            this.setState({ currentActiveKey: currentCollapsePanelKey, loading: false });
          }, 1800)
          this.setState({ fromAPI: true })
        })
    }

    async componentDidMount() {
      document.getElementById('tab-pane').scrollIntoView()
      const { demoId } = this.props.match.params;
      const { previousTabs } = this.props;
      const isDemoClone = window.location.pathname.split('/').slice(-2)[0] === "clone";
      await this.setState({ isDemoClone: isDemoClone });
      this.isEditMode = isDemoClone ? false : this.props.match.params.demoId ? true : false;
      const { userRoleTemplate, } = this.props;
      const currentPath = window.location.pathname;
      const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin
      const adminAccess = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined
      const fetchAllRoleTemplates = isAdmin ? this.props.fetchAdminRoleTemplates : this.props.fetchRoleTemplates;
      demoId && this.setState({ currentActiveKey: "2" });
      batch(async () => {
        this.props.toggleBackButton(false);
        this.props.toggleIsFetchingDemosFlag(true);
        this.props.selectTitle("Manage Demo");
        await this.props.getLDAPUserGroups();
        await this.props.fetchActiveResources();
        // await this.props.fetchAllUsersForDemo();
        await this.props.fetchActiveCapabilities();
        await this.props.fetchDemoCollateralTypes();
        await this.props.fetchAllDemoQuestions({ getAll: true, access: adminAccess, status: true });
        await this.props.fetchAllManageEZDemos();
        await this.props.fetchDataCenters()
          .then(() => {
            this.props.resetDemoStatus();
            fetchAllRoleTemplates()
          })
          .then(async () => {
            this.props.toggleIsFetchingDemosFlag(true)
            if (isTabAlreadyOpen({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
              if (isTabRestoredFromLocalStorage({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
                batch(async () => {
                  if (demoId) await this.fetchDemosFromAPI(adminAccess)
                  else {
                    await this.props.addDemoTabDetailsOnCreate()
                    await this.props.fetchCurrentOpenTabDemoDetails(-1, false);
                  }
                  this.props.updateTabsLocalStorage(currentPath);
                });
              }
            } else {
              if (demoId) await this.fetchDemosFromAPI()
              else {
                await this.props.addDemoTabDetailsOnCreate()
                await this.props.fetchCurrentOpenTabDemoDetails(-1, false);
              }
            }
          })
          .then(() => {
            this.props.currentActivePanel(this.props.currentCollapsePanelKey)
            this.setState({
              demoType: this.props.demoType || CONFIG.demoTypes.standardDemo,
            });
            this.handleFormValues();
          })
      })
      await this.props.fetchCurrentOpenTabDemoDetails(demoId || -1, isDemoClone);
      this.descriptionKey = Math.random().toString();
      this.componentDetailsKey = Math.random().toString();
      this.accessKey = Math.random().toString();
    }

    handleFormValues = async () => {
      await this.props.toggleIsFetchingDemosFlag(true)
      setTimeout(() => {
        this.setState({ currentActiveKey: "1" });
      }, 500)
      setTimeout(() => {
        this.setState({ currentActiveKey: "2" });
      }, 500)
      setTimeout(async () => {
        this.setState({ currentActiveKey: this.props.currentCollapsePanelKey || "1", loading: false });
        await this.props.toggleIsFetchingDemosFlag(false)
      }, 500)
    }

    handleDemoTitleChange = (value) => {
      this.setState({ title: value })
    }

    async componentDidUpdate(prevProps) {
      const { openedTabs, activeKey, userRoleTemplate } = this.props;
      const isEdit = window.location.pathname.includes("/demos/edit")
      if (this.props.warningMessage &&
        (this.isProduction === true ? true : prevProps.form.getFieldValue("isProduction") === true ? false : true)
      ) {
        this.renderActivationConfrim();
        if (this.isProduction) {
          this.props.form.setFieldsValue({ isProduction: this.isProduction });
          this.isProduction = false;
        }
      }
      const isSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin
      this.hasDemoChanged = isEdit ? checkIfDemoHasChanged(this.props.currentTabData, this.props.oldDemo, isSuperAdmin, this.getDemoObject(this.props.form.getFieldsValue())) : true;
      const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
      if (!hasUnsavedChanges && this.hasDemoChanged)
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasDemoChanged);
      else if (hasUnsavedChanges && !this.hasDemoChanged)
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasDemoChanged);

    }

    fetchUserEmails = searchString => {
      fetchEmailsBySearchString({ searchString })
        .then((result) => { result.length !== 0 ? this.setState({ matchedUserEmails: [...new Set(result.map(item => item.email))] }) : this.setState({ matchedUserEmails: [] }) });
    };

    getDemoApproversList = (demoApprovers) => {
      let approversList = []
      if (demoApprovers) demoApprovers.forEach((approver, index) => (approver) && approver.forEach(item => approversList.push({ level: index, approver: item.trim() })))
      return approversList
    }

    getRegionalManagersList = (primes) => {
      let primeList = []
      if (primes) primes.forEach((approver, index) => primeList.push({ regionalPrime: JSON.parse(approver) }));
      return primeList;
    }

    getDemoObject = (demoBasicDetails) => {
      const oldDemo = this.props.oldDemo;
      return {
        id: this.props.demoBasicDetails?.id,
        title: demoBasicDetails.title,
        startTimeBuffer: demoBasicDetails.demoType === CONFIG.demoTypes.standardDemo ? demoBasicDetails.startTimeBuffer : demoBasicDetails.buildDuration * 60 || 0,
        endTimeBuffer: demoBasicDetails.endTimeBuffer || 0,
        isActive: demoBasicDetails.isActive,
        isExpired: demoBasicDetails.isExpired,
        isProduction: demoBasicDetails.isProduction,
        isRatingReset: demoBasicDetails.isRatingReset,
        isPreRegistration: demoBasicDetails.isPreRegistration,
        isOneTime: demoBasicDetails.isOneTime || false,
        schTimeLimit: demoBasicDetails.schTimeLimit ? demoBasicDetails.schTimeLimit * 60 : 0 || 0,
        notifyUsers: demoBasicDetails.notifyUsers,
        roletemplate: undefined,
        demoType: demoBasicDetails.demoType,
        maxSchedule: demoBasicDetails.maxSchedule || null,
        autoDestroy: demoBasicDetails.autoDestroy !== undefined ? demoBasicDetails.autoDestroy : false,
        vdiRequired: demoBasicDetails.vdiRequired,
        scheduleRange: demoBasicDetails.scheduleRange || null,
        isManual: demoBasicDetails.isManual !== undefined ? demoBasicDetails.isManual : false,
        reminderHours: demoBasicDetails?.reminderHours || 2,
        parameters: (demoBasicDetails.parameters && demoBasicDetails.parameters?.length > 0) ? demoBasicDetails.parameters?.join(CONFIG.delimiters.selectFilter) : null,
        externalApis: demoBasicDetails.isManual ? null : [
          {
            id: oldDemo?.demoBasicDetails?.externalApis?.[0]?.id,
            url: demoBasicDetails.createURL || null,
            methodType: demoBasicDetails.createMethodType || null,
            phase: "BUILD_DEMO",
            // eslint-disable-next-line
            headerJson: demoBasicDetails.createHeaderKeys && demoBasicDetails.createHeaderKeys?.length !== 0 ? JSON.stringify(demoBasicDetails.createHeaderKey?.reduce((acc, curr, index) => { (acc[curr] = demoBasicDetails.createHeaderValue[index], acc) }, {})) : null,
            bodyJson: demoBasicDetails.createBodyJson || JSON.stringify({}),
            authorisationJson: JSON.stringify({ userName: demoBasicDetails.createAuthUserName, password: demoBasicDetails.createAuthPassword }),
            // eslint-disable-next-line
            paramsJson: demoBasicDetails.createParamKeys && demoBasicDetails.createParamKeys?.length !== 0 ? JSON.stringify(demoBasicDetails.createParamKey?.reduce((acc, curr, index) => (acc[curr] = demoBasicDetails.createParamValue[index], acc), {})) : null
          },
          {
            id: oldDemo?.demoBasicDetails?.externalApis?.[1]?.id,
            url: demoBasicDetails.deleteURL || null,
            methodType: demoBasicDetails.deleteMethodType || null,
            phase: "DELETE_DEMO",
            // eslint-disable-next-line
            headerJson: demoBasicDetails.deleteHeaderKeys && demoBasicDetails.deleteHeaderKeys?.length !== 0 ? JSON.stringify(demoBasicDetails.deleteHeaderKey?.reduce((acc, curr, index) => (acc[curr] = demoBasicDetails.deleteHeaderValue[index], acc), {})) : null,
            bodyJson: demoBasicDetails.deleteBodyJson || JSON.stringify({}),
            authorisationJson: JSON.stringify({ userName: demoBasicDetails.deleteAuthUserName, password: demoBasicDetails.deleteAuthPassword }),
            // eslint-disable-next-line
            paramsJson: demoBasicDetails.deleteParamKeys && demoBasicDetails.deleteParamKeys?.length !== 0 ? JSON.stringify(demoBasicDetails.deleteParamKey?.reduce((acc, curr, index) => (acc[curr] = demoBasicDetails.deleteParamValue[index], acc), {})) : null
          }
        ],
        demoApprovers: this.getDemoApproversList(demoBasicDetails.demoApprovers),
        isManagerMandatory: demoBasicDetails.isManagerMandatory || false,
        approvalRequired: demoBasicDetails.approvalRequired || false,
        managerTitle: demoBasicDetails.managerTitle,
        notes: demoBasicDetails.notes,
        isManagerEmail: demoBasicDetails.isManagerEmail || false,
        regionalPrimeTitle: demoBasicDetails.regionalPrimeTitle,
        isRegionalPrime: demoBasicDetails.isRegionalPrime || false,
        isMultipleApprover: demoBasicDetails.isMultipleApprover || false,
        demoRegionalPrimes: this.getRegionalManagersList(demoBasicDetails.regionalPrimes)
      };
    }

    renderPanelHeader = (headerText, icon) => {
      return (
        <span className="large-text bold">
          <Icon aria-label="aria-menu" className="primary-text-color" type={icon} />
          &nbsp;&nbsp;&nbsp;
          {headerText}
        </span>
      )
    }

    render() {
      const editorKeys = {
        descriptionKey: this.descriptionKey,
        componentDetailsKey: this.componentDetailsKey,
        accessKey: this.accessKey
      };
      const { demoBasicDetails } = this.props;
      const { demoId } = this.props.match.params;
      const isDemoClone = window.location.pathname.split('/').slice(-2)[0] === "clone";
      const { currentActiveKey, loading } = this.state;
      const { demoType, showPreRegistrationPanel, title } = this.state;
      const isPreRegistrationToggleOn = this.props.form.getFieldValue("isPreRegistration");
      const actualTitle = this.state.title || demoBasicDetails?.title
      return (
        <>
          <Spin spinning={this.props.isFetchingDemo || loading} wrapperClassName="spin-overlay" >
            <div className="content-container" >
              {(actualTitle ?? "") !== "" && <div className="fixed-title" > {actualTitle} <Divider /> </div>}
              {this.renderFormButtons()}
              {isDemoClone && this.renderWarningMessage()}
              <Collapse
                style={{ backgroundColor: 'white' }}
                // defaultActiveKey={"1"}
                activeKey={currentActiveKey}
                onChange={(key) => { this.setState({ currentActiveKey: key }) }}
                // accordion
                bordered={false}
                expandIconPosition='right'
                expandIcon={({ isActive }) => <Icon aria-label="aria-menu" type="caret-right" rotate={isActive ? 90 : 0} />}
              >
                <Panel header={this.renderPanelHeader('Basic Details', 'profile')} key="1" >
                  <DemoBasicDetails
                    demoId={demoId && parseInt(demoId)}
                    isEditMode={this.isEditMode}
                    form={this.props.form}
                    demoType={this.state.demoType}
                    handleDemoTypeChange={this.handleDemoTypeChange}
                    handlePreRegistrationToggle={this.handlePreRegistrationToggle}
                    handleDemoTitleChange={this.handleDemoTitleChange}
                    fromAPI={this.state.fromAPI}
                    isDemoClone={isDemoClone} />
                </Panel>
                {demoType !== CONFIG.demoTypes.standardDemo &&
                  <Panel id="demo-form-lab-details" header={this.renderPanelHeader(demoType === CONFIG.demoTypes.restfulApiDemo ? "Restful API Demo" : "Lab & POC Demo", 'cloud')} key="2" >
                    <RestfulAPIDemoDetails
                      demoId={demoId && parseInt(demoId)}
                      demoTitle={title}
                      isEditMode={this.isEditMode}
                      form={this.props.form}
                      demoType={this.state.demoType}
                      fetchUserEmails={this.fetchUserEmails}
                      matchedUserEmails={this.state.matchedUserEmails}
                      createStatus={this.state.createStatus}
                      deleteStatus={this.state.deleteStatus}
                      toggleIsFetchingDemosFlag={this.props.toggleIsFetchingDemosFlag}
                      setStatus={this.handleStatusChange} />
                  </Panel>}
                <Divider />
                <Panel header={this.renderPanelHeader('Additional Details', 'diff')} key="3" >
                  <DemoDetails
                    demoTitle={title}
                    form={this.props.form}
                    isEditMode={this.isEditMode}
                    editorKeys={editorKeys}
                    demoId={demoId && parseInt(demoId)}
                    demoType={this.state.demoType} />
                  <br />
                </Panel>
                <Divider />
                <Panel id="demo-form-collateral" header={this.renderPanelHeader('Manage Collateral', 'file-add')} key="4" >
                  <DemoCollateral demoTitle={title} isEditMode={this.isEditMode} demoId={demoId && parseInt(demoId)} /><br />
                </Panel>
                <Divider />
                <Panel header={this.renderPanelHeader('Manage EZ Demos', 'file-image')} key="5" >
                  <EZDemos demoTitle={title} isEditMode={this.isEditMode} demoId={demoId && parseInt(demoId)} /><br />
                </Panel>
                <Divider />
                {demoType === CONFIG.demoTypes.standardDemo && <Panel id="demo-form-resources" header={this.renderPanelHeader('Assign Resources', 'solution')} key="6" ><br />
                  <DemoResources demoTitle={title} />
                </Panel>}
                <Divider />
                {(showPreRegistrationPanel || isPreRegistrationToggleOn) && <Panel header={this.renderPanelHeader('Assign Pre-registration Questions', 'form')} key="7" >
                  <DemoQuestions demoTitle={title} isEditMode={this.isEditMode} editorKeys={editorKeys} demoType={this.state.demoType} questionType={CONFIG.demoQuestionTypes?.[1]?.value} isDemoClone={isDemoClone} /><br />
                </Panel>}
                {(showPreRegistrationPanel || isPreRegistrationToggleOn) && <Divider />}
                <Panel header={this.renderPanelHeader('Assign Additional Information Questions', 'form')} key="8" >
                  <DemoQuestions
                    demoId={demoId && parseInt(demoId)}
                    demoTitle={title}
                    isEditMode={this.isEditMode}
                    editorKeys={editorKeys}
                    demoType={this.state.demoType}
                    questionType={CONFIG.demoQuestionTypes?.[0]?.value}
                    isDemoClone={isDemoClone} />
                  <br />
                </Panel>
                <Divider />
                <Panel header={this.renderPanelHeader('Assign Capabilities', 'import')} key="9" >
                  <DemoCapability
                    demoId={demoId && parseInt(demoId)}
                    demoTitle={title}
                    isEditMode={this.isEditMode}
                    editorKeys={editorKeys}
                    form={this.props.form}
                    demoType={this.state.demoType}
                    isDemoClone={isDemoClone} />
                  <br />
                </Panel>
                <Divider />
              </Collapse>
              <br />
              {this.renderFormButtons()}
            </div>
          </Spin>
        </>
      );
    }
  }
)
const mapStateToProps = ({ demos, demoForm, demoCollaterals, user, demoQuestionsAssociation, demoComponentDetails, demoAccess, demoDescription, demoResources, demoStatus, roleTemplates, tabsLayout, openTabDemoDetails }) => {
  const { currentTabData } = openTabDemoDetails || {};
  const { originalDemoObj, demoBasicDetails, } = currentTabData || {};
  return {
    currentTabData,
    demoTabData: demoForm.demoTabData,
    newCollaterals: demoCollaterals.newCollaterals,
    isFetchingDemo: demos.isFetching,
    warningMessage: demos.warningMessage,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
    demoType: demoBasicDetails?.demoType,
    title: demoBasicDetails?.title,
    currentCollapsePanelKey: demoBasicDetails?.currentCollapsePanelKey,
    oldDemo: originalDemoObj,
    isPreRegistration: demoBasicDetails?.isPreRegistration,
    demoCollaterals,
    demoBasicDetails,
    demoComponentDetails,
    demoAccess,
    demoDescription,
    demoResources,
    demoStatus,
    allRoleTemplates: roleTemplates.all,
    preReqQuestionsData: demoQuestionsAssociation?.preReqQuestionsData,
    preRegQuestionsData: demoQuestionsAssociation?.preRegQuestionsData,
    activePanelKey: demos?.activePanelKey,
    activeKey: tabsLayout.activeKey,
    openedTabs: tabsLayout.panes,
    previousTabs: tabsLayout?.previousTabs
  };
};
export default connect(
  mapStateToProps,
  {
    toggleBackButton,
    fetchDemo,
    postNewDemo,
    toggleIsFetchingDemosFlag,
    updateDemoSaveWarning,
    deleteUnassociatedCollaterals,
    fetchRoleTemplates,
    fetchAdminRoleTemplates,
    getLDAPUserGroups,
    toggleIsUserLoading,
    clearCurrentDemo,
    fetchDataCenters,
    resetDemoStatus,
    clearNewDemoCollaterals,
    currentDemoFilter,
    updateDemosCurrentPage,
    selectTitle,
    fetchActiveResources,
    fetchAllDemoQuestions,
    clearDemoQuestions,
    currentActivePanel,
    addDemoTabDetails,
    updateTabsLocalStorage,
    fetchCurrentOpenTabDemoDetails,
    updateCurrentTabDemoDetails,
    fetchDemoCollateralTypes,
    closeCurrentOpenTab,
    handleOnClickAction,
    addDemoTabDetailsOnCreate,
    resetDemoForm,
    toggleHasUnsavedChangesFlag,
    fetchActiveCapabilities,
    closeActiveTab,
    fetchAllUsersForDemo,
    fetchAllManageEZDemos
  }
)(DemoForm);

import React, { Component } from 'react'
import { connect, batch } from 'react-redux';
import { Table, Divider, Row, Button, Icon, Col, Typography, message, Dropdown, Tooltip, Popconfirm } from "antd";
import { exportMenu } from "../../utils/strings";
import moment from 'moment-timezone'
import scrollIntoView from 'scroll-into-view';
import { Icon as NeoIcon } from "@avaya/neo-react";
import CONFIG from '../../config';
import pushMessage from '../common/PushMessage';
import StatusIcon from "../common/StatusIcon";
import FilterPanel from '../common/FilterPanel';
import StatusFilter from '../common/StatusFilter';
import openConfirm from '../common/Confirm';
import { fetchNamesBySearchString } from '../../actions/userRole'
import { getAllCustomReports } from '../../actions/reports'
import { isTabAlreadyOpen } from "../common/TabLayout";
import { selectTitle, toggleBackButton, toggleIsFetchingReportFlag, handleOnClickAction, fetchReports, updateCustomReportsCurrentPage, deleteReport, currentReportFilter, exportTable, restoreReport, clearReports } from "../../actions";
import { getReportExportData } from '../../actions/reports'

const dataIndexMap = {
    name: 'name',
    viewDeletedItems: 'viewDeletedItems',
    startCreatedTime: "startCreatedTime",
    endCreatedTime: "endCreatedTime",
    startUpdatedTime: "startUpdatedTime",
    endUpdatedTime: "endUpdatedTime",
    startLastRunTime: "startLastRunTime",
    endLastRunTime: "endLastRunTime",
    createdBy: "createdBy.fullName",
    updatedBy: "updatedBy.fullName",
    lastRunBy: "lastRunBy.fullName"
};

const { Text } = Typography
class ManageReports extends Component {

    state = {
        matchedUsersNames: [],
        searchString: [],
        hasEnteredString: false,
        status: true,
        isReturnedFromEdit: false,
        isReturnedFromDelete: false,
        deletedRecordIndex: undefined,
        customReportsData: [],
        showFilter: false,
    };

    currentPage = 1
    filterSort = {}
    tableRef = React.createRef()
    flag = 0

    clearCurrentCustomReports = async () => {
        this.currentPage = 1
        this.setState({ customReportsData: [] })
    }

    async componentDidMount() {
        const { currentStatus, currentFilter, isEdit, currentPageNumber } = this.props;
        document.getElementById('tab-pane').scrollIntoView();
        this.setState({ loading: true })
        this.setState({ currentPath: window.location.pathname })
        var tableContent = document.querySelector('.ant-table-body');
        tableContent && tableContent.addEventListener('scroll', (event) => {
            let maxScroll = event.target.scrollHeight - event.target.clientHeight
            let currentScroll = event.target.scrollTop
            if ((event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100) && (maxScroll !== 0 && currentScroll !== 0)) {
                this.flag += 1;
                this.flag === 1 && this.handleFetchCustomReports()
            }
        })

        if (isEdit) {
            this.currentPage = currentPageNumber + 1;
            this.filterSort = currentFilter;
            if (this.filterSort?.filter !== undefined) this.setState({ showFilter: true })
            await this.setState({ status: currentStatus })
        }

        else {
            this.filterSort = {}
        }
        batch(() => {
            this.fetchCustomReportsOnMount()
            this.props.selectTitle("Manage Reports");
            this.props.toggleIsFetchingReportFlag(true);
        })
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            const filterSort = {
                ...this.filterSort,
                sorter: this.state.sortedInfo ?? undefined
            }

            this.props.currentReportFilter(filterSort, this.state.status, true, this.currentPage, (this.currentPage * 12))
        }
        else {
            this.props.clearReports();
        }
    }


    componentDidUpdate = () => {
        const customReportToScrollIntoView = this.state.isReturnedFromDelete
            ? Math.abs(this.state.deletedRecordIndex - 1)
            : this.state.isReturnedFromEdit
                ? this.props.customReportIndex
                : 0;
        (this.state.isReturnedFromEdit || this.state.isReturnedFromDelete)
            && this.state.customReportsData?.length > 0
            && customReportToScrollIntoView
            && scrollIntoView(document.querySelector(`.scroll-row-${customReportToScrollIntoView}`), {
                align: { top: 0, left: 0 },
                behavior: "smooth"
            },
                this.setState({ isReturnedFromEdit: false, isReturnedFromDelete: false })
            )
    }

    handleSelectChange = async (value) => {
        await this.clearCurrentCustomReports()
        this.setState({ status: value }, () => { this.handleFetchCustomReports() })
    }

    handleFetchCustomReports = async () => {
        const { customReportsCount } = this.props
        const countOfRecordsFetched = this.state.customReportsData?.length
        if ((customReportsCount === undefined && countOfRecordsFetched === undefined) || !(customReportsCount <= countOfRecordsFetched)) {
            this.setState({ loading: true });
            await this.props.fetchReports({ pageNumber: this.currentPage++, status: this.state.status, filterSort: this.filterSort })
                .then(() => {
                    this.setState(({ customReportsData }) => ({
                        loading: false,
                        customReportsData: this.props.customReports ? customReportsData?.concat(this.props.customReports) : [],
                    }))
                    this.flag = 0
                }).then(() => {
                    var scrollContainer = document.querySelector('.ant-table-body')
                    scrollContainer.scrollTop = scrollContainer?.scrollHeight - scrollContainer?.clientHeight - scrollContainer?.clientHeight;
                })
        }
    }

    fetchCustomReportsOnMount = async () => {
        const { isEdit, pageSize } = this.props;
        this.setState({ loading: true })
        await this.props.fetchReports({
            pageNumber: (isEdit || this.state.isReturnedFromDelete) ? 1 : this.currentPage++,
            pageSize: (isEdit || this.state.isReturnedFromDelete) ? pageSize : CONFIG.lazyLoadPageSize,
            filterSort: this.filterSort,
            status: this.state.status
        })
        setTimeout(() => {
            this.setState(({ customReportsData }) => ({
                loading: false,
                customReportsData: this.props.customReports ? customReportsData?.concat(this.props.customReports) : [],
                isReturnedFromEdit: isEdit ? true : false,
            }))
        }, 100)
    }

    handleSortChange = async (pagination, _, sorter) => {
        await this.clearCurrentCustomReports()
        this.filterSort = {
            ...this.filterSort,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }
        this.handleFetchCustomReports();
    }

    handleClear = async () => {
        await this.clearCurrentCustomReports()
        this.filterSort = { ...this.filterSort, filter: undefined };
        this.setState({ matchedUsersNames: [] })
        this.handleFetchCustomReports();
    }

    getUTCTime = (time) => {
        const { timeZoneName } = this.props;
        return time.tz(timeZoneName, true).toISOString() || undefined
    }

    handleFilter = async (filters) => {
        let { name, viewDeletedItems, createdTime, updatedTime, lastRunTime, createdBy, updatedBy, lastRunBy } = filters;
        name = name?.trim();
        createdBy = createdBy?.trim();
        updatedBy = updatedBy?.trim();
        lastRunBy = lastRunBy?.trim();
        const iscreatedTimeEmpty = !createdTime || (createdTime && createdTime.length === 0);
        const isupdatedTimeEmpty = !updatedTime || (updatedTime && updatedTime.length === 0);
        const islastRunTimeEmpty = !lastRunTime || (lastRunTime && lastRunTime.length === 0);
        if (!name && !viewDeletedItems && !createdBy && iscreatedTimeEmpty && isupdatedTimeEmpty && islastRunTimeEmpty && !lastRunBy && !updatedBy) {
            pushMessage(CONFIG.messageType.warning, "Please select some fields to filter")
            return;
        }
        this.filterSort = {
            filter: {
                [dataIndexMap.name]: name || undefined,
                [dataIndexMap.viewDeletedItems]: viewDeletedItems,
                [dataIndexMap.startCreatedTime]: !iscreatedTimeEmpty ? this.getUTCTime(createdTime[0]) : undefined,
                [dataIndexMap.endCreatedTime]: !iscreatedTimeEmpty ? this.getUTCTime(createdTime[1]) : undefined,
                [dataIndexMap.startUpdatedTime]: !isupdatedTimeEmpty ? this.getUTCTime(updatedTime[0]) : undefined,
                [dataIndexMap.endUpdatedTime]: !isupdatedTimeEmpty ? this.getUTCTime(updatedTime[1]) : undefined,
                [dataIndexMap.startLastRunTime]: !islastRunTimeEmpty ? this.getUTCTime(lastRunTime[0]) : undefined,
                [dataIndexMap.endLastRunTime]: !islastRunTimeEmpty ? this.getUTCTime(lastRunTime[1]) : undefined,
                [dataIndexMap.updatedBy]: updatedBy || undefined,
                [dataIndexMap.createdBy]: createdBy || undefined,
                [dataIndexMap.lastRunBy]: lastRunBy || undefined,
                updatedBy: updatedBy || undefined,
                createdBy: createdBy || undefined,
                lastRunBy: lastRunBy || undefined,
                [dataIndexMap.viewDeletedItems]: viewDeletedItems,
            }
        }

        if (viewDeletedItems) this.setState({ status: false })
        this.setState({ matchedUsersNames: [] })
        await this.clearCurrentCustomReports();
        this.handleFetchCustomReports();
        this.tableRef.current.scrollIntoView({
            behavior: 'smooth',
        })
    }


    fetchUsersName = searchString => {
        this.setState({ matchedUsersNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    this.setState({ matchedUsersNames: [...new Set(result.map(item => item.fullName))] })
                }
                this.setState({ hasEnteredString: false })
            });
    };

    handleDeleteConfirmation = async () => {
        const hardDelete = this.filterSort?.filter?.viewDeletedItems;
        const response = await this.props.deleteReport(this.reportId, hardDelete);
        if (response) {
            this.clearCurrentCustomReports()
            const pageSize = ((Math.ceil(this.recordIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) + CONFIG.lazyLoadPageSize);
            const currentPageNumber = ((Math.ceil(this.recordIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) / CONFIG.lazyLoadPageSize) + 1;
            await this.props.updateCustomReportsCurrentPage(currentPageNumber, pageSize, this.reportId, this.recordIndex)
            await this.setState({ isReturnedFromDelete: true, deletedRecordIndex: this.recordIndex })
            this.currentPage = this.props.currentPageNumber + 1
            await this.fetchCustomReportsOnMount();
        }
    }

    handleRestore = async (reportId, index) => {
        const response = await this.props.restoreReport(reportId)
        if (response) {
            this.clearCurrentCustomReports()
            const pageSize = ((Math.ceil(index / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) + CONFIG.lazyLoadPageSize);
            const currentPageNumber = ((Math.ceil(index / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) / CONFIG.lazyLoadPageSize) + 1;
            await this.props.updateCustomReportsCurrentPage(currentPageNumber, pageSize, reportId, index)
            await this.setState({ isReturnedFromDelete: true, deletedRecordIndex: index })
            this.currentPage = this.props.currentPageNumber + 1
            await this.fetchCustomReportsOnMount();
        }
    }

    handleDelete = (reportId, index) => {
        this.reportId = reportId;
        this.recordIndex = index;
        const hardDelete = this.filterSort?.filter?.viewDeletedItems;
        if (this.props.userProfile?.isDeveloper && hardDelete) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
        else {
            const okButtonText = hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
            const content = hardDelete ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Report")}</h4> : <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "Report")}</h4>
            const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'report')
            openConfirm(okButtonText, this.handleDeleteConfirmation, null, content, modalTitle);
        }
    }

    handleExport = (record) => {
        const { timeZoneName } = this.props;
        let reportName = record?.name;
        reportName = reportName + ".xlsx";
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        getReportExportData({ reportId: record.id })
            .then((reportData) => exportTable(CONFIG.exportTable.report.name, reportData.dataSet, timeZoneName, reportName, "xlsx", reportData.exportViewColumns))
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Report exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export report")
            })
    }

    handleTableExport = async (fileType) => {
        const { timeZoneName } = this.props;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const demos = await getAllCustomReports({ filterSort: this.filterSort, status: this.state.status })
        if (demos?.length > 0 && demos !== undefined) {
            exportTable(CONFIG.exportTable.reports.name, demos, timeZoneName, CONFIG.exportTable.reports.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    render() {
        const { customReportsCount, timeZoneName } = this.props;
        const { customReportsData, loading } = this.state
        const title = this.filterSort?.filter?.viewDeletedItems ? 'List of Deleted Reports' : this.state.status ? 'List of Active Reports' : this.state.status === false ? 'List of Inactive Reports' : 'List of All Reports';
        const columns = [
            {
                title: 'No.',
                key: 'index',
                align: 'center',
                width: 70,
                render: (text, record, index) => index + 1,
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.name] ? "filtered-column" : ''}>Title</span>,
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                width: 250,
                align: 'left',
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.createdBy] ? "filtered-column" : ''}>Created By</span>,
                dataIndex: 'createdBy',
                key: 'createdBy.fullName',
                sorter: true,
                align: 'left',
                render: createdBy => createdBy !== null && createdBy?.fullName
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.startCreatedTime] ? "filtered-column" : ''}>Created Time</span>,
                dataIndex: 'createdTime',
                key: 'createdTime',
                sorter: true,
                align: 'center',
                render: (publishTime) => moment.tz(publishTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.updatedBy] ? "filtered-column" : ''}>Updated By</span>,
                dataIndex: 'updatedBy',
                key: 'updatedBy.fullName',
                sorter: true,
                align: 'left',
                render: updatedBy => updatedBy !== null && updatedBy?.fullName
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.startUpdatedTime] ? "filtered-column" : ''}>Updated Time</span>,
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                sorter: true,
                align: 'center',
                render: (updatedTime) => updatedTime !== null ? moment.tz(updatedTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"
            },

            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.lastRunBy] ? "filtered-column" : ''}>Last Run By</span>,
                dataIndex: 'lastRunBy',
                key: 'lastRunBy.fullName',
                sorter: true,
                align: 'left',
                render: lastRunBy => lastRunBy !== null && lastRunBy?.fullName
            },
            {
                title: <span className={this.filterSort?.filter?.[dataIndexMap.startLastRunTime] ? "filtered-column" : ''}>Last Run Time</span>,
                dataIndex: 'lastRunTime',
                key: 'lastRunTime',
                sorter: true,
                align: 'center',
                render: (lastRunTime) => lastRunTime !== null ? moment.tz(lastRunTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"
            },
            {
                title: 'Status',
                dataIndex: 'isActive',
                key: 'isActive',
                sorter: true,
                align: 'center',
                render: status => <StatusIcon status={status} />
            },
            {
                title: 'Actions',
                key: 'actions',
                align: 'center',
                width: 280,
                render: (text, record, index) => {
                    const { userProfile } = this.props;
                    const { roleTemplate } = userProfile;
                    const deleteView = this.filterSort?.filter?.viewDeletedItems;
                    const isEditDisabled = roleTemplate && roleTemplate.templateType !== CONFIG.roleTypes.superAdmin && record.adminAccess === CONFIG.roleTemplateAccess.readOnly;
                    return (
                        <React.Fragment>
                            {!deleteView &&
                                <>

                                    <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                        <button
                                            onClick={() => {
                                                this.props.handleOnClickAction({
                                                    component: CONFIG.editComponentRoute.reports,
                                                    tabTitle: record?.name,
                                                    recordId: record?.id,
                                                    recordName: "custom"
                                                })
                                            }}
                                            disabled={isEditDisabled}
                                            className={!isEditDisabled ? "link" : "link-disabled"}>
                                            <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip >

                                    {" "}
                                    <Divider type="vertical" />

                                    <button
                                        onClick={() => {
                                            this.props.handleOnClickAction({
                                                component: CONFIG.cloneComponentRoute.reports,
                                                tabTitle: record?.name,
                                                recordId: record?.id,
                                                isClone: true,
                                                recordName: "custom"
                                            })
                                        }}
                                        disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"}><span class="neo-icon-copy" title="Clone" style={{ fontSize: "20px" }}></span></button>

                                    {" "}
                                    <Divider type="vertical" />
                                    <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleExport(record)}><span class="neo-icon-export-logs" title="Export" style={{ fontSize: "20px" }}></span></button>
                                    {" "}
                                    <Divider type="vertical" />
                                </>
                            }
                            <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                <>
                                    <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleDelete(record.id, index + 1)}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                                    {deleteView && <>
                                        <Divider type="vertical" />
                                        <Popconfirm disabled={isEditDisabled} title="Confirm restore?" onConfirm={() => this.handleRestore(record.id, index + 1)}>
                                            <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"}><span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span></button>
                                        </Popconfirm>
                                    </>
                                    }
                                </>
                            </Tooltip>
                        </React.Fragment>
                    )
                }
            },
        ];

        const formFields = [
            {
                label: "Report Name",
                type: CONFIG.formFieldTypes.INPUT,
                key: "name",
                value: this.filterSort?.filter?.name
            },
            {
                label: "Created By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "createdBy",
                data: this.state.matchedUsersNames,
                value: this.filterSort?.filter?.createdBy
            },
            {
                label: "Updated By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "updatedBy",
                data: this.state.matchedUsersNames,
                value: this.filterSort?.filter?.updatedBy
            },
            {
                label: "Last Run By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "lastRunBy",
                data: this.state.matchedUsersNames,
                value: this.filterSort?.filter?.lastRunBy
            },
            {
                label: "Created Time",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "createdTime",
                value: [this.filterSort?.filter?.startCreatedTime, this.filterSort?.filter?.endCreatedTime],
            },
            {
                label: "Updated Time",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "updatedTime",
                value: [this.filterSort?.filter?.startUpdatedTime, this.filterSort?.filter?.endUpdatedTime]
            },
            {
                label: "Last Run Time",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "lastRunTime",
                value: [this.filterSort?.filter?.startLastRunTime, this.filterSort?.filter?.endLastRunTime]
            }
        ]
        const { showFilter } = this.state
        return (
            <React.Fragment>
                <Row className="vertical-spacing">
                    <Col xl={3} sm={6} xs={24}>
                        <StatusFilter disabled={this.filterSort?.filter?.viewDeletedItems} onChange={this.handleSelectChange} value={this.state.status} />
                    </Col>
                    <Col xl={2} sm={6} xs={24} className="left-spacing">
                        <Button className="primary-action-button-filled"
                            onClick={() => {
                                this.props.handleOnClickAction({
                                    component: CONFIG.createComponentRoute.reports,
                                    tabTitle: "Create"
                                })
                            }}>New
                            <Icon aria-label="aria-menu" type="plus-circle" />
                        </Button>
                    </Col>
                    {/* for export icon */}
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <Dropdown overlay={exportMenu(this.handleTableExport)} trigger="click">
                            <NeoIcon aria-label="menu-aria" title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </Dropdown>
                    </Col>
                    {/* for refresh icon */}
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" title="Refresh" className='clickable' icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                    {/* for filter icon */}
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon aria-label="menu-aria" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                </Row>
                <div className="content-container responsive-container">
                    <Row>
                        {this.state.showFilter &&
                            <FilterPanel
                                label='View Deleted Reports'
                                formFields={formFields}
                                handleClear={this.handleClear}
                                handleFilter={this.handleFilter}
                                getSearchData={this.fetchUsersName}
                                hideApplyToAll={true}
                                panelHeader="Filters and Export"
                                timeZone={timeZoneName}
                                viewDeletedItemsValue={this.filterSort?.filter?.viewDeletedItems}
                            />}

                    </Row>
                    <Row>
                        <h3>{title}</h3>
                        <div ref={this.tableRef}>
                            <Table
                                size='middle'
                                className="responsive-container"
                                rowClassName={(record, index) => (`scroll-row-${index + 1}`)}
                                scrollToFirstRowOnChange={true}
                                rowKey={(record) => record.id}
                                bordered
                                scroll={{ y: 550, scrollToFirstRowOnChange: false, x: true | 2300 }}
                                dataSource={this.state.customReportsData || []}
                                columns={columns}
                                loading={loading}
                                onChange={this.handleSortChange}
                                pagination={false}
                                footer={() => <>{customReportsData?.length !== 0 && <div style={{ textAlign: 'center' }}>
                                    <Text strong>Fetched {customReportsData?.length} out of {customReportsCount} Custom Reports</Text>
                                </div>}
                                </>}
                            />
                        </div>

                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ reports, user, tabsLayout }) => {
    return {
        userProfile: user.profile,
        reports: reports,
        timeZoneName: user.profile.timezone,
        currentStatus: reports.status,
        currentFilter: reports.currentFilter,
        isEdit: reports.isEditReport,
        customReports: reports.data,
        customReportsCount: reports.count,
        currentPageNumber: reports.currentPageNumber,
        pageSize: reports.pageSize,
        openedTabs: tabsLayout.panes,
        customReportIndex: reports.customReportIndex,
    }
}

export default connect(mapStateToProps, { selectTitle, handleOnClickAction, toggleBackButton, toggleIsFetchingReportFlag, fetchReports, currentReportFilter, updateCustomReportsCurrentPage, deleteReport, exportTable, restoreReport, clearReports })(ManageReports);
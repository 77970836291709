import React from 'react';
import { Select, Button, Icon, Row, Col, Popconfirm, Table, Form, Tooltip } from 'antd';
import { connect } from 'react-redux';

import {
    deleteDemoQuestionAssociation,
    addNewDemoQuestions,
    clearDemoQuestions,
    fetchCurrentOpenTabDemoDetails
} from '../../actions';
import CONFIG from '../../config';

const { Option } = Select;

export const ManageDemoQuestionsContext = React.createContext();

class DemoQuestions extends React.Component {

    state = {
        editingKey: ""
    };
    demoQuestionId = null;

    columns = [
        {
            title: 'Title',
            key: 'question.title',
            dataIndex: 'question',
            align: 'left',
            width: 250,
            render: question => question ? question?.title : "",
        },
        {
            title: 'Type',
            key: 'question.answerType',
            dataIndex: "question",
            align: 'center',
            width: 150,
            render: question => question ? question?.answerType : "",
        },
        {
            title: 'Action',
            key: 'action',
            width: 150,
            render: (option, record) => {
                const { userRoleTemplate, allDemoQuestions } = this.props;
                const hasFullAccess = allDemoQuestions && allDemoQuestions.find(question => question.id === record?.question?.id) !== undefined;
                const isDisabled = userRoleTemplate && userRoleTemplate.templateType !== CONFIG.roleTypes.superAdmin && !hasFullAccess

                return (<div>
                    <Popconfirm title="Confirm Delete?"
                        okText="Yes" cancelText="No"
                        onConfirm={() => {
                            this.handleDelete(record)
                        }}>
                        <Tooltip title={isDisabled ? CONFIG.warningMessages.noAccess : undefined} key={Math.random()}>
                            <button aria-label="Click" disabled={isDisabled} className={!isDisabled ? "link" : "link-disabled"}> <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                        </Tooltip>
                    </Popconfirm>
                </div >
                );
            },
            align: 'center'
        }
    ];


    handleDelete = async (record) => {
        this.demoQuestionId = record?.question?.id;
        await this.props.deleteDemoQuestionAssociation(this.demoQuestionId, this.props.questionType);
    }


    handleAdd = () => {
        const { form, questionType, demoId } = this.props;
        const demoQuestions = form.getFieldsValue().demoQuestions;
        demoQuestions.forEach(async (question) => {
            let questionToAdd = { question: JSON.parse(question) }
            questionToAdd.type = questionType
            await this.props.addNewDemoQuestions(demoId, questionToAdd, questionType)

        })
        form.resetFields();
    }

    renderQuestionOptions = () => {
        const { allDemoQuestions, additionalInfoQuestionsData, preRegQuestionsData, questionType } = this.props;
        const demoQuestionsData = questionType === CONFIG.demoQuestionTypes?.[0]?.value ? additionalInfoQuestionsData : preRegQuestionsData
        var filteredQuestions = allDemoQuestions?.filter(function (obj) {
            return !demoQuestionsData?.some(function (obj2) {
                return obj.id === obj2?.question?.id;
            });
        });

        return filteredQuestions
            ? filteredQuestions.map((question, index) => {
                return <Option key={index} value={JSON.stringify(question)}>{question.title}</Option>;
            })
            : [];
    }

    renderQuestionSelect = () => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Row gutter={24} className="vertical-spacing">
                <Col xl={12} sm={12} xs={24}>
                    <Form id="demoQuestionForm">
                        <Form.Item >
                            {
                                getFieldDecorator('demoQuestions')(
                                    <Select getPopupContainer={() => document.getElementById('demoQuestionForm')} showSearch={true} mode="multiple" placeholder="Select one or more questions" disabled={this.state.editingKey !== ""}>
                                        {this.renderQuestionOptions()}
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Form>
                </Col>
                <Col xl={2} sm={2} xs={24}>
                    <Button aria-label="Click" className="primary-action-button-bordered horizontal-spacing antd-button-fix" onClick={this.handleAdd} disabled={(!form.getFieldValue('demoQuestions') || form.getFieldValue('demoQuestions')?.length === 0)}>
                        Add
                        <Icon aria-label="aria-menu" type="plus-circle" />
                    </Button>
                </Col>
            </Row>
        )
    }


    render() {
        const { additionalInfoQuestionsData, preRegQuestionsData, questionType } = this.props;
        return (
            <React.Fragment>
                {this.renderQuestionSelect()}
                <ManageDemoQuestionsContext.Provider value={this.props.form}>
                    <Table
                        size='middle'
                        className="responsive-container"
                        bordered
                        loading={this.props.isFetching}
                        dataSource={questionType === CONFIG.demoQuestionTypes?.[0]?.value ? additionalInfoQuestionsData : preRegQuestionsData}
                        columns={this.columns}
                        pagination={false}
                    />
                </ManageDemoQuestionsContext.Provider>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ demoQuestions, viewport, user, openTabDemoDetails, demoForm }) => {
    return {
        allDemoQuestions: demoQuestions?.allQuestions,
        additionalInfoQuestionsData: openTabDemoDetails?.currentTabData?.assignedAdditionalInfoQuestions,
        preRegQuestionsData: openTabDemoDetails?.currentTabData?.assignedPreRegisterQuestions,
        isFetching: demoQuestions?.isFetching,
        viewport,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        demo: openTabDemoDetails?.currentTabData?.demoBasicDetails?.demo,
        timeZoneName: user.profile?.timezone,
    };
}
const EditableFormTable = Form.create()(DemoQuestions);

export default connect(
    mapStateToProps,
    {
        deleteDemoQuestionAssociation,
        addNewDemoQuestions,
        clearDemoQuestions,
        fetchCurrentOpenTabDemoDetails,

    }
)(EditableFormTable);

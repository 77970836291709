import React from 'react';
import { connect, batch } from 'react-redux';
import moment from 'moment-timezone';
import { analytics } from '../../firebase';
import { Row, Col, Card, Icon, Tooltip, Modal, Divider, Spin } from 'antd';
import { Button } from '@avaya/neo-react'
import noSchedules from "../../assets/images/noSchedules.png"
import contact from "../../assets/images/contact.png";
import { LoadingOutlined } from '@ant-design/icons';

import {
  fetchUserSchedules,
  fetchDashboardActiveSchedules,
  toggleIsFetchingSchedule,
  fetchScheduleById,
  selectTitle,
  toggleBackButton,
  fetchActiveDemoResources,
  deleteDemoSchedule,
  toggleIsFetchingUserSchedules,
  deleteMaintenanceSchedule,
  fetchDemos,
  fetchSchedulesOverDuration,
  updateCurrentDemoSchedule,
  updateSchedulerStartEndTime,
  fetchDataCenters,
  clearMostUsedDemos,
  fetchUserNotifications,
  toggleIsFetchingDashboardSchedules,
  toggleIsFetchingMaintenanceSchedule,
  fetchUserDemoRequests,
  updateUserSchedulesParams,
  optOutSchedule,
  toggleIsReschedule,
  deleteDashboardSchedule,
  openLinkInNewTab,
  fetchDashboardPreviousSchedules,
  clearUserDashboardSectionViews,
  refreshUserDashboardView,
  isDashboardLoading,
  toggleIsFetchingTrendingDemos,
  fetchMostScheduledDemos,
  toggleIsFetchingUserEZDemos,
  fetchPopularEzDemos,
  toggleIsFetchingNewsFlag,
  fetchUserNews
} from '../../actions';
import DashboardSchedules from './DashboardSchedules';
import DashboardPopularEZDemos from './DashboardPopularEZDemos';
import DashboardNews from './DashboardNews';
import DashboardMostUsedDemos from './DashboardMostUsedDemos';
import PendingApprovals from './PendingApprovals';
import CONFIG from '../../config';
import history from '../../history';
import openConfirm from '../common/Confirm';
import openInfo from '../common/Info';
import DemoRegistrationModal from '../common/DemoRegistrationModal';
import { getDemoCardWarnings } from '../../utils/dashboardCardMessage';
import PromotionCarousel from '../common/PromotionCarousel';
import DashboardNoData from '../common/DashboardNoData';

const { Meta } = Card;
class Dashboard extends React.Component {

  state = {
    loading: false,
    registrationModalVisible: false,
    demoRequestData: null
  }

  handleDemoScheduleUpdate = async (scheduleId, schedule, isReschedule, isEdit, fromRescheduleEmail, isBatchEdit) => {
    const { timezone, isActiveSchedules } = this.props;
    const { demo } = schedule;
    const { showRequestButton, showScheduleButton, demoType } = demo;

    const schedulerStartTime = isActiveSchedules
      ? moment(schedule.startTime).tz(timezone).startOf('day').toISOString()
      : moment().tz(timezone).startOf('day').toISOString();
    const schedulerEndTime = isActiveSchedules
      ? moment(schedule.startTime).tz(timezone).endOf('day').toISOString()
      : moment().tz(timezone).endOf('day').toISOString();

    // const startTime = moment(schedule.startTime).tz(timezone).startOf('day').toISOString();

    // const endTime = moment(schedule.startTime).tz(timezone).endOf('day').toISOString();
    this.props.updateSchedulerStartEndTime(schedulerStartTime, schedulerEndTime);

    //  !isReschedule is to check if the user is editing the schedule.

    if (showScheduleButton || isEdit) {
      await this.props.fetchActiveDemoResources(demo?.id)
        // .then(async () => await this.props.fetchSchedulesOverDuration(demo?.id, startTime, endTime))
        .then(async () => await this.props.updateCurrentDemoSchedule({ ...schedule, id: scheduleId }, fromRescheduleEmail))
        .then(async () => await this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`,
          (isBatchEdit && demoType === CONFIG.demoTypes.standardDemo) ? `/scheduler/standard/${scheduleId}/edit/isReschedule=${isReschedule}/isBatchEdit=${isBatchEdit}/batchId=${schedule?.batchNumber}`
            : `/scheduler/${demoType === CONFIG.demoTypes.standardDemo ? "standard" : "lab"}/${scheduleId}/edit/isReschedule=${isReschedule}/fromManageSide=${false}`))
    }
    else if (showRequestButton) {
      this.handleDemoRequestEdit(schedule)
    }
    else {
      this.showAccessWarning();
      this.setState({ loading: false });
    }
  }

  handleDemoScheduleCancel = async (scheduleId, demoType, fromEmail) => {
    await this.props.deleteDashboardSchedule(scheduleId, demoType)
    Modal.destroyAll();
  }

  handleMaintenanceCancel = (maintenanceId) => {
    this.props.deleteMaintenanceSchedule(maintenanceId)
      .then(() => {
        this.props.fetchUserSchedules(({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.CARD }))
        this.props.fetchUserSchedules(this.props.params);
      });
  };

  handleOptout = async (record, demoType) => {
    this.props.toggleIsFetchingDashboardSchedules(true);
    await this.props.optOutSchedule(record, demoType);
    this.props.toggleIsFetchingDashboardSchedules(false);
  }

  handleDeleteScheduleForMyself = async (record, demoType) => {
    const { userDetails } = this.props;
    const eapUsers = record?.participants?.eapUsers?.filter((eachUser) => eachUser?.id !== userDetails?.id);

    const schedule = {
      createdBy: record?.createdBy,
      demo: record?.demo,
      demoPurpose: record?.demoPurpose,
      endTime: record?.endTime,
      endTimeBuffer: record?.endTimeBuffer,
      id: record?.id,
      isOneTime: record?.isOneTime,
      batchNumber: record?.batchNumber,
      isPreRegistration: record?.isPreRegistration,
      purposeAttributesSchedule: record?.purposeAttributesSchedule,
      resource: record?.resource,
      startTime: record?.startTime,
      startTimeBuffer: record?.startTimeBuffer,
      userAnswers: record?.userAnswers,
      participants: {
        ...record.participants,
        eapUsers
      } || null
    }

    await this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.CARD });
    this.handleOptout(schedule, demoType);
    Modal.destroyAll();
  }

  handleDeleteEmailRedirection = async () => {
    const { timezone, userDetails } = this.props;
    const scheduleId = localStorage.getItem('scheduleId')
    this.props.toggleIsFetchingSchedule(true);
    localStorage.removeItem('scheduleId');
    localStorage.removeItem('deleteSchedule')
    const scheduleDetails = await fetchScheduleById(scheduleId);
    this.setState({ loading: true });
    const isScheduleExpired = scheduleDetails && scheduleDetails?.isExpired
    const isScheduleSoftDeleted = scheduleDetails && scheduleDetails?.isSoftDelete

    if (isScheduleExpired !== undefined && !isScheduleExpired && !isScheduleSoftDeleted) {
      const demoType = scheduleDetails?.demo?.demoType;
      const hasParticipants = scheduleDetails?.participants !== null;
      const scheduleCreator = hasParticipants && [...scheduleDetails?.participants?.eapUsers, ...scheduleDetails?.participants?.extUsers]?.filter(par => par?.isCreator === true)?.[0];
      const isLoggedInUserCreator = scheduleCreator?.id === userDetails?.id
      const showSingleScheduleDelete = !hasParticipants || isLoggedInUserCreator
      const startDateTime = moment.tz(scheduleDetails?.startTime, timezone).format(CONFIG.dateFormats.userDateTime);
      const endDateTime = moment.tz(scheduleDetails?.endTime, timezone).format(CONFIG.dateFormats.userDateTime);
      const endTime = moment.tz(scheduleDetails?.endTime, timezone).format(CONFIG.dateFormats.userTime);
      const bufferStart = scheduleDetails?.demo?.startTimeBuffer;
      const bufferEnd = scheduleDetails?.demo?.endTimeBuffer;
      const bufferStartTime = bufferStart > 0 ? moment.tz(scheduleDetails?.startTime, timezone).subtract(bufferStart, 'm') : undefined;
      const bufferEndTime = bufferEnd > 0 ? moment.tz(scheduleDetails?.endTime, timezone).add(bufferEnd, 'm') : undefined;
      const scheduleCard =
        <Card
          id="demo-card"
          size="small"
          style={{ marginTop: '15px' }}
          title={
            <div className="demo-card-timings">
              <Row className="top-spacing-6">
                <Col span={24}>
                  <span>
                    {startDateTime} - {demoType === CONFIG.demoTypes.standardDemo ? endTime : endDateTime} &nbsp;
                    {bufferStartTime && (bufferEndTime || demoType !== CONFIG.demoTypes.standardDemo) &&
                      < Tooltip
                        title={<><div>Buffer Start Time  - {bufferStartTime.format(CONFIG.dateFormats.userDateTime)}</div>{demoType === CONFIG.demoTypes.standardDemo && <div>Buffer End Time  - {bufferEndTime.format(CONFIG.dateFormats.userDateTime)}</div>}</>}>
                        <Icon aria-label="aria-menu" type="info-circle" className="text-color-primary" width={1} />
                      </Tooltip>}
                  </span>
                </Col>
              </Row>
            </div>}>
          <span className="medium-text bold">{scheduleDetails?.demo?.title}</span>
          {demoType === CONFIG.demoTypes.standardDemo && <div className="demoApprovedStatus">
            <span className="demo-card-body-details bold">Resource: </span>
            <span className="demo-card-body-details">{scheduleDetails?.resource?.name}</span>
          </div>}

          {hasParticipants && !isLoggedInUserCreator && <div className="delete-option-modal center-align-content ">
            <Button
              className="delete-option-button-email primary-action-button-filled"
              onClick={() => this.handleDeleteScheduleForMyself(scheduleDetails, demoType, true)} > Delete for me
            </Button>
            <Button
              className="delete-option-button-email primary-action-button-filled"
              onClick={() => this.handleDemoScheduleCancel(scheduleDetails.id, demoType, true)} > Delete for Everyone
            </Button>
          </div>}
        </Card>


      showSingleScheduleDelete && openConfirm('Yes', () => { (!hasParticipants || isLoggedInUserCreator) && this.handleDemoScheduleCancel(scheduleDetails.id, demoType, true) }, null, scheduleCard, CONFIG.deleteModalTitle?.replace('<placeholder>', 'schedule'), 'access-alert')
      hasParticipants && !isLoggedInUserCreator && Modal.warning({ width: '500px', title: 'Confirm Delete?', content: scheduleCard, okText: "Cancel" })
    }
    else
      openInfo("Warning!", " Sorry! You are no longer authorized to access this schedule or this schedule has been removed", null, "OK", true)
    this.setState({ loading: false });
  }

  handleSelectSchedule = (selectedDemo) => {
    history.replace({ pathname: `/solutions/demo/${selectedDemo.demo?.id}` });
  }

  showAccessWarning = () => {
    openInfo("Warning!", " Sorry! You are no longer authorized to access this schedule or this schedule has been removed", null, "OK", true)
  }

  checkIfScheduleIsEditable = (schedule, reschedule) => {
    const isScheduleExpired = reschedule ? false : schedule && schedule?.isExpired
    const isSoftDeleted = schedule && reschedule ? false : schedule?.isSoftDelete

    // To Show Edit button : isDemoActive ->True, hasAccessToDemo--> True, isDemoExpired-->False, isDemoSoftDeleted-->False
    const showEditButton = schedule && getDemoCardWarnings(schedule)[2]
    const isScheduleEditable = !isScheduleExpired && !isSoftDeleted && showEditButton
    localStorage.removeItem('scheduleId');
    return isScheduleEditable
  }


  handleEditEmailRedirection = async () => {
    const scheduleId = localStorage.getItem('scheduleId')
    this.props.toggleIsFetchingSchedule(true);
    localStorage.removeItem('scheduleId');
    localStorage.removeItem('editSchedule'); // Remove the cookies
    const schedule = await fetchScheduleById(scheduleId);
    this.setState({ loading: true });

    const isScheduleEditable = this.checkIfScheduleIsEditable(schedule, false)
    const demoType = schedule?.demo?.demoType; // Check if schedule is editable
    if (isScheduleEditable) {
      if (demoType !== CONFIG.demoTypes.standardDemo) {
        await this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/lab/${schedule?.id}/edit/isReschedule=${false}/fromManageSide=${false}`)
        return
      }
      else if (demoType === CONFIG.demoTypes.standardDemo) {
        const { demo } = schedule;
        // const { timezone } = this.props;
        // const startTime = moment(schedule.startTime).tz(timezone).startOf('day').toISOString();
        // const endTime = moment(schedule.startTime).tz(timezone).endOf('day').toISOString();
        await this.props.fetchActiveDemoResources(demo?.id)
          // .then(async () => await this.props.fetchSchedulesOverDuration(demo?.id, startTime, endTime))
          .then(async () => await this.props.updateCurrentDemoSchedule({ ...schedule, id: schedule?.id }))
          .then(async () => await this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/standard/${schedule?.id}/edit/isReschedule=${false}/fromManageSide=${false}`)
          )
      }
      else this.showAccessWarning();
    }
    else this.showAccessWarning();
    this.setState({ loading: false });
  }

  handleRescheduleEmailRedirection = async () => {
    const scheduleId = localStorage.getItem('scheduleId')
    this.props.toggleIsFetchingSchedule(true);
    localStorage.removeItem('scheduleId');
    localStorage.removeItem('reSchedule')
    const schedule = await fetchScheduleById(scheduleId);
    this.setState({ loading: true });
    const isScheduleReschedulable = this.checkIfScheduleIsEditable(schedule, true)
    const { isDemoAlreadyRequested, showRequestButton } = schedule?.demo || [];
    const shouldRedirectToRequestPage = showRequestButton || isDemoAlreadyRequested;
    const demoType = schedule?.demo?.demoType;
    if (isScheduleReschedulable) {
      if (shouldRedirectToRequestPage) {
        this.handleSelectSchedule(schedule);
      }
      else {
        if (demoType !== CONFIG.demoTypes.standardDemo) {
          await this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/lab/${schedule?.id}/edit/isReschedule=${true}/fromManageSide=${false}`)
          return
        }
        else if (demoType === CONFIG.demoTypes.standardDemo) {
          this.props.toggleIsReschedule(true)
          const isReschedule = true;
          const isEdit = false;
          const fromRescheduleEmail = true;
          this.handleDemoScheduleUpdate(schedule.id, schedule, isReschedule, isEdit, fromRescheduleEmail);
        }
        else this.showAccessWarning();
      }
    }
    else this.showAccessWarning();
    this.setState({ loading: false });
  }


  fetchAPIsForUserDashboardSections = async () => {

    try {
      await this.props.isDashboardLoading(true);

      // Todays and Upcoming Schedules Section
      await this.props.toggleIsFetchingDashboardSchedules(true);
      await this.props.toggleIsFetchingUserSchedules(true, "isLoading");
      await this.props.toggleIsFetchingDashboardSchedules(true);
      await this.props.fetchDataCenters();
      await this.props.fetchDashboardActiveSchedules();
      if (this.props.dashboardActiveScheduleCount < CONFIG.previousSchedulesCardSize) {
        await this.props.fetchDashboardPreviousSchedules();
      }
      await this.props.toggleIsFetchingDashboardSchedules(false)

      // Most popular Scheduled Demos
      await this.props.toggleIsFetchingTrendingDemos(true);
      await this.props.fetchMostScheduledDemos();

      // Most Popular EZ Demos
      await this.props.toggleIsFetchingUserEZDemos(true);
      await this.props.fetchPopularEzDemos();
      await this.props.toggleIsFetchingUserEZDemos(false);

      // News
      await this.props.toggleIsFetchingNewsFlag(true);
      await this.props.fetchUserNews(true);
      await this.props.toggleIsFetchingNewsFlag(false);


      await this.props.refreshUserDashboardView();
    } catch (error) {
    } finally {
      // Finally block ensures that this.props.isDashboardLoading(false); is called
      this.props.isDashboardLoading(false);
    }
  }



  componentDidMount() {
    const { isLoginComplete } = this.props;
    batch(async () => {
      if (isLoginComplete) {
        this.props.selectTitle('User Dashboard');
        this.props.toggleBackButton(false);

        await this.fetchAPIsForUserDashboardSections();
        await this.props.refreshUserDashboardView();

        if (localStorage.getItem('scheduleId') !== null) {
          if (localStorage.getItem('deleteSchedule'))
            this.handleDeleteEmailRedirection();
          else if (localStorage.getItem('editSchedule'))
            this.handleEditEmailRedirection()
          else if (localStorage.getItem('reSchedule'))
            this.handleRescheduleEmailRedirection()
        }
      }
    })
  }

  componentWillUnmount() {
    this.props.clearMostUsedDemos();
    this.props.clearUserDashboardSectionViews()
  }

  handleContactUsNavigation = () => {
    const { userDetails } = this.props;
    const { contactUs: {
      name: eventName,
      parameters: eventParams
    } } = CONFIG.firebaseEvents;

    const userObj = { ...userDetails, userName: userDetails?.firstName + " " + userDetails?.lastName }
    // "User Name": "userName",
    // "User Email": "email"
    let parameters = {};
    Object.keys(eventParams).forEach(paramKey => {
      parameters[paramKey] = userObj[eventParams[paramKey]]
    });
    analytics().logEvent(eventName, parameters);

    window.open(process.env.REACT_APP_SUPPORT_URL)
  }

  renderContactCard = () => {
    return (
      <Card
        id="support"
        className="margin-bottom"
        onClick={this.handleContactUsNavigation}
        hoverable
        cover={<img className='support-image-card-dashboard' height='auto' alt="Contact Us" src={contact} />}
      >
        <Meta title={<React.Fragment>Contact Us<Icon aria-label="aria-menu" className="float-right arrow-color" type="arrow-right" /></React.Fragment>} />
      </Card>
    )
  }

  handleDemoRequestEdit = (record) => {
    this.setState({
      demoRequestData: { demo: record?.demo, viewOnly: false },
      registrationModalVisible: true
    })
  }

  handleDemoRequestModalClose = () => {
    this.setState({ demoRequestData: null, registrationModalVisible: false })
  }

  handleDemoRequestSubmit = () => {
    this.handleDemoRequestModalClose();
    history.replace("/dashboard-demo-settings");
    history.replace("/dashboard");
  }

  renderNoScheduleContainer = () => {
    const { menu } = this.props;
    const solutionTypes = menu ? menu?.filter(obj => obj.title === "Solution Demos") : [];
    const firstSolType = (solutionTypes?.length > 0) && solutionTypes[0]?.subMenu[0];
    return (<div className="dashboard-no-schedules-img">
      <img alt="homepage" src={noSchedules} />
      <div className='large-text bold'>There are no demos scheduled!</div>
      <div>Check out all the available demos for scheduling</div>
      <div>or EZ Demos that does not require scheduling to use.</div>
      {firstSolType && <Button disabled={!firstSolType} onClick={() => firstSolType && this.props.openLinkInNewTab(firstSolType?.title, `${firstSolType?.url}/${firstSolType?.value}`)} className="vertical-spacing" animation="none" size="compact" status="default" >Explore Demos</Button>}
    </div>)
  }


  render() {
    const { isLoginComplete, showUserUpcomingSchedulesSection, isDashboardAPIsFetching, showUserTodaySchedulesSection, showUserEZDemoSection, showUserPreviousSchedulesSection, showUserNewsSection, showUserMostScheduledDemosSection, isPopularEzDemosLoading, isNewsLoading, isFetchingPreviousSchedules, isMostUsedDemosLoading, isFetchingDashboardSchedules } = this.props;
    const { registrationModalVisible, demoRequestData } = this.state;
    return (isLoginComplete &&
      // <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} spinning={isDashboardAPIsFetching}>
      <div style={{ height: isDashboardAPIsFetching && "500px" }}>
        {(!showUserMostScheduledDemosSection && !showUserUpcomingSchedulesSection && !showUserTodaySchedulesSection)
          ?
          <div>
            <Row
              gutter={24}
              className="vertical-spacing dashboard-main-container"
            >
              <Col xl={14} md={12} xs={24} className="margin-bottom dashboard-left-container" id="ScheduledID">
                <div className="dashboard-card-grid-height-left">
                  <PromotionCarousel />
                </div>
                {(showUserEZDemoSection && !isPopularEzDemosLoading) ? <div style={{ marginLeft: "2%" }}>
                  <DashboardPopularEZDemos />
                </div> : showUserEZDemoSection ? <DashboardNoData heading="Popular EZ Demos" icon="presentation-only" /> : undefined}
              </Col>
              <Col xl={10} md={12} xs={24} style={{ marginTop: "2px" }} className="margin-bottom dashboard-right-container">
                {(showUserNewsSection && !isNewsLoading) ? <div className="dashboard-news-container">
                  <DashboardNews />
                </div> : showUserNewsSection ? <DashboardNoData heading="News" icon="file" /> : undefined}
                <div className="dashboard-card-grid-height-right top-spacing-2">
                  {this.renderNoScheduleContainer()}
                </div>
              </Col>
            </Row>
            < Divider className="dashboard-news-divider" />
            <Row>
              {(showUserPreviousSchedulesSection && !isFetchingPreviousSchedules) ? <div>
                <DashboardSchedules
                  handleDemoScheduleUpdate={this.handleDemoScheduleUpdate}
                  section="Previous"
                />
              </div> : showUserPreviousSchedulesSection ? <DashboardNoData heading="Previous Schedules" icon="history" /> : undefined}
            </Row>
          </div>
          :
          <Row
            gutter={24}
            className="vertical-spacing dashboard-main-container"
          >
            <Col xl={15} md={12} xs={24} className="margin-bottom dashboard-left-container" id="ScheduledID">
              {isFetchingDashboardSchedules
                ?
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={isFetchingDashboardSchedules} >{this.renderNoScheduleContainer()} </Spin>
                : <div className="dashboard-card-grid-height-left" style={{ marginLeft: "2%" }}>
                  {!showUserUpcomingSchedulesSection && !showUserTodaySchedulesSection &&
                    <>{this.renderNoScheduleContainer()}
                      < Divider className="dashboard-news-divider" /></>
                  }
                  {showUserTodaySchedulesSection && <DashboardSchedules
                    handleDemoScheduleUpdate={this.handleDemoScheduleUpdate}
                    handleDemoScheduleCancel={this.handleDemoScheduleCancel}
                    handleMaintenanceCancel={this.handleMaintenanceCancel}
                    section="Today"
                  />}
                  {showUserUpcomingSchedulesSection && <DashboardSchedules
                    handleDemoScheduleUpdate={this.handleDemoScheduleUpdate}
                    handleDemoScheduleCancel={this.handleDemoScheduleCancel}
                    handleMaintenanceCancel={this.handleMaintenanceCancel}
                    section="Upcoming"
                  />}
                </div>}
              {(showUserEZDemoSection && !isPopularEzDemosLoading) ? <div style={{ marginLeft: "2%" }}>
                <DashboardPopularEZDemos />
              </div> : showUserEZDemoSection ? <DashboardNoData heading="Popular EZ Demos" icon="presentation-only" /> : undefined}
              {(showUserPreviousSchedulesSection && !isFetchingPreviousSchedules) ? <div>
                <DashboardSchedules
                  handleDemoScheduleUpdate={this.handleDemoScheduleUpdate}
                  section="Previous"
                />
              </div> : showUserPreviousSchedulesSection ? <DashboardNoData heading="Previous Schedules" icon="history" /> : undefined}
            </Col>
            {<Col xl={9} md={12} xs={24} style={{ marginTop: "2px" }} className="margin-bottom dashboard-right-container">
              <div className="dashboard-card-grid-height-right">
                <div>
                  <PendingApprovals />
                </div>
                {(showUserNewsSection && !isNewsLoading) ? <div className="dashboard-news-container">
                  <DashboardNews />
                </div> : showUserNewsSection ? <DashboardNoData heading="News" icon="file" /> : undefined}
                {(showUserMostScheduledDemosSection && !isMostUsedDemosLoading) ? <div className="dashboard-news-container">
                  <DashboardMostUsedDemos />
                </div> : showUserMostScheduledDemosSection ? <DashboardNoData heading="Most Popular Demos" /> : undefined
                }
              </div>
            </Col>}
          </Row>}
        {
          registrationModalVisible &&
          <DemoRegistrationModal
            visible={registrationModalVisible}
            handleDemoRequestSubmit={this.handleDemoRequestSubmit}
            demo={demoRequestData?.demo}
            isEdit={false}
            rawData={demoRequestData}
            handleCancel={this.handleDemoRequestModalClose}
          />
        }
      </div >
      // </Spin>
    );
  }
}

const mapStateToProps = ({ user, dashboard, scheduler, notifications, viewport, demoSchedules, mostScheduledDemos, trendingDemos, userDashboardSections, ezDemos, news }) => {
  return {
    mostScheduledDemos: mostScheduledDemos.mostScheduledDemos,
    dashboardActiveScheduleCount: dashboard.dashboardActiveScheduleCount,
    todaySchedules: dashboard.dashboardTodaySchedules,
    upcomingSchedules: dashboard.dashboardUpcomingSchedules,
    isDashboardAPIsFetching: dashboard.isDashboardAPIsFetching,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
    timezone: scheduler.selectedTimezone,
    schedule: demoSchedules.demoSchedule,
    response: demoSchedules.scheduleResponse,
    isActiveSchedules: dashboard.isActiveSchedules,
    isLoginComplete: user.isLoginComplete,
    userDetails: user.profile,
    menu: user?.menu,
    viewport,
    notifications: notifications.userNotifications,
    activeScheduleCount: dashboard.activeScheduleCount,
    params: dashboard.fetchUsersSchedulesParams,
    showUserUpcomingSchedulesSection: userDashboardSections.showUserUpcomingSchedulesSection,
    showUserTodaySchedulesSection: userDashboardSections.showUserTodaySchedulesSection,
    showUserEZDemoSection: userDashboardSections.showUserEZDemoSection,
    showUserPreviousSchedulesSection: userDashboardSections.showUserPreviousSchedulesSection,
    showUserNewsSection: userDashboardSections.showUserNewsSection,
    showUserMostScheduledDemosSection: userDashboardSections.showUserMostScheduledDemosSection,
    isPopularEzDemosLoading: ezDemos?.loading,
    isNewsLoading: news.isFetching,
    isFetchingPreviousSchedules: dashboard.isFetchingPreviousSchedules,
    isMostUsedDemosLoading: trendingDemos?.isLoading,
    isFetchingDashboardSchedules: dashboard.fetchingDashboardSchedules,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchUserSchedules,
    fetchDashboardActiveSchedules,
    selectTitle,
    toggleBackButton,
    fetchActiveDemoResources,
    deleteDemoSchedule,
    toggleIsFetchingUserSchedules,
    deleteMaintenanceSchedule,
    fetchDemos,
    fetchSchedulesOverDuration,
    updateCurrentDemoSchedule,
    updateSchedulerStartEndTime,
    fetchDataCenters,
    clearMostUsedDemos,
    fetchUserNotifications,
    toggleIsFetchingMaintenanceSchedule,
    toggleIsFetchingDashboardSchedules,
    fetchUserDemoRequests,
    toggleIsFetchingSchedule,
    fetchScheduleById,
    updateUserSchedulesParams,
    optOutSchedule,
    toggleIsReschedule,
    openLinkInNewTab,
    deleteDashboardSchedule,
    fetchDashboardPreviousSchedules,
    clearUserDashboardSectionViews,
    refreshUserDashboardView,
    isDashboardLoading,
    toggleIsFetchingTrendingDemos,
    fetchMostScheduledDemos,
    toggleIsFetchingUserEZDemos,
    fetchPopularEzDemos,
    toggleIsFetchingNewsFlag,
    fetchUserNews
  }
)(Dashboard);

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Table, Popconfirm, Button, Icon, Row, Col, Form, Select, Tag } from 'antd';
import {
    deleteEZDemo,
    addNewEZDemo
} from "../../actions"
import StatusIcon from '../common/StatusIcon';
const { Option } = Select;
class EZDemos extends Component {

    constructor(props) {
        super(props);
        this.columns = [
            {
                title: 'Title',
                dataIndex: 'ezDemo.title',
                key: 'title',
                align: 'left',
                width: 200,
                sorter: false,
            },
            {
                title: 'Status',
                dataIndex: 'ezDemo.isActive',
                key: 'status',
                width: 100,
                render: (status) => <StatusIcon status={status} />,
                align: 'center'
            },
            {
                title: 'EZ Demo Type',
                dataIndex: 'ezDemo.isFile',
                key: 'status',
                width: 100,
                render: (isFile) => <Tag color={isFile ? "geekblue" : "volcano"}>{isFile ? "File" : "Link"}</Tag>,
                align: 'center'
            },
            {
                title: 'Action',
                key: 'action',
                width: 100,
                render: (option, record) => {
                    return (
                        <div>
                            <Popconfirm title="Confirm Delete?" onConfirm={() => this.handleDelete(record?.ezDemo?.id)} okText="Yes" cancelText="No">
                                <span className="link" >Delete</span>
                            </Popconfirm>
                        </div>
                    )
                },
                align: 'center'
            },
        ];
    }

    handleDelete = async (ezDemoId) => {
        await this.props.deleteEZDemo(ezDemoId)
    }

    handleAdd = () => {
        const { form } = this.props;
        const ezDemos = form.getFieldsValue().ezDemos;
        ezDemos.forEach(ezDemo => {
            this.props.addNewEZDemo({ ezDemo: JSON.parse(ezDemo) });
        })
        form.resetFields();
    };

    renderEZDemoOptions = () => {
        const { allEZDemos, ezDemos } = this.props;
        const filteredEZDemos = allEZDemos?.length > 0 && allEZDemos?.filter(ar =>
            ezDemos?.every(ez => ez?.ezDemo?.id !== ar.id));

        return filteredEZDemos
            ? filteredEZDemos?.map((ezDemo, index) => {
                return <Option key={index} value={JSON.stringify({ id: ezDemo?.id, title: ezDemo?.title, isFile: ezDemo?.isFile, isActive: ezDemo?.isActive })}>{ezDemo.title}</Option>;
            })
            : [];
    }

    renderEZDemosSelect = () => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Row gutter={24} className="vertical-spacing">
                <Col xl={12} sm={12} xs={24}>
                    <Form id="demoEZDemosForm">
                        <Form.Item>
                            {
                                getFieldDecorator('ezDemos')(
                                    <Select showSearch={true}
                                        autoClearSearchValue={false}
                                        getPopupContainer={() => document.getElementById('demoEZDemosForm')}
                                        mode="multiple"
                                        placeholder="Select one or more EZ Demos"
                                    >
                                        {this.renderEZDemoOptions()}
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Form>
                </Col>
                <Col xl={2} sm={2} xs={24}>
                    <Button aria-label="Click" className="primary-action-button-bordered horizontal-spacing antd-button-fix" onClick={this.handleAdd} disabled={!form.getFieldValue('ezDemos')}>
                        Add
                        <Icon aria-label="aria-menu" type="plus-circle" />
                    </Button>
                </Col>
            </Row>
        )
    }


    render() {
        const { ezDemos } = this.props;
        return (
            <React.Fragment>
                {this.renderEZDemosSelect()}
                <Table
                    size='middle'
                    rowKey={(record) => record.id}
                    className="responsive-container"
                    bordered
                    dataSource={ezDemos}
                    columns={this.columns}
                    pagination={false}
                    scroll={{ y: 550, scrollToFirstRowOnChange: false, x: 800 }}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ openTabDemoDetails, viewport, demoEZDemos }) => {
    const { demoEzDemos } = openTabDemoDetails?.currentTabData || []
    return {
        ezDemos: demoEzDemos,
        viewport,
        allEZDemos: demoEZDemos.allEZDemos
    };
}

const EditableEZDemosTable = Form.create()(EZDemos);

export default connect(mapStateToProps,
    {
        deleteEZDemo,
        addNewEZDemo
    }
)(EditableEZDemosTable);